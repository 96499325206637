import * as FileSaver from "file-saver";
// import * as XLSX from "xlsx";
import moment from "moment";

// const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
// const fileExtension = ".xlsx";
const format = "YYYY-MM-DD HH:mm:ss";

export const exportToCSV = (
  response,
  fileName = "report",
  requiredDate = true,
) => {
  response?.blob()?.then((data) => {
    const dateString = `_${moment(new Date()).format(format).toString()}`;
    // const ws = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(apiData)));
    // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // const data = new Blob([excelBuffer], { type: fileType });
    if (requiredDate) {
      FileSaver.saveAs(data, fileName + dateString);
    } else {
      FileSaver.saveAs(data, fileName);
    }
  });
};

export const getBdayFilename = (date) => {
  const month = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  const d = new Date(date);
  return `birthday_report_${month[d.getMonth()]}_${d.getFullYear()}`;
};
