import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isFireHistoryLoading } from "_helpers/reduxSelector";
import { formatDate } from "_helpers/helpers";
import ReactPaginate from "react-paginate";

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  assignmentClass: ASC_CLASS,
  incidentClass: ASC_CLASS,
  locationClass: ASC_CLASS,
  complexityClass: ASC_CLASS,
  fuelTypeClass: ASC_CLASS,
  fireSizeClass: ASC_CLASS,
  hotlineClass: ASC_CLASS,
  dateOnClass: ASC_CLASS,
  dateOffClass: ASC_CLASS,
  crewClass: ASC_CLASS,
};

const Sort = {
  Asc: "asc",
  Dsc: "desc",
};

const FireHistoryReportAll = (props) => {
  const { data, setSortParams, fetchFireHistoryReport, lastPage } = props;

  const [
    {
      crewClass,
      assignmentClass,
      incidentClass,
      locationClass,
      complexityClass,
      fuelTypeClass,
      fireSizeClass,
      hotlineClass,
      dateOnClass,
      dateOffClass,
    },
    setStyleState,
  ] = useState(initialStyleState);
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("incident_name");
  const [page, setPage] = useState(1);

  const isLoading = useSelector((state) => isFireHistoryLoading(state));

  const handlePageChange = (newPage) => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    fetchFireHistoryReport(newPage?.selected + 1, sortItem, sortOrder);
    setPage(newPage?.selected + 1);
  };

  const noDataAvailable = !isLoading && !data?.length;

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams(sort, nextOrder);
  };

  useEffect(() => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    if (sortItem) {
      fetchFireHistoryReport(page, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  useEffect(() => {
    setPage(0);
  }, []);

  return (
    <>
      <div className="history_lists outer_list table__scroll">
        {!isLoading ? (
          <table className="mobile_hide report_list_width_full">
            <thead>
              <tr className="report_list_table_row">
                <th
                  onClick={() =>
                    sortList("crewClass", crewClass, "resource_name")
                  }
                >
                  <span>Crew</span>
                  <span className={crewClass} />
                </th>
                <th
                  onClick={() =>
                    sortList(
                      "assignmentClass",
                      assignmentClass,
                      "assignment_type",
                    )
                  }
                >
                  <span>Type of Assignment</span>
                  <span className={assignmentClass} />
                </th>
                <th
                  onClick={() =>
                    sortList("incidentClass", incidentClass, "incident_name")
                  }
                >
                  <span>Incident Name</span>
                  <span className={incidentClass} />
                </th>
                <th
                  onClick={() =>
                    sortList("locationClass", locationClass, "location")
                  }
                >
                  <span>Location</span>
                  <span className={locationClass} />
                </th>
                <th
                  onClick={() =>
                    sortList("complexityClass", complexityClass, "complexity")
                  }
                >
                  <span>Complexity</span>
                  <span className={complexityClass} />
                </th>
                <th
                  onClick={() =>
                    sortList("fuelTypeClass", fuelTypeClass, "fuel_type")
                  }
                >
                  <span>Fuel Type</span>
                  <span className={fuelTypeClass} />
                </th>
                <th
                  onClick={() =>
                    sortList("fireSizeClass", fireSizeClass, "fire_size")
                  }
                >
                  <span>Fire Size</span>
                  <span className={fireSizeClass} />
                </th>

                <th
                  onClick={() =>
                    sortList("hotlineClass", hotlineClass, "hotline")
                  }
                >
                  <span>Hotline Shifts</span>
                  <span className={hotlineClass} />
                </th>
                <th
                  onClick={() =>
                    sortList("dateOnClass", dateOnClass, "date_on")
                  }
                >
                  <span>Date On</span>
                  <span className={dateOnClass} />
                </th>
                <th
                  onClick={() =>
                    sortList("dateOffClass", dateOffClass, "date_off")
                  }
                >
                  <span>Date Off</span>
                  <span className={dateOffClass} />
                </th>
              </tr>
            </thead>
            <tbody className="report_list_table_tbody">
              {noDataAvailable ? (
                <tr>
                  <td colSpan="7">
                    <div className="dashboard_expiration_date_no_data">
                      No Data Found
                    </div>
                  </td>
                </tr>
              ) : (
                data?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.resource?.resource_name ?? "-"}</td>
                      <td>{item?.assignment_type ?? "-"}</td>
                      <td>{item?.name || "-"}</td>
                      <td>{item?.location || "-"}</td>
                      <td>{item?.complexity || "-"}</td>
                      <td>{item?.fuel_type || "-"}</td>
                      <td>{item?.fire_size || "-"}</td>
                      <td>{item?.hotline_shifts || "-"}</td>
                      <td>{formatDate(item?.date_on) || "-"}</td>
                      <td>{formatDate(item?.date_off) || "-"}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        ) : (
          <center id="feedLoader">
            <img
              alt="Loader"
              src={require("../assets/images/loader.gif").default}
            />
          </center>
        )}

        {/* Mobile */}
        {noDataAvailable ? (
          <div className="dashboard_expiration_date_no_data for_mobile_history_list">
            No Data Found
          </div>
        ) : (
          <FireHistoryReportMobileView
            data={data}
            fetchFireHistoryReport={fetchFireHistoryReport}
            setSortParams={setSortParams}
          />
        )}
      </div>
      {!isLoading && data?.length ? (
        <div className="fs-pagination-wrapper-outer" key={7}>
          <div className="customised-pagination right-align">
            <ReactPaginate
              forcePage={page ? page - 1 : page}
              previousLabel={""}
              nextLabel={""}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={lastPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => handlePageChange(data)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FireHistoryReportAll;

const FireHistoryReportMobileView = ({
  data,
  fetchFireHistoryReport,
  setSortParams,
}) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("incident_name");
  const [
    {
      crewClass,
      assignmentClass,
      incidentClass,
      locationClass,
      complexityClass,
      fuelTypeClass,
      fireSizeClass,
      hotlineClass,
      dateOnClass,
      dateOffClass,
    },
    setStyleState,
  ] = useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      fetchFireHistoryReport(0, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams?.(sort, nextOrder);
  };

  return data?.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("crewClass", crewClass, "resource_name")}
            >
              <strong>Crew</strong>
              <span className={crewClass} />
            </div>
            <div className="list_item">
              {item?.resource?.resource_name ?? "-"}
            </div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("assignmentClass", assignmentClass, "assignment_type")
              }
            >
              <strong>Assignment Type</strong>
              <span className={assignmentClass} />
            </div>
            <div className="list_item">{item?.assignment_type ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("incidentClass", incidentClass, "incident")
              }
            >
              <strong>Incident Name</strong>
              <span className={incidentClass} />
            </div>
            <div className="list_item">{item?.name ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("locationClass", locationClass, "location")
              }
            >
              <strong>Location</strong>
              <span className={locationClass} />
            </div>
            <div className="list_item">{item?.location ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("complexityClass", complexityClass, "complexity")
              }
            >
              <strong>Complexity</strong>
              <span className={complexityClass} />
            </div>
            <div className="list_item">{item?.complexity ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("fuelTypeClass", fuelTypeClass, "fuel_type")
              }
            >
              <strong>Fuel Type</strong>
              <span className={fuelTypeClass} />
            </div>
            <div className="list_item">{item?.fuel_type}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("fireSizeClass", fireSizeClass, "fire_size")
              }
            >
              <strong>Fire Size</strong>
              <span className={fireSizeClass} />
            </div>
            <div className="list_item">{item?.fire_size}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("hotlineClass", hotlineClass, "hotline")}
            >
              <strong>Hotline Shifts</strong>
              <span className={hotlineClass} />
            </div>
            <div className="list_item">{item?.hotline_shifts}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("dateOnClass", dateOnClass, "date_on")}
            >
              <strong>Date On</strong>
              <span className={dateOnClass} />
            </div>
            <div className="list_item">{item?.date_on}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("dateOffClass", dateOffClass, "date_off")}
            >
              <strong>Date Off</strong>
              <span className={dateOffClass} />
            </div>
            <div className="list_item">{item?.date_off}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};
