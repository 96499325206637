import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { dashboardActions, resourceActions } from "_actions";
import { dashboardService } from "../_services/dashboard.service";
import "../assets/styles/report.css";
import RefresherReportList from "./DocsStatusListReport";
import { selectMasterList } from "_helpers/reduxSelector";
import { redirectToPdf } from "_helpers/redirectToPdf";

const MENU_WIDTH = 300;

const customStyles = {
  content: {
    width: "460px",
    height: "353px",
  },
};

const selectStyles = {
  control: (css) => ({
    ...css,
    width: MENU_WIDTH || "auto",
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 7,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 9999,
  }),
  option: (css) => ({ ...css, paddingRight: 36 + 8, zIndex: 9999 }),
};

const DocsStatusListReport = (props) => {
  const dispatch = useDispatch();
  const resetRef = useRef(null);
  const [selectedResource, setSelectedResource] = React.useState([]);
  const [isAskFillPending, setIsAskFillPending] = React.useState(false);
  const [isLockPending, setIsLockPending] = React.useState(false);
  const [isUnlockPending, setIsUnlockPending] = React.useState(false);
  const [selectedUsersHash, setSelectedUsersHash] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isLockModalOpen, setIsLockModalOpen] = React.useState(false);
  const [isUnlockModalOpen, setIsUnlockModalOpen] = React.useState(false);
  const [sortAndOrder, setSortAndOrder] = React.useState({
    order: "asc",
    sortBy: "first_name",
  });
  const [globalPage, setGlobalPage] = React.useState(0);
  const [keySearch, setKeySearch] = React.useState("");
  const [resetOption, setResetOption] = useState("1");
  const [isAllPagesSelected, setIsAllPagesSelected] = useState(false);
  const [isChecked, setIsChecked] = useState("");

  const refresherReports = useSelector(selectMasterList);
  let selectedEmployee = useRef();

  const getRefresherReportWithFilters = (newPage = 0, sort, order) => {
    handleSortAndOrderForPrint(sort, order);
    if (keySearch.length || selectedResource?.value) {
      setGlobalPage(0);
      newPage = 0;
    }
    let data = new FormData();
    data.append("page", newPage || globalPage || 0);
    selectedResource.forEach((resource) => {
      data.append("resource_id[]", resource.value);
    });
    data.append("sort", sort || "last_name");
    data.append("order", order || "asc");
    data.append("keyword", keySearch || "");
    dispatch(dashboardActions.getMasterList(data));
  };

  const onPrintClick = () => {
    let data = new FormData();
    selectedResource.forEach((resource) => {
      data.append("resource_id[]", resource.value);
    });
    data.append("sort", sortAndOrder?.sortBy);
    data.append("order", sortAndOrder?.order);
    data.append("keyword", keySearch || "");
    data.append("report_type", "pdf");
    dashboardService
      .generateMasterListPdf(data)
      .then((response) => redirectToPdf(response, 'doc_status_list_' + Date.now()));
  };

  const onExportClick = () => {
    let data = new FormData();
    selectedResource.forEach((resource) => {
      data.append("resource_id[]", resource.value);
    });
    data.append("sort", sortAndOrder?.sortBy);
    data.append("order", sortAndOrder?.order);
    data.append("keyword", keySearch || "");
    data.append("report_type", "csv");
    dashboardService.generateDocsStatusReportCsv(data);
  };

  const handleSearch = async (text) => {
    let data = new FormData();
    data.append("keyword", text);
    const resources = dispatch(resourceActions.searchResource(data));
    let resourceList = [];

    const response = await resources;
    const items = response.data;
    var res = [];
    for (var key in items) {
      var arr = items[key];
      if (arr.length) {
        res = res.concat(arr);
      }
    }
    if (res.length > 0) {
      res.forEach((item) => {
        let resource = {
          label: item.resource_name,
          value: item.id,
        };
        resourceList.push(resource);
      });
    }
    return resourceList;
  };

  const loadResources = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(handleSearch(inputValue));
      }, 1000);
    });

  const handleSortAndOrderForPrint = (sortBy, order) => {
    let _sortAndOrder = { ...sortAndOrder };
    _sortAndOrder.sortBy = sortBy || _sortAndOrder.sortBy;
    _sortAndOrder.order = order || _sortAndOrder.order;
    setSortAndOrder(_sortAndOrder);
  };

  const handleAskToFillDocument = async () => {
    setIsAskFillPending(true);
    let data = new FormData();
    selectedUsersHash?.forEach((hash, index) => {
      data.append(`hash[${index}]`, hash);
    });
    data.append("all_checked", isAllPagesSelected);
    data.append("reset_type", resetOption);
    if (keySearch.length) data.append("keyword", keySearch || "");

    dashboardService
      .askToFillDocumentsByUsersHash(data)
      .then((response) => {
        toast.success(response.message);
        setIsAskFillPending(false);
        setIsModalOpen(false);
        getRefresherReportWithFilters();
        setSelectedUsersHash([]);
      })
      .catch((err) => {
        toast.error(err.message);
        setIsAskFillPending(false);
      });
    handleCancel();
  };

  const checkboxStatus = (status = false) => {
    setIsChecked(status ? "checked" : "");
  };

  const lockEmployees = async () => {
    setIsLockPending(true);
    let data = new FormData();
    selectedUsersHash?.forEach((hash, index) => {
      data.append(`hash[${index}]`, hash);
    });
    data.append("modify_active_status", true);
    data.append("status", "Locked");

    dashboardService
      .lockEmployees(data)
      .then((response) => {
        toast.success(response.message);
        setIsLockPending(false);
        setIsLockModalOpen(false);
        setIsAllPagesSelected(false);
        getRefresherReportWithFilters();
        setSelectedUsersHash([]);
        resetRef.current.handleClearSelection();
      })
      .catch((err) => {
        toast.error(err.message);
        setIsLockPending(false);
      });
  };

  const unlockEmployees = async () => {
    setIsUnlockPending(true);
    let data = new FormData();
    selectedUsersHash?.forEach((hash, index) => {
      data.append(`hash[${index}]`, hash);
    });
    data.append("modify_active_status", true);
    data.append("status", "Unlocked");

    dashboardService
      .lockEmployees(data)
      .then((response) => {
        toast.success(response.message);
        setIsUnlockPending(false);
        setIsUnlockModalOpen(false);
        setIsAllPagesSelected(false);
        getRefresherReportWithFilters();
        setSelectedUsersHash([]);
        resetRef.current.handleClearSelection();
      })
      .catch((err) => {
        toast.error(err.message);
        setIsUnlockPending(false);
      });
  };

  const handleResetOptionSelect = (e) => {
    setResetOption(e.target.value);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsAllPagesSelected(false);
    setSelectedUsersHash([]);
    resetRef.current.handleClearSelection();
  };

  useEffect(() => {
    if (keySearch.length) {
      setGlobalPage(0);
    }
    const timer = setTimeout(() => {
      getRefresherReportWithFilters();
    }, 1000);
    return () => clearTimeout(timer);
  }, [keySearch]);

  useEffect(() => {
    if (selectedUsersHash.length === 1) {
      const employeeHash = selectedUsersHash.at(0);
      selectedEmployee.current = refresherReports.data.find(
        (item) => item.hash === employeeHash,
      );
      return;
    }
  }, [selectedUsersHash.length]);

  return (
    <div>
      <div className={props.isReportMenu ? "" : "dashboard_container"}>
        <div className="white">
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head sold_rpt_mbl_flx docststus__outer docs-status-list-report">
                <h5 className="graph_header">Docs Status List</h5>
                <div
                  style={{
                    gap: `${props.isReportMenu ? "10px" : "0px"}`,
                    width: "80%",
                  }}
                  className="report_head_btn_grp exp_multi_head_flx"
                >
                  <div className="report_multi_btn_grp exp_multi_btn_flx">
                    <div
                      className="inp_sub_container"
                      style={{ minWidth: 300 }}
                    >
                      <input
                        type="text"
                        className="employee_search"
                        value={keySearch}
                        name="keyword"
                        placeholder="Search..."
                        onChange={(e) => setKeySearch(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                    <div
                      style={{ minWidth: 300 }}
                      className="inp_sub_container"
                    >
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadResources}
                        onChange={(selection) =>
                          setSelectedResource(selection || [])
                        }
                        isMulti
                        defaultOptions
                        isClearable
                        styles={selectStyles}
                        placeholder={"Choose Resource Filter"}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="button__div-flex aks__btn-outer"
                  style={{ marginTop: "10px", display: "flex", gap: "10px" }}
                >
                  {refresherReports?.data?.length && props.isReportMenu ? (
                    <div
                      className={`button__div-flex aks__btn-outer ${isChecked}`}
                      style={{ flexWrap: "wrap" }}
                    >
                      {selectedUsersHash.length || isAllPagesSelected ? (
                        <>
                          <button
                            className="print_button button ask-to-fill-btn"
                            onClick={(event) => setIsModalOpen(true)}
                            title="Ask to fill documents"
                          >
                            Ask to fill documents
                          </button>

                          <button
                            className="print_button button ask-to-fill-btn"
                            onClick={(event) => setIsLockModalOpen(true)}
                            title="Lock & Unavailable"
                          >
                            Lock & Unavailable
                          </button>

                          <button
                            className="print_button button ask-to-fill-btn"
                            onClick={(event) => setIsUnlockModalOpen(true)}
                            title="Unlock & Available"
                          >
                            Unlock & Available
                          </button>
                        </>
                      ) : null}
                      <button
                        href=""
                        className="print_button ask-to-fill-btn"
                        style={{ marginRight: "10px" }}
                        onClick={(event) => {
                          onExportClick();
                        }}
                      >
                        Export
                      </button>
                      <button
                        href=""
                        className="print_button ask-to-fill-btn"
                        onClick={(event) => {
                          onPrintClick();
                        }}
                      >
                        Print
                      </button>
                    </div>
                  ) : (
                    <div className="dashboard-expiration-date-print-btn-mock" />
                  )}
                </div>
              </div>
            </div>
            <div>
              <RefresherReportList
                selectedUsersHash={selectedUsersHash}
                setSelectedUsersHash={setSelectedUsersHash}
                refresherReports={refresherReports}
                getRefresherReport={getRefresherReportWithFilters}
                selectedResource={selectedResource}
                setGlobalPage={setGlobalPage}
                checkboxStatus={checkboxStatus}
                setIsAllPagesSelected={setIsAllPagesSelected}
                ref={resetRef}
              />
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={isModalOpen}
        contentLabel="Unlock Employees"
        style={customStyles}
        overlayClassName="RemoveApplntPopup emp_status_modal"
      >
        <div className="modal-head">
          <h4>Ask To Fill Documents</h4>
          <img
            alt="close"
            className="closeModal"
            onClick={() => (isAskFillPending ? {} : setIsModalOpen(false))}
            src={require("../assets/images/close_green.png").default}
          />
        </div>

        <div className="modal-body">
          {isAllPagesSelected ? (
            <>
              <p style={{ color: "green" }}>
                Number of employees: {refresherReports.total}
              </p>
              <p style={{ color: "green" }}>
                Number of unselected employees: {selectedUsersHash.length}
              </p>
            </>
          ) : (
            <p style={{ color: "green" }}>
              Number of selected Employees:{" "}
              {selectedUsersHash.length || refresherReports.total}
            </p>
          )}

          <p>
            Are you sure you want to ask to fill documents for the selected
            employees?
          </p>

          <div className="radio-fields-modal">
            {(selectedUsersHash.length > 1 ||
              selectedEmployee?.current?.docs_status !== -1 ||
              isAllPagesSelected) && (
              <div className="radio-btn-modal">
                <input
                  type="radio"
                  name="reset-doc"
                  value="1"
                  id="reset-onboarding"
                  onChange={handleResetOptionSelect}
                  checked={resetOption === "1"}
                />
                <label htmlFor="reset-onboarding">
                  Reset Onboarding documents
                </label>
              </div>
            )}
            {(selectedUsersHash.length > 1 ||
              selectedEmployee?.current?.rehire_docs_status !== -1 ||
              isAllPagesSelected) && (
              <div className="radio-btn-modal">
                <input
                  type="radio"
                  name="reset-doc"
                  value="2"
                  id="reset-rehire"
                  onChange={handleResetOptionSelect}
                  checked={resetOption === "2"}
                />
                <label htmlFor="reset-rehire">Reset Rehire documents</label>
              </div>
            )}
            {(selectedUsersHash.length > 1 ||
              isAllPagesSelected ||
              (selectedEmployee?.current?.docs_status !== -1 &&
                selectedEmployee?.current?.rehire_docs_status !== -1)) && (
              <div className="radio-btn-modal">
                <input
                  type="radio"
                  name="reset-doc"
                  value="3"
                  id="reset-both"
                  onChange={handleResetOptionSelect}
                  checked={resetOption === "3"}
                />
                <label htmlFor="reset-both">Reset both</label>
              </div>
            )}
            {(selectedUsersHash.length > 1 || isAllPagesSelected) && (
              <div className="radio-btn-modal">
                <input
                  type="radio"
                  name="reset-doc"
                  value="4"
                  id="last-submission"
                  onChange={handleResetOptionSelect}
                  checked={resetOption === "4"}
                />
                <label htmlFor="last-submission">
                  Reset docs based on last submission (Onboarding/Rehire)
                </label>
              </div>
            )}
          </div>
          <div className="confirm-btn">
            <button
              className="print_button button btn_close"
              onClick={handleCancel}
            >
              Cancel
            </button>
            {
              <button
                className="print_button button"
                onClick={handleAskToFillDocument}
              >
                Confirm
              </button>
            }
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={isLockModalOpen}
        contentLabel="Lock Employees"
        style={customStyles}
        overlayClassName="RemoveApplntPopup emp_status_modal"
      >
        <div className="modal-head">
          <h4>Lock & Make Unavailable</h4>
          <img
            alt="close"
            className="closeModal"
            onClick={() => setIsLockModalOpen(false)}
            src={require("../assets/images/close_green.png").default}
          />
        </div>

        <div className="modal-body">
          <p>
            Are you sure you want to lock all selected employees and make them
            unavailable?
          </p>

          <div className="confirm-btn">
            <button
              className="print_button button btn_close"
              disabled={isLockPending}
              onClick={() => setIsLockModalOpen(false)}
            >
              Cancel
            </button>
            {
              <button
                className="print_button button"
                disabled={isLockPending}
                onClick={lockEmployees}
              >
                Lock
              </button>
            }
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={isUnlockModalOpen}
        contentLabel="Unlock Employees"
        style={customStyles}
        overlayClassName="RemoveApplntPopup emp_status_modal"
      >
        <div className="modal-head">
          <h4>Unlock & Make Available</h4>
          <img
            alt="close"
            className="closeModal"
            onClick={() => setIsUnlockModalOpen(false)}
            src={require("../assets/images/close_green.png").default}
          />
        </div>

        <div className="modal-body">
          <p>
            Are you sure you want to unlock all selected employees and make them
            available?
          </p>

          <div className="confirm-btn">
            <button
              className="print_button button btn_close"
              disabled={isUnlockPending}
              onClick={() => setIsUnlockModalOpen(false)}
            >
              Cancel
            </button>
            {
              <button
                className="print_button button"
                disabled={isUnlockPending}
                onClick={unlockEmployees}
              >
                Unlock
              </button>
            }
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default DocsStatusListReport;
