import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { dashboardActions, inventoryActions, resourceActions } from "_actions";
import { dashboardService } from "../_services/dashboard.service";
import AllInventoryReportList from "./AllInventoryReportList";
import {
  getAllInventoryReport as getAllInventoryReportStore,
  selectInventoryEquipments,
} from "_helpers/reduxSelector";
import { exportToCSV } from "_utils/export";

import "../assets/styles/report.css";
import AsyncSelect from "react-select/async";

const FILE_NAME = "All Inventory Report";
const VEHICLE = 1;

const MENU_WIDTH = 300;

const selectStyles = {
  control: (css) => ({
    ...css,
    width: MENU_WIDTH || "auto",
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 7,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 9999,
  }),
  option: (css) => ({ ...css, paddingRight: 36 + 8, zIndex: 9999 }),
};

const AllInventoryReportListContainer = (props) => {
  const dispatch = useDispatch();

  const allInventoryReport = useSelector((state) =>
    getAllInventoryReportStore(state),
  );
  const inventoryEquipments = useSelector(selectInventoryEquipments);

  const [globalSort, setGlobalSort] = React.useState("division");
  const [globalOrder, setGlobalOrder] = React.useState("asc");
  const [selectedDivision, setSelectedDivision] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [selectedAssignee, setSelectedAssignee] = React.useState("");
  const [selectedResource, setSelectedResource] = React.useState();

  const getAllInventoryReport = (newPage, sort, order) => {
    let data = new FormData();
    data.append("page", newPage || 0);
    data.append("sort", sort || "division");
    data.append("order", order || "asc");
    data.append("category", selectedCategory ?? "");
    data.append("division", selectedDivision ?? "");
    data.append("assignee", selectedAssignee ?? "");
    data.append("resource_id", selectedResource?.value || 0);
    dispatch(dashboardActions.getAllInventoryReport(data));
  };

  const setSortParams = (sort, order) => {
    const sortOrder = order ? "asc" : "desc";
    setGlobalSort(sort);
    setGlobalOrder(sortOrder);
  };

  const handleSearch = async (text) => {
    let data = new FormData();
    data.append("keyword", text);
    const resources = dispatch(resourceActions.searchResource(data));
    let resourceList = [];

    const response = await resources;
    const items = response.data;
    var res = [];
    for (var key in items) {
      var arr = items[key];
      if (arr.length) {
        res = res.concat(arr);
      }
    }
    if (res.length > 0) {
      res.forEach((item) => {
        let resource = {
          label: item.resource_name,
          value: item.id,
        };
        resourceList.push(resource);
      });
    }
    return resourceList;
  };

  const loadResources = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(handleSearch(inputValue));
      }, 1000);
    });

  const onPrintClick = () => {
    let data = new FormData();
    data.append("sort", globalSort || "division");
    data.append("order", globalOrder || "asc");
    data.append("category", selectedCategory ?? "");
    data.append("division", selectedDivision ?? "");
    data.append("assignee", selectedAssignee ?? "");
    data.append("resource_id", selectedResource?.value || 0);
    data.append("report_type", "pdf");
    dashboardService.printAllInventoryReportPdf(data).then((response) => {
      redirectToPdf(response);
    });
  };

  const onExportClick = () => {
    let data = new FormData();
    data.append("sort", globalSort || "division");
    data.append("order", globalOrder || "asc");
    data.append("category", selectedCategory ?? "");
    data.append("division", selectedDivision ?? "");
    data.append("assignee", selectedAssignee ?? "");
    data.append("resource_id", selectedResource?.value || 0);
    data.append("report_type", "csv");
    dashboardService.generateAllInventoryReportCsv(data);
  };

  const redirectToPdf = (data) => {
    if (data?.success) {
      const pdfLink = data.data;
      var link = document.createElement("a");
      if (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i)
      ) {
        link.href = pdfLink;
      } else {
        link.href = pdfLink;
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfLink);
      }, 100);
    }
  };

  React.useEffect(() => {
    dispatch(inventoryActions?.getListEquipments());
  }, []);

  React.useEffect(() => {
    getAllInventoryReport();
  }, [selectedCategory, selectedDivision, selectedAssignee, selectedResource]);

  const { divisions, equipmentTypes, equipmentAssignees } =
    React.useMemo(() => {
      let divisions =
        inventoryEquipments?.Division?.map((item) => ({
          label: item?.value,
          value: item?.id,
        })) ?? [];
      let equipmentTypes =
        inventoryEquipments?.EquipmentType?.map((item) => ({
          label: item?.value,
          value: item?.id,
        })).filter((item) => item.value !== VEHICLE) ?? [];
      let equipmentAssignees =
        inventoryEquipments?.Assignee?.map((item) => ({
          label: item?.value,
          value: item?.id,
        })) ?? [];
      return { divisions, equipmentTypes, equipmentAssignees };
    }, [inventoryEquipments]);

  return (
    <div>
      <div className={props.isReportMenu ? "" : "dashboard_container"}>
        <div className="white">
          {!props.isReportMenu && (
            <div className="back_to_board dashboard_expiration_date_nav">
              <div className="back_button">
                <img
                  src={require("../assets/images/back.svg").default}
                  alt=""
                />{" "}
                <span>
                  <Link to="/">Back to dashboard</Link>
                </span>
              </div>
              {allInventoryReport?.data?.length ? (
                <button
                  href=""
                  className="print_button"
                  onClick={(event) => {
                    onPrintClick();
                  }}
                >
                  {/* <img alt="" src={require("../assets/images/print.svg").default} /> Print */}
                  Export
                </button>
              ) : (
                <div className="dashboard-expiration-date-print-btn-mock" />
              )}
            </div>
          )}
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head">
                <h5 style={{ width: "100%" }} className="graph_header">
                  All Inventory Report
                </h5>
              </div>
              <div className="report_tbl_head" style={{ marginLeft: 0 }}>
                <div
                  style={{
                    gap: `${props.isReportMenu ? "10px" : "0px"}`,
                    width: "100%",
                  }}
                  className="report_head_btn_grp"
                >
                  <div style={{ minWidth: 250 }} className="inp_sub_container">
                    <ReactSelect
                      isDisabled={!divisions?.length}
                      options={divisions}
                      isClearable
                      onChange={(newDivision) =>
                        setSelectedDivision(newDivision?.value)
                      }
                      placeholder={"Choose Division"}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                  <div style={{ minWidth: 250 }} className="inp_sub_container">
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadResources}
                      onChange={(selection) => setSelectedResource(selection)}
                      // isMulti
                      defaultOptions
                      isClearable
                      styles={selectStyles}
                      placeholder={"Choose Resource / Crew"}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                  <div style={{ minWidth: 250 }} className="inp_sub_container">
                    <ReactSelect
                      isDisabled={!equipmentTypes?.length}
                      options={equipmentTypes}
                      onChange={(newEquipmentType) =>
                        setSelectedCategory(newEquipmentType?.value)
                      }
                      isClearable
                      placeholder={"Choose Category"}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                  <div style={{ minWidth: 250 }} className="inp_sub_container">
                    <ReactSelect
                      isDisabled={!equipmentAssignees?.length}
                      options={equipmentAssignees}
                      onChange={(newEquipmentAssignee) =>
                        setSelectedAssignee(newEquipmentAssignee?.value)
                      }
                      isClearable
                      placeholder={"Choose Assignee"}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: "10px" }}>
                {props.isReportMenu && allInventoryReport?.data?.length ? (
                  <div style={{ marginLeft: "auto" }}>
                    <button
                      href=""
                      className="print_button"
                      style={{ marginRight: "10px" }}
                      onClick={(event) => {
                        onExportClick();
                      }}
                    >
                      {/* <img alt="" src={require("../assets/images/print.svg").default} /> Print */}
                      Export
                    </button>
                    <button
                      href=""
                      className="print_button"
                      onClick={(event) => {
                        onPrintClick();
                      }}
                    >
                      {/* <img alt="" src={require("../assets/images/print.svg").default} /> Print */}
                      Print
                    </button>
                  </div>
                ) : (
                  <div className="dashboard-expiration-date-print-btn-mock" />
                )}
              </div>
            </div>
            <div>
              <AllInventoryReportList
                data={allInventoryReport?.data ?? []}
                lastPage={allInventoryReport?.last_page}
                getAllInventoryReport={getAllInventoryReport}
                setSortParams={setSortParams}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllInventoryReportListContainer;
