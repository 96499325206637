import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { dashboardActions } from "_actions";
import { dashboardService } from "../_services/dashboard.service";
import { exportToCSV } from "_utils/export";
import { getAffirmativeActionReport as getAffirmativeActionReportStore } from "_helpers/reduxSelector";
import AffirmativeActionReportList from "./AffirmativeActionReportList";

import "../assets/styles/report.css";

const FILE_NAME = "Affirmative Action Report";

const AffirmativeActionReportListContainer = (props) => {
  const dispatch = useDispatch();

  const affirmativeActionReport = useSelector((state) =>
    getAffirmativeActionReportStore(state),
  );

  const [globalSort, setGlobalSort] = React.useState("division");
  const [globalOrder, setGlobalOrder] = React.useState("asc");

  const getAffirmativeActionReport = (newPage, sort, order) => {
    let data = new FormData();
    data.append("page", newPage || 0);
    data.append("sort", sort || "division");
    data.append("order", order || "asc");
    dispatch(dashboardActions.getAffirmativeActionReport(data));
  };

  const setSortParams = (sort, order) => {
    const sortOrder = order ? "asc" : "desc";
    setGlobalSort(sort);
    setGlobalOrder(sortOrder);
  };

  const onExportClick = () => {
    let data = new FormData();
    data.append("sort", globalSort || "division");
    data.append("order", globalOrder || "asc");
    data.append("report_type", "csv");
    dashboardService.generateAffirmativeActionReport(data);
  };

  const onPrintClick = () => {
    let data = new FormData();
    data.append("sort", globalSort || "division");
    data.append("order", globalOrder || "asc");
    data.append("report_type", "pdf");
    dashboardService.printAffirmativeActionReport(data).then((res) => {
      redirectToPdf(res);
    });
  };

  const exportToExcel = (res) => {
    if (res.success) {
      exportToCSV(res.data, FILE_NAME);
    }
  };

  const redirectToPdf = (data) => {
    if (data?.success) {
      const pdfLink = data.data;
      var link = document.createElement("a");
      if (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i)
      ) {
        link.href = pdfLink;
      } else {
        link.href = pdfLink;
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfLink);
      }, 100);
    }
  };

  return (
    <div>
      <div className={props.isReportMenu ? "" : "dashboard_container"}>
        <div className="white">
          {!props.isReportMenu && (
            <div className="back_to_board dashboard_expiration_date_nav">
              <div className="back_button">
                <img src={require("../assets/images/back.svg").default} />{" "}
                <span>
                  <Link to="/">Back to dashboard</Link>
                </span>
              </div>
              {affirmativeActionReport?.data?.length ? (
                <button
                  href=""
                  className="print_button"
                  onClick={(event) => {
                    onPrintClick();
                  }}
                >
                  {/* <img alt="" src={require("../assets/images/print.svg").default} /> Print */}
                  Export
                </button>
              ) : (
                <div className="dashboard-expiration-date-print-btn-mock" />
              )}
            </div>
          )}
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head">
                <h5 className="graph_header">Affirmation Action Report</h5>
                <div
                  style={{ gap: `${props.isReportMenu ? "10px" : "0px"}` }}
                  className="report_head_btn_grp"
                >
                  {props.isReportMenu &&
                  affirmativeActionReport?.data?.length ? (
                    <div>
                      <button
                        className="print_button"
                        onClick={(event) => {
                          onExportClick();
                        }}
                      >
                        Export
                      </button>
                      <button
                        className="print_button"
                        onClick={(event) => {
                          onPrintClick();
                        }}
                        style={{ marginLeft: "10px" }}
                      >
                        Print
                      </button>
                    </div>
                  ) : (
                    <div className="dashboard-expiration-date-print-btn-mock" />
                  )}
                </div>
              </div>
            </div>
            <div>
              <AffirmativeActionReportList
                data={affirmativeActionReport?.data ?? []}
                lastPage={affirmativeActionReport?.last_page}
                getAffirmativeActionReport={getAffirmativeActionReport}
                setSortParams={setSortParams}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffirmativeActionReportListContainer;
