/* eslint-disable eqeqeq */
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config } from "_helpers/config";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { usPhone } from "_utils/formatter";
import { docsActions } from "_actions/docs.actions";
import { toast } from "react-toastify";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";
import moment from "moment";
import { getCurrentUserInfo } from "_helpers/reduxSelector";
import checkIfDocsShouldBeLocked from "_helpers/lockDocs";

class CoveredVeteran extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire_doc: false,
      doc: {},
      form: {
        disabled_veteran: "no",
        other_protected_veteran: "no",
        armed_forces_medal_veteran: "no",
        recently_separated_veteran: "no",
        recently_separated_date: moment().format("MM/DD/YYYY"),
        disclaimer_check: false,
      },
      errors: {},
      form_edited: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.navigate = navigate.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.goBack = this.goBack.bind(this);
    !this.state.form_edited && this.setState({ form_edited: true });
    // Refs
    this.form = React.createRef();
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date) {
      _.set(nextState, field, date.format("MM/DD/YYYY"));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: "",
      },
    });
  }

  handleFormChange(e) {
    // safety check for datepicker
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { value, checked, type } = e.target;
      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handlePhoneChange(e) {
    e.target.value = usPhone(e.target.value);
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();

    if (err) {
      return;
    }

    let { user, form, doc } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc
      ? (data.single_doc = true)
      : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { form, user } = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.recently_separated_date =
          form.recently_separated_date ||
          currentState.form.recently_separated_date;
        form.position = user.position || currentState.form.position;
        if (!form?.printed_name) {
          form.printed_name = user?.full_name ?? "";
        }
        if (!form?.phone) {
          form.phone = user?.phone ?? "";
        }

        // spread the data coming from API into state
        this.setState({
          form,
        });
      }
    });
  }
  goBack() {
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen("sidebar");
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    // if (this.props.rehire) {
    //     doc = this.props.docs.userDocs.find(
    //         (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //     );
    // } else {
    //     doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1);
    // }
    if (this.props.giss) {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 5,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 4,
        );
      }
    } else {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 1,
        );
      }
    }
    this.setState({ doc });
    this.getFormData(doc.id);
  }

  render() {
    let { form, errors } = this.state;
    let { adminMode, currentUserInfo } = this.props;

    const shouldLockDocs = checkIfDocsShouldBeLocked(
      currentUserInfo,
      form,
      adminMode,
    );

    return (
      <div className="doc_info-rh">
        <div className="doc-title">
          <img
            src={`${config.assetUrl}icons/doc-title-img.svg`}
            className="doc-title-img"
          />
          <span className="close-button" onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Covered Veteran</h3>
        </div>
        <div className="doc_info-form">
          <form
            className="info-form"
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            noValidate
          >
            <fieldset disabled={shouldLockDocs}>
              <div className="row">
                <div className="col-md-12">
                  <h6 className="inputs-head">
                    IMPORTANT: Please complete this form only if you are a
                    current employee, or a job offer has been extended and you
                    have accepted it.
                  </h6>
                  <p>
                    To enable Firestorm Wildland Fire Suppression Inc to meet
                    government reporting regulations, employees are asked, but
                    not required, to provide information with respect to
                    “covered veterans” in its workforce. This category includes
                    disabled veterans, other protected veterans, armed forces
                    service medal veterans and recently separated veterans. If
                    you fall within any of these categories and wish to identify
                    yourself, you may do so.
                  </p>
                  <p>
                    You may provide this information on a voluntary basis, which
                    will be treated as personal and confidential and kept
                    separate from your personnel file. Your decision to provide
                    or refuse to provide the requested information will not
                    subject you to any adverse treatment. Your voluntary
                    cooperation will be appreciated.
                  </p>
                </div>
              </div>
              <div className="row form-row">
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">
                      Applicant Data
                      <span className="required-star">&#x2A;</span>
                    </div>
                    <div className="inputs-items">
                      <input
                        type="text"
                        className={`${errors["form.printed_name"] ? "error" : ""}`}
                        name="printed_name"
                        field="form.printed_name"
                        value={form.printed_name}
                        id="printed_name"
                        placeholder="Print Name"
                        required
                      />
                      {errors["form.printed_name"] && (
                        <label htmlFor="printed_name" className="error_label">
                          {errors["form.printed_name"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-items">
                      <input
                        type="text"
                        className={`${errors["form.position"] ? "error" : ""}`}
                        name="position"
                        id="position"
                        field="form.position"
                        value={form.position}
                        placeholder="Position (must specify position)"
                        required
                      />
                      {errors["form.position"] && (
                        <label htmlFor="position" className="error_label">
                          {errors["form.position"]}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="inputs-col">
                    <div className="inputs-items">
                      <input
                        type="text"
                        className={`${errors["form.phone"] ? "error" : ""}`}
                        name="phone"
                        id="phone"
                        field="form.phone"
                        value={form.phone}
                        placeholder="Cell Phone"
                        onChange={this.handlePhoneChange}
                        required
                      />
                      {errors["form.phone"] && (
                        <label htmlFor="phone" className="error_label">
                          {errors["form.phone"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group agreement_checks">
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="disabled_veteran"
                          id="disabled_veteran1"
                          value="yes"
                          field="form.disabled_veteran"
                          checked={form.disabled_veteran == "yes"}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="disabled_veteran1">
                          <strong>
                            I qualify as a “Disabled Veteran” because I am:
                          </strong>
                        </label>
                      </div>
                      <ul className="checkb_lists">
                        <li>
                          <span>A:</span> A person who is entitled to
                          compensation (or who but for the receipt of military
                          retired pay would be entitled to compensation) under
                          laws administered by the Secretary of Veterans
                          Affairs; or
                        </li>
                        <li>
                          <span>B:</span> A person who was discharged or
                          released from active duty because of a
                          service-connected disability.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group agreement_checks">
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="disabled_veteran"
                          id="disabled_veteran2"
                          value="no"
                          field="form.disabled_veteran"
                          checked={form.disabled_veteran == "no"}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="disabled_veteran2">
                          <strong>
                            No. I do not fall under this category.
                          </strong>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group agreement_checks">
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="other_protected_veteran"
                          id="other_protected_veteran1"
                          value="yes"
                          field="form.other_protected_veteran"
                          checked={form.other_protected_veteran == "yes"}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="other_protected_veteran1">
                          <strong>
                            I qualify as an “Other Protected Veteran” because I
                            am:
                          </strong>
                        </label>
                      </div>
                      <p className="checkb_detail">
                        A person who served on active duty in the U.S. military,
                        ground, naval or air service in a war, campaign or
                        expedition in which a campaign badge has been authorized
                        under laws administered by the Department of Defense.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group agreement_checks">
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="other_protected_veteran"
                          id="other_protected_veteran2"
                          value="no"
                          field="form.other_protected_veteran"
                          checked={form.other_protected_veteran == "no"}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="other_protected_veteran2">
                          <strong>
                            No. I do not fall under this category.
                          </strong>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group agreement_checks">
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="armed_forces_medal_veteran"
                          id="armed_forces_medal_veteran1"
                          value="yes"
                          field="form.armed_forces_medal_veteran"
                          checked={form.armed_forces_medal_veteran == "yes"}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="armed_forces_medal_veteran1">
                          <strong>
                            I qualify as an “Armed Forces Service Medal Veteran”
                            because I am:
                          </strong>
                        </label>
                      </div>
                      <p className="checkb_detail">
                        A person who, while serving on active duty in the Armed
                        Forces, participated in a United States military
                        operation for which a service medal was awarded pursuant
                        to Executive Order 12985.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group agreement_checks">
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="armed_forces_medal_veteran"
                          id="armed_forces_medal_veteran2"
                          value="no"
                          field="form.armed_forces_medal_veteran"
                          checked={form.armed_forces_medal_veteran == "no"}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="armed_forces_medal_veteran2">
                          <strong>
                            No. I do not fall under this category.
                          </strong>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group agreement_checks">
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="recently_separated_veteran"
                          id="recently_separated_veteran1"
                          value="yes"
                          field="form.recently_separated_veteran"
                          checked={form.recently_separated_veteran == "yes"}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="recently_separated_veteran1">
                          <strong>
                            I qualify as an “Recently Separated Veteran” because
                            I am:
                          </strong>
                        </label>
                      </div>
                      <p className="checkb_detail m-0">
                        A person who was discharged or released from active duty
                        within the last 3 years.
                      </p>
                      <div className="inputs-col">
                        <div className="inputs-head">Discharge Dates</div>
                        <div className="inputs-items">
                          <DatePicker
                            className={`${
                              errors["form.recently_separated_date"]
                                ? "error"
                                : ""
                            } w-100`}
                            name="recently_separated_date"
                            id="recently_separated_date"
                            selected={moment(form.recently_separated_date)}
                            field="form.recently_separated_date"
                            placeholder="mm/dd/yyyy"
                            onChange={this.handleDateChange.bind(
                              this,
                              "form.recently_separated_date",
                            )}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            autoComplete="off"
                            disabled={form.recently_separated_veteran == "no"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group agreement_checks">
                      <div className="check_radio_item">
                        <input
                          type="radio"
                          name="recently_separated_veteran"
                          id="recently_separated_veteran2"
                          value="no"
                          field="form.recently_separated_veteran"
                          checked={form.recently_separated_veteran == "no"}
                          required
                        />
                        <span className="radio_checkbox"></span>
                        <label htmlFor="recently_separated_veteran2">
                          <strong>
                            No. I do not fall under this category.
                          </strong>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <div className="col">
                  <div className="disclaimers">
                    <strong className="diss">Disclaimer: </strong>
                    You must read and carefully understand the documents that
                    you are filling out and signing. These are legally binding
                    documents and must be accurate. By filling these forms out,
                    you are agreeing to our
                    <a href="https://firestormfire.com/terms-of-use/">
                      {" "}
                      Terms of Use, Privacy Policy and Employee Agreements.
                    </a>
                  </div>
                </div>
              </div>
              <div className="row form-row last">
                <div className="col">
                  <div className="inputs-col">
                    <div className="check_radio_group check_disclaimer">
                      <div className="check_radio_item">
                        <input
                          type="checkbox"
                          name="disclaimer_check"
                          id="disclaimer_check"
                          field="form.disclaimer_check"
                          checked={form.disclaimer_check}
                          required
                        />
                        <span className="checkbox_checkbox"></span>
                        <label htmlFor="disclaimer_check">
                          I have confirmed the above details are true and valid.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FormNav
                adminMode={adminMode}
                navigate={this.navigate.bind(this)}
                disclaimer_check={form.disclaimer_check}
                mobileMode={this.props.mobileMode}
              />
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs, currentUserInfo: getCurrentUserInfo(state) };
}

let connectedCoveredVeteran = compose(
  withRouter,
  connect(mapStateToProps),
)(CoveredVeteran);
export { connectedCoveredVeteran as CoveredVeteran };
