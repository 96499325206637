import React from "react";
import { config } from "../_helpers";
import { connect } from "react-redux";
import { chatActions } from "../_actions";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { dateTimeConstants } from "_constants";

class User extends React.Component {
  constructor() {
    super();
    this.state = {
      editOptions: false,
    };

    this.removeUser = this.removeUser.bind(this);
  }

  removeUser(member) {
    this.setState({ editOptions: false });
    this.props.removeUser(member);
  }

  render() {
    let { member, permissions, user } = this.props;

    let { editOptions } = this.state;

    return (
      <div className="msg-users">
        <div className="msg-user-pic">
          <img
            src={config.baseUrl + member.profile_photo}
            onError={(e) => {
              e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
            }}
          />
        </div>
        <div className="msg-messageData">
          <div className="msg-name">
            {`${member.first_name} ${member.last_name}`}
          </div>
          <div className="msg-title">{member.designation}</div>
          {user &&
          user.hash != member.hash &&
          permissions.length > 0 &&
          permissions.includes("Remove participants") ? (
            <div className="member-modify">
              <a
                onClick={() => {
                  this.setState({ editOptions: true });
                }}
                id="msg-edit"
              >
                <img src={`${config.assetUrl}icons/msg-modify.svg`} />
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
        {editOptions && (
          <OutsideClickHandler
            onOutsideClick={() => {
              this.setState({ editOptions: false });
            }}
          >
            <div className="msg-action">
              <button
                onClick={this.removeUser.bind(this, member)}
                className="btn btn-default btn-delete"
              >
                <i className="fas fa-trash-alt"></i> Remove
              </button>
            </div>
          </OutsideClickHandler>
        )}
      </div>
    );
  }
}

class Crew extends React.Component {
  constructor() {
    super();
    this.state = {
      editOptions: false,
    };

    this.removeCrew = this.removeCrew.bind(this);
    this.collapseCrew = this.collapseCrew.bind(this);
  }

  removeCrew(crew) {
    this.setState({ editOptions: false });
    this.props.removeCrew(crew);
  }

  collapseCrew(index) {
    this.props.collapseCrew(index);
  }

  render() {
    let { permissions, user, crew, index } = this.props;

    let { editOptions } = this.state;

    return (
      <div className="msg-users msg-crew">
        <div className="msg-user-pic">
          <img
            src={config.baseUrl + crew.resource_photo}
            onError={(e) => {
              e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
            }}
            alt=""
          />
        </div>
        <div
          onClick={this.collapseCrew.bind(this, index)}
          className="msg-messageData"
        >
          <div className="msg-name"> {crew.resource_name} </div>
          <div className="msg-title crew-number">
            {" "}
            {crew.members.length} Members{" "}
          </div>
          {user &&
          permissions.length > 0 &&
          permissions.includes("Remove participants") ? (
            <div className="member-modify">
              <a
                onClick={(e) => {
                  this.setState({ editOptions: true });
                  e.stopPropagation();
                }}
                id="msg-edit"
              >
                <img src={`${config.assetUrl}icons/msg-modify.svg`} alt="" />
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
        {editOptions && (
          <OutsideClickHandler
            onOutsideClick={() => {
              this.setState({ editOptions: false });
            }}
          >
            <div className="msg-action">
              <button
                onClick={this.removeCrew.bind(this, crew)}
                className="btn btn-default btn-delete"
              >
                <i className="fas fa-trash-alt"></i> Remove
              </button>
            </div>
          </OutsideClickHandler>
        )}
      </div>
    );
  }
}

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      editOptions: false,
      editMode: false,
      nameEdit: false,
      conversationName: "",
      date_created: "",
      memberCount: 0,
      members: [],
      crewGroups: [],
      crews: [],
    };

    this.handleTextChange = this.handleTextChange.bind(this);
    this.updateName = this.updateName.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.removeCrew = this.removeCrew.bind(this);
    this.collapseCrew = this.collapseCrew.bind(this);
  }

  handleTextChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  updateName() {
    let data = {
      id: this.state.id,
      title: this.state.conversationName,
    };

    this.props.dispatch(chatActions.updateConversation(data));

    this.setState({
      nameEdit: false,
      editMode: false,
    });
  }

  removeUser(member) {
    let { id } = this.props.chat.conversation;

    let data = {
      conversation_id: id,
      user: member.hash,
    };

    this.props.dispatch(chatActions.removeParticipant(data));
  }

  removeCrew(crew) {
    let { id } = this.props.chat.conversation;

    let data = {
      conversation_id: id,
      crew: crew.id,
    };

    this.props.dispatch(chatActions.removeParticipant(data));
  }

  collapseCrew(index) {
    let crew = this.state.crewGroups[index];
    crew.collapsed = !crew.collapsed;
    this.setState({
      crewGroups: this.state.crewGroups.map((_crew) => {
        if (_crew.id === crew.id) return crew;
        return _crew;
      }),
    });
  }

  componentDidMount() {
    let { id, data, users, crews, created_at } = this.props.chat.conversation;

    let groupedUsers = users.reduce((group, user) => {
      if (group[user.crew_identifier_id]) {
        group[user.crew_identifier_id].push(user);
      } else {
        group[user.crew_identifier_id] = [user];
      }
      return group;
    }, {});

    let crewGroups = crews
      .map((crew) => {
        return {
          ...crew,
          collapsed: false,
          members: groupedUsers[crew.id] || [],
        };
      })
      .filter((_crew) => _crew.members.length > 1);

    let individuals = [];

    Object.keys(groupedUsers).forEach((crew_id) => {
      if (groupedUsers[crew_id].length <= 1)
        individuals = [...individuals, ...groupedUsers[crew_id]];
    });

    this.setState({
      id,
      conversationName: data.title,
      crewGroups: crewGroups || [],
      members: individuals || [],
      memberCount: (users && users.length) || 0,
      date_created: created_at,
    });
  }

  componentDidUpdate(oldProps) {
    let { id, data, users, crews, created_at } = this.props.chat.conversation;

    let nextState = {};

    if (!this.state.editMode && this.props.chat != oldProps.chat) {
      let groupedUsers = users.reduce((group, user) => {
        if (group[user.crew_identifier_id]) {
          group[user.crew_identifier_id].push(user);
        } else {
          group[user.crew_identifier_id] = [user];
        }
        return group;
      }, {});

      let crewGroups = crews
        .map((crew) => {
          return {
            ...crew,
            collapsed: false,
            members: groupedUsers[crew.id] || [],
          };
        })
        .filter((_crew) => _crew.members.length > 1);

      let individuals = [];

      Object.keys(groupedUsers).forEach((crew_id) => {
        if (groupedUsers[crew_id].length <= 1)
          individuals = [...individuals, ...groupedUsers[crew_id]];
      });

      nextState = {
        id,
        conversationName: data.title,
        crewGroups: crewGroups || [],
        members: individuals || [],
        memberCount: (users && users.length) || 0,
        date_created: created_at,
      };

      if (data.type != "group" && this.props.infoActive) {
        this.props.toggleInfo();
      }

      this.setState(nextState);
    }
  }

  render() {
    let { infoActive, user } = this.props;
    let { permissions } = this.props.permission;
    let { id } = this.props.chat.conversation;
    let {
      conversationName,
      nameEdit,
      members,
      crewGroups,
      memberCount,
      date_created,
      editOptions,
    } = this.state;

    let groupDateCreated = new Date(date_created);
    groupDateCreated = groupDateCreated.toLocaleDateString(
      dateTimeConstants.LOCALE_DATE_STRING_FORMAT,
      {
        month: dateTimeConstants.TWO_DIGIT_FORMAT,
        day: dateTimeConstants.TWO_DIGIT_FORMAT,
        year: dateTimeConstants.NUMERIC_FORMAT,
      },
    );

    return (
      <div className={`msg-group-info ${infoActive ? "opened" : ""}`}>
        <div className="row msg-mob-messageheader">
          <div className="col-xs-8">
            <a
              className="mob-backbtn"
              onClick={() => {
                this.props.toggleInfo();
              }}
            >
              <img src={`${config.assetUrl}icons/close.svg`} />
            </a>
            <h3 className="title">Edit Group</h3>
          </div>
          {/* <div className="col-xs-4">
                        <ul className="admincontrols nav navbar-nav navbar-right">
                            <li><a href="#" className="msg-admincontrol"><img src={`${config.assetUrl}icons/save-tick.svg`} /></a></li>
                        </ul>
                    </div> */}
        </div>
        <button
          onClick={() => {
            this.props.toggleInfo();
          }}
          className="close-inputBtn"
          id="close-edit"
        >
          <img src={`${config.assetUrl}icons/close.svg`} alt="" />
        </button>
        <div className="group-info-header">
          <img src={`${config.assetUrl}icons/info-icon.svg`} alt="" /> Group
          Info{" "}
        </div>
        <div className="group-titles">
          <img
            src={`${config.assetUrl}icons/group-info-icon.png`}
            className="group-image"
            alt=""
          />
          {nameEdit ? (
            <form
              className="group-titleEdit"
              id="groupEdit"
              onSubmit={this.updateName}
            >
              <input
                type="text"
                className="form-control"
                name="conversationName"
                value={conversationName}
                onChange={this.handleTextChange}
              />
              <button className="btn msg-btn">Save</button>
            </form>
          ) : (
            <h3 className="group-title">
              {" "}
              {conversationName}{" "}
              <a
                onClick={() => {
                  this.setState({ nameEdit: true, editMode: true });
                }}
                className="groupname-edit"
              >
                <img src={`${config.assetUrl}icons/edit_icon_grey.svg`} />
              </a>
            </h3>
          )}
          <h4 className="group-title">Created on: {groupDateCreated}</h4>
        </div>
        <h3 className="membercount">
          Members <span>{memberCount}</span>
          {/* <a href="#" className="groupmember-edit pull-right"><i className="fa fa-edit"></i></a> */}
        </h3>
        {user.profile && permissions ? (
          <div className="group-member-list">
            {permissions && permissions.data.includes("Add participants") ? (
              <div className="msg-addUser">
                <Link to={`/chat/edit-group/${id}`} className="add">
                  {" "}
                  <img
                    src={`${config.assetUrl}icons/add-user.svg`}
                    alt=""
                  />{" "}
                  Add Members{" "}
                </Link>
              </div>
            ) : (
              ""
            )}
            {crewGroups.length > 0 &&
              crewGroups.map((_crew, index) => {
                return (
                  <React.Fragment key={`crew${index}`}>
                    <Crew
                      key={index}
                      index={index}
                      user={user.profile.data}
                      crew={_crew}
                      permissions={permissions.data}
                      removeCrew={this.removeCrew}
                      collapseCrew={this.collapseCrew}
                    />
                    <div
                      className={`crew-members ${_crew.collapsed ? "toggled" : ""}`}
                    >
                      {_crew.members.length > 0 &&
                        _crew.members.map((_member, index) => {
                          return (
                            <User
                              key={index}
                              user={user.profile.data}
                              permissions={permissions.data}
                              member={_member}
                              removeUser={this.removeUser}
                            />
                          );
                        })}
                    </div>
                  </React.Fragment>
                );
              })}
            {members.length > 0 &&
              members.map((member, index) => {
                return (
                  <User
                    key={index}
                    user={user.profile.data}
                    permissions={permissions.data}
                    member={member}
                    removeUser={this.removeUser}
                  />
                );
              })}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { chat, user, permission } = state;
  return { chat, user, permission };
}

export default connect(mapStateToProps)(Info);
