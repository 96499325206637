import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import footerLogo from "../assets/images/firestorm.png";
// import { userActions } from '../_actions';

class ResourceType extends React.Component {
  constructor(props) {
    super(props);
    this.choosePosition = this.choosePosition.bind(this);
  }

  choosePosition(type) {
    this.props.choosePosition(type);
  }

  render() {
    return (
      <div className="resource_type">
        <div className="login_header">
          <Link to={"/login"}>
            <img src={footerLogo} alt="Firestorm" />
          </Link>
          <p>Employee Management System</p>
        </div>

        <div className="top_head">
          <Link to={"/login"} className="back">
            Back to Login Page
          </Link>
          <span className="title">Step 1 - Choose Resource Type</span>
        </div>

        <div className="signup-contentarea">
          <div id="choosePositionWrapA0" className="position-select">
            <button onClick={this.choosePosition.bind(this, "FireFighter")}>
              <span className="pos-sel">Firefighter Resource</span>
            </button>
            <button onClick={this.choosePosition.bind(this, "AdminStaff")}>
              <span className="pos-sel">Admin Staff Resource</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering } = state.registration;
  return {
    registering,
  };
}

const connectedRegisterPage = connect(mapStateToProps)(ResourceType);
export { connectedRegisterPage as ResourceType };
