import { qualFieldValidationError } from "_helpers/reduxSelector";
import { qualificationConstants } from "../_constants";

export function qualification(state = {}, action) {
  switch (action.type) {
    case qualificationConstants.REMOVE_QUALIFICATION_FROM_LIST:
      return {
        ...state,
        qualificationList: state.qualificationList.filter(
          (qualification) =>
            qualification.id !== action.payload.qualificationId,
        ),
      };
    case qualificationConstants.ALL_QUALIFICATIONS_ERROR:
      return {
        ...state,
        isQualificationListLoading: false,
        isQualificationListError: action.error,
      };
    case qualificationConstants.ALL_QUALIFICATIONS_SUCCESS:
      return {
        ...state,
        isQualificationListLoading: false,
        qualificationList: action.data.data,
      };
    case qualificationConstants.ALL_QUALIFICATIONS_REQUEST:
      return {
        ...state,
        isQualificationListLoading: true,
      };
    case qualificationConstants.DELETE_QUALIFICATION_ERROR:
      return {
        ...state,
        isDeleteQualificationLoading: false,
        isDeleteQualificationError: action.error,
      };
    case qualificationConstants.DELETE_QUALIFICATION_SUCCESS:
      return {
        ...state,
        isDeleteQualificationError: false,
        isDeleteQualificationLoading: false,
        qualificationList: state.qualificationList.filter(
          (qualification) => qualification.id !== action.payload.qualId,
        ),
      };
    case qualificationConstants.DELETE_QUALIFICATION_REQUEST:
      return {
        ...state,
        isDeleteQualificationError: null,
        isDeleteQualificationLoading: true,
      };
    case qualificationConstants.EDIT_QUALIFICATION_ERROR:
      const {
        validation_error: edit_validation_error,
        message: editFieldValidationError,
      } = JSON.parse(action.error);
      let editErrorData = null;
      if (edit_validation_error) {
        editErrorData = editFieldValidationError;
      }
      return {
        ...state,
        isEditQualificationLoading: false,
        isEditQualificationError: action.error,
        qualFieldValidationError: editErrorData,
      };
    case qualificationConstants.EDIT_QUALIFICATION_SUCCESS:
      const { id, data } = action.payload;

      // Find the index of the qualification with the matching ID
      const index = state.qualificationList.findIndex(
        (qualification) => qualification.id === id,
      );

      // If the qualification is found
      if (index !== -1) {
        // Update the properties of the existing qualification with the new data
        state.qualificationList[index] = {
          ...state.qualificationList[index],
          ...data,
        };

        // Re-order the qualification list based on the updated position key
        state.qualificationList.sort((a, b) => a.position - b.position);

        // Return the updated state
        return {
          ...state,
          isEditQualificationError: false,
          isEditQualificationLoading: false,
          qualificationList: [...state.qualificationList], // Make sure to create a new array to trigger React re-render
        };
      }
      // If the qualification is not found, return the state without any changes
      return state;
    case qualificationConstants.EDIT_QUALIFICATION_REQUEST:
      return {
        ...state,
        isEditQualificationError: null,
        isEditQualificationLoading: true,
      };
    case qualificationConstants.CREATE_QUALIFICATION_ERROR:
      const {
        validation_error: create_validation_error,
        message: createFieldValidationError,
      } = JSON.parse(action.error);
      let createErrorData = null;
      if (create_validation_error) {
        createErrorData = createFieldValidationError;
      }
      return {
        ...state,
        isCreateQualificationLoading: false,
        isCreateQualificationError: action.error,
        qualFieldValidationError: createErrorData,
      };
    case qualificationConstants.CREATE_QUALIFICATION_SUCCESS:
      const { qual } = action.payload;

      // Insert the new qualification data as a new object into the qualificationList
      state.qualificationList.push(qual);

      // Re-order the qualification list based on the position key
      state.qualificationList.sort((a, b) => a.position - b.position);

      // Return the updated state
      return {
        ...state,
        isCreateQualificationLoading: false,
        isCreateQualificationError: false,
        qualificationList: [...state.qualificationList],
      };
    case qualificationConstants.CREATE_QUALIFICATION_REQUEST:
      return {
        ...state,
        isCreateQualificationError: null,
        isCreateQualificationLoading: true,
      };
    case qualificationConstants.RESET_QUALIFICATION_ERROR:
      return {
        ...state,
        isDeleteQualificationError: null,
        isCreateQualificationError: null,
        isEditQualificationError: null,
      };
    default:
      return state;
  }
}
