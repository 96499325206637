import React, { useEffect, useState } from 'react';
import './Profile.scss';
import MedicalInformationComponent from './MedicalInformationComponent';
import ContactInformationComponent from './ContactInformationComponent';
import WorkersCompensationComponent from './WorkersCompensationComponent';
import NotesComponent from './NotesComponent';
import { useQuery } from 'react-query';
import { profileService } from '_services/profile.service';
import DriverQualificationsComponent from './DriverQualificationsComponent';
import AdministrationComponent from './AdministrationComponent';
import QualificationsComponent from './QualificationsComponent';
import InventoryAssignedComponent from './InventoryAssignedComponent';
import ImportantDatesComponent from './ImportantDatesComponent';
import VerifiedDocumentsComponent from './VerifiedDocumentsComponent';
import EmploymentHistoryComponent from './EmploymentHistoryComponent';
import CardComponent from './CardComponent';

const ViewProfile = ({
  userHash,
  authUser,
  permList,
  changeTab,
  reloadKey,
}) => {
  // Fetch dropdown data
  const { data: dropdownData } = useQuery(
    'dropdownData',
    profileService.fetchDropdownData,
  );

  useEffect(() => {
    // Fetch data or trigger update whenever reloadKey changes
    refetchUser();
    refetchHistory();
  }, [reloadKey]);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveDivision(`#${entry.target.id}`);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '-80px 0px -80% 0px',
    });

    const querySections = () => {
      const sections = document.querySelectorAll('.profile-data_comp');
      if (sections.length > 0) {
        sections.forEach((section) => observer.observe(section));
      } else {
        setTimeout(querySections, 500); // Retry every 500ms
      }
    };

    // Call the querySections function to check for sections
    querySections();

    // Cleanup observer on component unmount
    return () => observer.disconnect();
  }, []);

  const [activeDivision, setActiveDivision] = useState('#administration');
  const [expandedComponents, setExpandedComponents] = useState([]);

  const divisions = [
    {
      href: '#administration',
      title: 'Administration',
      text: 'Administration',
    },
    {
      href: '#contact-information',
      title: 'Contact Information',
      text: 'Contact Information',
    },
    {
      href: '#medical-information',
      title: 'Medical Information',
      text: 'Medical Information',
      permission: 'Manage Medical Information',
      ownProfile: true,
    },
    {
      href: '#important-dates',
      title: 'Important Dates',
      text: 'Important Dates',
      permission: 'Manage Expiry/Assigned Dates',
      ownProfile: true,
    },
    {
      href: '#driver-qualifications',
      title: 'Driver Qualifications',
      text: 'Driver Qualifications',
      permission: 'Manage Expiry/Assigned Dates',
      ownProfile: true,
    },
    {
      href: '#qualifications',
      title: 'Qualifications',
      text: 'Qualifications',
      permission: 'Manage Qualifications',
      ownProfile: true,
    },
    {
      href: '#inventory-assigned',
      title: 'Inventory Assigned',
      text: 'Inventory Assigned',
      permission: 'Inventory',
      ownProfile: true,
    },
    {
      href: '#workers-compensation',
      title: "Workers' Compensation",
      text: "Workers' Compensation",
      permission: 'Workers Compensation',
      ownProfile: false,
    },
    {
      href: '#verified-documents',
      title: 'Verified Documents',
      text: 'Verified Documents',
      permission: 'Manage Expiry/Assigned Dates',
      ownProfile: true,
    },
    {
      href: '#employment-history',
      title: 'Employment History',
      text: 'Employment History',
      permission: 'Manage Expiry/Assigned Dates',
      ownProfile: true,
    },
  ];

  const changeActiveDivision = (divisionHref) => {
    // Scroll to the corresponding section with smooth scrolling and offset
    const targetSection = document.querySelector(divisionHref);
    const offset = 86; // Height of the top bar or header

    if (targetSection) {
      const sectionPosition =
        targetSection.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({
        top: sectionPosition,
        behavior: 'smooth',
      });
    }
    setExpandedComponents((prev) =>
      prev.includes(divisionHref) ? prev : [...prev, divisionHref],
    );
  };

  const collapseAllComponents = () => {
    setActiveDivision('#administration');
    setExpandedComponents([]);
  };

  const expandAllComponents = () => {
    setActiveDivision('#administration');
    setExpandedComponents(divisions.map((division) => division.href));
  };

  const toggleAccordion = (divisionHref) => {
    setExpandedComponents((prev) =>
      prev.includes(divisionHref)
        ? prev.filter((item) => item !== divisionHref)
        : [...prev, divisionHref],
    );
  };

  // Fetch profile data, passing the function reference, not the result of the function call
  const {
    data: profileData,
    refetch: refetchUser,
    isSuccess: isProfileDataFetched,
  } = useQuery(
    ['userData', userHash], // Unique key including userHash to prevent conflicts
    () => profileService.getUser(userHash), // Pass function reference, not the result
    {
      enabled: !!userHash, // Ensure query only runs when userHash is available
    },
  );

  const ownProfile = profileData?.id == authUser?.id;

  const { data: inventoryAssignedData } = useQuery(
    ['inventoryAssignedUserData', profileData?.hash],
    () => profileService.getInventoryAssignedData(profileData?.hash),
    {
      enabled: isProfileDataFetched && !!profileData?.hash,
    },
  );

  const { data: historyData, refetch: refetchHistory } = useQuery(
    ['historyUserData', profileData?.id], // Use `id` instead of `hash` for uniqueness
    async () => {
      if (!profileData?.id) return Promise.reject('Profile ID is missing'); // Prevent API call
      return profileService.getEmploymentHistoryData(profileData.id);
    },
    {
      enabled: isProfileDataFetched && !!profileData?.id, // Ensure `id` exists before enabling query
    },
  );

  let profileUserName = '';
  if (profileData) {
    // User name
    profileUserName =
      profileData.first_name &&
      profileData.first_name +
        ' ' +
        (profileData.middle_name ? profileData.middle_name + ' ' : '') +
        profileData.last_name;
  }

  return (
    <div className='ft profile-data'>
      <div className='profile-data__cnt-wrap'>
        <nav className='profile-data__nav'>
          <div className='profile-data__nav-wrap'>
            <ul role='list' className='profile-divisions'>
              {permList && profileData && (
                <>
                  {divisions.map((division, index) =>
                    !division.permission ||
                    (division.permission &&
                      permList.includes(division.permission)) ||
                    (division.ownProfile && ownProfile) ? (
                      <li
                        key={index}
                        className={
                          division.href === activeDivision ? 'active' : ''
                        }
                      >
                        <a
                          onClick={() => changeActiveDivision(division.href)}
                          title={division.title}
                          className='division-button'
                        >
                          {division.text}
                        </a>
                      </li>
                    ) : null,
                  )}
                </>
              )}
            </ul>
            {permList && profileData && (
              <button
                type='button'
                onClick={
                  expandedComponents.length
                    ? collapseAllComponents
                    : expandAllComponents
                }
              >
                {expandedComponents.length ? 'Collapse' : 'Expand'} All
              </button>
            )}
          </div>
        </nav>
        <div className='profile-data__cnt'>
          {!profileData && (
            <div className='dashboard_expiration_date_no_data'>
              <img
                className='loader'
                alt='Loader'
                src={require('../../assets/images/loader.gif').default}
              />
            </div>
          )}
          {profileData && dropdownData && (
            <div className='profile-data__col'>
              <AdministrationComponent
                profileData={profileData}
                dropdownData={dropdownData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes('#administration')}
                toggleAccordion={toggleAccordion}
                permList={permList}
                authUser={authUser}
              />

              <ContactInformationComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes('#contact-information')}
                toggleAccordion={toggleAccordion}
                permList={permList}
                authUser={authUser}
              />

              {(permList.includes('Manage Medical Information') ||
                ownProfile) && (
                <MedicalInformationComponent
                  profileData={profileData}
                  refetchUser={refetchUser}
                  isOpen={expandedComponents.includes('#medical-information')}
                  toggleAccordion={toggleAccordion}
                  permList={permList}
                  authUser={authUser}
                />
              )}

              {(permList.includes('Manage Expiry/Assigned Dates') ||
                ownProfile) && (
                <>
                  <ImportantDatesComponent
                    profileData={profileData}
                    refetchUser={refetchUser}
                    isOpen={expandedComponents.includes('#important-dates')}
                    toggleAccordion={toggleAccordion}
                    permList={permList}
                    authUser={authUser}
                  />

                  <DriverQualificationsComponent
                    profileData={profileData}
                    refetchUser={refetchUser}
                    isOpen={expandedComponents.includes(
                      '#driver-qualifications',
                    )}
                    toggleAccordion={toggleAccordion}
                    permList={permList}
                    authUser={authUser}
                  />
                </>
              )}

              {(permList.includes('Manage Qualifications') || ownProfile) && (
                <QualificationsComponent
                  profileData={profileData}
                  dropdownData={dropdownData}
                  isOpen={expandedComponents.includes('#qualifications')}
                  refetchUser={refetchUser}
                  toggleAccordion={toggleAccordion}
                  changeTab={changeTab}
                  permList={permList}
                  authUser={authUser}
                />
              )}

              {(permList.includes('Inventory') || ownProfile) && (
                <InventoryAssignedComponent
                  profileData={profileData}
                  inventoryData={inventoryAssignedData?.data}
                  isOpen={expandedComponents.includes('#inventory-assigned')}
                  toggleAccordion={toggleAccordion}
                  permList={permList}
                  authUser={authUser}
                />
              )}

              {permList.includes('Workers Compensation') && (
                <WorkersCompensationComponent
                  profileData={profileData}
                  refetchUser={refetchUser}
                  isOpen={expandedComponents.includes('#workers-compensation')}
                  toggleAccordion={toggleAccordion}
                />
              )}

              {(permList.includes('Manage Expiry/Assigned Dates') ||
                ownProfile) && (
                <>
                  <VerifiedDocumentsComponent
                    profileData={profileData}
                    refetchUser={refetchUser}
                    isOpen={expandedComponents.includes('#verified-documents')}
                    toggleAccordion={toggleAccordion}
                    permList={permList}
                    authUser={authUser}
                  />
                  <EmploymentHistoryComponent
                    profileData={profileData}
                    refetchUser={refetchUser}
                    refetchHistory={refetchHistory}
                    historyData={historyData?.data}
                    isOpen={expandedComponents.includes('#employment-history')}
                    toggleAccordion={toggleAccordion}
                    permList={permList}
                    authUser={authUser}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Profile Data Card */}
      {profileData && (
        <div className='profile-data__card-wrap'>
          <div className='profile-data__card-container'>
            <CardComponent
              profileData={profileData}
              profileUserName={profileUserName}
              refetchUser={refetchUser}
              permList={permList}
              authUser={authUser}
            />

            {permList.includes('Employee Notes') && (
              <NotesComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes('#notes')}
                toggleAccordion={toggleAccordion}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewProfile;
