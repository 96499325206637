import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import ReactModal from "react-modal";
import {
  dashboardActions,
  createProfileActions,
  assignmentStatsActions,
} from "_actions";
import { selectEmployeesFireHistoryReportLoading } from "_helpers/reduxSelector";
import { toast } from "react-toastify";
import {
  formatDate,
  handleErrorResponse,
  preventNegativeValueInDocs,
} from "_helpers/helpers";
import deleteIcon from "../assets/icons/delete-btn.svg";
import moment from "moment";
import DatePicker from "react-datepicker";
import Modal from "_components/utils/Modal/Modal";
import Select from "react-select";
import {
  selectCurrentUserPermissions,
  getAssignmentStats,
  getAssignmentStatusTypes,
  selectQualifications,
} from "_helpers/reduxSelector";

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  employeeClass: ASC_CLASS,
  assignmentTypeClass: ASC_CLASS,
  incidentNameClass: ASC_CLASS,
  shiftsClass: ASC_CLASS,
  locationClass: ASC_CLASS,
  fireSizeClass: ASC_CLASS,
  complexityClass: ASC_CLASS,
  fuelTypeClass: ASC_CLASS,
  hotlineClass: ASC_CLASS,
  dateOnClass: ASC_CLASS,
  dateOffClass: ASC_CLASS,
};

const datePickerPopperModifiers = [
  {
    name: "offset",
    options: {
      offset: [5, 10],
    },
  },
  {
    name: "customStyle",
    enabled: true,
    fn: (data) => {
      data.styles.width = "300px";
      return data;
    },
  },
];

const API_req_date_format = "YYYY-MM-DD";

const FireHistoryReportWithEmployees = ({
  getFireHistoryByHash,
  fireHistoryReport,
  handleDeleteItem,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectEmployeesFireHistoryReportLoading);
  const permList = useSelector(selectCurrentUserPermissions);
  const data = fireHistoryReport?.data ?? [];
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState(null);
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("id");
  const [page, setPage] = useState(0);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [selectedHistoryItem, setSelectedHistoryItem] = useState({
    id: "",
    name: "",
    assignmentType: "",
    fuelType: null,
    fireSize: null,
    complexity: null,
    shifts: "",
    hotline: "",
    startDate: "",
    endDate: "",
    incident: "",
    location: "",
  });

  const [sortArrowStyle, setStyleState] = useState(initialStyleState);
  const {
    employeeClass,
    assignmentTypeClass,
    incidentNameClass,
    locationClass,
    fireSizeClass,
    fuelTypeClass,
    dateOffClass,
    dateOnClass,
    complexityClass,
    shiftsClass,
    hotlineClass,
  } = sortArrowStyle;

  const handleOnWheel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.blur();
  };

  const handlePageChange = (newPage) => {
    const sortOrder = order ? "asc" : "desc";
    getFireHistoryByHash(newPage?.selected + 1, sortItem || "id", sortOrder);
    setPage(newPage?.selected + 1);
  };

  const getData = () => {
    dispatch(assignmentStatsActions.getAssignmentComplexity());
    dispatch(assignmentStatsActions.getAssignmentFireSize());
    dispatch(assignmentStatsActions.getAssignmentFuelType());
    dispatch(assignmentStatsActions.getAssignemntStatusTypes());
  };

  const assignmentStats = useSelector(getAssignmentStats);
  let assignmentStatusTypesOptions = useSelector(getAssignmentStatusTypes);
  assignmentStatusTypesOptions = assignmentStatusTypesOptions?.map(
    (option) => ({
      value: option.id,
      label: option.value,
    })
  );

  let listComplexity = assignmentStats?.persist?.complexity;
  listComplexity = listComplexity.map((option) => ({
    value: option.value,
    label: option.value,
  }));

  let listFuelType = assignmentStats?.persist?.fuelType;
  listFuelType = listFuelType.map((option) => ({
    value: option.value,
    label: option.value + "- " + option.label,
  }));

  let listFireSize = assignmentStats?.persist?.fireSize;
  listFireSize = listFireSize.map((option) => ({
    value: option.value,
    label: option.value + "- " + option.label,
  }));

  const qualifications = useSelector(selectQualifications)?.map((qual) => ({
    ...qual,
    label: qual?.key,
    value: qual.id,
  }));

  const verifyItem = async (id) => {
    const res = await dispatch(dashboardActions.verifyFireHistory(id));
    if (res?.success) {
      toast.success(
        "The fire history has been verified and moved to fire history."
      );
      fetchFireHistory();
    } else {
      toast.error("Failed to verify fire history.");
    }
  };

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  const onClickSelectHistoryItem = (selected) => {
    setSelectedHistoryItem({
      id: selected?.assignment_history_user_id,
      name: selected?.first_name + " " + selected?.last_name,
      assignmentType: selected.assignment_type
        ? assignmentStatusTypesOptions.find(
            (status) => status.label === selected.assignment_type
          )
        : null,
      fireSize: selected.fire_size
        ? listFireSize.find((fireSize) => fireSize.value === selected.fire_size)
        : null,
      fuelType: selected.fuel_type
        ? listFuelType.find((fuelType) => fuelType.value === selected.fuel_type)
        : null,
      complexity: selected.complexity
        ? listComplexity.find(
            (complexity) => complexity.label === selected.complexity
          )
        : null,
      shifts: selected?.shifts,
      hotline: selected?.hotline_shifts,
      incident: selected?.incident_name || null,
      location: selected?.location || null,
      startDate: moment(selected?.date_on).isValid()
        ? moment(selected?.date_on)
        : "",
      endDate: moment(selected?.date_off).isValid()
        ? moment(selected?.date_off)
        : "",
      is_verified: selected?.is_verified,
    });
    setEditModalOpen(true);
  };

  const onChangeEditHistoryItem = (name, value) => {
    setSelectedHistoryItem((prevState) => {
      if (name === "qualSelect") {
        return { ...prevState, qual: { value } };
      } else if (name === "complexity") {
        return { ...prevState, complexity: { value } };
      } else if (name === "assignmentType") {
        return { ...prevState, assignmentType: { value } };
      } else if (name === "fireSize") {
        return { ...prevState, fireSize: { value } };
      } else if (name === "fuelType") {
        return { ...prevState, fuelType: { value } };
      } else if (name === "startDate" || name === "endDate") {
        const newStartDate = name === "startDate" ? value : prevState.startDate;
        const newEndDate = name === "endDate" ? value : prevState.endDate;

        const diffInDays = newEndDate.diff(newStartDate, "days") + 1;

        return {
          ...prevState,
          startDate: newStartDate,
          endDate: newEndDate,
          shifts: diffInDays, // or any other key you want to set for the difference
        };
      } else {
        return { ...prevState, [name]: value };
      }
    });
  };

  const updateHistoryItem = async (e) => {
    e.preventDefault();
    if (!selectedHistoryItem?.hotline) {
      return toast.error("Hotline Shifts is a required field.");
    }
    const reqData = new FormData();
    reqData.append("id", selectedHistoryItem?.id || "");
    reqData.append(
      "assignment_type",
      selectedHistoryItem?.assignmentType?.value?.value ||
        selectedHistoryItem?.assignmentType?.value ||
        ""
    );
    reqData.append("incident", selectedHistoryItem?.incident || "");
    reqData.append("location", selectedHistoryItem?.location || "");
    reqData.append(
      "complexity",
      selectedHistoryItem?.complexity?.value?.value ||
        selectedHistoryItem?.complexity?.value ||
        ""
    );
    reqData.append(
      "fuel_type",
      selectedHistoryItem?.fuelType?.value?.value ||
        selectedHistoryItem?.fuelType?.value ||
        ""
    );
    reqData.append(
      "fire_size",
      selectedHistoryItem?.fireSize?.value?.value ||
        selectedHistoryItem?.fireSize?.value ||
        ""
    );
    reqData.append(
      "qualification",
      selectedHistoryItem?.qual?.value?.value ||
        selectedHistoryItem?.qual?.value ||
        ""
    );
    reqData.append("shifts", selectedHistoryItem?.shifts || "");
    reqData.append("hotline_shifts", selectedHistoryItem?.hotline || "");
    reqData.append(
      "date_on",
      selectedHistoryItem.startDate
        ? moment(selectedHistoryItem.startDate).format(API_req_date_format)
        : ""
    );
    reqData.append(
      "date_off",
      selectedHistoryItem.endDate
        ? moment(selectedHistoryItem.endDate).format(API_req_date_format)
        : ""
    );

    const res = await dispatch(
      dashboardActions.updateIndividualFireHistoryEntry(reqData)
    );
    if (res.success) {
      toast.success("Fire history updated successfully");
      setEditModalOpen(false);
      fetchFireHistory();
    } else {
      handleErrorResponse(res);
    }
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedHistoryItem({
      id: "",
      name: "",
      assignmentType: "",
      fuelType: null,
      fireSize: null,
      complexity: null,
      qual: null,
      shifts: "",
      hotline: "",
      startDate: "",
      endDate: "",
      incident: "",
      location: "",
      is_verified: 0,
      qual: 0,
    });
  };

  const fetchFireHistory = () => {
    const sortOrder = order ? "asc" : "desc";
    getFireHistoryByHash(page, sortItem, sortOrder);
  };

  useEffect(() => {
    getData();
    if (sortItem) {
      fetchFireHistory();
    }
    dispatch(createProfileActions.getAll());
  }, [sortItem, order]);

  return (
    <div className="history_lists outer_list">
      <Modal
        isOpen={editModalOpen}
        className="update-applicant-status-modal incident-center"
      >
        <div className="modal-head">
          <h4>EDIT FIRE HISTORY</h4>
        </div>
        <form onSubmit={updateHistoryItem}>
          <div className="modal-body applicant-status-modal-body scroll-incident">
            <div className="modify-item">
              <p>Employee Name</p>
              <input
                name="assignmentType"
                value={selectedHistoryItem?.name}
                disabled
              />
            </div>
            <div className="modify-item">
              <p>Incident Name</p>

              <input
                name="incident"
                value={selectedHistoryItem?.incident}
                disabled={selectedHistoryItem?.is_verified != 3}
              />
            </div>
            <div className="modify-item">
              <p>Assignment Type</p>
              {selectedHistoryItem?.is_verified != 3 ? (
                <input
                  name="assignmentType"
                  value={selectedHistoryItem?.assignmentType?.label}
                  disabled={selectedHistoryItem?.is_verified != 3}
                />
              ) : (
                <Select
                  options={assignmentStatusTypesOptions}
                  name="assignmentType"
                  defaultValue={selectedHistoryItem.assignmentType}
                  onChange={(option) =>
                    onChangeEditHistoryItem("assignmentType", option)
                  }
                />
              )}
            </div>
            <div className="modify-item">
              <p>Location</p>
              <input
                name="location"
                value={selectedHistoryItem?.location}
                disabled={selectedHistoryItem?.is_verified != 3}
              />
            </div>
            <div className="fh-flex-container">
              <div className="modify-item">
                <p>Complexity</p>
                {selectedHistoryItem?.is_verified != 3 ? (
                  <input
                    name="complexity"
                    value={selectedHistoryItem?.complexity?.label}
                    disabled={selectedHistoryItem?.is_verified != 3}
                  />
                ) : (
                  <Select
                    options={listComplexity}
                    name="complexity"
                    defaultValue={selectedHistoryItem.complexity}
                    onChange={(option) =>
                      onChangeEditHistoryItem("complexity", option)
                    }
                  />
                )}
              </div>
              <div className="modify-item">
                <p>Fuel Type</p>
                {selectedHistoryItem?.is_verified != 3 ? (
                  <input
                    name="fuelType"
                    value={selectedHistoryItem?.fuelType?.label}
                    disabled={selectedHistoryItem?.is_verified != 3}
                  />
                ) : (
                  <Select
                    options={listFuelType}
                    name="fuelType"
                    defaultValue={selectedHistoryItem.fuelType}
                    onChange={(option) =>
                      onChangeEditHistoryItem("fuelType", option)
                    }
                  />
                )}
              </div>

              <div className="modify-item">
                <p>Fire Size</p>
                {selectedHistoryItem?.is_verified != 3 ? (
                  <input
                    name="fireSize"
                    value={selectedHistoryItem?.fireSize?.label}
                    disabled={selectedHistoryItem?.is_verified != 3}
                  />
                ) : (
                  <Select
                    options={listFireSize}
                    name="fireSize"
                    defaultValue={selectedHistoryItem.fireSize}
                    onChange={(option) =>
                      onChangeEditHistoryItem("fireSize", option)
                    }
                  />
                )}
              </div>
            </div>
            <div className="fh-flex-container">
              <div className="modify-item">
                <p>Shifts</p>
                <input
                  name="shifts"
                  value={selectedHistoryItem?.shifts}
                  type="number"
                  disabled
                  required
                />
              </div>

              <div className="modify-item">
                <p>Hotline Shifts</p>
                <input
                  type="number"
                  min={0}
                  onWheel={handleOnWheel}
                  name="hotline"
                  value={selectedHistoryItem?.hotline}
                  onChange={(input) =>
                    onChangeEditHistoryItem("hotline", input.target.value)
                  }
                  onKeyDown={preventNegativeValueInDocs}
                  required
                />
              </div>
            </div>
            <div className="fh-flex-container">
              <div className="modify-item">
                <p>Start Date</p>
                {selectedHistoryItem?.is_verified != 3 ? (
                  <input
                    disabled
                    name="startDate"
                    value={
                      selectedHistoryItem?.startDate
                        ? formatDate(selectedHistoryItem?.startDate)
                        : ""
                    }
                  />
                ) : (
                  <DatePicker
                    name="startDate"
                    selected={
                      selectedHistoryItem?.startDate
                        ? moment(selectedHistoryItem?.startDate)
                        : ""
                    }
                    onChange={(date) =>
                      onChangeEditHistoryItem("startDate", date)
                    }
                    onKeyDown={(e) => e.preventDefault()}
                    popperClassName="some-custom-class"
                    popperModifiers={datePickerPopperModifiers}
                    showMonthDropdown
                    showYearDropdown
                    isClearable
                  />
                )}
              </div>
              <div className="modify-item">
                <p>End Date</p>
                {selectedHistoryItem?.is_verified != 3 ? (
                  <input
                    disabled
                    name="endDate"
                    value={
                      selectedHistoryItem?.endDate
                        ? formatDate(selectedHistoryItem?.endDate)
                        : ""
                    }
                  />
                ) : (
                  <DatePicker
                    name="endDate"
                    selected={
                      selectedHistoryItem?.endDate
                        ? moment(selectedHistoryItem?.endDate)
                        : ""
                    }
                    onChange={(date) =>
                      onChangeEditHistoryItem("endDate", date)
                    }
                    onKeyDown={(e) => e.preventDefault()}
                    popperModifiers={datePickerPopperModifiers}
                    showMonthDropdown
                    showYearDropdown
                    isClearable
                    minDate={selectedHistoryItem?.startDate}
                  />
                )}
              </div>
            </div>

            <>
              <div className="button-holder button-holder--right">
                <button
                  disabled={false}
                  className="button grey"
                  onClick={handleCloseEditModal}
                >
                  Cancel
                </button>
                <button className="button" type="submit" disabled={isLoading}>
                  Update
                </button>
              </div>
            </>
          </div>
        </form>
      </Modal>
      <table className="mobile_hide report_list_width_full long__report tab_hide expiry_report">
        <tr className="report_list_table_row">
          <th
            onClick={() =>
              sortList("employeeClass", employeeClass, "first_name")
            }
          >
            <div className="report_table_header">
              <span>Employee</span>
              <span className={employeeClass} />
            </div>
          </th>
          <th
            onClick={() =>
              sortList(
                "assignmentTypeClass",
                assignmentTypeClass,
                "assignment_type"
              )
            }
          >
            <div className="report_table_header">
              <span>Type</span>
              <span className={assignmentTypeClass} />
            </div>
          </th>
          <th onClick={() =>
              sortList("incidentNameClass", incidentNameClass, "incident_name")
            }>
            <div className="report_table_header">
              <span>Event Name</span>
              <span className={incidentNameClass} />
            </div>
          </th>
          <th onClick={() => sortList("locationClass", locationClass, "location")}>
            <div className="report_table_header">
              <span>Location</span>
              <span className={locationClass} />
            </div>
          </th>
          <th onClick={() =>
              sortList("complexityClass", complexityClass, "complexity")
            }>
            <div className="report_table_header">
              <span>Complexity (1,2,3,4,5)</span>
              <span className={complexityClass} />
            </div>
          </th>
          <th onClick={() =>
              sortList("fuelTypeClass", fuelTypeClass, "fuel_type")
            }>
            <div className="report_table_header">
              <span>Fuel Type</span>
              <span className={fuelTypeClass} />
            </div>
          </th>
          <th onClick={() =>
              sortList("fireSizeClass", fireSizeClass, "fire_size")
            }>
            <div className="report_table_header">
              <span>Fire Size</span>
              <span className={fireSizeClass} />

            </div>
          </th>
          <th onClick={() => sortList("dateOnClass", dateOnClass, "date_on")}>
            <div className="report_table_header">
              <span>Start Date</span>
              <span className={dateOnClass} />
            </div>
          </th>
          <th onClick={() => sortList("dateOffClass", dateOffClass, "date_off")}>
            <div className="report_table_header">
              <span>End Date</span>
              <span className={dateOffClass} />
            </div>
          </th>
          <th onClick={() => sortList("shiftsClass", shiftsClass, "shifts")}>
            <div className="report_table_header">
              <span>Shifts</span>
              <span className={shiftsClass} />
            </div>
          </th>
          <th onClick={() => sortList("hotlineClass", hotlineClass, "hotline")}>
            <div className="report_table_header">
              <span>Hotline Shifts</span>
              <span className={hotlineClass} />
            </div>
          </th>
          {permList.includes("Edit Assignment History") && (
            <th colSpan={3}>
              <div className="report_table_header" />
            </th>
          )}
        </tr>
        <tbody className="report_list_table_tbody">
          {isLoading ? (
            <tr>
              <td colSpan={10}>
                <div className="dashboard_expiration_date_no_data">
                  Loading..
                </div>
              </td>
            </tr>
          ) : data?.length ? (
            data.map((item, index) => (
              <tr key={index}>
                <td>{`${item?.first_name ?? ""} ${item?.last_name}`}</td>
                <td>{item?.assignment_type ?? "-"}</td>
                <td>{item?.incident_name ?? "-"}</td>
                <td>{item?.location ?? "-"}</td>
                <td>{item?.complexity ?? "-"}</td>
                <td>{item?.fuel_type ?? "-"}</td>
                <td>{item?.fire_size ?? "-"}</td>
                <td>{formatDate(item?.date_on) ?? "-"}</td>
                <td>{formatDate(item?.date_off) ?? "-"}</td>
                <td>{item?.shifts ?? "-"}</td>
                <td>{item?.hotline_shifts ?? "-"}</td>
                {permList.includes("Edit Assignment History") && (
                  <>
                    <td
                      onClick={() => onClickSelectHistoryItem(item)}
                      className="edit-button-fire-history"
                    >
                      Edit
                    </td>

                    {item.is_verified == 3 ? (
                      <>
                        <td
                          className="edit-button-fire-history"
                          onClick={() => {
                            setDeleteConfirmModal(true);
                            setItemToDelete(item);
                          }}
                        >
                          Delete
                        </td>

                        <td
                          onClick={() =>
                            verifyItem(item?.assignment_history_user_id)
                          }
                          className="edit-button-fire-history"
                        >
                          Verify
                        </td>
                      </>
                    ) : (
                      <>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </>
                )}
              </tr>
            ))
          ) : (
            !isLoading && (
              <tr>
                <td colSpan={10}>
                  <div className="dashboard_expiration_date_no_data">
                    No Data Found
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>

      {data?.length && !isLoading ? (
        <>
          <FireHistoryReportWithEmployeesMobileView
            data={data}
            sortArrowStyle={sortArrowStyle}
            sortList={sortList}
            onDelete={(item) => {
              setDeleteConfirmModal(true);
              setItemToDelete(item);
            }}
            onClickSelectHistoryItem={(item) => onClickSelectHistoryItem(item)}
            permList={permList}
            verifyItem={verifyItem}
          />
          {data?.length ? (
            <div className="fs-pagination-wrapper-outer" key={7}>
              <div className="customised-pagination right-align">
                <ReactPaginate
                  previousLabel={""}
                  nextLabel={""}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={fireHistoryReport?.last_page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(data) => handlePageChange(data)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          ) : null}
        </>
      ) : (
        !isLoading && (
          <div className="dashboard_expiration_date_no_data for_mobile_history_list">
            No Data Found
          </div>
        )
      )}
      <ReactModal
        isOpen={deleteConfirmModal}
        onClose={() => setDeleteConfirmModal(false)}
      >
        <div id="statusPopup" className="popupModal">
          <div className="modal-head">
            <h4 className="modal-title">Confirm Action</h4>
          </div>
          <div className="modal-body">
            <p>{`Are you sure you want to delete ${
              itemToDelete?.first_name ?? ""
            } ${itemToDelete?.last_name} ?`}</p>
            <div className="resource_order_form_delete_modal_action">
              <button
                className="button grey"
                onClick={() => setDeleteConfirmModal(false)}
              >
                Cancel
              </button>
              <button
                name="save"
                className="button"
                onClick={() => {
                  handleDeleteItem(itemToDelete);
                  setDeleteConfirmModal(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default FireHistoryReportWithEmployees;

const FireHistoryReportWithEmployeesMobileView = ({
  data,
  sortList,
  sortArrowStyle,
  onDelete,
  onClickSelectHistoryItem,
  permList,
  verifyItem,
}) => {
  const {
    employeeClass,
    assignmentTypeClass,
    incidentNameClass,
    locationClass,
    fireSizeClass,
    fuelTypeClass,
    dateOffClass,
    dateOnClass,
    complexityClass,
    shiftsClass,
    hotlineClass,
  } = sortArrowStyle;

  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list fs_custom_list tab_show">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div className="list_item" onClick={() =>
                sortList(
                  "employeeClass",
                  employeeClass,
                  "first_name"
                )}>
              <strong>Employee</strong>
              <span className={employeeClass} />
            </div>
            <div className="list_item">{`${item?.first_name ?? ""} ${
              item?.last_name
            }`}</div>
          </div>
          <div className="status_item">
            <div className="list_item" onClick={() =>
                sortList(
                  "assignmentTypeClass",
                  assignmentTypeClass,
                  "assignment_type"
                )
              }>
              <strong>Type</strong>
              <span className={assignmentTypeClass} />
            </div>
            <div className="list_item">{item?.assignment_type ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item" onClick={() =>
                sortList("incidentNameClass", incidentNameClass, "incident_name")
              }>
              <strong>Event Name</strong>
              <span className={incidentNameClass} />

            </div>
            <div className="list_item">{item?.incident_name ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item" onClick={() =>
                sortList("locationClass", locationClass, "location")
              }>
              <strong>Location</strong>
              <span className={locationClass} />
            </div>
            <div className="list_item">{item?.location ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item" onClick={() =>
                sortList("complexityClass", complexityClass, "complexity")
              }>
              <strong>Complexity (1,2,3,4,5)</strong>
              <span className={complexityClass} />
            </div>
            <div className="list_item">{item?.complexity ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item" onClick={() =>
                sortList("fuelTypeClass", fuelTypeClass, "fuel_type")
              }>
              <strong>Fuel Type</strong>
              <span className={fuelTypeClass} />
            </div>
            <div className="list_item">{item?.fuel_type ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item" onClick={() =>
                sortList("fireSizeClass", fireSizeClass, "fire_size")
              }>
              <strong>Fire Size</strong>
              <span className={fireSizeClass} />
            </div>
            <div className="list_item">{item?.fire_size ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item" onClick={() => sortList("dateOnClass", dateOnClass, "date_on")}>
              <strong>Start Date</strong>
              <span className={dateOnClass} />
            </div>
            <div className="list_item">{formatDate(item?.date_on) ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item" onClick={() => sortList("dateOffClass", dateOffClass, "date_off")}>
              <strong>End Date</strong>
              <span className={dateOffClass} />
            </div>
            <div className="list_item">{formatDate(item?.date_off) ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item" onClick={() => sortList("shiftsClass", shiftsClass, "shifts")}>
              <strong>Shifts</strong>
              <span className={shiftsClass} />
            </div>
            <div className="list_item">{item?.shifts ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item" onClick={() => sortList("hotlineClass", hotlineClass, "hotline")}>
              <strong>Hotline Shifts</strong>
              <span className={hotlineClass} />
            </div>
            <div className="list_item">{item?.hotline_shifts ?? "-"}</div>
          </div>
          <div className="status_item">
            {permList?.includes()}
            <div className="list_item">
              <div>
                <span
                  className="edit-button-fire-history"
                  onClick={() => onClickSelectHistoryItem(item)}
                >
                  Edit
                </span>
              </div>
            </div>
            {item.is_verified == 3 && (
              <>
                <div className="list_item">
                  <div>
                    <span
                      className="edit-button-fire-history"
                      onClick={() => onDelete(item)}
                    >
                      Delete
                    </span>
                  </div>
                </div>
                <div className="list_item">
                  <div>
                    <span
                      className="edit-button-fire-history"
                      onClick={() =>
                        verifyItem(item?.assignment_history_user_id)
                      }
                    >
                      Verify
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};
