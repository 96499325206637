import { authHeader, config } from "../_helpers";

export const notificationService = {
  getAll,
  getByFilter,
  getBulletin,
  deleteFile,
  uploadFile,
  setLastSeen,
  markAsRead,
  setRead,
  getCount,
  pinnedPost,
  deleteBulletin,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/notification/getnotification",
    requestOptions,
  ).then(handleResponse, handleError);
}

function getByFilter(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = "/api/notification/getnotification";
  let qpSet = false;

  Object.keys(params).map((key, index) => {
    if (params[key] && !qpSet) {
      url += `?${key}=${params[key]}`;
      qpSet = true;
    } else if (params[key]) {
      url += `&${key}=${params[key]}`;
    }
  });

  return fetch(config.apiUrl + encodeURI(url), requestOptions).then(
    handleResponse,
    handleError,
  );
}

function setLastSeen() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    config.apiUrl + "/api/notification/setlastseen",
    requestOptions,
  ).then(handleResponse, handleError);
}

function getBulletin() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/bulletin/getbulletin",
    requestOptions,
  ).then(handleResponse, handleError);
}

function deleteFile(data) {
  const requestOptions = {
    headers: authHeader(),
    method: "POST",
    body: data,
  };

  return fetch(config.apiUrl + "/api/bulletin/deletefile", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function deleteBulletin(id) {
  const requestOptions = {
    headers: authHeader(),
    method: "GET",
    // body: data
  };

  return fetch(
    config.apiUrl + "/api/bulletin/delete?id=" + id,
    requestOptions,
  ).then(handleResponse, handleError);
}

function uploadFile(data) {
  const requestOptions = {
    headers: authHeader(),
    method: "POST",
    body: data,
  };

  return fetch(config.apiUrl + "/api/bulletin/uploadfile", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function markAsRead() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/notification/setmarkallasread",
    requestOptions,
  ).then(handleResponse, handleError);
}

function setRead(id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    // body: JSON.stringify({ id })
  };

  return fetch(
    config.apiUrl + "/api/notification/setsingleasread?id=" + id,
    requestOptions,
  ).then(handleResponse, handleError);
}

function getCount() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/notification/system/count",
    requestOptions,
  ).then(handleResponse, handleError);
}

function pinnedPost(id) {
  const requestOptions = {
    headers: authHeader(),
    method: "GET",
    // body: data
  };

  return fetch(
    config.apiUrl + "/api/bulletin/pinnedpost?id=" + id,
    requestOptions,
  ).then(handleResponse, handleError);
}

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then((json) => resolve(json));
      } else {
        resolve();
      }
    } else {
      // return error message from response body
      response.text().then((text) => reject(text));
    }
  });
}

function handleError(error) {
  return Promise.reject(error && error.message);
}
