import React, { useState } from "react";

import { formatDate } from "_helpers/helpers";
import { EditProfile } from "./EditProfile";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";
import { dateTimeConstants } from "_constants";

const EmploymentHistoryComponent = ({
  profileData,
  refetchUser,
  refetchHistory,
  historyData,
  isOpen,
  toggleAccordion,
  permList,
  authUser,
}) => {
  const [startDate, setStartDate] = useState(null);

  const editClicked = (record) => {
    setStartDate(record.start_date);
  };

  return (
    <div
      id="employment-history"
      className={`profile-data__drops profile-data_comp ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#employment-history")}>
        <button type="button">
          <h3>Employment History</h3>
        </button>
      </header>

      <div className="profile-data__drops-cnt">
        <div className="profile-data__table">
          <table>
            <thead>
              <tr>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Last Day Worked</th>
                <th scope="col">Reason</th>
                {permList.includes(
                            "Status Update",
                          ) && <th></th>}
              </tr>
            </thead>
            <tbody>
              {historyData?.length > 0 ? (
                historyData.map((record, index) => {
                  return (
                    <tr>
                      <td>{formatDate(record.start_date)}</td>
                      <td>{formatDate(record.end_date)}</td>
                      <td>{formatDate(record.last_day_worked)}</td>
                      <td>{record.reason ?? "-"}</td>
                      {permList.includes(
                          "Status Update",
                        ) && 
                          <td>
                            <EditProfile
                              label="Employment History"
                              profileData={profileData}
                              refetchUser={refetchUser}
                              refetchHistory={refetchHistory}
                              authUser={authUser}
                              hasPermissionRequiredToEditOwn={false}
                              hasPermissionToEditOthers={permList.includes(
                                "Status Update",
                              )}
                              editClicked={() => editClicked(record)}
                            >
                              {(register, errors, setValue, control) => (
                                <>
                                  <input
                                    type="hidden"
                                    name="employment_history_id"
                                    value={record.id}
                                  />

                                  <input
                                    {...register("employment_history_id", {
                                      maxLength: {
                                        value: 1200,
                                        message:
                                          "Length exceeds the maximum limit of 1200 characters.",
                                      },
                                    })}
                                    type="hidden"
                                    defaultValue={record.id}
                                  />

                                  <label>Employment Start Date</label>
                                  <Controller
                                    name="employment_start_date"
                                    control={control}
                                    defaultValue={
                                      record.start_date
                                        ? moment(record.start_date).format(
                                            dateTimeConstants.DATE_FORMAT,
                                          )
                                        : null
                                    }
                                    rules={{
                                      required: "Employment Start Date is required",
                                    }}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <DatePicker
                                        selected={value ? moment(value) : ""}
                                        onChange={(date) => {
                                          setStartDate(date);
                                          onChange(
                                            date &&
                                              !date
                                                .endOf("day")
                                                .isAfter(moment().endOf("day"))
                                              ? date.format(
                                                  dateTimeConstants.DATE_FORMAT,
                                                )
                                              : "",
                                          );
                                        }}
                                        customInput={<input inputMode="none" />}
                                        maxDate={moment()}
                                        showMonthDropdown
                                        showYearDropdown
                                        placeholderText="Select Employment Start Date"
                                        className={
                                          error ? "profile-form-field-error" : ""
                                        }
                                        dateFormat={dateTimeConstants.DATE_FORMAT}
                                        showPopperArrow={false}
                                      />
                                    )}
                                  />
                                  {errors.employment_start_date && (
                                    <p className="error-message">
                                      {errors.employment_start_date.message}
                                    </p>
                                  )}

                                  {record.end_date ? (
                                    <>
                                      <label>Employment End Date</label>
                                      <Controller
                                        name="employment_end_date"
                                        control={control}
                                        defaultValue={
                                          record.end_date
                                            ? moment(record.end_date).format(
                                                dateTimeConstants.DATE_FORMAT,
                                              )
                                            : null
                                        }
                                        rules={
                                          record.end_date
                                            ? {
                                                required:
                                                  "Employment End Date is required",
                                              }
                                            : {}
                                        }
                                        render={({
                                          field: { onChange, value },
                                          fieldState: { error },
                                        }) => (
                                          <DatePicker
                                            selected={value ? moment(value) : ""}
                                            onChange={(date) =>
                                              onChange(
                                                date &&
                                                  !date
                                                    .endOf("day")
                                                    .isAfter(
                                                      moment().endOf("day"),
                                                    ) &&
                                                  date
                                                    .endOf("day")
                                                    .isAfter(
                                                      moment(startDate).startOf(
                                                        "day",
                                                      ),
                                                    )
                                                  ? date.format(
                                                      dateTimeConstants.DATE_FORMAT,
                                                    )
                                                  : "",
                                              )
                                            }
                                            customInput={<input inputMode="none" />}
                                            maxDate={moment()}
                                            minDate={startDate}
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select Employment End Date"
                                            className={
                                              error
                                                ? "profile-form-field-error"
                                                : ""
                                            }
                                            dateFormat={
                                              dateTimeConstants.DATE_FORMAT
                                            }
                                            showPopperArrow={false}
                                          />
                                        )}
                                      />
                                      {errors.employment_end_date && (
                                        <p className="error-message">
                                          {errors.employment_end_date.message}
                                        </p>
                                      )}

                                      <label>Last Day Worked</label>
                                      <Controller
                                        name="last_day_worked_date"
                                        control={control}
                                        defaultValue={
                                          record.last_day_worked
                                            ? moment(record.last_day_worked).format(
                                                dateTimeConstants.DATE_FORMAT,
                                              )
                                            : null
                                        }
                                        rules={
                                          record.end_date
                                            ? {
                                                required:
                                                  "Last Day Worked is required",
                                              }
                                            : {}
                                        }
                                        render={({
                                          field: { onChange, value },
                                          fieldState: { error },
                                        }) => (
                                          <DatePicker
                                            selected={value ? moment(value) : ""}
                                            onChange={(date) =>
                                              onChange(
                                                date &&
                                                  !date
                                                    .endOf("day")
                                                    .isAfter(moment().endOf("day"))
                                                  ? date.format(
                                                      dateTimeConstants.DATE_FORMAT,
                                                    )
                                                  : "",
                                              )
                                            }
                                            customInput={<input inputMode="none" />}
                                            maxDate={moment()}
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select Last Day Worked"
                                            className={
                                              error
                                                ? "profile-form-field-error"
                                                : ""
                                            }
                                            dateFormat={
                                              dateTimeConstants.DATE_FORMAT
                                            }
                                            showPopperArrow={false}
                                          />
                                        )}
                                      />
                                      {errors.last_day_worked_date && (
                                        <p className="error-message">
                                          {errors.last_day_worked_date.message}
                                        </p>
                                      )}

                                      <label>Reason</label>
                                      <input
                                        {...register("last_day_worked_reason", {
                                          maxLength: {
                                            value: 1200,
                                            message:
                                              "Length exceeds the maximum limit of 1200 characters.",
                                          },
                                        })}
                                        type="text"
                                        placeholder="Reason"
                                        defaultValue={record.reason}
                                        className={
                                          errors.last_day_worked_reason &&
                                          "profile-form-field-error"
                                        }
                                      />
                                      {errors.last_day_worked_reason && (
                                        <p className="error-message">
                                          {errors.last_day_worked_reason.message}
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </EditProfile>
                          </td>
                      }

                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EmploymentHistoryComponent;
