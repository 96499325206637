import { combineReducers } from "redux";
import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { user } from "./users.reducer";
import { qualification } from "./qualification.reducer";
import { alert } from "./alert.reducer";
import { application } from "./application.reducer";
import { applicants } from "./applicants.reducer";
import { resource } from "./resources.reducer";
import { applicantStats } from "./applicantStats.reducer";
import { createProfile } from "./createProfile.reducer";
import { notification } from "./notification.reducer";
import { employee } from "./employee.reducer";
import { bulletin } from "./bulletin.reducer";
import { vehicles } from "./vehicles.reducer";
import { vehicle } from "./vehicle.reducer";
import { permission } from "./permission.reducer";
import { dashboard } from "./dashboard.reducer";
import { singleresource } from "./singleresource.reducer";
import { search } from "./search.reducer";
import { manifest } from "./manifest.reducer";
import { crews } from "./crews.reducer";
import { superintendent } from "./superintendent.reducer";
import { email } from "./email.reducer";
import { adminpermission } from "./adminpermission.reducer";
import { inventory } from "./inventory.reducer";
import { assignmentStats } from "./assignmentStats.reducer";
import { chat } from "./chat.reducer";
import { docs } from "./docs.reducer";
import { interview } from "./interview.reducer";
import { userConstants } from "../_constants/user.constants";

const appReducer = combineReducers({
  authentication,
  registration,
  user,
  alert,
  application,
  applicants,
  resource,
  applicantStats,
  assignmentStats,
  notification,
  employee,
  bulletin,
  vehicles,
  vehicle,
  permission,
  dashboard,
  createProfile,
  singleresource,
  search,
  manifest,
  crews,
  superintendent,
  email,
  adminpermission,
  inventory,
  chat,
  docs,
  interview,
  qualification,
});

const rootReducer = (state, action) => {
  if (action.type === userConstants.LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
