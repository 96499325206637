import { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import moment from "moment";
import { dashboardService } from "_services";

const DATE_FORMAT = "MMMM DD, YYYY, h:mm A";

const ResourceOrderHistory = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const pdfContainer = useRef(null);

  const [url, setURL] = useState("");
  const [numPages, setNumPages] = useState(1);
  const [width, setWidth] = useState(998);
  const [historyList, setHistoryList] = useState([]);
  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const pdfWidth = pdfContainer?.current?.clientWidth;
    setWidth(pdfWidth);
    const formData = new FormData();
    formData.append("rof_id", id);
    dashboardService
      .getResourceOrderHistory(formData)
      .then((res) => {
        if (res.success) {
          setHistoryList(res?.data);
          getPDF(res?.data[0]?.id);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const getPDF = (itemId) => {
    const formData = new FormData();
    formData.append("roh_id", itemId);
    dashboardService
      .getResourceOrderPDF(formData)
      .then((res) => {
        if (res.success) {
          setURL(res?.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onClickHistory = (hist, index) => {
    setActive(index);
    getPDF(hist.id);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      <div className="dashboard_container">
        <div className="white">
          <div className="back_to_board dashboard_expiration_date_nav">
            <div className="back_button">
              <img
                src={require("../assets/images/back.svg").default}
                alt="back"
              />{" "}
              <span>
                <Link to="/resource-order-form">Back to Resource Orders</Link>
              </span>
            </div>
          </div>
        </div>
        <section className="user-docs res_pdf_container">
          <div className="onboarding-docs  sidebar-form">
            <section className="d-flex w-100">
              <div className="doc_info">
                <div className="doc_info-lh doc-list-lh">
                  <div className="doc-title">
                    <h3>Version History </h3>
                  </div>
                  <div className="doc-statusbar doc-listing">
                    <ul className="doc-lists">
                      {historyList.map((hist, index) => (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            onClickHistory(hist, index);
                          }}
                          href="/"
                        >
                          <li
                            className={`${active === index ? "doc-active hist_list" : "hist_list"}`}
                          >
                            <p>
                              <b className="active_green_text">
                                {moment(hist.created_at).format(DATE_FORMAT)}
                              </b>
                            </p>
                            {index === 0 && (
                              <p>
                                <i>Current version</i>
                              </p>
                            )}
                            <p>{hist.user.full_name}</p>
                          </li>
                        </a>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className={`doc_info-rh`} ref={pdfContainer}>
                  {/* Document */}
                  <div className="doc-wrapper doc-wrapper-rs">
                    {!loading && (
                      <Fragment>
                        <Document
                          file={url}
                          onLoadSuccess={onDocumentLoadSuccess}
                          noData="No history available for the selected Resource order"
                        >
                          {Array.from(new Array(numPages), (el, index) => (
                            <div className="doc-page" key={`page_${index + 1}`}>
                              <Page
                                pageNumber={index + 1}
                                width={width - 100}
                                scale={1}
                              />
                            </div>
                          ))}
                        </Document>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ResourceOrderHistory;
