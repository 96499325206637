import { manifestConstants } from "../_constants";

export function manifest(state = {}, action) {
  switch (action.type) {
    case manifestConstants.GETTYPE_REQUEST:
      return {
        loading: true,
      };
    case manifestConstants.GETTYPE_SUCCESS:
      return {
        manifest_type: action.manifest_type,
      };
    case manifestConstants.GETTYPE_FAILURE:
      return {
        error: action.error,
      };
    case manifestConstants.GETMANIFESTLIST_REQUEST:
      return {
        loading: true,
      };
    case manifestConstants.GETMANIFESTLIST_SUCCESS:
      return {
        ManifestList: action.ManifestList,
      };
    case manifestConstants.GETMANIFESTLIST_FAILURE:
      return {
        error: action.error,
      };
    case manifestConstants.GETMANIFEST_REQUEST:
      return {
        loading: true,
      };
    case manifestConstants.GETMANIFEST_SUCCESS:
      return {
        list: action.manifest,
      };
    case manifestConstants.GETMANIFEST_FAILURE:
      return {
        error: action.error,
      };
    case manifestConstants.GETUSERS_REQUEST:
      return {
        loading: true,
      };
    case manifestConstants.GETUSERS_SUCCESS:
      return {
        users: action.users,
      };
    case manifestConstants.GETUSERS_FAILURE:
      return {
        error: action.error,
      };

    case manifestConstants.GETVEH_REQUEST:
      return {
        loading: true,
      };
    case manifestConstants.GETVEH_SUCCESS:
      return {
        vehicles: action.vehicles,
      };
    case manifestConstants.GETVEH_FAILURE:
      return {
        error: action.error,
      };
    case manifestConstants.SAVEMANIFEST_REQUEST:
      return {
        loading: true,
      };
    case manifestConstants.SAVEMANIFEST_SUCCESS:
      return {
        saved: action.manifest,
      };
    case manifestConstants.SAVEMANIFEST_FAILURE:
      return {
        error: action.error,
      };
    case manifestConstants.PRINTMANIFEST_REQUEST:
      return {
        loading: true,
      };
    case manifestConstants.PRINTMANIFEST_SUCCESS:
      return {
        print: action.manifest,
      };
    case manifestConstants.PRINTMANIFEST_FAILURE:
      return {
        error: action.error,
      };
    case manifestConstants.DELETEMANIFEST_REQUEST:
      return {
        loading: true,
      };
    case manifestConstants.DELETEMANIFEST_SUCCESS:
      return {
        delete: action.manifest,
      };
    case manifestConstants.DELETEMANIFEST_FAILURE:
      return {
        error: action.error,
      };
    case manifestConstants.GETDISPATCHMAILLIST_REQUEST:
      return {
        loading: true,
      };
    case manifestConstants.GETDISPATCHMAILLIST_SUCCESS:
      return {
        dispatchMailList: action.mailList,
      };
    case manifestConstants.GETDISPATCHMAILLIST_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
