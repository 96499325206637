import React, { useRef } from 'react';

import { toast } from 'react-toastify';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Dropzone from 'react-dropzone';
import ReactModal from 'react-modal';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';

import { userStatus } from '_constants';

import { config } from '_helpers';

import { EditProfile, useUpdateProfile } from './EditProfile';

const CardComponent = ({
  profileData,
  profileUserName,
  refetchUser,
  refetchHistory,
  permList,
  authUser,
}) => {
  const [imageCropModalOpen, setImageCropModalOpen] = React.useState(false);
  const [imgPreview, setImagePreview] = React.useState(null);
  const [cropResult, setCropResult] = React.useState(
    config.apiUrl + '/' + profileData.profile_photo,
  );
  const cropperRef = useRef(null);

  const mutation = useUpdateProfile(refetchUser, refetchHistory, () => {});

  const onImageDrop = (files) => {
    // Validation
    const allowed = ['png', 'jpg', 'jpeg'],
      file = files[0] || null;
    if (!file) {
      return toast.error('Please select an image file.');
    }
    const name = file.name,
      extension = name.split('.').pop().toLowerCase();
    if (allowed.indexOf(extension) === -1) {
      return toast.error(
        'The file format is invalid. Allowed formats are png, jpg, jpeg.',
      );
    }
    setImagePreview(files[0].preview);
    setImageCropModalOpen(true);
  };

  const imageCropModalClose = () => {
    setImageCropModalOpen(false);
  };

  const onImageError = (e) => {
    e.target.setAttribute('src', config.apiUrl + '/' + config.altImg);
  };

  const cropImage = () => {
    const cropper = cropperRef.current?.cropper;
    cropper.getCroppedCanvas().toBlob(
      (blob) => {
        setCropResult(cropper.getCroppedCanvas().toDataURL());
        setImageCropModalOpen(false);
        let data = new FormData();
        data.append('profile_pic', blob);
        mutation.mutate({ userId: profileData.id, formData: data });
      },
      'image/jpeg',
      0.45,
    );
  };

  let img_preview = '';
  if (imgPreview) {
    img_preview = imgPreview;
  } else if (profileData && profileData.profile_photo) {
    img_preview = config.apiUrl + '/' + profileData.profile_photo;
  } else {
    img_preview = config.apiUrl + '/' + config.altImg;
  }

  return (
    <>
      <div className='profile-data__card'>
        <figure
          className={
            profileData.is_locked === 1
              ? 'Locked'
              : profileData.status === userStatus.Guest
                ? 'Guest'
                : profileData.active
          }
        >
          {permList.includes('Update Profile Picture') &&
          profileData.status != 0 ? (
            <Dropzone
              id='file_name'
              name='file_name'
              multiple={false}
              accept='image/*'
              onDrop={onImageDrop}
              style={{ position: 'unset' }}
            >
              <div className='profile__camera'>
                <CameraIcon />
              </div>
              {cropResult && (
                <img
                  onError={onImageError}
                  className='imagePreview'
                  src={cropResult}
                  alt='cropped-img'
                />
              )}
            </Dropzone>
          ) : cropResult ? (
            <img
              onError={onImageError}
              className='imagePreview'
              src={cropResult}
              alt='cropped image'
            />
          ) : (
            <img src={config.apiUrl + '/' + config.altImg} alt='Profile' />
          )}
        </figure>
        <div className="profile-data__user-title">
        <h3>{profileUserName}</h3>
        <EditProfile
          label='Name'
          profileData={profileData}
          refetchUser={refetchUser}
          authUser={authUser}
          hasPermissionRequiredToEditOwn={true}
          hasPermissionToEditOthers={permList.includes(
            'Manage Employee Profile Fields',
          )}
        >
          {(register, errors) => (
            <>
              <label>First Name</label>
              <input
                {...register('first_name', {
                  required: 'First name is required',
                  maxLength: {
                    value: 100,
                    message:
                      'Length exceeds the maximum limit of 100 characters.',
                  },
                })}
                placeholder='Enter First Name'
                defaultValue={profileData.first_name}
                className={errors.first_name && 'profile-form-field-error'}
              />
              {errors.first_name && (
                <p className='error-message'>{errors.first_name.message}</p>
              )}

              <label>Middle Name</label>
              <input
                {...register('middle_name', {
                  maxLength: {
                    value: 100,
                    message:
                      'Length exceeds the maximum limit of 100 characters.',
                  },
                })}
                placeholder='Enter Middle Name'
                defaultValue={profileData.middle_name}
                className={errors.middle_name && 'profile-form-field-error'}
              />
              {errors.middle_name && (
                <p className='error-message'>{errors.middle_name.message}</p>
              )}

              <label>Last Name</label>
              <input
                {...register('last_name', {
                  required: 'Last name is required',
                  maxLength: {
                    value: 100,
                    message:
                      'Length exceeds the maximum limit of 100 characters.',
                  },
                })}
                placeholder='Enter Last Name'
                defaultValue={profileData.last_name}
                className={errors.last_name && 'profile-form-field-error'}
              />
              {errors.last_name && (
                <p className='error-message'>{errors.last_name.message}</p>
              )}
            </>
          )}
        </EditProfile>
        </div>
        <ul role='list'>
          <li role='listitem'>{profileData.user_type}</li>
          <li role='listitem'>{profileData.crew_name}</li>
          <li role='listitem'>
            Firestorm ID #: <span>{profileData.fire_id ?? '-'}</span>
          </li>
          <li role='listitem'>
            Primary Crew: <span>{profileData.primary_crew_name ?? '-'}</span>
          </li>
          <li role='listitem'>
            RT-130: <span> {profileData.rt_130_expiry ?? '-'}</span>
          </li>
        </ul>
      </div>
      <ReactModal
        isOpen={imageCropModalOpen}
        onClose={imageCropModalClose}
        contentLabel='Minimal Modal Example'
        overlayClassName='imageCropPopup'
      >
        <div className='modal-head'>
          <img
            className='closeModal'
            onClick={imageCropModalClose}
            src={require('../../assets/images/close_green.png').default}
          />
        </div>
        <div id='edit_profile_pic_popup' className='modal-body'>
          <Cropper
            ref={cropperRef}
            src={img_preview}
            style={{ height: 400, width: '100%' }}
            // Cropper.js options
            aspectRatio={16 / 16}
            guides={false}
          />
          <div className='button_list'>
            <Dropzone
              id='file_name'
              className='button popup_upload'
              name='file_name'
              multiple={false}
              accept='image/*'
              onDrop={onImageDrop}
            >
              <p>Upload Here</p>
            </Dropzone>
            <button onClick={cropImage} className='button'>
              Crop Image
            </button>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default CardComponent;
