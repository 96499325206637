/**Below functions are for Functional Components */
export function validateForm(form, errors) {
  let errorMsg = "Please enter a valid value";
  let focusEl = null;

  Object.keys(form).forEach((index) => {
    let el = form[index];
    if (el.type == "radio" && el.required && !el.checked) {
      let field = el.attributes["name"].value;
      errors = {
        ...errors,
        [field]: "Please choose an option",
      };

      if (!focusEl) focusEl = el;
    } else if (el.required && !el.value) {
      let field = el.attributes["name"].value;
      errors = {
        ...errors,
        [field]: errorMsg,
      };

      if (!focusEl) focusEl = el;
    }
  });

  Object.keys(form).forEach((index) => {
    let el = form[index];
    if (el.type == "radio" && el.checked) {
      let field = el.attributes["name"].value;
      delete errors[field];
    }
  });

  if (focusEl) focusEl.focus();

  return errors;
}
