import React from "react";
import ReactModal from "react-modal";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

import styles from "./generateRegistrationLink.module.css";
import TextInput from "Docs/Components/TextInput";
import { resourceService } from "_services";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translateY(50%)",
    width: "calc(100% - 50px)",
    maxWidth: "420px",
  },
};

const SUCCESS_ALERT = {
  modalTitle: "Email sent successfully",
  content:
    "GIS Resource Registration Link was successfully sent to johndoe@email.com",
};

const defaultValues = {
  name: "",
  email: "",
};

const GenerateRegistrationLink = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  // SUCCESS ALERT
  const [generatedSuccessfully, setGeneratedSuccessfully] =
    React.useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const handleOnSubmit = async () => {
    try {
      setIsSubmitting(true);
      let values = getValues();
      let data = new FormData();
      data.append("email", values?.email);
      data.append("name", values?.name);
      const response = await resourceService?.generateGissResourceRegLink(data);
      if (response?.success) {
        setIsSubmitting(false);
        toast.success(response?.message);
        setGeneratedSuccessfully(true);
      } else {
        throw response;
      }
    } catch (error) {
      toast.error(error?.message);
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (generatedSuccessfully) {
      setGeneratedSuccessfully(false);
    }
    if (!isModalOpen) {
      reset();
    }
  }, [isModalOpen]);

  return (
    <React.Fragment>
      <button className="button" type="button" onClick={() => toggleModal()}>
        Generate Registration Link
      </button>

      {/* Modal */}

      <ReactModal
        isOpen={isModalOpen}
        onClose={() => toggleModal()}
        style={customStyles}
      >
        <div className={styles?.container}>
          {/* Modal Header */}
          <div className={styles?.dialog_title}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4 className={styles?.h4Style}>
                {generatedSuccessfully
                  ? SUCCESS_ALERT?.modalTitle
                  : "Generate GIS Resource Registration Link"}
              </h4>
            </div>
            {/* Close Button */}
            {!generatedSuccessfully && (
              <div onClick={() => (isSubmitting ? {} : toggleModal())}>
                <img
                  alt="close"
                  className="closeModal"
                  src={require("../../assets/images/close_green.png").default}
                />
              </div>
            )}
          </div>
          {generatedSuccessfully ? (
            <SuccessAlertContent
              toggleModal={toggleModal}
              email={getValues("email")}
            />
          ) : (
            <form
              onSubmit={handleSubmit(handleOnSubmit)}
              className={styles?.content}
            >
              <Controller
                rules={{
                  required: true,
                  // pattern: {
                  //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  //   message: "invalid email address",
                  // },
                }}
                name="name"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    label="Name"
                    type="text"
                    value={value}
                    onChange={(event) => onChange(event?.target?.value)}
                    error={errors.name}
                    placeholder="- Enter Name -"
                    required
                  />
                )}
              />
              <Controller
                rules={{ required: true }}
                name="email"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    label="Email Address"
                    type="email"
                    value={value}
                    onChange={(event) => onChange(event?.target?.value)}
                    error={errors.email}
                    placeholder="- Enter Email -"
                    required
                  />
                )}
              />
              <div className={styles?.buttonContainer}>
                <button
                  className={`button grey ${styles?.marginRight}`}
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => toggleModal()}
                >
                  Cancel
                </button>
                <button
                  className={`button ${styles?.registerBtn}`}
                  type="submit"
                  disabled={isSubmitting}
                >
                  Send Registration Link
                </button>
              </div>
            </form>
          )}
        </div>
      </ReactModal>
    </React.Fragment>
  );
};

export default GenerateRegistrationLink;

const SuccessAlertContent = ({ email = "", toggleModal }) => (
  <div className={styles?.content}>
    <div className={styles?.minHeight}>
      <p>{`GIS Resource Registration Link was successfully sent to ${email}`}</p>
    </div>
    <div className={styles?.buttonContainer}>
      <button className="button" onClick={() => toggleModal?.()}>
        Close
      </button>
    </div>
  </div>
);
