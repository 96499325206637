import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { chatActions } from "../_actions";
import { config } from "../_helpers";

/*function MemberCount({ count }) {
    if (count > 9)
        return <span>9+</span>
    return <span>{count}</span>
}*/

function Spinner() {
  return (
    <React.Fragment>
      <span className="conversation-search-loader user-search">
        <img
          src={`${config.assetUrl}icons/circle.svg`}
          className="spinner fa-spin"
          alt=""
        />
      </span>
    </React.Fragment>
  );
}

class NewGroup extends React.Component {
  constructor(props) {
    super(props);

    this.SelectedItemsList = null;

    this.state = {
      activeTab: "name",
      searchText: "",
      searchLoader: true,
      groupName: "",
      groupNameEditMode: false,
      activeList: "crew",
      showViewAll: false,
      viewAll: false,
      users: [],
      crews: [],
      selectedItems: [],
    };

    this.resourceList = React.createRef();

    this.handleUserSelect = this.handleUserSelect.bind(this);
    this.handleCrewSelect = this.handleCrewSelect.bind(this);
    this.handleUnselect = this.handleUnselect.bind(this);
    this.selectActiveList = this.selectActiveList.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.clearSearchText = this.clearSearchText.bind(this);
    this.saveGroupName = this.saveGroupName.bind(this);
    this.createConversation = this.createConversation.bind(this);
  }

  saveGroupName() {
    if (this.state.groupName.length) {
      this.setState({
        groupNameEditMode: false,
      });
    } else {
      this.setState({
        groupName: "New Group",
        groupNameEditMode: false,
      });
    }
  }

  handleSearch(e) {
    this.setState(
      {
        searchText: e.target.value,
        searchLoader: true,
      },
      () => {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          let requestData = new FormData();
          requestData.append("keyword", this.state.searchText);

          this.props.dispatch(chatActions.getUsers(requestData));
          this.props.dispatch(chatActions.getCrews(requestData));
        }, 600);
      },
    );
  }

  clearSearchText() {
    this.setState(
      {
        searchText: "",
      },
      () => {
        clearTimeout(this.searchTimeout);
        let requestData = new FormData();
        requestData.append("keyword", this.state.searchText);
        this.props.dispatch(chatActions.getUsers(requestData));
        this.props.dispatch(chatActions.getCrews(requestData));
      },
    );
  }

  handleUserSelect(index, e) {
    let nextState = {};

    if (e.target.checked) {
      nextState.users = this.state.users.map((user, userIndex) => {
        if (userIndex == index) return { ...user, checked: e.target.checked };

        return user;
      });

      nextState.selectedItems = [
        ...this.state.selectedItems,
        { ...this.state.users[index], checked: e.target.checked },
      ];
    } else {
      nextState.users = this.state.users.map((user, userIndex) => {
        if (userIndex == index) return { ...user, checked: e.target.checked };

        return user;
      });

      nextState.selectedItems = this.state.selectedItems.filter(
        (user) => user.hash != this.state.users[index].hash,
      );
    }

    this.setState(nextState);
  }

  handleCrewSelect(index, e) {
    let nextState = {};

    if (e.target.checked) {
      nextState.crews = this.state.crews.map((crew, crewIndex) => {
        if (crewIndex == index) return { ...crew, checked: e.target.checked };

        return crew;
      });

      nextState.selectedItems = [
        ...this.state.selectedItems,
        { ...this.state.crews[index], checked: e.target.checked },
      ];
    } else {
      nextState.crews = this.state.crews.map((crew, crewIndex) => {
        if (crewIndex == index) return { ...crew, checked: e.target.checked };

        return crew;
      });

      nextState.selectedItems = this.state.selectedItems.filter(
        (item) => item.id != this.state.crews[index].id,
      );
    }

    this.setState(nextState);
  }

  selectActiveList(e) {
    this.setState({
      activeList: e.target.id,
    });
  }

  handleUnselect({ index, type }) {
    if (type == "user") {
      this.setState({
        users: this.state.users.map((user) => {
          if (user.hash == this.state.selectedItems[index].hash) {
            return { ...user, checked: false };
          }
          return user;
        }),
        selectedItems: this.state.selectedItems.filter(
          (user, userIndex) => userIndex != index,
        ),
      });
    } else if (type == "crew") {
      this.setState({
        crews: this.state.crews.map((crew) => {
          if (crew.id == this.state.selectedItems[index].id) {
            return { ...crew, checked: false };
          }
          return crew;
        }),
        selectedItems: this.state.selectedItems.filter(
          (item, itemIndex) => itemIndex != index,
        ),
      });
    }
  }

  createConversation() {
    let requestData = new FormData();

    requestData.append("title", this.state.groupName);
    requestData.append("type", "group");

    this.state.selectedItems.forEach((item) => {
      if (item.resource_name) {
        requestData.append("crews[]", item.id);
      } else if (item.hash) {
        requestData.append("users[]", item.hash);
      }
    });

    this.props.dispatch(chatActions.createConversation(requestData));
  }

  componentDidMount() {
    let { user, crews } = this.props.chat;

    if (user) {
      this.setState({
        users: user.data.map((user) => {
          return {
            ...user,
            checked: false,
          };
        }),
      });
    }

    if (crews) {
      this.setState({
        crews: crews.map((crew) => {
          return {
            ...crew,
            checked: false,
          };
        }),
      });
    }

    this.props.dispatch(chatActions.getUsers());
    this.props.dispatch(chatActions.getCrews());
  }

  componentDidUpdate(prevProps) {
    if (
      this.SelectedItemsList &&
      !this.state.showViewAll &&
      this.SelectedItemsList.clientHeight > 100
    ) {
      this.setState({
        showViewAll: true,
      });
    }

    if (
      this.SelectedItemsList &&
      this.state.showViewAll &&
      this.SelectedItemsList.clientHeight <= 100
    ) {
      this.setState({
        showViewAll: false,
      });
    }

    let { user, crews } = this.props.chat;

    if (user !== prevProps.chat.user) {
      this.setState({
        users: user.data.map((user) => {
          let existingUser = this.state.selectedItems.find(
            (item) => item.hash == user.hash,
          );

          if (existingUser) {
            return {
              ...user,
              checked: existingUser.checked,
            };
          }

          return {
            ...user,
            checked: false,
          };
        }),
        searchLoader: false,
      });

      if (this.resourceList.scrollTop) this.resourceList.scrollTop = 0;
    }

    if (crews !== prevProps.chat.crews) {
      this.setState({
        crews: crews.map((crew) => {
          let existingCrew = this.state.selectedItems.find(
            (item) => item.id == crew.id,
          );

          if (existingCrew) {
            return {
              ...crew,
              checked: existingCrew.checked,
            };
          }

          return {
            ...crew,
            checked: false,
          };
        }),
        searchLoader: false,
      });

      if (this.resourceList.scrollTop) this.resourceList.scrollTop = 0;
    }
  }

  render() {
    let path = this.props.match.path;
    let { user } = this.props.chat;

    let selectedList = [...this.state.selectedItems];

    return (
      <div className="container-fluid">
        <div className="row msg msg-header">
          {this.state.activeTab == "name" ? (
            <div className="col-md-6">
              <h1 className="msg-heading">Create New Group</h1>
            </div>
          ) : (
            <div className="col-md-6">
              {this.state.groupNameEditMode ? (
                <form
                  onSubmit={this.saveGroupName}
                  className="group-titleEdit"
                  id="groupEdit"
                >
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.groupName}
                    onChange={(e) => {
                      this.setState({ groupName: e.target.value });
                    }}
                  />
                  <button className="sendBtn">
                    <img src={`${config.assetUrl}icons/save-tick-white.svg`} />
                  </button>
                </form>
              ) : (
                <h1 className="msg-heading">
                  {this.state.groupName}
                  <a
                    onClick={() => {
                      this.setState({ groupNameEditMode: true });
                    }}
                    id="name-edit"
                  >
                    <img src={`${config.assetUrl}icons/edit_icon_grey.svg`} />
                  </a>
                </h1>
              )}
            </div>
          )}
          <div className="col-md-6">
            <Link to={`/chat`} className="btn msg-cancel-btn pull-right">
              Cancel
            </Link>
          </div>
        </div>
        <div className="row msg">
          <div className="msg-new">
            <div className="container-fluid">
              <div className="row msg-mob-messageheader">
                <div className="col-xs-8">
                  <h3 className="title">New Group</h3>
                </div>
                <div className="col-xs-4">
                  <ul className="admincontrols nav navbar-nav navbar-right">
                    <li>
                      <Link to={`/chat`} className="msg-admincontrol">
                        Cancel
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="new-container container-fluid new-group">
              {this.state.activeTab == "name" ? (
                <div className="row">
                  <div className="col-md-12">
                    <form
                      id="addNewMember"
                      className="add-memberform group-name-form"
                    >
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="selectUser"
                          id="selectUser"
                          placeholder="Enter Group Name"
                          value={this.state.groupName}
                          onChange={(e) => {
                            this.setState({ groupName: e.target.value });
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row group-member-added">
                      <div className="col-xs-6">
                        <h3 className="new-title">Group MembersX</h3>
                      </div>
                      <div className="col-xs-6">
                        {this.state.showViewAll && (
                          <a
                            onClick={() => {
                              this.setState({ viewAll: !this.state.viewAll });
                            }}
                            className="pull-right msg-link"
                          >
                            {this.state.viewAll ? "View Less" : "View All"}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                                            <h3 className="membercount">Group Members <MemberCount count={this.state.selectedItems.length}></MemberCount> <a href="#" className="groupmember-edit pull-right"></a> </h3>
                                        </div> */}
                  <div className="col-sm-12">
                    <div
                      className={`row added-members ${this.state.viewAll ? "all" : ""}`}
                    >
                      <div
                        ref={(el) => {
                          this.SelectedItemsList = el;
                        }}
                        className="col-md-12"
                      >
                        {selectedList.reverse().map((item, index) => {
                          return (
                            <div className="msg-added-member" key={index}>
                              <div className="msg-user-pic">
                                {
                                  <img
                                    src={
                                      item.resource_name
                                        ? item.resource_photo
                                        : config.baseUrl + item.profile_photo
                                    }
                                    onError={(e) => {
                                      e.target.setAttribute(
                                        "src",
                                        config.apiUrl + "/" + config.altImg,
                                      );
                                    }}
                                    alt=""
                                  />
                                }
                              </div>
                              <div className="msg-messageData">
                                {item.email && (
                                  <React.Fragment>
                                    <div className="msg-name">
                                      {item?.first_name}{" "}
                                      {item?.last_name?.charAt(0)}
                                    </div>
                                    <a
                                      onClick={this.handleUnselect.bind(this, {
                                        index,
                                        type: "user",
                                      })}
                                      className="removeMember"
                                    >
                                      <img
                                        src={`${config.assetUrl}icons/removeMember.svg`}
                                      />
                                    </a>
                                  </React.Fragment>
                                )}
                                {item.resource_name && (
                                  <React.Fragment>
                                    <div className="msg-name">
                                      {item.resource_name}
                                    </div>
                                    <a
                                      onClick={this.handleUnselect.bind(this, {
                                        index,
                                        type: "crew",
                                      })}
                                      className="removeMember"
                                    >
                                      <img
                                        src={`${config.assetUrl}icons/removeMember.svg`}
                                      />
                                    </a>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-md-12">
                        <form
                          id="addNewMember"
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                          className="add-memberform"
                        >
                          <h3 className="new-title">Select Members</h3>
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              value={this.state.searchText}
                              onChange={this.handleSearch}
                              name="searchText"
                              id="selectUser"
                              placeholder="Search people or crew"
                            />
                            <button
                              onClick={this.clearSearchText}
                              className="close-inputBtn"
                            >
                              <img
                                src={`${config.assetUrl}icons/search-close.svg`}
                              />
                            </button>
                          </div>
                        </form>
                        <div className="group-member-list">
                          <ul className="nav nav-tabs" role="tablist">
                            <li role="presentation" className="active">
                              <a
                                onClick={this.selectActiveList}
                                id="crew"
                                className={`btn search-filter ${this.state.activeList == "crew" ? "active" : ""}`}
                                aria-controls="home"
                                role="tab"
                                data-toggle="tab"
                              >
                                Crew
                              </a>
                            </li>
                            <li role="presentation">
                              <a
                                onClick={this.selectActiveList}
                                id="people"
                                className={`btn search-filter ${this.state.activeList == "people" ? "active" : ""}`}
                                aria-controls="profile"
                                role="tab"
                                data-toggle="tab"
                              >
                                People
                              </a>
                            </li>
                          </ul>
                          <div
                            className="group-member-list-scroll"
                            ref={(el) => {
                              this.resourceList = el;
                            }}
                          >
                            {this.state.activeList == "crew" && (
                              <React.Fragment>
                                {this.state.crews.length ? (
                                  this.state.crews.map((crew, index) => {
                                    return (
                                      <div
                                        className={`msg-users ${crew.checked ? "selected" : ""}`}
                                        key={index}
                                      >
                                        <label htmlFor={`checkCrew${index}`}>
                                          <div className="msg-user-pic">
                                            <img
                                              src={crew.resource_photo}
                                              onError={(e) => {
                                                e.target.setAttribute(
                                                  "src",
                                                  config.apiUrl +
                                                    "/" +
                                                    config.altImg,
                                                );
                                              }}
                                              alt=""
                                            />
                                          </div>
                                          <div className="msg-messageData">
                                            <div className="msg-name">
                                              {crew.resource_name}
                                            </div>
                                            <div className="msg-title">
                                              {crew.resource_type}
                                            </div>
                                            <div className="memberAdd">
                                              <div className="pretty p-icon p-round p-smooth">
                                                <input
                                                  type="checkbox"
                                                  id={`checkCrew${index}`}
                                                  onChange={this.handleCrewSelect.bind(
                                                    this,
                                                    index,
                                                  )}
                                                  checked={crew.checked}
                                                />
                                                <div className="state p-success">
                                                  <i className="icon mdi mdi-check"></i>
                                                  <label></label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                    );
                                  })
                                ) : this.state.searchLoader ? (
                                  <Spinner />
                                ) : (
                                  <div className="no-results">
                                    No Results Found
                                  </div>
                                )}
                              </React.Fragment>
                            )}

                            {this.state.activeList == "people" && (
                              <React.Fragment>
                                {this.state.users.length ? (
                                  this.state.users.map((user, index) => {
                                    return (
                                      <div
                                        className={`msg-users ${user.checked ? "selected" : ""}`}
                                        key={index}
                                      >
                                        <label htmlFor={`checkUser${index}`}>
                                          <div className="msg-user-pic">
                                            <img
                                              src={
                                                config.baseUrl +
                                                user.profile_photo
                                              }
                                              onError={(e) => {
                                                e.target.setAttribute(
                                                  "src",
                                                  config.apiUrl +
                                                    "/" +
                                                    config.altImg,
                                                );
                                              }}
                                              alt=""
                                            />
                                          </div>
                                          <div className="msg-messageData">
                                            <div className="msg-name">
                                              {user.first_name} {user.last_name}
                                            </div>
                                            <div className="msg-title">
                                              {user.user_type}
                                            </div>
                                            <div className="memberAdd">
                                              <div className="pretty p-icon p-round p-smooth">
                                                <input
                                                  type="checkbox"
                                                  id={`checkUser${index}`}
                                                  onChange={this.handleUserSelect.bind(
                                                    this,
                                                    index,
                                                  )}
                                                  checked={user.checked}
                                                />
                                                <div className="state p-success">
                                                  <i className="icon mdi mdi-check"></i>
                                                  <label></label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                    );
                                  })
                                ) : this.state.searchLoader ? (
                                  <Spinner />
                                ) : (
                                  <div className="no-results">
                                    No Results Found
                                  </div>
                                )}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="groupSave">
              {this.state.activeTab == "name" ? (
                <button
                  onClick={() => {
                    this.setState({ activeTab: "people" });
                  }}
                  className="btn msg-btn"
                  disabled={this.state.groupName.length <= 0}
                >
                  Next
                </button>
              ) : (
                <button
                  onClick={this.createConversation}
                  className="btn msg-btn"
                  disabled={this.state.selectedItems.length <= 0}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { chat } = state;
  return { chat };
}

export default connect(mapStateToProps)(NewGroup);
