import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  userActions,
  permissionActions,
  inventoryActions,
  notificationActions,
  chatActions,
} from "../_actions";
import { authHeader, config } from "../_helpers";
import Modal from "react-responsive-modal";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import { AlertPopup } from "../_components/AlertPopup";
import ReactModal from "react-modal";
import NewModal from "react-responsive-modal";
import NumberFormat from "react-number-format";
import { UserDocsList } from "./UserDocsList";
import { docsService } from "../_services/docs.service";
import { unAvailableReason } from "../_utils/utils";
import ChangePassword from 'ChangePassword';
import { dateTimeConstants, profileType, userConstants } from "_constants";
import ErrorBoundary from "_components/ErrorBoundary";
import UpdateEmployeeTraining from "EmployeeTraining";
import UserQualifications from "UserQualifications";
import Management from "Settings/Management";
import ApplicantsTab from "ApplicantTab";
import { profileService } from "_services/profile.service";
import { handleErrorResponse } from "_helpers/helpers";
import { formatDate } from "_helpers/helpers";
import ViewProfile from "_components/Profile/ViewProfile";
import SmsNotification from "SmsNotification/SmsNotification";

ReactModal.setAppElement("#app");

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hash: this.props.props,
      request_rehire_doc: false,
      request_rehire_doc_status: false,
      tab: this.props.match.params.step,
      statusOpen: false,
      removeOpen: false,
      alertOpen: false,
      mailSendOpen: false,
      doc_request: "",
      requireDocs: false, // for status modal
      email: true,
      sms: true,
      status: "",
      available: "",
      statusStep: "1",
      returnDateField: moment(),
      returnDate: "",
      message: "",
      notes: "",
      alertPop: false,
      userName: "",
      afterSuccess: false,
      inventoryUserAlert: false,
      isGuest: false,
      unavailableReason: 0,
      reasonToDisplay: 0,
      unavailableErr: "",
      unavailableDescriptionErr: "",
      otherReason: "",
      displayOtherReason: "",
      savingNote: false,
      sendingDDForm: false,
      send_dd_form_confirm: false,
      active: 1,
      lastDayWorked: moment(),
      lastDayReason: "",
      lastDayReasonErr: "",
      reloadKey: 0,
      loader: false,
    };
    this.goBack = this.goBack.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.handleRemoveEmployee = this.handleRemoveEmployee.bind(this);
    this.removeCloseModal = this.removeCloseModal.bind(this);
    this.alertOpenModal = this.alertOpenModal.bind(this);
    this.alertCloseModal = this.alertCloseModal.bind(this);
    this.mailSendOpenModal = this.mailSendOpenModal.bind(this);
    this.mailSendCloseModal = this.mailSendCloseModal.bind(this);
    this.setStatusReturn = this.setStatusReturn.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.downloadAllDocuments = this.downloadAllDocuments.bind(this);
    this.downloadAllDocumentsAsPDF = this.downloadAllDocumentsAsPDF.bind(this);
    this.unlockProfile = this.unlockProfile.bind(this);
    this.requireDocs = this.requireDocs.bind(this);
    this.lockProfile = this.lockProfile.bind(this);
    this.docRequestModalClose = this.docRequestModalClose.bind(this);
  }

  statusOpenModal() {
    this.setState({ statusOpen: true, statusStep: "1" });
  }

  statusCloseModal() {
    this.setState({
      statusOpen: false,
      unavailableReason: 0,
      unavailableErr: "",
      unavailableDescriptionErr: "",
      otherReason: "",
    });
    // Below changes is to remove the auto added inline styles.
    let doc = document.querySelector("html");
    doc.style.overflow = "";
    doc.style.position = "";
    doc.style.width = "";
    doc.style.top = "";
  }

  removeCloseModal() {
    this.setState({ removeOpen: false });
  }

  changeStepParam = (newStep) => {
    const { history, match } = this.props;
    const { hash } = match.params;

    history.replace(`/resource-profile/${hash}/${newStep}`);
  };

  alertOpenModal() {
    this.setState({ alertOpen: true });
  }

  alertCloseModal() {
    this.setState({ alertOpen: false, redirect: "/resources" }, () => {
      this?.props.dispatch({
        type: userConstants?.REMOVEPROFILE_SUCCESS,
        removeUser: null,
      });
    });
  }

  mailSendOpenModal() {
    this.setState({ mailSendOpen: true });
  }

  mailSendCloseModal() {
    this.setState({ mailSendOpen: false });
  }

  handleRemoveEmployee(e) {
    let inventoryList = this.props.inventory.inventory.data || [];
    if (inventoryList && inventoryList.length) {
      this.setState({ inventoryUserAlert: true });
    } else {
      this.setState({ removeOpen: true });
    }
  }

  handleChange(event) {
    if (event.target.name === "notes") {
      if (this.state.permList.includes("Employee Notes")) {
        this.setState({
          [event.target.name]: event.target.value,
          template: "custommail",
        });
      }
      return;
    }
    this.setState({
      [event.target.name]: event.target.value,
      template: "custommail",
    });
    // this.setState({ [event.target.name]: event.target.value, template:'custommail' });
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  changeTab(tab) {
    this.changeStepParam(tab);
    this.setState({ tab });
  }

  documentRequest(hash, doc_request) {
    this.setState({ doc_request });
    docsService.requestToFillDocs(hash, doc_request).then(
      (res) => {
        this.setState({
          request_rehire_doc_status: res.success,
          request_rehire_doc: true,
        });
        var hash = "?hash=" + this.state.hash;
        this.props.dispatch(userActions.getProfile(hash));
      },
      (err) => console.log(err),
    );
  }

  openDdformConfirmModal() {
    this.setState({ send_dd_form_confirm: true });
  }

  ddFormConfirmCloseModal() {
    this.setState({
      send_dd_form_confirm: false,
    });
  }

  sendDDForm() {
    if (this.props.user.items.data.hash) {
      this.setState({
        ...this.state,
        sendingDDForm: true,
        send_dd_form_confirm: false,
      });
      let data = new FormData();
      data.append("user_hash", this.props.user.items.data.hash);
      profileService
        .sendDDForm(data)
        .then((res) => {
          if (res.success) {
            toast.success(res.message);
          } else {
            handleErrorResponse(res);
          }
          this.setState({
            ...this.state,
            sendingDDForm: false,
          });
        })
        .catch((error) => {
          toast.error("Failed to send email to user.");
          this.setState({
            ...this.state,
            sendingDDForm: false,
          });
        });
    } else {
      toast.error("An unexpected error occurred.");
    }
  }

  docRequestModalClose() {
    this.setState({ request_rehire_doc: false });
  }

  downloadAllDocuments(event, rehire = false, giss = false) {
    let { hash } = this.state.profile;
    let params = {
      uid: this.props.user.profile.data.hash,
      signature: this.props.chat.settings.signature,
    };
    let url = new URL(`${config.apiUrl}/docs/download/${hash}`);
    rehire &&
      (url = new URL(`${config.apiUrl}/docs/download/${hash}?rehire=true`));
    giss && (url = new URL(`${config.apiUrl}/docs/download/${hash}?giss=true`));
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key]),
    );
    window.open(url.href);
  }

  downloadAllDocumentsAsPDF(event, rehire = false, giss = false) {
    let { hash } = this.state.profile;
    let params = {
      uid: this.props.user.profile.data.hash,
      signature: this.props.chat.settings.signature,
    };
    let url = new URL(`${config.apiUrl}/docs/download/pdf/${hash}`);
    rehire &&
      (url = new URL(`${config.apiUrl}/docs/download/pdf/${hash}?rehire=true`));
    giss &&
      (url = new URL(`${config.apiUrl}/docs/download/pdf/${hash}?giss=true`));

    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key]),
    );
    window.open(url.href);
  }

  componentDidMount() {
    var hash = "?hash=" + this.state.hash;
    this.props.dispatch(userActions.getProfile(hash));
    this.props.dispatch(permissionActions.getPermission());
    let data = new FormData();
    data.append("hash", this.state.hash);
    this.props.dispatch(inventoryActions.getInventoryList(data));
    this.props.dispatch(chatActions.getConversationSettings());
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (user?.lockProfileSuccess && !prevProps.user?.lockProfileSuccess) {
      this.statusCloseModal();
      toast.success("Profile updated successfully");
      this.setState({
        reloadKey: this.state.reloadKey + 1,
        lastDayReason: "",
        lastDayWorked: moment(),
        loader: false,
      });
    }

    if (user?.lockProfileFailure && !prevProps.user?.lockProfileFailure) {
      this.statusCloseModal();
      toast.error("Failed to update profile");
      this.setState({ loader: false });
    }

    if (user?.changeStatusSuccess && !prevProps.user?.changeStatusSuccess) {
      this.statusCloseModal();
      this.setState({ reloadKey: this.state.reloadKey + 1, loader: false });
    }

    if (user?.changeStatusFailure && !prevProps.user?.changeStatusFailure) {
      this.statusCloseModal();
      this.setState({ loader: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { user, inventory, permission } = nextProps;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;

      this.setState({
        permList,
      });
    }

    var generalQualificationList = [],
      inventory_list = [];
    if (inventory.inventory) {
      if (inventory.inventory.data) {
        var total_cost = 0;
        inventory.inventory.data.map((item, index) => {
          total_cost += parseInt(item.cost, 10);
          inventory_list.push(
            <div className="inv_item" key={index}>
              <div className="inv_cell">
                {index + 1 + ". " + item.unique_id + " (" + item.type + ") "}
                {/* <span className="cost">{"$"+item.cost}</span>*/}
                <NumberFormat
                  className="cost"
                  value={item.cost}
                  displayType={"text"}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </div>
              {/*<div className="inv_cell" data-header-title="Unique Id">{item.unique_id}</div>
                            <div className="inv_cell" data-header-title="Cost">{item.cost}</div>*/}
            </div>,
          );
          return inventory_list;
        });
        this.setState({ inventory_list, total_cost });
      }
    }

    if (user.items) {
      // this.setState({ active: user.items.data.active });
      var profile = user.items.data;
      var available = profile.active === "Available" ? true : false;
      if (this.props.props == profile.hash) {
        this.setState({
          active: profile.active,
          status: profile.active,
          is_locked: profile.is_locked,
          available: available,
          notes: this?.state?.notes ? this?.state?.notes : profile.notes,
          profile,
          hash: profile.hash,
          userName: profile.first_name,
          user_type: user.items.data.user_type,
          resource_id: user.items.data.crew_identifier_id,
          isGuest: user.items.data.status === 3 ? true : false,
          reasonToDisplay: user.items.data.unavailable_reason,
          displayOtherReason: user.items.data.other_reason,
        });
        if (profile.qualification) {
          profile.qualification.map((item, index) => {
            generalQualificationList.push(
              <p className="crew-profile-detail" key={index}>
                {item}
              </p>,
            );
            return generalQualificationList;
          });
        }
      }
    }

    this.setState({
      generalQualificationList,
      permList,
    });

    if (user.removeUser) {
      if (user.removeUser.success) {
        this.setState({
          alertHead: "Success",
          alertMsg: user.removeUser.message,
          removeOpen: false,
          alertOpen: this.state.removeOpen,
        });
        // this.props.goBack(this.props.resourceDetails);
      } else {
        var hash = "?hash=" + this.state.hash;
        // this.props.dispatch(userActions.getProfile(hash));
        // this.setState({ removeOpen: false });
        // alert(user.removeUser.message);
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: user.removeUser.message,
          alertFunction: "",
          removeOpen: false,
        });
      }
    }
    if (user.notes) {
      if (user.notes.success) {
      } else {
        // alert(user.notes.message);
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: user.notes.message,
          alertFunction: "",
        });
      }
      this.props.dispatch(userActions.getProfile(hash));
    }
  }

  //Change the reason for unavailability
  changeUnavailabilityReason = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      unavailableDescriptionErr: "",
      unavailableErr: "",
    });
    e.target.setAttribute("errors", "");
  };

  changeLastDayReason = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      lastDayReasonErr: "",
    });
  };

  setStatusReturn(status) {
    if (Number(this.state.unavailableReason) === Number(0)) {
      this.setState({
        unavailableErr: "error",
      });
      return;
    }
    if (!this.state.otherReason.trim()) {
      this.setState({
        unavailableDescriptionErr: "error",
      });
      return;
    }

    if (status) {
      var step = "2a";
      this.setState({ returnDate: moment().format(dateTimeConstants.DATE_FORMAT) });
    } else {
      step = "2b";
      this.setState({
        returnDate: "",
      });
    }
    this.setState(
      {
        statusStep: step,
      },
      function () {
        this.componentWillReceiveProps(this.props);
      },
    );
  }

  setStep(step) {
    this.setState({ statusStep: step }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  updateNotes(hash) {
    let data = new FormData();
    data.append("hash", hash);
    data.append("note", this.state.notes);
    if (this.state.notes !== null) {
      this.setState({ savingNote: true });
      this.props
        .dispatch(userActions.updateEmployeeNote(data, "?hash=" + hash))
        .then(
          (res) => {
            toast.success("Notes Saved !");
            this.setState({ savingNote: false });
          },
          (err) => {
            toast.error("Notes Cannot be saved");
            this.setState({ savingNote: false });
          },
        );
    }
    // this.props.dispatch(userActions.getProfile("?hash="+hash));
  }

  handleDateChange(date) {
    this.setState({
      returnDateField: date,
      returnDate: date.format("MM/DD/YY"),
    });
  }

  handleLastDayChange(date) {
    if (date) {
      this.setState({
        lastDayWorked: date,
      });
    }
  }

  changeStatus(status) {
    if (status) {
      var stat =
        this.state.status === "Available" ? "Unavailable" : "Available";
      var hash = "?hash=" + this.state.hash;
      let data = new FormData();
      data.append("end_date", this.state.returnDate);
      data.append("hash", this.state.hash);
      data.append("status", stat);
      data.append(
        "unavailableReason",
        this.state.unavailableReason ? this.state.unavailableReason : 0,
      );
      // if (this.state.unavailableReason === "4") {
      data.append(
        "otherReason",
        this.state.otherReason ? this.state.otherReason : "",
      );
      // } else {
      // data.append("otherReason", "");
      // }
      this.props.dispatch(userActions.changeStatus(data, hash));
      this.props.dispatch(notificationActions.getCount());
      this.setState({ status: stat, active: stat, loader: true });
    }
  }

  setUnAvailableReason = (reason, other) => {
    return unAvailableReason(reason, other);
  };

  lockProfile() {
    if (!this.state.lastDayReason.trim()) {
      this.setState({
        lastDayReasonErr: "error",
      });
      return;
    }
    var stat = "Locked";
    var hash = "?hash=" + this.state.hash;
    let data = new FormData();
    // this.statusCloseModal();
    data.append("hash", this.state.hash);
    data.append("status", stat);
    data.append(
      "last_day_worked",
      moment(this.state.lastDayWorked).format(
        dateTimeConstants.ISO_DATE_FORMAT,
      ),
    );
    data.append("last_day_reason", this.state.lastDayReason);
    this.props.dispatch(userActions.lockUserToggle(data, hash));
    this.props.dispatch(notificationActions.getCount());
    this.setState({ status: stat, loader: true });
  }

  requireDocs(e) {
    this.setState({ requireDocs: e.target.checked });
  }

  unlockProfile() {
    var stat = "Unlocked";
    var hash = "?hash=" + this.state.hash;
    let data = new FormData();
    // this.statusCloseModal();
    data.append("hash", this.state.hash);
    data.append("status", stat);
    data.append("document", this.state.requireDocs);
    this.props.dispatch(userActions.lockUserToggle(data, hash));
    this.props.dispatch(notificationActions.getCount());
    this.setState({ status: stat, loader: true });
  }

  remove(hash) {
    let data = new FormData();
    this.statusCloseModal();
    data.append("hash", hash);
    this.props.dispatch(userActions.removeEmployee(data));
    // this.props.goBack(this.props.resourceDetails);
    // this.setState({
    //     redirect: "/resources"
    // })
  }

  goBack() {
    this.props.goBack(this.props.resourceDetails);
  }

  toResourceList() {
    this.props.toResourceList();
  }

  editProfile(hash) {
    this.props.editProfile(hash);
  }

  print() {
    window.print();
  }

  sendMail(hash) {
    let data = new FormData();
    this.mailSendCloseModal();
    data.append("type", "mail");
    data.append("message", this.state.message);
    data.append("user_hash", hash);
    var self = this;
    fetch(config.apiUrl + "/api/employee/sendmails", {
      headers: authHeader(),
      method: "POST",
      body: data,
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        // alert( JSON.stringify( data.message ) )
        if (data.success) {
          self.setState({
            alertPop: true,
            alertHead: "Success",
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: "",
          });
        } else {
          self.setState({
            alertPop: true,
            alertHead: "Error",
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: "",
          });
        }
      });
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  render() {
    const { permission } = this.props;
    let user;
    this.props &&
      this.props.user &&
      this.props.user.items &&
      (user = this.props.user.items.data);

    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }
    if (this.state.redirect === "/resources") {
      return <Redirect to={"/resources/" + this.state.resource_id} />;
    }

    const authUser = this.props.user.profile?.data ?? {};
    const userData = this.props.user.items?.data ?? {};

    if (this.state.profile) {
      return (
        // details
        <div className="resource_container profile_container">
          <div className="breadcrumbs">
            <Link
              onClick={this.toResourceList.bind(this)}
              to={"/resources"}
              className="green"
            >
              Resources
            </Link>{" "}
            / <Link to={"/resources/" + this.state.resource_id}>Details</Link> /
            Profile
          </div>
          <div className="page_title float">
            <h1>
              {this.state.profile
                ? this.state.profile.first_name +
                  " " +
                  (this.state.profile.middle_name
                    ? this.state.profile.middle_name + " "
                    : "") +
                  this.state.profile.last_name
                : ""}
            </h1>
            <div className="btn_list">
              {/*<button className="button remove_resource" onClick={this.goBack.bind(this)}>Back</button>*/}
              {this.state.tab == "profile" ? (
                // Profile Buttons
                <React.Fragment>
                  <Link to={`/fire-history/${this.state?.hash}`}>
                    <button
                      style={{ fontSize: "11px", padding: "6px 16px" }}
                      className="button print"
                    >
                      Fire History
                    </button>
                  </Link>
                  <Link to={`/fire-history/${this.state?.hash}/legacy`}>
                    <button
                      style={{ fontSize: "11px", padding: "6px 16px" }}
                      className="button print"
                    >
                      Legacy Fire History
                    </button>
                  </Link>
                  {permList.includes("Manage Passwords") && (
                    <ChangePassword
                      userType="user"
                      user={this.state.profile}
                      isAdmin={
                        this.props?.user?.profile?.data?.is_admin !== 0
                          ? true
                          : false
                      }
                    />
                  )}
                  <button
                    style={{ fontSize: "11px", padding: "6px 16px" }}
                    className="button print"
                    onClick={this.print.bind(this)}
                  >
                    Print
                  </button>
                  {permList.includes("Message Employees") && (
                    <button
                      style={{ fontSize: "11px", padding: "6px 16px" }}
                      className="button edit_resource"
                      onClick={this.mailSendOpenModal.bind(this)}
                    >
                      Message
                    </button>
                  )}
                  {permList.includes("Status Update") && (
                    <button
                      style={{ fontSize: "11px", padding: "6px 16px" }}
                      className="button edit_resource"
                      onClick={this.statusOpenModal.bind(this)}
                    >
                      Edit Status
                    </button>
                  )}
                  {permList.includes("Remove Employee") && (
                    <button
                      style={{ fontSize: "11px", padding: "6px 16px" }}
                      className="button edit_resource"
                      onClick={this.handleRemoveEmployee.bind(this)}
                    >
                      Remove Employee
                    </button>
                  )}
                  {(permList.includes("Modify SMS Notification Preference") || authUser.id == userData.id) && userData && (
                    <SmsNotification initialStatus={userData.sms_notify} providerOptedOutStatus={userData.sms_provider_opt_out} type={profileType.EMPLOYEE} id={userData.id} customClass={'edit_resource xs-font'} />
                  )}
                  {permList.includes("Send DD Form") && (
                    <button
                      className="button edit_resource"
                      onClick={this.openDdformConfirmModal.bind(this)}
                      disabled={this.state.sendingDDForm}
                      style={{ fontSize: "11px", padding: "6px 16px" }}
                    >
                      Send DD Form
                    </button>
                  )}
                </React.Fragment>
              ) : (
                // Document Buttons
                <React.Fragment>
                  {permList.includes("Manage Docs") &&
                    user.docs_status === -1 && (
                      <button
                        className={`button edit_resource ${
                          user && user.rehire_docs_status !== -1 && "disabled"
                        }`}
                        onClick={() =>
                          this.documentRequest(user.hash, "onboarding")
                        }
                        disabled={user && user.docs_status !== -1}
                      >
                        {user && user.docs_status === -1
                          ? "Ask to Fill Onboarding Documents"
                          : "Asked to Fill Onboarding Documents"}
                      </button>
                    )}
                  {permList.includes("Manage Docs") &&
                    user.rehire_docs_status === -1 &&
                    user.docs_status >= 2 && (
                      <button
                        className={`button edit_resource ${
                          user && user.rehire_docs_status !== -1 && "disabled"
                        }`}
                        onClick={() =>
                          this.documentRequest(user.hash, "rehire")
                        }
                        disabled={user && user.rehire_docs_status !== -1}
                      >
                        {user && user.rehire_docs_status === -1
                          ? "Ask to Fill Rehire Documents"
                          : "Asked to Fill Rehire Documents"}
                      </button>
                    )}
                  {/* Onboarding */}
                  {permList.includes("Manage Docs") &&
                    user.docs_status >= 2 && (
                      <button
                        className="button edit_resource"
                        onClick={this.downloadAllDocuments}
                        title="Download Onboarding documents as ZIP File"
                      >
                        Onboarding{" "}
                        <i className="fas fa-file-archive text-white inline-icons"></i>
                      </button>
                    )}
                  {permList.includes("Manage Docs") &&
                    user.docs_status >= 2 && (
                      <button
                        className="button primary edit_resource"
                        onClick={this.downloadAllDocumentsAsPDF}
                        title="Download Onboarding documents as PDF"
                      >
                        Onboarding{" "}
                        <i className="fas fa-file-pdf text-white inline-icons"></i>
                      </button>
                    )}

                  {/* Rehire */}
                  {permList.includes("Manage Docs") &&
                    user.rehire_docs_status >= 2 && (
                      <button
                        className="button edit_resource"
                        onClick={(event) =>
                          this.downloadAllDocuments(event, true, false)
                        }
                        title="Download Rehire documents as ZIP File"
                      >
                        Rehire{" "}
                        <i className="fas fa-file-archive text-white inline-icons"></i>
                      </button>
                    )}
                  {permList.includes("Manage Docs") &&
                    user.rehire_docs_status >= 2 && (
                      <button
                        className="button primary edit_resource"
                        onClick={(event) =>
                          this.downloadAllDocumentsAsPDF(event, true, false)
                        }
                        title="Download Rehire documents as PDF"
                      >
                        Rehire{" "}
                        <i className="fas fa-file-pdf text-white inline-icons"></i>
                      </button>
                    )}

                  {/* Ask Rehire Modal */}
                  <ReactModal
                    isOpen={this.state.request_rehire_doc}
                    onClose={this.docRequestModalClose.bind(this)}
                    contentLabel="Rehire Request"
                    overlayClassName="alertPopup"
                  >
                    <div id="rehire_request" className="popupModal">
                      <div className="modal-head">
                        <h4 className="modal-title">
                          Request {this.state.doc_request} Docs
                        </h4>
                      </div>
                      <div className="modal-body">
                        {this.state.request_rehire_doc_status ? (
                          <p>
                            Request for fill out{" "}
                            <span className="modal-word-highlight">
                              {this.state.doc_request.toUpperCase()} documents
                            </span>
                            ,sent out to the user.
                          </p>
                        ) : (
                          <p>
                            Unable to request{" "}
                            <strong>
                              {this.state.doc_request.toUpperCase()} documents
                            </strong>
                            !!!
                          </p>
                        )}
                        <div className="btn_list">
                          <button
                            className="button"
                            onClick={this.docRequestModalClose.bind(this)}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </ReactModal>
                </React.Fragment>
              )}
            </div>
          </div>
          {/* Profile/Document Tab Navigation */}
          <div className="applicants_inner_head inner_head header_tabs">
            <h2
              className={this.state.tab === "profile" ? "active" : ""}
              onClick={this.changeTab.bind(this, "profile")}
            >
              Profile
            </h2>
            {permList.includes("Manage Docs") && (
              <h2
                className={this.state.tab === "documents" ? "active" : ""}
                onClick={this.changeTab.bind(this, "documents")}
              >
                Documents
              </h2>
            )}
            <h2
              className={this.state.tab === "qualifications" ? "active" : ""}
              onClick={this.changeTab.bind(this, "qualifications")}
            >
              Qualifications
            </h2>
            <h2
              className={this.state.tab === "trainings" ? "active" : ""}
              onClick={this.changeTab.bind(this, "trainings")}
            >
              Trainings
            </h2>

            {permList.includes("View/Add Management") && (
              <h2
                className={this.state.tab === "management" ? "active" : ""}
                onClick={this.changeTab.bind(this, "management")}
              >
                Management
              </h2>
            )}
            {this.state.profile?.applicant_hash && (
              <h2
                className={this.state.tab === "application" ? "active" : ""}
                onClick={this.changeTab.bind(this, "application")}
              >
                Application
              </h2>
            )}
          </div>
          {/* Profile/Document Tab Content */}
          <div className="content_div settings profile--tab">
            <div className="clearFix" />
            {(() => {
              switch (this.state.tab) {
                case "profile":
                  return (
                    <ViewProfile
                      userHash={this.props?.id}
                      authUser={this.props?.user?.profile?.data?.hash}
                      permList={permList}
                      changeTab={this.changeTab}
                      reloadKey={this.state.reloadKey}
                    />
                  );
                case "documents":
                  return <UserDocsList userHash={this.state.hash} />;
                case "trainings":
                  return (
                    <ErrorBoundary>
                      <UpdateEmployeeTraining userHash={this.state.hash} />
                    </ErrorBoundary>
                  );
                case "qualifications":
                  return (
                    <ErrorBoundary>
                      <UserQualifications userHash={this.state.hash} />
                    </ErrorBoundary>
                  );
                case "management":
                  return (
                    <ErrorBoundary>
                      <Management />
                    </ErrorBoundary>
                  );
                case "application":
                  return (
                    <ErrorBoundary>
                      <ApplicantsTab
                        hash={this.props?.user?.items?.data?.applicant_hash}
                        isApplicationEditable={false}
                        applnType={this.state.applnType}
                        email={this.state.email}
                        sendMail={this.sendMail}
                        viewHistory={this.viewHistory}
                      />
                    </ErrorBoundary>
                  );
                default:
              }
            })()}
          </div>

          {/* Status modal */}
          <NewModal
            id="status_popup"
            classNames={{ modal: "status_popup" }}
            open={this.state.statusOpen}
            onClose={this.statusCloseModal.bind(this)}
            overlayClassName="empStatusPopup resEmpStatusPop profileEmp_status_modal"
            className="status_popup"
            center
          >
            {/*<Modal style={"width: 200px"} id="status_popup" className="status_popup" open={this.state.statusOpen} onClose={this.statusCloseModal.bind(this)} little>*/}
            <div id="statusPopup">
              <div className="modal-head">
                <h4 className="modal-title">Current Status</h4>
                {/* <img
                  className='closeModal'
                  onClick={this.statusCloseModal.bind(this)}
                  src={require("../assets/images/close_green.png")}
                  alt='close'
                /> */}
              </div>
              {this.state.statusStep === "1" && (
                <div className="modal-body">
                  <div className="status_step step1">
                    <label>
                      {this.state.profile.first_name +
                        " " +
                        this.state.profile.last_name +
                        " is: "}
                      <span className="green">{this.state.status}</span>
                    </label>
                    <br />
                    <div className="user-status-edit-wrapper">
                      <div>
                        {this.state.is_locked === 0 && (
                          <button
                            className="button"
                            onClick={this.setStep.bind(this, "1b")}
                          >
                            Lock Profile
                          </button>
                        )}
                        {this.state.is_locked === 1 && (
                          <button
                            className="button"
                            onClick={this.setStep.bind(this, "1a")}
                          >
                            Unlock Profile
                          </button>
                        )}
                      </div>
                      <div>
                        <button
                          className={`button outlined ${
                            this.state.is_locked === 1 ? "disabled" : ""
                          }`}
                          onClick={this.setStep.bind(this, "2")}
                          disabled={this.state.is_locked === 1}
                        >
                          {this.state.status === "Available"
                            ? "Make Unavailable"
                            : "Make Available"}
                        </button>
                        {/* <p className="step_no">1/3</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.statusStep === "1a" && (
                <div className="modal-body">
                  <div className="status_step step1">
                    <section className="d-flex flex-column checkb">
                      <h6 className="text-bold">
                        Are you sure you want to Unlock the selected employee
                      </h6>

                      {(user.docs_status === -1 ||
                        user.rehire_docs_status === -1) && (
                        <label>
                          <input
                            type="checkbox"
                            onChange={this.requireDocs}
                            defaultChecked={this.state.requireDocs}
                          />
                          <span>Ask to fill Documents</span>
                        </label>
                      )}
                    </section>
                    <div className="user-status-edit-wrapper">
                      <div>
                        <button
                          disabled={this.state.loader}
                          className="button disabled"
                          onClick={this.setStep.bind(this, "2")}
                        >
                          Cancel
                        </button>
                      </div>
                      <div>
                        <button
                          disabled={this.state.loader}
                          className="button"
                          onClick={this.unlockProfile}
                        >
                          Unlock
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.statusStep === "1b" && (
                <div className="modal-body">
                  <div className="status_step step1">
                    <section className="d-flex flex-column checkb">
                      <p>
                        Are you sure you want to Lock the selected employee ?
                      </p>
                    </section>
                    <div className="user-status-edit-wrapper">
                      <div>
                        <button
                          className="button grey"
                          onClick={this.setStep.bind(this, "2")}
                        >
                          Cancel
                        </button>
                      </div>
                      <div>
                        <button
                          className="button"
                          onClick={this.setStep.bind(this, "1c")}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.statusStep === "1c" && (
                <div className="modal-body">
                  <div className="status_step step1">
                    <section className="d-flex flex-column checkb">
                      <div classNames="full-width">
                        <p>Last Day Worked</p>
                        <DatePicker
                          className="lastDayWorked"
                          name="lastDayWorked"
                          selected={this.state.lastDayWorked}
                          onChange={this.handleLastDayChange.bind(this)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                        <textarea
                          rows="3"
                          maxLength={1200}
                          className="lastDayReason"
                          value={this.state.lastDayReason}
                          name="lastDayReason"
                          onChange={this.changeLastDayReason}
                          errors={this.state.lastDayReasonErr}
                        />
                      </div>
                    </section>
                    <div className="user-status-edit-wrapper">
                      <div>
                        <button
                          disabled={this.state.loader}
                          className="button grey"
                          onClick={this.setStep.bind(this, "2")}
                        >
                          Cancel
                        </button>
                      </div>
                      <div>
                        <button
                          disabled={this.state.loader}
                          className="button"
                          onClick={this.lockProfile}
                        >
                          Lock
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.statusStep === "2" && this.state.available && (
                <div className="modal-body">
                  <div className="status_step step2">
                    <label>Reason for Unavailability: </label>
                    <select
                      name="unavailableReason"
                      className="unavailability_reason"
                      value={this.state.unavailableReason}
                      onChange={this.changeUnavailabilityReason}
                      errors={this.state.unavailableErr}
                    >
                      <option value="0">-- Select Reason --</option>
                      <option value="1">Time Off</option>
                      <option value="2">Sick</option>
                      <option value="3">Injury</option>
                      <option value="4">Other</option>
                    </select>
                    {(this.state.unavailableReason === "1" ||
                      this.state.unavailableReason === "2" ||
                      this.state.unavailableReason === "3" ||
                      this.state.unavailableReason === "4") && (
                      <div classNames="full-width">
                        <textarea
                          rows="3"
                          maxLength={1200}
                          className="other_reason"
                          value={this.state.otherReason}
                          name="otherReason"
                          onChange={this.changeUnavailabilityReason}
                          errors={this.state.unavailableDescriptionErr}
                        />
                      </div>
                    )}
                    <label>
                      Do you know when {this.state.userName} will return?
                    </label>
                    <button
                      className="button"
                      onClick={this.setStatusReturn.bind(this, true)}
                    >
                      Yes
                    </button>
                    <button
                      className="button grey"
                      onClick={this.setStatusReturn.bind(this, false)}
                    >
                      No
                    </button>
                    <p className="step_no">2/3</p>
                  </div>
                </div>
              )}
              {this.state.statusStep === "2" && !this.state.available && (
                <div className="modal-body">
                  <div className="status_step step2">
                    <label>
                      Are you sure you want to make the employee available?
                    </label>
                    <button
                      disabled={this.state.loader}
                      className="button"
                      onClick={this.changeStatus.bind(this, true)}
                    >
                      Yes
                    </button>
                    <button
                      disabled={this.state.loader}
                      className="button grey"
                      onClick={this.statusCloseModal.bind(this)}
                    >
                      No
                    </button>
                    <p className="step_no">2/2</p>
                  </div>
                </div>
              )}
              {this.state.statusStep === "2a" && (
                <div className="modal-body">
                  <div className="status_step step4">
                    <label>Choose date of return:</label>
                    <DatePicker
                      className="returnDateField"
                      name="returnDateField"
                      selected={this.state.returnDateField}
                      onChange={this.handleDateChange.bind(this)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      minDate={moment()}
                    />
                    <button
                      className="button btn_margin"
                      onClick={this.setStep.bind(this, "3")}
                    >
                      Next
                    </button>
                    <p className="step_no">2/3</p>
                  </div>
                </div>
              )}
              {this.state.statusStep === "2b" && (
                <div className="modal-body">
                  <div className="status_step step2">
                    <label>
                      Are you sure you want to make the employee unavailable?
                    </label>
                    <button
                      disabled={this.state.loader}
                      className="button"
                      onClick={this.changeStatus.bind(this, true)}
                    >
                      Yes
                    </button>
                    <button
                      disabled={this.state.loader}
                      className="button grey"
                      onClick={this.statusCloseModal.bind(this)}
                    >
                      No
                    </button>
                    <p className="step_no">3/3</p>
                  </div>
                </div>
              )}
              {this.state.statusStep === "3" && (
                <div className="modal-body">
                  <div className="status_step step5">
                    <label>
                      Are you sure you want to switch {this.state.userName} to
                      Unavailable?
                    </label>
                    <p>
                      (returning{" "}
                      {this.state.returnDate === ""
                        ? moment().format("MM/DD/YYYY")
                        : formatDate(this.state.returnDate)}
                      )
                    </p>
                    <button
                      className="button"
                      onClick={this.changeStatus.bind(this, true)}
                    >
                      Yes
                    </button>
                    <button
                      className="button grey"
                      onClick={this.statusCloseModal.bind(this)}
                    >
                      No
                    </button>
                    <p>Time off must be approved by your supervisor.</p>
                    <p className="step_no">3/3</p>
                  </div>
                </div>
              )}
            </div>
            {/*</Modal>*/}
          </NewModal>

          {/* uers upate success modal */}
          <ReactModal
            isOpen={this.state.alertOpen}
            onClose={this.alertCloseModal.bind(this)}
            contentLabel="Minimal Modal Example"
            overlayClassName="alertPopup"
          >
            <div id="statusPopup" className="popupModal">
              <div className="modal-head">
                <h4 className="modal-title">{this.state.alertHead}</h4>
                {/*<img className="closeModal" onClick={this.viewCloseModal.bind(this)} src={require("../assets/images/close_green.png")} />*/}
              </div>
              <div className="modal-body">
                <p>{this.state.alertMsg}</p>
                <div className="btn_list">
                  <button
                    className="button"
                    onClick={this.alertCloseModal.bind(this)}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </ReactModal>
          <Modal
            id="remove_popup"
            className="status_popup"
            open={this.state.inventoryUserAlert}
            onClose={() => {
              this.setState({ inventoryUserAlert: false });
            }}
            little
          >
            <div id="removePopup">
              <div className="modal-head">
                <h4 className="modal-title">Error:Remove Inventory First</h4>
              </div>
              <div className="modal-body">
                <label>
                  In order to remove this employee you need to remove their
                  inventory first
                </label>
                <br />
                <Link to="/inventory">
                  <button className="button">Go to inventory</button>
                </Link>
                <button
                  className="button grey"
                  onClick={() => {
                    this.setState({ inventoryUserAlert: false });
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            id="remove_popup"
            className="status_popup"
            open={this.state.removeOpen}
            onClose={this.removeCloseModal.bind(this)}
            little
          >
            <div id="removePopup">
              <div className="modal-head">
                <h4 className="modal-title">
                  Remove Employee:{" "}
                  {this.state.profile
                    ? this.state.profile.first_name +
                      " " +
                      this.state.profile.last_name
                    : ""}
                </h4>
              </div>
              <div className="modal-body">
                <label>Are you sure you want to archive this employee?</label>
                <br />
                <button
                  className="button grey"
                  onClick={this.removeCloseModal.bind(this)}
                >
                  Cancel
                </button>
                <button
                  className="button"
                  onClick={this.remove.bind(this, this.state.hash)}
                >
                  Archive
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            id="remove_popup"
            className="status_popup"
            open={this.state.send_dd_form_confirm}
            onClose={this.ddFormConfirmCloseModal.bind(this)}
            little
          >
            <div id="removePopup">
              <div className="modal-head">
                <h4 className="modal-title">Direct Deposit Form</h4>
              </div>
              <div className="modal-body">
                <label>
                  This will send an email to{" "}
                  {this.state.profile
                    ? this.state.profile.first_name +
                      " " +
                      this.state.profile.last_name
                    : "this employee"}
                  , with Direct Deposit form link.
                </label>
                <br />
                <button
                  className="button grey"
                  onClick={this.ddFormConfirmCloseModal.bind(this)}
                >
                  Cancel
                </button>
                <button className="button" onClick={this.sendDDForm.bind(this)}>
                  Send
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            id="email_popup"
            className="status_popup"
            open={this.state.mailSendOpen}
            onClose={this.mailSendCloseModal.bind(this)}
            little
          >
            <div id="emailPopup">
              <div className="modal-head">
                <h4 className="modal-title">Custom Message</h4>
              </div>
              <div className="modal-body">
                <textarea
                  name="message"
                  cols="80"
                  rows="10"
                  value={this.state.message}
                  onChange={this.handleChange.bind(this)}
                />

                <p className="step_no">
                  Signature will automatically be included.
                </p>
                {/*<div className="btn_list">*/}
                <button
                  className="button grey"
                  onClick={this.mailSendCloseModal.bind(this)}
                >
                  Cancel
                </button>
                <button
                  className="button"
                  onClick={this.sendMail.bind(this, this.state.hash)}
                >
                  Send
                </button>
                {/*</div>*/}
              </div>
            </div>
          </Modal>
          {this.state.alertPop && (
            <AlertPopup
              head={this.state.alertHead}
              message={this.state.alertMsg}
              alertFunction={this.state.alertFunction}
              close={this.closeAlert.bind(this)}
            />
          )}
        </div>
      );
    }
    return (
      <div className="resource_container profile_container">
        <div className="page_title float">
          <h1>
            {this.state.profile
              ? this.state.profile.first_name +
                " " +
                this.state.profile.last_name
              : ""}
          </h1>
          <div className="btn_list">
            {/*<button className="button remove_resource" onClick={this.goBack.bind(this)}>Back</button>*/}
            {permList.includes("Manage Passwords") && (
              <ChangePassword
                userType="user"
                user={user}
                isAdmin={
                  this.props?.user?.profile?.data?.is_admin !== 0 ? true : false
                }
              />
            )}
            <button className="button print" onClick={this.print.bind(this)}>
              Print
            </button>
            {permList.includes("Message Employees") && (
              <button
                className="button edit_resource"
                onClick={this.mailSendOpenModal.bind(this)}
              >
                Message
              </button>
            )}
            {permList.includes("Status Update") && (
              <button
                className="button edit_resource"
                onClick={this.statusOpenModal.bind(this)}
              >
                Edit Status
              </button>
            )}
            {permList.includes("Remove Employee") && (
              <button
                className="button edit_resource"
                onClick={this.handleRemoveEmployee.bind(this)}
              >
                Remove Employee
              </button>
            )}
          </div>
        </div>
        <div className="applicants_inner_head inner_head" />
        <div className="content_div settings">
          <center
            id="feedLoader"
            className={this.state.loader ? this.state.loader : ""}
          >
            <img
              alt="Loader"
              src={require("../assets/images/loader.gif").default}
            />
          </center>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, permission, inventory, chat } = state;
  return {
    user,
    permission,
    inventory,
    chat,
  };
}

const connectedRegisterPage = connect(mapStateToProps)(Profile);
export { connectedRegisterPage as Profile };
