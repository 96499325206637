import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";

import { dashboardActions } from "_actions";
import { dashboardService } from "../_services/dashboard.service";
import EquipmentSoldList from "./EquipmentSoldList";
import { getEquipmentSoldReport as getEquipmentSoldReportStore } from "_helpers/reduxSelector";
import { exportToCSV } from "_utils/export";

import "../assets/styles/report.css";

const DATE_FORMAT = "YYYY-MM-DD";

const FILE_NAME = "Equipment Sold Report";

const EquipmentSoldListContainer = (props) => {
  const dispatch = useDispatch();
  const equipmentSoldReport = useSelector((state) =>
    getEquipmentSoldReportStore(state),
  );
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [globalSort, setGlobalSort] = useState("unique_id");
  const [globalOrder, setGlobalOrder] = useState("asc");

  const getEquipmentSoldReport = (newPage, sort, order) => {
    let data = new FormData();
    data.append(
      "from_date",
      fromDate ? moment(fromDate).format(DATE_FORMAT) : "",
    );
    data.append("to_date", toDate ? moment(toDate).format(DATE_FORMAT) : "");
    data.append("page", newPage || 0);
    data.append("sort", sort || "unique_id");
    data.append("order", order || "asc");
    dispatch(dashboardActions.getEquipmentSoldReport(data));
  };

  const onPrintClick = () => {
    let data = new FormData();
    data.append(
      "from_date",
      fromDate ? moment(fromDate).format(DATE_FORMAT) : "",
    );
    data.append("to_date", toDate ? moment(toDate).format(DATE_FORMAT) : "");
    data.append("sort", globalSort || "unique_id");
    data.append("order", globalOrder || "asc");
    data.append("report_type", "pdf");
    dashboardService
      ?.printEquipmentSoldReportPdf(data)
      .then((res) => redirectToPdf(res));
  };

  //has already
  const onExportClick = () => {
    let data = new FormData();
    data.append(
      "from_date",
      fromDate ? moment(fromDate).format(DATE_FORMAT) : "",
    );
    data.append("to_date", toDate ? moment(toDate).format(DATE_FORMAT) : "");
    data.append("sort", globalSort || "unique_id");
    data.append("order", globalOrder || "asc");
    data.append("report_type", "csv");
    dashboardService?.generateEquipmentSoldReport(data);
  };

  const setSortParams = (sort, order) => {
    const sortOrder = order ? "asc" : "desc";
    setGlobalSort(sort);
    setGlobalOrder(sortOrder);
  };

  const onDateFilter = () => {
    let data = new FormData();
    data.append(
      "from_date",
      fromDate ? moment(fromDate).format(DATE_FORMAT) : "",
    );
    data.append("to_date", toDate ? moment(toDate).format(DATE_FORMAT) : "");
    data.append("sort", globalSort || "unique_id");
    data.append("order", globalOrder || "asc");
    dispatch(dashboardActions.getEquipmentSoldReport(data));
  };

  const exportToExcel = (res) => {
    if (res.success) {
      exportToCSV(res.data, FILE_NAME);
    }
  };

  const redirectToPdf = (data) => {
    if (data?.success) {
      const pdfLink = data.data;
      var link = document.createElement("a");
      if (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i)
      ) {
        link.href = pdfLink;
      } else {
        link.href = pdfLink;
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfLink);
      }, 100);
    }
  };

  return (
    <div>
      <div className={props.isReportMenu ? "" : "dashboard_container"}>
        <div className="white">
          {!props.isReportMenu && (
            <div className="back_to_board dashboard_expiration_date_nav">
              <div className="back_button">
                <img src={require("../assets/images/back.svg").default} />{" "}
                <span>
                  <Link to="/">Back to dashboard</Link>
                </span>
              </div>
              {equipmentSoldReport?.data?.length ? (
                <button
                  href=""
                  className="print_button"
                  onClick={(event) => {
                    onPrintClick();
                  }}
                >
                  {/* <img alt="" src={require("../assets/images/print.svg").default} /> Print */}
                  Export
                </button>
              ) : (
                <div className="dashboard-expiration-date-print-btn-mock" />
              )}
            </div>
          )}
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head sold_rpt_mbl_flx">
                <h5 className="graph_header">Equipment Sold Report</h5>
                <div
                  style={{ gap: `${props.isReportMenu ? "10px" : "0px"}` }}
                  className="report_head_btn_grp"
                >
                  <div className="report_multi_btn_grp">
                    <div className="inp_sub_container date_filter_container">
                      <DatePicker
                        className="date_picker_cust"
                        placeholderText="From"
                        id="fromDate"
                        name="fromDate"
                        selected={fromDate}
                        onChange={(e) => setFromDate(e)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                      />
                    </div>
                    <div className="inp_sub_container date_filter_container">
                      <DatePicker
                        className="date_picker_cust"
                        placeholderText="To"
                        id="toDate"
                        name="toDate"
                        selected={toDate}
                        onChange={(e) => setToDate(e)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                      />
                    </div>
                    <button
                      className="print_button"
                      onClick={() => {
                        onDateFilter();
                      }}
                    >
                      Search
                    </button>
                  </div>
                  {props.isReportMenu && equipmentSoldReport?.data?.length ? (
                    <div className="button__div-flex">
                      <button
                        href=""
                        style={{ marginRight: "10px" }}
                        className="print_button"
                        onClick={(event) => {
                          onExportClick();
                        }}
                      >
                        {/* <img alt="" src={require("../assets/icons/print.svg").default} /> Download */}
                        Export
                      </button>
                      <button
                        href=""
                        className="print_button"
                        onClick={(event) => {
                          onPrintClick();
                        }}
                      >
                        {/* <img alt="" src={require("../assets/icons/print.svg").default} /> Download */}
                        Print
                      </button>
                    </div>
                  ) : (
                    <div className="dashboard-expiration-date-print-btn-mock" />
                  )}
                </div>
              </div>
            </div>
            <div>
              <EquipmentSoldList
                fromDate={fromDate}
                toDate={toDate}
                data={equipmentSoldReport?.data ?? []}
                lastPage={equipmentSoldReport?.last_page}
                getEquipmentSoldReport={getEquipmentSoldReport}
                setSortParams={setSortParams}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquipmentSoldListContainer;
