import React from "react";
import { connect } from "react-redux";
import "../assets/styles/createProfile.css";
import { ResourceType } from "./ResourceType";
import { Position } from "./Position";
import { InformationForm } from "./InformationForm";
import { ProfilePic } from "./ProfilePic";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainHtml: "",
      step: "information",
    };
    this.choosePosition = this.choosePosition.bind(this);
    this.toResourceType = this.toResourceType.bind(this);
    this.information = this.information.bind(this);
    this.ProfilePic = this.ProfilePic.bind(this);
    this.toInformation = this.toInformation.bind(this);
  }

  // Step 1 - Resource Type
  toResourceType() {
    this.setState({ step: "ResourceType" }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }
  // Step 2 - Information
  information(type, position) {
    this.setState(
      { step: "information", type: type, position: position },
      function () {
        this.componentWillReceiveProps(this.props);
      },
    );
  }
  // Step 2 - Information
  toInformation() {
    this.setState({ step: "information" }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }
  // Step 3 - the was removed
  choosePosition(type) {
    this.setState({ step: "Position", type: type }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  // Step 3 - Profile picture
  ProfilePic(user) {
    this.setState({ step: "ProfilePic", user: user }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  componentDidMount() {
    this.componentWillReceiveProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    var options;
    // if (this.state.step === "ResourceType") {
    //   options = (
    //     <div>
    //       <ResourceType
    //         toInformation={this.toInformation}
    //         // adminStaff={this.adminStaff}
    //       />
    //     </div>
    //   );
    // }
    // if (this.state.step === "Position") {
    //   options = (
    //     <div>
    //       <Position
    //         type={this.state.type}
    //         toResourceType={this.toResourceType}
    //         information={this.information}
    //         position={this.state.position}
    //       />
    //     </div>
    //   );
    // }
    if (this.state.step === "information") {
      options = (
        <div>
          <InformationForm
            ProfilePic={this.ProfilePic}
            toResourceType={this.toResourceType}
            choosePosition={this.choosePosition}
            type={this.state.type}
            position={this.state.position}
            prevData={this.state.user}
          />
        </div>
      );
    }
    if (this.state.step === "ProfilePic") {
      options = (
        <div>
          <ProfilePic
            toInformation={this.toInformation}
            type={this.state.type}
            position={this.state.position}
            information={this.information}
            user={this.state.user}
          />
        </div>
      );
    }
    this.setState({ mainHtml: options });
  }

  render() {
    return <div>{this.state.mainHtml}</div>;
  }
}

function mapStateToProps(state) {
  const { applicants } = state;
  return {
    applicants,
  };
}

const connectedHomePage = connect(mapStateToProps)(Register);
export { connectedHomePage as Register };
