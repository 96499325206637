import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import ErrorBoundary from "_components/ErrorBoundary";
import FormContainer from "../Components/FormContainer";
import TextInput from "../Components/TextInput";
import CustomDatePicker from "../Components/DatePicker";
import Checkbox from "../Components/Checkbox";
import RadioButton from "../Components/RadioButton";
import CustomSignaturePad from "../Components/SignaturePad";
import SubmitButton from "../Components/SubmitButton";
import { docsActions } from "_actions";
import mapEmptyToNull from "_helpers/mapEmptyToNull";
import "../../assets/styles/Payroll.css";
import { preventNegativeValueInDocs } from "_helpers/helpers";

const toastConfig = {
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const defaultValues = {
  employee_printed_name: "",
  payroll_advance_check: false,
  payroll_advance_amout: "",
  payroll_advance_recieved_date: "",
  payroll_advance_repayment_rate: "",
  payroll_advance_fee_applied_check: "",
  deduction_check: false,
  deduction_amount: "",
  deduction_for: "",
  deduction_fee_applied_check: "",
  allowance_check: "",
  allowance_amount: "",
  allowance_for: "",
  reimbursement_check: "",
  reimbursement_amount: "",
  reimbursement_for: "",
  rate: "",
  employee_signature: "",
  employee_signature_date: "",
  superintendent_signature: "",
  superintendent_signature_date: "",
  posted_date: "",
  prior_date: "",
  up_to_date: "",
  disclaimer_check: false,
};

const DATE_FORMAT = "MM/DD/YYYY";
const Payroll = ({ isSingleDoc, docDetails, adminMode, goBack }) => {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const [isFormLoading, setIsFormLoading] = React.useState(false);

  const handleOnSubmit = () => {
    setIsFormLoading(true);
    let values = getValues();

    // Remove dependent fields if it's not checked
    if (!values?.payroll_advance_check) {
      values.payroll_advance_amout = null;
      values.payroll_advance_recieved_date = null;
    }
    if (!values?.deduction_check) {
      values.deduction_amount = null;
      values.deduction_for = null;
    }
    if (!values?.allowance_check) {
      values.allowance_amount = null;
      values.allowance_for = null;
    }
    if (!values?.reimbursement_check) {
      values.reimbursement_amount = null;
      values.reimbursement_for = null;
    }
    // End
    values = mapEmptyToNull(values);
    const payload = {
      doc_type: docDetails?.docs_type_id,
      form: values,
      id: docDetails?.id,
      rehire: false,
      single_doc: true,
    };
    dispatch(docsActions.saveDoc(payload))
      .then((res) => {
        if (res?.success) {
          toast.success("Time off request submitted!", {
            position: "top-right",
            autoClose: 1000,
            ...toastConfig,
          });
          if (goBack) {
            goBack();
          }
        } else {
          throw res;
        }
      })
      .catch((err) => {
        setIsFormLoading(false);
        toast.error(err?.message ?? "Submission Failed", {
          position: "top-right",
          autoClose: 5000,
          ...toastConfig,
        });
      });
  };

  React.useEffect(() => {
    const docId = docDetails?.id;
    if (docId) {
      setIsFormLoading(true);
      dispatch(docsActions.getForm(docId))
        .then((res) => {
          if (res.success) {
            let initialData = res?.data?.form;
            let userData = res?.data?.user;
            if (!initialData?.employee_printed_name) {
              initialData.employee_printed_name = userData?.full_name ?? "";
            }
            reset(initialData);
          }
          setIsFormLoading(false);
        })
        .catch((err) => {
          setIsFormLoading(false);
          toast.error(err?.message ?? "Error on loading data!", {
            position: "top-right",
            autoClose: 5000,
            ...toastConfig,
          });
        });
    }
  }, []);

  const WatchDisclaimerCheck = watch("disclaimer_check");
  const WatchPayrollAdvanceCheck = watch("payroll_advance_check");
  const WatchDeductionCheck = watch("deduction_check");
  const WatchAllowanceCheck = watch("allowance_check");
  const WatchReimbursementCheck = watch("reimbursement_check");

  const datePickerNullSanitize = (date) =>
    date ? moment(date).format(DATE_FORMAT) : "";

  return (
    <ErrorBoundary>
      <FormContainer
        title="AUTHORIZATION FOR PAYROLL DEDUCTION / ALLOWANCE / REIMBURSEMENT"
        isLoading={isFormLoading}
      >
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Row className="form-row">
            <Col sm="12">
              <Row className="inline-flex">
                <Col sm={12} className="inline-flex custom--inline-flex">
                  <div className="helper-text">
                    <span>I,</span>
                  </div>
                  <Controller
                    rules={{ required: true }}
                    name="employee_printed_name"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        disabled={!adminMode}
                        label=""
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        error={errors.employee_printed_name}
                        placeholder="- Enter here -"
                        required
                      />
                    )}
                  />
                  <div className="helper-text helper-text--lg">
                    AN EMPLOYEE OF FIRESTORM WILDLAND FIRE SUPPRESSION, INC,
                    HEREBY REQUEST AND AUTHORIZE 1EMPLOYER TO MODIFY MY PAYCHECK
                    WITH THE FOLLOWING:{" "}
                  </div>
                </Col>
              </Row>
              <Row className="inline-flex">
                <Col sm={12}>
                  <div className="form-main-group">
                    <div className="form-sub-group">
                      <Row>
                        <Col sm={12}>
                          <Row>
                            <Col sm={6}>
                              <Controller
                                name="payroll_advance_check"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <Checkbox
                                    disabled={!adminMode}
                                    label="PAYROLL ADVANCE REPAYMENT of $"
                                    checked={Boolean(value)}
                                    onChange={(event) =>
                                      onChange(event?.target?.checked)
                                    }
                                    placeholder="- Enter here -"
                                  />
                                )}
                              />
                              <Controller
                                rules={{ required: WatchPayrollAdvanceCheck }}
                                name="payroll_advance_amout"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextInput
                                    disabled={!adminMode}
                                    label=""
                                    type="number"
                                    value={value}
                                    min={0}
                                    onChange={(event) =>
                                      onChange(
                                        event?.target?.value < 0
                                          ? 0
                                          : event?.target?.value,
                                      )
                                    }
                                    placeholder="- Enter Amount -"
                                    error={errors.payroll_advance_amout}
                                    required={WatchPayrollAdvanceCheck}
                                    onKeyDown={preventNegativeValueInDocs}
                                  />
                                )}
                              />
                            </Col>
                            <Col sm={6}>
                              <Controller
                                rules={{ required: WatchPayrollAdvanceCheck }}
                                name="payroll_advance_recieved_date"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <CustomDatePicker
                                    disabled={!adminMode}
                                    label="Received on"
                                    selected={value ? moment(value) : ""}
                                    onChange={(newDate) =>
                                      onChange(datePickerNullSanitize(newDate))
                                    }
                                    error={errors.payroll_advance_recieved_date}
                                    required={WatchPayrollAdvanceCheck}
                                  />
                                )}
                              />
                            </Col>
                          </Row>

                          <div>
                            <p>
                              This advance will be repaid at the rate $ (plus
                              fee if applicable) per pay period to commence this
                              pay period
                            </p>
                            <Controller
                              name="payroll_advance_repayment_rate"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <TextInput
                                  disabled={!adminMode}
                                  label=""
                                  type="number"
                                  value={value}
                                  min={0}
                                  onChange={(event) =>
                                    onChange(
                                      event?.target?.value < 0
                                        ? 0
                                        : event?.target?.value,
                                    )
                                  }
                                  placeholder="- Enter Amount -"
                                  onKeyDown={preventNegativeValueInDocs}
                                />
                              )}
                            />

                            <p>10% fee applied:</p>
                            <div className="form-group-radio">
                              <Controller
                                name="payroll_advance_fee_applied_check"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <>
                                    <RadioButton
                                      disabled={!adminMode}
                                      checked={value === "yes"}
                                      label="Yes"
                                      value="yes"
                                      name="payroll_advance_fee_applied_check"
                                      onChange={(event) =>
                                        onChange(event.target.value)
                                      }
                                    />
                                    <RadioButton
                                      disabled={!adminMode}
                                      checked={value === "no"}
                                      label="No"
                                      value="no"
                                      name="payroll_advance_fee_applied_check"
                                      onChange={(event) =>
                                        onChange(event.target.value)
                                      }
                                    />
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="form-sub-group">
                      <Row>
                        <Col sm={12}>
                          <Controller
                            name="deduction_check"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <Checkbox
                                disabled={!adminMode}
                                label="DEDUCTION $"
                                checked={Boolean(value)}
                                onChange={(event) =>
                                  onChange(event?.target?.checked)
                                }
                                placeholder="- Enter here -"
                              />
                            )}
                          />

                          <Controller
                            rules={{ required: WatchDeductionCheck }}
                            name="deduction_amount"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextInput
                                disabled={!adminMode}
                                label=""
                                type="number"
                                value={value}
                                min={0}
                                onChange={(event) =>
                                  onChange(
                                    event?.target?.value < 0
                                      ? 0
                                      : event?.target?.value,
                                  )
                                }
                                placeholder="- Enter Amount -"
                                error={errors.deduction_amount}
                                required={WatchDeductionCheck}
                                onKeyDown={preventNegativeValueInDocs}
                              />
                            )}
                          />
                        </Col>

                        <Col sm={12}>
                          <Controller
                            name="deduction_for"
                            rules={{ required: WatchDeductionCheck }}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextInput
                                disabled={!adminMode}
                                label="For"
                                type="text"
                                value={value}
                                onChange={(event) =>
                                  onChange(event?.target?.value)
                                }
                                placeholder="- Enter here -"
                                error={errors.deduction_for}
                                required={WatchDeductionCheck}
                              />
                            )}
                          />

                          <div>
                            <p>10% fee applied:</p>
                            <div className="form-group-radio">
                              <Controller
                                name="deduction_fee_applied_check"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <>
                                    <RadioButton
                                      disabled={!adminMode}
                                      checked={value === "yes"}
                                      label="Yes"
                                      value="yes"
                                      name="deduction_fee_applied_check"
                                      onChange={(event) =>
                                        onChange(event.target.value)
                                      }
                                    />
                                    <RadioButton
                                      disabled={!adminMode}
                                      checked={value === "no"}
                                      label="No"
                                      value="no"
                                      name="deduction_fee_applied_check"
                                      onChange={(event) =>
                                        onChange(event.target.value)
                                      }
                                    />
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="form-sub-group">
                      <Row>
                        <Col sm={12}>
                          <Controller
                            name="allowance_check"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <Checkbox
                                disabled={!adminMode}
                                label="ALLOWANCE $"
                                checked={Boolean(value)}
                                onChange={(event) =>
                                  onChange(event?.target?.checked)
                                }
                                placeholder="- Enter here -"
                              />
                            )}
                          />

                          <Controller
                            rules={{ required: WatchAllowanceCheck }}
                            name="allowance_amount"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextInput
                                disabled={!adminMode}
                                label=""
                                type="number"
                                value={value}
                                min={0}
                                onChange={(event) =>
                                  onChange(
                                    event?.target?.value < 0
                                      ? 0
                                      : event?.target?.value,
                                  )
                                }
                                placeholder="- Enter Amount -"
                                error={errors.allowance_amount}
                                required={WatchAllowanceCheck}
                                onKeyDown={preventNegativeValueInDocs}
                              />
                            )}
                          />
                        </Col>

                        <Col sm={12}>
                          <Controller
                            rules={{ required: WatchAllowanceCheck }}
                            name="allowance_for"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextInput
                                disabled={!adminMode}
                                label="For"
                                type="text"
                                value={value}
                                onChange={(event) =>
                                  onChange(event?.target?.value)
                                }
                                placeholder="- Enter here -"
                                error={errors.allowance_for}
                                required={WatchAllowanceCheck}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </div>

                    <div className="form-sub-group">
                      <Row>
                        <Col sm={12}>
                          <Controller
                            name="reimbursement_check"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <Checkbox
                                disabled={!adminMode}
                                label="REIMBURSEMENT $"
                                checked={Boolean(value)}
                                onChange={(event) =>
                                  onChange(event?.target?.checked)
                                }
                                placeholder="- Enter here -"
                              />
                            )}
                          />

                          <Controller
                            rules={{ required: WatchReimbursementCheck }}
                            name="reimbursement_amount"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextInput
                                disabled={!adminMode}
                                label=""
                                type="number"
                                value={value}
                                min={0}
                                onChange={(event) =>
                                  onChange(
                                    event?.target?.value < 0
                                      ? 0
                                      : event?.target?.value,
                                  )
                                }
                                placeholder="- Enter Amount -"
                                error={errors.reimbursement_amount}
                                required={WatchReimbursementCheck}
                                onKeyDown={preventNegativeValueInDocs}
                              />
                            )}
                          />
                        </Col>

                        <Col sm={12}>
                          <Controller
                            rules={{ required: WatchReimbursementCheck }}
                            name="reimbursement_for"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextInput
                                disabled={!adminMode}
                                label="For"
                                type="text"
                                value={value}
                                onChange={(event) =>
                                  onChange(event?.target?.value)
                                }
                                placeholder="- Enter here -"
                                error={errors.reimbursement_for}
                                required={WatchReimbursementCheck}
                              />
                            )}
                          />
                        </Col>
                        <Col sm={12}>
                          <p>
                            AT THE RATE OF $ (plus fee if applicable) PER PAY
                            PERIOD TO COMMENCE THIS PAY PERIOD.
                          </p>
                          <Controller
                            rules={{ required: true }}
                            name="rate"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextInput
                                disabled={!adminMode}
                                label=""
                                type="number"
                                value={value}
                                min={0}
                                onChange={(event) =>
                                  onChange(
                                    event?.target?.value < 0
                                      ? 0
                                      : event?.target?.value,
                                  )
                                }
                                placeholder="- Enter Amount -"
                                error={errors.rate}
                                required
                                onKeyDown={preventNegativeValueInDocs}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="signatureMainWrap">
            <Row>
              <Col md={6} sm={12}>
                <div>
                  <label className="control-label clearfix">
                    EMPLOYEE SIGNATURE:
                    {adminMode ? null : <span class="required-star">*</span>}
                  </label>
                </div>
                <Controller
                  rules={{ required: !adminMode }}
                  name="employee_signature"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CustomSignaturePad
                      value={value}
                      error={errors.employee_signature}
                      onSave={(signature) => onChange(signature)}
                    />
                  )}
                />
              </Col>
              <Col md={6} sm={12}>
                <Controller
                  rules={{ required: !adminMode }}
                  name="employee_signature_date"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CustomDatePicker
                      label="Date"
                      selected={value ? moment(value) : ""}
                      onChange={(newDate) =>
                        onChange(datePickerNullSanitize(newDate))
                      }
                      error={errors.employee_signature_date}
                      required={!adminMode}
                    />
                  )}
                />
              </Col>
            </Row>
          </div>

          <div className="signatureMainWrap">
            <Row>
              <Col md={6} sm={12}>
                <div>
                  <label className="control-label clearfix">
                    APPROVED BY:
                    <span class="required-star">*</span>
                  </label>
                </div>
                <Controller
                  rules={{ required: true }}
                  name="superintendent_signature"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CustomSignaturePad
                      disabled={!adminMode}
                      error={errors.superintendent_signature}
                      value={value}
                      onSave={(signature) => onChange(signature)}
                    />
                  )}
                />
              </Col>
              <Col md={6} sm={12}>
                <Controller
                  rules={{ required: true }}
                  name="superintendent_signature_date"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CustomDatePicker
                      disabled={!adminMode}
                      label="Date"
                      selected={value ? moment(value) : ""}
                      onChange={(newDate) =>
                        onChange(datePickerNullSanitize(newDate))
                      }
                      required
                    />
                  )}
                />
              </Col>
            </Row>
          </div>
          {adminMode ? (
            <div className="officeBlock">
              <p className="officeBlock__title">
                <span>PLEASE RETURN FORM TO THE PAYROLL OFFICE </span>
                <span>(naomi.tora@fwfsinc.com)</span>
                <span>Rev 2021</span>
              </p>
              <Row>
                <Col xs={12} md={4}>
                  <Controller
                    name="posted_date"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomDatePicker
                        label="Posted Date:"
                        selected={value ? moment(value) : ""}
                        onChange={(newDate) =>
                          onChange(datePickerNullSanitize(newDate))
                        }
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <Controller
                    name="prior_date"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomDatePicker
                        label="Prior:"
                        selected={value ? moment(value) : ""}
                        onChange={(newDate) =>
                          onChange(datePickerNullSanitize(newDate))
                        }
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <Controller
                    name="up_to_date"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomDatePicker
                        label="Up to:"
                        selected={value ? moment(value) : ""}
                        onChange={(newDate) =>
                          onChange(datePickerNullSanitize(newDate))
                        }
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
          ) : null}

          <div className="row form-row">
            <div className="col">
              <div className="disclaimers">
                <strong className="diss">Disclaimer: </strong>You must read and
                carefully understand the documents that you are filling out and
                signing. These are legally binding documents and must be
                accurate. By filling these forms out, you are agreeing to our{" "}
                <a
                  href="https://firestormfire.com/terms-of-use/"
                  target="blank"
                >
                  Terms of Use, Privacy Policy and Employee Agreements.
                </a>
              </div>
            </div>
          </div>

          <div className="disclaimer_checkbox">
            <Controller
              name="disclaimer_check"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  label=" I have confirmed the above details are true and valid."
                  value={value}
                  checked={Boolean(value)}
                  onChange={(event) => onChange(event?.target?.checked)}
                  placeholder="- Enter here -"
                />
              )}
            />
          </div>
          <div className="submit_button_wrapper">
            <SubmitButton
              disabled={!WatchDisclaimerCheck}
              type="submit"
              label="Submit Document"
            />
          </div>
        </form>
      </FormContainer>
    </ErrorBoundary>
  );
};

export default Payroll;
