import React, { useEffect, useState } from "react";
import styles from "./interview.module.scss";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { InterviewFormList } from "./InterviewFormList";
import { InterviewForm } from "./InterviewForm";
import { Complete } from "./Complete";
import { interviewActions } from "_actions";
import {
  getInterviewFormList as getInterviewListStore,
  getCurrentUserInfo,
  isInterviewFomLoading,
} from "_helpers/reduxSelector";
import { Loader } from "./InterviewFormLoader";
import ReferenceForm from "./ReferenceForm";
import { withRouter } from "react-router";

const InterviewTab = ({ hash, applicant, listOnly, ...props }) => {
  const dispatch = useDispatch();

  const [isNew, setIsNew] = useState(false);
  const [newFormId, setNewFormId] = useState("");
  const [isSubmittable, setIsSubmittable] = useState(false);

  const interviewFormList = useSelector((state) =>
    getInterviewListStore(state),
  );
  const user = useSelector((state) => getCurrentUserInfo(state));
  const [currentView, setCurrentView] = useState("listView");

  const [formId, setFormId] = useState("");
  const permissions =
    useSelector((state) => state.permission?.permissions?.data) || [];

  const loading = useSelector((state) => isInterviewFomLoading(state));

  const getInterviewFormList = (newPage, sort, sortOder) => {
    let data = new FormData();
    const userType = listOnly ? 2 : 1;
    data.append("page", newPage || 0);
    data.append("sort", sort || "interviewer");
    data.append("order", sortOder || "asc");
    data.append("hash", hash);
    data.append("user_type", userType);
    dispatch(interviewActions.getInterviewFormList(data));
  };

  const getCurrentComponent = () => {
    let View = <React.Fragment></React.Fragment>;
    switch (currentView) {
      case "listView": {
        View = (
          <InterviewFormList
            interviewFormList={interviewFormList?.data}
            getInterviewFormList={getInterviewFormList}
            lastPage={interviewFormList?.last_page}
            setCurrentView={setCurrentView}
            setFormId={setFormId}
          />
        );
        break;
      }
      case "referenceForm":
        View = (
          <ReferenceForm
            applicant={applicant}
            user={user}
            isNew={isNew}
            setIsNew={setIsNew}
            setCurrentView={setCurrentView}
            hash={hash}
            editMode
            formId={formId}
            getInterviewFormList={getInterviewFormList}
            newFormId={newFormId}
            setNewFormId={setNewFormId}
            isSubmittable={isSubmittable}
            setIsSubmittable={setIsSubmittable}
          />
        );
        break;
      case "interviewForm":
        View = (
          <InterviewForm
            hash={hash}
            applicant={applicant}
            setCurrentView={setCurrentView}
            editMode
            user={user}
            formId={formId}
            getInterviewFormList={getInterviewFormList}
            newFormId={newFormId}
            referenceFormCompleted={true}
            isSubmittable={isSubmittable}
          />
        );
        break;
      case "draftComplete":
        View = (
          <Complete
            setCurrentView={setCurrentView}
            type="draft"
            getInterviewFormList={getInterviewFormList}
          />
        );
        break;
      case "submitComplete":
        View = (
          <Complete
            setCurrentView={setCurrentView}
            type="submit"
            getInterviewFormList={getInterviewFormList}
          />
        );
        break;
      default:
        View = (
          <InterviewFormList
            interviewFormList={interviewFormList?.interview_forms}
            getInterviewFormList={getInterviewFormList}
            lastPage={interviewFormList?.last_page || 1}
          />
        );
        break;
    }
    return View;
  };
  // -----------------------------------------------------------------//
  const onAddClick = () => {
    setCurrentView("referenceForm");
    setIsNew(true);
    setFormId("");
  };

  useEffect(() => {
    getInterviewFormList();
  }, [newFormId]);

  let canAddInterviewForms = props.location.pathname.includes("applicant-view");

  return (
    <>
      {loading && <Loader />}
      <div className={styles.int_tab_container}>
        {!listOnly && (
          <div className="int_tab_header">
            {currentView === "listView" ? (
              <h3>Interview Forms</h3>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            <div className={styles.int_btn_container}>
              {currentView === "listView" &&
                canAddInterviewForms &&
                permissions.includes("Manage Interview Forms") && (
                  <button
                    className={cx("button", styles.add_int_btn)}
                    onClick={onAddClick}
                  >
                    Add Interview Form
                  </button>
                )}
            </div>
          </div>
        )}
        <div className={styles.int_form_container}>{getCurrentComponent()}</div>
      </div>
    </>
  );
};

export default withRouter(InterviewTab);
