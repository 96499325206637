import React from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { authHeader, config } from "../_helpers";
import { ListResources } from "./ListResources";
import { ResourceDetails } from "./ResourceDetails";
import { Profile } from "./Profile";
import { EditProfile } from "./EditProfile";
import { EditResource } from "./EditResource";
import { CreateManifest } from "../Manifest/CreateManifest";
import { SortYourCrew } from "../Manifest/SortYourCrew";
import { SelectCrewPosition } from "../Manifest/SelectCrewPosition";
import { VehicleList } from "../Manifest/VehicleList";
import { Finalize } from "../Manifest/Finalize";
import ManifestVersion from "../Manifest/ManifestVersion";
import { ArchivedResourcesDetails } from "./ArchivedResourcesDetails";
import ResourceOrderForm from "Docs/Forms/ResourceOrderForm/ResourceOrderForm";
import "../assets/styles/resource.css";

class Resources extends React.Component {
  constructor(props) {
    super(props);

    var step, id, isArchiveResources;
    if (this.props.match.path === "/resources/:id") {
      step = "Details";
      id = this.props.match.params.id;
    } else if (this.props.match.path === "/resource-profile/:hash/:step") {
      step = "Profile";
      id = this.props.match.params.hash;
    } else {
      step = "List";
      id = "";
    }

    if (this.props.match.path === "/archived-resources/:id") {
      isArchiveResources = true;
      id = this.props.match.params.id;
    }
    this.state = {
      mainHtml: "",
      step: step,
      button: "",
      props: id,
      hash: "",
      id: id,
      isArchiveResources,
      resource_id: "",
      manifest_id: "",
      contract_number_id: "",
      dispatch_location: "",
      prevtype: "",
      type: "",
      prev: false,
      vPrev: false,
      edit: false,
      permissions: [],
      redirect: "",
      ResourceOrderData: {},
      assignedManifest: false,
      assignedDateOn: null,
      assignedDateOff: null,
      originalVehicles: [],
      originalCrews: [],
    };
    this.changeTab = this.changeTab.bind(this);
    this.profile = this.profile.bind(this);
    this.editResource = this.editResource.bind(this);
    this.details = this.details.bind(this);
    this.createManifest = this.createManifest.bind(this);
    this.yourCrew = this.yourCrew.bind(this);
    this.goToType = this.goToType.bind(this);
    this.toProjectPositionSelection =
      this.toProjectPositionSelection.bind(this);
    this.toVehicle = this.toVehicle.bind(this);
    this.goToCrew = this.goToCrew.bind(this);
    this.finalize = this.finalize.bind(this);
    this.goToVehicle = this.goToVehicle.bind(this);
    this.editManifest = this.editManifest.bind(this);
    this.viewManifest = this.viewManifest.bind(this);
    this.goBackDetails = this.goBackDetails.bind(this);
    this.editProfile = this.editProfile.bind(this);
  }

  changeTab(tab) {
    this.setState({ step: tab }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  details(props) {
    this.setState(
      { step: "Details", props: props, edit: false, prev: false },
      function () {
        this.componentWillReceiveProps(this.props);
      },
    );
  }

  profile(hash) {
    this.setState({ step: "Profile", hash: hash }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  editProfile(hash) {
    this.setState({ step: "EditProfile", hash: hash }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  editResource(id) {
    this.setState({ step: "Edit", id: id }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  createManifest() {
    this.setState({ step: "CreateManifest" }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  yourCrew(type, contract_number_id, dispatch_location) {
    this.setState(
      { step: "SortYourCrew", type, contract_number_id, dispatch_location },
      function () {
        this.componentWillReceiveProps(this.props);
      },
    );
  }

  goToType(type) {
    this.setState(
      { step: "CreateManifest", prevtype: type, prev: false },
      function () {
        this.componentWillReceiveProps(this.props);
      },
    );
  }

  goToCrew(crews) {
    this.setState(
      { step: "SortYourCrew", savedCrews: crews, prev: true },
      function () {
        this.componentWillReceiveProps(this.props);
      },
    );
  }

  toVehicle(crews) {
    this.setState(
      { step: "VehicleList", savedCrews: crews, crews: crews },
      function () {
        this.componentWillReceiveProps(this.props);
      },
    );
  }

  toProjectPositionSelection(crews) {
    this.setState(
      { step: "SelectCrewPosition", savedCrews: crews },
      function () {
        this.componentWillReceiveProps(this.props);
      },
    );
  }

  goToVehicle(crews) {
    this.setState(
      {
        step: "VehicleList",
        crews: crews,
        vPrev: true,
        assignedManifest: this.state.assignedManifest,
      },
      function () {
        this.componentWillReceiveProps(this.props);
      },
    );
  }

  storeAssignedStatus(status) {
    this.setState({ assignedManifest: status });
  }

  storeAssignedDateOff(date_off) {
    this.setState({ assignedDateOff: date_off });
  }

  storeAssignedDateOn(date_on) {
    this.setState({ assignedDateOn: date_on });
  }

  storeOriginalVehicles(vehicles) {
    this.setState({ originalVehicles: vehicles });
  }

  storeOriginalCrews(crews) {
    this.setState({ originalCrews: crews });
  }

  finalize(vehicles) {
    this.setState({ step: "Finalize", vehicles: vehicles }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }
  goBackDetails() {
    this.setState({ step: "List", prev: false }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  editManifest(id, type) {
    this.setState(
      { step: "SortYourCrew", manifest_id: id, type, edit: true },
      function () {
        this.componentWillReceiveProps(this.props);
      },
    );
  }

  editRof(roData) {
    this.setState(
      { step: "CreatedRO", ResourceOrderData: roData },
      function () {
        this.componentWillReceiveProps(this.props);
      },
    );
  }

  viewManifest(id, type) {
    this.setState(
      { step: "ViewManifest", manifest_id: id, type: type },
      function () {
        this.componentWillReceiveProps(this.props);
      },
    );
  }

  componentDidMount() {
    if (localStorage.getItem("user") === null) {
      this.setState({
        redirect: "/login",
      });
    }
    this.componentWillReceiveProps(this.props);
    var self = this;
    fetch(config.apiUrl + "/api/permission/getuserpermissions", {
      headers: authHeader(),
      method: "GET",
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        self.setState({ permissions: data.data });
      })
      .catch((err) => console.log(err));
    // this.props.dispatch(resourceActions.getAllVehicles());
  }

  componentWillReceiveProps(nextProps) {
    var options;
    if (this.state.step === "List") {
      let assignmentStatus =
        this.props.location.state && this.props.location.state.assignmentStats
          ? this.props.location.state.assignmentStats
          : "";
      options = (
        <div>
          <ListResources
            details={this.details.bind(this)}
            assignmentStatus={assignmentStatus}
          />
        </div>
      );
    }

    if (this.state.step === "Details") {
      options = (
        <div>
          <ResourceDetails
            goBack={this.changeTab.bind(this, "List")}
            props={this.state.props}
            profile={this.profile.bind(this)}
            editResource={this.editResource.bind(this)}
            createManifest={this.createManifest.bind(this)}
            editManifest={this.editManifest.bind(this)}
            viewManifest={this.viewManifest.bind(this)}
            details={this.details.bind(this)}
            editRof={this.editRof.bind(this)}
          />
        </div>
      );
    }

    if (this.state.step === "Profile") {
      options = (
        <div>
          <Profile
            {...this.props}
            goBack={this.details.bind(this)}
            props={this.state.props}
            hash={this.state.hash}
            resourceDetails={this.state.props}
            id={this.state.id}
            editProfile={this.editProfile}
            toResourceList={this.goBackDetails}
          />
        </div>
      );
    }

    if (this.state.step === "EditProfile") {
      options = (
        <div>
          <EditProfile
            goBack={this.profile.bind(this)}
            hash={this.state.hash}
            resourceDetails={this.state.props}
            toResourceList={this.goBackDetails}
            id={this.state.id}
          />
        </div>
      );
    }

    if (this.state.step === "Edit") {
      options = (
        <div>
          <EditResource
            goBack={this.details.bind(this)}
            id={this.state.props}
          />
        </div>
      );
    }

    if (this.state.step === "CreateManifest") {
      options = (
        <div>
          <CreateManifest
            yourCrew={this.yourCrew}
            // crew_id={this.state.crew_id}
            props={nextProps}
            type={this.state.prevtype}
            contract_number_id={this.state.contract_number_id}
            cancel={this.details.bind(this)}
          />
        </div>
      );
    }

    if (this.state.step === "SortYourCrew") {
      options = (
        <div>
          <SortYourCrew
            resource={this.state.props}
            type={this.state.type}
            previous={this.goToType}
            next={this.toProjectPositionSelection.bind(this)}
            assignedManifest={this.state.assignedManifest}
            assignedDateOn={this.state.assignedDateOn}
            assignedDateOff={this.state.assignedDateOff}
            originalCrews={this.state.originalCrews}
            storeAssignedManifest={this.storeAssignedStatus.bind(this)}
            storeAssignedDateOn={this.storeAssignedDateOn.bind(this)}
            storeAssignedDateOff={this.storeAssignedDateOff.bind(this)}
            storeOriginalCrews={this.storeOriginalCrews.bind(this)}
            crews={this.state.crews}
            savedCrews={this.state.savedCrews}
            prev={this.state.prev}
            edit={this.state.edit}
            manifest_id={this.state.manifest_id}
            cancel={this.details.bind(this)}
          />
        </div>
      );
    }

    if (this.state.step === "SelectCrewPosition") {
      options = (
        <div>
          <SelectCrewPosition
            savedCrews={this.state.savedCrews}
            crew_id={this.state.crew_id}
            type={this.state.type}
            previous={this.goToCrew.bind(this)}
            next={this.toVehicle.bind(this)}
          />
        </div>
      );
    }

    if (this.state.step === "CreatedRO") {
      options = (
        <div>
          <div className="applicants_container sort_your_crew">
            <div className="breadcrumbs">
              <Link to={"/resources"} className="green">
                Resources
              </Link>{" "}
              / <span onClick={this.details.bind(this)}>Details</span> / Edit
              Resource Order
            </div>
            <div className="page_title float">
              <h1>Edit Resource Order</h1>
            </div>
          </div>
          <ResourceOrderForm
            resourceDetails={this.state.props}
            editMode={true}
            rofData={this.state.ResourceOrderData}
            onSubmit={this.details.bind(this)}
            onCancel={this.details.bind(this)}
          />
        </div>
      );
    }

    if (this.state.step === "VehicleList") {
      options = (
        <div>
          <VehicleList
            type={this.state.type}
            savedCrews={this.state.savedCrews}
            resource={this.state.props}
            previous={this.toProjectPositionSelection.bind(this)}
            next={this.finalize.bind(this)}
            assignedManifest={this.state.assignedManifest}
            assignedDateOn={this.state.assignedDateOn}
            assignedDateOff={this.state.assignedDateOff}
            originalVehicles={this.state.originalVehicles}
            storeAssignedManifest={this.storeAssignedStatus.bind(this)}
            storeAssignedDateOn={this.storeAssignedDateOn.bind(this)}
            storeAssignedDateOff={this.storeAssignedDateOff.bind(this)}
            storeOriginalVehicles={this.storeOriginalVehicles.bind(this)}
            SelVehicles={this.state.vehicles}
            prev={this.state.vPrev}
            edit={this.state.edit}
            manifest_id={this.state.manifest_id}
            cancel={this.details.bind(this)}
          />
        </div>
      );
    }

    if (this.state.step === "Finalize") {
      options = (
        <Finalize
          crews={this.state.crews}
          resourceDetails={this.state.props}
          vehicles={this.state.vehicles}
          details={this.details.bind(this)}
          manifestType={this.state.type}
          contract_number_id={this.state.contract_number_id}
          dispatch_location={this.state.dispatch_location}
          previous={this.goToVehicle.bind(this)}
          edit={this.state.edit}
          manifest_id={this.state.manifest_id}
        />
      );
    }

    if (this.state.step === "ViewManifest") {
      const { history } = this.props;
      history.push(
        `/resources/${this.state.id}/manifest/${this.state.manifest_id}`,
      );
      // options = (
      //   <div>
      //     <ManifestVersion
      //       resourceId={this.state.id}
      //       manifestType={this.state.type}
      //       manifest_id={this.state.manifest_id}
      //     />
      //     {/* <ViewManifest
      //       resourceDetails={this.state.props}
      //       manifestType={this.state.type}
      //       manifest_id={this.state.manifest_id}
      //     /> */}
      //   </div>
      // );
    }

    if (this?.state?.isArchiveResources) {
      options = (
        <div>
          <ArchivedResourcesDetails
            goBack={this.changeTab.bind(this, "List")}
            props={this.state.props}
            profile={this.profile.bind(this)}
            editResource={this.editResource.bind(this)}
            createManifest={this.createManifest.bind(this)}
            editManifest={this.editManifest.bind(this)}
            viewManifest={this.viewManifest.bind(this)}
            details={this.details.bind(this)}
          />
        </div>
      );
    }

    this.setState({ mainHtml: options });
  }
  // if(this.state.step === 'CreateManifest') {

  //     options = <div>
  //                 <CreateManifest goBack={this.changeTab.bind(this,'Details')} hash={this.state.hash}/>
  //             </div>
  // }

  render() {
    if (this.state.redirect === "/login") {
      return <Redirect to="/login" />;
    }
    const { permissions } = this.state;
    return (
      permissions.length > 0 &&
      (!permissions.includes("Manage Resources") ? (
        <div className="content_div">
          <div className="no_permission">No permission to this page</div>
        </div>
      ) : (
        <div className="hello-world">{this.state.mainHtml}</div>
      ))
    );
  }
}

function mapStateToProps(state) {
  const { vehicles } = state;
  return {
    vehicles,
  };
}

const connectedResourcePage = connect(mapStateToProps)(Resources);
export { connectedResourcePage as Resources };
