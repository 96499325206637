import React, { useRef, useState } from "react";
import TORRequests from "./TORRequests";
import { Tab, Row, Col, NavItem, Nav } from "react-bootstrap";
import cx from "classnames";

const Sort = {
  Asc: "asc",
  Dsc: "desc",
};

function TORDocumentsContainer() {
  const [globalSort, setGlobalSort] = useState("updated_at");
  const [globalOrder, setGlobalOrder] = useState("desc");
  const [activeTab, setActiveTab] = useState("pending");
  const torTypes = [
    {
      title: "Pending Approval",
      key: "pending",
    },
    {
      title: "Approved Requests",
      key: "approved",
    },
    {
      title: "Denied Requests",
      key: "denied",
    },
    {
      title: "Expired Requests",
      key: "expired",
    },
  ];

  const setSortParams = (sort, order) => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    setGlobalSort(sort);
    setGlobalOrder(sortOrder);
  };

  return (
    <section className="user-docs">
      <h1 className="page_title">TOR Documents</h1>
      <div className="white">
        <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
          <Tab.Container id="tabs-with-dropdown" activeKey={activeTab}>
            <Row>
              <Col sm={12}>
                <div>
                  <Nav bsStyle="tabs">
                    {torTypes.map((type) => (
                      <NavItem
                        eventKey={type.key}
                        className="custom-tab tor-tab"
                        key={type.key}
                        onClick={() => setActiveTab(type.key)}
                      >
                        {type.title}
                      </NavItem>
                    ))}
                  </Nav>
                </div>
              </Col>
              <Col sm={12}>
                <div className="custom-tab-content">
                  <Tab.Content animation>
                    <Tab.Pane eventKey="pending">
                      <TORRequests
                        type="pending"
                        setSortParams={setSortParams}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="approved">
                      <TORRequests
                        type="approved"
                        setSortParams={setSortParams}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="denied">
                      <TORRequests
                        type="denied"
                        setSortParams={setSortParams}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="expired">
                      <TORRequests
                        type="expired"
                        setSortParams={setSortParams}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </section>
  );
}

export default TORDocumentsContainer;
