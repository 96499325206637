export const dashboardConstants = {
  GETBULLETIN_REQUEST: "DASHBOARD_GETBULLETIN_REQUEST",
  GETBULLETIN_SUCCESS: "DASHBOARD_GETBULLETIN_SUCCESS",
  GETBULLETIN_FAILURE: "DASHBOARD_GETBULLETIN_FAILURE",

  // VIEWAPPLN_REQUEST: 'DASHBOARD_VIEWAPPLN_REQUEST',
  // VIEWAPPLN_SUCCESS: 'DASHBOARD_VIEWAPPLN_SUCCESS',
  // VIEWAPPLN_FAILURE: 'DASHBOARD_VIEWAPPLN_FAILURE',

  EXPIRATION_DATES_REQUEST: "EXPIRATION_DATES_REQUEST",
  EXPIRATION_DATES_SUCCESS: "EXPIRATION_DATES_SUCCESS",
  EXPIRATION_DATES_ERROR: "EXPIRATION_DATES_ERROR",

  QUALIFICATIONS_REQUEST: "QUALIFICATIONS_REQUEST",
  QUALIFICATIONS_SUCCESS: "QUALIFICATIONS_SUCCESS",
  QUALIFICATIONS_ERROR: "QUALIFICATIONS_ERROR",

  QUALIFICATION_REPORT_REQUEST: "QUALIFICATION_REPORT_REQUEST",
  QUALIFICATION_REPORT_SUCCESS: "QUALIFICATION_REPORT_SUCCESS",
  QUALIFICATION_REPORT_ERROR: "QUALIFICATION_REPORT_ERROR",

  VEHICLE_REPORT_REQUEST: "VEHICLE_REPORT_REQUEST",
  VEHICLE_REPORT_SUCCESS: "VEHICLE_REPORT_SUCCESS",
  VEHICLE_REPORT_ERROR: "VEHICLE_REPORT_ERROR",

  AFFIRMATIVE_ACTION_REPORT_REQUEST: "AFFIRMATIVE_ACTION_REPORT_REQUEST",
  AFFIRMATIVE_ACTION_REPORT_SUCCESS: "AFFIRMATIVE_ACTION_REPORT_SUCCESS",
  AFFIRMATIVE_ACTION_REPORT_ERROR: "AFFIRMATIVE_ACTION_REPORT_ERROR",

  EMPLOYEE_BIRTHDAY_REQUEST: "EMPLOYEE_BIRTHDAY_REQUEST",
  EMPLOYEE_BIRTHDAY_SUCCESS: "EMPLOYEE_BIRTHDAY_SUCCESS",
  EMPLOYEE_BIRTHDAY_ERROR: "EMPLOYEE_BIRTHDAY_ERROR",

  RESOURCE_ORDER_FORMS_REQUEST: "RESOURCE_ORDER_FORMS_REQUEST",
  RESOURCE_ORDER_FORMS_SUCCESS: "RESOURCE_ORDER_FORMS_SUCCESS",
  RESOURCE_ORDER_FORMS_ERROR: "RESOURCE_ORDER_FORMS_ERROR",

  CREATED_RESOURCE_ORDER_FORMS_REQUEST: "CREATED_RESOURCE_ORDER_FORMS_REQUEST",
  CREATED_RESOURCE_ORDER_FORMS_SUCCESS: "CREATED_RESOURCE_ORDER_FORMS_SUCCESS",
  CREATED_RESOURCE_ORDER_FORMS_ERROR: "CREATED_RESOURCE_ORDER_FORMS_ERROR",
  EQU_SOLD_REPORT_REQUEST: "EQU_SOLD_REPORT_REQUEST",
  EQU_SOLD_REPORT_SUCCESS: "EQU_SOLD_REPORT_SUCCESS",
  EQU_SOLD_REPORT_ERROR: "EQU_SOLD_REPORT_ERROR",

  ALL_INV_REPORT_REQUEST: "ALL_INV_REPORT_REQUEST",
  ALL_INV_REPORT_SUCCESS: "ALL_INV_REPORT_SUCCESS",
  ALL_INV_REPORT_ERROR: "ALL_INV_REPORT_ERROR",

  GET_REFRESHER_REPORT_REQUEST: "GET_REFRESHER_REPORT_REQUEST",
  GET_REFRESHER_REPORT_SUCCESS: "GET_REFRESHER_REPORT_SUCCESS",
  GET_REFRESHER_REPORT_ERROR: "GET_REFRESHER_REPORT_ERROR",

  GET_MASTER_REPORT_REQUEST: "GET_MASTER_REPORT_REQUEST",
  GET_MASTER_REPORT_SUCCESS: "GET_MASTER_REPORT_SUCCESS",
  GET_MASTER_REPORT_ERROR: "GET_MASTER_REPORT_ERROR",

  GET_MASTER_REPORT_ALL_EMPLOYEES_REQUEST:
    "GET_MASTER_REPORT_ALL_EMPLOYEES_REQUEST",
  GET_MASTER_REPORT_ALL_EMPLOYEES_SUCCESS:
    "GET_MASTER_REPORT_ALL_EMPLOYEES_SUCCESS",
  GET_MASTER_REPORT_ALL_EMPLOYEES_ERROR:
    "GET_MASTER_REPORT_ALL_EMPLOYEES_ERROR",

  MILEAGE_HOBBS_REPORT_REQUEST: "MILEAGE_HOBBS_REPORT_REQUEST",
  MILEAGE_HOBBS_REPORT_SUCCESS: "MILEAGE_HOBBS_REPORT_SUCCESS",
  MILEAGE_HOBBS_REPORT_ERROR: "MILEAGE_HOBBS_REPORT_ERROR",

  APPLICANTS_REPORT_REQUEST: "APPLICANTS_REPORT_REQUEST",
  APPLICANTS_REPORT_SUCCESS: "APPLICANTS_REPORT_SUCCESS",
  APPLICANTS_REPORT_ERROR: "APPLICANTS_REPORT_ERROR",

  ACTIVITY_LOG_REQUEST: "ACTIVITY_LOG_REQUEST",
  ACTIVITY_LOG_SUCCESS: "ACTIVITY_LOG_SUCCESS",
  ACTIVITY_LOG_ERROR: "ACTIVITY_LOG_ERROR",

  WARNING_MANAGEMENT_REQUEST: "WARNING_MANAGEMENT_REQUEST",
  WARNING_MANAGEMENT_SUCCESS: "WARNING_MANAGEMENT_SUCCESS",
  WARNING_MANAGEMENT_ERROR: "WARNING_MANAGEMENT_ERROR",

  WARNING_MANAGEMENT_ADD_ENTRY_REQUEST: "WARNING_MANAGEMENT_ADD_ENTRY_REQUEST",
  WARNING_MANAGEMENT_ADD_ENTRY_SUCCESS: "WARNING_MANAGEMENT_ADD_ENTRY_SUCCESS",
  WARNING_MANAGEMENT_ADD_ENTRY_ERROR: "WARNING_MANAGEMENT_ADD_ENTRY_ERROR",

  WARNING_MANAGEMENT_DELETE_REQUEST: "WARNING_MANAGEMENT_DELETE_REQUEST",
  WARNING_MANAGEMENT_DELETE_SUCCESS: "WARNING_MANAGEMENT_DELETE_SUCCESS",
  WARNING_MANAGEMENT_DELETE_ERROR: "WARNING_MANAGEMENT_DELETE_ERROR",

  UPLOAD_FILE_REQUEST: "UPLOAD_FILE_REQUEST",
  UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
  UPLOAD_FILE_FAIL: "UPLOAD_FILE_FAIL",

  GET_EMPLOYEES_FIRE_HISTORY_REQUEST: "GET_EMPLOYEES_FIRE_HISTORY_REQUEST",
  GET_EMPLOYEES_FIRE_HISTORY_SUCCESS: "GET_EMPLOYEES_FIRE_HISTORY_SUCCESS",
  GET_EMPLOYEES_FIRE_HISTORY_ERROR: "GET_EMPLOYEES_FIRE_HISTORY_ERROR",

  GET_INDIVIDUAL_FIRE_HISTORY_REQUEST: "GET_INDIVIDUAL_FIRE_HISTORY_REQUEST",
  GET_INDIVIDUAL_FIRE_HISTORY_SUCCESS: "GET_INDIVIDUAL_FIRE_HISTORY_SUCCESS",
  GET_INDIVIDUAL_FIRE_HISTORY_ERROR: "GET_INDIVIDUAL_FIRE_HISTORY_ERROR",

  UPDATE_INDIVIDUAL_FIRE_HISTORY_ENTRY_REQUEST:
    "UPDATE_INDIVIDUAL_FIRE_HISTORY_ENTRY_REQUEST",
  UPDATE_INDIVIDUAL_FIRE_HISTORY_ENTRY_SUCCESS:
    "UPDATE_INDIVIDUAL_FIRE_HISTORY_ENTRY_SUCCESS",
  UPDATE_INDIVIDUAL_FIRE_HISTORY_ENTRY_ERROR:
    "UPDATE_INDIVIDUAL_FIRE_HISTORY_ENTRY_ERROR",

  DELETE_INDIVIDUAL_FIRE_HISTORY_REQUEST:
    "DELETE_INDIVIDUAL_FIRE_HISTORY_REQUEST",
  DELETE_INDIVIDUAL_FIRE_HISTORY_SUCCESS:
    "DELETE_INDIVIDUAL_FIRE_HISTORY_SUCCESS",
  DELETE_INDIVIDUAL_FIRE_HISTORY_ERROR: "DELETE_INDIVIDUAL_FIRE_HISTORY_ERROR",

  VERIFY_FIRE_HISTORY_REQUEST: "VERIFY_FIRE_HISTORY_REQUEST",
  VERIFY_FIRE_HISTORY_SUCCESS: "VERIFY_FIRE_HISTORY_SUCCESS",
  VERIFY_FIRE_HISTORY_ERROR: "VERIFY_FIRE_HISTORY_ERROR",

  GET_FIRE_HISTORY_REPORT_REQUEST: "GET_FIRE_HISTORY_REPORT_REQUEST",
  GET_FIRE_HISTORY_REPORT_SUCCESS: "GET_FIRE_HISTORY_REPORT_SUCCESS",
  GET_FIRE_HISTORY_REPORT_FAILURE: "GET_FIRE_HISTORY_REPORT_FAILURE",

  GET_DOL_APPROVED_DRIVER_REPORT_REQUEST:
    "GET_DOL_APPROVED_DRIVER_REPORT_REQUEST",
  GET_DOL_APPROVED_DRIVER_REPORT_SUCCESS:
    "GET_DOL_APPROVED_DRIVER_REPORT_SUCCESS",
  GET_DOL_APPROVED_DRIVER_REPORT_FAILURE:
    "GET_DOL_APPROVED_DRIVER_REPORT_FAILURE",
};
