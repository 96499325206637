export const profileConstants = {
  GET_RESOURCE_TYPES_AND_EMPLOYEES_REQUEST:
    "GET_RESOURCE_TYPES_AND_EMPLOYEES_REQUEST",
  GET_RESOURCE_TYPES_AND_EMPLOYEES_SUCCESS:
    "GET_RESOURCE_TYPES_AND_EMPLOYEES_SUCCESS",
  GET_RESOURCE_TYPES_AND_EMPLOYEES_FAILURE:
    "GET_RESOURCE_TYPES_AND_EMPLOYEES_FAILURE",

  GET_RESOURCE_RECIPIENTS_REQUEST: "GET_RESOURCE_RECIPIENTS_REQUEST",
  GET_RESOURCE_RECIPIENTS_SUCCESS: "GET_RESOURCE_RECIPIENTS_SUCCESS",
  GET_RESOURCE_RECIPIENTS_FAILURE: "GET_RESOURCE_RECIPIENTS_FAILURE",

  ADD_RESOURCE_RECIPIENT_TOR_REQUEST: "ADD_RESOURCE_RECIPIENT_TOR_REQUEST",
  ADD_RESOURCE_RECIPIENT_TOR_SUCCESS: "ADD_RESOURCE_RECIPIENT_TOR_SUCCESS",
  ADD_RESOURCE_RECIPIENT_TOR_FAILURE: "ADD_RESOURCE_RECIPIENT_TOR_FAILURE",

  EDIT_RESOURCE_RECIPIENT_TOR_REQUEST: "EDIT_RESOURCE_RECIPIENT_TOR_REQUEST",
  EDIT_RESOURCE_RECIPIENT_TOR_SUCCESS: "EDIT_RESOURCE_RECIPIENT_TOR_SUCCESS",
  EDIT_RESOURCE_RECIPIENT_TOR_FAILURE: "EDIT_RESOURCE_RECIPIENT_TOR_FAILURE",

  DELETE_RESOURCE_RECIPIENT_TOR_REQUEST:
    "DELETE_RESOURCE_RECIPIENT_TOR_REQUEST",
  DELETE_RESOURCE_RECIPIENT_TOR_SUCCESS:
    "DELETE_RESOURCE_RECIPIENT_TOR_SUCCESS",
  DELETE_RESOURCE_RECIPIENT_TOR_FAILURE:
    "DELETE_RESOURCE_RECIPIENT_TOR_FAILURE",
};
