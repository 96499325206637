import { resourceConstants } from "../_constants";

export function singleresource(state = {}, action) {
  switch (action.type) {
    case resourceConstants.GETSINGLERESOURCE_REQUEST:
      return {
        loading: true,
      };
    case resourceConstants.GETSINGLERESOURCE_SUCCESS:
      return {
        items: action.singleresource,
      };
    case resourceConstants.GETSINGLERESOURCE_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
