import { manifestConstants } from "../_constants";

export function crews(state = {}, action) {
  switch (action.type) {
    case manifestConstants.GETUSERS_REQUEST:
      return {
        loading: true,
      };
    case manifestConstants.GETUSERS_SUCCESS:
      return {
        list: action.crews,
      };
    case manifestConstants.GETUSERS_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
