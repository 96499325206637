import React from "react";

import { EditProfile } from "./EditProfile";

import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { dateTimeConstants } from "_constants";
import moment from "moment";
import { preventNegativeValueInDocs, formatDate } from "_helpers/helpers";

const progressStatus = {
  SENT: 1,
  APPROVED: 2,
};

const QualificationsComponent = ({
  profileData,
  dropdownData,
  refetchUser,
  isOpen,
  toggleAccordion,
  changeTab,
  permList,
  authUser,
}) => {

  const ownProfile = profileData?.id == authUser?.id;

  function getProgressStatusText(ifcaStat, ifcaDate) {
    if (!ifcaStat) return "";

    let statusText = "";
    if (ifcaStat === progressStatus.SENT) {
      statusText = "Sent";
    } else if (ifcaStat === progressStatus.APPROVED) {
      statusText = "Approved";
    }
    return `${statusText} on ${formatDate(ifcaDate)}`;
  }

  return (
    <div
      id="qualifications"
      className={`profile-data__drops profile-data_comp ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#qualifications")}>
        <button type="button">
          <h3>Qualifications</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>Title</b>
            </span>
            <span>{profileData.user_type}</span>
            <EditProfile
              label="Title"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes("Edit Title")}
            >
              {(register, errors) => (
                <>
                  <label>Title</label>
                  <select
                    {...register("user_type_id", {
                      required: "Title is required",
                    })}
                    defaultValue={profileData.user_type_id}
                    className={
                      errors.user_type_id && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Title</option>
                    {dropdownData.Roles.map((role, index) => (
                      <option key={index} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>

                  {errors.user_type_id && (
                    <p className="error-message">
                      {errors.user_type_id.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Fire Seasons</b>
            </span>
            <span>{profileData.fire_seasons ?? "-"}</span>
            <EditProfile
              label="Fire Seasons"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors) => (
                <>
                  <label>Fire Seasons</label>
                  <input
                    type="number"
                    {...register("fire_seasons", {
                      min: {
                        value: 0,
                        message: "Fire Seasons must be a valid value",
                      },
                      validate: (value) =>
                        value.toString().length <= 3 ||
                        "Fire Seasons must be at most 3 digits",
                      valueAsNumber: true,
                    })}
                    placeholder="Enter Fire Seasons"
                    defaultValue={profileData.fire_seasons}
                    className={
                      errors.fire_seasons && "profile-form-field-error"
                    }
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={preventNegativeValueInDocs}
                  />
                  {errors.fire_seasons && (
                    <p className="error-message">
                      {errors.fire_seasons.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          {(permList.includes('Manage Expiry/Assigned Dates') ||
            ownProfile) && (
            <li role='listitem'>
              <span>
                <b>RT-130</b>
              </span>
              <span>{formatDate(profileData.rt_130_expiry)}</span>
              <EditProfile
                label='RT-130'
                profileData={profileData}
                refetchUser={refetchUser}
                authUser={authUser}
                hasPermissionRequiredToEditOwn={false}
                hasPermissionToEditOthers={permList.includes(
                  'Manage Expiry/Assigned Dates',
                )}
              >
                {(register, errors, setValue, control) => (
                  <>
                    <label>RT-130</label>
                    <Controller
                      name='rt_130_expiry'
                      control={control}
                      defaultValue={
                        profileData.rt_130_expiry
                          ? moment(profileData.rt_130_expiry).format(
                              dateTimeConstants.DATE_FORMAT,
                            )
                          : null
                      }
                      rules={{
                        required: 'RT-130 is required',
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <DatePicker
                          selected={value ? moment(value) : ''}
                          onChange={(date) =>
                            onChange(
                              date &&
                                !date
                                  .endOf('day')
                                  .isAfter(moment().endOf('day'))
                                ? date.format(dateTimeConstants.DATE_FORMAT)
                                : '',
                            )
                          }
                          customInput={<input inputMode='none' />}
                          maxDate={moment()}
                          showMonthDropdown
                          showYearDropdown
                          placeholderText='Select RT 130 Expiry Date'
                          className={error ? 'profile-form-field-error' : ''}
                          dateFormat={dateTimeConstants.DATE_FORMAT}
                          showPopperArrow={false}
                        />
                      )}
                    />
                    {errors.rt_130_expiry && (
                      <p className='error-message'>
                        {errors.rt_130_expiry.message}
                      </p>
                    )}
                  </>
                )}
              </EditProfile>
            </li>
          )}

          <li role="listitem">
            <span>
              <a
                onClick={() => changeTab("qualifications")}
                className="link-green"
              >
                Qualifications
              </a>
            </span>
            <ul role="list">
              {profileData.qual_key.map((qual, index) => (
                <li role="listitem">{qual}</li>
              ))}
            </ul>
          </li>

          <li class='accordion_sub' role='listitem'>
            <h4>IFCA Contract</h4>
          </li>

          <li role="listitem">
            <span>
              <b>IFCA #</b>
            </span>
            <span>{profileData.ifca_number ?? "-"}</span>
            <EditProfile
              label="IFCA #"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors) => (
                <>
                  <label>IFCA #</label>
                  <input
                    {...register("ifca_number", {
                      maxLength: {
                        value: 20,
                        message:
                          "Length exceeds the maximum limit of 20 characters.",
                      },
                      pattern: {
                        value: /^[A-Za-z0-9]+$/, // Regex for alphabets and numbers
                        message: "Only alphabets and numbers are allowed.",
                      },
                    })}
                    placeholder="Enter IFCA #"
                    defaultValue={profileData.ifca_number}
                    className={errors.ifca_number && "profile-form-field-error"}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={preventNegativeValueInDocs}
                  />
                  {errors.ifca_number && (
                    <p className="error-message">
                      {errors.ifca_number.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>FFT1</b>
            </span>
            <span>
              {profileData.ifca_fft1_status
                ? getProgressStatusText(
                    profileData.ifca_fft1_status,
                    profileData.ifca_fft1_date,
                  )
                : "-"}
            </span>
            <EditProfile
              label="IFCA FFT1"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("ifca_fft1_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.ifca_fft1_status}
                    className={
                      errors.ifca_fft1_status && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="1">Sent</option>
                    <option value="2">Approved</option>
                  </select>
                  {errors.ifca_fft1_status && (
                    <p className="error-message">
                      {errors.ifca_fft1_status.message}
                    </p>
                  )}

                  <label>Date</label>
                  <Controller
                    name="ifca_fft1_date"
                    control={control}
                    defaultValue={
                      profileData.ifca_fft1_date
                        ? moment(profileData.ifca_fft1_date).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    rules={{
                      required: "Date is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.ifca_fft1_date && (
                    <p className="error-message">
                      {errors.ifca_fft1_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>SRB</b>
            </span>
            <span>
              {profileData.ifca_srb_status
                ? getProgressStatusText(
                    profileData.ifca_srb_status,
                    profileData.ifca_srb_date,
                  )
                : "-"}
            </span>
            <EditProfile
              label="SRB"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("ifca_srb_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.ifca_srb_status}
                    className={
                      errors.ifca_srb_status && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="1">Sent</option>
                    <option value="2">Approved</option>
                  </select>
                  {errors.ifca_srb_status && (
                    <p className="error-message">
                      {errors.ifca_srb_status.message}
                    </p>
                  )}

                  <label>Date</label>
                  <Controller
                    name="ifca_srb_date"
                    control={control}
                    defaultValue={
                      profileData.ifca_srb_date
                        ? moment(profileData.ifca_srb_date).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    rules={{
                      required: "Date is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.ifca_srb_date && (
                    <p className="error-message">
                      {errors.ifca_srb_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>LSA</b>
            </span>
            <span>{profileData.lsa ? profileData.lsa : "-"}</span>
            <EditProfile
              label="LSA"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("lsa", {})}
                    defaultValue={profileData.lsa_id}
                    className={errors.lsa && "profile-form-field-error"}
                  >
                    <option value="">Select Status</option>
                    {dropdownData.Lsa.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                  {errors.lsa && (
                    <p className="error-message">{errors.lsa.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li class='accordion_sub' role='listitem'>
            <h4>National Crew Contract</h4>
          </li>

          <li role="listitem">
            <span>
              <b>FFT1</b>
            </span>
            <span>
              {profileData.ncc_fft1_status
                ? getProgressStatusText(
                    profileData.ncc_fft1_status,
                    profileData.ncc_fft1_date,
                  )
                : "-"}
            </span>
            <EditProfile
              label="NCC FFT1"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("ncc_fft1_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.ncc_fft1_status}
                    className={
                      errors.ncc_fft1_status && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="1">Sent</option>
                    <option value="2">Approved</option>
                  </select>
                  {errors.ncc_fft1_status && (
                    <p className="error-message">
                      {errors.ncc_fft1_status.message}
                    </p>
                  )}

                  <label>Date</label>
                  <Controller
                    name="ncc_fft1_date"
                    control={control}
                    defaultValue={
                      profileData.ncc_fft1_date
                        ? moment(profileData.ncc_fft1_date).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    rules={{
                      required: "Date is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.ncc_fft1_date && (
                    <p className="error-message">
                      {errors.ncc_fft1_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>ICT5</b>
            </span>
            <span>
              {profileData.ncc_ict5_status
                ? getProgressStatusText(
                    profileData.ncc_ict5_status,
                    profileData.ncc_ict5_date,
                  )
                : "-"}
            </span>
            <EditProfile
              label="ICT5"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("ncc_ict5_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.ncc_ict5_status}
                    className={
                      errors.ncc_ict5_status && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="1">Sent</option>
                    <option value="2">Approved</option>
                  </select>
                  {errors.ncc_ict5_status && (
                    <p className="error-message">
                      {errors.ncc_ict5_status.message}
                    </p>
                  )}

                  <label>Date</label>
                  <Controller
                    name="ncc_ict5_date"
                    control={control}
                    defaultValue={
                      profileData.ncc_ict5_date
                        ? moment(profileData.ncc_ict5_date).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    rules={{
                      required: "Date is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.ncc_ict5_date && (
                    <p className="error-message">
                      {errors.ncc_ict5_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>CRWB</b>
            </span>
            <span>
              {profileData.ncc_crwb_status
                ? getProgressStatusText(
                    profileData.ncc_crwb_status,
                    profileData.ncc_crwb_date,
                  )
                : "-"}
            </span>
            <EditProfile
              label="CRWB"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("ncc_crwb_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.ncc_crwb_status}
                    className={
                      errors.ncc_crwb_status && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="1">Sent</option>
                    <option value="2">Approved</option>
                  </select>
                  {errors.ncc_crwb_status && (
                    <p className="error-message">
                      {errors.ncc_crwb_status.message}
                    </p>
                  )}

                  <label>Date</label>
                  <Controller
                    name="ncc_crwb_date"
                    control={control}
                    defaultValue={
                      profileData.ncc_crwb_date
                        ? moment(profileData.ncc_crwb_date).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    rules={{
                      required: "Date is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.ncc_crwb_date && (
                    <p className="error-message">
                      {errors.ncc_crwb_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li class='accordion_sub' role='listitem'>
            <h4>VIPR Contract</h4>
          </li>

          <li role="listitem">
            <span>
              <b>FFT1</b>
            </span>
            <span>
              {profileData.vipr_fft1_status
                ? getProgressStatusText(
                    profileData.vipr_fft1_status,
                    profileData.vipr_fft1_date,
                  )
                : "-"}
            </span>
            <EditProfile
              label="VIPR FFT1"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("vipr_fft1_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.vipr_fft1_status}
                    className={
                      errors.vipr_fft1_status && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="1">Sent</option>
                    <option value="2">Approved</option>
                  </select>
                  {errors.vipr_fft1_status && (
                    <p className="error-message">
                      {errors.vipr_fft1_status.message}
                    </p>
                  )}

                  <label>Date</label>
                  <Controller
                    name="vipr_fft1_date"
                    control={control}
                    defaultValue={
                      profileData.vipr_fft1_date
                        ? moment(profileData.vipr_fft1_date).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    rules={{
                      required: "Date is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.vipr_fft1_date && (
                    <p className="error-message">
                      {errors.vipr_fft1_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>ENGB</b>
            </span>
            <span>
              {profileData.vipr_engb_status
                ? getProgressStatusText(
                    profileData.vipr_engb_status,
                    profileData.vipr_engb_date,
                  )
                : "-"}
            </span>
            <EditProfile
              label="ENGB"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("vipr_engb_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.vipr_engb_status}
                    className={
                      errors.vipr_engb_status && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="1">Sent</option>
                    <option value="2">Approved</option>
                  </select>
                  {errors.vipr_engb_status && (
                    <p className="error-message">
                      {errors.vipr_engb_status.message}
                    </p>
                  )}

                  <label>Date</label>
                  <Controller
                    name="vipr_engb_date"
                    control={control}
                    defaultValue={
                      profileData.vipr_engb_date
                        ? moment(profileData.vipr_engb_date).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    rules={{
                      required: "Date is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.vipr_engb_date && (
                    <p className="error-message">
                      {errors.vipr_engb_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

        </ul>
      </div>
    </div>
  );
};

export default QualificationsComponent;
