import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import FormContainer from "Docs/Components/FormContainer";
import TextInput from "Docs/Components/TextInput";
import CustomSignaturePad from "Docs/Components/SignaturePad";
import CustomDatePicker from "Docs/Components/DatePicker";
import { datePickerNullSanitize } from "_helpers/mapEmptyToNull";
import { docsActions } from "_actions";
import SubmitButton from "Docs/Components/SubmitButton";
import Checkbox from "Docs/Components/Checkbox";
import { useSelector } from "react-redux";
import { getCurrentUserInfo } from "_helpers/reduxSelector";
import { withRouter } from "react-router";

// Styles
import "./equipmentResponsibilityForm.css";

const defaultValues = {
  printed_name: "",
  signature: "",
  signed_date: "",
};

export default withRouter(function EquipmentResponsibilityForm({
  isSingleDoc,
  docDetails,
  adminMode,
  goBack,
  location,
}) {
  const dispatch = useDispatch();
  const {
    reset,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
    shouldFocusError: true,
  });

  const [defaultData, setDefaultData] = React.useState({});
  const [docUserInfo, setDocUserInfo] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [disclaimerCheckbox, setDisclaimerCheckbox] = React.useState(false);
  const [readOnly, setReadOnly] = React.useState(true);

  const currentUserInfo = useSelector(getCurrentUserInfo);

  const getDocInfo = () => {
    const docId = docDetails?.id;
    if (docId) {
      setIsLoading(true);
      dispatch(docsActions.getForm(docId))
        .then((res) => {
          if (res.success) {
            let initialData = res?.data?.form;
            let resetData = {
              signature: initialData?.signature,
              signed_date: initialData?.signed_date,
              printed_name: initialData?.printed_name,
            };
            setDefaultData(initialData);
            setDocUserInfo(res?.data?.user);
            setIsLoading(false);
            reset(resetData);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.message ?? "Error on loading data!");
        });
    }
  };

  const checkCurrentUserPermission = () => {
    // Validate is current user doc
    const _urlParams = new URLSearchParams(location?.search);
    let isCurrentUser = null;
    if (_urlParams?.get("hash")) {
      isCurrentUser = _urlParams?.get("hash") === currentUserInfo?.hash;
    } else {
      isCurrentUser = docUserInfo?.hash === currentUserInfo?.hash;
    }
    if (isCurrentUser) {
      setReadOnly(false);
    }
  };

  const handleOnSubmit = async () => {
    setIsLoading(true);
    let values = getValues();
    // Remove Empty values
    let _defaultData = { ...defaultData };
    Object.keys(defaultData).forEach((key) => {
      if (!_defaultData[key]) {
        delete _defaultData[key];
      }
    });

    const payload = {
      doc_type: _defaultData?.docs_type_id,
      form: {
        ..._defaultData, //This is required
        ...values,
      },
      id: docDetails?.id,
      rehire: false,
      single_doc: isSingleDoc,
    };
    dispatch(docsActions.saveDoc(payload))
      .then((res) => {
        if (res?.success) {
          toast.success(
            "Equipment responsibility form submitted successfully!",
          );
          if (goBack) {
            goBack();
          }
        } else {
          throw res;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.message ?? "Submission Failed");
      });
  };

  React.useEffect(() => {
    getDocInfo();
  }, []);

  React.useEffect(() => {
    if (currentUserInfo) {
      checkCurrentUserPermission();
    }
  }, [currentUserInfo, docUserInfo]);

  const isFormAlreadySigned =
    defaultData?.printed_name &&
    defaultData?.signature &&
    defaultData?.signed_date;

  return (
    <FormContainer title="Equipment Responsibility Form" isLoading={isLoading}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        {/* Content */}
        <div>
          <h1 className="equip_resp_h1">Acknowledgment</h1>
          <p>
            I acknowledge that I have received the following equipment for use.
          </p>
          <br />
          <p>
            I understand that it is my complete responsibility to keep equipment
            in a safe place within the work area. I also understand that under
            no circumstances am I to remove the equipment from the work area
            without authorization from the appropriate person.
          </p>
          <br />
          <p>
            I am aware that if the equipment is broken, stolen, or damaged it is
            my responsibility to immediately report to the appropriate
            supervisor. I also understand that I may be charged for any damages
            that occurred while in my possession that are a result of misuse or
            carelessness (This is based on method of damages.)
          </p>
          <br />

          <p>
            I understand that once my employment ends, it is my responsibility
            to return all equipment signed out to me. Failure to do so will
            result in deduction of wages from your final check.
          </p>
          <br />
          <p>
            By signing below, employee acknowledges that he/she has reviewed
            this equipment responsibility form and accepts the conditions set
            forth as related to the equipment described below.
          </p>
        </div>
        <hr />
        <div>
          <TextInput
            label="Responsible Employee"
            value={defaultData?.responsible_employee}
            readOnly
          />
        </div>

        <TextInput
          label="Resource"
          value={defaultData?.resource_name}
          readOnly
        />

        {/* Table */}
        <div>
          <h5 className="equip_resp_label">Equipment Assigned</h5>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Equipment Description/Type</th>
                  <th scope="col">Model #</th>
                  <th scope="col">Serial #</th>
                </tr>
              </thead>
              {defaultData?.equipment_details?.length ? (
                <tbody>
                  {defaultData?.equipment_details?.map((item, index) => (
                    <tr key={index}>
                      <th>{item?.equipment}</th>
                      <th>{item?.equipment_model || "-"}</th>
                      <th>{item?.equipment_serial || "-"}</th>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <th>{defaultData?.equipment || "-"}</th>
                    <th>{defaultData?.equipment_model || "-"}</th>
                    <th>{defaultData?.equipment_serial || "-"}</th>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>

        <div>
          <p>
            The responsible party must complete his/her printed name, signature,
            and date in the spaces provided below:
          </p>
        </div>

        <Controller
          name="printed_name"
          rules={{ required: true }}
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextInput
              label="Name"
              type="text"
              value={value}
              error={errors.printed_name}
              onChange={(event) => onChange(event?.target?.value)}
              disabled={readOnly}
            />
          )}
        />
        <Row>
          <Col md={6} sm={12}>
            <Controller
              rules={{ required: true }}
              name="signature"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomSignaturePad
                  error={errors.signature}
                  value={value}
                  onSave={(signature) => onChange(signature)}
                  disabled={readOnly}
                />
              )}
            />
          </Col>
          <Col md={6} sm={12}>
            <Controller
              rules={{ required: true }}
              name="signed_date"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomDatePicker
                  label="Date"
                  selected={value ? moment(value) : ""}
                  onChange={(newDate) =>
                    onChange(datePickerNullSanitize(newDate))
                  }
                  error={errors.signed_date}
                  required={true}
                  disabled={readOnly}
                  minDate={moment()}
                  maxDate={moment()}
                />
              )}
            />
          </Col>
        </Row>
        {isFormAlreadySigned ? null : (
          <>
            <div className="row form-row equip_resp_mt_5">
              <div className="col">
                <div className="disclaimers">
                  <strong className="diss">Disclaimer: </strong>You must read
                  and carefully understand the documents that you are filling
                  out and signing. These are legally binding documents and must
                  be accurate. By filling these forms out, you are agreeing to
                  our{" "}
                  <a href="https://firestormfire.com/terms-of-use/">
                    Terms of Use, Privacy Policy and Employee Agreements.
                  </a>
                </div>
              </div>
            </div>
            <div className="disclaimer_checkbox">
              <Checkbox
                label="I have confirmed the above details are true and valid."
                value={disclaimerCheckbox}
                checked={disclaimerCheckbox}
                onChange={(event) =>
                  setDisclaimerCheckbox(event?.target?.checked)
                }
                placeholder="- Enter here -"
              />
            </div>
            <div className="submit_button_wrapper">
              <SubmitButton
                disabled={readOnly || !disclaimerCheckbox || isLoading}
                type="submit"
                label="Submit Document"
              />
            </div>
          </>
        )}
      </form>
    </FormContainer>
  );
});
