import React, { Fragment } from "react";
import { config } from "../_helpers";
import { chatActions } from "../_actions";
import { connect } from "react-redux";

class ConversationHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      conversation: {
        marked: false,
      },
    };

    this.setFavroite = this.setFavroite.bind(this);
    this.clearConversation = this.clearConversation.bind(this);
  }

  setFavroite() {
    let { conversation } = this.props.chat;

    if (conversation.id)
      this.props.dispatch(chatActions.setFavorite({ id: conversation.id }));
  }

  clearConversation() {
    this.props.dispatch(chatActions.clearConversation());
  }

  componentDidUpdate(oldProps) {}

  render() {
    let { conversation, admin } = this.props.chat;

    let { profile } = this.props.user;

    return (
      <div className="container-fluid">
        <div className="row msg-messageheader">
          <div className="col-sm-12">
            <h3 className="title">
              {conversation.data.type == "single" ? (
                <Fragment>
                  <div className="msg-user-pic">
                    <img
                      src={
                        config.baseUrl +
                        conversation.recipients[0].profile_photo
                      }
                      onError={(e) => {
                        e.target.setAttribute(
                          "src",
                          config.apiUrl + "/" + config.altImg,
                        );
                      }}
                    />
                  </div>
                  {`${conversation.recipients[0].first_name} ${conversation.recipients[0].last_name}`}
                </Fragment>
              ) : (
                <Fragment>
                  <span>
                    <i className="fas fa-users"></i>
                  </span>
                  {conversation.data.title}
                </Fragment>
              )}
            </h3>
            {admin.active ||
            (profile &&
              conversation.users.some(
                (_user) => _user.hash == profile.data.hash,
              )) ? (
              <ul className="admincontrols nav navbar-nav navbar-right">
                {!admin.active && (
                  <li>
                    <a
                      onClick={this.setFavroite}
                      className={`msg-admincontrol favorite ${conversation.mark == 1 ? "favorited" : ""}`}
                    >
                      <i
                        className={`fa-star ${conversation.mark == 1 ? "fa" : "far"}`}
                      ></i>{" "}
                    </a>
                  </li>
                )}
                {conversation.data.type == "group" && (
                  <li>
                    <a
                      onClick={() => {
                        this.props.toggleInfo();
                      }}
                      className="msg-admincontrol gi-open"
                    >
                      <img src={`${config.assetUrl}icons/info-icon.svg`} />{" "}
                      Group Info
                    </a>
                  </li>
                )}
                {conversation.data.type == "group" && (
                  <li>
                    <a className="msg-admincontrol gi-open user-count">
                      <i className="fa fa-user"></i>{" "}
                      {conversation.users.length}{" "}
                    </a>
                  </li>
                )}
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row msg-mob-messageheader">
          <div className="name-col">
            <a onClick={this.clearConversation} className="mob-backbtn">
              <img src={`${config.assetUrl}icons/back-btn.svg`} alt="" />
            </a>
            <h3 className="title">
              {conversation.data.type == "single" ? (
                <Fragment>
                  <div className="msg-user-pic">
                    <img
                      src={
                        config.baseUrl +
                        conversation.recipients[0].profile_photo
                      }
                      onError={(e) => {
                        e.target.setAttribute(
                          "src",
                          config.apiUrl + "/" + config.altImg,
                        );
                      }}
                    />
                  </div>
                  {`${conversation.recipients[0].first_name} ${conversation.recipients[0].last_name}`}
                </Fragment>
              ) : (
                <Fragment>
                  <span>
                    <i className="fas fa-users"></i>
                  </span>
                  {conversation.data.title}
                </Fragment>
              )}
            </h3>
          </div>

          <div className="control-col">
            {admin.active ||
            (profile &&
              conversation.users.some(
                (_user) => _user.hash == profile.data.hash,
              )) ? (
              <ul className="admincontrols nav navbar-nav navbar-right">
                {!admin.active && (
                  <li>
                    <a
                      onClick={this.setFavroite}
                      className={`msg-admincontrol favorite ${conversation.mark == 1 ? "favorited" : ""}`}
                    >
                      <i
                        className={`fa-star ${conversation.mark == 1 ? "fa" : "far"}`}
                      ></i>{" "}
                    </a>
                  </li>
                )}
                {conversation.data.type == "group" && (
                  <li className="msg-action_toggler">
                    <a
                      onClick={() => {
                        this.props.toggleInfo();
                      }}
                      className="msg-admincontrol"
                    >
                      ...
                    </a>
                  </li>
                )}
              </ul>
            ) : (
              ""
            )}
          </div>
          {/* <div className="msg-action">
                            <button className="btn btn-default btn-delete"><i className="fas fa-users"></i> Group Info</button>
                        </div> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { chat, user } = state;
  return { chat, user };
}

export default connect(mapStateToProps)(ConversationHeader);
