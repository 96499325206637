import React from "react";
import { connect } from "react-redux";
import { assignmentStatsActions } from "../_actions";
import { BarChart } from "react-d3-components";
import moment from "moment";
import "../assets/styles/report.css";

class ReportGraphView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      typeId: this.props.typeId,
      toDate: this.props.toDate,
      fromDate: this.props.fromDate,
      width: document.getElementById("chart").offsetWidth,
      height: 400,
    };
  }

  componentDidMount() {
    this.loadGraph();
  }

  loadGraph = () => {
    let data = new FormData();
    data.append("from", moment(this.state.fromDate).format("YYYY-MM-DD"));
    data.append("to", moment(this.state.toDate).format("YYYY-MM-DD"));
    data.append("assignment_type_id", this.props.typeId);
    this.props.dispatch(
      assignmentStatsActions.getAssignemntStatusGraphView(data),
    );
  };

  componentWillReceiveProps(nextProps) {
    let { assignmentStats, toDate, fromDate, typeId } = nextProps;
    if (
      toDate != this.state.toDate ||
      fromDate != this.state.fromDate ||
      typeId != this.state.typeId
    ) {
      this.setState(
        {
          toDate: toDate,
          fromDate: fromDate,
          typeId: typeId,
        },
        () => {
          this.loadGraph();
        },
      );
    }
    if (assignmentStats.assignmentStatusGraph) {
      if (assignmentStats.assignmentStatusGraph.success) {
        this.setState({
          list: assignmentStats.assignmentStatusGraph.data,
        });
      }
    }
  }

  render() {
    let { list } = this.state;
    let data = [
      {
        values: [],
      },
    ];
    list.forEach(function (element) {
      data[0].values.push({
        x: element.resource_name,
        y: element.no_of_shifts,
      });
    });
    return (
      <div className="chart-report-wrapper" id="chart">
        <div className="y-axis">No of Shifts</div>
        {data[0].values.length > 0 && (
          <BarChart
            data={data}
            width={this.state.width}
            height={this.state.height}
            shapeColor={"red"}
            xAxis={{
              interpolate: "basis-closed",
              tickDirection: "diagonal",
              tickFormat: function (d) {
                return d;
              },
            }}
            margin={{ top: 10, bottom: 100, left: 50, right: 10 }}
          />
        )}
        <div className="x-axis">Crew</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

const assignmentStatus = connect(mapStateToProps)(ReportGraphView);
export { assignmentStatus as ReportGraphView };
