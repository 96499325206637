import React, { useState } from "react";
import styles from "./resourceOrderForm.module.css";
import moment from "moment";
import { connect } from "react-redux";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import CustomDatePicker from "Docs/Components/DatePicker";
import TextInput from "Docs/Components/TextInput";
import { resourceActions } from "_actions";
import { history } from "_helpers";
import { toast } from "react-toastify";
import { resourceService } from "_services";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../assets/styles/docs.scss";

const DATE_FORMAT = "MM/DD/YYYY";

const DATE_TIME_FORMAT = "MM/DD/YYYY HH:mm";

const TIME_FORMAT = "HH:mm";

const IS_A_NEW_PAGE = "/resource-order-form-create";

const PER_DIEM_MIN = 0;

const PER_DIEM_MAX = 75;

const CREW_MEMBERS_APPEND = {
  crew_assigned: "",
  order_date_time: "",
  from: "",
  // qty: "",
  resource_requested: "",
  needed_date_time: "",
  deliver_to: "",
  time: "",
  etd: "",
  eta: "",
  etimated_return_date: "",
  assigned_date: "",
};

const ResourceOrderForm = ({ onSubmit, onCancel, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const isPageMode = props?.location?.pathname === IS_A_NEW_PAGE;
  const [fullResourceList, setFullResourceList] = useState([]);
  const [filteredResourceList, setFilteredResourceList] = useState([]);
  const [displayResource, setDisplayResource] = useState([]);
  const [assignedCrewName, setAssignedCrewName] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const { editMode } = props || false;
  const defaultItems = editMode ? props?.rofData : defaultValues;
  const [inputWidths, setInputWidths] = useState([]);
  const containerRefs = React.useRef([]);

  const datePickerNullSanitize = (date, dateFormat) => {
    const today = moment().startOf("day");
    const selectedDate = moment(date);

    if (selectedDate.isBefore(today)) {
      toast.info("Please note that you have selected a past date.");
    }
    return date ? moment(date).format(dateFormat || DATE_FORMAT) : "";
  };

  defaultItems.date_time = datePickerNullSanitize(moment(), DATE_TIME_FORMAT); //To override the date with current system date.

  let timeOut = null;
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: defaultItems,
    shouldFocusError: true,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "resource_order_form_crews",
  });

  const hideAllLists = () => {
    setDisplayResource((prev) => prev.map(() => false));
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      let clickedOutside = true;

      containerRefs.current.forEach((ref, index) => {
        if (ref && ref.contains(event.target)) {
          clickedOutside = false;
        }
      });

      if (clickedOutside) {
        hideAllLists();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    const fetchResources = async () => {
      let data = new FormData();
      data.append("keyword", ""); // Empty keyword to fetch all resources
      const resp = await props.dispatch(resourceActions.searchResource(data));
      if (resp.data) {
        const items = resp.data;
        let res = [];
        for (const key in items) {
          const arr = items[key];
          if (arr.length) {
            res = res.concat(arr);
          }
        }
        setFullResourceList(res);
      }
    };

    fetchResources();
  }, []);

  React.useEffect(() => {
    const widths = fields.map((_, index) => {
      const input = document.getElementById(`input-${index}`);
      return input ? input.offsetWidth : 0;
    });
    setInputWidths(widths);
  }, [fields]);

  React.useEffect(() => {
    if (editMode) {
      const resources = props?.rofData?.resource_order_form_crews;
      let crewNames = [];
      resources.forEach((resource) => {
        crewNames.push(resource.crew_name);
      });
      setAssignedCrewName(crewNames);
    }
  }, []);

  const handleOnCancel = () => {
    if (props.resourceDetails) {
      onCancel?.(props.resourceDetails);
    } else {
      onCancel?.();
    }
    if (isPageMode) {
      history?.replace("/resource-order-form");
    }
  };
  const handleOnSubmit = (payload) => {
    if (payload.equipment_needs.replace(/<[^>]+>|&nbsp;/g, "").trim() === "") {
      setError("equipment_needs", {
        type: "manual",
        message: "Equipment Needs / Other Information is a required field.",
      });
      toast.error("Please fix all errors before submitting the form.");
      return;
    }
    setIsLoading(true);
    let data = new FormData();
    if (payload?.resource_order_form_crews) {
      payload.resource_order_form_crews = JSON.stringify(
        payload?.resource_order_form_crews,
      );
    }
    for (const [key, value] of Object.entries(payload)) {
      data.append(key, value);
    }
    resourceService
      ?.submitResourceOrderForm(data)
      .then(function (data) {
        if (props.resourceDetails) {
          onSubmit?.(props.resourceDetails);
        } else {
          onSubmit?.({
            rof_type: 1,
            rof_id: data?.data,
            fileName: payload?.project_name,
          });
        }
        if (isPageMode) {
          history?.replace("/resource-order-form");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.message ?? "Something went wrong!");
      });
  };

  const updateFieldChanged = (value, index) => {
    let newArr = [...assignedCrewName];
    newArr[index] = value;
    setAssignedCrewName(newArr);
  };

  const handleInputClick = (index) => {
    let resource_list = fullResourceList.map((item, idx) => (
      <li
        key={idx}
        className={styles?.item}
        onClick={() => {
          updateFieldChanged(item.resource_name, index);
          setValue(
            `resource_order_form_crews[${index}].crew_assigned`,
            item.id,
          );
          updateDisplayResource(false, index);
        }}
      >
        {item.resource_name}
      </li>
    ));
    setFilteredResourceList(resource_list);
    updateDisplayResource(true, index);
  };

  const updateDisplayResource = (value, index) => {
    let newArr = [];
    newArr[index] = value;
    setDisplayResource(newArr);
  };

  const handleSearch = (event, index) => {
    const text = event.target.value.toLowerCase();
    const filteredList = fullResourceList.filter((item) =>
      item.resource_name.toLowerCase().includes(text),
    );

    let resource_list = [];
    if (filteredList.length > 0) {
      resource_list = filteredList.map((item, idx) => (
        <li
          key={idx}
          className={styles?.item}
          onClick={() => {
            updateFieldChanged(item.resource_name, index);
            setValue(
              `resource_order_form_crews[${index}].crew_assigned`,
              item.id,
            );
            updateDisplayResource(false, index);
          }}
        >
          {item.resource_name}
        </li>
      ));
    } else {
      resource_list.push(
        <li key="nodata" className={styles?.item}>
          No results found
        </li>,
      );
    }

    setFilteredResourceList(resource_list);
    updateDisplayResource(true, index);
  };

  const renderPerDiemOptions = () => {
    let options = [];
    options.push(<option value="">Select..</option>);
    for (let i = PER_DIEM_MIN; i <= PER_DIEM_MAX; i++) {
      options.push(<option value={i}>{`$ ${i}`}</option>);
    }
    return options;
  };

  const convertContentToHTML = () => {
    if (!editorState) return "";
    try {
      const rawContent = convertToRaw(editorState.getCurrentContent());
      const currentContentAsHTML = draftToHtml(rawContent);
      return currentContentAsHTML;
    } catch (error) {
      console.error("Error converting content to HTML:", error);
      return "";
    }
  };

  const onSubmitForm = (data) => {
    handleOnSubmit(data);
  };

  const onError = (errors) => {
    if (Object.keys(errors).length > 0) {
      toast.error("Please fix all errors before submitting the form.");
    }
  };

  React.useEffect(() => {
    handleUpdateEditorContent(props?.rofData?.equipment_needs);
  }, [editMode, props?.rofData]);

  const handleUpdateEditorContent = (editorContent) => {
    let data = (editorContent || "").toString().trim();
    //Below code has been added to resolve font sie issue
    const blocksFromHtml = htmlToDraft(data);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
  };
  return (
    <div
      className={`resource-order-form ${styles?.resource_order} ${
        isPageMode ? styles?.margin_top_enabled : ""
      }`}
    >
      <h4>{editMode ? "Edit Resource Order" : "Create Resource Order"}</h4>
      <form
        novalidate
        className={styles?.resource_form}
        onSubmit={handleSubmit(onSubmitForm, onError)}
      >
        <fieldset disabled={isLoading}>
          <div className={`${styles.row} ${styles.form_row}`}>
            <div className={styles?.col}>
              <div className={styles?.inputs_col}>
                <div className={styles?.inputs_head}>
                  Date/Time <span className="required-star">*</span>
                </div>
                <div
                  className={`${styles?.inputs_items} resource_form_datepicker`}
                >
                  <Controller
                    rules={{
                      required: "Date/Time is required.",
                      maxLength: {
                        value: 255,
                        message:
                          "Length exceeds the maximum limit of 255 characters.",
                      },
                    }}
                    name="date_time"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomDatePicker
                        showTimeSelect={true}
                        selected={value ? moment(value) : ""}
                        onChange={(newDate) => {
                          onChange(
                            datePickerNullSanitize(newDate, DATE_TIME_FORMAT),
                          );
                        }}
                        error={errors.date_time}
                        required
                        timeFormat={TIME_FORMAT}
                        dateFormat={DATE_TIME_FORMAT}
                        disabled
                      />
                    )}
                  />
                  {errors.date_time && errors.date_time.message && (
                    <label
                      style={{ marginTop: "-20px" }}
                      htmlFor="date_time"
                      className="error_label"
                    >
                      {errors.project_name.message}
                    </label>
                  )}
                </div>
              </div>
              <div className={styles?.inputs_col}>
                <div className={styles?.inputs_head}>
                  Project Name<span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required: "Project name is required.",
                      maxLength: {
                        value: 255,
                        message:
                          "Length exceeds the maximum limit of 255 characters.",
                      },
                    }}
                    name="project_name"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        error={errors.project_name}
                        placeholder="Enter here"
                      />
                    )}
                  />
                  {errors.project_name && errors.project_name.message && (
                    <label
                      style={{ marginTop: "-20px" }}
                      htmlFor="project_name"
                      className="error_label"
                    >
                      {errors.project_name.message}
                    </label>
                  )}
                </div>
              </div>
              <div className={styles?.inputs_col}>
                <div className={styles?.inputs_head}>
                  Description Location<span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required: "Location is required.",
                      maxLength: {
                        value: 255,
                        message:
                          "Length exceeds the maximum limit of 255 characters.",
                      },
                    }}
                    name="description_location"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        error={errors.description_location}
                        placeholder="Enter here"
                      />
                    )}
                  />
                  {errors.description_location &&
                    errors.description_location.message && (
                      <label
                        style={{ marginTop: "-20px" }}
                        htmlFor="description_location"
                        className="error_label"
                      >
                        {errors.description_location.message}
                      </label>
                    )}
                </div>
              </div>
              <div className={styles?.inputs_col}>
                <div className={styles?.inputs_head}>
                  Contract Number<span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required: "Contract number is required.",
                      maxLength: {
                        value: 255,
                        message:
                          "Length exceeds the maximum limit of 255 characters.",
                      },
                    }}
                    name="contract_number"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        error={errors.contract_number}
                        placeholder="Enter here"
                      />
                    )}
                  />
                  {errors.contract_number && errors.contract_number.message && (
                    <label
                      style={{ marginTop: "-20px" }}
                      htmlFor="contract_number"
                      className="error_label"
                    >
                      {errors.contract_number.message}
                    </label>
                  )}
                </div>
              </div>
              <div className={styles?.inputs_col}>
                <div className={styles?.inputs_head}>
                  Task Order No.<span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required: "Task Order Number is a required field.",
                      maxLength: {
                        value: 255,
                        message:
                          "Length exceeds the maximum limit of 255 characters.",
                      },
                    }}
                    name="task_order_no"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        error={errors.task_order_no}
                        placeholder="Enter here"
                      />
                    )}
                  />
                  {errors.task_order_no && errors.task_order_no.message && (
                    <label
                      style={{ marginTop: "-20px" }}
                      htmlFor="task_order_no"
                      className="error_label"
                    >
                      {errors.task_order_no.message}
                    </label>
                  )}
                </div>
              </div>
              <div className={styles?.inputs_col}>
                <div className={styles?.inputs_head}>
                  Project No.<span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required: "Project Number is a required field.",
                      maxLength: {
                        value: 255,
                        message:
                          "Length exceeds the maximum limit of 255 characters.",
                      },
                    }}
                    name="project_no"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        error={errors.project_no}
                        placeholder="Enter here"
                      />
                    )}
                  />
                  {errors.project_no && errors.project_no.message && (
                    <label
                      style={{ marginTop: "-20px" }}
                      htmlFor="project_no"
                      className="error_label"
                    >
                      {errors.project_no.message}
                    </label>
                  )}
                </div>
              </div>
            </div>

            <div className={styles?.col}>
              <div
                className={`${styles.inputs_col} ${styles.col_lg_9} ${styles.col_md_6}`}
              >
                <div className={styles?.inputs_head}>
                  Reporting Location <span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required: "Reporting Location is a required field.",
                      maxLength: {
                        value: 255,
                        message:
                          "Length exceeds the maximum limit of 255 characters.",
                      },
                    }}
                    name="reporting_location"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        error={errors.reporting_location}
                        placeholder="Enter here"
                      />
                    )}
                  />
                  {errors.reporting_location &&
                    errors.reporting_location.message && (
                      <label
                        style={{ marginTop: "-20px" }}
                        htmlFor="reporting_location"
                        className="error_label"
                      >
                        {errors.reporting_location.message}
                      </label>
                    )}
                </div>
              </div>
              <div className={styles?.inputs_col}>
                <div className={styles?.inputs_head}>
                  Point of Hire <span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required: "Point of Hire is a required field.",
                      maxLength: {
                        value: 255,
                        message:
                          "Length exceeds the maximum limit of 255 characters.",
                      },
                    }}
                    name="point_of_hire"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        error={errors.point_of_hire}
                        placeholder="Enter here"
                      />
                    )}
                  />
                  {errors.point_of_hire && errors.point_of_hire.message && (
                    <label
                      style={{ marginTop: "-20px" }}
                      htmlFor="point_of_hire"
                      className="error_label"
                    >
                      {errors.point_of_hire.message}
                    </label>
                  )}
                </div>
              </div>
            </div>

            <div className={styles?.col}>
              <div className={styles?.inputs_col}>
                <div className={styles?.inputs_head}>
                  Agency / Jurisdiction <span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required: "Agency is a required field.",
                      maxLength: {
                        value: 255,
                        message:
                          "Length exceeds the maximum limit of 255 characters.",
                      },
                    }}
                    name="agency"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        error={errors.agency}
                        placeholder="Enter here"
                      />
                    )}
                  />
                  {errors.agency && errors.agency.message && (
                    <label
                      style={{ marginTop: "-20px" }}
                      htmlFor="agency"
                      className="error_label"
                    >
                      {errors.agency.message}
                    </label>
                  )}
                </div>
              </div>
              <div className={styles?.inputs_col}>
                <div className={styles?.inputs_head}>
                  Ordering Office <span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required: "Ordering Office is a required field.",
                      maxLength: {
                        value: 255,
                        message:
                          "Length exceeds the maximum limit of 255 characters.",
                      },
                    }}
                    name="ordering_office"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        error={errors.ordering_office}
                        placeholder="Enter here"
                      />
                    )}
                  />
                  {errors.ordering_office && errors.ordering_office.message && (
                    <label
                      style={{ marginTop: "-20px" }}
                      htmlFor="ordering_office"
                      className="error_label"
                    >
                      {errors.ordering_office.message}
                    </label>
                  )}
                </div>
              </div>
              <div className={styles?.inputs_col}>
                <div className={styles?.inputs_head}>
                  Firestorm Contact <span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required: "Firestorm Contact is a required field.",
                      maxLength: {
                        value: 255,
                        message:
                          "Length exceeds the maximum limit of 255 characters.",
                      },
                    }}
                    name="fs_contact"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        error={errors.fs_contact}
                        placeholder="Enter here"
                      />
                    )}
                  />
                  {errors.fs_contact && errors.fs_contact.message && (
                    <label
                      style={{ marginTop: "-20px" }}
                      htmlFor="fs_contact"
                      className="error_label"
                    >
                      {errors.fs_contact.message}
                    </label>
                  )}
                </div>
              </div>
              <div className={styles?.inputs_col}>
                <div className={styles?.inputs_head}>
                  Project Type <span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required: "Project Type is a required field.",
                      maxLength: {
                        value: 255,
                        message:
                          "Length exceeds the maximum limit of 255 characters.",
                      },
                    }}
                    name="project_type"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        error={errors.project_type}
                        placeholder="Enter here"
                      />
                    )}
                  />
                  {errors.project_type && errors.project_type.message && (
                    <label
                      style={{ marginTop: "-20px" }}
                      htmlFor="project_type"
                      className="error_label"
                    >
                      {errors.project_type.message}
                    </label>
                  )}
                </div>
              </div>
            </div>

            <div className={styles?.col}>
              <div className={`${styles.inputs_col} ${styles.col_lg_6}`}>
                <div className={styles?.inputs_head}>
                  Project Contact Name / Number{" "}
                  <span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required:
                        "Project Contact Name / Number is a required field.",
                      maxLength: {
                        value: 255,
                        message:
                          "Length exceeds the maximum limit of 255 characters.",
                      },
                    }}
                    name="project_contact_no"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        type="text"
                        value={value}
                        onChange={(event) => onChange(event?.target?.value)}
                        error={errors.project_contact_no}
                        placeholder="Enter here"
                      />
                    )}
                  />
                  {errors.project_contact_no &&
                    errors.project_contact_no.message && (
                      <label
                        style={{ marginTop: "-20px" }}
                        htmlFor="project_contact_no"
                        className="error_label"
                      >
                        {errors.project_contact_no.message}
                      </label>
                    )}
                </div>
              </div>
              <div className={styles?.inputs_col}>
                <div className={styles?.inputs_head}>
                  Travel Pay <span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required: "Travel Pay is a required field.",
                    }}
                    name="travel_pay"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <label class="control-label"></label>
                        <select
                          value={value}
                          onChange={(event) => onChange(event?.target?.value)}
                          error={errors.travel_pay}
                          placeholder="Enter here"
                          className={`form-control ${errors.travel_pay ? "error" : ""}`}
                        >
                          <option value="">Select..</option>
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </>
                    )}
                  />
                  {errors.travel_pay && errors.travel_pay.message && (
                    <label htmlFor="travel_pay" className="error_label">
                      {errors.travel_pay.message}
                    </label>
                  )}
                </div>
              </div>
              <div className={styles?.inputs_col}>
                <div className={styles?.inputs_head}>
                  Per Diem <span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required: "Per Diem is a required field.",
                    }}
                    name="per_diem"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <label class="control-label"></label>
                        <select
                          value={value}
                          onChange={(event) => onChange(event?.target?.value)}
                          error={errors.per_diem}
                          placeholder="Enter here"
                          className={`form-control ${errors.travel_pay ? "error" : ""}`}
                        >
                          {renderPerDiemOptions()}
                        </select>
                      </>
                    )}
                  />
                  {errors.per_diem && errors.per_diem.message && (
                    <label htmlFor="per_diem" className="error_label">
                      {errors.per_diem.message}
                    </label>
                  )}
                </div>
              </div>
            </div>

            <div className={styles?.col}>
              <div
                className={`${styles.inputs_col} ${styles.col_lg_9} ${styles.col_md_6}`}
              >
                <div className={styles?.inputs_head}>
                  Equipment Needs / Other Information
                  <span className="required-star">*</span>
                </div>
                <div className={styles?.inputs_items}>
                  <Controller
                    rules={{
                      required: "Equipment Needs is a required field.",
                    }}
                    name="equipment_needs"
                    error={errors.equipment_needs}
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={(state) => {
                          setEditorState(state);
                          onChange(convertContentToHTML());
                        }}
                      />
                    )}
                  />
                  {errors.equipment_needs && errors.equipment_needs.message && (
                    <label
                      style={{ marginTop: "-20px" }}
                      htmlFor="equipment_needs"
                      className="error_label"
                    >
                      {errors.equipment_needs.message}
                    </label>
                  )}
                </div>
              </div>
              <div className={styles?.inputs_col}>
                <div className={styles?.inputs_col}>
                  <div className={styles?.inputs_head}>
                    Wage Rate <span className="required-star">*</span>
                  </div>
                  <div className={styles?.inputs_items}>
                    <Controller
                      rules={{
                        required: "Wage Rate is a required field.",
                        maxLength: {
                          value: 255,
                          message:
                            "Length exceeds the maximum limit of 255 characters.",
                        },
                      }}
                      name="wage_rate"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextInput
                          disableLabel
                          type="text"
                          value={value}
                          onChange={(event) => onChange(event?.target?.value)}
                          error={errors.wage_rate}
                          placeholder="Enter here"
                        />
                      )}
                    />
                    {errors.wage_rate && errors.wage_rate.message && (
                      <label
                        style={{ marginTop: "-20px" }}
                        htmlFor="wage_rate"
                        className="error_label"
                      >
                        {errors.wage_rate.message}
                      </label>
                    )}
                  </div>
                </div>
                <div className={styles?.inputs_col}>
                  <div className={styles?.inputs_head}>
                    Billing Information / Rates{" "}
                    <span className="required-star">*</span>
                  </div>
                  <div className={styles?.inputs_items}>
                    <Controller
                      rules={{
                        required:
                          "Billing Information / Rates is a required field.",
                        maxLength: {
                          value: 255,
                          message:
                            "Length exceeds the maximum limit of 255 characters.",
                        },
                      }}
                      name="billing_information"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextInput
                          type="text"
                          value={value}
                          onChange={(event) => onChange(event?.target?.value)}
                          error={errors.billing_information}
                          placeholder="Enter here"
                        />
                      )}
                    />
                    {errors.billing_information &&
                      errors.billing_information.message && (
                        <label
                          style={{ marginTop: "-20px" }}
                          htmlFor="billing_information"
                          className="error_label"
                        >
                          {errors.billing_information.message}
                        </label>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles?.add_crews_btn_wrap}>
            <button
              className="button"
              type="button"
              onClick={() => append(CREW_MEMBERS_APPEND)}
            >
              Add Crew
            </button>
          </div>
          <div className={`${styles.row} ${styles.form_row}`}>
            <div className={styles?.col}>
              <div
                className={`${styles.inputs_col} ${styles.inputs_col_table}`}
              >
                <table border="1" className={styles?.inputs_table}>
                  <tbody>
                    <tr>
                      <td width="9%">
                        Crew Assigned{" "}
                        {fields?.length ? (
                          <span className="required-star">*</span>
                        ) : null}
                      </td>
                      <td width="8%">
                        Assigned Date{" "}
                        {fields?.length ? (
                          <span className="required-star">*</span>
                        ) : null}
                      </td>
                      <td width="10%">
                        Order Date / Time{" "}
                        {fields?.length ? (
                          <span className="required-star">*</span>
                        ) : null}
                      </td>
                      <td width="8%">
                        Departure City{" "}
                        {fields?.length ? (
                          <span className="required-star">*</span>
                        ) : null}
                      </td>
                      <td width="10%">
                        Resources / Personnel Requested{" "}
                        {fields?.length ? (
                          <span className="required-star">*</span>
                        ) : null}
                      </td>
                      <td width="8%">
                        Needed Date{" "}
                        {fields?.length ? (
                          <span className="required-star">*</span>
                        ) : null}
                      </td>
                      <td width="8%">
                        Deliver To{" "}
                        {fields?.length ? (
                          <span className="required-star">*</span>
                        ) : null}
                      </td>
                      <td width="9%">
                        Time{" "}
                        {fields?.length ? (
                          <span className="required-star">*</span>
                        ) : null}
                      </td>
                      <td width="8%">
                        ETD{" "}
                        {fields?.length ? (
                          <span className="required-star">*</span>
                        ) : null}
                      </td>
                      <td width="8%">
                        ETA{" "}
                        {fields?.length ? (
                          <span className="required-star">*</span>
                        ) : null}
                      </td>
                      <td width="8%">
                        Estimated Return Date{" "}
                        {fields?.length ? (
                          <span className="required-star">*</span>
                        ) : null}
                      </td>
                      <td width="4%"></td>
                    </tr>
                    {fields?.length ? (
                      fields?.map((field, index) => (
                        <tr key={field.id}>
                          <td>
                            <Controller
                              rules={{
                                required: "Crew Assigned is a required field.",
                              }}
                              name={`resource_order_form_crews[${index}].crew_assigned`}
                              defaultValue={field.crew_assigned}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <div
                                  ref={(el) =>
                                    (containerRefs.current[index] = el)
                                  }
                                >
                                  <TextInput
                                    id={`input-${index}`}
                                    type="text"
                                    value={assignedCrewName[index]}
                                    onClick={() => {
                                      handleInputClick(index);
                                    }}
                                    onChange={(e) => {
                                      updateFieldChanged(e.target.value, index);
                                      handleSearch(e, index);
                                    }}
                                    error={
                                      errors?.resource_order_form_crews?.[index]
                                        ?.crew_assigned
                                    }
                                    placeholder="Enter here"
                                  />
                                  {displayResource[index] && (
                                    <ul
                                      style={{ width: inputWidths[index] }}
                                      className={styles?.resource_order_rs_list}
                                    >
                                      {filteredResourceList}
                                    </ul>
                                  )}
                                </div>
                              )}
                            />
                            {errors?.resource_order_form_crews?.[index]
                              ?.crew_assigned && (
                              <label
                                htmlFor="crew_assigned"
                                className="error_label"
                              >
                                {
                                  errors?.resource_order_form_crews?.[index]
                                    ?.crew_assigned.message
                                }
                              </label>
                            )}
                          </td>
                          <td className="resource_form_datepicker">
                            <Controller
                              rules={{
                                required: "Assigned date is a required field.",
                              }}
                              name={`resource_order_form_crews[${index}].assigned_date`}
                              defaultValue={field.assigned_date}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <CustomDatePicker
                                  selected={value ? moment(value) : ""}
                                  onChange={(newDate) => {
                                    onChange(
                                      datePickerNullSanitize(
                                        newDate,
                                        DATE_FORMAT,
                                      ),
                                    );
                                  }}
                                  error={
                                    errors?.resource_order_form_crews?.[index]
                                      ?.assigned_date
                                  }
                                  dateFormat={DATE_FORMAT}
                                />
                              )}
                            />
                            {errors?.resource_order_form_crews?.[index]
                              ?.assigned_date && (
                              <label
                                htmlFor="assigned_date"
                                className="error_label"
                              >
                                {
                                  errors?.resource_order_form_crews?.[index]
                                    ?.assigned_date.message
                                }
                              </label>
                            )}
                          </td>
                          <td className="resource_form_datepicker">
                            <Controller
                              rules={{
                                required:
                                  "Order Date / Time is a required field.",
                              }}
                              name={`resource_order_form_crews[${index}].order_date_time`}
                              defaultValue={field.order_date_time}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <CustomDatePicker
                                  showTimeSelect={true}
                                  selected={value ? moment(value) : ""}
                                  onChange={(newDate) => {
                                    onChange(
                                      datePickerNullSanitize(
                                        newDate,
                                        DATE_TIME_FORMAT,
                                      ),
                                    );
                                  }}
                                  timeFormat={TIME_FORMAT}
                                  dateFormat={DATE_TIME_FORMAT}
                                  placeholderText="mm/dd/yyyy hh:mm"
                                  error={
                                    errors?.resource_order_form_crews?.[index]
                                      ?.order_date_time
                                  }
                                />
                              )}
                            />
                            {errors?.resource_order_form_crews?.[index]
                              ?.order_date_time && (
                              <label
                                htmlFor="order_date_time"
                                className="error_label"
                              >
                                {
                                  errors?.resource_order_form_crews?.[index]
                                    ?.order_date_time.message
                                }
                              </label>
                            )}
                          </td>
                          <td>
                            <Controller
                              rules={{
                                required: "Departure City is a required field.",
                                maxLength: {
                                  value: 255,
                                  message:
                                    "Length exceeds the maximum limit of 255 characters.",
                                },
                              }}
                              name={`resource_order_form_crews[${index}].from`}
                              defaultValue={field.from}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <TextInput
                                  type="text"
                                  value={value}
                                  onChange={(event) =>
                                    onChange(event?.target?.value)
                                  }
                                  error={
                                    errors?.resource_order_form_crews?.[index]
                                      ?.from
                                  }
                                  placeholder="Enter here"
                                />
                              )}
                            />
                            {errors?.resource_order_form_crews?.[index]
                              ?.from && (
                              <label htmlFor="from" className="error_label">
                                {
                                  errors?.resource_order_form_crews?.[index]
                                    ?.from.message
                                }
                              </label>
                            )}
                          </td>
                          {/* <td>
                          <Controller
                            name={`resource_order_form_crews[${index}].qty`}
                            defaultValue={field.qty}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextInput
                                type="number"
                                value={value}
                                onChange={(event) =>
                                  onChange(event?.target?.value)
                                }
                                error={errors.qty}
                                placeholder="Enter here"
                              />
                            )}
                          />
                        </td> */}
                          <td>
                            <Controller
                              rules={{
                                required:
                                  "Resources / Personnel Requested is a required field.",
                                maxLength: {
                                  value: 255,
                                  message:
                                    "Length exceeds the maximum limit of 255 characters.",
                                },
                              }}
                              name={`resource_order_form_crews[${index}].resource_requested`}
                              defaultValue={field.resource_requested}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <TextInput
                                  type="text"
                                  value={value}
                                  onChange={(event) =>
                                    onChange(event?.target?.value)
                                  }
                                  error={
                                    errors?.resource_order_form_crews?.[index]
                                      ?.resource_requested
                                  }
                                  placeholder="Enter here"
                                />
                              )}
                            />
                            {errors?.resource_order_form_crews?.[index]
                              ?.resource_requested && (
                              <label
                                htmlFor="resource_requested"
                                className="error_label"
                              >
                                {
                                  errors?.resource_order_form_crews?.[index]
                                    ?.resource_requested.message
                                }
                              </label>
                            )}
                          </td>
                          <td className="resource_form_datepicker">
                            <Controller
                              rules={{
                                required: "Needed Date is a required field.",
                              }}
                              name={`resource_order_form_crews[${index}].needed_date_time`}
                              defaultValue={field.needed_date_time}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <CustomDatePicker
                                  selected={value ? moment(value) : ""}
                                  onChange={(newDate) => {
                                    onChange(
                                      datePickerNullSanitize(
                                        newDate,
                                        DATE_FORMAT,
                                      ),
                                    );
                                  }}
                                  error={
                                    errors?.resource_order_form_crews?.[index]
                                      ?.needed_date_time
                                  }
                                  dateFormat={DATE_FORMAT}
                                />
                              )}
                            />
                            {errors?.resource_order_form_crews?.[index]
                              ?.needed_date_time && (
                              <label
                                htmlFor="needed_date_time"
                                className="error_label"
                              >
                                {
                                  errors?.resource_order_form_crews?.[index]
                                    ?.needed_date_time.message
                                }
                              </label>
                            )}
                          </td>
                          <td>
                            <Controller
                              rules={{
                                required: "Deliver To is a required field.",
                                maxLength: {
                                  value: 255,
                                  message:
                                    "Length exceeds the maximum limit of 255 characters.",
                                },
                              }}
                              name={`resource_order_form_crews[${index}].deliver_to`}
                              defaultValue={field.deliver_to}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <TextInput
                                  type="text"
                                  value={value}
                                  onChange={(event) =>
                                    onChange(event?.target?.value)
                                  }
                                  error={
                                    errors?.resource_order_form_crews?.[index]
                                      ?.deliver_to
                                  }
                                  placeholder="Enter here"
                                />
                              )}
                            />
                            {errors?.resource_order_form_crews?.[index]
                              ?.deliver_to && (
                              <label
                                htmlFor="deliver_to"
                                className="error_label"
                              >
                                {
                                  errors?.resource_order_form_crews?.[index]
                                    ?.deliver_to.message
                                }
                              </label>
                            )}
                          </td>
                          <td>
                            <Controller
                              rules={{
                                required: "Time is a required field.",
                                maxLength: {
                                  value: 255,
                                  message:
                                    "Length exceeds the maximum limit of 255 characters.",
                                },
                              }}
                              name={`resource_order_form_crews[${index}].time`}
                              defaultValue={field.time}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <TextInput
                                  type="text"
                                  value={value}
                                  onChange={(event) =>
                                    onChange(event?.target?.value)
                                  }
                                  error={
                                    errors?.resource_order_form_crews?.[index]
                                      ?.time
                                  }
                                  placeholder="Enter here"
                                />
                              )}
                            />
                            {errors?.resource_order_form_crews?.[index]
                              ?.time && (
                              <label htmlFor="time" className="error_label">
                                {
                                  errors?.resource_order_form_crews?.[index]
                                    ?.time.message
                                }
                              </label>
                            )}
                          </td>
                          <td>
                            <Controller
                              rules={{
                                required: "ETD is a required field.",
                                maxLength: {
                                  value: 255,
                                  message:
                                    "Length exceeds the maximum limit of 255 characters.",
                                },
                              }}
                              name={`resource_order_form_crews[${index}].etd`}
                              defaultValue={field.etd}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <TextInput
                                  type="text"
                                  value={value}
                                  onChange={(event) =>
                                    onChange(event?.target?.value)
                                  }
                                  error={
                                    errors?.resource_order_form_crews?.[index]
                                      ?.etd
                                  }
                                  placeholder="Enter here"
                                />
                              )}
                            />
                            {errors?.resource_order_form_crews?.[index]
                              ?.etd && (
                              <label htmlFor="etd" className="error_label">
                                {
                                  errors?.resource_order_form_crews?.[index]
                                    ?.etd.message
                                }
                              </label>
                            )}
                          </td>
                          <td>
                            <Controller
                              rules={{
                                required: "ETA is a required field.",
                                maxLength: {
                                  value: 255,
                                  message:
                                    "Length exceeds the maximum limit of 255 characters.",
                                },
                              }}
                              name={`resource_order_form_crews[${index}].eta`}
                              defaultValue={field.eta}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <TextInput
                                  type="text"
                                  value={value}
                                  onChange={(event) =>
                                    onChange(event?.target?.value)
                                  }
                                  error={
                                    errors?.resource_order_form_crews?.[index]
                                      ?.eta
                                  }
                                  placeholder="Enter here"
                                />
                              )}
                            />
                            {errors?.resource_order_form_crews?.[index]
                              ?.eta && (
                              <label htmlFor="eta" className="error_label">
                                {
                                  errors?.resource_order_form_crews?.[index]
                                    ?.eta.message
                                }
                              </label>
                            )}
                          </td>
                          <td className="resource_form_datepicker overflow-right">
                            <Controller
                              rules={{
                                required:
                                  "Estimated Return Date is a required field.",
                              }}
                              name={`resource_order_form_crews[${index}].etimated_return_date`}
                              defaultValue={field.etimated_return_date}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <CustomDatePicker
                                  selected={value ? moment(value) : ""}
                                  onChange={(newDate) => {
                                    onChange(datePickerNullSanitize(newDate));
                                  }}
                                  error={
                                    errors?.resource_order_form_crews?.[index]
                                      ?.etimated_return_date
                                  }
                                  popperPlacement="right-end"
                                />
                              )}
                            />
                            {errors?.resource_order_form_crews?.[index]
                              ?.etimated_return_date && (
                              <label
                                htmlFor="etimated_return_date"
                                className="error_label"
                              >
                                {
                                  errors?.resource_order_form_crews?.[index]
                                    ?.etimated_return_date.message
                                }
                              </label>
                            )}
                          </td>
                          <td className="resource_form_datepicker overflow-right">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => remove(index)}
                            >
                              <i className="fas fa-trash-alt" />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <div className={styles?.empty_placeholder}>
                            <p>Add Crew to display</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {defaultItems?.user?.full_name ? (
              <div className={styles?.created_by}>
                <p>{`Created By: ${defaultItems?.user?.full_name}`}</p>
              </div>
            ) : null}
          </div>
          <div style={{ marginBottom: "30px" }} className={styles?.action_bar}>
            <button
              disabled={isLoading}
              type="button"
              className={`button grey ${styles?.cancel_btn_margin}`}
              onClick={() => handleOnCancel()}
            >
              Cancel
            </button>
            <button disabled={isLoading} className="button" type="submit">
              Submit
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { resource } = state;
  return {
    resource,
  };
};

export default connect(mapStateToProps)(ResourceOrderForm);

const defaultValues = {
  date_time: moment(),
  project_name: "",
  description_location: "",
  contract_number: "",
  task_order_no: "",
  project_no: "",
  reporting_location: "",
  point_of_hire: "",
  agency: "",
  ordering_office: "",
  fs_contact: "",
  project_type: "",
  project_contact_no: "",
  travel_pay: "",
  per_diem: "",
  equipment_needs: "",
  wage_rate: "",
  billing_information: "",
};
