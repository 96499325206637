import styles from "./interview.module.scss";

export const Loader = () => {
  return (
    <div className={styles.loader_container}>
      <div className={styles.overlay_loader}>
        <i class="fa fa-spinner fa-spin spin-big"></i>
      </div>
    </div>
  );
};
