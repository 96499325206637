import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import ExpirationDateFilters from "ExpirationDateStatus/ExpirationDateFilters";
import { dashboardActions, resourceActions } from "_actions";
import { dashboardService } from "../_services/dashboard.service";
import {
  expirationDateFilterOptions,
  selectAllOption,
} from "ExpirationDateStatus/ExpirationDateFilters";
import "../assets/styles/report.css";
import ExpirationDateReportList from "./ExpirationDateReportList";
import { getUsersExpirationDates } from "_helpers/reduxSelector";

const MENU_WIDTH = 300;

const selectStyles = {
  control: (css) => ({
    ...css,
    width: MENU_WIDTH || "auto",
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 7,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 9999,
  }),
  option: (css) => ({ ...css, paddingRight: 36 + 8, zIndex: 9999 }),
};

const ExpirationDateReport = (props) => {
  const dispatch = useDispatch();
  const [currentFilters, setCurrentFilters] = React.useState([]);
  const [currentFiltersRaw, setCurrentFiltersRaw] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [selectedResource, setSelectedResource] = React.useState();
  const [sortAndOrder, setSortAndOrder] = React.useState({
    order: "asc",
    sortBy: "first_name",
  });

  const usersExpirationDates = useSelector((state) =>
    getUsersExpirationDates(state),
  );

  React.useEffect(() => {
    let _currentFilters = props?.location?.state?.currentFilters?.length
      ? props?.location?.state?.currentFilters
      : ["all"];
    _currentFilters =
      _currentFilters?.length === expirationDateFilterOptions?.length
        ? ["all"]
        : _currentFilters;

    const _currentFiltersRaw = _currentFilters?.includes("all")
      ? selectAllOption
      : expirationDateFilterOptions.filter((item) =>
          _currentFilters?.includes(item?.value),
        );

    setCurrentFiltersRaw(_currentFiltersRaw);
    setCurrentFilters(_currentFilters);
  }, []);

  // React.useEffect(() => {
  //   if (currentFilters?.length) {
  //     getExpirationDatesByFilters();
  //   }
  // }, [currentFilters]);

  React.useEffect(() => {
    if (currentFilters?.length) {
      getExpirationDatesByFilters();
    }
  }, [selectedResource, currentFilters]);

  const getExpirationDatesByFilters = (newPage, sort, order) => {
    handleSortAndOrderForPrint(sort, order);
    let data = new FormData();
    data.append("filter", JSON.stringify(currentFilters));
    data.append("page", newPage || 0);
    data.append("resource_id", selectedResource?.value || 0);
    data.append("sort", sort || "first_name");
    data.append("order", order || "asc");

    dispatch(dashboardActions.getExpirationDates(data));
    if (newPage) {
      setCurrentPage(newPage);
    }
  };

  const handleOnChange = (newFilters, event) => {
    const hasSelectedAllOptions =
      (event?.action === "select-option" && event?.option?.value === "all") ||
      newFilters?.length === expirationDateFilterOptions?.length;
    if (hasSelectedAllOptions) {
      setCurrentFiltersRaw(selectAllOption);
      setCurrentFilters(["all"]);
    } else {
      setCurrentFiltersRaw(newFilters);
      setCurrentFilters(newFilters.map((item) => item?.value));
    }
  };

  const onPrintClick = () => {
    let data = new FormData();
    data.append("filter", JSON.stringify(currentFilters));
    data.append("resource_id", selectedResource?.value || 0);
    data.append("sort", sortAndOrder?.sortBy);
    data.append("order", sortAndOrder?.order);
    data.append("report_type", "pdf");
    dashboardService
      .generateEmployeeExpirationDateReport(data)
      .then((response) => redirectToPdf(response));
  };

  const onExportClick = () => {
    let data = new FormData();
    data.append("filter", JSON.stringify(currentFilters));
    data.append("resource_id", selectedResource?.value || 0);
    data.append("sort", sortAndOrder?.sortBy);
    data.append("order", sortAndOrder?.order);
    data.append("report_type", "csv");
    dashboardService.generateEmployeeExpirationDateReportCsv(data);
  };

  const redirectToPdf = (data) => {
    if (data?.success) {
      const pdfLink = data.data;
      var link = document.createElement("a");
      if (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i)
      ) {
        link.href = pdfLink;
      } else {
        link.href = pdfLink;
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfLink);
      }, 100);
    }
  };

  const handleSearch = async (text) => {
    let data = new FormData();
    data.append("keyword", text);
    const resources = dispatch(resourceActions.searchResource(data));
    let resourceList = [];

    const response = await resources;
    const items = response.data;
    var res = [];
    for (var key in items) {
      var arr = items[key];
      if (arr.length) {
        res = res.concat(arr);
      }
    }
    if (res.length > 0) {
      res.forEach((item) => {
        let resource = {
          label: item.resource_name,
          value: item.id,
        };
        resourceList.push(resource);
      });
    }
    return resourceList;
  };

  const loadResources = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(handleSearch(inputValue));
      }, 1000);
    });

  const handleSortAndOrderForPrint = (sortBy, order) => {
    let _sortAndOrder = { ...sortAndOrder };
    _sortAndOrder.sortBy = sortBy || _sortAndOrder.sortBy;
    _sortAndOrder.order = order || _sortAndOrder.order;
    setSortAndOrder(_sortAndOrder);
  };

  return (
    <div>
      <div className={props.isReportMenu ? "" : "dashboard_container"}>
        <div className="white">
          {!props.isReportMenu && (
            <div className="back_to_board dashboard_expiration_date_nav">
              <div className="back_button">
                <img src={require("../assets/images/back.svg").default} />{" "}
                <span>
                  <Link to="/">Back to dashboard</Link>
                </span>
              </div>
              {currentFilters?.length ? (
                <button
                  href=""
                  className="print_button"
                  onClick={(event) => {
                    onPrintClick();
                  }}
                >
                  <img
                    alt=""
                    src={require("../assets/images/print.svg").default}
                  />{" "}
                  Print
                </button>
              ) : (
                <div className="dashboard-expiration-date-print-btn-mock" />
              )}
            </div>
          )}
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head sold_rpt_mbl_flx">
                <h5 className="graph_header">Expiration Dates Report</h5>
                <div
                  style={{ gap: `${props.isReportMenu ? "10px" : "0px"}` }}
                  className="report_head_btn_grp exp_multi_head_flx"
                >
                  <div className="report_multi_btn_grp exp_multi_btn_flx">
                    <div
                      style={{ minWidth: 300 }}
                      className="inp_sub_container"
                    >
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadResources}
                        onChange={(selection) => setSelectedResource(selection)}
                        // isMulti
                        defaultOptions
                        isClearable
                        styles={selectStyles}
                        placeholder={"Choose Resource Filter"}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                    <div
                      style={{ minWidth: 300 }}
                      className="inp_sub_container"
                    >
                      <ExpirationDateFilters
                        value={currentFiltersRaw}
                        options={
                          currentFilters?.includes("all")
                            ? selectAllOption
                            : selectAllOption.concat(
                                expirationDateFilterOptions,
                              )
                        }
                        onChange={(newFilters, event) =>
                          handleOnChange(newFilters, event)
                        }
                      />
                    </div>
                    {usersExpirationDates?.data?.length &&
                    props.isReportMenu &&
                    currentFilters?.length ? (
                      <div className="button__div-flex">
                        <button
                          href=""
                          style={{ marginRight: "10px" }}
                          className="print_button"
                          onClick={(event) => {
                            onExportClick();
                          }}
                        >
                          Export
                        </button>
                        <button
                          href=""
                          className="print_button"
                          onClick={(event) => {
                            onPrintClick();
                          }}
                        >
                          Print
                        </button>
                      </div>
                    ) : (
                      <div className="dashboard-expiration-date-print-btn-mock" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ExpirationDateReportList
                usersExpirationDates={usersExpirationDates}
                getExpirationDatesByFilters={getExpirationDatesByFilters}
                currentFilters={currentFilters}
                resourceFilter={selectedResource}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpirationDateReport;
