// import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "_actions";
// import DatePicker from "react-datepicker";
import ApplicantsReport from "./ApplicantsReport";
import { applicantsReport } from "_helpers/reduxSelector";
import { dashboardService } from "../_services/dashboard.service";
import { redirectToPdf } from "_helpers/redirectToPdf";
import DropdownBtn from "_components/utils/DropdownBtn";
import ReactModal from "react-modal";
import { toast } from "react-toastify";

// const DATE_FORMAT = "YYYY-MM-DD";

const Sort = {
  Asc: "asc",
  Dsc: "desc",
};

const ReportType = {
  Csv: "csv",
  Pdf: "pdf",
};

const customStyles = {
  content: {
    width: "460px",
    height: "353px",
  },
};

function ApplicantsReportContainer(props) {
  const dispatch = useDispatch();

  const reportData = useSelector((state) => applicantsReport(state));
  const [globalSort, setGlobalSort] = useState("last_name");
  const [globalOrder, setGlobalOrder] = useState("asc");
  const [keySearch, setKeySearch] = useState("");
  const [selectOption, setSelectedOption] = useState("");
  const [printOption, setPrintOption] = useState("");
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const fetchApplicantsReport = (newPage, sort, order) => {
    const data = new FormData();
    data.append("page", newPage || 0);
    data.append("sort", sort || "last_name");
    data.append("keyword", keySearch || "");
    data.append("order", order || "asc");
    data.append("type", selectOption);
    dispatch(dashboardActions.fetchApplicantsReport(data));
  };

  const setSortParams = (sort, order) => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    setGlobalSort(sort);
    setGlobalOrder(sortOrder);
  };

  const onExport = () => {
    const data = new FormData();
    data.append("sort", globalSort || "last_name");
    data.append("keyword", keySearch || "");
    data.append("order", globalOrder || "asc");
    data.append("report_type", ReportType.Csv);
    data.append("type", selectOption);
    dashboardService.fetchApplicantsReportCsv(data);
  };

  const onPrint = () => {
    const data = new FormData();
    data.append("sort", globalSort || "last_name");
    data.append("keyword", keySearch || "");
    data.append("order", globalOrder || "asc");
    data.append("report_type", ReportType.Pdf);
    data.append("date_filter", printOption);
    data.append("type", selectOption);
    dashboardService.fetchApplicantsReportPdf(data).then((res) => {
      if (printOption === "all") return toast.info(res.message);
      redirectToPdf(res, 'applicant_report_' + Date.now());
    });
  };

  const onSelectOption = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleConfirmSendEmail = () => {
    onPrint();
    setIsModalOpen(false);
    setTimeout(() => {
      setPrintOption("");
    }, 1000);
  };

  const handleCancelSendEmail = () => {
    setIsModalOpen(false);
    setPrintOption("");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchApplicantsReport(0, "last_name", "asc", selectOption);
    }, 1000);
    return () => clearTimeout(timer);
  }, [keySearch]);

  useEffect(() => {
    fetchApplicantsReport(0, globalSort, globalOrder, selectOption);
  }, [selectOption]);

  useEffect(() => {
    if (printOption === "all") {
      setIsModalOpen(true);
      return;
    }

    if (printOption) onPrint();
    setTimeout(() => {
      setPrintOption("");
    }, 1000);
  }, [printOption]);

  return (
    <div>
      <ReactModal
        isOpen={isModalOpen}
        contentLabel="Unloack Employees"
        style={customStyles}
        overlayClassName="RemoveApplntPopup emp_status_modal"
      >
        <div className="modal-head">
          <h4>CONFIRMATION</h4>
        </div>

        <div className="modal-body applicants-report-modal-btn-group">
          <p>
            This will send the PDF report to your email address, are you sure
            you want to continue?
          </p>
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <button
              className="print_button button btn_close"
              onClick={handleCancelSendEmail}
            >
              Cancel
            </button>
            {
              <button
                className="print_button button"
                onClick={handleConfirmSendEmail}
              >
                Yes
              </button>
            }
          </div>
        </div>
      </ReactModal>

      <div>
        <div className="white">
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head sold_rpt_mbl_flx">
                <h5 className="graph_header">Applicant's Report</h5>
                <div
                  style={{ gap: `${props.isReportMenu ? "10px" : "0px"}` }}
                  className="report_head_btn_grp"
                >
                  <div className="report_multi_btn_grp milage__head-wrap">
                    <div className="select-applicants-report">
                      <select
                        name="filter"
                        className="applicant_filter"
                        value={selectOption}
                        onChange={onSelectOption}
                      >
                        <option value="">All</option>
                        <option value="received">Received</option>
                        <option value="inreview">In Review</option>
                        <option value="offered">Offered Position</option>
                        <option value="hire">Was Hired</option>
                        <option value="remove">Not Selected</option>
                        <option value="donthire">Do Not Hire</option>
                      </select>
                    </div>

                    <div
                      className="inp_sub_container"
                      style={{ minWidth: 300 }}
                    >
                      <input
                        type="text"
                        className="employee_search"
                        value={keySearch}
                        name="keyword"
                        placeholder="Search Applicant..."
                        onChange={(e) => setKeySearch(e.target.value)}
                        autoComplete="off"
                      />
                    </div>

                    <div className="milage__btn-wraper">
                      {!!reportData?.data?.length && (
                        <>
                          <button
                            className="print_button"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              onExport();
                            }}
                          >
                            Export
                          </button>
                          {/* <button
                            className="print_button"
                            onClick={() => {
                              onPrint();
                            }}
                          >
                            Print
                          </button> */}
                          <DropdownBtn
                            setPrintOption={setPrintOption} // handlePrintOption is your function to handle the selected option
                            label={"Print"}
                            options={[
                              { label: "This Month", value: "this_month" },
                              {
                                label: "Last Six Months",
                                value: "last_6_months",
                              },
                              { label: "Last Year", value: "last_year" },
                              { label: "All", value: "all" },
                            ]}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ApplicantsReport
                data={reportData?.data || []}
                lastPage={reportData?.last_page}
                fetchApplicantsReport={fetchApplicantsReport}
                setSortParams={setSortParams}
                keySearch={keySearch}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicantsReportContainer;
