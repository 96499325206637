import React from "react";
import PropTypes from "prop-types";
import {
  FormGroup,
  ControlLabel,
  HelpBlock,
  FormControl,
} from "react-bootstrap";

const TextInput = ({
  id,
  label,
  help,
  disableLabel,
  error,
  additionalClasses,
  ...props
}) => {
  return (
    <FormGroup controlId={id}>
      {!disableLabel && (
        <ControlLabel>
          {label}
          {label && props?.required && <span class="required-star">*</span>}
        </ControlLabel>
      )}
      <FormControl
        {...props}
        className={`${error ? "error" : ""} ${additionalClasses || ""}`}
      />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
};

export default TextInput;

TextInput.propTypes = {
  error: PropTypes.bool,
};
