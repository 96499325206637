import React from "react";
import { config } from "../_helpers";
import { connect } from "react-redux";
import TextMessage from "./TextMessage";
import FileMessage from "./FileMessage";
import { chatActions } from "../_actions";
import moment from "moment";
import { dateTimeConstants } from "_constants";

function LineNotification(props) {
  let { message, conversation, user } = props;

  let { data } = conversation;
  let notification = "";

  try {
    notification = JSON.parse(message.body);
  } catch (e) {}

  let body = notification.message ? notification.message : "";

  if (
    user.profile &&
    user.profile.data &&
    user.profile.data.hash == notification.by_user.hash
  ) {
    body = body.replace("#by_user", `You`);
  } else {
    body = body.replace(
      "#by_user",
      `${notification.by_user.first_name} ${notification.by_user.last_name}`,
    );
  }

  if (
    user.profile &&
    user.profile.data &&
    user.profile.data.hash == notification.user.hash
  ) {
    body = body.replace("#user", `You`);
  } else {
    body = body.replace(
      "#user",
      `${notification.user.first_name} ${notification.user.last_name}`,
    );
  }

  return (
    <div className="msg-users">
      <div className="user_added">
        <span>{data.title}</span> - {body}
      </div>
    </div>
  );
}

function Spinner() {
  return (
    <React.Fragment>
      <span className="scroll-spinner">
        <img
          src={`${config.assetUrl}icons/circle.svg`}
          className="spinner fa-spin"
        />
      </span>
    </React.Fragment>
  );
}

class Conversation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messageList: [],
      scrollLoading: false,
      isEditing: false,
    };

    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.editMessage = this.editMessage.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);
    this.messageListScroll = this.messageListScroll.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
  }

  refreshTimer = null;

  scrollToBottom(force) {
    const scrollHeight = this.MessageWrapper.scrollHeight;
    const height = this.MessageWrapper.clientHeight;
    let maxScrollTop = scrollHeight - height;

    if (this.MessageWrapper.scrollTop > height / 2) {
      this.MessageWrapper.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    } else if (force) {
      // called from parent component
      this.MessageWrapper.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }

  // load messages after it reaches top
  messageListScroll(e) {
    let { conversation } = this.props.chat;
    if (
      !this.props.chat.loading &&
      conversation.id &&
      e.target.scrollTop == 0 &&
      conversation.messages.length < conversation.totalPages
    ) {
      conversation.currentPage = conversation.currentPage + 1;
      this.setState({ scrollLoading: true }, () => {
        this.props.dispatch(chatActions.loadMessages(conversation));
      });
    }
  }

  editMessage(message) {
    this.props.dispatch(chatActions.updateMessage(message));
  }

  toggleEditing() {
    this.setState({
      isEditing: !this.state.isEditing,
    });
  }

  deleteMessage(message) {
    this.props.dispatch(chatActions.deleteMessage(message));
  }

  getDateLabel(date) {
    const format = "MM/DD/YYYY";
    const today = moment();
    const yesterday = moment().subtract(1, "day");

    let parsedDate;

    if (moment.isMoment(date)) {
      parsedDate = date;
    } else {
      parsedDate = moment(date, format, true);
    }

    if (!parsedDate.isValid()) {
      return "Invalid date";
    }

    if (parsedDate.isSame(today, "day")) return "Today";
    else if (parsedDate.isSame(yesterday, "day")) return "Yesterday";

    return parsedDate.format(format);
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimer);
  }

  componentDidMount() {
    this.MessageWrapper.scrollTop = this.MessageWrapper.scrollHeight;

    let { conversation } = this.props.chat;

    let groupedMessages = conversation.messages.reduce((groups, message) => {
      const date = new Date(message.created_at).toLocaleDateString(
        dateTimeConstants.LOCALE_DATE_STRING_FORMAT,
        {
          month: dateTimeConstants.TWO_DIGIT_FORMAT,
          day: dateTimeConstants.TWO_DIGIT_FORMAT,
          year: dateTimeConstants.NUMERIC_FORMAT,
        },
      );
      if (!groups[date]) groups[date] = [];
      groups[date].push(message);
      return groups;
    }, {});

    this.setState(
      {
        messageList: groupedMessages || [],
      },
      () => {
        this.scrollToBottom(true);
      },
    );

    // this.refreshTimer = setInterval(
    //   function() {
    //     let chat = this.props.chat;
    //     if (!chat.conversation.loading && chat.conversation.id) {
    //       this.props.dispatch(chatActions.getLatestMessages(chat.conversation));
    //     }
    //   }.bind(this),
    //   15000
    // );
  }

  componentDidUpdate(oldProps, newProps) {
    let { conversation } = this.props.chat;

    if (conversation !== oldProps.chat.conversation) {
      let prevLength = oldProps.chat.conversation.messages.length;

      let groupedMessages = conversation.messages.reduce((groups, message) => {
        const date = new Date(message.created_at).toLocaleDateString(
          dateTimeConstants.LOCALE_DATE_STRING_FORMAT,
          {
            month: dateTimeConstants.TWO_DIGIT_FORMAT,
            day: dateTimeConstants.TWO_DIGIT_FORMAT,
            year: dateTimeConstants.NUMERIC_FORMAT,
          },
        );
        if (!groups[date]) groups[date] = [];
        groups[date].push(message);
        return groups;
      }, {});

      let isScrollLoading = this.state.scrollLoading;
      let currentHeight = this.MessageWrapper.scrollHeight;

      this.setState(
        {
          messageList: groupedMessages || [],
          scrollLoading: false,
        },
        () => {
          if (prevLength != conversation.messages.length && !isScrollLoading) {
            this.scrollToBottom(true);
          }
          if (isScrollLoading) {
            this.MessageWrapper.scrollTop =
              this.MessageWrapper.scrollHeight - currentHeight;
          }
        },
      );
    }
  }

  render() {
    let { messageList } = this.state;
    let { conversation, admin } = this.props.chat;
    let { permissions } = this.props.permission;
    let { profile } = this.props.user;

    return (
      <React.Fragment>
        {this.state.scrollLoading && <Spinner />}
        <div
          ref={(el) => {
            this.MessageWrapper = el;
          }}
          onScroll={this.messageListScroll}
          className={`container-fluid msg-messages-list${admin.active ? " admin-mode" : ""}${
            admin.active ? " is-admin" : ""
          }${
            profile &&
            conversation.users.some((_user) => _user.hash == profile.data.hash)
              ? ""
              : " admin-mode"
          }`}
        >
          <div className="row msg-message-section">
            <div className="col-sm-12 col-msg">
              {Object.keys(messageList).length > 0 &&
                permissions &&
                Object.keys(messageList).map((key, index) => {
                  return (
                    <React.Fragment key={index}>
                      {Object.keys(messageList).length > 1 && (
                        <div className="mgs-updated-time">
                          <span>{this.getDateLabel(key)}</span>
                        </div>
                      )}
                      {messageList[key] &&
                        Array.isArray(messageList[key]) &&
                        messageList[key].map((message, msgIndex) => {
                          switch (message.type) {
                            case "file":
                              return (
                                <FileMessage
                                  settings={this.props.chat.settings}
                                  permissions={permissions.data}
                                  admin={admin}
                                  key={msgIndex}
                                  message={message}
                                  user={this.props.user}
                                  editMessage={this.editMessage}
                                  deleteMessage={this.deleteMessage}
                                />
                              );
                              break;
                            case "info":
                              return (
                                <LineNotification
                                  key={msgIndex}
                                  message={message}
                                  user={this.props.user}
                                  conversation={conversation}
                                />
                              );
                              break;
                            default:
                              return (
                                <TextMessage
                                  key={msgIndex}
                                  isEditing={this.state.isEditing}
                                  toggleEditing={this.toggleEditing}
                                  conversation={conversation}
                                  permissions={permissions.data}
                                  message={message}
                                  user={this.props.user}
                                  admin={admin}
                                  editMessage={this.editMessage}
                                  deleteMessage={this.deleteMessage}
                                />
                              );
                          }
                        })}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { chat, user, permission } = state;
  return { chat, user, permission };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  Conversation,
);
