import React from "react";

import { convertDateTimeToPST, formatDate } from "_helpers/helpers";

import { useUpdateProfile } from "./EditProfile";
import DatePicker from "react-datepicker";
import { dateTimeConstants } from "_constants";
import moment from "moment";
import AutoGrowTextarea from "_components/utils/AutoGrowTextrea";

const WorkersCompensationComponent = ({
  profileData,
  refetchUser,
  refetchHistory,
  isOpen,
  toggleAccordion,
}) => {
  const [comp, setComp] = React.useState("");
  const [compDate, setCompDate] = React.useState(moment());
  const [displayAllComp, setDisplayAllComp] = React.useState(false);

  const handleCompChange = (event) => {
    setComp(event.target.value);
  };

  const handleCompDateChange = (date) => {
    setCompDate(date);
  };

  const mutation = useUpdateProfile(refetchUser, refetchHistory, () => {
    setComp("");
    setCompDate(moment());
  });

  const toggleAllCompDisplay = () => {
    setDisplayAllComp(!displayAllComp);
  };

  const cancelComp = () => {
    setComp("");
  };

  const saveComp = () => {
    let data = new FormData();
    data.append("worker_comp", comp.trim());
    data.append(
      "worker_compensation_date",
      moment(compDate).format(dateTimeConstants.DATE_FORMAT),
    );
    mutation.mutate({ userId: profileData.id, formData: data });
  };

  return (
    <div
      id="workers-compensation"
      className={`profile-data__drops profile-data_comp profile-data__drops--notes ${
        isOpen ? "is-active" : ""
      }`}
    >
      <header onClick={() => toggleAccordion("#workers-compensation")}>
        <button type="button">
          <h3>Workers' Compensation</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <div className="profile-data__form-wrap is-active">
           <AutoGrowTextarea
            maxLength='1200'
            value={comp}
            onChange={handleCompChange}
            placeholder='Add your note here'
          />
          {comp.trim().length ? (
            <>
            <h5>Compensation Date</h5>
              <DatePicker
                selected={compDate ? moment(compDate) : ""}
                onChange={(date) => handleCompDateChange(date ? date : "")}
                showMonthDropdown
                showYearDropdown
                customInput={<input inputMode="none" />}
                placeholderText="Enter Compensation Date"
                dateFormat={dateTimeConstants.DATE_FORMAT}
                showPopperArrow={false}
              />
              <footer>
                <button
                  onClick={cancelComp}
                  type="button"
                  className="l-btn l-btn--close"
                >
                  Cancel
                </button>
                <button
                  disabled={!compDate}
                  onClick={saveComp}
                  type="button"
                  className="l-btn"
                >
                  Save
                </button>
              </footer>
            </>
          ) : (
            <></>
          )}
        </div>
        <ul role="list">
          {profileData.worker_compensation.length &&
          profileData.worker_compensation[0] ? (
            <li role="listitem">
              <div className="profile-data__note">
                <header>
                  <h4>
                    Note
                    {profileData.worker_compensation[0].author_user ? (
                      " by " +
                      profileData.worker_compensation[0].author_user.full_name
                    ) : (
                      <></>
                    )}
                  </h4>
                  <div className="profile-data__note-dates">
                    <span>
                      {profileData.worker_compensation[0].author_user
                        ? convertDateTimeToPST(
                            profileData.worker_compensation[0].created_at,
                          )
                        : ""}
                    </span>
                  </div>
                </header>
                <p>{profileData.worker_compensation[0].note}</p>
                <p>
                  Compensation Date:{" "}
                  {profileData.worker_compensation[0].comp_date
                    ? formatDate(profileData.worker_compensation[0].comp_date)
                    : "-"}
                </p>
              </div>
            </li>
          ) : (
            <></>
          )}
          {displayAllComp &&
            profileData.worker_compensation.length > 1 &&
            profileData.worker_compensation.slice(1).map((noteItem, index) => (
              <li role="listitem">
                <div className="profile-data__note">
                  <header>
                    <h4>
                      Note
                      {noteItem.author_user ? (
                        " by " + noteItem.author_user.full_name
                      ) : (
                        <></>
                      )}
                    </h4>
                    <div className="profile-data__note-dates">
                      <span>
                        {noteItem.author_user
                          ? convertDateTimeToPST(noteItem.created_at)
                          : ""}
                      </span>
                    </div>
                  </header>
                  <p>{noteItem.note}</p>
                  <p>
                    Compensation Date:{" "}
                    {noteItem.comp_date ? formatDate(noteItem.comp_date) : "-"}
                  </p>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <footer
        className={
          profileData.worker_compensation.length > 1 ? "footer-border" : ""
        }
      >
        {profileData.worker_compensation.length > 1 && (
          <button onClick={toggleAllCompDisplay} type="button">
            Show {displayAllComp ? "Less" : "All"}
          </button>
        )}
      </footer>
    </div>
  );
};

export default WorkersCompensationComponent;
