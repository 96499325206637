import { profileConstants } from "_constants/profile.constants";
import { createAsyncReduxAction } from "_helpers/helpers";
import { profileService } from "_services/profile.service";

export const profileActions = {
  getRecipientsList,
  addRecipientForTor,
  deleteRecipientForTor,
};

function getRecipientsList(data) {
  const actionTypes = {
    request: profileConstants.GET_RESOURCE_RECIPIENTS_REQUEST,
    success: profileConstants.GET_RESOURCE_RECIPIENTS_SUCCESS,
    failure: profileConstants.GET_RESOURCE_RECIPIENTS_FAILURE,
  };
  return createAsyncReduxAction(
    profileService.getRecipientsList,
    actionTypes,
  )(data);
}

function addRecipientForTor(data) {
  const actionTypes = {
    request: profileConstants.ADD_RESOURCE_RECIPIENT_TOR_REQUEST,
    success: profileConstants.ADD_RESOURCE_RECIPIENT_TOR_SUCCESS,
    failure: profileConstants.ADD_RESOURCE_RECIPIENT_TOR_FAILURE,
  };
  return createAsyncReduxAction(
    profileService.addRecipientForTor,
    actionTypes,
  )(data);
}

function deleteRecipientForTor(data) {
  const actionTypes = {
    request: profileConstants.DELETE_RESOURCE_RECIPIENT_TOR_REQUEST,
    success: profileConstants.DELETE_RESOURCE_RECIPIENT_TOR_SUCCESS,
    failure: profileConstants.DELETE_RESOURCE_RECIPIENT_TOR_FAILURE,
  };
  return createAsyncReduxAction(
    profileService.deleteRecipientForTor,
    actionTypes,
  )(data);
}
