import { interviewConstants } from "../_constants";
import { interviewService } from "../_services";

export const interviewActions = {
  getInterviewFormList,
  getInterviewForm,
  saveInterviewForm,
  deleteInterviewForm,
};

function getInterviewFormList(data) {
  return (dispatch) => {
    dispatch(request());
    interviewService.getInterviewFormList(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error)),
    );
  };
  function request() {
    return { type: interviewConstants.INT_FORM_LIST_REQUEST };
  }
  function success(list) {
    return {
      type: interviewConstants.INT_FORM_LIST_SUCCESS,
      payload: list.data,
    };
  }
  function failure(error) {
    return { type: interviewConstants.INT_FORM_LIST_FAILURE, error };
  }
}

function getInterviewForm(id) {
  return (dispatch) => {
    dispatch(request());
    interviewService.getInterviewForm(id).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error)),
    );
  };
  function request() {
    return { type: interviewConstants.INT_FORM_GET_REQUEST };
  }
  function success(form) {
    return {
      type: interviewConstants.INT_FORM_GET_SUCCESS,
      payload: form.data,
    };
  }
  function failure(error) {
    return { type: interviewConstants.INT_FORM_GET_FAILURE, error };
  }
}

function saveInterviewForm(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      interviewService.saveInterviewForm(data).then(
        (form) => {
          dispatch(success(form));
          resolve(form);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        },
      );
    });

  function request() {
    return { type: interviewConstants.INT_FORM_SAVE_REQUEST };
  }
  function success(form) {
    return {
      type: interviewConstants.INT_FORM_SAVE_SUCCESS,
      payload: form.data,
    };
  }
  function failure(error) {
    return { type: interviewConstants.INT_FORM_SAVE_FAILURE, error };
  }
}

function deleteInterviewForm(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      interviewService.deleteInterviewForm(data).then(
        (form) => {
          dispatch(success(form));
          resolve(form);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        },
      );
    });

  function request() {
    return { type: interviewConstants.INT_FORM_DELETE_REQUEST };
  }
  function success(form) {
    return {
      type: interviewConstants.INT_FORM_DELETE_SUCCESS,
      payload: form.data,
    };
  }
  function failure(error) {
    return { type: interviewConstants.INT_FORM_DELETE_FAILURE, error };
  }
}
