import React from "react";
import { connect } from "react-redux";
import { authHeader, config } from "../_helpers";
import {
  applicantsActions,
  permissionActions,
  createProfileActions,
  resourceActions,
} from "../_actions";
import "../assets/styles/applicants.css";
import moment from "moment";
import ReactModal from "react-modal";
import { AlertPopup } from "../_components/AlertPopup";
import { toast } from "react-toastify";
import NoInterviewFormAlert from "_components/NoInterviewFormAlert/NoInterviewFormAlert";
import Modal from "_components/utils/Modal/Modal";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import DateAndTimePicker from "_components/utils/DateAndTimePicker/DateAndTimePicker";
import ApplicantStatusCard from "_components/utils/ApplicantStatusCard/ApplicantStatusCard";
import { AddEditNote } from "_components/utils/AddEditNote/AddEditNote";
import { ApplicantNoteCard } from "_components/utils/ApplicantNoteCard/ApplicantNoteCard";
import {
  checkCurrentUserIsSuperAdmin,
  handleErrorResponse,
} from "_helpers/helpers";
import { formatDate, formatDateTime } from "_helpers/helpers";
import { contactEmailConstants, profileType } from "_constants";
import { Button } from "_components/utils/Button/Button";
import SmsNotification from "SmsNotification/SmsNotification";

ReactModal.setAppElement("#app");

function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}

const APPLICATIONSTATUSES = {
  received: 1,
  inReview: 3,
  offeredPosition: 4,
  wasHired: 2,
  notSelected: 0,
  doNotHire: 10,
};

const statusApplication = {
  NOT_SELECTED_ARCHIVE: 0,
  RECIEVED: 1,
  WAS_HIRED: 2,
  IN_REVIEW: 3,
  OFFERED_POSITION: 4,
  DO_NOT_HIRE_ARCHIVE: 10,
};

class ViewApplicant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusUpdating: false,
      flag: true,
      applicationItem: [],
      notes: "",
      statusOpen: false,
      removeStatusOpen: false,
      removeAction: "",
      hash: this.props.hash,
      email: this.props.email,
      selected: "",
      applicant_list: [],
      hideSearch: "hide",
      user_list: [],
      borderError: "",
      appication_history: [],
      to: "",
      forwardingNote: "",
      crews: [],
      applicant_details: null,
      applicantStatusList: [],
      applicantStatus: 0,
      crew: "",
      whoSelected: "",
      whoRejected: "",
      rejectionReason: "",
      date: moment(),
      time: moment(),
      isConfirmUpdateModalOpen: false,
      isNotesOpen: false,
      selectedApplicantNote: "",
      editableNote: { note: "", id: "" },
      noteEditMode: false,
      reasonEditMode: false,
      onOutsideClick: false,
      FULL_DATE_FORMAT: "",
      applicationStatus: null,
      interviewFormSubmissionDate: moment(),
      formerEmpModal: false,
      formerEmpApproval: null,
      admin_reason_for_leaving: "",
    };
    this.prevNext = this.prevNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.headerSearch = this.headerSearch.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.removeStatusOpenModal = this.removeStatusOpenModal.bind(this);
    this.removeStatusCloseModal = this.removeStatusCloseModal.bind(this);
    this.updateApplicantStatus = this.updateApplicantStatus.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.forwardApplicant = this.forwardApplicant.bind(this);
    this.keyUpFn = this.keyUpFn.bind(this);
    this.goBack = this.goBack.bind(this);
    this.fileDownload = this.fileDownload.bind(this);
    this.userClick = this.userClick.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.checkNull = this.checkNull.bind(this);
    this.onForwardingNote = this.onForwardingNote.bind(this);
    this.onChangeUpdateApplicantStatus =
      this.onChangeUpdateApplicantStatus.bind(this);
    this.onChangeUpdateApplicantDate =
      this.onChangeUpdateApplicantDate.bind(this);
    this.onChangeUpdateApplicantTime =
      this.onChangeUpdateApplicantTime.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.findCurrentCrew = this.findCurrentCrew.bind(this);
    this.findWhoSelected = this.findWhoSelected.bind(this);
    this.toggleNotes = this.toggleNotes.bind(this);
    this.onEditNoteClick = this.onEditNoteClick.bind(this);
    this.onCancelAddEditNote = this.onCancelAddEditNote.bind(this);
    this.onCancelAddEditReason = this.onCancelAddEditReason.bind(this);
    this.onChangeEditNote = this.onChangeEditNote.bind(this);
    this.onChangeEditReason = this.onChangeEditReason.bind(this);
    this.deleteApplicantNote = this.deleteApplicantNote.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.saveReason = this.saveReason.bind(this);
  }

  onChangeUpdateApplicantStatus(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSelect(e) {
    const { name, value } = e.target;
    this.setState({ formerEmpApproval: value });
  }

  formerEmpOpenModal() {
    this.setState({ formerEmpModal: true });
  }

  formerEmpCloseModal() {
    this.setState({ formerEmpModal: false });
  }

  changeFormerEmpApprovalStatus() {
    this.setState({ statusUpdating: true });

    let data = new FormData();
    data.append("hash", this.state.hash);
    data.append("approval_status", this.state.formerEmpApproval);

    this.props
      .dispatch(applicantsActions.changeFormerEmployeeApprovalStatus(data))
      ?.then((res) => {
        if (res?.success) {
          window.location.href = "/former-employed-applicants";
          this.formerEmpCloseModal();
          this.setState({
            statusUpdating: false,
          });
        } else {
          handleErrorResponse(res);
          this.setState({ statusUpdating: false });
        }
      })
      ?.catch((err) => {
        this.setState({ statusUpdating: false });
        throw err;
      });
  }

  saveReason() {
    let data = new FormData();
    data.append("hash", this.state.hash);
    data.append(
      "admin_reason_for_leaving",
      this.state.admin_reason_for_leaving
    );

    this.props
      .dispatch(applicantsActions.saveAdminReasonForLeaving(data))
      ?.then((res) => {
        if (res?.success) {
          toast.success("Saved reason for leaving / returning successfully.");
        } else {
          toast.error("Faied to save reason for leaving / returning.");
        }
      })
      ?.catch((err) => {
        toast.error("Faied to save reason for leaving / returning.");
        throw err;
      });
  }

  toggleNotes(note) {
    if (this.state.selectedApplicantNote) {
      return this.setState({ isNotesOpen: false, selectedApplicantNote: null });
    }
    this.setState({
      isNotesOpen: !this.state.isNotesOpen,
      selectedApplicantNote: note,
    });
  }

  onEditNoteClick(note) {
    this.setState({
      noteEditMode: true,
      editableNote: {
        ...this.state.editableNote,
        id: note.id,
        note: note.notes,
      },
    });
  }

  onCancelAddEditNote() {
    this.setState({ editableNote: { note: "", id: "" }, noteEditMode: false });
  }

  onCancelAddEditReason() {
    this.setState({ reasonEditMode: false });
  }

  onChangeEditNote(value) {
    this.setState({
      editableNote: { ...this.state.editableNote, note: value },
    });
  }

  onChangeEditReason(e) {
    const { value } = e.target;
    this.setState({
      admin_reason_for_leaving: value,
    });
  }

  async deleteApplicantNote(note) {
    this.setState({ isNoteLoading: true });
    const { id } = note;
    const data = new FormData();
    data.append("note_id", id);
    const res = await this.props.dispatch(applicantsActions.deleteNote(data));
    if (res.data.success) {
      toast.success(res.data.message);
      await this.props.dispatch(applicantsActions.viewDetails(this.state.hash));
      this.setState({ isNoteLoading: false });
    }
  }

  statusOpenModal(hash, status, selectedApplicantNoInterviewForms) {
    this.setState({
      statusOpen: true,
      removeApplicant: hash,
      currentStatusUpdate: status > -1 ? status : "",
      selectedApplicantNoInterviewForms,
    });
  }

  onChangeUpdateApplicantDate(date) {
    this.setState({ date });
  }

  onChangeUpdateApplicantTime(time) {
    this.setState({ time });
  }


  onChangeSelect(selected, type) {
    this.setState({ [type]: selected.value });
    if (type === "whoSelected") {
      this.setState({ whoRejected: "", rejectionReason: "" });
    } else if (type === "whoRejected") {
      this.setState({ whoSelected: "", crew: "" });
    } else if (
      (type === "applicantStatus" &&
        selected.value === statusApplication.RECIEVED) ||
      selected.value === statusApplication.IN_REVIEW ||
      selected.value === statusApplication.OFFERED_POSITION
    ) {
      this.setState({
        whoRejected: "",
        rejectionReason: "",
        whoSelected: "",
        crew: "",
      });
    }
  }

  async handleSearch(value, type) {
    let data = new FormData();
    data.append("keyword", value);
    let response = [];
    switch (type) {
      case "crew":
        const crews = await this.props.dispatch(
          resourceActions.searchResource(data)
        );
        response = crews;
        break;
      case "who selected":
      case "who rejected":
        const employees = await this.props.dispatch(
          resourceActions.employeeList(data)
        );
        response = employees?.data;
        break;
      default:
        break;
    }

    const items = response?.data;
    let res = [];
    let options = [];

    if (type === "who selected" || type === "who rejected") {
      options = items.map((emp) => ({
        ...emp,
        value: emp.userid,
        label: `${emp.last_name} ${emp.first_name}`,
      }));
      return options;
    }

    for (let key in items) {
      let arr = items[key];
      if (arr.length) {
        res = res.concat(arr);
      }
    }

    options = res.map((item) => ({
      label: item?.resource_name,
      value: item?.id,
    }));

    return options;
  }

  loadOptions = (value, callback, type) => {
    setTimeout(async () => {
      const res = await this.handleSearch(value, type);
      callback(res);
    }, 500);
  };

  componentDidMount() {
    this.props.dispatch(createProfileActions.getAll());
    this.props.dispatch(applicantsActions.viewDetails(this.state.hash));
    this.props.dispatch(permissionActions.getPermission());
  }

  findLabelsForCrewAndWhoSelected = () => {
    if (
      this.state.applicationStatus === APPLICATIONSTATUSES.received ||
      this.state.applicationStatus === APPLICATIONSTATUSES.inReview ||
      this.state.applicationStatus === APPLICATIONSTATUSES.offeredPosition
    ) {
      return true;
    }
  };

  findCurrentStatus = () => {
    const currentStatus = this.state?.applicantStatusList?.find(
      (item) =>
        item.value === this.props?.applicants?.list?.data?.applicant_status
    );
    this.setState({ applicantStatus: currentStatus?.value });
    return currentStatus?.label
      ? { label: currentStatus?.label, value: currentStatus?.value }
      : null;
  };

  findCurrentCrew() {
    if (this.findLabelsForCrewAndWhoSelected()) {
      return {
        label: this.props?.user?.profile?.data?.crew_name,
        value: this.props?.user?.profile?.data?.crew_identifier_id,
      };
    }

    return this.state?.applicant_details?.crew
      ? {
          label: this.state?.applicant_details?.crew?.resource_name,
          value: this.state.applicant_details?.crew?.id,
        }
      : null;
  }

  findWhoSelected() {
    if (this.findLabelsForCrewAndWhoSelected()) {
      return {
        label: this.props?.user?.profile?.data?.full_name_last_first,
        value: this.props?.user?.profile?.data?.id,
      };
    }

    return this.state?.applicant_details?.performed_user
      ? {
          label: this.state?.applicant_details?.performed_user?.full_name,
          value: this.state?.applicant_details?.performed_user?.id,
        }
      : null;
  }

  componentWillReceiveProps(nextProps) {
    const applicants = nextProps.applicants.list;
    const user = nextProps;
    const { allAdmin } = nextProps.search;
    var user_list = [];
    const US_DATE_FORMAT =
      user?.user?.profile?.data.date_formats?.US_DATE_FORMAT;

    const statuses = nextProps.listAll?.listAll?.ApplicantStatus?.map(
      (item) => ({ ...item, label: item?.name })
    );
    const applicantDetails = this.props?.applicants?.list?.data;
    const statusChangeDateAndTime = applicantDetails?.status_change_date;
    const interviewFormDate = applicantDetails?.interview_form_submission_date;
    this.setState({
      FULL_DATE_FORMAT:
        user?.user?.profile?.data.date_formats?.FULL_DATE_FORMAT,
    });

    this.setState({
      applicantStatusList: statuses,
      applicant_details: applicantDetails,
      rejectionReason: applicantDetails?.rejected_reason,
      date: moment(statusChangeDateAndTime).isValid()
        ? moment(statusChangeDateAndTime, this.state.FULL_DATE_FORMAT)
        : moment(),
      time: moment(statusChangeDateAndTime).isValid()
        ? moment(statusChangeDateAndTime, this.state.FULL_DATE_FORMAT)
        : moment(),
      whoRejected: applicantDetails?.performed_by,
      whoSelected: applicantDetails?.performed_by,
      hasInterviewForms: applicantDetails?.interview_form_count > 0,
      applicationStatus: this.props?.applicants?.list?.data?.applicant_status,
      interviewFormSubmissionDate: moment(interviewFormDate).isValid()
        ? moment(interviewFormDate).format(US_DATE_FORMAT)
        : "",
    });

    // Dispalys success alert message
    if (applicants) {
      if (applicants.success) {
        this.setState({
          hash: applicants.data.hash,
          email: applicants.data.email_address,
          applicant_list: applicants,
          notes: applicants.data.notes,
          appication_history: applicants.data.appication_history,
          admin_reason_for_leaving: applicants.data?.admin_reason_for_leaving,
        });
      } else {
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: JSON.parse(JSON.stringify(applicants.message)),
          alertFunction: this.goBack,
        });
      }
    }
    if (allAdmin) {
      const userSearchList = allAdmin.data;
      if (userSearchList) {
        userSearchList.data.map((item, index) => {
          var name = item.first_name + " " + item.last_name;
          user_list.push(
            <li
              className="user_click"
              onClick={this.userClick.bind(this, name, item.email, item.hash)}
              key={index}
            >
              <span>{item.first_name + " " + item.last_name}</span>
              <img
                src={
                  item.profile_photo
                    ? config.apiUrl + "/" + item.profile_photo
                    : config.apiUrl + "/" + config.altImg
                }
                onError={this.onImageError.bind(this)}
                alt="profile_photo"
              />
            </li>
          );
        });
        this.setState({ user_list });
      }
    }
    if (nextProps.enableStatusModal) {
      this.removeStatusOpenModal(
        applicants ? applicants.data.applicant_status : ""
      );
    }
  }

  statusOpenModal(hash, status, selectedApplicantNoInterviewForms) {
    this.setState({
      statusOpen: true,
      updateApplicantStatus: hash,
      currentStatusUpdate: status > -1 ? status : "",
      selectedApplicantNoInterviewForms,
    });
  }

  statusCloseModal() {
    this.setState({
      statusOpen: false,
      statusUpdating: false,
      selectedApplicantNoInterviewForms: false,
      forwardingNote: "",
    });
  }

  removeStatusOpenModal(status) {
    this.setState({ removeStatusOpen: true, currentStatusUpdate: status });
  }

  removeStatusCloseModal() {
    this.setState({ removeStatusOpen: false });
    this.props.setEnableStatusModal(false);
    this.setState({
      whoRejected: "",
      rejectionReason: "",
      whoSelected: "",
      crew: "",
    });
  }

  editApplicant(hash) {
    this.props.editApplicant(this.state.hash);
  }

  viewHistory() {
    this.props.viewHistory(this.state.hash);
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  fileDownload(files) {
    if (files.length > 0) {
      files.map((file, index) => {
        const response = {
          file: config.apiUrl + "/" + file.file_hash,
        };
        setTimeout(function () {
          window.open(response.file);
        }, 1);
      });
    }
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  keyUpFn(e) {
    var textInput = document.getElementById("txtSearchUsersForForward");
    clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(
      function () {
        this.headerSearch(textInput.value);
      }.bind(this),
      1000
    );
  }

  headerSearch(text) {
    var content = "";
    let data = new FormData();
    data.append("keyword", text);

    if (text.length === 0) {
      this.setState({ hideSearch: "hide" });
    } else if (text.length >= 1) {
      this.setState({ hideSearch: "show", search_close: "show" });
      this.props.dispatch(permissionActions.adminSearch(data));
    } else {
      this.setState({ formShow: "show" });
    }
    this.props.dispatch(permissionActions.getPermission());
  }

  userClick(name, email, hash, event) {
    this.setState({ to: email, user_hash: hash });
    document.getElementById("txtSearchUsersForForward").value = name;
    this.setState({ hideSearch: "hide" });
  }

  async updateNotes(noteDetails) {
    this.setState({ isNoteLoading: true });
    const applicantHash = this?.state?.noteEditMode
      ? noteDetails.applicant_hash
      : this.state.hash;

    const { id, note } = this.state.editableNote;
    let data = new FormData();
    data.append("applicant_hash", applicantHash);
    data.append("notes", note);
    data.append("note_id", this.state.noteEditMode ? id : "");
    await this.props
      .dispatch(applicantsActions.updateNote(data))
      .then(async (res) => {
        if (res?.data?.success) {
          toast.success(res.data.message);
          this.setState({
            noteEditMode: false,
            editableNote: {
              ...this.state.editableNote,
              id: "",
              note: "",
            },
          });
          await this.props.dispatch(
            applicantsActions.viewDetails(this.state.hash)
          );
          this.setState({ isNoteLoading: false });
        } else {
          toast.error("Creating note failed!");
          this.setState({ isNoteLoading: false });
        }
      });
  }

  goBack() {
    this.props.goBack();
  }

  print() {
    window.print();
  }

  printManifest() {
    var hash = this.props.hash;
    var data = "?hash=" + hash + "";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(config.apiUrl + "/api/printapplicant" + data, requestOptions)
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        const pdfLink = data.data;

        var link = document.createElement("a");
        if (
          window.navigator.userAgent.match(/iPad/i) ||
          window.navigator.userAgent.match(/iPhone/i)
        ) {
          link.href = pdfLink;
        } else {
          link.href = pdfLink;
          link.target = "_blank";
        }
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          document.body.removeChild(link);
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(pdfLink);
        }, 100);
      });
  }

  sendMail() {
    this.props.sendMail(this.state.email);
  }

  chooseAction(action) {
    var status = {
      denied: 0,
      received: 1,
      hire: 2,
      inreview: 3,
      offered: 4,
      donthire: 10,
      remove: 0,
    };
    this.setState({
      removeAction: action,
      selected: action,
      currentStatusUpdate: status[action],
      validMsg: "",
      borderError: "",
    });
  }

  // set active class for selected option
  isActive(value) {
    var status = {
      0: "remove",
      1: "received",
      2: "hire",
      3: "inreview",
      4: "offered",
      10: "donthire",
    };
    if (
      this.state.currentStatusUpdate > -1 &&
      status.hasOwnProperty(this.state.currentStatusUpdate)
    ) {
      if (status[this.state.currentStatusUpdate] === value) {
        return "active";
      }
    }
    return "";
    // return value === this.state.selected ? "active" : "default";
  }

  validate() {
    var err = false;
    if (this.state.removeAction === "") {
      return true;
    }
  }

  checkNull(value) {
    let flag = false;
    if (value === null) {
      flag = true;
    } else if (value === "") {
      flag = true;
    } else if (value === "null") {
      flag = true;
    } else if (value === undefined) {
      flag = true;
    }
    return flag;
  }
  prevNext(hash) {
    this.props.dispatch(applicantsActions.viewDetails(hash));
  }

  updateApplicantStatus() {
    const {
      hash,
      applicantStatus,
      crew,
      whoRejected,
      whoSelected,
      rejectionReason,
      date,
      time,
      isConfirmUpdateModalOpen,
      hasInterviewForms,
    } = this.state;

    const isConfirmNeeded = [
      statusApplication.WAS_HIRED,
      statusApplication.NOT_SELECTED_ARCHIVE,
      statusApplication.DO_NOT_HIRE_ARCHIVE,
    ].includes(applicantStatus);

    if (
      (applicantStatus === statusApplication.NOT_SELECTED_ARCHIVE ||
        applicantStatus === statusApplication.DO_NOT_HIRE_ARCHIVE) &&
      !rejectionReason?.length
    ) {
      return toast.error("The  notes on reason for rejection can't be empty.");
    }

    if (
      isConfirmNeeded &&
      this.props.applicants.list.data.applicant_status !== applicantStatus &&
      !isConfirmUpdateModalOpen
    ) {
      return this.setState({ isConfirmUpdateModalOpen: true });
    } else if (
      (applicantStatus === statusApplication.OFFERED_POSITION ||
        applicantStatus === statusApplication.WAS_HIRED) &&
      !hasInterviewForms
    ) {
      return toast.error(
        " The applicant hasn't filled out the interview form."
      );
    }

    this.setState({ statusUpdating: true });

    let data = new FormData();
    data.append("hash", hash);
    data.append("action", applicantStatus);

    if (isConfirmNeeded) {
      if (applicantStatus === statusApplication.WAS_HIRED) {
        data.append(
          "crew_id",
          crew ? crew : this.props?.user?.profile?.data?.crew_identifier_id
        );
      } else if (
        applicantStatus === statusApplication.DO_NOT_HIRE_ARCHIVE ||
        applicantStatus === statusApplication.NOT_SELECTED_ARCHIVE
      ) {
        data.append("rejected_reason", rejectionReason);
      }
      data.append("performed_by", this.props?.user?.profile?.data?.id);
      data.append(
        "status_change_date",
        date && time
          ? `${moment(date).format("YYYY-MM-DD")} ${moment(time).format(
              "hh:mm:ss"
            )}`
          : null
      );
    }

    this.props
      .dispatch(applicantsActions.removeApplicant(data))
      ?.then((res) => {
        if (res?.success) {
          this.props?.goBack();
          this.removeStatusCloseModal();
          this.setState({
            isConfirmUpdateModalOpen: false,
            statusUpdating: false,
          });
          this.componentDidMount();
          this.setState({ statusUpdating: false });
        } else {
          handleErrorResponse(res);
          this.setState({ statusUpdating: false });
        }
      })
      ?.catch((err) => {
        throw err;
      });
  }

  updateApplicantStatusErrorToast(message) {
    toast?.error(message);
    this.setState({ statusUpdating: false });
  }

  forwardApplicant() {
    let data = new FormData();
    this.statusCloseModal();
    data.append("hash", this.state.hash);
    data.append("to", this.state.to || "");
    data.append("forward_note", this.state.forwardingNote);

    var self = this;
    fetch(config.apiUrl + "/api/forwardapplicant", {
      headers: authHeader(),
      method: "POST",
      body: data,
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (data.success) {
          self.setState({
            alertPop: true,
            alertHead: "Success",
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: "",
            to: "",
            forwardingNote: "",
          });
        } else {
          self.setState({
            alertPop: true,
            alertHead: "Error",
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: "",
          });
        }
      });
  }

  onForwardingNote(e) {
    this.setState({ forwardingNote: e.target.value });
  }

  render() {
    const applicants = this.props.applicants.list;
    const applicantStatusList = this.state.applicantStatusList;
    const status = applicants?.data?.status;

    let title = "";

    if (
      status === statusApplication.NOT_SELECTED_ARCHIVE ||
      status === statusApplication.DO_NOT_HIRE_ARCHIVE
    ) {
      title = "Who rejected them?";
    } else if (status === statusApplication.WAS_HIRED) {
      title = "Who selected them?";
    }
    let university1 = [],
      university2 = [],
      license = [],
      reference1 = [],
      reference2 = [],
      reference3 = [],
      professional1 = [],
      professional2 = [],
      professional3 = [],
      applying_position = [],
      qualifications = [],
      reference1_flag = false,
      reference2_flag = false,
      reference3_flag = false,
      professional1_flag = false,
      professional2_flag = false,
      professional3_flag = false;

    let prevNext = [];
    if (applicants && applicants.success) {
      if (this.props.applnType === "") {
        if (applicants && applicants.data.previous !== null) {
          prevNext.push(
            <p
              key="prev"
              className="gotoPrev left"
              onClick={this.prevNext.bind(this, applicants.data.previous.hash)}
            >
              <span>Previous Applicant: </span>
              {applicants.data.previous.first_name +
                " " +
                applicants.data.previous.last_name}{" "}
            </p>
          );
        }
        if (applicants && applicants.data.next !== null) {
          prevNext.push(
            <p
              key="next"
              className="gotoNext right"
              onClick={this.prevNext.bind(this, applicants.data.next.hash)}
            >
              <span>Next Applicant: </span>
              {applicants.data.next.first_name +
                " " +
                applicants.data.next.last_name}{" "}
            </p>
          );
        }
      }
    }
    function checkProperties(obj) {
      for (var key in obj) {
        if (key !== "id") {
          if (
            obj[key] !== null &&
            obj[key] != "" &&
            obj[key] != "undefined" &&
            obj[key] != "null"
          )
            return false;
        }
      }
      return true;
    }

    const { permission } = this.props;
    let permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }
    const loggedInUser = this.props?.user?.profile?.data;
    const isSupAdmin = loggedInUser?.is_admin === 2;

    if (applicants && applicants.success) {
      var notes;
      if (this.state.notes !== null) {
        notes = this.state.notes;
      } else {
        notes = "";
      }

      let dateObj,
        reference1_start_date_Obj,
        reference1_end_date_Obj,
        dateObj1,
        dateObj2,
        reference2_start_date_Obj,
        reference2_end_date_Obj,
        dateObj3,
        dateObj4,
        reference3_start_date_Obj,
        dateObj5,
        reference3_end_date_Obj,
        reference1_date,
        reference2_date,
        reference3_date;

      if (
        applicants.data.reference1 &&
        applicants.data.reference1.start_date !== ""
      ) {
        reference1_start_date_Obj = formatDate(
          applicants.data.reference1.start_date
        );
      } else {
        reference1_start_date_Obj = "";
      }

      if (
        applicants.data.reference1 &&
        applicants.data.reference1.end_date !== ""
      ) {
        reference1_end_date_Obj = formatDate(
          applicants.data.reference1.end_date
        );
      } else {
        reference1_end_date_Obj = "";
      }

      if (
        applicants.data.reference2 &&
        applicants.data.reference2.start_date !== ""
      ) {
        reference2_start_date_Obj = formatDate(
          applicants.data.reference2.start_date
        );
      } else {
        reference2_start_date_Obj = "";
      }

      if (
        applicants.data.reference2 &&
        applicants.data.reference2.end_date !== ""
      ) {
        reference2_end_date_Obj = formatDate(
          applicants.data.reference2.end_date
        );
      } else {
        reference2_end_date_Obj = "";
      }

      if (
        applicants.data.reference3 &&
        applicants.data.reference3.start_date !== ""
      ) {
        reference3_start_date_Obj = formatDate(
          applicants.data.reference3.start_date
        );
      } else {
        reference3_start_date_Obj = "";
      }

      if (
        applicants.data.reference3 &&
        applicants.data.reference3.end_date !== ""
      ) {
        reference3_end_date_Obj = formatDate(
          applicants.data.reference3.end_date
        );
      } else {
        reference3_end_date_Obj = "";
      }

      if (reference3_start_date_Obj !== "" && reference3_end_date_Obj !== "") {
        reference3_date =
          reference3_start_date_Obj + " - " + reference3_end_date_Obj;
      } else {
        reference3_date = "";
      }
      if (reference2_start_date_Obj !== "" && reference2_end_date_Obj !== "") {
        reference2_date =
          reference2_start_date_Obj + " - " + reference2_end_date_Obj;
      } else {
        reference2_date = "";
      }
      if (reference1_start_date_Obj !== "" && reference1_end_date_Obj !== "") {
        reference1_date =
          reference1_start_date_Obj + " - " + reference1_end_date_Obj;
      } else {
        reference1_date = "";
      }
      if (checkProperties(applicants.data.university1)) {
        university1.push(
          <p
            key="university1"
            id="avTechSchool1"
            className="av-answer av-answer-clear"
          >
            <span className="av-empty">Did not fill out</span>
          </p>
        );
      } else {
        university1.push(
          <div
            key="university1"
            id="avTechSchool1"
            className="av-answer av-answer-clear"
          >
            {applicants.data.university1.name !== "null" &&
              applicants.data.university1.name !== "undefined" && (
                <p className="av-empty">{applicants.data.university1.name}</p>
              )}
            {applicants.data.university1.duration !== "null" &&
              applicants.data.university1.duration !== "undefined" && (
                <p className="av-empty">
                  {applicants.data.university1.duration}
                </p>
              )}
            {applicants.data.university1.course !== "null" &&
              applicants.data.university1.course !== "undefined" && (
                <p className="av-empty">{applicants.data.university1.course}</p>
              )}
            {applicants.data.university1.gratuate_status !== "null" &&
              applicants.data.university1.gratuate_status !== "undefined" && (
                <p className="av-empty">
                  {applicants.data.university1.gratuate_status}
                </p>
              )}
          </div>
        );
      }
      if (checkProperties(applicants.data.university2)) {
        university2.push(
          <p
            key="university2"
            id="avTechSchool1"
            className="av-answer av-answer-clear"
          >
            <span className="av-empty">Did not fill out</span>
          </p>
        );
      } else {
        university2.push(
          <div
            key="university2"
            id="avTechSchool2"
            className="av-answer av-answer-clear"
          >
            {applicants.data.university2.name !== "null" &&
              applicants.data.university2.name !== "undefined" && (
                <p className="av-empty">{applicants.data.university2.name}</p>
              )}
            {applicants.data.university2.duration !== "null" &&
              applicants.data.university2.duration !== "undefined" && (
                <p className="av-empty">
                  {applicants.data.university2.duration}
                </p>
              )}
            {applicants.data.university2.course !== "null" &&
              applicants.data.university2.course !== "undefined" && (
                <p className="av-empty">{applicants.data.university2.course}</p>
              )}
            {applicants.data.university2.gratuate_status !== "null" &&
              applicants.data.university2.gratuate_status !== "undefined" && (
                <p className="av-empty">
                  {applicants.data.university2.gratuate_status}
                </p>
              )}
          </div>
        );
      }

      if (checkProperties(applicants.data.license)) {
        license.push(
          <p key="license" id="avLicense" className="av-answer av-answer-clear">
            <span className="av-empty">Did not fill out</span>
          </p>
        );
      } else {
        license.push(
          <div
            key="license"
            id="avLicense"
            className="av-answer av-answer-clear"
          >
            {applicants.data.license.state_id &&
              applicants.data.license.state_id !== "null" && (
                <p className="av-empty">{applicants.data.license.state_id}</p>
              )}
            {applicants.data.license.no &&
              applicants.data.license.no !== "null" && (
                <p className="av-empty">{applicants.data.license.no}</p>
              )}
            {applicants.data.license.exp_date &&
              applicants.data.license.exp_date !== "null" && (
                <p className="av-empty">
                  {formatDate(applicants.data.license.exp_date)}
                </p>
              )}
            {applicants.data.license.class &&
              applicants.data.license.class !== "null" && (
                <p className="av-empty">{applicants.data.license.class}</p>
              )}
            {applicants.data.license.endorsement &&
              applicants.data.license.endorsement !== "null" && (
                <p className="av-empty">
                  {applicants.data.license.endorsement}
                </p>
              )}
          </div>
        );
      }

      if (
        applicants.data.reference1 &&
        applicants.data.reference1.last_employee &&
        !this.checkNull(applicants.data.reference1.last_employee)
      ) {
        reference1_flag = true;
        reference1.push(
          <div
            key="reference"
            id="avReference1"
            className="av-answer av-answer-clear"
          >
            <span>{applicants.data.reference1.last_employee}</span>
            {applicants.data.reference1.address &&
              applicants.data.reference1.address !== "null" &&
              applicants.data.reference1.address !== null &&
              applicants.data.reference1.address !== "" && (
                <p className="view_inner_p">
                  {applicants.data.reference1.address}
                  <br />
                </p>
              )}
            {applicants.data.reference1.city_id &&
              applicants.data.reference1.city_id !== "null" &&
              applicants.data.reference1.city_id !== null &&
              applicants.data.reference1.city_id !== "" && (
                <p>{applicants.data.reference1.city_id}&nbsp;</p>
              )}
            {applicants.data.reference1.state_id &&
              applicants.data.reference1.state_id !== "null" &&
              applicants.data.reference1.state_id !== null &&
              applicants.data.reference1.state_id !== "" && (
                <p className="view_inner_p">
                  {applicants.data.reference1.state_id}
                </p>
              )}
            {applicants.data.reference1.position &&
              applicants.data.reference1.position !== "null" &&
              applicants.data.reference1.position !== null &&
              applicants.data.reference1.position !== "" && (
                <p>{applicants.data.reference1.position}</p>
              )}
            {reference1_date && (
              <p className="view_inner_p">{formatDate(reference1_date)}</p>
            )}
            {applicants.data.reference1.supervisor_name &&
              applicants.data.reference1.supervisor_name !== "null" &&
              applicants.data.reference1.supervisor_name !== null &&
              applicants.data.reference1.supervisor_name !== "" && (
                <p>{applicants.data.reference1.supervisor_name}</p>
              )}
            {applicants.data.reference1.telephone &&
              applicants.data.reference1.telephone !== "null" &&
              applicants.data.reference1.telephone !== null &&
              applicants.data.reference1.telephone !== "" && (
                <p>
                  {/* {formatPhoneNumber(
                    applicants.data.reference1.telephone + " (phone)"
                  )} */}
                  (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${applicants.data.reference1.telephone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(
                      applicants.data.reference1.telephone + " (phone)"
                    )}
                  </a>
                  )
                </p>
              )}
            {applicants.data.reference1.fax &&
              applicants.data.reference1.fax !== "null" &&
              applicants.data.reference1.fax !== null &&
              applicants.data.reference1.fax !== "" && (
                <p className="view_inner_p">
                  {applicants.data.reference1.fax + " (fax)"}
                </p>
              )}
            {applicants.data.reference1.responsibilities &&
              applicants.data.reference1.responsibilities !== "null" &&
              applicants.data.reference1.responsibilities !== null &&
              applicants.data.reference1.responsibilities !== "" && (
                <p className="view_inner_p">
                  <span>Responsabilities:</span>
                  <br />
                  <span>{applicants.data.reference1.responsibilities}</span>
                </p>
              )}
            {applicants.data.reference1.reason_for_leaving &&
              applicants.data.reference1.reason_for_leaving !== "null" &&
              applicants.data.reference1.reason_for_leaving !== null &&
              applicants.data.reference1.reason_for_leaving !== "" && (
                <p className="view_inner_p">
                  <span>Reason for Leaving:</span>
                  <br />
                  <span>{applicants.data.reference1.reason_for_leaving}</span>
                </p>
              )}
          </div>
        );
      } else {
        reference1_flag = false;
        reference1.push(
          <p
            key="reference"
            id="avReference1"
            className={`av-answer av-answer-clear`}
          >
            <span className={`av-empty ${!reference1_flag ? "disabled" : ""}`}>
              Did not fill out
            </span>
          </p>
        );
      }
      //
      if (
        applicants.data.reference2 &&
        applicants.data.reference2.last_employee &&
        !this.checkNull(applicants.data.reference2.last_employee)
      ) {
        reference2_flag = true;
        reference2.push(
          <div
            key="reference2"
            id="avReference2"
            className="av-answer av-answer-clear"
          >
            <p>{applicants.data.reference2.last_employee}</p>
            {applicants.data.reference2.address &&
              applicants.data.reference2.address !== "null" &&
              applicants.data.reference2.address !== null &&
              applicants.data.reference2.address !== "" && (
                <p className="view_inner_p">
                  {applicants.data.reference2.address}
                </p>
              )}
            {applicants.data.reference2.city_id &&
              applicants.data.reference2.city_id !== "null" &&
              applicants.data.reference2.city_id !== null &&
              applicants.data.reference2.city_id !== "" && (
                <p>{applicants.data.reference2.city_id}&nbsp;</p>
              )}
            {applicants.data.reference2.state_id &&
              applicants.data.reference2.state_id !== "null" &&
              applicants.data.reference2.state_id !== null &&
              applicants.data.reference2.state_id !== "" && (
                <p className="view_inner_p">
                  {applicants.data.reference2.state_id}
                </p>
              )}
            {applicants.data.reference2.position &&
              applicants.data.reference2.position !== "null" &&
              applicants.data.reference2.position !== null &&
              applicants.data.reference2.position !== "" && (
                <p>{applicants.data.reference2.position}</p>
              )}
            {reference2_date && (
              <p className="view_inner_p">{reference2_date}</p>
            )}
            {applicants.data.reference2.supervisor_name &&
              applicants.data.reference2.supervisor_name !== "null" &&
              applicants.data.reference2.supervisor_name !== null &&
              applicants.data.reference2.supervisor_name !== "" && (
                <p>{applicants.data.reference2.supervisor_name}</p>
              )}
            {applicants.data.reference2.telephone &&
              applicants.data.reference2.telephone !== "null" &&
              applicants.data.reference2.telephone !== null &&
              applicants.data.reference2.telephone !== "" && (
                <p>
                  (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${applicants.data.reference2.telephone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(
                      applicants.data.reference2.telephone + " (phone)"
                    )}
                  </a>
                  )
                  {/* {formatPhoneNumber(applicants.data.reference2.telephone) +
                    " (phone)"} */}
                </p>
              )}
            {applicants.data.reference2.fax &&
              applicants.data.reference2.fax !== "null" &&
              applicants.data.reference2.fax !== null &&
              applicants.data.reference2.fax !== "" && (
                <p className="view_inner_p">
                  {applicants.data.reference2.fax + " (fax)"}
                </p>
              )}
            {applicants.data.reference2.responsibilities &&
              applicants.data.reference2.responsibilities !== "null" &&
              applicants.data.reference2.responsibilities !== null &&
              applicants.data.reference2.responsibilities !== "" && (
                <p className="view_inner_p">
                  <span>Responsibilities:</span>
                  <br />
                  <span>{applicants.data.reference2.responsibilities}</span>
                </p>
              )}
            {applicants.data.reference2.reason_for_leaving &&
              applicants.data.reference2.reason_for_leaving !== "null" &&
              applicants.data.reference2.reason_for_leaving !== null &&
              applicants.data.reference2.reason_for_leaving !== "" && (
                <p className="view_inner_p">
                  <span>Reason for Leaving:</span>
                  <br />
                  <span>{applicants.data.reference2.reason_for_leaving}</span>
                </p>
              )}
          </div>
        );
      } else {
        reference2_flag = false;
        reference2.push(
          <p
            key="reference2"
            id="avReference2"
            className="av-answer av-answer-clear"
          >
            <span className={`av-empty ${!reference2_flag ? "disabled" : ""}`}>
              Did not fill out
            </span>
          </p>
        );
      }
      //

      if (
        applicants.data.reference3 &&
        applicants.data.reference3.last_employee &&
        !this.checkNull(applicants.data.reference3.last_employee)
      ) {
        reference3_flag = true;
        reference3.push(
          <div
            key="reference3"
            id="avReference3"
            className="av-answer av-answer-clear"
          >
            <p>{applicants.data.reference3.last_employee}</p>
            {applicants.data.reference3.address &&
              applicants.data.reference3.address !== "null" &&
              applicants.data.reference3.address !== null &&
              applicants.data.reference3.address !== "" && (
                <p className="view_inner_p">
                  {applicants.data.reference3.address}
                </p>
              )}
            {applicants.data.reference3.city_id &&
              applicants.data.reference3.city_id !== "null" &&
              applicants.data.reference3.city_id !== null &&
              applicants.data.reference3.city_id !== "" && (
                <p>{applicants.data.reference3.city_id}&nbsp;</p>
              )}
            {applicants.data.reference3.state_id &&
              applicants.data.reference3.state_id !== "null" &&
              applicants.data.reference3.state_id !== null &&
              applicants.data.reference3.state_id !== "" && (
                <p className="view_inner_p">
                  {applicants.data.reference3.state_id}
                </p>
              )}
            {applicants.data.reference3.position &&
              applicants.data.reference3.position !== "null" &&
              applicants.data.reference3.position !== null &&
              applicants.data.reference3.position !== "" && (
                <span>{applicants.data.reference3.position}</span>
              )}
            {reference3_date && (
              <p className="view_inner_p">{reference3_date}</p>
            )}
            {applicants.data.reference3.supervisor_name &&
              applicants.data.reference3.supervisor_name !== "null" &&
              applicants.data.reference3.supervisor_name !== null &&
              applicants.data.reference3.supervisor_name !== "" && (
                <p>{applicants.data.reference3.supervisor_name}</p>
              )}
            {applicants.data.reference3.telephone &&
              applicants.data.reference3.telephone !== "null" &&
              applicants.data.reference3.telephone !== null &&
              applicants.data.reference3.telephone !== "" && (
                <p>
                  (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${applicants.data.reference3.telephone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(
                      applicants.data.reference3.telephone + " (phone)"
                    )}
                  </a>
                  )
                  {/* {formatPhoneNumber(
                    applicants.data.reference3.telephone + " (phone)"
                  )} */}
                </p>
              )}
            {applicants.data.reference3.fax &&
              applicants.data.reference3.fax !== "null" &&
              applicants.data.reference3.fax !== null &&
              applicants.data.reference3.fax !== "" && (
                <p className="view_inner_p">
                  {applicants.data.reference3.fax + " (fax)"}
                </p>
              )}
            {applicants.data.reference3.responsibilities &&
              applicants.data.reference3.responsibilities !== "null" &&
              applicants.data.reference3.responsibilities !== null &&
              applicants.data.reference3.responsibilities !== "" && (
                <p className="view_inner_p">
                  <span>Responsabilities:</span>
                  <br />
                  <span>{applicants.data.reference3.responsibilities}</span>
                </p>
              )}
            {applicants.data.reference3.reason_for_leaving &&
              applicants.data.reference3.reason_for_leaving !== "null" &&
              applicants.data.reference3.reason_for_leaving !== null &&
              applicants.data.reference3.reason_for_leaving !== "" && (
                <p className="view_inner_p">
                  <span>Reason for Leaving:</span>
                  <br />
                  <span>{applicants.data.reference3.reason_for_leaving}</span>
                </p>
              )}
          </div>
        );
      } else {
        reference3_flag = false;
        reference3.push(
          <p
            key="reference3"
            id="avReference3"
            className="av-answer av-answer-clear"
          >
            <span className={`av-empty ${!reference3_flag ? "disabled" : ""}`}>
              Did not fill out
            </span>
          </p>
        );
      }

      if (checkProperties(applicants.data.personalreference1)) {
        professional1_flag = false;
        professional1.push(
          <p
            key="personal"
            id="avProfessionalReference1"
            className="av-answer av-answer-clear"
          >
            <span
              className={`av-empty ${!professional1_flag ? "disabled" : ""}`}
            >
              Did not fill out
            </span>
          </p>
        );
      } else {
        professional1_flag = true;
        professional1.push(
          <div
            key="personal"
            id="avProfessionalReference1"
            className="av-answer av-answer-clear"
          >
            {/* {applicants.data.personalreference1.name &&
              applicants.data.personalreference1.name !== "null" && (
              null
              )} */}
            {applicants.data.personalreference1.name &&
              applicants.data.personalreference1.name !== "null" && (
                <p className="av-empty">
                  {applicants.data.personalreference1.name}
                </p>
              )}
            {applicants.data.personalreference1.relationship &&
              applicants.data.personalreference1.relationship !== "null" && (
                <p className="av-empty">
                  {applicants.data.personalreference1.relationship}
                </p>
              )}
            {applicants.data.personalreference1.phone &&
              applicants.data.personalreference1.phone !== "null" && (
                <p className="av-empty">
                  (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${applicants.data.personalreference1.phone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(
                      applicants.data.personalreference1.phone
                    )}
                  </a>
                  )
                  {/* {formatPhoneNumber(applicants.data.personalreference1.phone)} */}
                </p>
              )}
            {applicants.data.personalreference1.known &&
              applicants.data.personalreference1.known !== "null" && (
                <p className="av-empty">
                  {applicants.data.personalreference1.known}
                </p>
              )}
          </div>
        );
      }

      if (checkProperties(applicants.data.personalreference2)) {
        professional2_flag = false;
        professional2.push(
          <p
            key="personal1"
            id="avProfessionalReference2"
            className="av-answer av-answer-clear"
          >
            <span
              className={`av-empty ${!professional2_flag ? "disabled" : ""}`}
            >
              Did not fill out
            </span>
          </p>
        );
      } else {
        professional2_flag = true;
        professional2.push(
          <div
            key="personal1"
            id="avProfessionalReference2"
            className="av-answer av-answer-clear"
          >
            {applicants.data.personalreference2.name &&
              applicants.data.personalreference2.name !== "null" && (
                <p className="av-empty">
                  {applicants.data.personalreference2.name}
                </p>
              )}
            {applicants.data.personalreference2.relationship &&
              applicants.data.personalreference2.relationship !== "null" && (
                <p className="av-empty">
                  {applicants.data.personalreference2.relationship}
                </p>
              )}
            {applicants.data.personalreference2.phone &&
              applicants.data.personalreference2.phone !== "null" &&
              formatPhoneNumber(applicants.data.personalreference2.phone) && (
                <p className="av-empty">
                  (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${applicants.data.personalreference2.phone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(
                      applicants.data.personalreference2.phone
                    )}
                  </a>
                  )
                  {/* {formatPhoneNumber(applicants.data.personalreference2.phone)} */}
                </p>
              )}
            {applicants.data.personalreference2.known &&
              applicants.data.personalreference2.known !== "null" && (
                <p className="av-empty">
                  {applicants.data.personalreference2.known}
                </p>
              )}
          </div>
        );
      }

      if (checkProperties(applicants.data.personalreference3)) {
        professional3_flag = false;
        professional3.push(
          <p
            key="personal2"
            id="avProfessionalReference3"
            className="av-answer av-answer-clear"
          >
            <span
              className={`av-empty ${!professional3_flag ? "disabled" : ""}`}
            >
              Did not fill out
            </span>
          </p>
        );
      } else {
        professional3_flag = true;
        professional3.push(
          <div
            key="personal2"
            id="avProfessionalReference3"
            className="av-answer av-answer-clear"
          >
            {applicants.data.personalreference3.name &&
              applicants.data.personalreference3.name !== "null" && (
                <p className="av-empty">
                  {applicants.data.personalreference3.name}
                </p>
              )}
            {applicants.data.personalreference3.relationship &&
              applicants.data.personalreference3.relationship !== "null" && (
                <p className="av-empty">
                  {applicants.data.personalreference3.relationship}
                </p>
              )}
            {applicants.data.personalreference3.phone &&
              applicants.data.personalreference3.phone !== "null" && (
                <p className="av-empty">
                  (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${applicants.data.personalreference3.phone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(
                      applicants.data.personalreference3.phone
                    )}
                  </a>
                  )
                  {/* {formatPhoneNumber(applicants.data.personalreference3.phone)} */}
                </p>
              )}
            {applicants.data.personalreference3.known &&
              applicants.data.personalreference3.known !== "null" && (
                <p className="av-empty">
                  {applicants.data.personalreference3.known}
                </p>
              )}
          </div>
        );
      }
      if (
        applicants.data.applying_position_id !== null &&
        applicants.data.applying_position_id !== "null" &&
        applicants.data.applying_position_id !== ""
      ) {
        applicants.data.applying_position.map((item, index) => {
          applying_position.push(<p key={index}>{item}</p>);
        });
      } else {
        applying_position.push(
          <span key="empty" className="av-empty">
            Did not fill out
          </span>
        );
      }

      if (
        applicants.data.qualification_id !== null &&
        applicants.data.qualification_id !== "null" &&
        applicants.data.qualification_id !== ""
      ) {
        applicants.data.qualification.map((item, index) => {
          qualifications.push(<p key={index}>{item}</p>);
        });
      } else {
        qualifications.push(
          <span key="empty" className="av-empty">
            Did not fill out
          </span>
        );
      }
    }
    //

    return (
      <div className="applicants_container application_details">
        {/* <div className="breadcrumbs">
          <span onClick={this.goBack.bind(this)} className="green">
            Applicants
          </span>{" "}
          / View Applicant
        </div> Moved to Tab screen*/}
        <div className="applicant_hdr_btn_grp">
          <h3 className="left">Job Application</h3>
          {applicants?.data?.former_employee_approval ? (
            <h4 style={{ color: "red" }}>FORMER EMPLOYEE</h4>
          ) : (
            <></>
          )}

          <div className="top_btn_group new_btn_grp flex-group">
            {/* {permList.includes("Manage Passwords")  && (Edit
              <ChangePassword userType="applicant" user={applicants?.data} isAdmin={true}/>
            )} Moved to Tab screen*/}
            {/* {permList.includes("Email Applicants") && (
              <button className="button" onClick={this.sendMail.bind(this)}>
                Email
              </button>
            )} Moved to Tab screen*/}

            {[
              contactEmailConstants.HR,
              contactEmailConstants.TEST_ADMIN,
            ].includes(loggedInUser?.email) &&
            applicants?.data?.former_employee_approval == 1 ? (
              <button
                className="button"
                onClick={this.formerEmpOpenModal.bind(this)}
              >
                Approve/Reject
              </button>
            ) : (
              <></>
            )}
            {permList.includes("Manage Applicants") &&
              this.props.isApplicationEditable && (
                <button
                  className="button"
                  onClick={this.editApplicant.bind(this, this.props.hash)}
                >
                  Edit
                </button>
              )}
            {/* {permList.includes("Manage Applicants") && (
              <button
                className="button"
                onClick={this.removeStatusOpenModal.bind(
                  this,
                  applicants ? applicants.data.applicant_status : ""
                )}
              >
                Update Status
              </button>
            )} Moved to Tab screen*/}

            {permList.includes("Manage Applicants") && (
              <button
                className="button"
                onClick={this.printManifest.bind(this)}
              >
                Print
              </button>
            )}

            {permList.includes("Manage Applicants") && (
              <button
                className="button"
                onClick={this.statusOpenModal.bind(this)}
              >
                Forward
              </button>
            )}

            {permList.includes("Modify SMS Notification Preference") && applicants ? (
              <SmsNotification initialStatus={applicants.data.sms_notify} providerOptedOutStatus={applicants.data.sms_provider_opt_out} type={profileType.APPLICATION} id={applicants.data.id} customClass={'edit_profile xl-font'} />
            ) : <></>}
          </div>
        </div>
        {this.state.appication_history.length > 0 && (
          <div className="applicants_inner_head inner_head">
            <div
              className="previous_history"
              onClick={this.viewHistory.bind(this)}
            >
              <img
                src={
                  require("../assets/images/history-clock-button.svg").default
                }
                alt=""
              />
              <span>View Previous History</span>
            </div>
          </div>
        )}
        <div
          className="inner_container tab_inner_container col-lg-12"
          id="applicants_inner"
        >
          <div className="applicantNavigation">{prevNext}</div>
          <div className="clearfix" />
          <div className="applicant-view-container">
            <h3 className="left">
              Full Application:{" "}
              {applicants
                ? applicants.data.first_name +
                  " " +
                  applicants.data.middle_initial +
                  " " +
                  applicants.data.last_name
                : ""}
            </h3>
            <h3 className="right">
              Date Applied: {applicants ? applicants.data.date : ""}
            </h3>

            {loggedInUser &&
            applicants?.data?.former_employee_approval != 0 &&
            [
              contactEmailConstants.HR,
              contactEmailConstants.JIMMY_DO,
              contactEmailConstants.TEST_ADMIN,
            ].includes(loggedInUser?.email) ? (
              <>
                <h4>REASON FOR LEAVING/RETURNING</h4>
                <textarea
                  onClick={() => {
                    this.setState({
                      reasonEditMode: [
                        contactEmailConstants.HR,
                        contactEmailConstants.TEST_ADMIN,
                      ].includes(loggedInUser?.email),
                    });
                  }}
                  type="text"
                  className="add-edit-note"
                  rows="4"
                  onChange={this.onChangeEditReason}
                  value={this.state.admin_reason_for_leaving}
                  placeholder="Reason for leaving / returning"
                  autoComplete="off"
                  maxLength={1200}
                  disabled={[contactEmailConstants.JIMMY_DO].includes(
                    loggedInUser?.email
                  )}
                />

                {this.state.reasonEditMode && (
                  <div className="buttons">
                    <Button
                      name="Save Reason"
                      fontColor="#fff"
                      backgroundColor="#297a18"
                      loading={false}
                      onClick={this.saveReason}
                      disabled={!this.state.admin_reason_for_leaving}
                    />
                    &nbsp;
                    <Button
                      name="Cancel"
                      fontColor="#fff"
                      backgroundColor="#747474"
                      onClick={this.onCancelAddEditReason}
                    />
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
            <br />
            <div className="applicant-status-details">
              <ApplicantStatusCard
                title="Hiring Status"
                content={
                  applicantStatusList?.find(
                    (status) => status.value === applicants?.data?.status
                  )?.label
                }
              />
              {status === statusApplication.WAS_HIRED && (
                <ApplicantStatusCard
                  title="Crew"
                  content={applicants?.data?.crew?.resource_name || ""}
                />
              )}
              {(status === statusApplication.WAS_HIRED ||
                status === statusApplication.NOT_SELECTED_ARCHIVE ||
                status === statusApplication.DO_NOT_HIRE_ARCHIVE) && (
                <ApplicantStatusCard
                  title={title}
                  content={
                    applicants?.data?.performed_user?.full_name_last_first
                  }
                />
              )}
              {(status === statusApplication.NOT_SELECTED_ARCHIVE ||
                status === statusApplication.DO_NOT_HIRE_ARCHIVE) && (
                <ApplicantStatusCard
                  title="Notes on why the application was rejected"
                  content={applicants?.data?.rejected_reason}
                />
              )}
              {(status === statusApplication.WAS_HIRED ||
                status === statusApplication.NOT_SELECTED_ARCHIVE ||
                status === statusApplication.DO_NOT_HIRE_ARCHIVE) && (
                <ApplicantStatusCard
                  title="Date and time"
                  content={formatDateTime(applicants?.data?.status_change_date)}
                />
              )}
            </div>
            {applicants?.data?.interview_form_count > 0 && (
              <div>
                <h4>
                  <b>
                    Interview Form Submitted Date:{" "}
                    {moment.isMoment(this?.state?.interviewFormSubmissionDate)
                      ? formatDate(this?.state?.interviewFormSubmissionDate)
                      : this?.state?.interviewFormSubmissionDate}
                  </b>
                </h4>
              </div>
            )}

            {permList.includes("Applicant Statistics") && (
              <div className="applicant-notes-wrapper">
                <h3>Notes</h3>
                <div className="note-editor">
                  {(permList.includes("Update Applicant Note") || isSupAdmin) &&
                    !this.state.noteEditMode && (
                      <AddEditNote
                        note={this.state.editableNote?.note}
                        value={this.state.editableNote?.note}
                        onChange={this.onChangeEditNote}
                        onCancel={this.onCancelAddEditNote}
                        disabled={!this.state.editableNote?.note}
                        textBoxDisabled={!this.props.isApplicationEditable}
                        onSaveNote={this.updateNotes}
                        loading={this.state.isNoteLoading}
                      />
                    )}
                  <div className="applicant-notes">
                    {!!notes?.length &&
                      notes?.map((note) =>
                        note.id === this.state.editableNote?.id ? (
                          <AddEditNote
                            disabled={!this.state.editableNote?.note}
                            key={note.id}
                            note={note}
                            value={this.state.editableNote?.note}
                            onChange={this.onChangeEditNote}
                            onCancel={this.onCancelAddEditNote}
                            onSaveNote={this.updateNotes}
                            loading={this.state.isNoteLoading}
                          />
                        ) : (
                          <ApplicantNoteCard
                            key={note.id}
                            note={note}
                            loading={this.state.isNoteLoading}
                            onEditNoteClick={this.onEditNoteClick}
                            onDeleteNoteClick={this.deleteApplicantNote}
                            canEditOrDelete={
                              note.author.id === loggedInUser?.id || isSupAdmin
                            }
                          />
                        )
                      )}
                  </div>
                </div>
              </div>
            )}

            <div className="left col-50 applicant-view-left">
              <h4 className="av-title">Basic Information</h4>

              <p className="av-question">How did you hear about Firestorm?</p>
              <p id="avSourceOfKnowledge" className="av-answer av-answer-clear">
                {applicants ? applicants.data.source_of_knowledge : ""}
              </p>
              <p id="avEmpName" className="av-answer">
                {applicants &&
                applicants.data.firestorm_employee !== "" &&
                applicants.data.firestorm_employee !== null &&
                applicants.data.firestorm_employee !== "null" &&
                applicants.data.firestorm_employee !== ""
                  ? applicants.data.firestorm_employee
                  : ""}
              </p>
              <br />
              <div>
                <div className="left col-50">
                  <p id="avName" className="av-answer">
                    {applicants
                      ? applicants.data.first_name +
                        " " +
                        applicants.data.middle_initial +
                        " " +
                        applicants.data.last_name
                      : ""}
                  </p>
                  <p id="avAddress1" className="av-answer">
                    {applicants &&
                    applicants.data.mailing_address1 !== null &&
                    applicants.data.mailing_address1 !== "null" &&
                    applicants.data.mailing_address1 !== ""
                      ? applicants.data.mailing_address1
                      : ""}
                  </p>
                  <p id="avAddress2" className="av-answer">
                    {applicants &&
                    applicants.data.mailing_address2 !== null &&
                    applicants.data.mailing_address2 !== "null" &&
                    applicants.data.mailing_address2 !== ""
                      ? applicants.data.mailing_address2
                      : ""}
                  </p>
                  <p id="avAddress2" className="av-answer">
                    {applicants &&
                    applicants.data &&
                    applicants.data.city_id !== null &&
                    applicants.data.city_id !== "null" &&
                    applicants.data.city_id !== ""
                      ? `${applicants.data.city_id}, `
                      : ""}
                    {applicants &&
                    applicants.data &&
                    applicants.data.state_id !== null &&
                    applicants.data.state_id !== "null" &&
                    applicants.data.state_id !== ""
                      ? applicants.data.state_id
                      : ""}
                  </p>
                  <p id="avCityStateZip" className="av-answer">
                    {applicants &&
                    applicants.data.zip_id !== null &&
                    applicants.data.zip_id !== "null" &&
                    applicants.data.zip_id !== ""
                      ? applicants.data.zip_id
                      : ""}
                  </p>
                </div>

                {checkCurrentUserIsSuperAdmin(
                  this.props?.user?.profile?.data?.is_admin
                ) && (
                  <div className="right col-50">
                    {applicants &&
                      applicants.data.gender !== null &&
                      applicants.data.gender !== "null" &&
                      applicants.data.gender !== "" && (
                        <p id="avGender" className="av-answer">
                          Gender:{" "}
                          <span className="av-empty">
                            {applicants.data.gender}
                          </span>
                        </p>
                      )}
                    {applicants &&
                      applicants.data.race !== null &&
                      applicants.data.race !== "null" &&
                      applicants.data.race !== "" && (
                        <p
                          id="avRace"
                          className="av-answer"
                          ng-if="user.type  == 1"
                        >
                          Race:{" "}
                          <span className="av-empty">
                            {applicants.data.race}
                          </span>
                        </p>
                      )}
                    {applicants &&
                      applicants.data.veteran !== null &&
                      applicants.data.veteran !== "null" &&
                      applicants.data.veteran !== "" && (
                        <p
                          id="avVeteran"
                          className="av-answer"
                          ng-if="user.type  == 1"
                        >
                          Veteran:{" "}
                          <span className="av-empty">
                            {applicants.data.veteran}
                          </span>
                        </p>
                      )}
                  </div>
                )}

                <div className="clearfix" />
                <br />
                <p id="avEmail" className="av-answer">
                  {applicants &&
                  applicants.data.email_address !== "null" &&
                  applicants.data.email_address !== null &&
                  applicants.data.email_address !== "" ? (
                    <a
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      className="phone_email_link"
                      href={`mailto:${applicants.data.email_address}`}
                      target="_top"
                    >
                      {applicants.data.email_address}
                    </a>
                  ) : (
                    ""
                  )}
                </p>
                <p id="avPhone" className="av-answer">
                  {applicants &&
                  applicants.data.phone_number !== "null" &&
                  applicants.data.phone_number !== null &&
                  applicants.data.phone_number !== "" ? (
                    <a
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      className="phone_email_link"
                      href={`tel:${applicants.data.phone_number}`}
                      target="_top"
                    >
                      {formatPhoneNumber(
                        applicants.data.phone_number + "(Main)"
                      )}
                    </a>
                  ) : (
                    //   formatPhoneNumber(applicants.data.phone_number)+' (Main)'
                    ""
                  )}
                </p>
                <p id="avSecPhone" className="av-answer">
                  {applicants &&
                  applicants.data.secondary_phone !== "" &&
                  applicants.data.secondary_phone !== null &&
                  applicants.data.secondary_phone !== "null" ? (
                    <a
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      className="phone_email_link"
                      href={`tel:${applicants.data.secondary_phone}`}
                      target="_top"
                    >
                      {formatPhoneNumber(
                        applicants.data.secondary_phone + "(Other)"
                      )}
                    </a>
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <br />

              <p className="av-question">
                Are physically fit to perform duties required to be a wildland
                firefighter?
              </p>
              <p id="avQuestion2" className="av-answer av-answer-clear">
                {applicants && applicants.data.question2 ? (
                  applicants.data.question2
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br />

              <p className="av-question">
                Will you comply to our company drug and smoking policies?
              </p>
              <p id="avQuestion3" className="av-answer av-answer-clear">
                {applicants && applicants.data.question3 ? (
                  applicants.data.question3
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br />

              <h4 className="av-title">Education and History</h4>

              <p className="av-question">High School:</p>
              {applicants && applicants.data.highschool_name ? (
                <p id="avHighSchool" className="av-answer av-answer-clear">
                  <span className="av-empty">
                    {applicants &&
                    applicants.data.highschool_name !== "null" &&
                    applicants.data.highschool_name !== null &&
                    applicants.data.highschool_name !== ""
                      ? applicants.data.highschool_name
                      : ""}
                  </span>
                  {applicants &&
                  applicants.data.highschool_duration !== "null" &&
                  applicants.data.highschool_duration !== null &&
                  applicants.data.highschool_duration !== "" ? (
                    <span className="av-empty">
                      {applicants.data.highschool_duration}
                      <br />
                    </span>
                  ) : (
                    <span className="av-empty">
                      Did not fill out
                      <br />
                    </span>
                  )}

                  <span className="av-empty">
                    {applicants &&
                    applicants.data.highschool_course !== "null" &&
                    applicants.data.highschool_course !== null &&
                    applicants.data.highschool_course !== ""
                      ? applicants.data.highschool_course
                      : ""}
                  </span>
                  <span className="av-empty">
                    {applicants &&
                    applicants.data.highschool_gratuate_status !== "null" &&
                    applicants.data.highschool_gratuate_status !== null &&
                    applicants.data.highschool_gratuate_status !== ""
                      ? applicants.data.highschool_gratuate_status
                      : ""}
                  </span>
                  <br />
                </p>
              ) : (
                <p id="avHighSchool" className="av-answer av-answer-clear">
                  <span className="av-empty">
                    Did not fill out
                    <br />
                  </span>
                </p>
              )}

              <p className="av-question">University / Tech School:</p>
              {university1}
              <br />

              <p className="av-question">University / Tech School:</p>
              {university2}
              <br />

              <p className="av-question">Additional Information:</p>
              <p id="avAdditionalInfo" className="av-answer av-answer-clear">
                {applicants &&
                applicants.data.applicant_info !== null &&
                applicants.data.applicant_info !== "null" &&
                applicants.data.applicant_info !== "" ? (
                  applicants.data.applicant_info
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br />

              <p className="av-question">
                Have you worked for Firestorm in the past?
              </p>
              <p id="avFirestormPastWork" className="av-answer av-answer-clear">
                {applicants &&
                applicants.data.worked_for_firestorm !== null &&
                applicants.data.worked_for_firestorm !== "null" &&
                applicants.data.worked_for_firestorm !== "" ? (
                  applicants.data.worked_for_firestorm
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              {applicants &&
              applicants.data.reason_leave_firestorm !== null &&
              applicants.data.reason_leave_firestorm !== "null" &&
              applicants.data.reason_leave_firestorm !== "" ? (
                <p className="av-answer av-answer-clear">
                  {applicants.data.reason_leave_firestorm}
                  <br />
                </p>
              ) : (
                ""
              )}

              {!reference1_flag && !reference2_flag && !reference3_flag && (
                <h4 className="av-title">No past work experiences</h4>
              )}
              <p
                className={`av-question ${!reference1_flag ? "disabled" : ""}`}
              >
                Past Experiences / Employment Record / References - #1:
              </p>
              {reference1}
              <br />

              <p
                className={`av-question ${!reference2_flag ? "disabled" : ""}`}
              >
                Past Experiences / Employment Record / References - #2
              </p>
              {reference2}
              <br />

              <p
                className={`av-question ${!reference3_flag ? "disabled" : ""}`}
              >
                Past Experiences / Employment Record / References - #3
              </p>
              {reference3}
              <br />

              {!professional1_flag &&
                !professional2_flag &&
                !professional3_flag && (
                  <h4 className="av-title">No past professional reference</h4>
                )}
              <p
                className={`av-question ${
                  !professional1_flag ? "disabled" : ""
                }`}
              >
                Professional Reference - #1:
              </p>
              {professional1}
              <br />

              <p
                className={`av-question ${
                  !professional2_flag ? "disabled" : ""
                }`}
              >
                Professional Reference - #2:
              </p>
              {professional2}
              <br />

              <p
                className={`av-question ${
                  !professional3_flag ? "disabled" : ""
                }`}
              >
                Professional Reference - #3:
              </p>
              {professional3}
              <br />
            </div>

            <div className="right col-50 applicant-view-right">
              <h4 className="av-title">Availability and Experience</h4>

              <p className="av-question">Current Certification Status:</p>
              <p id="avCertStatus" className="av-answer av-answer-clear">
                {applicants && applicants.data.certification_status ? (
                  applicants.data.certification_status
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br />

              {applicants && applicants.data.general_qualification && (
                <>
                  <p className="av-question">Qualification:</p>
                  <p
                    id="avGenQualification"
                    className="av-answer av-answer-clear"
                  >
                    {applicants.data.general_qualification}
                  </p>
                  <br />
                </>
              )}

              <p className="av-question">
                Anticipated Living Location for Fire Season:
              </p>
              <p id="avAnticipatedLoc" className="av-answer av-answer-clear">
                {applicants && applicants.data.anticipated_location ? (
                  applicants.data.anticipated_location
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br />

              <p className="av-question">Anticipated relocation date:</p>
              <p
                id="avAnticipatedLocDate"
                className="av-answer av-answer-clear"
              >
                {applicants && applicants.data.anticipated_relocation ? (
                  formatDate(applicants.data.anticipated_relocation)
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br />

              <p className="av-question">Desired Reporting Location:</p>
              <p id="avReportingLoc" className="av-answer av-answer-clear">
                {applicants && applicants.data.reportinglocation ? (
                  applicants.data.reportinglocation
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br />

              <p className="av-question">Position Applying for:</p>
              <div
                id="avApplyingPosition"
                className="av-answer av-answer-clear"
              >
                {applying_position}
              </div>
              <br />

              <p className="av-question">Position to be Considered:</p>
              <p id="avPositionOn" className="av-answer av-answer-clear">
                {applicants &&
                applicants.data.position_on_id !== null &&
                applicants.data.position_on_id !== "null" &&
                applicants.data.position_on_id !== "" ? (
                  applicants.data.position_on
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br />

              <p className="av-question">Current Fire Qualifications:</p>
              <div id="avQualification" className="av-answer av-answer-clear">
                {qualifications}
              </div>
              <br />

              <p className="av-question">
                Copies of Qualification / Certificates:
              </p>
              <div id="fileQualificatns" className="av-answer av-answer-clear">
                {applicants &&
                applicants.success &&
                applicants.data.filequalification.length > 0 ? (
                  <div>
                    <p>
                      {applicants.data.filequalification.length +
                        " attached files"}{" "}
                    </p>

                    <button
                      className="button"
                      type="button"
                      onClick={this.fileDownload.bind(
                        this,
                        applicants.data.filequalification
                      )}
                    >
                      Download attachments
                    </button>
                  </div>
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </div>
              <br />

              <h4 className="av-title">Driving History</h4>

              <p className="av-question">Drivers License</p>
              {license}
              <br />

              <p className="av-question">
                Have you ever been denied a license, permit or privilege to
                operate a motor vehicle?
              </p>
              <p id="avDeniedLicense" className="av-answer av-answer-clear">
                {applicants &&
                applicants.data.denied_license !== "null" &&
                applicants.data.denied_license !== null &&
                applicants.data.denied_license !== "" ? (
                  applicants.data.denied_license
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
              </p>
              <br />

              <p className="av-question">
                Has any license, permit or privilege ever been revoked?
              </p>
              <div id="avRevokedLicense" className="av-answer av-answer-clear">
                {applicants &&
                applicants.data.license_revoked !== "null" &&
                applicants.data.license_revoked !== null &&
                applicants &&
                applicants.data.license_revoked !== "" ? (
                  applicants.data.license_revoked
                ) : (
                  <span className="av-empty">Did not fill out</span>
                )}
                <br />
                {applicants &&
                applicants.data.reason_license_revoked !== "null" &&
                applicants.data.reason_license_revoked !== null &&
                applicants.data.reason_license_revoked !== "" ? (
                  <p>
                    <br />
                    <span>Explain:</span> <br />
                    <span>{applicants.data.reason_license_revoked}</span>
                  </p>
                ) : (
                  ""
                )}
              </div>
              <br />
            </div>
          </div>
          <div className="clearfix" />
        </div>

        <ReactModal
          isOpen={this.state.statusOpen}
          onClose={this.statusCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="ForwardPopup"
        >
          <div className="modal-head">
            <h4 className="modal-title">Forward Application</h4>
            <img
              className="closeModal"
              onClick={this.statusCloseModal.bind(this)}
              src={require("../assets/images/close_green.png").default}
              alt=""
            />
          </div>
          <div className="modal-body" id="allAdminPopup">
            <div className="status_step step1">
              <div className="employee-list-input">
                <label>Search User:</label>
                <br />
                <input
                  name="to"
                  className="txtSearchUsersForForward"
                  id="txtSearchUsersForForward"
                  onKeyUp={this.keyUpFn}
                  autoComplete="off"
                />

                <ul
                  id="searchResultTop"
                  className={`${this.state.hideSearch} employee-list`}
                >
                  {this.state.user_list}
                </ul>
              </div>
              <label>Note:</label>
              <br />
              <textarea
                name="to"
                className="forward-applicant-note"
                id="forward-applicant-note"
                onChange={this.onForwardingNote}
                autoComplete="off"
              />
              <div className="btn_list">
                <button
                  className="button grey"
                  onClick={this.statusCloseModal.bind(this)}
                >
                  Cancel
                </button>
                <button
                  className="button"
                  onClick={this.forwardApplicant.bind(this)}
                  disabled={!this.state.to}
                >
                  Email
                </button>
              </div>
            </div>
          </div>
        </ReactModal>

        <Modal
          isOpen={this.state.formerEmpModal}
          className="update-applicant-status-modal"
        >
          <div className="modal-head">
            <h4>Approve / Reject Application</h4>
          </div>

          <div className="modal-body applicant-status-modal-body">
            <p>
              Note: Approving the application will move this application to
              Applicants List and rejecting will move this to Archived
              Applicants List.
            </p>
            <div className="status-select input-holder 1">
              <span>Status</span>
              <select
                value={this.state.formerEmpApproval}
                onChange={this.handleSelect.bind(this)}
              >
                <option value="">--</option>
                <option value="2">Approve</option>
                <option value="3">Reject</option>
              </select>
            </div>

            <>
              <div className="button-holder button-holder--right">
                <button
                  disabled={false}
                  className="button grey"
                  onClick={this.formerEmpCloseModal.bind(this)}
                >
                  Cancel
                </button>
                <button
                  disabled={
                    !this.state.formerEmpApproval || this.state.statusUpdating
                  }
                  className="button"
                  onClick={this.changeFormerEmpApprovalStatus.bind(this)}
                >
                  Update
                </button>
              </div>
            </>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.removeStatusOpen}
          className="update-applicant-status-modal"
        >
          <div className="modal-head">
            <h4>UPDATE APPLICANT STATUS</h4>
          </div>

          <div className="modal-body applicant-status-modal-body">
            {this?.state?.applicant_list?.data?.interview_form_count === 0 && (
              <NoInterviewFormAlert />
            )}

            {this.state?.applicantStatusList && (
              <div className="status-select input-holder 1">
                <span>Status</span>
                <Select
                  options={this.state.applicantStatusList}
                  defaultValue={this.findCurrentStatus}
                  onChange={(selection) =>
                    this.onChangeSelect(selection, "applicantStatus")
                  }
                  classNamePrefix="select"
                />
              </div>
            )}

            {this.state.applicantStatus === statusApplication.WAS_HIRED && (
              <>
                <div className="crew-select input-holder">
                  <span>Crew</span>
                  <AsyncSelect
                    loadOptions={(value, callback) =>
                      this.loadOptions(value, callback, "crew")
                    }
                    onChange={(selection) =>
                      this.onChangeSelect(selection, "crew")
                    }
                    defaultValue={this.findCurrentCrew}
                    placeholder="Search"
                    classNamePrefix="select"
                    isDisabled={true}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <div className="who-select input-holder">
                  <span>Who selected them?</span>
                  <AsyncSelect
                    loadOptions={(value, callback) =>
                      this.loadOptions(value, callback, "who selected")
                    }
                    onChange={(selection) =>
                      this.onChangeSelect(selection, "whoSelected")
                    }
                    defaultValue={this.findWhoSelected}
                    placeholder="Search"
                    classNamePrefix="select"
                    isDisabled={true}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              </>
            )}

            {(this.state.applicantStatus ===
              statusApplication.DO_NOT_HIRE_ARCHIVE ||
              this.state.applicantStatus ===
                statusApplication.NOT_SELECTED_ARCHIVE) && (
              <>
                <div className="who-select input-holder">
                  <span>Who rejected them?</span>
                  <AsyncSelect
                    loadOptions={(value, callback) =>
                      this.loadOptions(value, callback, "who rejected")
                    }
                    onChange={(selection) =>
                      this.onChangeSelect(selection, "whoRejected")
                    }
                    defaultValue={this.findWhoSelected}
                    placeholder="Search"
                    classNamePrefix="select"
                    isDisabled={true}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <div className="rejection-notes">
                  <span>Notes on why the application was rejected</span>
                  <textarea
                    rows="5"
                    value={this.state.rejectionReason}
                    onChange={(e) =>
                      this.setState({ rejectionReason: e.target.value })
                    }
                  />
                </div>
              </>
            )}

            {(this.state.applicantStatus === statusApplication.WAS_HIRED ||
              this.state.applicantStatus ===
                statusApplication.NOT_SELECTED_ARCHIVE ||
              this.state.applicantStatus ===
                statusApplication.DO_NOT_HIRE_ARCHIVE) && (
              <>
                <div className="date-time input-holder">
                  <span>Date and time</span>
                  <DateAndTimePicker
                    date={this.state.date}
                    onChangeDate={this.onChangeUpdateApplicantDate}
                    time={this.state.time}
                    onChangeTime={this.onChangeUpdateApplicantTime}
                    disabled={true}
                  />
                </div>
              </>
            )}

            <>
              <div className="button-holder button-holder--right">
                <button
                  disabled={false}
                  className="button grey"
                  onClick={this.removeStatusCloseModal.bind(this)}
                >
                  Cancel
                </button>
                <button
                  disabled={
                    this?.state?.statusUpdating ||
                    this.props?.applicants?.list?.data?.applicant_status ===
                      this?.state?.applicantStatus
                  }
                  className="button"
                  onClick={this.updateApplicantStatus.bind(this)}
                >
                  Update
                </button>
              </div>
            </>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.isConfirmUpdateModalOpen}
          className="update-applicant-status-modal update-applicant-status-modal--sec"
        >
          <div className="modal-head">
            <h4 className="text-left">Confirm Status Update</h4>
          </div>
          <div className="modal-body applicant-status-modal-body">
            <p>
              An email notification will be sent to both the applicant and the
              respective authority.
            </p>
            <>
              <div className="button-holder button-holder--right">
                <button
                  disabled={false}
                  className="button grey"
                  onClick={() =>
                    this.setState({ isConfirmUpdateModalOpen: false })
                  }
                >
                  Cancel
                </button>
                <button
                  disabled={this?.state?.statusUpdating}
                  className="button"
                  onClick={this.updateApplicantStatus.bind(this)}
                >
                  Confirm
                </button>
              </div>
            </>
          </div>
        </Modal>

        {this.state.alertPop && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            viewOpen="true"
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    applicants,
    permission,
    search,
    user,
    createProfile: listAll,
  } = state;

  return {
    applicants,
    permission,
    search,
    listAll,
    user,
  };
}

const connectedHomePage = connect(mapStateToProps)(ViewApplicant);
export { connectedHomePage as ViewApplicant };
