import React from "react";
import { connect } from "react-redux";
import { authHeader, config } from "../_helpers";
// import { applicantsActions } from '../_actions';
import {
  resourceActions,
  chatActions,
  createProfileActions,
  userActions,
} from "../_actions";
import "../assets/styles/resource.css";
import { AlertPopup } from "../_components/AlertPopup";
import {
  selectQualifications,
  selectUserRoles,
  selectVerificationDetails,
} from "_helpers/reduxSelector";
import CustomCheckbox from "Docs/Components/Checkbox";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";

function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}

const DATE_FORMAT = "YYYY-MM-DD";
const US_DATE_FORMAT = "MM-DD-YYYY";

class EmployeeVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceId: "",
      hash: "",
      name: "",
      position: "",
      phone: "",
      email: "",
      options: [],
      roles: [],
      alertPop: false,
      docsStatus: "",
      rehire_docs_completed_status: false,
      docs_completed_status: false,
      // drug_screening_doc: false,
      // i9_doc: false,
      // onboarding_doc: false,
      userattributes: {
        drug_screening_doc: false,
        i9_doc: false,
        onboarding_doc: false,
        drug_screening_doc_date: null,
        i9_doc_date: null,
        docs_approved_at: null,
        // onboarding_doc_date: null,
        // rehire_docs_approved_at: null,
      },
      loading: true,
      employeeRoleType: "",
      employeeQualifications: [],
      employeeQualificationsToString: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.generateCheckbox = this.generateCheckbox.bind(this);
  }

  componentDidMount() {
    const hash = this.props.match.params.hash;
    this.setState({ hash });
    this.props.dispatch(resourceActions.getAll());
    this.props.dispatch(resourceActions.getVerificationDetails(hash));
    this.props.dispatch(chatActions.getConversationSettings());
    this.props.dispatch(createProfileActions.getAll());
    this.props.dispatch(userActions.getUserRoles());
    // this.props.dispatch(createProfileActions.getAllGISS());
  }

  componentDidUpdate(prevProps) {
    // Update Employee Role
    const prevEmployeeRoleType =
      prevProps?.verificationDetails?.user_type ?? "";
    const currentEmployeeRoleType =
      this?.props.verificationDetails?.user_type ?? "";
    const shouldUpdateEmployeeRole =
      currentEmployeeRoleType !== prevEmployeeRoleType;

    if (shouldUpdateEmployeeRole) {
      this.setState({ employeeRoleType: currentEmployeeRoleType });
    }
    // End

    // Update Employee Qualification
    const prevEmployeeQualifications =
      prevProps?.verificationDetails?.qualification_id ?? "";
    const currentEmployeeQualifications =
      this?.props?.verificationDetails?.qualification_id ?? "";
    const shouldUpdateEmployeeQualifications =
      currentEmployeeQualifications !== prevEmployeeQualifications;

    if (shouldUpdateEmployeeQualifications) {
      this.setState({
        employeeQualificationsToString: currentEmployeeQualifications,
        employeeQualifications: currentEmployeeQualifications?.split(","),
      });
    }
    // End
  }

  componentWillReceiveProps(nextProps) {
    const { resource, employee } = nextProps;
    var options = [];

    if (employee.items) {
      const {
        name,
        phone,
        email,
        position,
        crew_identifier_id,
        status,
        docs_status,
        roles,
        rehire_docs_status,
        rehire_docs_completed_status,
        docs_completed_status,
        userattribute,
      } = employee.items.data;

      const applicantsAttributes = userattribute[userattribute.length - 1];

      const applicantsAttributesModified = {
        drug_screening_doc:
          applicantsAttributes?.drug_screening_doc === 1 ? true : false,
        i9_doc: applicantsAttributes?.i9_doc === 1 ? true : false,
        onboarding_doc:
          applicantsAttributes?.onboarding_doc === 1 ? true : false,
        drug_screening_doc_date: moment(
          applicantsAttributes?.drug_screening_doc_date,
        ).isValid()
          ? moment(applicantsAttributes?.drug_screening_doc_date).format(
              US_DATE_FORMAT,
            )
          : null,
        i9_doc_date: moment(applicantsAttributes?.i9_doc_date).isValid()
          ? moment(applicantsAttributes?.i9_doc_date).format(US_DATE_FORMAT)
          : null,
        docs_approved_at: moment(
          applicantsAttributes?.docs_approved_at,
        ).isValid()
          ? moment(applicantsAttributes?.docs_approved_at).format(
              US_DATE_FORMAT,
            )
          : null,
      };

      this.setState({
        name,
        phone,
        email,
        position,
        resourceId: crew_identifier_id,
        status,
        docsStatus: docs_status,
        roles,
        rehire_docs_status,
        rehire_docs_completed_status,
        docs_completed_status,
        loading: false,
        userattributes: applicantsAttributesModified,
      });
    }

    if (resource.items) {
      Object.keys(resource.items.data).forEach((key) => {
        if (key && key !== "") {
          resource.items.data[key].map((item, index) => {
            options.push(
              <option key={index} value={item.id}>
                {item.resource_name}
              </option>,
            );
          });
        }
        return options;
      });

      this.setState({ options });
    }
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  handleChange(event) {
    this.setState({ [event?.target?.name]: event.target.value });
  }

  validate(e) {
    let isError = false;
    if (this.state.resourceId === "" || this.state.resourceId === undefined) {
      isError = true;
      this.setState({
        resourceIdError: "error",
      });
    }

    // Validating Employee Type

    if (!this.state.employeeRoleType) {
      isError = true;
      this.setState({ employeeRoleTypeError: "error" });
    }

    return isError;
  }

  handleStatus(status, rehire = false, approveAll = false) {
    const userAttributes = this?.state?.userattributes;

    if (
      !userAttributes.drug_screening_doc ||
      !userAttributes.i9_doc ||
      !userAttributes.onboarding_doc
    ) {
      return toast.error(
        "The checkboxes for docs submitted & verified are required.",
      );
    }

    if (
      !userAttributes.drug_screening_doc_date ||
      !userAttributes.i9_doc_date ||
      !userAttributes.docs_approved_at
    ) {
      return toast.error(
        "The dates for docs submitted & verified are required.",
      );
    }

    let url,
      data = new FormData();
    const err = this.validate();
    if (!status) {
      data.append("hash", this.state.hash);
      url = "/api/denyemployee";
    } else {
      const err = this.validate();
      if (!err) {
        data.append("hash", this.state.hash);
        data.append("resourceId", this.state.resourceId);
        data.append("position", this?.state?.employeeRoleType);
        data.append(
          "currentQualification",
          this?.state?.employeeQualificationsToString,
        );

        data.append("drug_screening_doc", userAttributes.drug_screening_doc);
        data.append("i9_doc", userAttributes.i9_doc);
        data.append("onboarding_doc", userAttributes.onboarding_doc);
        data.append(
          "drug_screening_doc_date",
          moment(userAttributes?.drug_screening_doc_date).isValid()
            ? moment(userAttributes?.drug_screening_doc_date).format(
                DATE_FORMAT,
              )
            : "",
        );
        data.append(
          "i9_doc_date",
          moment(userAttributes?.i9_doc_date).isValid()
            ? moment(userAttributes?.i9_doc_date).format(DATE_FORMAT)
            : "",
        );
        data.append(
          "docs_approved_at",
          moment(userAttributes?.docs_approved_at).isValid()
            ? moment(userAttributes?.docs_approved_at).format(DATE_FORMAT)
            : "",
        );

        if (approveAll) {
          data.append("approve_all", approveAll);
        }
        status === 1
          ? (url = `/api/verification?rehire=${rehire}`)
          : (url = `/api/employee/skipdocs?rehire=${rehire}`);
      }
    }
    if (!err && status === 1) {
      const hash = this.props.match.params.hash;
      var self = this;
      fetch(config.apiUrl + url, {
        headers: authHeader(),
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            self.setState({
              alertPop: true,
              alertHead: "Success",
              alertMsg: JSON.parse(JSON.stringify(data.message)),
              alertFunction: self.props.dispatch(
                resourceActions.getVerificationDetails(hash),
              ),
            });

            let params = {
              uid: this.props.user.profile.data.hash,
              signature: this.props.chat.settings.signature,
            };

            let url = new URL(
              `${config.apiUrl}/docs/download/zip/${hash}?rehire=${rehire}`,
            );
            Object.keys(params).forEach((key) =>
              url.searchParams.append(key, params[key]),
            );
            // window.open(url.href);
            window.location.href = url.href; //Added this line to avoid the opening of new tab.
          } else {
            self.setState({
              alertPop: true,
              alertHead: "Error",
              // alertMsg: JSON.parse(JSON.stringify(data.message)),
              alertMsg: "Something went wrong with Approving documents!",
              alertFunction: "",
            });
          }
        });
    } else if (status === 2) {
      fetch(config.apiUrl + url, {
        headers: authHeader(),
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          this.props.history.push(`/profile/${this.state.hash}`);
        });
    }
  }

  handleCheckbox(el) {
    let key = el.name;
    let userattributes = { ...this.state.userattributes };

    if (el.checked) {
      // this.setState({ [key]: true });
      userattributes[key] = true;
    } else {
      // this.setState({ [key]: false });
      userattributes[key] = false;
    }
    this.setState({ userattributes });
  }

  handleDateChange(date, key) {
    let userattributes = { ...this.state.userattributes };
    userattributes[key] = date?.format(DATE_FORMAT);
    this.setState({ userattributes });
  }

  generateCheckbox() {
    let userattributes = [];
    for (let [key, value] of Object.entries(this.state.userattributes)) {
      let name = "";
      let datePickerKey = "";
      switch (key) {
        case "drug_screening_doc":
          name = "Drug Screening";
          datePickerKey = "drug_screening_doc_date";
          break;
        case "i9_doc":
          name = "ID/I9";
          datePickerKey = "i9_doc_date";
          break;
        case "onboarding_doc":
          name =
            this.state.rehire_docs_status === 2
              ? "Rehire Documents"
              : "Onboarding Documents";
          datePickerKey = "docs_approved_at";
          break;
        default:
          break;
      }
      // let datePickerValue=this.state?.userattributes?.[datePickerKey]

      let datePickerValue = null;

      if (
        this?.state?.userattributes?.[datePickerKey] &&
        this?.state?.userattributes?.[datePickerKey].length
      ) {
        const formattedDateString = this?.state?.userattributes?.[
          datePickerKey
        ]?.replace(/-/g, "/");
        const dateObject = new Date(formattedDateString);
        datePickerValue = moment(dateObject, DATE_FORMAT);
      } else {
        datePickerValue = null;
      }

      name &&
        userattributes.push(
          <div
            className="current_qualification_item current_qualification_item_alignment"
            key={key}
          >
            <div className="current_qualification_item_alignment_left">
              <input
                type="checkbox"
                id={key}
                name={key}
                defaultChecked={value === 1 || value === true}
                onChange={(e) => this.handleCheckbox(e.currentTarget)}
              />
              <label htmlFor={key}>
                <span />
              </label>
              <span
                className="wpcf7-list-item-label mr-1"
                style={{ wordBreak: "break-all" }}
              >
                {name}
              </span>
            </div>
            <div className="current_qualification_item_alignment_right">
              <DatePicker
                name={datePickerKey}
                selected={
                  moment(datePickerValue)?.isValid()
                    ? moment(datePickerValue)
                    : null
                }
                onChange={(date) => this.handleDateChange(date, datePickerKey)}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={moment()}
              />
            </div>
          </div>,
        );
    }
    return userattributes;
  }

  handleEmployeeQualification(qualificationId) {
    let employeeQualifications = [
      ...(this.state?.employeeQualifications ?? []),
    ];
    let index = employeeQualifications?.indexOf(qualificationId);
    if (index > -1) {
      employeeQualifications.splice(index, 1);
    } else {
      employeeQualifications.push(qualificationId);
    }
    this.setState({
      employeeQualifications,
      employeeQualificationsToString: employeeQualifications?.toString(),
    });
  }

  render() {
    let buttonGroup = { "margin-top": "3rem" },
      listItem = { "margin-right": "2rem", display: "inline-block" };

    let { loading } = this.state;

    return (
      <div className="resource_container">
        <div className="page_title float" style={{ marginTop: "60px" }}>
          <h1>Verify Employee</h1>
        </div>

        {!loading && (
          <div className="resource_list emp_verify_wrap">
            <div className="formField">
              <label>Name:</label>
              <h4 className="txt-capitalize">{this.state.name}</h4>
            </div>
            <div className="formField">
              <label>Position:</label>
              <h4>{this.state.position}</h4>
            </div>
            <div className="formField">
              <label>Resource type *</label>
              <select
                name="resourceId"
                id="resource_type"
                value={this.state.resourceId}
                onChange={this.handleChange}
                errors={this.state.resourceIdError}
              >
                <option key={"a"} value="0">
                  Assign Resource*
                </option>
                {this.state.options}
              </select>
            </div>
            <div className="formField">
              <label>Cell number:</label>
              <h4>
                {this.state.phone ? (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`tel:${this.state.phone}`}
                    target="_top"
                  >
                    {formatPhoneNumber(this.state.phone)}
                  </a>
                ) : (
                  ""
                )}
                {/* {formatPhoneNumber(this.state.phone)} */}
              </h4>
            </div>
            <div className="formField">
              <label>Email:</label>
              <h4>
                {this.state.email ? (
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="phone_email_link"
                    href={`mailto:${this.state.email}`}
                    target="_top"
                  >
                    {this.state.email}
                  </a>
                ) : (
                  ""
                )}
                {/* {this.state.email} */}
              </h4>
            </div>

            {/* Checkboxes */}
            <div className="formField">
              <label>
                Check each box below to indicate it has been submitted and
                verified:
              </label>
              <div className="clearfix">{this.generateCheckbox()}</div>
            </div>
            {/* {this.state.roles.length > 0 && (
              <div className="formField">
                <label>The user have following roles</label>
                {this.state.roles.map((role) => (
                  <h4 style={listItem}>{role}</h4>
                ))}
              </div>
            )} */}
            <div className="formField">
              <label>Employee Type *</label>
              <select
                name="employeeRoleType"
                id="employee_role_type"
                value={this.state.employeeRoleType}
                onChange={this.handleChange}
                errors={this.state.employeeRoleTypeError}
              >
                <option value="">Assign a type*</option>
                {this?.props?.userRoles?.map((item) => (
                  <option key={item.id} value={item?.name}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
            {/* Fire Qualifications */}
            <div className="formField">
              <div>
                <span className="special-h-1">
                  Current Fire Qualifications (Check all that apply)
                </span>
              </div>
              <br />
              <div>
                <span className="wpcf7-form-control-wrap chkCurrentQualification">
                  <span
                    id="currentQualification"
                    className="wpcf7-form-control wpcf7-checkbox check-list-1"
                  >
                    {this?.props?.qualifications?.map((item, index) => (
                      <CustomCheckbox
                        onChange={() =>
                          this.handleEmployeeQualification(item?.id?.toString())
                        }
                        checked={this.state?.employeeQualifications?.includes(
                          item?.id?.toString(),
                        )}
                        key={index}
                        label={item.key}
                      />
                    ))}
                  </span>
                </span>
              </div>
            </div>
            {/* Buttons */}

            {this.state.docsStatus === 2 && (
              <div className="formField fr button-group" style={buttonGroup}>
                <button
                  className="btn button grey create_manifest marg-right-20"
                  onClick={() => this.handleStatus(0, false)}
                >
                  Deny
                </button>
                <button
                  className="btn button create_manifest"
                  onClick={() => this.handleStatus(1, false)}
                >
                  Approve &amp; Download (Onboarding)
                </button>
              </div>
            )}

            {this.state.rehire_docs_status === 2 && (
              <div className="formField fr button-group" style={buttonGroup}>
                <button
                  className="btn button grey create_manifest marg-right-20"
                  onClick={() => this.handleStatus(0, true)}
                >
                  Deny
                </button>
                <button
                  className="btn button create_manifest"
                  onClick={() => this.handleStatus(1, true)}
                >
                  Approve &amp; Download (Rehire)
                </button>
              </div>
            )}

            <div
              className="verification-buttons fr button-group"
              style={buttonGroup}
            >
              {(this.state.docsStatus === 1 ||
                this.state.docsStatus === -1 ||
                this.state.docsStatus === 0) &&
                this.state.roles.length === 0 && (
                  <button
                    className="btn button create_manifest verification-button"
                    onClick={() => this.handleStatus(2, false)}
                  >
                    Skip Docs &amp; Approve (Onboarding)
                  </button>
                )}
              {this.state.docsStatus === -1 &&
              this.state.docs_completed_status ? (
                <button
                  className="btn button create_manifest verification-button"
                  onClick={() => this.handleStatus(1, false, true)}
                >
                  Approve Documents (Onboarding)
                </button>
              ) : this.state.rehire_docs_status === -1 &&
                this.state.rehire_docs_completed_status ? (
                <button
                  className="btn button create_manifest verification-button"
                  onClick={() => this.handleStatus(1, true, true)}
                >
                  Approve Documents (Rehire)
                </button>
              ) : this.state.roles.length !== 0 ? (
                <p className=" emp_status verification-button">
                  User roles already assigned and there is no pending docs to
                  approve
                </p>
              ) : (
                ""
              )}

              {/* Commented out temporarily */}
              {/* {this.state.docsStatus === 3 && (this.state.rehire_docs_status === 1 || this.state.rehire_docs_status === -1 || this.state.rehire_docs_status === 0) && this.state.roles.length === 0 && (
              <button className="btn button create_manifest verification-button" onClick={() => this.handleStatus(2, true)}>
                Skip Docs &amp; Approve (Rehire)
              </button>
            )} */}
              {/* Messages */}
              {this.state.docsStatus === 1 && (
                <p className=" emp_status verification-button">
                  Please fill out all the Onboarding documents.
                </p>
              )}
              {this.state.rehire_docs_status === 1 && (
                <p className=" emp_status verification-button">
                  Please fill out all the Rehire documents.
                </p>
              )}
              {/* this.state.docsStatus === -1 && <p className=" emp_status">Please contact support team.</p> */}
              {this.state.docsStatus === 3 && (
                <p className=" emp_status verification-button">
                  Onboarding documents already approved
                </p>
              )}
              {this.state.rehire_docs_status === 3 && (
                <p className=" emp_status verification-button">
                  Rehire documents already approved
                </p>
              )}
            </div>
          </div>
        )}

        {this.state.alertPop === true && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { resource, employee, chat, user } = state;
  return {
    resource,
    employee,
    chat,
    user,
    qualifications: selectQualifications(state),
    userRoles: selectUserRoles(state),
    verificationDetails: selectVerificationDetails(state),
  };
}

const connectedResource = connect(mapStateToProps)(EmployeeVerification);
export { connectedResource as EmployeeVerification };
