import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import footerLogo from "../assets/images/firestorm.png";
// import { userActions } from '../_actions';

class Position extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      captain: false,
    };
    this.goBack = this.goBack.bind(this);
    this.information = this.information.bind(this);
  }

  componentDidMount() {}

  information(position) {
    if (position === "Superintendent / Engine Captain") {
      this.setState({ captain: true });
    } else {
      this.setState({ captain: false });
      this.props.information(this.props.type, position);
    }
  }

  goBack() {
    this.props.toResourceType();
  }

  render() {
    return (
      <div className="resource_type">
        <div className="login_header">
          <Link to={"/login"}>
            <img src={footerLogo} alt="Firestorm" />
          </Link>
          <p>Employee Management System</p>
        </div>

        <div className="top_head">
          <span className="back" onClick={this.goBack}>
            Back
          </span>
          <span className="title">Step 2 - Choose Position</span>
        </div>

        <div className="signup-contentarea">
          <div
            id="choosePositionWrapA"
            className={
              this.props.type === "FireFighter" && !this.state.captain
                ? "position-select"
                : "hide position-select"
            }
          >
            <span
              className="pos-sel"
              onClick={this.information.bind(this, "Firefighter")}
            >
              Firefighter
            </span>
            <span
              className="pos-sel"
              onClick={this.information.bind(this, "Crew Captain")}
            >
              Crew Captain
            </span>
            <span
              className="expand-opts"
              onClick={this.information.bind(
                this,
                "Superintendent / Engine Captain",
              )}
            >
              Superintendent / Engine Captain
            </span>
          </div>
          <div
            id="choosePositionWrapB"
            className={
              this.props.type === "AdminStaff" && !this.state.captain
                ? "position-select"
                : "hide position-select"
            }
          >
            <span
              className="pos-sel"
              onClick={this.information.bind(this, "Staff Manager")}
            >
              Staff Manager
            </span>
            <span
              className="pos-sel"
              onClick={this.information.bind(this, "Staff")}
            >
              Staff
            </span>
          </div>
          <div
            id="choosePositionWrapC"
            className={
              this.props.type === "FireFighter" && this.state.captain
                ? "position-select"
                : "hide position-select"
            }
          >
            <span
              className="pos-sel"
              onClick={this.information.bind(this, "Superintendent")}
            >
              Superintendent
            </span>
            <span
              className="pos-sel"
              onClick={this.information.bind(this, "Engine Captain")}
            >
              Engine Captain
            </span>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering } = state.registration;
  return {
    registering,
  };
}

const connectedRegisterPage = connect(mapStateToProps)(Position);
export { connectedRegisterPage as Position };
