import React, { useState, useEffect } from "react";
import { Tab, Row, Col, NavItem, Nav } from "react-bootstrap";
import cx from "classnames";
import { useSelector } from "react-redux";
import ChangePassword from "ChangePassword";
import { ViewApplicant } from "Applicants/ViewApplicant";
import InterviewForm from "InterviewForm";
import NoAccess from "./NoAccess";
import styles from "./applicant.module.scss";
import { withRouter } from "react-router";

const TAB_VALUES = [
  {
    title: "Job Application",
    key: "applicant",
    permission: "Manage Applicants",
  },
  {
    title: "Interview Forms",
    key: "interview",
    permission: "Manage Interview Forms",
  },
];

const ApplicantsTab = ({
  hash,
  applnType,
  goBack,
  email,
  sendMail,
  editApplicant,
  viewHistory,
  applicantView,
  isApplicationEditable,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState("no_reports_found");
  const permissions =
    useSelector((state) => state.permission?.permissions?.data) || [];
  const applicants = useSelector((state) => state.applicants?.list?.data) || {};
  const [enableStatusModal, setEnableStatusModal] = useState(false);

  let selfView = props.location.pathname.includes("settings"); //condition returns true of the user is viewing their own Job application

  const TABS = React.useMemo(() => {
    if (selfView) {
      return TAB_VALUES;
    } else {
      return (
        TAB_VALUES?.filter(({ permission }) =>
          permissions.includes(permission),
        ) ?? []
      );
    }
  }, [permissions]);

  const activeLabel =
    TABS?.filter(({ key }) => key === activeTab)[0]?.title || "";

  useEffect(() => {
    const actTab = TABS.length ? TABS[0].key : "no_reports_found";
    setActiveTab(actTab);
  }, [permissions]);

  return (
    <div className={styles.applicant__container}>
      {applicantView && (
        <>
          <div className="applicants_container application_details">
            <div className="breadcrumbs">
              <span onClick={goBack} className="green">
                Applicants
              </span>{" "}
              / {activeLabel}
            </div>
          </div>
          <div className={styles.applicant_tab_header}>
            <h1 className={styles.page_title}>
              {applicants
                ? `${applicants.first_name} ${applicants.middle_initial} ${applicants.last_name}`
                : ""}
            </h1>
            <div className={styles.header_btn_grp}>
              {permissions.includes("Manage Passwords") && (
                <ChangePassword
                  userType="applicant"
                  user={applicants}
                  isAdmin={true}
                />
              )}
              {permissions.includes("Email Applicants") && (
                <button className="button" onClick={() => sendMail(email)}>
                  Email
                </button>
              )}
              {permissions.includes("Manage Applicants") && (
                <button
                  className="button"
                  onClick={() => setEnableStatusModal(true)}
                >
                  Update Status
                </button>
              )}
            </div>
          </div>
        </>
      )}
      <section className="d-flex w-100">
        <div className="col-vert pos-rel w-100">
          <div className="doc-list-items">
            <Tab.Container id="tabs-with-dropdown" activeKey={activeTab}>
              <Row>
                <Col sm={12}>
                  <div className={cx("m-0 custom-tabs", styles.p_custom)}>
                    <Nav bsStyle="tabs">
                      {TABS.map((report) => (
                        <NavItem
                          eventKey={report.key}
                          className="custom-tab"
                          key={report.key}
                          onClick={() => setActiveTab(report.key)}
                        >
                          <div className={styles.tab_nav_item_txt}>
                            <img
                              src={
                                require(
                                  `assets/icons/${report.key}_${activeTab === report.key ? "green" : "grey"}.svg`,
                                ).default
                              }
                              alt=""
                            />
                            {report.title}
                          </div>
                        </NavItem>
                      ))}
                    </Nav>
                  </div>
                </Col>

                <Col sm={12}>
                  <div className="custom-tab-content">
                    <Tab.Content animation>
                      <Tab.Pane eventKey="applicant">
                        <ViewApplicant
                          hash={hash}
                          isApplicationEditable={isApplicationEditable}
                          applnType={applnType}
                          goBack={goBack}
                          email={email}
                          sendMail={sendMail}
                          editApplicant={editApplicant}
                          viewHistory={viewHistory}
                          enableStatusModal={enableStatusModal}
                          setEnableStatusModal={setEnableStatusModal}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="interview">
                        <InterviewForm
                          hash={hash}
                          email={email}
                          applicant={applicants}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="no_reports_found">
                        <NoAccess />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(ApplicantsTab);
