const DOC_LOCK_STATUS = 3;
const LOCK_DOCS = [1, 2, 4, 5];

const DocumentTypes = {
  Onboarding: 1,
  Rehire: 2,
  Other: 3,
  GISS_Onboarding: 4,
  GISS_Rehire: 5,
};
const checkIfDocsShouldBeLocked = (currentUserInfo, form, isAdmin) => {
  if (isAdmin) {
    return false;
  } else {
    const docCategoryId = form?.docs_categories_id;
    const isLockable = form?.is_lockable;
    let userDocStatus =
      docCategoryId === DocumentTypes.Rehire ||
      docCategoryId === DocumentTypes.GISS_Rehire
        ? currentUserInfo?.rehire_docs_status
        : currentUserInfo?.docs_status;
    return (
      userDocStatus === DOC_LOCK_STATUS &&
      isLockable &&
      LOCK_DOCS.includes(docCategoryId)
    );
  }
};

export default checkIfDocsShouldBeLocked;

export const getFontAwIconAndTitle = (status, doc_type) => {
  let status_code;
  if (doc_type === "onboarding") {
    switch (status) {
      case -1:
        status_code = {
          className: "fas fa-times-circle text-red",
          title: "Onboarding Document not filled",
        };
        break;
      case 0:
        status_code = {
          className: "fas fa-check-circle text-gray",
          title: "Asked to fill Onboarding Document",
        };
        break;
      case 1:
        status_code = {
          className: "fas fa-check-circle text-gray",
          title: "Started filling Onboarding Document",
        };
        break;
      case 2:
        status_code = {
          className: "fas fa-check-circle text-yellow",
          title: "Submitted For Review",
        };
        break;
      case 3:
        status_code = {
          className: "fas fa-check-circle text-green",
          title: "Approved Onboarding Document",
        };
        break;
    }
  } else if (doc_type === "rehire") {
    switch (status) {
      case -1:
        status_code = {
          className: "fas fa-times-circle text-red",
          title: "Rehire Document not filled",
        };
        break;
      case 0:
        status_code = {
          className: "fas fa-check-circle text-gray",
          title: "Asked to fill Rehire Document",
        };
        break;
      case 1:
        status_code = {
          className: "fas fa-check-circle text-gray",
          title: "Started filling Rehire Document",
        };
        break;
      case 2:
        status_code = {
          className: "fas fa-check-circle text-yellow",
          title: "Submitted For Review",
        };
        break;
      case 3:
        status_code = {
          className: "fas fa-check-circle text-green",
          title: "Approved Rehire Document",
        };
        break;
    }
  }
  return status_code;
};
