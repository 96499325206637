import { authHeader, config } from "../_helpers";

export const permissionService = {
  getAll,
  getPermission,
  setPermission,
  getAdminList,
  changeUserType,
  adminSearch,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/permission/getpermissions",
    requestOptions,
  ).then(handleResponse, handleError);
}

function getPermission() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/permission/getuserpermissions",
    requestOptions,
  ).then(handleResponse, handleError);
}

function setPermission(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/permission/setuserPermissions",
    requestOptions,
  ).then(handleResponse, handleError);
}

function changeUserType(hash) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: hash,
  };

  return fetch(
    config.apiUrl + "/api/permission/changeusertype",
    requestOptions,
  ).then(handleResponse, handleError);
}

function adminSearch(keyword) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: keyword,
  };

  return fetch(config.apiUrl + "/api/employee/getlist", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function getAdminList() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/permission/getadminlist",
    requestOptions,
  ).then(handleResponse, handleError);
}

function handleResponse(response) {
  if (response.status == 401) {
    localStorage.removeItem("user");
    window.location.href = "/login";
  } else {
    return new Promise((resolve, reject) => {
      if (response.ok) {
        // return json if it was returned in the response
        var contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          response.json().then((json) => resolve(json));
        } else {
          resolve();
        }
      } else {
        // return error message from response body
        response.text().then((text) => reject(text));
      }
    });
  }
}

function handleError(error) {
  return Promise.reject(error && error.message);
}
