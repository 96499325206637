import React from "react";
import { connect } from "react-redux";
import { config } from "../_helpers";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
ReactModal.setAppElement("#app");

class Bulletin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusOpen: false,
      file: "",
    };
    this.onImageError = this.onImageError.bind(this);
    this.viewFile = this.viewFile.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
  }
  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }
  viewFile(file) {
    window.open(config.apiUrl + "/" + file);
  }
  statusOpenModal(file) {
    this.setState({ statusOpen: true, file });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }
  render() {
    if (this.props.list) {
      const { data } = this.props.list;
      var options = [];
      data.map((item, index) => {
        var file_list = [],
          image_list = [];
        item.bulletin_files.map((file, index2) => {
          var file_format = file.file_name.split(".").pop().toLowerCase();
          if (
            file_format === "pdf" ||
            file_format === "doc" ||
            file_format === "docx" ||
            file_format === "txt"
          ) {
            file_list.push(
              <div
                key={"file" + index2}
                className="bulletin_link_item"
                onClick={this.viewFile.bind(this, file.file_hash)}
              >
                <p
                  className={
                    file_format === "docx"
                      ? "doc green"
                      : file_format + " green"
                  }
                >
                  {/*file.file_name ? file.file_name : file.title*/}
                </p>
              </div>,
            );
            return file_list;
          } else {
            // var inlineStyle = {
            //     background: 'url(' + config.apiUrl+"/"+file.file_hash + ')'
            // }
            image_list.push(
              <div
                key={"img" + index2}
                className="bulletin_imgs_item"
                // style={inlineStyle}
                onClick={this.statusOpenModal.bind(this, file.file_hash)}
              >
                {/* <img src={config.apiUrl+"/"+file.file_hash} alt="Bulletin" onError={this.onImageError.bind(this)}/>*/}
                <p className={file_format + " green"}>
                  {/*file.file_name ? file.file_name : file.title*/}
                </p>
              </div>,
            );
            return image_list;
          }
        });
        options.push(
          <div key={index} className="bullet_item">
            <div className="bullet_head">
              <h3>{item.title}</h3>
              <span className="bullet_date">
                {item.date}
                {/* {moment(item.updated_at).format("hh:mm a")} */}
              </span>
              {item.pinned && <span className="pinned_post">Pinned Post</span>}
            </div>
            <div className="has-ul-dot">
              <p dangerouslySetInnerHTML={{ __html: item.message }} />
            </div>
            <div className="bulletin_link">{file_list}</div>
            <div className="bulletin_imgs">{image_list}</div>
            <div className="clearFix" />
          </div>,
        );
        return options;
      });
    }
    return (
      <div className=" bulletin">
        <div className="bulletin_head inner_head">
          <h2>
            Bulletin Board
            {this.props.permission &&
              this.props.permission.includes("Bulletin Board") && (
                <span className="bullettin-plus-icon">
                  <Link to={"/settings/#bulletin"}>
                    <img
                      src={require("../assets/images/add-new.png").default}
                    />
                  </Link>
                </span>
              )}
            <span {...this.props.drag} className="drag-icon-controller" />
          </h2>

          <span
            className="toggle-controller"
            aria-owns="bulletin_details"
            onClick={(event) => {
              this.props.toggle(event, this.props.widgetid);
            }}
          />
        </div>
        <div
          className="bulletin_div home_bulletin"
          aria-expanded={this.props.visibility == 1 ? "true" : "false"}
          id="bulletin_details"
        >
          {options}
          <div
            className="bottom-fade-overlay"
            aria-owns="bulletin_details"
            onClick={(event) => {
              this.props.toggle(event, this.props.widgetid);
            }}
          />
        </div>
        <ReactModal
          isOpen={this.state.statusOpen}
          onClose={this.statusCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="bulletinPopup"
        >
          <div className="modal-head">
            <img
              alt="close"
              className="closeModal"
              onClick={this.statusCloseModal.bind(this)}
              src={require("../assets/images/close_green.png").default}
            />
          </div>
          <div className="modal-body" id="bulletinPopupBody">
            <img src={config.apiUrl + "/" + this.state.file} />
          </div>
        </ReactModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { bulletin } = state;
  return {
    bulletin,
  };
}

const connectedHomePage = connect(mapStateToProps)(Bulletin);
export { connectedHomePage as Bulletin };

// export default Navigation;
