export const dateTimeConstants = {
  PST_TIMEZONE: "America/New_York",
  TWO_DIGIT_FORMAT: "2-digit",
  NUMERIC_FORMAT: "numeric",
  LOCALE_DATE_STRING_FORMAT: "en-US",
  DATE_TIME_FORMAT: "MM/DD/YYYY HH:mm",
  DATE_FORMAT: "MM/DD/YYYY",
  ISO_DATE_FORMAT: "YYYY-MM-DD",
  DATE_TIME_MERIDIEM_FORMAT: "MM/DD/YYYY hh:mm A",
};
