import { useEffect, useMemo, useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { createProfileActions, userActions } from '_actions';
import {
  getSelectedUserInfo,
  getCurrentUserInfo,
  selectCurrentUserPermissions,
  selectQualifications,
  generalQualifications,
  listLSA,
} from '_helpers/reduxSelector';
import TextInput from 'Docs/Components/TextInput';
import { config } from '../_helpers';
import { ReactComponent as Calendar } from 'assets/icons/calendar-icon.svg';
import deleteIcon from '../assets/icons/delete-btn.svg';
import '../assets/styles/react-datepicker-modal.css';
import { useQuery } from 'react-query';
import { profileService } from '_services/profile.service';

const UserQualifications = ({ userHash }) => {
  const dispatch = useDispatch();

  const selectedUserInfo = useSelector(getSelectedUserInfo);
  const currentUserInfo = useSelector(getCurrentUserInfo);
  const qualifications = useSelector(selectQualifications);
  const generalQualification = useSelector(generalQualifications);
  const LSAList = useSelector(listLSA);
  const currentUserPermissions = useSelector(selectCurrentUserPermissions);
  const selectedQualifications = selectedUserInfo?.qualificationdetails;
  const checkboxesChecked = selectedQualifications
    ?.filter((qualification) => qualification?.quals_checked === 1)
    ?.map((qualification) => qualification?.qualification_id);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedQualId, setSelectedQualId] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedOptionQualName, setSelectedOptionQualName] = useState(null);
  const [selectedOptionColorCode, setSelectedOptionColorCode] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTableFieldEdited, setIsTableFieldEdited] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [tableData, setTableData] = useState(selectedQualifications);
  const [userQualsChecked, setUserQualsChecked] = useState(checkboxesChecked);
  const [userQualifications, setUserQualifications] = useState({
    generalQualification: '',
    lsa: '',
    rt130Expiry: '',
    fireSeasons: '',
  });
  const [updateBtn, setUpdateBtn] = useState(false);
  const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
  const red = '#FF0000';
  const grey = '#CCCCCC';
  const USDateFormat = 'MM/DD/YYYY';

  const { data: trainingResponse } = useQuery(
    ['trainingUserData', selectedUserInfo?.id],
    () => profileService.getTrainingData(selectedUserInfo?.usertraining?.id),
    {
      enabled: !!selectedUserInfo?.usertraining?.id,
    },
  );

  const ownProfile = selectedUserInfo?.id == currentUserInfo?.id;
  const trainingData = trainingResponse?.data?.form;

  // First form instance for Edit Qualifications
  const formAddQual = useForm({
    defaultValues: {
      quals_id: '',
      tsk_book_ini_date: '',
      quals_date: '',
      exp_date: '',
      quals_short_form: '',
      issuing_authority: '',
      color_code: '',
    },
  });

  // Second form instance for Edit Qualifications
  const formEditQual = useForm({
    defaultValues: {
      quals_name: '',
      quals_short_form: '',
      color_code: '',
    },
  });

  const {
    control: controlAddQual,
    handleSubmit: handleSubmitAddQual,
    formState: { errors: errorsAddQual },
    reset: resetAddQual,
  } = formAddQual;
  const {
    control: controlEditQual,
    handleSubmit: handleSubmitEditQual,
    formState: { errors: errorsEditQual },
    setValue: setValueEditQual,
    clearErrors: clearErrorsEditQual,
    setError: setErrorsEditQual,
  } = formEditQual;

  const getUserInfo = () => {
    if (userHash) {
      dispatch(userActions?.getProfile(`?hash=${userHash}`));
    } else {
      dispatch(userActions?.getAll());
    }
  };

  const handleCheckboxChange = (event, checkboxId) => {
    setIsTableFieldEdited(true);
    const isChecked = event?.target?.checked;

    if (isChecked) {
      // Checkbox is checked, add the ID to the array
      setUserQualsChecked((prevChecked) => [...prevChecked, checkboxId]);
    } else {
      // Checkbox is unchecked, remove the ID from the array
      setUserQualsChecked((prevChecked) =>
        prevChecked.filter((id) => id !== checkboxId),
      );
    }
  };

  const handleCancel = () => {
    //revert any changes made in the table before proceeding to save the changes
    setTableData((prev) => selectedUserInfo?.qualificationdetails);
    setIsTableFieldEdited(false);
    setUserQualsChecked(checkboxesChecked);
    setIsUpdateModalOpen(false);
  };

  const qualWithoutDates = useMemo(() => {
    //list of qualifications to display in Add qualifications section
    let items = [];
    qualifications?.forEach((item) => {
      let qualDetails = selectedQualifications?.find(
        (qual) => qual.qualification_id === item?.id,
      );

      items?.push({
        label: item?.value,
        value: item?.id,
        key: item?.key,
      });
    });
    return items;
  }, [qualifications, selectedUserInfo]);

  const listColorCode = useMemo(() => {
    //list of available color codes for the qualifications
    let items = [];
    generalQualification?.forEach((item) => {
      items?.push({
        label: (
          <div>
            <span
              className='qual_color_code'
              style={{
                backgroundColor: item?.color_code,
              }}
            />
            {item?.color_code}
          </div>
        ),
        value: item?.id,
      });
    });
    return items;
  }, [qualifications, selectedUserInfo]);

  const CustomDatePickerInput = forwardRef(
    ({ value, onClick, onChange }, ref) => (
      <div className='qualification-datepicker' onClick={onClick}>
        <input type='text' value={value} onChange={onChange} ref={ref} />
        <Calendar className='svg-icon' />
      </div>
    ),
  );

  const handleDateChange = (index, date, flag) => {
    setIsTableFieldEdited(true);

    const formatTableData = (field) => {
      const updatedTableData = tableData?.map((obj, objIndex) => {
        if (objIndex === index) {
          return {
            ...obj,
            [field]: date ? moment(date).format(DATE_FORMAT) : '',
          };
        }
        return obj;
      });
      setTableData(updatedTableData);
    };

    switch (flag) {
      case 'tsk':
        formatTableData('tsk_book_ini_date');
        break;
      case 'qual':
        formatTableData('qualifying_date');
        break;
      case 'exp':
        formatTableData('expiration_date');
        break;
      default:
        break;
    }
  };

  const handleIssuingAuthority = (index, event) => {
    //handle asynchronous issuing authority change functionality in the table
    setIsTableFieldEdited(true);
    const tempTableData = tableData?.map((value, tableIndex) => {
      if (tableIndex === index) {
        return {
          ...value,
          issuing_authority: event.target.value,
        };
      }
      return value;
    });
    setTableData(tempTableData);
  };

  const handleEditModal = (index) => {
    setSelectedIndex(index);
    setIsEditModalOpen(true);
  };

  const handleOnSubmit = async (values) => {
    //handle Add qualifications functionality
    setIsSubmitting(true);
    let data = new FormData();

    if (selectedOptionQualName?.__isNew__) {
      //appending data for a new qualification
      data.append('quals_short_form', values?.quals_short_form);
      data.append('is_new_qual', 1);
      data.append('quals_name', selectedOptionQualName?.label);
      data.append('color_code', values?.color_code);
    } else {
      data.append('quals_id', values?.quals_id);
    }

    data.append(
      'tsk_book_ini_date',
      values?.tsk_book_ini_date
        ? moment(values?.tsk_book_ini_date)?.format(DATE_FORMAT)
        : '',
    );

    data.append(
      'qualifying_date',
      values?.quals_date ? moment(values.quals_date)?.format(DATE_FORMAT) : '',
    );

    data.append(
      'expiration_date',
      values?.exp_date ? moment(values?.exp_date)?.format(DATE_FORMAT) : '',
    );

    data.append('issuing_authority', values?.issuing_authority);
    data.append('user_id', selectedUserInfo?.id);

    let res;
    try {
      res = await dispatch(userActions.addQualification(data));
      if (res.success) {
        getUserInfo();
        setIsTableFieldEdited(false);
        closeSubmitModal();
        return toast.success(res?.message);
      } else if (!res.success) {
        return toast.error(res?.message);
      }
    } catch (error) {
      let errorMessage = 'Something went wrong!';
      setIsSubmitting(false);
      const res = JSON.parse(error);
      if (res?.validation_error == false) {
        errorMessage = res?.message;
      } else {
        if (res.message) {
          Object.keys(res.message).some((key) => {
            if (res.message[key].length > 0) {
              errorMessage = res.message[key][0];
              return true;
            }
            return false;
          });
        }
      }
      return toast.error(errorMessage);
    }
  };

  const handleOnEdit = async (values, id) => {
    //handle Edit qualification functioanility
    setIsSubmitting(true);
    let data = new FormData();

    data.append('quals_short_form', values?.quals_short_form);
    data.append('quals_name', values?.quals_name);
    data.append('quals_id', id);
    data.append('color_code', values?.color_code);
    data.append('id', selectedUserInfo?.id);

    let res;
    try {
      res = await dispatch(userActions.editQualification(data));
      if (res.success) {
        getUserInfo();
        setIsTableFieldEdited(false);
        setIsEditModalOpen(false);
        setIsSubmitting(false);
        return toast.success(res?.message);
      } else if (!res.success) {
        return toast.error(res?.message);
      }
    } catch (error) {
      let errorMessage = 'Something went wrong!';
      setIsSubmitting(false);
      const res = JSON.parse(error);
      if (res?.validation_error == false) {
        errorMessage = res?.message;
      } else {
        if (res.message) {
          Object.keys(res.message).some((key) => {
            if (res.message[key].length > 0) {
              errorMessage = res.message[key][0];
              return true;
            }
            return false;
          });
        }
      }
      return toast.error(errorMessage);
    }
  };

  const handleUpdate = async () => {
    //handle Update quailfications details of the user functionality
    let data = new FormData();
    data.append('user_id', selectedUserInfo?.id);
    const qualificationDetails = tableData?.map((item) => ({
      qualification_id: item?.qualification_id,
      qualifying_date: item?.qualifying_date || null,
      issuing_authority: item?.issuing_authority || null,
      tsk_book_ini_date: item?.tsk_book_ini_date || null,
      expiration_date: item?.expiration_date || null,
      id: item?.id || null,
      quals_checked: userQualsChecked?.includes(item?.qualification_id) ? 1 : 0,
    }));

    data.append('qualificationDetails', JSON.stringify(qualificationDetails));

    let res;
    try {
      res = await dispatch(userActions.updateQualification(data));
      if (res.success) {
        getUserInfo();
        setIsTableFieldEdited(false);
        setIsUpdateModalOpen(false);
        setIsSubmitting(false);
        return toast.success(res?.message);
      } else if (!res.success) {
        return toast.error(res?.message);
      }
    } catch (error) {
      let errorMessage = 'Something went wrong!';
      setIsSubmitting(false);
      const res = JSON.parse(error);
      if (res?.validation_error == false) {
        errorMessage = res?.message;
      } else {
        if (res.message) {
          Object.keys(res.message).some((key) => {
            if (res.message[key].length > 0) {
              errorMessage = res.message[key][0];
              return true;
            }
            return false;
          });
        }
      }
      return toast.error(errorMessage);
    }
  };

  const closeSubmitModal = () => {
    setIsAddModalOpen(false);
    resetAddQual();
    setIsSubmitting(false);
  };

  const errorStyles = (field) => ({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: field ? red : grey,
    }),
  });

  const handleOnChange = (name, value) => {
    setUpdateBtn(true);
    setUserQualifications((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const openDeleteModal = (qualId) => {
    setSelectedQualId(qualId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteSubmitModal = () => {
    setSelectedQualId(null);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteQualSubmit = async (event) => {
    event.preventDefault();
    let data = new FormData();
    data.append('id', selectedQualId);

    try {
      const res = await dispatch(userActions.removeQualification(data));
      if (res?.success) {
        setSelectedQualId(null);
        setIsDeleteModalOpen(false);
        setTableData((prevData) =>
          prevData.filter((item) => item.id !== selectedQualId),
        );
        return toast.success(res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateGeneralQualification = async () => {
    let data = new FormData();

    data.append('id', selectedUserInfo.id);
    const qualificationItem = generalQualification.find(
      (element) => element.name === userQualifications.generalQualification,
    );
    if (!qualificationItem?.id) {
      return toast.error('Qualification is a required field');
    }
    if (!userQualifications?.rt130Expiry) {
      return toast.error('RT-130 is a required field');
    }
    data.append(
      'generalQualification',
      qualificationItem?.id ? qualificationItem?.id : '',
    );

    const lsaItem = LSAList.find(
      (element) => element.value === userQualifications.lsa,
    );
    data.append('lsa', lsaItem?.id ? lsaItem?.id : '');
    data.append('rt130Expiry', userQualifications?.rt130Expiry);
    data.append('fire_seasons', userQualifications?.fireSeasons);

    //Update Qualifications Flag
    data.append('update_qualifications_flag', true);
    try {
      const res = await dispatch(
        userActions.editProfile(data, '?hash=' + selectedUserInfo?.hash),
      );
      if (res?.success) {
        getUserInfo();
        return toast.success(res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!isAddModalOpen) {
      setSelectedOptionQualName(null);
    }
  }, [isAddModalOpen]);

  useEffect(() => {
    if (!isDeleteModalOpen) {
      setSelectedQualId(null);
    }
  }, [isDeleteModalOpen]);

  useEffect(() => {
    clearErrorsEditQual();
    if (selectedIndex !== null) {
      const tempValue = selectedQualifications[selectedIndex];

      setValueEditQual('quals_name', tempValue?.qualification?.value);
      setValueEditQual('quals_short_form', tempValue?.qualification?.key);
      setValueEditQual(
        'color_code',
        listColorCode?.find(
          (option) => option.value === tempValue?.qualification?.color_code,
        )?.value,
      );
    }
  }, [selectedIndex, isEditModalOpen]);

  useEffect(() => {
    if (selectedQualifications?.length) {
      setTableData(selectedQualifications);
    }
    setUserQualsChecked(checkboxesChecked);
  }, [selectedQualifications]);

  useEffect(() => {
    dispatch(createProfileActions?.getAll());
    getUserInfo();
  }, []);

  const QualificationItemMobile = ({ data, index }) => (
    <div key={index}>
      <hr></hr>
      <div className='status_item'>
        <div className='list_item'>
          <strong>Qualification</strong>
        </div>
        <div className='mobile_qualification_item'>
          <input
            type='checkbox'
            id={'checkbox_current_qualification' + data?.key}
            checked={userQualsChecked?.includes(data?.qualification_id)}
            name='currentQualification'
            defaultValue={data?.key ?? '...'}
            onChange={(event) =>
              handleCheckboxChange(event, data?.qualification_id)
            }
            disabled={
              !currentUserPermissions?.includes('Manage Qualifications')
            }
          />
          <label
            htmlFor={'check_current_qualification' + data?.qualification?.key}
          >
            <span />
          </label>
          <span className='wpcf7-list-item-label'>
            {data?.qualification?.key}
          </span>
          {currentUserPermissions?.includes('Manage Qualifications') && (
            <span
              style={{ cursor: 'pointer', marginLeft: '10px' }}
              onClick={() => {
                openDeleteModal(data?.id);
              }}
            >
              <img
                src={deleteIcon}
                alt='delete document'
                style={{ width: '15px' }}
              />
            </span>
          )}
          {currentUserPermissions?.includes('Manage Qualifications') && (
            <img
              style={{ paddingLeft: 5 }}
              src={`${config.assetUrl}icons/edit-icon.svg`}
              alt='Edit Qualification'
              className='edit-icon-btn'
              onClick={() => handleEditModal(index)}
            />
          )}
        </div>
      </div>
      <div className='status_item'>
        <div className='list_item'>
          <strong>Tsk Book Initiated</strong>
        </div>
        <div className='mobile_qualification_item'>
          <DatePicker
            dateFormat={USDateFormat}
            maxDate={moment()} //max date shall not  be more than the current date
            name='tsk_book_ini_date'
            selected={
              data?.tsk_book_ini_date ? moment(data?.tsk_book_ini_date) : ''
            }
            onChange={(date) => {
              // Check if the selected date is greater than the current date
              if (date && moment(date).isAfter(moment())) {
                handleDateChange(index, null, 'tsk'); // Clear the input by setting it to null
              } else {
                handleDateChange(index, date, 'tsk'); // Update the date if it's valid
              }
            }}
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            disabled={
              !currentUserPermissions?.includes('Manage Qualifications')
            }
            popperClassName='datePickerTop0'
          />
        </div>
      </div>
      <div className='status_item'>
        <div className='list_item'>
          <strong>Qualifying Date</strong>
        </div>
        <div className='mobile_qualification_item'>
          <DatePicker
            dateFormat={USDateFormat}
            maxDate={moment()} //max date shall not  be more than the current date
            name='qualifying_date'
            selected={
              data?.qualifying_date ? moment(data?.qualifying_date) : ''
            }
            onChange={(date) => {
              // Check if the selected date is greater than the current date
              if (date && moment(date).isAfter(moment())) {
                handleDateChange(index, null, 'qual'); // Clear the input by setting it to null
              } else {
                handleDateChange(index, date, 'qual'); // Update the date if it's valid
              }
            }}
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            disabled={
              !currentUserPermissions?.includes('Manage Qualifications')
            }
            popperClassName='datePickerTop0'
          />
        </div>
      </div>
      <div className='status_item'>
        <div className='list_item'>
          <strong>Expiration Date</strong>
        </div>
        <div className='mobile_qualification_item'>
          <DatePicker
            dateFormat={USDateFormat}
            minDate={moment()} //minimum date shall not  be lesser than the current date
            name='expiration_date'
            selected={
              data?.expiration_date ? moment(data?.expiration_date) : ''
            }
            onChange={(date) => {
              // Check if the selected date is lesser than the current date
              if (date && moment(date).isBefore(moment())) {
                handleDateChange(index, null, 'exp'); // Clear the input by setting it to null
              } else {
                handleDateChange(index, date, 'exp'); // Update the date if it's valid
              }
            }}
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            disabled={
              !currentUserPermissions?.includes('Manage Qualifications')
            }
            popperClassName='datePickerTop0'
          />
        </div>
      </div>
      <div className='status_item'>
        <div className='list_item'>
          <strong>Issuing Authority</strong>
        </div>
        <div className='mobile_qualification_item'>
          <div className='mobile_input_container'>
            <input
              type='text'
              name='issuing_authority'
              onChange={(event) => handleIssuingAuthority(index, event)}
              value={data?.issuing_authority ? data?.issuing_authority : ''}
              disabled={
                !currentUserPermissions?.includes('Manage Qualifications')
              }
            />
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const matchingQualification = generalQualification?.find(
      (qualification) =>
        qualification.id === selectedUserInfo?.general_qualification_id,
    );
    setUserQualifications({
      generalQualification: matchingQualification
        ? matchingQualification.name
        : '',
      lsa: selectedUserInfo?.lsa || '',
      rt130Expiry: selectedUserInfo?.useritemdates?.rt_130_expiry || '',
      fireSeasons:
        selectedUserInfo?.fire_seasons >= 0
          ? selectedUserInfo?.fire_seasons
          : '',
    });
  }, [selectedUserInfo, generalQualification]);

  return (
    <div className='content_div settings'>
      <div>
        <div className='qualifications_header-tab'>
          <h3>General Qualification</h3>
          {updateBtn &&
            currentUserPermissions?.includes('Manage Qualifications') && (
              <button
                className='button'
                onClick={handleUpdateGeneralQualification}
              >
                Update Qualifications
              </button>
            )}
        </div>
        <div className='qualifications_header-tab'>
          <div className='formField'>
            <label>Qualification</label>
            <select
              name='generalQualification'
              value={userQualifications?.generalQualification}
              onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              disabled={
                !(
                  currentUserPermissions?.includes('Manage Qualifications') ||
                  ownProfile
                )
              }
            >
              <option value=''>--</option>
              {generalQualification.map((item) => {
                return <option value={item.name}>{item.name}</option>;
              })}
            </select>
          </div>
          <div className='formField'>
            <label>LSA</label>
            <select
              name='lsa'
              value={userQualifications?.lsa}
              onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              disabled={
                !(
                  currentUserPermissions?.includes('Manage Qualifications') ||
                  ownProfile
                )
              }
            >
              <option value=''>None</option>
              {LSAList.map((item) => {
                return <option value={item.value}>{item.value}</option>;
              })}
            </select>
          </div>
          <div className='formField'>
            <label>RT-130</label>
            <DatePicker
              name='rt130Expiry'
              selected={
                userQualifications.rt130Expiry
                  ? moment(userQualifications.rt130Expiry)
                  : null
              }
              onChange={(date) =>
                handleOnChange(
                  'rt130Expiry',
                  date ? moment(date).format(DATE_FORMAT) : '',
                )
              }
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              dateFormat={'MM/DD/YYYY'}
              disabled={
                !(
                  currentUserPermissions?.includes('Manage Qualifications') ||
                  ownProfile
                )
              }
            />
          </div>
          <div className='formField'>
            <label>Fire Seasons</label>
            <input
              name='fireSeasons'
              value={userQualifications?.fireSeasons}
              onChange={(e) => handleOnChange(e.target.name, e.target.value)}
              type='number'
              disabled={
                !(
                  currentUserPermissions?.includes('Manage Qualifications') ||
                  ownProfile
                )
              }
            />
          </div>
        </div>
      </div>

      <div className='qualifications_header-tab'>
        <h3>Qualifications</h3>
        {currentUserPermissions?.includes('Manage Qualifications') && (
          <div className='qualifications_btn_grp'>
            <button className='button' onClick={() => setIsAddModalOpen(true)}>
              Add Qualification
            </button>
            {isTableFieldEdited && (
              <>
                <button className='button' onClick={handleCancel}>
                  Cancel
                </button>
                <button
                  className='button'
                  onClick={() => setIsUpdateModalOpen(true)}
                >
                  Save Changes
                </button>
              </>
            )}
          </div>
        )}
      </div>
      <table className='tab_hide qualification_table' style={{ width: '100%', marginBottom: "20px" }}>
        <thead>
          <tr className='qualification_table_row'>
            <th>Qualification</th>
            <th>Tsk Book Initiated</th>
            <th>Qualifying Date</th>
            <th>Expiration Date</th>
            <th>Issuing Authority</th>
            <th> </th>
          </tr>
        </thead>
        {/* Desktop List */}
        <tbody className='qualification_table_tbody'>
          {tableData?.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <div className='current_qualification_item'>
                    <input
                      type='checkbox'
                      id={
                        'checkbox_current_qualification' +
                        item?.qualification?.key
                      }
                      checked={userQualsChecked?.includes(
                        item?.qualification_id,
                      )}
                      name='currentQualification'
                      defaultValue={item?.qualification?.key ?? '...'}
                      onChange={(event) =>
                        handleCheckboxChange(event, item?.qualification_id)
                      }
                      disabled={
                        !currentUserPermissions?.includes(
                          'Manage Qualifications',
                        )
                      }
                    />
                    <label
                      htmlFor={
                        'checkbox_current_qualification' +
                        item?.qualification?.key
                      }
                    >
                      <span />
                    </label>
                    <div>
                      <span className='wpcf7-list-item-label'>
                        {item?.qualification?.key}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <DatePicker
                    dateFormat={USDateFormat}
                    maxDate={moment()} //max date shall not  be more than the current date
                    name='tsk_book_ini_date'
                    selected={
                      item?.tsk_book_ini_date
                        ? moment(item?.tsk_book_ini_date)
                        : null
                    }
                    onChange={(date) => {
                      // Check if the selected date is greater than the current date
                      if (date && moment(date).isAfter(moment())) {
                        handleDateChange(index, null, 'tsk'); // Clear the input by setting it to null
                      } else {
                        handleDateChange(index, date, 'tsk'); // Update the date if it's valid
                      }
                    }}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    disabled={
                      !currentUserPermissions?.includes('Manage Qualifications')
                    }
                    isClearable
                    popperClassName='datePickerTop0'
                  />
                </td>
                <td>
                  <DatePicker
                    dateFormat={USDateFormat}
                    maxDate={moment()} //max date shall not  be more than the current date
                    name='qualifying_date'
                    selected={
                      item?.qualifying_date
                        ? moment(item?.qualifying_date)
                        : null
                    }
                    onChange={(date) => {
                      // Check if the selected date is greater than the current date
                      if (date && moment(date).isAfter(moment())) {
                        handleDateChange(index, null, 'qual'); // Clear the input by setting it to null
                      } else {
                        handleDateChange(index, date, 'qual'); // Update the date if it's valid
                      }
                    }}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    disabled={
                      !currentUserPermissions?.includes('Manage Qualifications')
                    }
                    isClearable
                    popperClassName='datePickerTop0'
                  />
                </td>
                <td>
                  <DatePicker
                    dateFormat={USDateFormat}
                    minDate={moment()} //minimum date shall not  be lesser than the current date
                    name='expiration_date'
                    selected={
                      item?.expiration_date
                        ? moment(item?.expiration_date)
                        : null
                    }
                    onChange={(date) => {
                      // Check if the selected date is lesser than the current date
                      if (date && moment(date).isBefore(moment())) {
                        handleDateChange(index, null, 'exp'); // Clear the input by setting it to null
                      } else {
                        handleDateChange(index, date, 'exp'); // Update the date if it's valid
                      }
                    }}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    disabled={
                      !currentUserPermissions?.includes('Manage Qualifications')
                    }
                    isClearable
                    popperClassName='datePickerTop0'
                  />
                </td>
                <td>
                  <input
                    type='text'
                    name='issuing_authority'
                    onChange={(event) => handleIssuingAuthority(index, event)}
                    value={
                      item?.issuing_authority ? item?.issuing_authority : ''
                    }
                    disabled={
                      !currentUserPermissions?.includes('Manage Qualifications')
                    }
                    isClearable
                  />
                </td>
                <td>
                  {currentUserPermissions?.includes(
                    'Manage Qualifications',
                  ) && (
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        openDeleteModal(item?.id);
                      }}
                    >
                      <img
                        src={deleteIcon}
                        alt='delete document'
                        style={{ width: '15px' }}
                      />
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Mobile List */}
      <div className='tab_show'>
        <div className='history_items'>
          {tableData?.map((item, index) => (
            <QualificationItemMobile
              data={item}
              index={index}
              key={index}
              checkboxesChecked={userQualsChecked}
            />
          ))}
        </div>
      </div>

      <div style={{ marginBottom: '30px' }}>
          <>
            <h3>Trainings</h3>

            <table className='display_full_width qualification_table'>
              <thead>
                <tr className='qualification_table_row'>
                  <th>Category</th>
                  <th>Completed Trainings</th>
                </tr>
              </thead>
              <tbody className='qualification_table_tbody'>
                {[
                  [
                    'Physical Fitness',
                    [
                      (trainingData?.physical_fitness_pack_tes ||
                        trainingData?.physical_fitness_pk_date) &&
                        'Pack Test',
                      (trainingData?.physical_fitness_two_mile ||
                        trainingData?.physical_fitness_2mi_date) &&
                        '2-Mile Run',
                    ],
                  ],
                  [
                    'Basic 32',
                    [
                      trainingData?.basic_32_s_130 && 'S-130',
                      trainingData?.basic_32_s_190 && 'S-190',
                      trainingData?.basic_32_l_180 && 'L-180',
                      trainingData?.basic_32_24_hr_cer && '24-hr Certificate',
                    ],
                  ],
                  [
                    'Fema Courses',
                    [
                      trainingData?.fema_courses_is_100 && 'IS-100',
                      trainingData?.fema_courses_is_200 && 'IS-200',
                      trainingData?.fema_courses_is_00700 && 'IS-00700',
                      trainingData?.fema_courses_is_00800 && 'IS-00800',
                    ],
                  ],
                  [
                    'Firestorm Courses',
                    [
                      trainingData?.fs_courses_business_courses &&
                        'Business Ethics',
                      trainingData?.fs_courses_driver_operator &&
                        'Driver/Operator',
                    ],
                  ],
                  [
                    'Instructor Training',
                    [trainingData?.instructor_training_m_410 && 'M-410'],
                  ],
                  [
                    'Medical Training',
                    [
                      trainingData?.medical_training_survival_cpr &&
                        'Survival CPR/First Aid',
                    ],
                  ],
                  [
                    'Specialty Training',
                    [
                      trainingData?.specialty_training_hazwoper_40 &&
                        'HAZWOPER 40',
                      trainingData?.specialty_training_hazwoper_8hr &&
                        'HAZWOPER 8HR',
                      trainingData?.specialty_training_tcia_co &&
                        'TCIA Chipper Operator',
                      trainingData?.specialty_training_fwfs_co &&
                        'FWFS Chipper Operator',
                      trainingData?.specialty_training_certified_flagger &&
                        'Certified Flagger',
                      trainingData?.specialty_training_hired_equipment &&
                        'Hire Equipment',
                      trainingData?.specialty_training_atv && 'ATV',
                      trainingData?.specialty_training_co_st &&
                        'Chainsaw Operator and Safety Training',
                      trainingData?.specialty_training_forklift_operator &&
                        'Forklift Operator',
                      trainingData?.specialty_training_forklift_trainer &&
                        'Forklift Trainer',
                      trainingData?.specialty_training_job_relations &&
                        'Job Relations',
                      trainingData?.specialty_training_leader_success &&
                        'Comm. For Leader Success',
                      trainingData?.specialty_training_colors_personality_training &&
                        'Colors Personality Training',
                      trainingData?.specialty_training_inter_advanced &&
                        'Inter/Advanced Sawyer/Felling',
                      trainingData?.specialty_training_fire_burn_boss &&
                        'State-Certified Prescribed-Fire Burn Boss',
                    ],
                  ],
                  [
                    'FFT1 Training',
                    [
                      trainingData?.fft1_training_s_131 && 'S-131',
                      trainingData?.fft1_training_s_133 && 'S-133',
                      trainingData?.fft1_training_s_134 && 'S-134',
                      trainingData?.fft1_training_s_211 && 'S-211',
                      trainingData?.fft1_training_s_212 && 'S-212',
                      trainingData?.fft1_training_s_212_rt && 'S-212 RT',
                      trainingData?.fft1_training_s_212_exp && 'S-212 Exp',
                    ],
                  ],
                  [
                    'SRB Training',
                    [
                      trainingData?.srb_training_s_215 && 'S-215',
                      trainingData?.srb_training_s_234_219 && 'S-234/219',
                      trainingData?.srb_training_s_230 && 'S-230',
                      trainingData?.srb_training_s_231 && 'S-231',
                      trainingData?.srb_training_s_260 && 'S-260',
                      trainingData?.srb_training_s_270 && 'S-270',
                      trainingData?.srb_training_s_290 && 'S-290',
                    ],
                  ],
                  [
                    'Skills Courses (I)',
                    [
                      trainingData?.skills_courses_s_200 && 'S-200',
                      trainingData?.skills_courses_s_203 && 'S-203',
                      trainingData?.skills_courses_s_216 && 'S-216',
                      trainingData?.skills_courses_s_232 && 'S-232',
                      trainingData?.skills_courses_s_233 && 'S-233r',
                      trainingData?.skills_courses_s_235 && 'S-235',
                      trainingData?.skills_courses_s_244_245 && 'S-244/245',
                      trainingData?.skills_courses_s_248 && 'S-248',
                      trainingData?.skills_courses_s_258 && 'S-258',
                      trainingData?.skills_courses_s_259 && 'S-259',
                      trainingData?.skills_courses_s_271 && 'S-271',
                      trainingData?.skills_courses_s_273 && 'S-273',
                      trainingData?.skills_courses_s_300 && 'S-300',
                      trainingData?.skills_courses_s_301 && 'S-301',
                      trainingData?.skills_courses_s_330 && 'S-330',
                      trainingData?.skills_courses_s_331 && 'S-331',
                      trainingData?.skills_courses_s_336 && 'S-336',
                      trainingData?.skills_courses_s_339 && 'S-339',
                      trainingData?.skills_courses_s_346 && 'S-346',
                      trainingData?.skills_courses_s_348_347 && 'S-348/347',
                      trainingData?.skills_courses_s_354 && 'S-354',
                      trainingData?.skills_courses_s_355 && 'S-355',
                      trainingData?.skills_courses_s_356 && 'S-356',
                    ],
                  ],
                  [
                    'Incident Courses',
                    [
                      trainingData?.incident_courses_i_220 && 'I-220',
                      trainingData?.incident_courses_i_300 && 'I-300',
                      trainingData?.incident_courses_i_400 && 'I-400',
                      trainingData?.incident_courses_i_401 && 'I-401',
                      trainingData?.incident_courses_i_402 && 'I-402',
                    ],
                  ],
                  [
                    'Leadership Courses',
                    [
                      trainingData?.leadership_courses_l_280 && 'L-280',
                      trainingData?.leadership_courses_l_380 && 'L-380',
                      trainingData?.leadership_courses_l_381 && 'L-381',
                      trainingData?.leadership_courses_fire_mgmt &&
                        'Fire Mgmt Leadership',
                    ],
                  ],
                  [
                    'RX Burn Courses',
                    [
                      trainingData?.rx_burn_courses_rx_300 && 'Rx-300',
                      trainingData?.rx_burn_courses_rx_340_310 && 'Rx-340/310',
                      trainingData?.rx_burn_courses_rx_410 && 'Rx-410',
                      trainingData?.rx_burn_courses_rx_540 && 'Rx-540',
                      trainingData?.rx_burn_courses_currency && 'Currency',
                    ],
                  ],
                  [
                    'Skills Courses (II)',
                    [
                      trainingData?.skills_courses_s_357 && 'S-357',
                      trainingData?.skills_courses_s_360 && 'S-360',
                      trainingData?.skills_courses_s_370 && 'S-370',
                      trainingData?.skills_courses_s_371 && 'S-371',
                      trainingData?.skills_courses_s_378 && 'S-378',
                      trainingData?.skills_courses_s_390 && 'S-390',
                      trainingData?.skills_courses_s_400 && 'S-400',
                      trainingData?.skills_courses_s_402 && 'S-402',
                      trainingData?.skills_courses_s_403 && 'S-403',
                      trainingData?.skills_courses_s_404 && 'S-404',
                      trainingData?.skills_courses_s_420 && 'S-420',
                      trainingData?.skills_courses_s_430 && 'S-430',
                      trainingData?.skills_courses_s_440 && 'S-440',
                      trainingData?.skills_courses_s_443 && 'S-443',
                      trainingData?.skills_courses_s_445 && 'S-445',
                      trainingData?.skills_courses_s_450 && 'S-450',
                      trainingData?.skills_courses_s_460 && 'S-460',
                      trainingData?.skills_courses_s_470 && 'S-470',
                      trainingData?.skills_courses_s_490 && 'S-490',
                      trainingData?.skills_courses_s_491 && 'S-491',
                      trainingData?.skills_courses_s_492 && 'S-492',
                      trainingData?.skills_courses_s_493 && 'S-493',
                    ],
                  ],
                ].map(([label, items]) => (
                  <tr key={label}>
                    <td>
                      <strong>{label}</strong>
                    </td>
                    <td>{items.filter(Boolean).join(', ') || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
      </div>

      {/* Modal: Create Qual */}

      <ReactModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        overlayClassName='alertPopup'
        style={customStylesQualModals}
      >
        <div id='statusPopup' className='popupModal'>
          <div className='modal-head'>
            <h4 className='modal-title'>Add Qualification</h4>
          </div>
          <form onSubmit={handleSubmitAddQual(handleOnSubmit)}>
            <div className='modal-body' style={{ textAlign: 'left' }}>
              <label>Qualification</label>
              <Controller
                rules={{ required: 'Qualification is required' }}
                name='quals_id'
                control={controlAddQual}
                render={({ field: { onChange }, fieldState }) => (
                  <div>
                    <Select
                      isDisabled={isSubmitting}
                      options={qualWithoutDates}
                      isClearable
                      onChange={(newDivision) => {
                        setSelectedOptionQualName(newDivision);
                        onChange(newDivision?.value);
                      }}
                      placeholder={'Select Qualification'}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      styles={errorStyles(errorsAddQual?.quals_id)}
                    />
                    {errorsAddQual?.quals_id && (
                      <div className='qual_error_container'>
                        {fieldState.error.message}
                      </div>
                    )}
                  </div>
                )}
              />
              {selectedOptionQualName != null && (
                <>
                  <div className='addqual_field_container'>
                    <label>Tsk Book initiated</label>
                    <Controller
                      name='tsk_book_ini_date'
                      control={controlAddQual}
                      render={({ field: { value, onChange } }) => {
                        const isDateExceeded =
                          value &&
                          moment(value, USDateFormat, true).isValid() &&
                          moment(value).isAfter(moment());

                        // Clear the input if the date exceeds the current date
                        if (isDateExceeded) {
                          onChange(null); // Clear the input by setting the value to null
                        }
                        return (
                          <DatePicker
                            dateFormat={USDateFormat}
                            maxDate={moment()} //max date shall not  be more than the current date
                            disabled={isSubmitting}
                            className='returnDateField'
                            name='tsk_book_ini_date'
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            selected={value}
                            onChange={onChange}
                            customInput={
                              <CustomDatePickerInput onChange={onChange} />
                            }
                            popperClassName='datePickerTop0'
                          />
                        );
                      }}
                    />
                  </div>
                  <div className='addqual_field_container'>
                    <label>Qualifying date</label>
                    <Controller
                      name='quals_date'
                      control={controlAddQual}
                      render={({ field: { value, onChange } }) => {
                        const isDateExceeded =
                          value &&
                          moment(value, USDateFormat, true).isValid() &&
                          moment(value).isAfter(moment());

                        // Clear the input if the date exceeds the current date
                        if (isDateExceeded) {
                          onChange(null); // Clear the input by setting the value to null
                        }
                        return (
                          <DatePicker
                            dateFormat={USDateFormat}
                            maxDate={moment()} //max date shall not  be more than the current date
                            disabled={isSubmitting}
                            className='returnDateField'
                            name='quals_date'
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            selected={value}
                            onChange={onChange}
                            customInput={<CustomDatePickerInput />}
                            popperClassName='datePickerTop0'
                          />
                        );
                      }}
                    />
                  </div>
                  <div className='addqual_field_container'>
                    <label>Expiration date</label>
                    <Controller
                      name='exp_date'
                      control={controlAddQual}
                      render={({ field: { value, onChange } }) => {
                        const isDateLess =
                          value &&
                          moment(value, USDateFormat, true).isValid() &&
                          moment(value).isBefore(moment());

                        // Clear the input if the date exceeds the current date
                        if (isDateLess) {
                          onChange(null); // Clear the input by setting the value to null
                        }
                        return (
                          <DatePicker
                            dateFormat={USDateFormat}
                            minDate={moment()} //minimum date shall not  be lesser than the current date
                            disabled={isSubmitting}
                            className='returnDateField'
                            name='exp_date'
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            selected={value}
                            onChange={onChange}
                            customInput={<CustomDatePickerInput />}
                            popperClassName='datePickerTop0'
                          />
                        );
                      }}
                    />
                  </div>
                  <div className='addqual_field_container'>
                    <label>Issuing Authority</label>
                    <Controller
                      name='issuing_authority'
                      control={controlAddQual}
                      render={({ field: { value, onChange } }) => (
                        <TextInput
                          disabled={isSubmitting}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </div>
                </>
              )}
              <div className='qual_btn_list'>
                <button
                  disabled={isSubmitting}
                  className='button grey mr-1'
                  onClick={closeSubmitModal}
                  type='button'
                >
                  Cancel
                </button>
                <button
                  disabled={isSubmitting}
                  className='button'
                  type='submit'
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </ReactModal>

      {/* Modal: Edit Qual */}

      <ReactModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        overlayClassName='alertPopup'
        style={customStylesQualModals}
      >
        <div id='statusPopup' className='popupModal'>
          <div className='modal-head'>
            <h4 className='modal-title'>Edit Qualification</h4>
          </div>
          <form
            onSubmit={handleSubmitEditQual((data) =>
              handleOnEdit(
                data,
                selectedQualifications[selectedIndex]?.qualification?.id,
              ),
            )}
          >
            <div className='modal-body' style={{ textAlign: 'left' }}>
              <label>Qualification</label>
              <Controller
                rules={{
                  required: 'Qualification name is required',
                }}
                name='quals_name'
                control={controlEditQual}
                render={({ field, fieldState }) => (
                  <div className='editqual_input_container'>
                    <input
                      type='text'
                      id='input1'
                      defaultValue={
                        selectedQualifications[selectedIndex]?.qualification
                          .value
                      }
                      onChange={field.onChange}
                      styles={errorStyles(errorsEditQual?.quals_name)}
                    />
                    {fieldState.error && (
                      <div className='qual_error_container'>
                        {fieldState.error.message}
                      </div>
                    )}
                  </div>
                )}
              />

              <label style={{ marginTop: 20 }}>Qualification Abbreviaton</label>
              <Controller
                rules={{
                  required: 'Qualification key is required',
                }}
                name='quals_short_form'
                control={controlEditQual}
                render={({ field, fieldState }) => (
                  <div className='editqual_input_container'>
                    <input
                      type='text'
                      id='input1'
                      defaultValue={
                        selectedQualifications[selectedIndex]?.qualification
                          ?.key
                      }
                      onChange={field.onChange}
                      styles={errorStyles(errorsEditQual?.quals_short_form)}
                    />
                    {fieldState.error && (
                      <div className='qual_error_container'>
                        {fieldState.error.message}
                      </div>
                    )}
                  </div>
                )}
              />

              <label style={{ marginTop: 20 }}>Color Code</label>
              <Controller
                rules={{ required: true }}
                name='color_code'
                control={controlEditQual}
                render={({ field: { onChange } }) => (
                  <Select
                    isDisabled={isSubmitting}
                    defaultValue={listColorCode?.find(
                      (option) =>
                        option.value ===
                        selectedQualifications[selectedIndex]?.qualification
                          .color_code,
                    )}
                    options={listColorCode}
                    onChange={(newDivision) => {
                      setSelectedOptionColorCode(newDivision);
                      onChange(newDivision?.value);
                    }}
                    placeholder={'Select Color Code'}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    styles={errorStyles(errorsEditQual?.color_code)}
                  />
                )}
              />
              <div className='qual_btn_list'>
                <button
                  disabled={isSubmitting}
                  className='button grey mr-1'
                  onClick={() => setIsEditModalOpen(false)}
                  type='button'
                >
                  Cancel
                </button>
                <button
                  disabled={isSubmitting}
                  className='button'
                  type='submit'
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </ReactModal>

      {/* Modal: Delete Qual */}
      <ReactModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        overlayClassName='alertPopup'
        style={customStylesQualModals}
      >
        <div id='statusPopup' className='popupModal'>
          <div className='modal-head'>
            <h4 className='modal-title-qual'>Remove Qualification</h4>
          </div>
          <form onSubmit={handleDeleteQualSubmit}>
            <div className='modal-body-qual' style={{ textAlign: 'left' }}>
              <p>
                Are you sure you want to remove this qualification from the
                profile?
              </p>
              <div className='qual_btn_list'>
                <button
                  disabled={isSubmitting}
                  className='button grey mr-1'
                  onClick={closeDeleteSubmitModal}
                  type='button'
                >
                  Cancel
                </button>
                <button
                  disabled={isSubmitting}
                  className='button'
                  type='submit'
                  style={{ backgroundColor: '#DA120B' }}
                >
                  Remove
                </button>
              </div>
            </div>
          </form>
        </div>
      </ReactModal>

      {/* Handle Update Modal */}

      <ReactModal
        isOpen={isUpdateModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        overlayClassName='alertPopup'
        style={customStylesUpdateQualModal}
      >
        <div id='statusPopup' className='popupModal'>
          <div className='modal-head'>
            <h4 className='modal-title-qual'>
              Are you sure you want to save the changes?
            </h4>
          </div>
          <div className='modal-body-qual'>
            <p>Saving the changes will update the data.</p>
            <div className='updateQual_btn_list'>
              <button
                disabled={isSubmitting}
                className='button grey mr-1'
                onClick={handleCancel}
                type='button'
              >
                Cancel
              </button>
              <button
                disabled={isSubmitting}
                className='button'
                onClick={() => handleUpdate()}
                type='submit'
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default UserQualifications;

const customStylesQualModals = {
  content: {
    maxWidth: 460,
    height: 'auto',
  },
};

const customStylesUpdateQualModal = {
  content: {
    width: 'auto',
    height: 'auto',
  },
};
