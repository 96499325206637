import React from "react";
import { default as NumberFormat } from "react-number-format";
import { connect } from "react-redux";
import { userActions } from "../_actions";
import { config } from "../_helpers";
import { createProfileActions, permissionActions } from "../_actions";
import DatePicker from "react-datepicker";
import moment from "moment";
import Dropzone from "react-dropzone";
import Cropper from "react-cropper";
import ReactModal from "react-modal";
import { AlertPopup } from "../_components/AlertPopup";
import { withRouter } from "react-router-dom";
import RadioButton from "../Docs/Components/RadioButton";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";
import Accordian from "_components/Accordian";

ReactModal.setAppElement("#app");

const UserRoles = {
  admin: 1,
  supAdmin: 2,
};

const DATE_FORMAT = "YYYY-MM-DD";
const verification_DATE_FORMAT = "MM-DD-YYYY";
const PERMISSION_FOR_EXPIRE_ASSIGNED_DATE = "Manage Expiry/Assigned Dates";
const INVALID_DATE = "Invalid date";
class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        fname: "",
        mname: "",
        lname: "",
        phone: "",
        secphone: "",
        fireid: "",
        ICANumber: "",
        crew_identifier_id: "",
        primary_crew_identifier_id: "",
        resourceId: "",
        email: "",
        reEmailId: "",
        password: "",
        mailingAddress: "",
        mailingCity: "",
        mailingState: "",
        mailingZip: "",
        tmpMailingAddress: "",
        tmpMailingCity: "",
        tmpMailingState: "",
        tmpMailingZip: "",
        chkSameAddress: false,
        physicalAddress: "",
        physicalCity: "",
        physicalState: "",
        physicalZip: "",
        sex: "",
        emgc1FName: "",
        emgc1LName: "",
        // emgc1PhoneCC: "",
        emgc1Phone: "",
        emgc1Relation: "",
        emgc2FName: "",
        emgc2LName: "",
        // emgc2PhoneCC: "",
        emgc2Phone: "",
        emgc2Relation: "",
        driversLicense: "",
        DLState: "",
        MSPA_ExpDate: "",
        pantSize: "",
        inseam_pant_size: "",
        shirtSize: "",
        eeoClassification: "",
        userType: "",
        // certifiedEMT: "",
        // generalQualification: "",
        lsa: "",
        weight: "",
        // currentQualification: "",
        resourcetype: this.props.type,
        position: this.props.position,
        profile_photo: "",
        dolBlueCardExpiry: "",
        fingerPrintExpiry: "",
        physicalExpiry: "",
        dlExpiry: "",
        firstAidExpiry: "",
        rt130Expiry: "",
        sexualHarassmentExpiry: "",
        fallerQuals: "",
        securityCodeAssigned: "",
        creditCardAssigned: "",
        fuelPinAssigned: "",
        keyFob: "",
        keyFobAssigned: "",
        userattributes: {
          drug_screening_doc: false,
          i9_doc: false,
          onboarding_doc: false,
          drug_screening_doc_date: null,
          i9_doc_date: null,
          rehire_docs_approved_at: null,
          docs_approved_at: null,
          // onboarding_doc_date:null,
        },
        dateOfBirth: "",
        ssn: "",
        allergies: "",
        prescriptions: "",
      },
      permList: [],
      resources: [],
      pantSize: [],
      inseamPantSize: [],
      Crews: [],
      shirtSize: [],
      eeoClassification: [],
      generalQualificationList: [],
      lsa: [],
      checkboxesChecked: [],
      alertPop: false,
      saveClick: false,
      saveBtnClicked: false,
    };
    // this.goBack = this.goBack.bind(this);
    // this.ProfilePic = this.ProfilePic.bind(this);
    // this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleVerifyDocCheckbox = this.handleVerifyDocCheckbox.bind(this);
    this.handleVerifyDocDate = this.handleVerifyDocDate.bind(this);
    this.onClearDate = this.onClearDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.apply = this.apply.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.goBack = this.goBack.bind(this);
    this.generateAttrCheckbox = this.generateAttrCheckbox.bind(this);
  }

  componentDidMount() {
    if (this.props.match.path === "/profile/:hash") {
      var hash = "?hash=" + this.props.match.params.hash;
      this.props.dispatch(userActions.getProfile(hash));
    } else {
      this.props.dispatch(userActions.loginProfile());
    }
    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(createProfileActions.getAll());
    this.componentWillReceiveProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { user, permission } = nextProps;

    /**
     * The onboarding_doc_date is considered as docs_approved_at and the checkbox value is onboarding_doc, use the same for default values
     * make sure to pass onboarding_date value in the docs_approved_at keyword when saving edit profile
     * Backend can only send/receive onboarding_date value in docs_approved_at keyword and its checkbox value in onboarding_doc
     */

    const modifyingProfile = this.props.location.pathname.includes("/profile/")
      ? this.props?.user?.items?.data
      : this.props?.user?.profile?.data;

    if (modifyingProfile?.userattribute) {
      this.setState({
        rehire_docs_status: user?.items?.data?.rehire_docs_status,
        userattributes: {
          ...modifyingProfile.userattribute,
          drug_screening_doc_date: moment(
            modifyingProfile?.userattribute?.drug_screening_doc_date,
          ).isValid()
            ? moment(
                modifyingProfile?.userattribute?.drug_screening_doc_date,
              )?.format(verification_DATE_FORMAT)
            : "",
          i9_doc_date: moment(
            modifyingProfile?.userattribute?.i9_doc_date,
          ).isValid()
            ? moment(modifyingProfile?.userattribute?.i9_doc_date)?.format(
                verification_DATE_FORMAT,
              )
            : "",
          docs_approved_at: moment(
            modifyingProfile?.userattribute?.docs_approved_at,
          ).isValid()
            ? moment(modifyingProfile?.userattribute?.docs_approved_at)?.format(
                verification_DATE_FORMAT,
              )
            : "",

          // rehire_docs_approved_at: moment(modifyingProfile.rehire_docs_approved_at)?.format(verification_DATE_FORMAT),
          // docs_approved_at: moment(modifyingProfile.docs_approved_at)?.format(verification_DATE_FORMAT),

          drug_screening_doc:
            modifyingProfile?.userattribute?.drug_screening_doc === 1,
          i9_doc: modifyingProfile?.userattribute?.i9_doc === 1,
          onboarding_doc: modifyingProfile?.userattribute?.onboarding_doc === 1,
        },
      });
    }

    let userData;
    const resources = [],
      pantSize = [],
      inseamPantSize = [],
      shirtSize = [],
      eeoClassification = [],
      generalQualificationList = [],
      lsa = [];

    let profile,
      resource = [],
      Crews = [],
      response = nextProps.listAll,
      attributes = {};

    if (permission.permissions) {
      let permList = permission.permissions.data;

      this.setState({
        permList,
      });
    }

    if (user.saveProfile) {
      var errMessage;
      if (typeof user.saveProfile.message === "object") {
        for (var key in user.saveProfile.message) {
          errMessage = JSON.parse(
            JSON.stringify(user.saveProfile.message[key][0]),
          );
        }
      } else {
        errMessage = JSON.parse(JSON.stringify(user.saveProfile.message));
      }
      if (this.state.saveClick && user.saveProfile.success) {
        this.setState({
          alertPop: true,
          alertHead: "Success",
          alertMsg: errMessage,
          alertFunction: this.cancel.bind(this),
        });
      } else if (this.state.saveClick) {
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: errMessage,
          alertFunction: "",
        });
      }
    }

    if (this.props.match.path === "/profile/:hash") {
      if (user.items) {
        userData = user.items;
      }
    } else {
      if (user.profile) {
        userData = user.profile;
      }
    }

    // condition to check the logged in user is viewing his own profile(user.items is not available here)
    if (this.props.match.path === "/settings") {
      userData = user.profile;
    }

    if (userData) {
      profile = userData.data;
      var mspa_exp_date_Obj = "";
      this.setState({ profile });
      if (profile.mspa_exp_date) {
        mspa_exp_date_Obj = moment(profile.mspa_exp_date);
      } else {
        mspa_exp_date_Obj = "";
      }

      if (profile.profile_photo === "") {
        this.setState({
          user: {
            ...this.state.user,
            profile_photo: config.apiUrl + "/" + profile.profile_photo,
          },
          cropResult: config.apiUrl + "/" + config.altImg,
        });
      } else {
        this.setState({
          user: {
            ...this.state.user,
            profile_photo: config.apiUrl + "/" + config.altImg,
          },
          cropResult: config.apiUrl + "/" + profile.profile_photo,
        });
      }

      // setting user attributes to this.state.user
      if (profile && response) {
        //generating user attributes checkbox
        if (profile.userattribute) {
          for (let [key, value] of Object.entries(profile.userattribute)) {
            value === 1 ? (attributes[key] = true) : (attributes[key] = false);
          }
        }

        Crews = [...response.FireFighter, ...response.AdminStaff];

        if (nextProps.type === "FireFighter") {
          resource = response.FireFighter;
        } else if (nextProps.type === "AdminStaff") {
          resource = response.AdminStaff;
        }
        if (resource) {
          resource.map((item, index) => {
            resources.push(
              <option className="position-option" value={item.id} key={index}>
                {item.resource_name}
              </option>,
            );
            return resources;
          });
        }
        if (response.PantSize) {
          response.PantSize.map((item, index) => {
            pantSize.push(
              <option className="pant-size-option" value={item.id} key={index}>
                {item.value}
              </option>,
            );
            return pantSize;
          });
        }
        if (response.ListInseamPantSize) {
          response.ListInseamPantSize.map((item, index) => {
            inseamPantSize.push(
              <option className="pant-size-option" value={item.id} key={index}>
                {item.value}
              </option>,
            );
            return inseamPantSize;
          });
        }
        if (response.ShirtSize) {
          response.ShirtSize.map((item, index) => {
            shirtSize.push(
              <option className="shirt-size-option" value={item.id} key={index}>
                {item.value}
              </option>,
            );
            return shirtSize;
          });
        }
        if (response.ListEeoClassification) {
          response.ListEeoClassification.map((item, index) => {
            eeoClassification.push(
              <option
                className="eeo-classification-option"
                value={item.id}
                key={index}
              >
                {item.title}
              </option>,
            );
            return eeoClassification;
          });
        }

        if (response.GeneralQualification) {
          response.GeneralQualification.map((item, index) => {
            generalQualificationList.push(
              <option
                className="qualification-option"
                value={item.id}
                key={index}
              >
                {item.name}
              </option>,
            );
            return generalQualificationList;
          });
        }
        if (response.Lsa) {
          response.Lsa.map((item, index) => {
            lsa.push(
              <option className="lsa-option" value={item.id} key={index}>
                {item.value}
              </option>,
            );
            return lsa;
          });
        }

        this.setState({
          hash: profile.hash,
          user: {
            ...this.state.user,
            ...attributes,
            id: profile.id,
            fname: profile.first_name,
            mname: profile.middle_name,
            lname: profile.last_name,
            phone: profile.phone,
            secphone: profile.second_phone,
            fireid: profile.fire_id,
            ICANumber: profile.ica_number,
            crew_identifier_id: profile.crew_identifier_id || "",
            primary_crew_identifier_id:
              profile.primary_crew_identifier_id || "",
            // resourceId: "",
            email: profile.email,
            reEmailId: profile.email,
            // password: "",
            mailingAddress: profile.mailing_address.address,
            mailingCity: profile.mailing_address.city,
            mailingState: profile.mailing_address.state,
            mailingZip: profile.mailing_address.zip,
            // chkSameAddress: false,
            physicalAddress: profile.physical_address.address,
            physicalCity: profile.physical_address.city,
            physicalState: profile.physical_address.state,
            physicalZip: profile.physical_address.zip,
            sex: profile.gender,
            emgc1FName: profile.emgcontact1.fname,
            emgc1LName: profile.emgcontact1.lname,
            // emgc1PhoneCC: "",
            emgc1Phone: profile.emgcontact1.phone,
            emgc1Relation: profile.emgcontact1.relation,
            emgc2FName: profile.emgcontact2.fname,
            emgc2LName: profile.emgcontact2.lname,
            // emgc2PhoneCC: "",
            emgc2Phone: profile.emgcontact2.phone,
            emgc2Relation: profile.emgcontact2.relation,
            driversLicense: profile.drivers_license,
            DLState: profile.DLState,
            MSPA_ExpDate: mspa_exp_date_Obj,
            pantSize: profile.pant_size,
            inseam_pant_size: profile.inseam_pant_size
              ? profile.inseam_pant_size
              : "",
            shirtSize: profile.shirt_size,
            eeoClassification: profile.eeo_classification_id,
            // certifiedEMT: profile.certified_emt,
            // generalQualification: profile.general_qualification_id,
            lsa: profile.lsa_id,
            weight: profile.weight,
            dateOfBirth: profile.dob,
            ssn: profile.ssn,
            allergies: profile.allergies,
            prescriptions: profile.prescriptions,
            dolBlueCardExpiry:
              (profile &&
                profile.useritemdates &&
                profile.useritemdates.dol_blue_card_expiry) ||
              "",
            fingerPrintExpiry:
              (profile &&
                profile.useritemdates &&
                profile.useritemdates.finger_print_expiry) ||
              "",
            physicalExpiry:
              (profile &&
                profile.useritemdates &&
                profile.useritemdates.physical_expiry) ||
              "",
            dlExpiry:
              (profile &&
                profile.useritemdates &&
                profile.useritemdates.dl_expiry) ||
              "",
            firstAidExpiry:
              (profile &&
                profile.useritemdates &&
                profile.useritemdates.first_aid_expiry) ||
              "",
            rt130Expiry:
              (profile &&
                profile.useritemdates &&
                profile.useritemdates.rt_130_expiry) ||
              "",
            sexualHarassmentExpiry:
              (profile &&
                profile.useritemdates &&
                profile.useritemdates.sexual_harassment_expiry) ||
              "",
            fallerQuals: profile?.useritemdates?.faller_quals ?? "",
            securityCodeAssigned:
              (profile &&
                profile.useritemdates &&
                profile.useritemdates.security_code_assigned) ||
              "",
            creditCardAssigned:
              (profile &&
                profile.useritemdates &&
                profile.useritemdates.credit_card_assigned) ||
              "",
            fuelPinAssigned:
              (profile &&
                profile.useritemdates &&
                profile.useritemdates.fuel_pin_assigned) ||
              "",
            keyFob:
              (profile &&
                profile.useritemdates &&
                profile.useritemdates.key_fob) ||
              "",
            keyFobAssigned:
              (profile &&
                profile.useritemdates &&
                profile.useritemdates.key_fob_assigned) ||
              "",
            personal_licence_plate_number:
              profile?.personal_licence_plate_number ?? "",
            plpn_make_or_model: profile?.plpn_make_or_model ?? "",
            personal_licence_plate_number_check:
              profile?.personal_licence_plate_number_check ?? "",
            userType: profile.user_type ?? "",
          },
          maddress: false,
          mcity: false,
          mstate: false,
          mzip: false,
          resources,
          pantSize,
          inseamPantSize,
          Crews,
          shirtSize,
          eeoClassification,
          generalQualificationList,
          lsa,
          ...attributes,
        });
      }
    }
  }

  componentDidUpdate() {}

  onClearDate(datePickerKey) {
    this.setState({
      userattributes: {
        ...this.state.userattributes,
        [datePickerKey]: "",
      },
    });
  }

  generateAttrCheckbox(attrs) {
    if (!this?.state?.userattributes) return;
    let userattributes = [];
    for (let [key, value] of Object.entries(this?.state?.userattributes)) {
      let name = "";
      let datePickerKey = "";
      switch (key) {
        case "drug_screening_doc":
          name = "Drug Screening";
          datePickerKey = "drug_screening_doc_date";
          break;
        case "i9_doc":
          name = "ID/I9";
          datePickerKey = "i9_doc_date";
          break;
        case "onboarding_doc":
          name = "Onboarding Documents";
          // datePickerKey = this?.state?.userattributes === 2 ? "rehire_docs_approved_at" : "docs_approved_at"
          datePickerKey = "docs_approved_at";
          break;
        default:
          break;
      }

      let datePickerValue = null;

      if (this?.state?.userattributes?.[datePickerKey]) {
        const formattedDateString = this?.state?.userattributes?.[
          datePickerKey
        ]?.replace(/-/g, "/");
        const dateObject = new Date(formattedDateString);
        datePickerValue = moment(dateObject, DATE_FORMAT);
      } else {
        datePickerValue = null;
      }

      name &&
        userattributes.push(
          <div
            className="current_qualification_item current_qualification_item_alignment"
            key={key}
          >
            <div className="current_qualification_item_alignment_left verification">
              <input
                type="checkbox"
                id={key}
                name={key}
                defaultChecked={value === true}
                onChange={(e) => this.handleVerifyDocCheckbox(e.currentTarget)}
              />
              <label htmlFor={key}>
                <span />
              </label>
              <span
                className="wpcf7-list-item-label mr-1"
                style={{ wordBreak: "break-all" }}
              >
                {name}
              </span>
            </div>
            <div className="current_qualification_item_alignment_right verification">
              <DatePicker
                name={datePickerKey}
                selected={
                  moment(datePickerValue)?.isValid()
                    ? moment(datePickerValue)
                    : null
                }
                onChange={(date) =>
                  this.handleVerifyDocDate(date, datePickerKey)
                }
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={moment()}
                // minDate={key === "onboarding_doc" ? moment() : null}
                // disabled={key === "onboarding_doc"}
              />
              <span
                className="clear-btn"
                onClick={() => this.onClearDate(datePickerKey)}
              >
                {" "}
                Clear
              </span>
            </div>
          </div>,
        );
    }

    return userattributes;
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  handleChange(e) {
    var name = e.target.name,
      value = e.target.value;
    const { user } = this.state;
    // if(value !== ""){

    this.setState(
      {
        user: {
          ...this.state.user,
          [name]: value,
        },
      },
      () => {
        var address = [
            "physicalAddress",
            "physicalCity",
            "physicalState",
            "physicalZip",
          ],
          ob = {};
        if (address.indexOf(name) > -1 && this.state.user.chkSameAddress) {
          ob = {
            mailingAddress: this.state.user.physicalAddress,
            mailingCity: this.state.user.physicalCity,
            mailingState: this.state.user.physicalState,
            mailingZip: this.state.user.physicalZip,
          };
        }
        this.setState(
          {
            user: {
              ...this.state.user,
              ...ob,
            },
          },
          () => {},
        );
      },
    );
    e.target.setAttribute("errors", "");
  }

  apply(file) {
    this.setState({
      imgPreview: window.URL.createObjectURL(file),
      uploadedFile: file,
    });

    // var pic = file.preview;
    // var pic_name = pic.split('/').pop();
    // this.setState({  })
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  handleVerifyDocCheckbox(el) {
    let key = el.name;
    let userattributes = { ...this.state.userattributes };

    if (el.checked) {
      userattributes[key] = true;
      // this.setState({ [key]: true });
    } else {
      userattributes[key] = false;
      // this.setState({ [key]: false });
    }

    this.setState({ userattributes });

    // if (el.checked) {
    //   this.setState({ [key]: true });
    // } else {
    //   this.setState({ [key]: false });
    // }
  }

  handleVerifyDocDate(date, key) {
    let userattributes = { ...this.state.userattributes };
    userattributes[key] = date?.format(DATE_FORMAT);
    this.setState({ userattributes });
  }

  handleAttributes(el) {
    let key = el.name;
    let user = this.state.user;
    if (el.checked) {
      this.setState({
        user: { ...this.state.user, [key]: true },
      });
    } else {
      this.setState({
        user: { ...user, [key]: false },
      });
    }
  }

  toggleAddressCheckbox = () => {
    const chkSameAddressCheckbox = document.getElementById("chkSameAddress");
    chkSameAddressCheckbox.checked = !chkSameAddressCheckbox.checked; // Toggle the checkbox
    this.handleAddress({ target: chkSameAddressCheckbox }); // Call handleAddress function with checkbox event
  };

  handleAddress(e) {
    var name = e.target.name,
      value = e.target.value;
    if (e.target.checked) {
      this.setState({
        user: {
          ...this.state.user,
          tmpMailingAddress: this.state.user.mailingAddress,
          tmpMailingCity: this.state.user.mailingCity,
          tmpMailingState: this.state.user.mailingState,
          tmpMailingZip: this.state.user.mailingZip,
          mailingAddress: this.state.user.physicalAddress,
          mailingCity: this.state.user.physicalCity,
          mailingState: this.state.user.physicalState,
          mailingZip: this.state.user.physicalZip,
          chkSameAddress: true,
        },
        maddress: true,
        mcity: true,
        mstate: true,
        mzip: true,
      });
      if (document.getElementById("mailingAddress"))
        document.getElementById("mailingAddress").setAttribute("errors", "");

      if (document.getElementById("mailingCity"))
        document.getElementById("mailingCity").setAttribute("errors", "");

      if (document.getElementById("mailingState"))
        document.getElementById("mailingState").setAttribute("errors", "");

      if (document.getElementById("mailingZip"))
        document.getElementById("mailingZip").setAttribute("errors", "");
    } else {
      this.setState({
        maddress: false,
        mcity: false,
        mstate: false,
        mzip: false,
        fnameError: "",

        user: {
          ...this.state.user,
          mailingAddress: this.state.user.tmpMailingAddress,
          mailingCity: this.state.user.tmpMailingCity,
          mailingState: this.state.user.tmpMailingState,
          mailingZip: this.state.user.tmpMailingZip,
          chkSameAddress: false,
        },
      });
    }
  }

  onImageDrop(files) {
    /*
    Validation
    */
    var allowed = ["png", "jpg", "jpeg"],
      file = files[0] || null;
    if (!file) {
      return this.setState({
        alertPop: true,
        alertHead: "Error",
        alertMsg: "Please select image file",
        alertFunction: "",
      });
    }
    var name = file.name,
      extension = name.split(".").pop().toLowerCase();
    if (allowed.indexOf(extension) === -1) {
      // Invalid file format.
      this.setState({
        alertPop: true,
        alertHead: "Error",
        alertMsg: "Invalid file format!",
        alertFunction: "",
      });
      return;
    }

    this.setState({
      imgPreview: files[0].preview,
      statusOpen: true,
    });

    this.handleImageUpload(files[0]);
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }

  cropImage() {
    this.cropper.getCroppedCanvas().toBlob(
      (blob) => {
        var imgData = this.cropper.getData();
        this.setState({
          cropResult: this.cropper.getCroppedCanvas().toDataURL(),
          statusOpen: false,
          imgData,
          uploadedFile: blob,
        });
      },
      "image/jpeg",
      0.45,
    );
  }
  _crop() {
    // image in dataUrl
    //
  }

  imageLoad() {
    this.setState({
      statusOpen: true,
    });
  }

  handleImageUpload(file) {
    // this.setState({ imgPreview : file.preview })

    this.setState({ imgPreview: file.preview });

    var pic = file.preview;
    var pic_name = pic.split("/").pop();
    this.setState({ picName: pic.split("/").pop(), uploadedFile: file });
  }

  handleDateChange(date) {
    this.setState({
      user: {
        ...this.state.user,
        MSPA_ExpDate: date,
      },
    });
  }

  handleOnChange(key, value) {
    let user = { ...this.state.user };
    user[key] = value === INVALID_DATE ? "" : value;
    this.setState({ user });
  }

  validate(e) {
    let isError = false;

    if (this.state.user.weight === "") {
      isError = true;
      this.setState({
        weightError: "error",
      });
      // this.weightInput.focus();
    }
    // if (this.state.user.generalQualification === "") {
    //   isError = true;
    //   this.setState({
    //     QualificationError: "error",
    //   });
    //   // this.QualificationInput.focus();
    // }
    if (this.state.user.emgc2Relation === "") {
      isError = true;
      this.setState({
        emgc2RelationError: "error",
      });
      // this.emgc2RelationInput.focus();
    }
    if (this.state.user.emgc2Phone === "") {
      isError = true;
      this.setState({
        emgc2PhoneError: "error",
      });
      // this.emgc2PhoneInput.focus();
    }
    if (this.state.user.emgc2LName === "") {
      isError = true;
      this.setState({
        emgc2LNameError: "error",
      });
      // this.emgc2LNameInput.focus();
    }
    if (this.state.user.emgc2FName === "") {
      isError = true;
      this.setState({
        emgc2FNameError: "error",
      });
      // this.emgc2FNameInput.focus();
    }
    if (this.state.user.emgc1Relation === "") {
      isError = true;
      this.setState({
        emgc1RelationError: "error",
      });
      // this.emgc1RelationInput.focus();
    }
    if (this.state.user.emgc1Phone === "") {
      isError = true;
      this.setState({
        emgc1PhoneError: "error",
      });
      // this.emgc1PhoneInput.focus();
    }
    if (this.state.user.emgc1LName === "") {
      isError = true;
      this.setState({
        emgc1LNameError: "error",
      });
      // this.emgc1LNameInput.focus();
    }
    if (this.state.user.emgc1FName === "") {
      isError = true;
      this.setState({
        emgc1FNameError: "error",
      });
      // this.emgc1FNameInput.focus();
    }
    if (this.state.user.sex === "") {
      isError = true;
      this.setState({
        sexError: "error",
      });
      // this.sexInput.focus();
    }
    if (this.state.user.physicalZip === "") {
      isError = true;
      this.setState({
        physicalZipError: "error",
      });
      // this.physicalZipInput.focus();
    }

    if (this.state.user.physicalState === "") {
      isError = true;
      this.setState({
        physicalStateError: "error",
      });
      // this.physicalStateInput.focus();
    }

    if (this.state.user.physicalCity === "") {
      isError = true;
      this.setState({
        physicalCityError: "error",
      });
      // this.physicalCityInput.focus();
    }
    if (this.state.user.physicalAddress === "") {
      isError = true;
      this.setState({
        physicalAddressError: "error",
      });
      // this.physicalAddressInput.focus();
    }
    if (this.state.user.mailingZip === "") {
      isError = true;
      this.setState({
        mailingZipError: "error",
      });
      // this.mailingZipInput.focus();
    }
    if (this.state.user.mailingState === "") {
      isError = true;
      this.setState({
        mailingStateError: "error",
      });
      // this.mailingStateInput.focus();
    }
    if (this.state.user.mailingCity === "") {
      isError = true;
      this.setState({
        mailingCityError: "error",
      });
      // this.mailingCityInput.focus();
    }
    if (this.state.user.mailingAddress === "") {
      isError = true;
      this.setState({
        mailingAddressError: "error",
      });
      this.mailingInput.focus();
    }
    if (this.state.user.password === "") {
      isError = true;
      this.setState({
        passwordError: "error",
      });
      // this.passwordInput.focus();
    }
    if (this.state.user.reEmailId === "") {
      isError = true;
      this.setState({
        reEmaiError: "error",
      });
      // this.reEmailInput.focus();
    }
    if (
      this.state.user.reEmailId !== "" &&
      this.state.reEmailId !== this.state.emailId
    ) {
      isError = true;
      this.setState({
        reEmaiError: "error",
      });
      // this.reEmailInput.focus();
    }
    if (this.state.user.email === "") {
      isError = true;
      this.setState({
        emailError: "error",
      });
      // this.emailInput.focus();
    }
    if (this.state.user.resourceId === "") {
      isError = true;
      this.setState({
        ResError: "error",
      });
      // this.resourceInput.focus();
    }
    if (this.state.user.phone === "") {
      isError = true;
      this.setState({
        phoneError: "error",
      });
      // this.phoneInput.focus();
    }
    if (this.state.user.lname === "") {
      isError = true;
      this.setState({
        lnameError: "error",
      });
      // this.lnameInput.focus();
    }
    if (this.state.user.fname === "") {
      isError = true;
      this.setState({
        fnameError: "error",
        fnameError1: "fnameError",
      });
      // this.fnameInput.focus();
    }

    return isError;
  }
  IsValidEmail(email) {
    var expr =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return expr.test(email);
  }
  //
  save(e) {
    e.preventDefault();
    const hasPermissionForVerification =
      this.state.permList.includes("Manage Docs");

    // if (hasPermissionForVerification) {
    //   if (
    //     (this.state.userattributes?.drug_screening_doc &&
    //       !this.state.userattributes?.drug_screening_doc_date) ||
    //     (this.state.userattributes?.i9_doc &&
    //       !this.state.userattributes?.i9_doc_date) ||
    //     (this.state.userattributes?.onboarding_doc &&
    //       !this.state.userattributes?.docs_approved_at)
    //   ) {
    //     return toast.error(
    //       "You have selected the checkbox for submitted and verified but have not selected the corresponding dates!"
    //     );
    //   }

    //   if (
    //     (!this.state.userattributes?.drug_screening_doc &&
    //       this.state.userattributes?.drug_screening_doc_date) ||
    //     (!this.state.userattributes?.i9_doc &&
    //       this.state.userattributes?.i9_doc_date) ||
    //     (!this.state.userattributes?.onboarding_doc &&
    //       this.state.userattributes?.docs_approved_at)
    //   ) {
    //     return this.setState({
    //       alertPop: true,
    //       alertHead: "Error",
    //       alertMsg:
    //         "The date for submitted and verified is added but the checkbox is left blank, either select the checkbox or clear the date.",
    //       alertFunction: "",
    //     });
    //   }
    // }

    const err = false; // this.validate();
    this.setState({ saveClick: true, saveBtnClicked: true });
    let data = new FormData();
    if (!err) {
      const { user } = this.state;
      const excludedKeys = [
        "userattributes",
        "drug_screening_doc",
        "drug_screening_doc_date",
        "i9_doc",
        "i9_doc_date",
        "onboarding_doc",
        "onboarding_doc_date",
        "rehire_docs_approved_at",
        "docs_approved_at",
      ];

      for (var key in user) {
        if (!excludedKeys.includes(key)) {
          data.append(key, user[key]);
        }
      }

      data.append(
        "drug_screening_doc",
        this.state?.userattributes?.drug_screening_doc,
      );
      data.append("i9_doc", this.state?.userattributes?.i9_doc);
      data.append("onboarding_doc", this.state?.userattributes?.onboarding_doc);
      data.append(
        "drug_screening_doc_date",
        moment(this.state?.userattributes?.drug_screening_doc_date).isValid()
          ? moment(
              this.state?.userattributes?.drug_screening_doc_date?.replace(
                /-/g,
                "/",
              ),
            ).format(DATE_FORMAT)
          : "",
      );
      data.append(
        "i9_doc_date",
        moment(this.state?.userattributes?.i9_doc_date).isValid()
          ? moment(
              this.state?.userattributes?.i9_doc_date?.replace(/-/g, "/"),
            ).format(DATE_FORMAT)
          : "",
      );
      data.append(
        "docs_approved_at",
        moment(this.state?.userattributes?.docs_approved_at).isValid()
          ? moment(
              this.state?.userattributes?.docs_approved_at?.replace(/-/g, "/"),
            ).format(DATE_FORMAT)
          : "",
      );

      if (this.state.uploadedFile !== undefined) {
        data.append("profilePic", this.state.uploadedFile);
      }

      if (this.props.match.path === "/profile/:hash") {
        this.props.dispatch(
          userActions.editProfile(data, "?hash=" + this.state.hash),
        );
      } else {
        this.props
          .dispatch(userActions.editSelfProfile(data))
          .then(() => {
            this.setState({ saveBtnClicked: false });
          })
          .catch(() => {
            this.setState({ saveBtnClicked: false });
          });
      }
    } else {
      this.setState({ saveBtnClicked: false });
    }
  }

  cancel() {
    this.props.cancel();
  }

  goBack() {
    this.props.goBack(this.props.id);
  }

  checkPermissionManageProfile() {
    if (
      this.props.user.profile.data.is_admin > 0 ||
      this.props.permission.permissions.data.includes(
        "Manage Employee Profile Fields",
      )
    ) {
      return true;
    } else return false;
  }

  render() {
    const { user } = this.props;
    let userData, profile;
    if (userData) {
      userData = user.items;
    } else if (user.profile) {
      userData = user.profile;
    }
    if (userData) {
      profile = userData.data;
    }

    var img = "";
    if (this.state.profile && this.state.profile.profile_photo) {
      img = config.apiUrl + "/" + this.state.profile.profile_photo;
    } else {
      img = config.apiUrl + "/" + config.altImg;
    }
    var img_preview = "";
    if (this.state.imgPreview) {
      img_preview = this.state.imgPreview;
    } else if (this.state.profile && this.state.profile.profile_photo) {
      img_preview = config.apiUrl + "/" + this.state.profile.profile_photo;
    } else {
      img_preview = config.apiUrl + "/" + config.altImg;
    }

    return (
      <div className="content_div profile_edit">
        <div className="btn_list">
          {user.loading && this.state.saveClick && (
            <img
              className="login_loader"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              alt="loading"
            />
          )}
          <button
            className="button save_profile"
            onClick={this.save.bind(this)}
            disabled={this.state.saveBtnClicked}
          >
            Save Profile
          </button>
          <button className="button grey" onClick={this.cancel.bind(this)}>
            Cancel
          </button>
        </div>
        <div className="profile_edit_wrapper profile_edit_wrapper--sec">
          {this.state.permList.includes("Update Profile Picture") ? (
            <div className="upload_or_drag edit_profile_pic">
              <Dropzone
                id="file_name"
                name="file_name"
                multiple={false}
                accept="image/*"
                onDrop={this.onImageDrop.bind(this)}
              >
                <p>Upload or Drag/Drop Here</p>
                {this.state.cropResult && (
                  <img
                    style={{ width: "100%" }}
                    onError={this.onImageError.bind(this)}
                    className="imagePreview"
                    src={this.state.cropResult}
                    alt="cropped image"
                  />
                )}
              </Dropzone>
            </div>
          ) : (
            this.state.cropResult && (
              <img
                style={{
                  width: "100%",
                  marginLeft: "20px",
                  borderRadius: "0%",
                }}
                onError={this.onImageError.bind(this)}
                className="profilePicture"
                src={this.state.cropResult}
                alt="cropped image"
              />
            )
          )}

          <div className="label_bar general">
            <h2>Personal Info</h2>
            <div className="personal-info-wrapper">
              <div className="personal-info-container">
                <div className="formField">
                  <label>First Name</label>
                  <input
                    type="text"
                    ref={(input) => {
                      this.fnameInput = input;
                    }}
                    name="fname"
                    value={this.state.user.fname}
                    errors={this.state.fnameError}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="formField">
                  <label>Middle Name</label>
                  <input
                    type="text"
                    ref={(input) => {
                      this.mnameInput = input;
                    }}
                    name="mname"
                    value={this.state.user.mname}
                    errors={this.state.mnameError}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="formField">
                  <label>Last Name</label>
                  <input
                    type="text"
                    ref={(input) => {
                      this.lnameInput = input;
                    }}
                    name="lname"
                    errors={this.state.lnameError}
                    value={this.state.user.lname}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="formField">
                  <label>Cell Phone (Visible to all Staff)</label>
                  <NumberFormat
                    format="###-###-####"
                    mask="_"
                    onChange={this.handleChange}
                    ref={(input) => {
                      this.phoneInput = input;
                    }}
                    name="phone"
                    errors={this.state.phoneError}
                    value={this.state.user.phone}
                  />
                </div>
                <div className="formField">
                  <label>Secondary Phone</label>
                  <NumberFormat
                    format="###-###-####"
                    mask="_"
                    onChange={this.handleChange}
                    name="secphone"
                    value={
                      this.state.user.secphone !== "null"
                        ? this.state.user.secphone
                        : ""
                    }
                  />
                </div>
                <div className="formField">
                  <label>Firestorm ID #</label>
                  <input
                    type="text"
                    name="fireid"
                    value={
                      this.state.user.fireid !== "null"
                        ? this.state.user.fireid
                        : ""
                    }
                    onChange={this.handleChange}
                    disabled={!this.checkPermissionManageProfile()}
                    className={
                      this.checkPermissionManageProfile()
                        ? ""
                        : "disabled-for-employee"
                    }
                  />
                </div>
                <div className="formField">
                  <label>ICA #</label>
                  <input
                    type="text"
                    name="ICANumber"
                    value={
                      this.state.user.ICANumber !== "null"
                        ? this.state.user.ICANumber
                        : ""
                    }
                    onChange={this.handleChange}
                    disabled={!this.checkPermissionManageProfile()}
                    className={
                      this.checkPermissionManageProfile()
                        ? ""
                        : "disabled-for-employee"
                    }
                  />
                </div>
                {this.state.permList.some((permission) =>
                  [
                    "Assign Staff",
                    "Assign Your Own Crew",
                    "Assign Entire Company",
                  ].includes(permission),
                ) ? (
                  <div className="formField">
                    <label>Primary Crew</label>
                    <select
                      name="primary_crew_identifier_id"
                      value={this.state.user.primary_crew_identifier_id || ""}
                      onChange={this.handleChange}
                    >
                      <option key="0" value="">
                        Select
                      </option>
                      {this.state.Crews.map((item, index) => {
                        return (
                          <option key={index + 1} value={item.id}>
                            {item.resource_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ) : (
                  ""
                )}
                <div className="formField">
                  <label>Email (Visible to all Staff)</label>
                  <input
                    type="text"
                    name="email"
                    value={this.state.user.email}
                    errors={this.state.emailError}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="formField">
                  <label>Confirm Email</label>
                  <input
                    type="text"
                    name="reEmailId"
                    errors={this.state.reEmaiError}
                    value={this.state.user.reEmailId}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="formField">
                  <div className="">
                    <label>Pant Size</label>
                    <select
                      name="pantSize"
                      value={this.state.user.pantSize}
                      onChange={this.handleChange}
                    >
                      <option value="">Pant Size</option>
                      {this.state.pantSize}
                    </select>
                  </div>
                  <div className="">
                    <label>Inseam Pant Size</label>
                    <select
                      name="inseam_pant_size"
                      value={this.state.user.inseam_pant_size}
                      onChange={this.handleChange}
                    >
                      <option value="">Inseam Pant Size</option>
                      {this.state.inseamPantSize}
                    </select>
                  </div>
                  <div className="">
                    <label>Shirt Size</label>
                    <select
                      name="shirtSize"
                      value={this.state.user.shirtSize}
                      onChange={this.handleChange}
                    >
                      <option value="">Shirt Size</option>
                      {this.state.shirtSize}
                    </select>
                  </div>
                  <div className="">
                    <label>Weight (lbs)</label>
                    <input
                      type="text"
                      ref={(input) => {
                        this.weightInput = input;
                      }}
                      name="weight"
                      value={this.state.user.weight}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="formField">
                  <label>Date Of Birth</label>
                  <DatePicker
                    dateFormat={"MM/DD/YYYY"}
                    name="dateOfBirth"
                    selected={
                      this.state.user.dateOfBirth
                        ? moment(this.state.user.dateOfBirth)
                        : ""
                    }
                    onChange={(date) =>
                      this.handleOnChange(
                        "dateOfBirth",
                        moment(date).format(DATE_FORMAT),
                      )
                    }
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    disabled={
                      !this.state.permList.includes(
                        "Manage Sensitive Employee Profile Fields",
                      )
                    }
                  />
                </div>
                <div className="formField">
                  <label>SSN</label>
                  <input
                    type="text"
                    name="ssn"
                    value={this.state.user.ssn}
                    onChange={this.handleChange}
                    disabled={
                      !this.state.permList.includes(
                        "Manage Sensitive Employee Profile Fields",
                      )
                    }
                  ></input>
                </div>

                {this.props.user.profile?.data &&
                this.props.user.profile.data.is_admin === UserRoles.supAdmin ? (
                  <div className="formField">
                    <label>EEO Classification</label>
                    <select
                      name="eeoClassification"
                      value={this.state.user.eeoClassification}
                      onChange={this.handleChange}
                    >
                      <option value="">EEO Classification</option>
                      {this.state.eeoClassification}
                    </select>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="personal-info-container">
                <div className="formField">
                  <label>Allergies</label>
                  <input
                    type="text"
                    name="allergies"
                    value={this.state.user.allergies}
                    onChange={this.handleChange}
                    disabled={
                      !this.state.permList.includes(
                        "Manage Sensitive Employee Profile Fields",
                      )
                    }
                  ></input>
                </div>

                <div className="formField">
                  <label>Prescriptions</label>
                  <input
                    type="text"
                    name="prescriptions"
                    value={this.state.user.prescriptions}
                    onChange={this.handleChange}
                    disabled={
                      !this.state.permList.includes(
                        "Manage Sensitive Employee Profile Fields",
                      )
                    }
                  ></input>
                </div>
                <div className="formField">
                  <label>Sex</label>
                  <select
                    id="sex"
                    ref={(input) => {
                      this.sexInput = input;
                    }}
                    name="sex"
                    value={this.state.user.sex}
                    errors={this.state.sexError}
                    onChange={this.handleChange}
                  >
                    <option value="">Sex</option>
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                  </select>
                </div>

                <div className="label_bar physicalAddress">
                  <h2>Physical Address</h2>

                  <div className="formField">
                    <label>Street Address</label>
                    <input
                      id="physicalAddress"
                      type="text"
                      ref={(input) => {
                        this.physicalAddressInput = input;
                      }}
                      name="physicalAddress"
                      value={this.state.user.physicalAddress}
                      errors={this.state.physicalAddressError}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="formField">
                    <label>City</label>
                    <input
                      id="physicalCity"
                      type="text"
                      ref={(input) => {
                        this.physicalCityInput = input;
                      }}
                      name="physicalCity"
                      value={this.state.user.physicalCity}
                      errors={this.state.physicalCityError}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="formField">
                    <label>State</label>
                    <input
                      id="physicalState"
                      type="text"
                      ref={(input) => {
                        this.physicalStateInput = input;
                      }}
                      name="physicalState"
                      value={this.state.user.physicalState}
                      errors={this.state.physicalStateError}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="formField">
                    <label>Zip</label>
                    <input
                      id="physicalZip"
                      type="text"
                      ref={(input) => {
                        this.physicalZipInput = input;
                      }}
                      name="physicalZip"
                      value={this.state.user.physicalZip}
                      errors={this.state.physicalZipError}
                      onChange={this.handleChange}
                    />
                  </div>

                  <h2>Mailing Address</h2>
                  <span className="sameAddress">
                    <input
                      id="chkSameAddress"
                      type="checkbox"
                      name="chkSameAddress"
                      defaultValue={this.state.user.chkSameAddress}
                      onChange={this.handleAddress}
                      style={{ cursor: "pointer", marginRight: "0px" }}
                    />
                    <span
                      className="wpcf7-list-item-label"
                      onClick={this.toggleAddressCheckbox} // Added onClick event to the label
                      style={{ cursor: "pointer" }} // Added cursor style to indicate the label is clickable
                    >
                      <b>&nbsp;&nbsp;Same as Physical Address</b>
                    </span>
                  </span>

                  <div className="formField">
                    <label>Street Address</label>
                    <input
                      id="mailingAddress"
                      type="text"
                      name="mailingAddress"
                      value={this.state.user.mailingAddress}
                      errors={this.state.mailingAddressError}
                      onChange={this.handleChange}
                      readOnly={this.state.maddress}
                      style={{
                        pointerEvents: this.state.maddress ? "none" : "auto",
                      }}
                    />
                  </div>
                  <div className="formField">
                    <label>City</label>
                    <input
                      id="mailingCity"
                      type="text"
                      name="mailingCity"
                      value={this.state.user.mailingCity}
                      errors={this.state.mailingCityError}
                      onChange={this.handleChange}
                      readOnly={this.state.mcity}
                      style={{
                        pointerEvents: this.state.mcity ? "none" : "auto",
                      }}
                    />
                  </div>
                  <div className="formField">
                    <label>State</label>
                    <input
                      id="mailingState"
                      type="text"
                      name="mailingState"
                      value={this.state.user.mailingState}
                      errors={this.state.mailingStateError}
                      onChange={this.handleChange}
                      readOnly={this.state.mstate}
                      style={{
                        pointerEvents: this.state.mstate ? "none" : "auto",
                      }}
                    />
                  </div>
                  <div className="formField">
                    <label>Zip</label>
                    <input
                      id="mailingZip"
                      type="text"
                      name="mailingZip"
                      value={this.state.user.mailingZip}
                      errors={this.state.mailingZipError}
                      onChange={this.handleChange}
                      readOnly={this.state.mzip}
                      style={{
                        pointerEvents: this.state.mzip ? "none" : "auto",
                      }}
                    />
                  </div>
                </div>
                <div className="label_bar physicalAddress">
                  <h2>Personal License Plate Number</h2>
                  <div className="formField">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <RadioButton
                        rootStyles={{ marginRight: 10 }}
                        checked={
                          this.state.user.personal_licence_plate_number_check ==
                          0
                        }
                        name="personal_licence_plate_number_check"
                        value={0}
                        label="No"
                        onChange={this.handleChange}
                      />
                      <RadioButton
                        checked={
                          this.state.user.personal_licence_plate_number_check ==
                          1
                        }
                        name="personal_licence_plate_number_check"
                        value={1}
                        label="Yes"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  {this?.state?.user?.personal_licence_plate_number_check ==
                    1 && (
                    <React.Fragment>
                      <div className="formField">
                        <label>License Number</label>
                        <input
                          type="text"
                          name="personal_licence_plate_number"
                          value={
                            this?.state?.user?.personal_licence_plate_number
                          }
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                      <div className="formField">
                        <label>Make / Model</label>
                        <input
                          type="text"
                          name="plpn_make_or_model"
                          value={this?.state?.user?.plpn_make_or_model}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="label_bar physicalAddress">
            <h2>Date Completed</h2>
            <div className="formField">
              <label>RT-130</label>
              <DatePicker
                // minDate={moment()}
                dateFormat={"MM/DD/YYYY"}
                name="rt130Expiry"
                selected={
                  this.state.user.rt130Expiry
                    ? moment(this.state.user.rt130Expiry)
                    : ""
                }
                onChange={(date) =>
                  this.handleOnChange(
                    "rt130Expiry",
                    moment(date).format(DATE_FORMAT)
                  )
                }
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div> */}

          <div className="label_bar emergency_contact1">
            <h2>Emergency Contact #1</h2>
            <div className="formField">
              <label>First Name</label>
              <input
                type="text"
                ref={(input) => {
                  this.emgc1FNameInput = input;
                }}
                name="emgc1FName"
                value={this.state.user.emgc1FName}
                errors={this.state.emgc1FNameError}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>Last Name</label>
              <input
                type="text"
                ref={(input) => {
                  this.emgc1LNameInput = input;
                }}
                name="emgc1LName"
                errors={this.state.emgc1LNameError}
                value={this.state.user.emgc1LName}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>Primary Phone</label>
              {/*<input type="text" ref={(input) => { this.emgc1PhoneInput = input; }} name="emgc1Phone" errors={this.state.emgc1PhoneError} maxLength="10" value={this.state.user.emgc1Phone}  onChange={this.handleChange} />*/}
              <NumberFormat
                format="###-###-####"
                mask="_"
                onChange={this.handleChange}
                ref={(input) => {
                  this.emgc1PhoneInput = input;
                }}
                name="emgc1Phone"
                errors={this.state.emgc1PhoneError}
                value={this.state.user.emgc1Phone}
              />
            </div>
            <div className="formField">
              <label>Relationship</label>
              <input
                type="text"
                ref={(input) => {
                  this.emgc1RelationInput = input;
                }}
                name="emgc1Relation"
                errors={this.state.emgc1RelationError}
                value={this.state.user.emgc1Relation}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="label_bar emergency_contact2">
            <h2>Emergency Contact #2</h2>
            <div className="formField">
              <label>First Name</label>
              <input
                type="text"
                ref={(input) => {
                  this.emgc2FNameInput = input;
                }}
                name="emgc2FName"
                errors={this.state.emgc2FNameError}
                value={this.state.user.emgc2FName}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>Last Name</label>
              <input
                type="text"
                ref={(input) => {
                  this.emgc2LNameInput = input;
                }}
                name="emgc2LName"
                errors={this.state.emgc2LNameError}
                value={this.state.user.emgc2LName}
                onChange={this.handleChange}
              />
            </div>
            <div className="formField">
              <label>Primary Phone</label>
              {/*<input type="text" ref={(input) => { this.emgc2PhoneInput = input; }} name="emgc2Phone" maxLength="10" errors={this.state.emgc2PhoneError} value={this.state.user.emgc2Phone}  onChange={this.handleChange} />*/}
              <NumberFormat
                format="###-###-####"
                mask="_"
                onChange={this.handleChange}
                ref={(input) => {
                  this.emgc2PhoneInput = input;
                }}
                name="emgc2Phone"
                errors={this.state.emgc2PhoneError}
                value={this.state.user.emgc2Phone}
              />
            </div>
            <div className="formField">
              <label>Relationship</label>
              <input
                type="text"
                ref={(input) => {
                  this.emgc2RelationInput = input;
                }}
                name="emgc2Relation"
                errors={this.state.emgc2RelationError}
                value={this.state.user.emgc2Relation}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <Accordian title="Expiration Dates">
            <div className="resource_accordion_wrapper">
              {this.state.permList.includes(
                PERMISSION_FOR_EXPIRE_ASSIGNED_DATE,
              ) && (
                <React.Fragment>
                  <div className="label_bar margin_exp_date">
                    <div className="formField">
                      <label>Drivers License</label>
                      <input
                        type="text"
                        name="driversLicense"
                        value={
                          this.state.user.driversLicense !== "null"
                            ? this.state.user.driversLicense
                            : ""
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="formField">
                      <label>Drivers License State issued</label>
                      <input
                        type="text"
                        name="DLState"
                        value={
                          this.state.user.DLState !== "null"
                            ? this.state.user.DLState
                            : ""
                        }
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="formField">
                      <label>Drivers License Expiration Date</label>
                      <DatePicker
                        minDate={moment()}
                        dateFormat={"MM/DD/YYYY"}
                        name="dlExpiry"
                        selected={
                          this.state.user.dlExpiry
                            ? moment(this.state.user.dlExpiry)
                            : ""
                        }
                        onChange={(date) =>
                          this.handleOnChange(
                            "dlExpiry",
                            moment(date).format(DATE_FORMAT),
                          )
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                    
                    <div className="formField">
                      <label>DOL Blue Card/MSPA Expiration date</label>
                      <DatePicker
                        id="MSPA_ExpDate"
                        name="MSPA_ExpDate"
                        selected={this.state.user.MSPA_ExpDate}
                        onChange={this.handleDateChange}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        disabled={!this.checkPermissionManageProfile()}
                        className={
                          this.checkPermissionManageProfile()
                            ? ""
                            : "disabled-for-employee"
                        }
                      />
                    </div>
                    <div className="formField">
                      <label>DOL - Finger Print</label>
                      <DatePicker
                        minDate={moment()}
                        dateFormat={"MM/DD/YYYY"}
                        name="fingerPrintExpiry"
                        selected={
                          this.state.user.fingerPrintExpiry
                            ? moment(this.state.user.fingerPrintExpiry)
                            : ""
                        }
                        onChange={(date) =>
                          this.handleOnChange(
                            "fingerPrintExpiry",
                            moment(date).format(DATE_FORMAT),
                          )
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                    <div className="formField">
                      <label>DOL - Physical</label>
                      <DatePicker
                        minDate={moment()}
                        dateFormat={"MM/DD/YYYY"}
                        name="physicalExpiry"
                        selected={
                          this.state.user.physicalExpiry
                            ? moment(this.state.user.physicalExpiry)
                            : ""
                        }
                        onChange={(date) =>
                          this.handleOnChange(
                            "physicalExpiry",
                            moment(date).format(DATE_FORMAT),
                          )
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                    <div className="formField">
                      <label>First Aid/CPR</label>
                      <DatePicker
                        minDate={moment()}
                        dateFormat={"MM/DD/YYYY"}
                        name="firstAidExpiry"
                        selected={
                          this.state.user.firstAidExpiry
                            ? moment(this.state.user.firstAidExpiry)
                            : ""
                        }
                        onChange={(date) =>
                          this.handleOnChange(
                            "firstAidExpiry",
                            moment(date).format(DATE_FORMAT),
                          )
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>

                    <div className="formField">
                      <label>Sexual Harassment</label>
                      <DatePicker
                        minDate={moment()}
                        dateFormat={"MM/DD/YYYY"}
                        name="sexualHarassmentExpiry"
                        selected={
                          this.state.user.sexualHarassmentExpiry
                            ? moment(this.state.user.sexualHarassmentExpiry)
                            : ""
                        }
                        onChange={(date) =>
                          this.handleOnChange(
                            "sexualHarassmentExpiry",
                            moment(date).format(DATE_FORMAT),
                          )
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                    <div className="formField">
                      <label>Faller Qualification</label>
                      <DatePicker
                        minDate={moment()}
                        dateFormat={"MM/DD/YYYY"}
                        name="fallerQuals"
                        selected={
                          this.state.user.fallerQuals
                            ? moment(this.state.user.fallerQuals)
                            : ""
                        }
                        onChange={(date) =>
                          this.handleOnChange(
                            "fallerQuals",
                            moment(date).format(DATE_FORMAT),
                          )
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                  </div>

                  <div className="label_bar">
                    <h2>Assigned Dates</h2>
                    <div className="formField">
                      <label>Security Code</label>
                      <DatePicker
                        maxDate={moment()}
                        dateFormat={"MM/DD/YYYY"}
                        name="securityCodeAssigned"
                        selected={
                          this.state.user.securityCodeAssigned
                            ? moment(this.state.user.securityCodeAssigned)
                            : ""
                        }
                        onChange={(date) =>
                          this.handleOnChange(
                            "securityCodeAssigned",
                            moment(date).format(DATE_FORMAT),
                          )
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                    <div className="formField">
                      <label>Credit Card</label>
                      <DatePicker
                        maxDate={moment()}
                        dateFormat={"MM/DD/YYYY"}
                        name="creditCardAssigned"
                        selected={
                          this.state.user.creditCardAssigned
                            ? moment(this.state.user.creditCardAssigned)
                            : ""
                        }
                        onChange={(date) =>
                          this.handleOnChange(
                            "creditCardAssigned",
                            moment(date).format(DATE_FORMAT),
                          )
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                    <div className="formField">
                      <label>Fuel Pin</label>
                      <DatePicker
                        maxDate={moment()}
                        dateFormat={"MM/DD/YYYY"}
                        name="fuelPinAssigned"
                        selected={
                          this.state.user.fuelPinAssigned
                            ? moment(this.state.user.fuelPinAssigned)
                            : ""
                        }
                        onChange={(date) =>
                          this.handleOnChange(
                            "fuelPinAssigned",
                            moment(date).format(DATE_FORMAT),
                          )
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                    <div className="formField">
                      <label>Key Fob</label>
                      <div className="settings_keyFob">
                        <input
                          placeholder="#"
                          type="text"
                          name="keyFob"
                          value={this.state.user.keyFob}
                          onChange={(event) =>
                            this.handleOnChange(
                              event.target.name,
                              event.target.value,
                            )
                          }
                        />
                        <DatePicker
                          maxDate={moment()}
                          dateFormat={"MM/DD/YYYY"}
                          name="keyFobAssigned"
                          selected={
                            this.state.user.keyFobAssigned
                              ? moment(this.state.user.keyFobAssigned)
                              : ""
                          }
                          onChange={(date) =>
                            this.handleOnChange(
                              "keyFobAssigned",
                              moment(date).format(DATE_FORMAT),
                            )
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </Accordian>
          {/* <div className="label_bar more">
            <h2>More Info</h2>
            <div className="formField">
              <label>Certified EMT?</label>
              <select
                name="certifiedEMT"
                value={this.state.user.certifiedEMT}
                onChange={this.handleChange}
              >
                <option value="">Certified EMT?</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div> */}
          {/* Removed Qualifications */}
          {/* <div className="label_bar more current_qualification">
            <div className="qualification-m-t-2">
              <div className="formField">
                <label>Qualification</label>
                <select
                  ref={(input) => {
                    this.QualificationInput = input;
                  }}
                  name="generalQualification"
                  value={this.state.user.generalQualification}
                  errors={this.state.QualificationError}
                  onChange={this.handleChange}
                >
                  <option value="">Qualification*</option>
                  {this.state.generalQualificationList}
                </select>
              </div>
              <div className="formField">
                <label>LSA</label>
                <select
                  name="lsa"
                  value={this.state.user.lsa}
                  onChange={this.handleChange}
                  disabled={!this.checkPermissionManageProfile()}
                  className={
                    this.checkPermissionManageProfile()
                      ? ""
                      : "disabled-for-employee"
                  }
                >
                  <option value="">LSA</option>
                  {this.state.lsa}
                </select>
              </div>
            </div>
          </div> */}

          {/* Verified Docs */}
          {
            // profile && profile.userattribute && profile.docs_status === 3 &&
            <div className="label_bar more current_qualification">
              <h2>
                Check each box below to indicate it has been submitted and
                verified
              </h2>
              <div className="formField">
                {this.generateAttrCheckbox(profile.userattribute)}
              </div>
            </div>
          }
        </div>
        {this.state.alertPop === true && (
          <AlertPopup
            className="profile_update"
            head={this.state.alertHead}
            message={this.state.alertMsg}
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
        <ReactModal
          isOpen={this.state.statusOpen}
          onClose={this.statusCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="imageCropPopup"
        >
          <div className="modal-head">
            <img
              className="closeModal"
              onClick={this.statusCloseModal.bind(this)}
              src={require("../assets/images/close_green.png").default}
            />
          </div>
          <div id="edit_profile_pic_popup" className="modal-body">
            <Cropper
              ref={(cropper) => {
                this.cropper = cropper;
              }}
              src={img_preview}
              style={{ height: 400, width: "100%" }}
              // Cropper.js options
              aspectRatio={16 / 16}
              guides={false}
              crop={this._crop.bind(this)}
            />
            <div className="button_list">
              <Dropzone
                id="file_name"
                className="button popup_upload"
                name="file_name"
                multiple={false}
                accept="image/*"
                onDrop={this.onImageDrop.bind(this)}
              >
                <p>Upload Here</p>
              </Dropzone>
              <button onClick={this.cropImage.bind(this)} className="button">
                Crop Image
              </button>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { listAll } = state.createProfile;
  return {
    listAll,
  };
}

const connectedRegisterPage = withRouter(connect(mapStateToProps)(EditProfile));
export { connectedRegisterPage as EditProfile };
