import { exportToCSV, getBdayFilename } from "_utils/export";
import { authHeader, config } from "../_helpers";

export const dashboardService = {
  // Expiration dates
  getExpirationDates,
  generateEmployeeExpirationDateReport,

  // Employee Qualifications
  getQualifications,
  getQualificationReport,
  generateEmployeeQualificationReport,
  generateQualificationCsvReport,

  // All Qualifications
  fetchAllQualifications,

  // Affirmative action
  getAffirmativeActionReport,
  generateAffirmativeActionReport,
  printAffirmativeActionReport,

  // Vehicle
  getVehicleReport,
  generateEmployeeVehicleReport,
  printVehicleReport,

  // Employee birthday
  getEmployeesBirthday,
  generateBdayReport,
  printEmployeeBirthdaysReportPdf,

  // Resource Order
  getResourceOrderForms,
  getCreatedResourceOrderForm,
  getResourceOrderHistory,
  getResourceOrderPDF,

  // Equipment sold
  getEquipmentSoldReport,
  generateEquipmentSoldReport,
  printEquipmentSoldReportPdf,

  // Mileage hobbs
  getMileageHobbsReport,
  generateMileageHobbsReport,
  printMileageHobbsReportPdf,

  // All inventory
  getAllInventoryReport,

  // Refresher
  getRefresherReport,
  generateRefresherReport,
  generateRefresherReportReportCsv,

  // Master list
  getMasterList,
  generateMasterListPdf,

  // Document
  askToFillDocumentsByUsersHash,
  resetDocumentStatus,
  getFullEmployeesDocsStatusReport,
  generateEmployeeExpirationDateReportCsv,
  generateDocsStatusReportCsv,
  getSignedDOUrl,
  uploadFile,

  // Inventory
  printAllInventoryReportPdf,
  generateAllInventoryReportCsv,

  // Applicants report
  fetchApplicantsReport,
  fetchApplicantsReportCsv,
  fetchApplicantsReportPdf,

  // Activity log
  fetchActivityLog,

  // Warning management
  fetchWarningManagement,
  warningManagementAddEntry,
  warningManagementDeleteEntry,

  // Fire history
  getEmployeesFireHistory,
  getFireHistory,
  updateIndividualFireHistoryEntry,
  updateFireHistoryHotlineShifts,
  generateFireHistory,
  deleteIndividualFireHistory,
  exportFireHistoryReportCSV,
  exportFireHistoryReportPDF,
  fetchFireHistoryReport,
  deleteFireHistory,
  verifyFireHistory,

  // DOL Approved Driver
  fetchDolApprovedDriverReport,
  exportDolApprovedReportCSV,
  exportDolApprovedReportPDF,

  // Training report
  exportTrainingReportPDF,

  // Bulk Lock / Unlock
  lockEmployees,
};

function getExpirationDates(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/employee/expirationreport",
    requestOptions,
  ).then(handleResponse, handleError);
}

function generateEmployeeExpirationDateReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/employee/printexpirationreport",
    requestOptions,
  ).then(handleResponse, handleError);
}
function generateEmployeeQualificationReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/reports/print/qualifications",
    requestOptions,
  ).then(handleResponse, handleError);
}

function fetchAllQualifications() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(config.apiUrl + "/api/qualification/list", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function getQualifications(data) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/getlist/qualification",
    requestOptions,
  ).then(handleResponse, handleError);
}

function getQualificationReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/reports/qualifications",
    requestOptions,
  ).then(handleResponse, handleError);
}

function getAffirmativeActionReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/affirmative-action/report",
    requestOptions,
  ).then(handleResponse, handleError);
}

function generateAffirmativeActionReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/affirmative-action/csv-report",
    requestOptions,
  ).then((res) => exportToCSV(res, "affirmative_action_report"), handleError);
}

function printAffirmativeActionReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/affirmative-action/csv-report",
    requestOptions,
  ).then(handleResponse, handleError);
}

function getVehicleReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + "/api/vehicle/report", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function getEquipmentSoldReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/inventory/equipmentsoldreport",
    requestOptions,
  ).then(handleResponse, handleError);
}

function getMileageHobbsReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + "/api/inventory/mileage", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function generateMileageHobbsReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/inventory/mileageCsv",
    requestOptions,
  ).then((res) => exportToCSV(res, "mileage_hobbs_report"), handleError);
}

function getAllInventoryReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/inventory/allinventoryreport",
    requestOptions,
  ).then(handleResponse, handleError);
}

function generateEmployeeVehicleReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    // config.apiUrl + "/api/vehicle/printreport",
    config.apiUrl + "/api/vehicle/vehiclereportcsv",
    requestOptions,
  ).then((res) => exportToCSV(res, "vehicle_report"), handleError);
}

function getEmployeesBirthday(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/employee/birthday/details",
    requestOptions,
  ).then(handleResponse, handleError);
}

function getResourceOrderForms(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/manifest/resourceorderformfiles",
    requestOptions,
  ).then(handleResponse, handleError);
}
function getCreatedResourceOrderForm(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/manifest/resourceorderforms",
    requestOptions,
  ).then(handleResponse, handleError);
}
function generateAllInventoryReportCsv(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/inventory/allinventoryreportcsv",
    requestOptions,
  ).then((res) => exportToCSV(res, "inventory_report"), handleError);
}

function generateEquipmentSoldReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/inventory/equipmentsoldreportcsv",
    requestOptions,
  ).then((res) => exportToCSV(res, "equipment_sold_report"), handleError);
}

function generateBdayReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  const filename = getBdayFilename(data.get("date"));
  return fetch(
    config.apiUrl + "/api/employee/birthday/reportcsv",
    requestOptions,
  ).then((res) => exportToCSV(res, filename, false), handleError);
}

function getResourceOrderHistory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/resourceorderhistory/list",
    requestOptions,
  ).then(handleResponse, handleError);
}

function getResourceOrderPDF(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/resourceorderhistory/details",
    requestOptions,
  ).then(handleResponse, handleError);
}

function getRefresherReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + "/api/employee/rtreport", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function generateRefresherReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/employee/rtreportpdf",
    requestOptions,
  ).then(handleResponse, handleError);
}

function getMasterList(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + "/api/employee/reports/masteradminlist",
    requestOptions,
  ).then(handleResponse, handleError);
}

function generateMasterListPdf(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/employee/reports/masteradminlistpdf",
    requestOptions,
  ).then(handleResponse, handleError);
}

function askToFillDocumentsByUsersHash(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/employee/reports/filldocuments",
    requestOptions,
  ).then(handleResponse, handleError);
}

function resetDocumentStatus(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + "/api/docs/forceresetdocs", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function getFireHistory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/reports/fire_history",
    requestOptions,
  ).then(handleResponse, handleError);
}

function generateFireHistory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/reports/fire_history/print",
    requestOptions,
  ).then(handleResponse, handleError);
}

function getEmployeesFireHistory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/reports/fire_history/employees",
    requestOptions,
  ).then(handleResponse, handleError);
}

function deleteIndividualFireHistory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/reports/fire_history/delete",
    requestOptions,
  ).then(handleResponse, handleError);
}

function deleteFireHistory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/reports/fire_history/remove/employee",
    requestOptions,
  ).then(handleResponse, handleError);
}

function verifyFireHistory(id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  return fetch(
    `${config.apiUrl}/api/employee_fire_history/${id}/verify`,
    requestOptions,
  ).then(handleResponse, handleError);
}

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then((json) => resolve(json));
      } else {
        resolve();
      }
    } else {
      // return error message from response body
      response.text().then((text) => reject(text));
    }
  });
}

function handleError(error) {
  return Promise.reject(error && error.message);
}

function generateQualificationCsvReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/reports/print/qualifications",
    requestOptions,
  ).then((res) => exportToCSV(res, "qualifications_report"), handleError);
}

function printVehicleReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/vehicle/vehiclereportcsv",
    requestOptions,
  ).then(handleResponse, handleError);
}

function generateEmployeeExpirationDateReportCsv(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + "/api/employee/printexpirationreport",
    requestOptions,
  ).then((res) => exportToCSV(res, "Expiration_date_report"), handleError);
}

function printAllInventoryReportPdf(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/inventory/allinventoryreportcsv",
    requestOptions,
  ).then(handleResponse, handleError);
}

function printEquipmentSoldReportPdf(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/inventory/equipmentsoldreportcsv",
    requestOptions,
  ).then(handleResponse, handleError);
}

function printEmployeeBirthdaysReportPdf(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/employee/birthday/reportcsv",
    requestOptions,
  ).then(handleResponse, handleError);
}

function generateRefresherReportReportCsv(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + "/api/employee/rtreportpdf",
    requestOptions,
  ).then((res) => exportToCSV(res, "Refresher_report"), handleError);
}

function generateDocsStatusReportCsv(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + "/api/employee/reports/masteradminlistpdf",
    requestOptions,
  ).then((res) => exportToCSV(res, "Docs_status_report"), handleError);
}

function printMileageHobbsReportPdf(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + "/api/inventory/mileageCsv",
    requestOptions,
  ).then(handleResponse, handleError);
}

//
function getFullEmployeesDocsStatusReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + "/api/employee/reports/docstatus",
    requestOptions,
  ).then(handleResponse, handleError);
}

// applicants report
function fetchApplicantsReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + "/api/reports/applicants", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function fetchApplicantsReportCsv(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/reports/applicants/export",
    requestOptions,
  ).then((res) => exportToCSV(res, "applicants"), handleError);
}

function exportFireHistoryReportCSV(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/reports/fire_history/export_assignment_history",
    requestOptions,
  ).then((res) => exportToCSV(res, "fireHistory"), handleError);
}

function exportFireHistoryReportPDF(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + "/api/reports/fire_history/export_assignment_history",
    requestOptions,
  ).then(handleResponse, handleError);
}

function fetchApplicantsReportPdf(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + "/api/reports/applicants/export",
    requestOptions,
  ).then(handleResponse, handleError);
}

function fetchActivityLog(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + "/api/activity", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function fetchWarningManagement(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + "/api/management", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function warningManagementAddEntry(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + "/api/management/create", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function warningManagementDeleteEntry(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + "/api/management/delete", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function getSignedDOUrl(reqData) {
  const { extension } = reqData;

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl +
      `/api/management/get/signedurl?type=file&extension=${extension}`,
    requestOptions,
  ).then(handleResponse, handleError);
}

function uploadFile(file, url) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": `${file.type}; charset=utf-8`,
    },
    body: file,
  };

  return fetch(url, requestOptions).then(handleResponse, handleError);
}

function updateIndividualFireHistoryEntry(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/reports/fire_history/update",
    requestOptions,
  ).then(handleResponse, handleError);
}

function updateFireHistoryHotlineShifts(id, data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    `${config.apiUrl}/api/reports/fire_history/${id}/update_hotline_shifts`,
    requestOptions,
  ).then(handleResponse, handleError);
}

function fetchFireHistoryReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    `${config.apiUrl}/api/reports/fire_history/assignment_history`,
    requestOptions,
  ).then(handleResponse, handleError);
}

function fetchDolApprovedDriverReport(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    `${config.apiUrl}/api/reports/dol-approved-drivers`,
    requestOptions,
  ).then(handleResponse, handleError);
}

function exportDolApprovedReportCSV(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    `${config.apiUrl}/api/reports/dol-approved-drivers/export`,
    requestOptions,
  ).then((res) => exportToCSV(res, "dol_approved"), handleError);
}

function exportDolApprovedReportPDF(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(
    `${config.apiUrl}/api/reports/dol-approved-drivers/export`,
    requestOptions,
  ).then(handleResponse, handleError);
}

function exportTrainingReportPDF(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(
    `${config.apiUrl}/api/reports/training/export`,
    requestOptions,
  ).then(handleResponse, handleError);
}

function lockEmployees(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + "/api/employee/status", requestOptions).then(
    handleResponse,
    handleError,
  );
}
