import { permissionConstants } from "../_constants";
import { permissionService } from "../_services";

export const permissionActions = {
  getAll,
  getPermission,
  setPermission,
  getAdminList,
  changeUserType,
  adminSearch,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());

    permissionService.getAll().then(
      (adminpermission) => dispatch(success(adminpermission)),
      (error) => dispatch(failure(error)),
    );
  };

  function request() {
    return { type: permissionConstants.GETALL_REQUEST };
  }
  function success(adminpermission) {
    return { type: permissionConstants.GETALL_SUCCESS, adminpermission };
  }
  function failure(error) {
    return { type: permissionConstants.GETALL_FAILURE, error };
  }
}

function getPermission() {
  return (dispatch) => {
    dispatch(request());

    permissionService.getPermission().then(
      (permission) => dispatch(success(permission)),
      (error) => dispatch(failure(error)),
    );
  };

  function request() {
    return { type: permissionConstants.GETALL_REQUEST };
  }
  function success(permission) {
    return { type: permissionConstants.GETALL_SUCCESS, permission };
  }
  function failure(error) {
    return { type: permissionConstants.GETALL_FAILURE, error };
  }
}

function setPermission(data) {
  return (dispatch) => {
    dispatch(request(data));

    permissionService.setPermission(data).then(
      (value) => dispatch(getAll()),
      (error) => dispatch(failure(error)),
    );
  };

  function request() {
    return { type: permissionConstants.SETPERM_REQUEST };
  }
  // function success(value) { return { type: permissionConstants.SETPERM_SUCCESS, value } }
  function failure(error) {
    return { type: permissionConstants.SETPERM_FAILURE, error };
  }
}

function changeUserType(hash) {
  return (dispatch) => {
    dispatch(request(hash));

    permissionService.changeUserType(hash).then(
      (unassign) => dispatch(getAdminList()),
      (error) => dispatch(failure(error)),
    );
  };

  function request() {
    return { type: permissionConstants.DELETE_REQUEST };
  }
  // function success(unassign) { return { type: permissionConstants.DELETE_SUCCESS, unassign } }
  function failure(error) {
    return { type: permissionConstants.DELETE_FAILURE, error };
  }
}

function adminSearch(keyword) {
  return (dispatch) => {
    dispatch(request(keyword));

    permissionService.adminSearch(keyword).then(
      (allAdmin) => dispatch(success(allAdmin)),
      (error) => dispatch(failure(error)),
    );
  };

  function request() {
    return { type: permissionConstants.ALLADMIN_REQUEST };
  }
  function success(allAdmin) {
    return { type: permissionConstants.ALLADMIN_SUCCESS, allAdmin };
  }
  function failure(error) {
    return { type: permissionConstants.ALLADMIN_FAILURE, error };
  }
}

function getAdminList() {
  return (dispatch) => {
    dispatch(request());

    permissionService.getAdminList().then(
      (adminList) => dispatch(success(adminList)),
      (error) => dispatch(failure(error)),
    );
  };

  function request() {
    return { type: permissionConstants.GETADMIN_REQUEST };
  }
  function success(adminList) {
    return { type: permissionConstants.GETADMIN_SUCCESS, adminList };
  }
  function failure(error) {
    return { type: permissionConstants.GETADMIN_FAILURE, error };
  }
}
