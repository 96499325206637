// import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "_actions";
import { dashboardService } from "_services";
import { fireHistory } from "_helpers/reduxSelector";
import { resourceActions } from "_actions";
import AsyncSelect from "react-select/async";
import FireHistoryReportAll from "./FireHistoryReportAll";
import { redirectToPdf } from "_helpers/redirectToPdf";

const Sort = {
  Asc: "asc",
  Dsc: "desc",
};

const ReportType = {
  Csv: "csv",
  Pdf: "pdf",
};

const selectStyles = {
  control: (css) => ({
    ...css,
    width: MENU_WIDTH || "auto",
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 7,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 9999,
  }),
  option: (css) => ({ ...css, paddingRight: 36 + 8, zIndex: 9999 }),
};

const MENU_WIDTH = 300;

function FireHistoryReportReportContainer(props) {
  const dispatch = useDispatch();

  const reportData = useSelector((state) => fireHistory(state));
  const [globalSort, setGlobalSort] = useState("incident");
  const [globalOrder, setGlobalOrder] = useState("asc");
  const [selectedResource, setSelectedResource] = useState("");

  const fetchFireHistoryReport = (newPage, sort, order) => {
    const data = new FormData();
    data.append("page", newPage || 0);
    data.append("sort", sort || "incident");
    data.append("order", order || "asc");
    data.append("id", selectedResource?.value || "");
    dispatch(dashboardActions.fetchFireHistoryReport(data));
  };

  const setSortParams = (sort, order) => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    setGlobalSort(sort);
    setGlobalOrder(sortOrder);
  };

  const onExport = () => {
    const data = new FormData();
    data.append("sort", globalSort || "incident");
    data.append("id", selectedResource?.value || "");
    data.append("order", globalOrder || "asc");
    data.append("report_type", ReportType.Csv);
    dashboardService.exportFireHistoryReportCSV(data);
  };

  const onPrint = () => {
    const data = new FormData();
    data.append("sort", globalSort || "incident");
    data.append("id", selectedResource?.value || "");
    data.append("order", globalOrder || "asc");
    data.append("report_type", ReportType.Pdf);
    dashboardService
      .exportFireHistoryReportPDF(data)
      .then((res) => redirectToPdf(res, 'fire_history_report_' + Date.now()));
  };

  const handleSearch = async (text) => {
    let data = new FormData();
    data.append("keyword", text);
    const resources = dispatch(resourceActions.searchResource(data));
    let resourceList = [];

    const response = await resources;
    const items = response.data;
    var res = [];
    for (var key in items) {
      var arr = items[key];
      if (arr.length) {
        res = res.concat(arr);
      }
    }
    if (res.length > 0) {
      res.forEach((item) => {
        let resource = {
          label: item.resource_name,
          value: item.id,
        };
        resourceList.push(resource);
      });
    }
    return resourceList;
  };

  const loadResources = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(handleSearch(inputValue));
      }, 1000);
    });

  useEffect(() => {
    fetchFireHistoryReport(0, globalSort, globalOrder);
  }, [selectedResource]);

  return (
    <div>
      <div>
        <div className="white">
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head sold_rpt_mbl_flx">
                <h5 className="graph_header">Fire History Report</h5>
                <div
                  style={{ gap: `${props.isReportMenu ? "10px" : "0px"}` }}
                  className="report_head_btn_grp"
                >
                  <div className="report_multi_btn_grp milage__head-wrap">
                    <div className="select-applicants-report">
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadResources}
                        onChange={(selection) => setSelectedResource(selection)}
                        defaultOptions
                        isClearable
                        styles={selectStyles}
                        placeholder={"Choose Resource Filter"}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                    <div className="milage__btn-wraper">
                      <button
                        className="print_button"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          onExport();
                        }}
                      >
                        Export
                      </button>
                      <button
                        className="print_button"
                        onClick={() => {
                          onPrint();
                        }}
                      >
                        Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <FireHistoryReportAll
                data={reportData?.data || []}
                lastPage={reportData?.last_page}
                fetchFireHistoryReport={fetchFireHistoryReport}
                setSortParams={setSortParams}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FireHistoryReportReportContainer;
