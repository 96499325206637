import React, { useState } from "react";
import styles from "./SwitchableTabs.module.scss";
import { Tab, Tabs } from "react-bootstrap";

export const SwitchableTabs = (props) => {
  const { tab1Content, tab2Content, tab1Name, tab2Name } = props;

  const [key, setKey] = useState();

  return (
    <div className={styles.SwitchableTabs}>
      <Tabs
        activeKey={key}
        onSelect={(key) => setKey(key)}
        id="controlled-tab-example"
      >
        <Tab eventKey={1} title={tab1Name}>
          {tab1Content}
        </Tab>
        <Tab eventKey={2} title={tab2Name}>
          {tab2Content}
        </Tab>
      </Tabs>
    </div>
  );
};
