import React from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import NewMessage from "./NewMessage";
import NewGroup from "./NewGroup";
import EditGroup from "./EditGroup";

import "./css/fontawesome.min.css";
import "./css/style.css";
import "./css/responsive.css";

class Chat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    let path = this.props.match.path;

    return (
      <div className="messaging">
        <Switch>
          <Route exact path={`${path}`} component={Dashboard}></Route>
          <Route path={`${path}/new-message`} component={NewMessage}></Route>
          <Route path={`${path}/new-group`} component={NewGroup}></Route>
          <Route
            path={`${path}/edit-group/:group`}
            component={EditGroup}
          ></Route>
          <Route
            exact
            path={`${path}/:conversation_id`}
            component={Dashboard}
          ></Route>
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { chat, user } = state;
  return { chat, user };
}

let connectedChat = connect(mapStateToProps)(Chat);

export { connectedChat as Chat };
