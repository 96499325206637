import React, { useState } from "react";
import styles from "./TimePicker.module.scss";
import RCTimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";

const format = "h:mm A";
// const now = moment().hour(0).minute(0).second(0);

const TimePicker = (props) => {
  const { value, onChange, disabled = false } = props;

  const [time, setTime] = useState(value);

  function handleTimeChange(value) {
    setTime(value);
    onChange(value);
  }

  return (
    <div className={`${styles.TimePicker} time-picker`}>
      <RCTimePicker
        showSecond={false}
        defaultValue={time}
        onChange={handleTimeChange}
        format={format}
        use12Hours
        inputReadOnly
        placeholder="-:-"
        clearIcon={null}
        disabled={disabled}
        className="custom-time-picker"
      />
    </div>
  );
};

export default TimePicker;
