import React, { useEffect, useState } from "react";
import styles from "./AddEditNote.module.scss";
import { Button } from "../Button/Button";

export const AddEditNote = (props) => {
  const {
    onSaveNote,
    note,
    onCancel,
    value,
    onChange,
    loading,
    disabled,
    textBoxDisabled,
  } = props;
  const [noteInputRows, setNoteInputRows] = useState(1);
  const [showButtons, setShowButtons] = useState(false);

  const onFocus = () => {
    setNoteInputRows(4);
    setShowButtons(true);
  };

  const onCancelClick = () => {
    setNoteInputRows(1);
    setShowButtons(false);
    onCancel();
  };

  useEffect(() => {
    if (value.length) {
      onFocus();
    }
  }, [value]);

  return (
    <div className={styles.AddEditNote}>
      <textarea
        type="text"
        className="add-edit-note"
        rows={noteInputRows}
        onFocus={onFocus}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder="Add your note here"
        autoComplete="off"
        maxLength={1200}
        disabled={textBoxDisabled}
      />

      {showButtons && (
        <div className="buttons">
          <Button
            name="Save Note"
            disabled={loading || disabled || !value.length}
            fontColor="#fff"
            backgroundColor="#297a18"
            loading={false}
            onClick={() => onSaveNote(note)}
          />
          <Button
            name="Cancel"
            fontColor="#fff"
            backgroundColor="#747474"
            loading={false}
            disabled={loading}
            onClick={onCancelClick}
          />
        </div>
      )}
    </div>
  );
};
