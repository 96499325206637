import React, { useRef, useEffect } from 'react';

const AutoGrowTextarea = ({
  value,
  onChange,
  placeholder = 'Add your note here',
  maxLength = 2000,
  lineHeight = 20, // Approximate px height per row
}) => {
  const textareaRef = useRef(null);
  const minRows = 1;
  const maxRows = 6;

  useEffect(() => {
    if (textareaRef.current) {
      // Reset height to auto before recalculating
      textareaRef.current.style.height = 'auto';

      const scrollHeight = Math.max(textareaRef.current.scrollHeight, 40);
      const minHeight = lineHeight * minRows; // 1 row
      const maxHeight = lineHeight * maxRows; // 6 rows

      textareaRef.current.style.height = `${Math.min(Math.max(scrollHeight, minHeight), maxHeight) + 10}px`;
    }
  }, [value, lineHeight]);

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
      placeholder={placeholder}
      rows={minRows}
      autoComplete="off"
      style={{
        overflowY: 'auto',
        resize: 'none',
        width: '100%',
        lineHeight: `${lineHeight}px`,
      }}
    />
  );
};

export default AutoGrowTextarea;
