import { resourceConstants, manifestConstants } from "../_constants";

export function vehicles(state = {}, action) {
  switch (action.type) {
    case resourceConstants.GETVEHICLES_REQUEST:
      return {
        loading: true,
      };
    case resourceConstants.GETVEHICLES_SUCCESS:
      return {
        items: action.vehicles,
      };
    case resourceConstants.GETVEHICLES_FAILURE:
      return {
        error: action.error,
      };
    case manifestConstants.GETVEH_REQUEST:
      return {
        loading: true,
      };
    case manifestConstants.GETVEH_SUCCESS:
      return {
        vehicleList: action.vehicleList,
      };
    case manifestConstants.GETVEH_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
