import React from "react";
import NumberFormat from "react-number-format";
import { preventNegativeValueInDocs, formatPhone } from "_helpers/helpers";

import { EditProfile } from "./EditProfile";

const ContactInformationComponent = ({
  profileData,
  refetchUser,
  isOpen,
  toggleAccordion,
  permList,
  authUser,
}) => {
  return (
    <div
      id="contact-information"
      className={`profile-data__drops profile-data_comp ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#contact-information")}>
        <button type="button">
          <h3>Contact Information</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>Cell Phone</b>
            </span>
            <span>
              <a
                onClick={(event) => {
                  event.stopPropagation();
                }}
                className="phone_email_link profile-link"
                href={`tel:${profileData.phone}`}
                target="_top"
              >
                {formatPhone(profileData.phone)}
              </a>
            </span>
            <EditProfile
              label="Cell Phone"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors, setValue) => (
                <>
                  <label>Cell Phone</label>
                  <NumberFormat
                    format="###-###-####"
                    mask="_"
                    placeholder="Enter Cell Phone"
                    value={profileData.phone}
                    onValueChange={({ value }) => {
                      setValue("phone", value);
                    }}
                    className={errors.phone && "profile-form-field-error"}
                    isNumericString
                    getInputRef={register("phone", {
                      required: "Cell Phone is required",
                      minLength: {
                        value: 10,
                        message: "Cell Phone must be 10 digits long",
                      },
                    })}
                    onKeyDown={preventNegativeValueInDocs}
                  />
                  {errors.phone && (
                    <p className="error-message">{errors.phone.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>Secondary Phone</b>
            </span>
            <span>
              <a
                onClick={(event) => {
                  event.stopPropagation();
                }}
                className="phone_email_link profile-link"
                href={`tel:${profileData.second_phone}`}
                target="_top"
              >
                {formatPhone(profileData.second_phone)}
              </a>
            </span>
            <EditProfile
              label="Secondary Phone"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors, setValue) => (
                <>
                  <label>Secondary Phone</label>
                  <NumberFormat
                    format="###-###-####"
                    mask="_"
                    placeholder="Enter Secondary Phone"
                    value={profileData.second_phone}
                    onValueChange={({ value }) => {
                      setValue("second_phone", value);
                    }}
                    className={
                      errors.second_phone && "profile-form-field-error"
                    }
                    isNumericString
                    getInputRef={register("second_phone", {
                      required: "Secondary Phone is required",
                      minLength: {
                        value: 10,
                        message: "Secondary Phone must be 10 digits long",
                      },
                    })}
                    onKeyDown={preventNegativeValueInDocs}
                  />
                  {errors.second_phone && (
                    <p className="error-message">
                      {errors.second_phone.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>Email</b>
            </span>
            <span>
              <a
                onClick={(event) => {
                  event.stopPropagation();
                }}
                className="phone_email_link profile-link"
                href={`mailto:${profileData.email}`}
                target="_top"
              >
                {profileData.email}
              </a>
            </span>
            <EditProfile
              label="Email"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors) => (
                <>
                  <label>Email</label>
                  <input
                    type="text"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                        message: "Invalid email address",
                      },
                    })}
                    placeholder="Enter Email"
                    defaultValue={profileData.email}
                    className={errors.email && "profile-form-field-error"}
                  />
                  {errors.email && (
                    <p className="error-message">{errors.email.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>Physical Address</b>
            </span>
            <address>
              <p>{profileData.physical_addr}</p>
              <p>{profileData.physical_city}</p>
              <p>{profileData.physical_state}</p>
              <p>{profileData.physical_zip}</p>
            </address>
            <EditProfile
              label="Physical Address"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors) => (
                <>
                  <label>Address</label>
                  <input
                    {...register("physical_addr", {
                      required: "Address is required",
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                    })}
                    placeholder="Enter Address"
                    defaultValue={profileData.physical_addr}
                    className={
                      errors.physical_addr && "profile-form-field-error"
                    }
                  />
                  {errors.physical_addr && (
                    <p className="error-message">
                      {errors.physical_addr.message}
                    </p>
                  )}

                  <label>City</label>
                  <input
                    {...register("physical_city", {
                      required: "City is required",
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                    })}
                    placeholder="Enter City"
                    defaultValue={profileData.physical_city}
                    className={
                      errors.physical_city && "profile-form-field-error"
                    }
                  />
                  {errors.physical_city && (
                    <p className="error-message">
                      {errors.physical_city.message}
                    </p>
                  )}

                  <label>State</label>
                  <input
                    {...register("physical_state", {
                      required: "State is required",
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                    })}
                    placeholder="Enter State"
                    defaultValue={profileData.physical_state}
                    className={
                      errors.physical_state && "profile-form-field-error"
                    }
                  />
                  {errors.physical_state && (
                    <p className="error-message">
                      {errors.physical_state.message}
                    </p>
                  )}

                  <label>Zip Code</label>
                  <input
                    {...register("physical_zip", {
                      required: "Zip Code is required",
                      pattern: {
                        value: /^\d{5}(-\d{4})?$/,
                        message:
                          "Invalid ZIP Code. Use format: 12345 or 12345-6789",
                      },
                    })}
                    placeholder="Enter Zip Code"
                    defaultValue={profileData.physical_zip}
                    className={
                      errors.physical_zip && "profile-form-field-error"
                    }
                  />
                  {errors.physical_zip && (
                    <p className="error-message">
                      {errors.physical_zip.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>Mailing Address</b>
            </span>
            <address>
              <p>{profileData.mailing_addr}</p>
              <p>{profileData.mailing_city}</p>
              <p>{profileData.mailing_state}</p>
              <p>{profileData.mailing_zip}</p>
            </address>

            <EditProfile
              label="Mailing Address"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors) => (
                <>
                  <label>Address</label>
                  <input
                    {...register("mailing_addr", {
                      required: "Address is required",
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                    })}
                    placeholder="Enter Address"
                    defaultValue={profileData.mailing_addr}
                    className={
                      errors.mailing_addr && "profile-form-field-error"
                    }
                  />
                  {errors.mailing_addr && (
                    <p className="error-message">
                      {errors.mailing_addr.message}
                    </p>
                  )}

                  <label>City</label>
                  <input
                    {...register("mailing_city", {
                      required: "City is required",
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                    })}
                    placeholder="Enter City"
                    defaultValue={profileData.mailing_city}
                    className={
                      errors.mailing_city && "profile-form-field-error"
                    }
                  />
                  {errors.mailing_city && (
                    <p className="error-message">
                      {errors.mailing_city.message}
                    </p>
                  )}

                  <label>State</label>
                  <input
                    {...register("mailing_state", {
                      required: "State is required",
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                    })}
                    placeholder="Enter State"
                    defaultValue={profileData.mailing_state}
                    className={
                      errors.mailing_state && "profile-form-field-error"
                    }
                  />
                  {errors.mailing_state && (
                    <p className="error-message">
                      {errors.mailing_state.message}
                    </p>
                  )}

                  <label>Zip Code</label>
                  <input
                    {...register("mailing_zip", {
                      required: "Zip Code is required",
                      pattern: {
                        value: /^\d{5}(-\d{4})?$/,
                        message:
                          "Invalid ZIP Code. Use format: 12345 or 12345-6789",
                      },
                    })}
                    placeholder="Enter Zip Code"
                    defaultValue={profileData.mailing_zip}
                    className={errors.mailing_zip && "profile-form-field-error"}
                  />
                  {errors.mailing_zip && (
                    <p className="error-message">
                      {errors.mailing_zip.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>Emergency Contact #1</b>
            </span>
            <address>
              <p>
                {profileData.emg_contact_1_first_name +
                  " " +
                  profileData.emg_contact_1_last_name}
              </p>
              <p>{profileData.emg_contact_1_relation}</p>
              <p>
                <a
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  className="phone_email_link profile-link"
                  href={`tel:${profileData.emg_contact_1_phone}`}
                  target="_top"
                >
                  {formatPhone(profileData.emg_contact_1_phone)}
                </a>
              </p>
            </address>
            <EditProfile
              label="Emergency Contact #1"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors, setValue) => (
                <>
                  <label>First Name</label>
                  <input
                    {...register("emg_contact_1_first_name", {
                      required: "First name is required",
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                    })}
                    placeholder="Enter First Name"
                    defaultValue={profileData.emg_contact_1_first_name}
                    className={
                      errors.emg_contact_1_first_name &&
                      "profile-form-field-error"
                    }
                  />
                  {errors.emg_contact_1_first_name && (
                    <p className="error-message">
                      {errors.emg_contact_1_first_name.message}
                    </p>
                  )}

                  <label>Last Name</label>
                  <input
                    {...register("emg_contact_1_last_name", {
                      required: "Last name is required",
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                    })}
                    placeholder="Enter Last Name"
                    defaultValue={profileData.emg_contact_1_last_name}
                    className={
                      errors.emg_contact_1_last_name &&
                      "profile-form-field-error"
                    }
                  />
                  {errors.emg_contact_1_last_name && (
                    <p className="error-message">
                      {errors.emg_contact_1_last_name.message}
                    </p>
                  )}

                  <label>Relation</label>
                  <input
                    {...register("emg_contact_1_relation", {
                      required: "Relation is required",
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                    })}
                    placeholder="Enter Relation"
                    defaultValue={profileData.emg_contact_1_relation}
                    className={
                      errors.emg_contact_1_relation &&
                      "profile-form-field-error"
                    }
                  />
                  {errors.emg_contact_1_relation && (
                    <p className="error-message">
                      {errors.emg_contact_1_relation.message}
                    </p>
                  )}

                  <label>Cell Phone</label>
                  <NumberFormat
                    format="###-###-####"
                    mask="_"
                    placeholder="Enter Cell Phone"
                    value={profileData.emg_contact_1_phone}
                    onValueChange={({ value }) => {
                      setValue("emg_contact_1_phone", value);
                    }}
                    className={
                      errors.emg_contact_1_phone && "profile-form-field-error"
                    }
                    isNumericString
                    getInputRef={register("emg_contact_1_phone", {
                      required: "Cell Phone is required",
                      minLength: {
                        value: 10,
                        message: "Cell Phone must be 10 digits long",
                      },
                    })}
                    onKeyDown={preventNegativeValueInDocs}
                  />
                  {errors.emg_contact_1_phone && (
                    <p className="error-message">
                      {errors.emg_contact_1_phone.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>Emergency Contact #2</b>
            </span>
            <address>
              <p>
                {profileData.emg_contact_2_first_name +
                  " " +
                  profileData.emg_contact_2_last_name}
              </p>
              <p>{profileData.emg_contact_2_relation}</p>
              <p>
                <a
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  className="phone_email_link profile-link"
                  href={`tel:${profileData.emg_contact_2_phone}`}
                  target="_top"
                >
                  {formatPhone(profileData.emg_contact_2_phone)}
                </a>
              </p>
            </address>
            <EditProfile
              label="Emergency Contact #2"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors, setValue) => (
                <>
                  <label>First Name</label>
                  <input
                    {...register("emg_contact_2_first_name", {
                      required: "First name is required",
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                    })}
                    placeholder="Enter First Name"
                    defaultValue={profileData.emg_contact_2_first_name}
                    className={
                      errors.emg_contact_2_first_name &&
                      "profile-form-field-error"
                    }
                  />
                  {errors.emg_contact_2_first_name && (
                    <p className="error-message">
                      {errors.emg_contact_2_first_name.message}
                    </p>
                  )}

                  <label>Last Name</label>
                  <input
                    {...register("emg_contact_2_last_name", {
                      required: "Last name is required",
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                    })}
                    placeholder="Enter Last Name"
                    defaultValue={profileData.emg_contact_2_last_name}
                    className={
                      errors.emg_contact_2_last_name &&
                      "profile-form-field-error"
                    }
                  />
                  {errors.emg_contact_2_last_name && (
                    <p className="error-message">
                      {errors.emg_contact_2_last_name.message}
                    </p>
                  )}

                  <label>Relation</label>
                  <input
                    {...register("emg_contact_2_relation", {
                      required: "Relation is required",
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                    })}
                    placeholder="Enter Relation"
                    defaultValue={profileData.emg_contact_2_relation}
                    className={
                      errors.emg_contact_2_relation &&
                      "profile-form-field-error"
                    }
                  />
                  {errors.emg_contact_2_relation && (
                    <p className="error-message">
                      {errors.emg_contact_2_relation.message}
                    </p>
                  )}

                  <label>Cell Phone</label>
                  <NumberFormat
                    format="###-###-####"
                    mask="_"
                    placeholder="Enter Cell Phone"
                    value={profileData.emg_contact_2_phone}
                    onValueChange={({ value }) => {
                      setValue("emg_contact_2_phone", value);
                    }}
                    className={
                      errors.emg_contact_2_phone && "profile-form-field-error"
                    }
                    isNumericString
                    getInputRef={register("emg_contact_2_phone", {
                      required: "Cell Phone is required",
                      minLength: {
                        value: 10,
                        message: "Cell Phone must be 10 digits long",
                      },
                    })}
                    onKeyDown={preventNegativeValueInDocs}
                  />
                  {errors.emg_contact_2_phone && (
                    <p className="error-message">
                      {errors.emg_contact_2_phone.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContactInformationComponent;
