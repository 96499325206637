import { assignmentStatsConstants } from "../_constants";
import { assignmentStatsService } from "../_services";
import { createAsyncReduxAction } from "_helpers/helpers";

export const assignmentStatsActions = {
  getAssignemntStatusTypes,
  getAssignemntStatus,
  getAssignemntHistory,
  saveStatus,
  getAssignemntStatusGraphView,
  getAssignemntStatusProgress,
  historyDelete,
  getAssignmentComplexity,
  getAssignmentFireSize,
  getAssignmentFuelType,
  resetStatus,
};

function getAssignemntStatusTypes() {
  return (dispatch) => {
    dispatch(request());
    assignmentStatsService.getAssignemntStatusTypes().then(
      (applicantStats) => dispatch(success(applicantStats)),
      (error) => dispatch(failure(error)),
    );
  };

  function request() {
    return { type: assignmentStatsConstants.GETASSIGNMENTSTATUSTYPE_REQUEST };
  }
  function success(applicantStats) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUSTYPE_SUCCESS,
      applicantStats,
    };
  }
  function failure(error) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUSTYPE_FAILURE,
      error,
    };
  }
}
function getAssignemntStatus(crewId) {
  return (dispatch) => {
    dispatch(request());
    assignmentStatsService.getAssignemntStatus(crewId).then(
      (applicantStats) => {
        dispatch(success(applicantStats));
      },
      (error) => dispatch(failure(error)),
    );
  };

  function request() {
    return { type: assignmentStatsConstants.GETASSIGNMENTSTATUS_REQUEST };
  }
  function success(applicantStats) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUS_SUCCESS,
      applicantStats,
    };
  }
  function failure(error) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUS_FAILURE,
      error,
    };
  }
}
function historyDelete(id) {
  return (dispatch) => {
    assignmentStatsService.historyDelete(id).then(
      (applicantStats) => dispatch(success(applicantStats)),
      (error) => dispatch(failure(error)),
    );
  };
  function success(applicantStats) {
    return {
      type: assignmentStatsConstants.DELETEHISTORY_SUCCESS,
      applicantStats,
    };
  }
  function failure(error) {
    return { type: assignmentStatsConstants.DELETEHISTORY_FAILURE, error };
  }
}
function getAssignemntHistory(crewId, pageNum) {
  return (dispatch) => {
    dispatch(request());
    assignmentStatsService.getAssignemntHistory(crewId, pageNum).then(
      (assignmentHistory) => dispatch(success(assignmentHistory)),
      (error) => dispatch(failure(error)),
    );
  };

  function request() {
    return { type: assignmentStatsConstants.GETASSIGNMENTHISTORY_REQUEST };
  }
  function success(assignmentHistory) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTHISTORY_SUCCESS,
      assignmentHistory,
    };
  }
  function failure(error) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTHISTORY_FAILURE,
      error,
    };
  }
}

function saveStatus(data) {
  const actionTypes = {
    request: assignmentStatsConstants.ASSIGNMENT_STATUS_SAVED_REQUEST,
    success: assignmentStatsConstants.ASSIGNMENT_STATUS_SAVED_SUCCESS,
    failure: assignmentStatsConstants.ASSIGNMENT_STATUS_SAVED_FAILURE,
  };
  return createAsyncReduxAction(
    assignmentStatsService.saveStatus,
    actionTypes,
  )(data);
}

function getAssignemntStatusGraphView(data, pageNum = 0) {
  return (dispatch) => {
    dispatch(request());
    assignmentStatsService.getAssignemntStatusGraphView(data, pageNum).then(
      (assignmentStatusGraph) => dispatch(success(assignmentStatusGraph)),
      (error) => dispatch(failure(error)),
    );
  };

  function request() {
    return { type: assignmentStatsConstants.GETASSIGNMENTSTATUSGRAPH_REQUEST };
  }
  function success(assignmentStatusGraph) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUSGRAPH_SUCCESS,
      assignmentStatusGraph,
    };
  }
  function failure(error) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUSGRAPH_FAILURE,
      error,
    };
  }
}
function getAssignemntStatusProgress() {
  return (dispatch) => {
    dispatch(request());
    assignmentStatsService.getAssignemntStatusProgress().then(
      (assignemntStatusProgress) => dispatch(success(assignemntStatusProgress)),
      (error) => dispatch(failure(error)),
    );
  };

  function request() {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUSPROGRESS_REQUEST,
    };
  }
  function success(assignemntStatusProgress) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUSPROGRESS_SUCCESS,
      assignemntStatusProgress,
    };
  }
  function failure(error) {
    return {
      type: assignmentStatsConstants.GETASSIGNMENTSTATUSPROGRESS_FAILURE,
      error,
    };
  }
}

function getAssignmentComplexity() {
  return (dispatch) => {
    dispatch(request());

    assignmentStatsService.getAssignmentComplexity().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: assignmentStatsConstants.GET_ASSIGNMENT_COMPLEXITY_REQUEST };
  }
  function success(data) {
    return {
      type: assignmentStatsConstants.GET_ASSIGNMENT_COMPLEXITY_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: assignmentStatsConstants.GET_ASSIGNMENT_COMPLEXITY_FAILURE,
      error,
    };
  }
}

function getAssignmentFireSize() {
  return (dispatch) => {
    dispatch(request());

    assignmentStatsService.getAssignmentFireSize().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: assignmentStatsConstants.GET_ASSIGNMENT_FIRE_SIZE_REQUEST };
  }
  function success(data) {
    return {
      type: assignmentStatsConstants.GET_ASSIGNMENT_FIRE_SIZE_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: assignmentStatsConstants.GET_ASSIGNMENT_FIRE_SIZE_FAILURE,
      error,
    };
  }
}

function getAssignmentFuelType() {
  return (dispatch) => {
    dispatch(request());

    assignmentStatsService.getAssignmentFuelType().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: assignmentStatsConstants.GET_ASSIGNMENT_FUEL_TYPE_REQUEST };
  }
  function success(data) {
    return {
      type: assignmentStatsConstants.GET_ASSIGNMENT_FUEL_TYPE_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: assignmentStatsConstants.GET_ASSIGNMENT_FUEL_TYPE_FAILURE,
      error,
    };
  }
}

function resetStatus() {
  return {
    type: assignmentStatsConstants.ASSIGNMENT_STATUS_SAVED_SUCCESS,
    assignmentStatusSaved: {},
  };
}
