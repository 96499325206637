import React from "react";
import { connect } from "react-redux";
import { applicantsActions } from "../_actions";
import "../assets/styles/applicants.css";
import ReactModal from "react-modal";
import { formatDate } from "_helpers/helpers";
ReactModal.setAppElement("#app");

class ApplicationHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hash: this.props.hash,
      applicationHistory: [],
    };

    this.goBack = this.goBack.bind(this);
    this.viewDetails = this.viewDetails.bind(this);
    this.status = this.status.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(applicantsActions.viewDetails(this.state.hash));
  }

  componentWillReceiveProps(nextProps) {
    let { applicants } = nextProps;

    if (applicants && applicants.list) {
      this.setState({
        applicationHistory: applicants.list.data.appication_history,
      });
    }
  }

  goBack() {
    this.props.goBack();
  }

  viewDetails() {
    this.props.viewDetails(this.state.hash);
  }

  status(status) {
    if (2 == status) {
      return "Completed";
    }
    if (3 == status) {
      return "In review";
    }
    if (1 == status) {
      return "Received";
    }
    if (0 == status || 10 == status) {
      return "Denied";
    }
    if (-1 == status) {
      return "Incomplete";
    }
    if (4 == status) {
      return "Offered Position";
    }
    return "";
  }

  render() {
    return (
      <div className="applicants_container application_details application_history">
        <div className="breadcrumbs">
          <span onClick={this.goBack.bind(this)} className="green">
            Applicants
          </span>{" "}
          / View Applicant
        </div>
        <div className="applicants_inner_head inner_head" />
        <div className="inner_container col-lg-12" id="applicants_inner">
          <div className="clearfix" />
          <div className="applicant-history-container">
            <div className="row">
              <div className="col-md-12">
                <img
                  src={require("../assets/images/left-arrow.svg").default}
                  onClick={this.viewDetails}
                  alt=""
                />
                <h2 className="previous_history">View Previous History</h2>
              </div>
            </div>
            <div className="wrapper">
              <div className="row">
                {this.state.applicationHistory.map((item, index) => (
                  <div key={index} className="col-md-12 item">
                    <div className="flex-row">
                      <div className="icon">
                        <img
                          src={
                            require("../assets/images/history-clock-button-grey.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <div className="">
                        <div className="title">Date Applied</div>
                        <div className="desc">{formatDate(item.date)}</div>
                      </div>
                      <div className="">
                        <div className="title">Status</div>
                        <div className="desc">{this.status(item.status)}</div>
                      </div>
                      <div className="note">
                        <div className="title">Notes</div>
                        <div className="desc">{item.notes || "--"}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { applicants } = state;
  return {
    applicants,
  };
}

const connectedHomePage = connect(mapStateToProps)(ApplicationHistory);
export { connectedHomePage as ApplicationHistory };
