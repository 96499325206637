import React, { Component } from "react";
import { Widgets } from "./Widgets";
import { Docs } from "./Docs/Docs";
import { Switch, Route } from "react-router-dom";
import "../assets/styles/docs.scss";
import "../assets/styles/responsive.scss";
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire:
        new URLSearchParams(this.props.location.search).get("rehire") == "true",
    };
  }

  componentWillReceiveProps() {
    this.setState({
      rehire:
        new URLSearchParams(this.props.location.search).get("rehire") == "true",
    });
  }

  render() {
    let { path } = this.props.match;

    return (
      <div>
        <Switch>
          <Route
            path={`${path}/docs/:doc`}
            render={(props) => <Docs {...props} rehire={this.state.rehire} />}
          />
          <Route path={path} component={Widgets} />
        </Switch>
      </div>
    );
  }
}

export { HomePage };
