import React from "react";
import { connect } from "react-redux";
import { config } from "../_helpers";
import Modal from "react-responsive-modal";
import { permissionActions } from "../_actions";
import ReactModal from "react-modal";
ReactModal.setAppElement("#app");

class Permissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_array: [],
      admin_list: [],
      user_list: [],
      unassignOpen: false,
      allAdminOpen: false,
      delete_hash: "",
      hideSearch: "hide",
      statusOpen: false,
      user_name: "",
      user_hash: "",
      permission_list: "",
    };
    this.unassignOpenModal = this.unassignOpenModal.bind(this);
    this.unassignCloseModal = this.unassignCloseModal.bind(this);
    this.allAdminOpenModal = this.allAdminOpenModal.bind(this);
    this.allAdminCloseModal = this.allAdminCloseModal.bind(this);
    this.deleteAdmin = this.deleteAdmin.bind(this);
    this.keyUpFn = this.keyUpFn.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.userClick = this.userClick.bind(this);
    this.assignAdmin = this.assignAdmin.bind(this);
    this.onImageError = this.onImageError.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(permissionActions.getAll());
    this.props.dispatch(permissionActions.getAdminList());
    this.props.dispatch(permissionActions.getPermission());
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  componentWillReceiveProps(nextProps) {
    const permission = nextProps.adminpermission;
    const { adminList } = nextProps.permission;
    var permission_array = [],
      Superintendent = [],
      Operations = [],
      Manager = [],
      Firefighter = [],
      Captains = [],
      Staff = [],
      Technician = [],
      admin_list = [],
      user_list = [];
    if (permission.permissions) {
      var permission_list = permission.permissions.data.permission;
      Superintendent = permission.permissions.data.roles["Superintendent"];
      Manager = permission.permissions.data.roles["Staff Manager"];
      Operations = permission.permissions.data.roles["DCR Operations Manager"];
      Firefighter = permission.permissions.data.roles["Firefighter"];
      Captains = permission.permissions.data.roles["Captains"];
      Staff = permission.permissions.data.roles["Office Staff"];
      Technician = permission.permissions.data.roles["GISS Technician"];
      permission_list.map((item, index) => {
        //this is a quick fix for word spacing
        if (item.name.includes("/")) {
          const modifiedName = item.name.replace(/\//g, " / ");
          item.name = modifiedName;
        }
        permission_array.push(
          <tr
            key={"parent" + index + item.id}
            className={item.nav === "0" ? "parent" : "child"}
          >
            <td className="title permission-title" colSpan="2">
              {item.name}
            </td>
            <td data-header-title="Staff Manager">
              <input
                type="checkbox"
                id={"Manager" + item.id}
                className="permission_ck manager_ck"
                defaultChecked={
                  Manager.indexOf(item.id) !== -1 ? "checked" : ""
                }
                onClick={this.setPermission.bind(this, "Manager", item.id)}
              />
              <label htmlFor={"Manager" + item.id}>
                <span></span>
              </label>
            </td>
            <td data-header-title="Superintendent">
              <input
                type="checkbox"
                id={"Superintendent" + item.id}
                className="permission_ck superin_ck"
                defaultChecked={
                  Superintendent.indexOf(item.id) !== -1 ? "checked" : ""
                }
                onChange={this.setPermission.bind(
                  this,
                  "Superintendent",
                  item.id,
                )}
              />
              <label htmlFor={"Superintendent" + item.id}>
                <span></span>
              </label>
            </td>
            <td data-header-title="DCR Operations Manager">
              <input
                type="checkbox"
                id={"Operations" + item.id}
                className="permission_ck operations_ck"
                defaultChecked={
                  Operations.indexOf(item.id) !== -1 ? "checked" : ""
                }
                onChange={this.setPermission.bind(this, "Operations", item.id)}
              />
              <label htmlFor={"Operations" + item.id}>
                <span></span>
              </label>
            </td>
            <td data-header-title="Captains">
              <input
                type="checkbox"
                id={"Captains" + item.id}
                className="permission_ck captain_ck"
                defaultChecked={
                  Captains.indexOf(item.id) !== -1 ? "checked" : ""
                }
                onChange={this.setPermission.bind(this, "Captains", item.id)}
              />
              <label htmlFor={"Captains" + item.id}>
                <span></span>
              </label>
            </td>
            <td data-header-title="Firefighter">
              <input
                type="checkbox"
                id={"Firefighter" + item.id}
                className="permission_ck fire_ck"
                defaultChecked={
                  Firefighter.indexOf(item.id) !== -1 ? "checked" : ""
                }
                onChange={this.setPermission.bind(this, "Firefighter", item.id)}
              />
              <label htmlFor={"Firefighter" + item.id}>
                <span></span>
              </label>
            </td>
            <td data-header-title="Office Staff">
              <input
                type="checkbox"
                id={"Staff" + item.id}
                className="permission_ck staff_ck"
                defaultChecked={Staff.indexOf(item.id) !== -1 ? "checked" : ""}
                onChange={this.setPermission.bind(this, "Staff", item.id)}
              />
              <label htmlFor={"Staff" + item.id}>
                <span></span>
              </label>
            </td>
            <td data-header-title="GISS Technician">
              <input
                type="checkbox"
                id={"Technician" + item.id}
                className="permission_ck staff_ck"
                defaultChecked={
                  Technician.indexOf(item.id) !== -1 ? "checked" : ""
                }
                onChange={this.setPermission.bind(
                  this,
                  "GISS Technician",
                  item.id,
                )}
              />
              <label htmlFor={"Technician" + item.id}>
                <span></span>
              </label>
            </td>
          </tr>,
        );
        item.child.map((child, i) => {
          //this is a quick fix for word spacing
          if (child.name.includes("/")) {
            const modifiedName = child.name.replace(/\//g, " / ");
            child.name = modifiedName;
          }
          permission_array.push(
            <tr
              key={"child" + i + item.id}
              className={child.nav === "0" ? "parent" : "child"}
            >
              <td className="title permission-name" colSpan="2">
                {child.name}
              </td>
              <td data-header-title="Staff Manager">
                <input
                  type="checkbox"
                  disabled={
                    Manager.indexOf(parseInt(child.nav)) !== -1
                      ? ""
                      : "disabled"
                  }
                  id={"Manager" + child.id}
                  className={
                    Manager.indexOf(parseInt(child.nav)) !== -1
                      ? "permission_ck manager_ck"
                      : "permission_ck manager_ck disabled"
                  }
                  defaultChecked={
                    Manager.indexOf(child.id) !== -1 ? "checked" : ""
                  }
                  onChange={this.setPermission.bind(this, "Manager", child.id)}
                />
                <label htmlFor={"Manager" + child.id}>
                  <span></span>
                </label>
              </td>
              <td data-header-title="Superintendent">
                <input
                  type="checkbox"
                  id={"Superintendent" + child.id}
                  disabled={
                    Superintendent.indexOf(parseInt(child.nav)) !== -1
                      ? ""
                      : "disabled"
                  }
                  className={
                    Superintendent.indexOf(parseInt(child.nav)) !== -1
                      ? "permission_ck manager_ck"
                      : "permission_ck manager_ck disabled"
                  }
                  defaultChecked={
                    Superintendent.indexOf(child.id) !== -1 ? "checked" : ""
                  }
                  onChange={this.setPermission.bind(
                    this,
                    "Superintendent",
                    child.id,
                  )}
                />
                <label htmlFor={"Superintendent" + child.id}>
                  <span></span>
                </label>
              </td>
              <td data-header-title="DCR Operations Manager">
                <input
                  type="checkbox"
                  id={"Operations" + child.id}
                  disabled={
                    Operations.indexOf(parseInt(child.nav)) !== -1
                      ? ""
                      : "disabled"
                  }
                  className={
                    Operations.indexOf(parseInt(child.nav)) !== -1
                      ? "permission_ck manager_ck"
                      : "permission_ck manager_ck disabled"
                  }
                  defaultChecked={
                    Operations.indexOf(child.id) !== -1 ? "checked" : ""
                  }
                  onChange={this.setPermission.bind(
                    this,
                    "Operations",
                    child.id,
                  )}
                />
                <label htmlFor={"Operations" + child.id}>
                  <span></span>
                </label>
              </td>
              <td data-header-title="Captains">
                <input
                  type="checkbox"
                  id={"Captains" + child.id}
                  disabled={
                    Captains.indexOf(parseInt(child.nav)) !== -1
                      ? ""
                      : "disabled"
                  }
                  className={
                    Captains.indexOf(parseInt(child.nav)) !== -1
                      ? "permission_ck manager_ck"
                      : "permission_ck manager_ck disabled"
                  }
                  defaultChecked={
                    Captains.indexOf(child.id) !== -1 ? "checked" : ""
                  }
                  onChange={this.setPermission.bind(this, "Captains", child.id)}
                />
                <label htmlFor={"Captains" + child.id}>
                  <span></span>
                </label>
              </td>
              <td data-header-title="Firefighter">
                <input
                  type="checkbox"
                  id={"Firefighter" + child.id}
                  disabled={
                    Firefighter.indexOf(parseInt(child.nav)) !== -1
                      ? ""
                      : "disabled"
                  }
                  className={
                    Firefighter.indexOf(parseInt(child.nav)) !== -1
                      ? "permission_ck manager_ck"
                      : "permission_ck manager_ck disabled"
                  }
                  defaultChecked={
                    Firefighter.indexOf(child.id) !== -1 ? "checked" : ""
                  }
                  onChange={this.setPermission.bind(
                    this,
                    "Firefighter",
                    child.id,
                  )}
                />
                <label htmlFor={"Firefighter" + child.id}>
                  <span></span>
                </label>
              </td>
              <td data-header-title="Office Staff">
                <input
                  type="checkbox"
                  id={"Staff" + child.id}
                  disabled={
                    Staff.indexOf(parseInt(child.nav)) !== -1 ? "" : "disabled"
                  }
                  className={
                    Staff.indexOf(parseInt(child.nav)) !== -1
                      ? "permission_ck manager_ck"
                      : "permission_ck manager_ck disabled"
                  }
                  defaultChecked={
                    Staff.indexOf(child.id) !== -1 ? "checked" : ""
                  }
                  onChange={this.setPermission.bind(this, "Staff", child.id)}
                />
                <label htmlFor={"Staff" + child.id}>
                  <span></span>
                </label>
              </td>
              <td data-header-title="GISS Technician">
                <input
                  type="checkbox"
                  id={"Technician" + child.id}
                  disabled={
                    Technician.indexOf(parseInt(child.nav)) !== -1
                      ? ""
                      : "disabled"
                  }
                  className={
                    Technician.indexOf(parseInt(child.nav)) !== -1
                      ? "permission_ck manager_ck"
                      : "permission_ck manager_ck disabled"
                  }
                  defaultChecked={
                    Technician.indexOf(child.id) !== -1 ? "checked" : ""
                  }
                  onChange={this.setPermission.bind(
                    this,
                    "GISS Technician",
                    child.id,
                  )}
                />
                <label htmlFor={"Technician" + child.id}>
                  <span></span>
                </label>
              </td>
            </tr>,
          );
          return permission_array;
        });
        return permission_array;
      });

      this.setState({ permission_array, permission_list });
    }

    /** Admin list**/
    const { allAdmin } = nextProps.adminpermission;
    if (adminList) {
      if (adminList.data) {
        adminList.data.map((item, index) => {
          admin_list.push(
            <div key={index}>
              <img
                alt={item.first_name + " " + item.last_name}
                src={
                  item.profile_photo
                    ? config.apiUrl + "/" + item.profile_photo
                    : config.apiUrl + "/" + config.altImg
                }
                onError={this.onImageError.bind(this)}
              />
              <p>
                <span className="user-name">
                  {item.first_name + " " + item.last_name} -{" "}
                </span>
                <span className="user-permission">
                  {item.user_type !== "Admin"
                    ? "Admin (" + item.user_type + ")"
                    : item.user_type}
                </span>

                {item.is_admin !== 2 && (
                  <span
                    className="remove-admin"
                    onClick={this.unassignOpenModal.bind(this, item.hash)}
                  ></span>
                )}
              </p>
            </div>,
          );
          return admin_list;
        });
        this.setState({ admin_list });
      }
    }
    //

    if (allAdmin) {
      const userSearchList = allAdmin.data;
      if (userSearchList) {
        userSearchList.data.map((item, index) => {
          var name = item.first_name + " " + item.last_name;
          user_list.push(
            <li
              className="user_click"
              onClick={this.userClick.bind(this, name, item.hash)}
              key={index}
            >
              <span>{item.first_name + " " + item.last_name}</span>
              <img
                alt={item.first_name + " " + item.last_name}
                src={
                  item.profile_photo
                    ? config.apiUrl + "/" + item.profile_photo
                    : config.apiUrl + "/" + config.altImg
                }
                onError={this.onImageError.bind(this)}
              />
            </li>,
          );
          return user_list;
        });
        this.setState({ user_list });
      }
    }
  }

  keyUpFn(e) {
    var textInput = document.getElementById("txtSearchUsersForAssign");
    clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(
      function () {
        this.headerSearch(textInput.value);
      }.bind(this),
      1000,
    );
  }

  userClick(name, hash, event) {
    this.setState({ user_name: name, user_hash: hash });
    document.getElementById("txtSearchUsersForAssign").value = name;
    this.setState({ hideSearch: "hide" });
  }

  headerSearch(text) {
    // var content = "";
    // var dropdown = document.getElementById('searchResultTop');
    // dropdown.innerHTML = '';
    let data = new FormData();
    data.append("keyword", text);

    if (text.length === 0) {
      this.setState({ hideSearch: "hide" });
    } else if (text.length >= 1) {
      // document.getElementById('searchResultTop').innerHTML = '';
      this.setState({ hideSearch: "show", search_close: "show" });
      // dropdown.innerHTML = '';
      this.props.dispatch(permissionActions.adminSearch(data));
    } else {
      //   dropdown.innerHTML = '';
      this.setState({ formShow: "show" });
    }
  }

  unassignCloseModal() {
    this.setState({ unassignOpen: false });
  }

  unassignOpenModal(hash) {
    this.setState({ unassignOpen: true, delete_hash: hash });
  }

  allAdminCloseModal() {
    this.setState({
      allAdminOpen: false,
      user_list: [],
      hideSearch: "hide",
      user_name: "",
    });
  }

  allAdminOpenModal() {
    this.setState({ allAdminOpen: true });
  }

  statusOpenModal() {
    this.setState({ statusOpen: true });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }

  deleteAdmin(hash) {
    let data = new FormData();
    data.append("hash", hash);
    this.props.dispatch(permissionActions.changeUserType(data));
    this.setState({ unassignOpen: false });
  }

  assignAdmin(hash) {
    let data = new FormData();
    data.append("hash", hash);
    this.props.dispatch(permissionActions.changeUserType(data));
    this.setState({ allAdminOpen: false, user_name: "" });
  }

  setPermission(usertype, id, e) {
    var status;
    if (e.target.checked) {
      status = 1;
    } else {
      status = 0;
    }
    let data = new FormData();
    data.append("usertype", usertype);
    data.append("permissionId", id);
    data.append("status", status);
    this.props.dispatch(permissionActions.setPermission(data));
  }

  render() {
    // const { adminpermission } = this.props;

    if (this.state.permission_list) {
      return (
        <div className="content_div permissions">
          <table id="permission-tbl">
            <thead>
              <tr>
                <th className="title" colSpan="2">
                  &nbsp;
                </th>
                <th>Staff Manager</th>
                <th>Superintendent</th>
                <th>DCR Operations Manager</th>
                <th>Captains</th>
                <th>Firefighter</th>
                <th>Office Staff</th>
                <th>GISS Technician</th>
              </tr>
            </thead>
            <tbody>{this.state.permission_array}</tbody>
          </table>

          {/* Admin Listing */}
          <div className="assign_admin_outer">
            <h3>Assign Administrator</h3>
            <div className="assign-admin-container">
              <div className="admin-list">{this.state.admin_list}</div>
              <div className="clearFix"></div>
              <button
                className="assign-admin button"
                onClick={this.allAdminOpenModal.bind(this)}
                // onClick={this.statusOpenModal.bind(this)}
              >
                Assign admin
              </button>
            </div>
          </div>

          <Modal
            id="unassignAdmin_popup"
            className="unassignAdmin_popup"
            open={this.state.unassignOpen}
            onClose={this.unassignCloseModal.bind(this)}
            little
          >
            <div id="unassignPopup">
              <p className="">Are you sure you want to remove this admin? </p>
              <button
                className="button grey"
                onClick={this.unassignCloseModal.bind(this)}
              >
                Cancel
              </button>
              <button
                className="button"
                onClick={this.deleteAdmin.bind(this, this.state.delete_hash)}
              >
                Delete
              </button>
            </div>
          </Modal>

          <Modal
            open={this.state.allAdminOpen}
            onClose={this.allAdminCloseModal.bind(this)}
            little
          >
            <div id="remove_applnt" className="modal-body">
              <div id="allAdminPopup">
                <label>Search Employee:</label>
                <input
                  type="text"
                  id="txtSearchUsersForAssign"
                  name="txtSearchUsersForAssign"
                  defaultValue={this.state.user_name}
                  className="txtSearchUsersForAssign"
                  onKeyUp={this.keyUpFn}
                  autoComplete="off"
                />
                <ul
                  id="searchResultTop"
                  className={"searchResultUl " + this.state.hideSearch}
                >
                  {this.state.user_list}
                </ul>
                <p>Are you sure you want to add this user as admin?</p>
                <button
                  className="button grey"
                  onClick={this.allAdminCloseModal.bind(this)}
                >
                  Cancel
                </button>
                <button
                  className="button"
                  onClick={this.assignAdmin.bind(this, this.state.user_hash)}
                >
                  Assign
                </button>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
    return (
      <div className="content_div permissions">
        <center
          id="feedLoader"
          className={this.state.loader ? this.state.loader : ""}
        >
          <img
            alt="Loader"
            src={require("../assets/images/loader.gif").default}
          />
        </center>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { adminpermission, permission } = state;
  return {
    adminpermission,
    permission,
  };
}

const connectedSettingsPage = connect(mapStateToProps)(Permissions);
export { connectedSettingsPage as Permissions };
