import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { isQualificationReportLoading } from "_helpers/reduxSelector";
import moment from "moment";
import { config } from "_helpers/config";
import _invert from "lodash/invert";
import { userStatus } from "_constants";

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const DATE_FORMAT = "MM/DD/YYYY";

const QualificationReportList = ({
  getQualificationReport,
  data,
  lastPage,
  currentFilters,
  qualifications = [],
  setCurrentSort,
  setCurrentOrder,
}) => {
  const [page, setPage] = React.useState(0);

  const isLoading = useSelector((state) => isQualificationReportLoading(state));
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("last_name");
  const [styleClass, setStyleClass] = useState({});
  const [columns, setColumns] = useState(qualifications);
  // debugger

  const status = React.useMemo(() => _invert(userStatus), []);

  useEffect(() => {
    setColumns(qualifications);
  }, [qualifications]);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    getQualificationReport(page + 1, sortItem, sortOrder);
  }, [currentFilters]);

  const getTableHead = () => {
    return columns?.sort(compareHeader)?.map((qual) => {
      return (
        <th
          onClick={() => sortList(`${qual?.key}Class`, qual?.id)}
          key={qual?.key}
        >
          {/* <th key={qual?.key}> */}
          <div className="report_table_header full__header">
            <span>{qual?.key}</span>
            <span className={styleClass[`${qual?.key}Class`] || ASC_CLASS} />
          </div>
        </th>
      );
    });
  };

  const handlePageChange = (newPage) => {
    const sortOrder = order ? "asc" : "desc";
    getQualificationReport(
      newPage?.selected + 1,
      sortItem || "last_name",
      sortOrder,
    );
    setPage(newPage?.selected);
  };

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    setCurrentSort(sortItem);
    setCurrentOrder(sortOrder);
    if (sortItem) {
      getQualificationReport(page + 1, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, sort) => {
    const newStyle = Object.keys(styleClass).length
      ? styleClass[className] === DESC_CLASS
        ? ASC_CLASS
        : DESC_CLASS
      : DESC_CLASS;
    const nextOrder = styleClass[className] !== DESC_CLASS ? false : true;
    setStyleClass({ [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  const compareColumns = (a, b) => {
    if (Number(a.qualification_id) < Number(b.qualification_id)) {
      return -1;
    }
    if (Number(a.qualification_id) > Number(b.qualification_id)) {
      return 1;
    }
    return 0;
  };

  const compareHeader = (a, b) => {
    if (Number(a.id) < Number(b.id)) {
      return -1;
    }
    if (Number(a.id) > Number(b.id)) {
      return 1;
    }
    return 0;
  };

  const noDataAvailable =
    !isLoading && (!data?.length || !currentFilters?.length);

  return (
    <div>
      <div className="history_lists outer_list qualification_report table__scroll">
        {!isLoading && data.length > 0 && currentFilters?.length ? (
          <table className="mobile_hide report_list_width_full">
            <tr className="report_list_table_row">
              <th
                onClick={() => sortList("nameClass", "first_name")}
                className="sticky-col first-col full__header"
              >
                <div className="report_table_header full__header">
                  <span>Employee Name</span>
                  <span className={styleClass["nameClass"] || ASC_CLASS} />
                </div>
              </th>
              <th
                onClick={() => sortList("statusClass", "status")}
                className="full__header"
              >
                <div className="report_table_header full__header">
                  <span>Status</span>
                  <span className={styleClass["statusClass"] || ASC_CLASS} />
                </div>
              </th>
              {getTableHead()}
            </tr>
            <tbody className="report_list_table_tbody">
              {noDataAvailable ? (
                <tr>
                  <td colSpan="12">
                    <div className="dashboard_expiration_date_no_data">
                      No Data Found
                    </div>
                  </td>
                </tr>
              ) : (
                data.map((item, index) => (
                  <tr key={index}>
                    <td className="sticky-col first-col">
                      {" "}
                      <a
                        href={`${config.baseUrl}profile/${item?.hash}`}
                        target="_blank"
                        rel="noreferrer"
                      >{`${item?.last_name} ${item?.first_name}`}</a>
                    </td>
                    <td className="">
                      <td>{status?.[item?.active]}</td>
                    </td>
                    {item?.qualification_details
                      ?.filter((it) =>
                        columns.some((obj) => obj.id == it.qualification_id),
                      )
                      ?.sort(compareColumns)
                      ?.map((qual, i) => {
                        if (i < columns.length) {
                          return (
                            <td>
                              {qual.qualifying_date
                                ? moment(qual.qualifying_date).format(
                                    DATE_FORMAT,
                                  )
                                : "-"}
                            </td>
                          );
                        } else {
                          return undefined;
                        }
                      })}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        ) : (
          <center id="feedLoader">
            {currentFilters?.length ? (
              <img
                alt="Loader"
                src={require("../assets/images/loader.gif").default}
              />
            ) : (
              "No Data Found"
            )}
          </center>
        )}
        {noDataAvailable ? (
          <center id="feedLoader">
            <div className="dashboard_expiration_date_no_data for_mobile_history_list">
              No Data Found
            </div>
          </center>
        ) : (
          <QualificationReportListMobileView
            data={data}
            getQualificationReport={getQualificationReport}
            columns={columns}
            setCurrentSort={setCurrentSort}
            setCurrentOrder={setCurrentOrder}
          />
        )}
      </div>
      {!isLoading && data.length > 0 && currentFilters?.length ? (
        <div className="fs-pagination-wrapper-outer" key={7}>
          <div className="customised-pagination right-align">
            <ReactPaginate
              forcePage={page}
              previousLabel={""}
              nextLabel={""}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={lastPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => handlePageChange(data)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default QualificationReportList;

const QualificationReportListMobileView = ({
  data,
  columns,
  getQualificationReport,
  setCurrentSort,
  setCurrentOrder,
}) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("last_name");
  const [styleClass, setStyleClass] = useState({});

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    setCurrentSort(sortItem);
    setCurrentOrder(sortOrder);
    if (sortItem) {
      getQualificationReport(0, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, sort) => {
    const newStyle = Object.keys(styleClass).length
      ? styleClass[className] === DESC_CLASS
        ? ASC_CLASS
        : DESC_CLASS
      : DESC_CLASS;
    const nextOrder = styleClass[className] !== DESC_CLASS ? false : true;
    setStyleClass({ [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  const createColumns = (item) => {
    return columns?.map((col, index) => (
      <div className="status_item">
        {/* <div className="list_item" key={col?.key}> */}
        <div
          className="list_item"
          onClick={() => sortList(`${col?.key}Class`, col?.id)}
          key={col?.key}
        >
          <strong>{col?.key}</strong>
          <span className={styleClass[`${col?.key}Class`] || ASC_CLASS} />
        </div>
        <div className="list_item">
          {item[index]?.qualifying_date
            ? moment(item[index]?.qualifying_date).format(DATE_FORMAT)
            : "-"}
        </div>
      </div>
    ));
  };

  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("nameClass", "first_name")}
            >
              <strong>Employee Name</strong>
              <span className={styleClass["nameClass"] || ASC_CLASS} />
            </div>
            <div className="list_item">
              <a
                href={`${config.baseUrl}profile/${item?.hash}`}
                target="_blank"
                rel="noreferrer"
              >{`${item?.first_name} ${item?.last_name}`}</a>
            </div>
          </div>
          {createColumns(item?.qualification_details)}
        </div>
      </div>
    </React.Fragment>
  ));
};
