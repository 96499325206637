import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import { isEquipmentSoldReportLoading } from "_helpers/reduxSelector";
import { formatDate } from "_helpers/helpers";

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  idClass: ASC_CLASS,
  soldDateClass: ASC_CLASS,
  soldValueClass: ASC_CLASS,
  resourceClass: ASC_CLASS,
};

const EquipmentSoldReportList = ({
  getEquipmentSoldReport,
  data,
  lastPage,
  fromDate,
  toDate,
  setSortParams,
}) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("unique_id");
  const [page, setPage] = useState(0);
  const isLoading = useSelector((state) => isEquipmentSoldReportLoading(state));
  const [
    { idClass, soldDateClass, soldValueClass, resourceClass },
    setStyleState,
  ] = useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getEquipmentSoldReport(page, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const handlePageChange = (newPage) => {
    const sortOrder = order ? "asc" : "desc";
    getEquipmentSoldReport(newPage?.selected + 1, sortItem, sortOrder);
    setPage(newPage?.selected);
  };

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams(sort, nextOrder);
  };

  React.useEffect(() => {
    setPage(0);
  }, [fromDate, toDate]);

  const noDataAvailable = !isLoading && !data?.length;
  return (
    <>
      <div className="history_lists outer_list table__scroll">
        <table className="mobile_hide report_list_width_full">
          <thead>
            <tr className="report_list_table_row">
              <th onClick={() => sortList("idClass", idClass, "unique_id")}>
                <span>Unique ID</span>
                <span className={idClass} />
              </th>
              <th
                onClick={() =>
                  sortList("resourceClass", resourceClass, "resource_name")
                }
              >
                <span>Resource</span>
                <span className={resourceClass} />
              </th>
              <th
                onClick={() =>
                  sortList("soldDateClass", soldDateClass, "sold_date")
                }
              >
                <span>Sold Date</span>
                <span className={soldDateClass} />
              </th>
              <th
                onClick={() =>
                  sortList("soldValueClass", soldValueClass, "sold_value")
                }
              >
                <span>Sold Value</span>
                <span className={soldValueClass} />
              </th>
            </tr>
          </thead>
          <tbody className="report_list_table_tbody">
            {noDataAvailable ? (
              <tr>
                <td colSpan="4">
                  <div className="dashboard_expiration_date_no_data">
                    No Data Found
                  </div>
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{item?.unique_id ?? "-"}</td>
                  <td>{item?.resource_name ?? "-"}</td>
                  <td>{formatDate(item?.sold_date) ?? "-"}</td>
                  <td>{item?.sold_value ?? "-"}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {noDataAvailable ? (
          <div className="dashboard_expiration_date_no_data for_mobile_history_list">
            No Data Found
          </div>
        ) : (
          <EquipmentSoldReportListMobileView
            data={data}
            getEquipmentSoldReport={getEquipmentSoldReport}
            setSortParams={setSortParams}
          />
        )}
      </div>
      {!isLoading && data?.length ? (
        <div className="fs-pagination-wrapper-outer" key={7}>
          <div className="customised-pagination right-align">
            <ReactPaginate
              forcePage={page}
              previousLabel={""}
              nextLabel={""}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={lastPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => handlePageChange(data)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default EquipmentSoldReportList;

const EquipmentSoldReportListMobileView = ({
  data,
  getEquipmentSoldReport,
  setSortParams,
}) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("unique_id");
  const [{ idClass, soldDateClass, soldValueClass }, setStyleState] =
    useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getEquipmentSoldReport(0, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams?.(sort, nextOrder);
  };
  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("idClass", idClass, "unique_id")}
            >
              <strong>Unique ID</strong>
              <span className={idClass} />
            </div>
            <div className="list_item">{item?.unique_id ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("soldDateClass", soldDateClass, "sold_date")
              }
            >
              <strong>Sold Date</strong>
              <span className={soldDateClass} />
            </div>
            <div className="list_item">{item?.sold_date ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("soldValueClass", soldValueClass, "sold_value")
              }
            >
              <strong>Sold Value</strong>
              <span className={soldValueClass} />
            </div>
            <div className="list_item">{item?.sold_value ?? "-"}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};
