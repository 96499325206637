import React, { Component } from 'react';
import { config } from '../_helpers/config';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { docsActions } from '../_actions/docs.actions';
class DocsWelcomeRehire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generateLoader: false,
    };
    this.generateDocs = this.generateDocs.bind(this);
  }

  generateDocs() {
    this.setState({ generateLoader: true });
    const rehire = true;
    this.props.dispatch(docsActions.generateDocs(rehire)).then((res) => {
      if (res.success) {
        let { path } = this.props.match;
        this.props.history.push(
          `${path}/docs/employee-re-hire-form?rehire=true`,
        );
      }
    });
  }

  render() {
    let user = this.props.user.profile ? this.props.user.profile.data : {};

    return (
      <div className='row'>
        <div className='col-md-12'>
          <div className='onboarding-docs'>
            <div className='doc-home'>
              <div className='doc-home-info'>
                <img
                  src={`${config.assetUrl}icons/doc-home.svg`}
                  className='doc-home-img'
                  alt=''
                />
                <h3 className='doc-username'>
                  Hi, {user.first_name} {user.last_name}.
                </h3>
                {user &&
                  (user.user_type_id === 11 || user.user_type_id === 12) && (
                    <div>
                      <p>
                        Welcome to Deer Creek Resources! We are now a part of
                        Firestorm Wildland Fire Suppression Inc. You are an
                        important member of a team effort. We hope that you will
                        find your position with the Company rewarding,
                        challenging and productive.
                      </p>

                      <p>
                        Please fill out the Documents to complete your
                        onboarding process.
                      </p>

                      <p>
                        If you have any questions, feel free to contact us at{' '}
                        <a href='tel:530-898-8153'>530-898-8153</a>.
                      </p>

                      <p>We look forward to working with you this year!</p>
                    </div>
                  )}
                {user &&
                  user.user_type_id !== 11 &&
                  user.user_type_id !== 12 && (
                    <p>Please fill out the Rehire Documents to continue.</p>
                  )}
                <button
                  disabled={this.state.generateLoader}
                  onClick={this.generateDocs}
                  className='doc-btn'
                >
                  Rehire Documents
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

let connectedDocsWelcome = compose(
  withRouter,
  connect(mapStateToProps),
)(DocsWelcomeRehire);
export { connectedDocsWelcome as DocsWelcomeRehire };
