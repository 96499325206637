import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardActions,
  assignmentStatsActions,
  userActions,
} from "_actions";
import { dashboardService } from "../_services/dashboard.service";
import "../assets/styles/report.css";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Modal from "_components/utils/Modal/Modal";
import FireHistoryReportList from "./FireHistoryReport";
import AllManifest from "_components/AllManifest";
import { handleErrorResponse } from "_helpers/helpers";
import {
  selectFireHistoryReport,
  getAssignmentStats,
  getAssignmentStatusTypes,
  getSelectedUserInfo,
} from "_helpers/reduxSelector";
import { toast } from "react-toastify";
import { redirectToPdf } from "_helpers/redirectToPdf";
import { dateTimeConstants } from "_constants";

const datePickerPopperModifiers = [
  {
    name: "offset",
    options: {
      offset: [5, 10],
    },
  },
  {
    name: "customStyle",
    enabled: true,
    fn: (data) => {
      data.styles.width = "300px";
      return data;
    },
  },
];

const momentInstance = moment();
const FIRE_HISTORY_TYPE_SLUG = {
  MANUAL_ASSIGN_MANIFEST: "manual_assign",
};

const FireHistoryReportMain = (props) => {
  const dispatch = useDispatch();
  const [selectedResource, setSelectedResource] = React.useState();
  const [addModalOpen, setAddModalOpen] = React.useState(false);
  const [inputValues, setInputValues] = useState({
    incidentName: "",
    location: "",
    shifts: "",
    hotlineShifts: "",
  });

  const [selectedOptions, setSelectedOptions] = useState({
    assignment: null,
    complexity: null,
    fuelType: null,
    fireSize: null,
    startDate: momentInstance,
    endDate: "",
  });

  const [sortAndOrder, setSortAndOrder] = React.useState({
    order: "desc",
    sortBy: "type",
  });

  const legacy = window.location.pathname.includes("legacy");

  const fireHistoryReport = useSelector(selectFireHistoryReport);
  const assignmentStats = useSelector(getAssignmentStats);
  const currentUserInfo = useSelector(getSelectedUserInfo);
  const API_req_date_format = dateTimeConstants.ISO_DATE_FORMAT;

  let assignmentStatusTypesOptions = useSelector(getAssignmentStatusTypes);
  assignmentStatusTypesOptions = assignmentStatusTypesOptions?.map(
    (option) => ({
      value: option.id,
      label: option.value,
    }),
  );

  let listComplexity = assignmentStats?.persist?.complexity;
  listComplexity = listComplexity.map((option) => ({
    value: option.value,
    label: option.value,
  }));

  let listFuelType = assignmentStats?.persist?.fuelType;
  listFuelType = listFuelType.map((option) => ({
    value: option.value,
    label: option.value + "- " + option.label,
  }));

  let listFireSize = assignmentStats?.persist?.fireSize;
  listFireSize = listFireSize.map((option) => ({
    value: option.value,
    label: option.value + "- " + option.label,
  }));

  const handleSelectChange = (selectedValue, selectName) => {
    // debugger;
    setSelectedOptions({
      ...selectedOptions,
      [selectName]: selectedValue,
    });
  };

  const handleCloseAddModal = () => {
    setAddModalOpen(false);
    resetFormValues();
  };

  const handleInputChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const userHash = props?.match?.params?.hash;

  const getFireHistoryByHash = (newPage, sort, order) => {
    handleSortAndOrderForPrint(sort, order);
    let data = new FormData();
    data.append("page", newPage || 0);
    data.append("hash", userHash);
    data.append("sort", sort || "type");
    data.append("order", order || "desc");
    data.append("legacy", legacy);
    dispatch(dashboardActions.getFireHistory(data));
  };

  const onPrintClick = () => {
    let data = new FormData();
    data.append("hash", userHash);
    data.append("sort", sortAndOrder?.sortBy);
    data.append("order", sortAndOrder?.order);
    dashboardService
      .generateFireHistory(data)
      .then((res) => redirectToPdf(res, 'fire_history_report_' + Date.now()));
  };

  const handleSortAndOrderForPrint = (sortBy, order) => {
    let _sortAndOrder = { ...sortAndOrder };
    _sortAndOrder.sortBy = sortBy || _sortAndOrder.sortBy;
    _sortAndOrder.order = order || _sortAndOrder.order;
    setSortAndOrder(_sortAndOrder);
  };

  const getData = () => {
    dispatch(assignmentStatsActions.getAssignmentComplexity());
    dispatch(assignmentStatsActions.getAssignmentFireSize());
    dispatch(assignmentStatsActions.getAssignmentFuelType());
    dispatch(assignmentStatsActions.getAssignemntStatusTypes());
  };

  const resetFormValues = () => {
    setSelectedOptions({
      assignment: null,
      complexity: null,
      fuelType: null,
      fireSize: null,
      startDate: momentInstance,
      endDate: "",
    });

    setInputValues({
      incidentName: null,
      location: null,
      shifts: null,
      hotlineShifts: null,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reqData = new FormData();
    reqData.append("status", 3); //status 3 for committed status
    reqData.append(
      "resource_identifier_id",
      currentUserInfo?.crew_identifier_id,
    );
    reqData.append("location", inputValues?.location || "");
    reqData.append("complexity", selectedOptions?.complexity?.value || "");
    reqData.append("fuel_type", selectedOptions?.fuelType?.value || "");
    reqData.append("fire_size", selectedOptions?.fireSize?.value || "");
    reqData.append("name", inputValues?.incidentName || "");
    reqData.append("hotline_shifts", inputValues?.hotlineShifts || "");
    reqData.append("assignment_type", selectedOptions?.assignment?.value || "");
    reqData.append(
      "date_on",
      moment(selectedOptions.startDate).format(API_req_date_format),
    );
    reqData.append(
      "date_off",
      moment(selectedOptions.endDate).format(API_req_date_format),
    );
    reqData.append("shifts", inputValues?.shifts || "");
    reqData.append("type_slug", FIRE_HISTORY_TYPE_SLUG?.MANUAL_ASSIGN_MANIFEST);
    reqData.append(
      "employees_fire_report",
      JSON.stringify([currentUserInfo?.id]),
    );
    reqData.append("insertion_type", 2); //insertion type 2 for individual fire history records

    const res = await dispatch(assignmentStatsActions.saveStatus(reqData));

    if (res?.success) {
      toast.success(res?.message);
      setAddModalOpen(false);
      getFireHistoryByHash();
      resetFormValues();
      dispatch(assignmentStatsActions.getAssignemntStatusTypes());
    } else {
      handleErrorResponse(res);
    }
  };

  React.useEffect(() => {
    getData();
    if (userHash) {
      dispatch(userActions?.getProfile(`?hash=${userHash}`));
    }
  }, []);

  React.useEffect(() => {
    if (selectedOptions.startDate && selectedOptions.endDate) {
      const diffInDays =
        selectedOptions.endDate
          .startOf("day")
          .diff(selectedOptions.startDate.startOf("day"), "days") + 1;
      setInputValues({ shifts: diffInDays });
      return;
    } else {
      setInputValues({ shifts: "" });
    }
  }, [selectedOptions.startDate, selectedOptions.endDate]);

  return (
    <div>
      <div className={"dashboard_container"}>
        <div className="white">
          <div className="back_to_board dashboard_expiration_date_nav">
            <div
              className="back_button"
              onClick={() => props?.history?.goBack?.()}
              style={{ cursor: "pointer" }}
            >
              <img src={require("../assets/images/back.svg").default} />
              <span>Back</span>
            </div>
          </div>
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head sold_rpt_mbl_flx">
                <h5 className="graph_header">
                  {legacy ? "Legacy " : ""}Fire History Report{" "}
                  <i>
                    {legacy
                      ? " (Click Verify to migrate to Fire History Report)"
                      : ""}
                  </i>
                </h5>
                <div
                  style={{ gap: `${props.isReportMenu ? "10px" : "0px"}` }}
                  className="report_head_btn_grp exp_multi_head_flx"
                >
                  <div className="report_multi_btn_grp exp_multi_btn_flx">
                    <>
                      {fireHistoryReport?.data?.length && props.isReportMenu ? (
                        <button
                          href=""
                          className="print_button"
                          onClick={(event) => {
                            onPrintClick();
                          }}
                        >
                          <img
                            alt=""
                            src={require("../assets/images/print.svg").default}
                          />{" "}
                          Print
                        </button>
                      ) : (
                        <div className="dashboard-expiration-date-print-btn-mock" />
                      )}
                      {/* <button
                    className="print_button"
                    onClick= {()=> {setAddModalOpen(true)}}>
                    Add Incident
                    </button> */}
                    </>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <FireHistoryReportList
                fireHistoryReport={fireHistoryReport}
                getFireHistoryByHash={getFireHistoryByHash}
                selectedResource={selectedResource}
                listComplexity={listComplexity}
                listFireSize={listFireSize}
                listFuelType={listFuelType}
                listAssignmentStatusTypesOptions={assignmentStatusTypesOptions}
                legacy={legacy}
              />
            </div>
          </div>
          <Modal
            isOpen={addModalOpen}
            className="update-applicant-status-modal incident-center"
          >
            <div className="modal-head">
              <h4>Add Incident</h4>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body applicant-status-modal-body scroll-incident">
                <div className="incident-item">
                  <p>Type of Assignment</p>
                  <Select
                    options={assignmentStatusTypesOptions}
                    name="assignment-type"
                    value={selectedOptions.assignment}
                    onChange={(selectedValue) =>
                      handleSelectChange(selectedValue, "assignment")
                    }
                    isClearable
                  />
                </div>

                <div className="incident-item">
                  <p>Incident Name *</p>
                  <input
                    name="incidentName"
                    value={inputValues?.incidentName}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="incident-item">
                  <p>Location</p>
                  <input
                    name="location"
                    value={inputValues?.location}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="incident-item">
                  <p>Complexity</p>
                  <Select
                    options={listComplexity}
                    name="complexity"
                    value={selectedOptions.complexity}
                    onChange={(selectedValue) =>
                      handleSelectChange(selectedValue, "complexity")
                    }
                  />
                </div>

                <div className="incident-item">
                  <p>Fuel Type</p>
                  <Select
                    options={listFuelType}
                    name="fuelType"
                    value={selectedOptions["fuelType"]}
                    onChange={(selectedValue) =>
                      handleSelectChange(selectedValue, "fuelType")
                    }
                  />
                </div>

                <div className="incident-item">
                  <p>Fire Size</p>
                  <Select
                    options={listFireSize}
                    name="fireSize"
                    value={selectedOptions["fireSize"]}
                    onChange={(selectedValue) =>
                      handleSelectChange(selectedValue, "fireSize")
                    }
                  />
                </div>

                <div className="incident-item dates">
                  <div className="date section">
                    <p>Start Date</p>
                    <DatePicker
                      name="startDate"
                      selected={selectedOptions.startDate}
                      onChange={(date) => handleSelectChange(date, "startDate")}
                      onKeyDown={(e) => e.preventDefault()}
                      popperModifiers={datePickerPopperModifiers}
                      showMonthDropdown
                      showYearDropdown
                      required
                    />
                  </div>
                  <div className="date section">
                    <p>End Date</p>
                    <DatePicker
                      name="endDate"
                      selected={selectedOptions.endDate}
                      onChange={(date) => handleSelectChange(date, "endDate")}
                      onKeyDown={(e) => e.preventDefault()}
                      popperModifiers={datePickerPopperModifiers}
                      showMonthDropdown
                      showYearDropdown
                      required
                      minDate={selectedOptions?.startDate}
                    />
                  </div>
                </div>

                <div className="incident-item">
                  <p>Total Shifts</p>
                  <input name="shifts" value={inputValues?.shifts} disabled />
                </div>
                <div className="incident-item">
                  <p>Hotline Shifts</p>
                  <input
                    name="hotlineShifts"
                    value={inputValues?.hotlineShifts}
                    onChange={handleInputChange}
                  />
                </div>
                <>
                  <div className="button-holder button-holder--right">
                    <button
                      disabled={false}
                      className="button grey"
                      onClick={handleCloseAddModal}
                    >
                      Cancel
                    </button>
                    <button className="button" type="submit">
                      Add
                    </button>
                  </div>
                </>
              </div>
            </form>
          </Modal>
        </div>
      </div>
    </div>
  );
};

FireHistoryReportMain.defaultProps = {
  isReportMenu: true,
};

export default FireHistoryReportMain;
