import { toast } from "react-toastify";
import { dashboardConstants, manifestConstants } from "../_constants";

const INITIAL_STATE = {
  usersExpirationDates: {},
  isUsersExpirationDatesLoading: true,
  usersExpirationDateError: null,
  qualifications: [],
  qualificationReport: {},
  isQualificationReportLoading: true,
  qualificationReportError: null,
  affirmativeActionReport: {},
  isAffirmativeActionReportLoading: true,
  affirmativeActionReportError: null,
  vehicleReport: {},
  isVehicleReportLoading: true,
  vehicleReportError: null,
  employeeBirthdays: {},
  isEmployeeBirthdayLoading: false,
  employeeBirthdayError: null,
  resourceOrderFormLoading: false,
  resourceOrderForms: {},
  resourceOrderFormsError: null,
  createdResourceOrderFormsLoading: false,
  createdResourceOrderForm: {},
  createdResourceOrderFormError: null,
  isAllResourceOrderFormLoading: true,
  allResourceOrderForm: [],
  isAllResourceOrderFormError: {},
  selectedManifestRofType: null,
  selectedManifestRofId: null,
  refresherReports: {},
  isRefresherReportLoading: true,
  refresherReportError: null,
  masterListReport: {},
  isMasterListLoading: true,
  masterListError: null,
  mileageHobbsListLoading: true,
  mileageHobbsReport: {},
  mileageHobbsListError: null,
  docsStatusReportAllUsersLoading: false,
  docsStatusReportAllUsers: {},
  docsStatusReportAllUsersError: null,

  applicantsReportLoading: true,
  applicantsReport: {},
  applicantsReportError: null,

  activityLogLoading: false,
  activityLog: {},
  activityLogError: null,

  warningManagementLoading: false,
  warningManagementData: {},
  warningManagementError: null,
  warningManagementAddEntryLoading: false,
  warningManagementAddedEntry: {},
  warningManagementAddEntryError: null,
  warningManagementDeleteEntryLoading: false,
  warningManagementDeletedEntry: {},
  warningManagementDeleteEntryError: null,

  individualFireHistory: [],
  individualFireHistoryLoading: false,

  employeesFireHistoryReport: [],
  employeesFireHistoryReportAdditionalData: {},
  isEmployeesFireHistoryReportLoading: false,
  fireHistoryReportLoading: true,
  fireHistoryReport: {},
  fireHistoryReportError: null,

  dolApprovedReportLoading: true,
  dolApprovedReport: {},
  dolApprovedReportError: null,
};

export function dashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Expiration Dates
    case dashboardConstants?.EXPIRATION_DATES_REQUEST:
      return {
        ...state,
        isUsersExpirationDatesLoading: true,
      };
    case dashboardConstants?.EXPIRATION_DATES_SUCCESS:
      return {
        ...state,
        isUsersExpirationDatesLoading: false,
        usersExpirationDates: action?.data,
      };
    case dashboardConstants?.EXPIRATION_DATES_ERROR:
      return {
        ...state,
        isUsersExpirationDatesLoading: false,
        usersExpirationDateError: action?.error,
      };
    // Qualification Report
    case dashboardConstants?.QUALIFICATIONS_SUCCESS:
      return {
        ...state,
        qualifications: action?.data,
      };
    case dashboardConstants?.QUALIFICATION_REPORT_REQUEST:
      return {
        ...state,
        isQualificationReportLoading: true,
      };
    case dashboardConstants?.QUALIFICATION_REPORT_SUCCESS:
      return {
        ...state,
        isQualificationReportLoading: false,
        qualificationReport: action?.data,
      };
    case dashboardConstants?.QUALIFICATION_REPORT_ERROR:
      return {
        ...state,
        isQualificationReportLoading: false,
        qualificationReportError: action?.error,
      };
    // Affirmative action report
    case dashboardConstants?.AFFIRMATIVE_ACTION_REPORT_REQUEST:
      return {
        ...state,
        isAffirmativeActionReportLoading: true,
      };
    case dashboardConstants?.AFFIRMATIVE_ACTION_REPORT_SUCCESS:
      return {
        ...state,
        affirmativeActionReport: action?.data,
        isAffirmativeActionReportLoading: false,
      };
    case dashboardConstants?.AFFIRMATIVE_ACTION_REPORT_ERROR:
      return {
        ...state,
        isAffirmativeActionReportLoading: false,
        affirmativeActionReportError: action?.error,
      };
    // Vehicle Report
    case dashboardConstants?.VEHICLE_REPORT_REQUEST:
      return {
        ...state,
        isVehicleReportLoading: true,
      };
    case dashboardConstants?.VEHICLE_REPORT_SUCCESS:
      return {
        ...state,
        vehicleReport: action?.data,
        isVehicleReportLoading: false,
      };
    case dashboardConstants?.VEHICLE_REPORT_ERROR:
      return {
        ...state,
        isVehicleReportLoading: false,
        vehicleReportError: action?.error,
      };
    // Employee Birthday
    case dashboardConstants?.EMPLOYEE_BIRTHDAY_REQUEST:
      return {
        ...state,
        isEmployeeBirthdayLoading: true,
      };
    case dashboardConstants?.EMPLOYEE_BIRTHDAY_SUCCESS:
      return {
        ...state,
        employeeBirthdays: action?.data,
        isEmployeeBirthdayLoading: false,
      };
    case dashboardConstants?.EMPLOYEE_BIRTHDAY_ERROR:
      return {
        ...state,
        isEmployeeBirthdayLoading: false,
        employeeBirthdayError: action?.error,
      };
    // Resource Order Forms [FILES]
    case dashboardConstants?.RESOURCE_ORDER_FORMS_REQUEST:
      return {
        ...state,
        resourceOrderFormLoading: true,
      };
    case dashboardConstants?.RESOURCE_ORDER_FORMS_SUCCESS:
      return {
        ...state,
        resourceOrderForms: action?.data,
        resourceOrderFormLoading: false,
      };
    case dashboardConstants?.RESOURCE_ORDER_FORMS_ERROR:
      return {
        ...state,
        resourceOrderFormLoading: false,
        resourceOrderFormsError: action?.error,
      };

    // Resource Order Forms [CREATED]
    case dashboardConstants?.CREATED_RESOURCE_ORDER_FORMS_REQUEST:
      return {
        ...state,
        createdResourceOrderFormsLoading: true,
      };
    case dashboardConstants?.CREATED_RESOURCE_ORDER_FORMS_SUCCESS:
      return {
        ...state,
        createdResourceOrderForm: action?.data,
        createdResourceOrderFormsLoading: false,
      };
    case dashboardConstants?.CREATED_RESOURCE_ORDER_FORMS_ERROR:
      return {
        ...state,
        createdResourceOrderFormsLoading: false,
        createdResourceOrderFormError: action?.error,
      };
    case manifestConstants?.GET_ALL_RESOURCE_ORDER_FORM_REQUEST:
      return {
        ...state,
        isAllResourceOrderFormLoading: true,
      };
    case manifestConstants?.GET_ALL_RESOURCE_ORDER_FORM_SUCCESS:
      return {
        ...state,
        isAllResourceOrderFormLoading: false,
        allResourceOrderForm: action?.data,
        selectedManifestRofId: action?.selectedManifestRofId,
        selectedManifestRofType: action?.selectedManifestRofType,
      };
    case manifestConstants?.GET_ALL_RESOURCE_ORDER_FORM_ERROR:
      return {
        ...state,
        isAllResourceOrderFormLoading: false,
        isAllResourceOrderFormError: action?.error,
      };
    // Equipment Sold Report
    case dashboardConstants?.EQU_SOLD_REPORT_REQUEST:
      return {
        ...state,
        isEquipmentSoldReportLoading: true,
      };
    case dashboardConstants?.EQU_SOLD_REPORT_SUCCESS:
      return {
        ...state,
        equipmentSoldReport: action?.data,
        isEquipmentSoldReportLoading: false,
      };
    case dashboardConstants?.EQU_SOLD_REPORT_ERROR:
      return {
        ...state,
        isEquipmentSoldReportLoading: false,
        equipmentSoldReportError: action?.error,
      };
    // All Inventory Report
    case dashboardConstants?.ALL_INV_REPORT_REQUEST:
      return {
        ...state,
        isAllInventoryReportLoading: true,
      };
    case dashboardConstants?.ALL_INV_REPORT_SUCCESS:
      return {
        ...state,
        allInventoryReport: action?.data,
        isAllInventoryReportLoading: false,
      };
    case dashboardConstants?.ALL_INV_REPORT_ERROR:
      return {
        ...state,
        isAllInventoryReportLoading: false,
        allInventoryReportError: action?.error,
      };
    // Refresher Report
    case dashboardConstants?.GET_REFRESHER_REPORT_REQUEST:
      return {
        ...state,
        isRefresherReportLoading: true,
      };
    case dashboardConstants?.GET_REFRESHER_REPORT_SUCCESS:
      return {
        ...state,
        isRefresherReportLoading: false,
        refresherReports: action?.data,
      };
    case dashboardConstants?.GET_REFRESHER_REPORT_ERROR:
      return {
        ...state,
        isRefresherReportLoading: false,
        refresherReportError: action?.error,
      };
    // Master List Report
    case dashboardConstants?.GET_MASTER_REPORT_REQUEST:
      return {
        ...state,
        isMasterListLoading: true,
      };
    case dashboardConstants?.GET_MASTER_REPORT_SUCCESS:
      return {
        ...state,
        isMasterListLoading: false,
        masterListReport: action?.data,
      };
    case dashboardConstants?.GET_MASTER_REPORT_ERROR:
      return {
        ...state,
        isMasterListLoading: false,
        masterListError: action?.error,
      };
    //
    case dashboardConstants.MILEAGE_HOBBS_REPORT_REQUEST:
      return {
        ...state,
        mileageHobbsListLoading: true,
      };
    case dashboardConstants?.MILEAGE_HOBBS_REPORT_SUCCESS:
      return {
        ...state,
        mileageHobbsListLoading: false,
        mileageHobbsReport: action?.data,
      };
    case dashboardConstants?.MILEAGE_HOBBS_REPORT_ERROR:
      return {
        ...state,
        mileageHobbsListLoading: false,
        mileageHobbsListError: action?.error,
      };
    //
    case dashboardConstants.GET_MASTER_REPORT_ALL_EMPLOYEES_REQUEST:
      return {
        ...state,
        docsStatusReportAllUsersLoading: true,
      };
    case dashboardConstants?.GET_MASTER_REPORT_ALL_EMPLOYEES_SUCCESS:
      return {
        ...state,
        docsStatusReportAllUsersLoading: false,
        docsStatusReportAllUsers: action?.data,
      };
    case dashboardConstants?.GET_MASTER_REPORT_ALL_EMPLOYEES_ERROR:
      return {
        ...state,
        docsStatusReportAllUsersLoading: false,
        docsStatusReportAllUsersError: action?.error,
      };
    //
    case dashboardConstants.APPLICANTS_REPORT_REQUEST:
      return {
        ...state,
        applicantsReportLoading: true,
      };
    case dashboardConstants.APPLICANTS_REPORT_SUCCESS:
      return {
        ...state,
        applicantsReportLoading: false,
        applicantsReport: action?.data,
      };
    case dashboardConstants.APPLICANTS_REPORT_ERROR:
      return {
        ...state,
        fireHistoryReportLoading: false,
        fireHistoryReportError: action?.error,
      };

    case dashboardConstants.GET_FIRE_HISTORY_REPORT_REQUEST:
      return {
        ...state,
        fireHistoryReportLoading: true,
      };
    case dashboardConstants.GET_FIRE_HISTORY_REPORT_SUCCESS:
      return {
        ...state,
        fireHistoryReportLoading: false,
        fireHistoryReport: action?.data,
      };
    case dashboardConstants.GET_FIRE_HISTORY_REPORT_FAILURE:
      return {
        ...state,
        applicantsReportLoading: false,
        applicantsReportError: action?.error,
      };
    //Activity log
    case dashboardConstants.ACTIVITY_LOG_REQUEST:
      return {
        ...state,
        activityLogLoading: true,
      };
    case dashboardConstants.ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        activityLogLoading: false,
        activityLog: action?.data,
      };
    case dashboardConstants.ACTIVITY_LOG_ERROR:
      // toast.error("Something went wrong with fetching activity log!")
      const parsed = JSON.parse(action.error);
      if (
        !typeof parsed.message === "string" &&
        Object.keys(parsed?.message).length
      ) {
        for (let key in parsed?.message) {
          parsed?.message[key]?.map((msg) => toast.error(msg));
        }
      } else {
        toast.error(parsed?.message);
      }
      return {
        ...state,
        activityLogLoading: false,
        activityLogError: action?.error,
      };

    case dashboardConstants.WARNING_MANAGEMENT_REQUEST:
      return {
        ...state,
        warningManagementLoading: true,
      };
    case dashboardConstants.WARNING_MANAGEMENT_SUCCESS:
      return {
        ...state,
        warningManagementLoading: false,
        warningManagementData: action.data,
      };
    case dashboardConstants.WARNING_MANAGEMENT_ERROR:
      return {
        ...state,
        warningManagementLoading: false,
        warningManagementError: action.error,
      };

    case dashboardConstants.WARNING_MANAGEMENT_ADD_ENTRY_REQUEST:
      return {
        ...state,
        warningManagementAddEntryLoading: true,
      };
    case dashboardConstants.WARNING_MANAGEMENT_ADD_ENTRY_SUCCESS:
      return {
        ...state,
        warningManagementAddEntryLoading: false,
        warningManagementAddedEntry: action.data,
      };
    case dashboardConstants.WARNING_MANAGEMENT_ADD_ENTRY_ERROR:
      return {
        ...state,
        warningManagementAddEntryLoading: false,
        warningManagementAddEntryError: action.error,
      };

    case dashboardConstants.WARNING_MANAGEMENT_DELETE_REQUEST:
      return {
        ...state,
        warningManagementDeleteEntryLoading: true,
      };
    case dashboardConstants.WARNING_MANAGEMENT_DELETE_SUCCESS:
      return {
        ...state,
        warningManagementDeleteEntryLoading: false,
        warningManagementDeletedEntry: action.data,
      };
    case dashboardConstants.WARNING_MANAGEMENT_DELETE_ERROR:
      return {
        ...state,
        warningManagementDeleteEntryLoading: false,
        warningManagementDeleteEntryError: action.error,
      };

    case dashboardConstants.GET_INDIVIDUAL_FIRE_HISTORY_REQUEST:
      return {
        ...state,
        individualFireHistoryLoading: true,
      };
    case dashboardConstants.GET_INDIVIDUAL_FIRE_HISTORY_SUCCESS:
      return {
        ...state,
        individualFireHistoryLoading: false,
        individualFireHistory: action.data,
      };
    case dashboardConstants.GET_INDIVIDUAL_FIRE_HISTORY_ERROR:
      return {
        ...state,
        individualFireHistoryLoading: false,
      };

    case dashboardConstants.GET_EMPLOYEES_FIRE_HISTORY_REQUEST:
      return {
        ...state,
        isEmployeesFireHistoryReportLoading: true,
      };
    case dashboardConstants.GET_EMPLOYEES_FIRE_HISTORY_SUCCESS:
      return {
        ...state,
        isEmployeesFireHistoryReportLoading: false,
        employeesFireHistoryReport: action.data,
        employeesFireHistoryReportAdditionalData: action.additional_data,
      };
    case dashboardConstants.GET_EMPLOYEES_FIRE_HISTORY_ERROR:
      return {
        ...state,
        isEmployeesFireHistoryReportLoading: false,
      };
    case dashboardConstants.GET_DOL_APPROVED_DRIVER_REPORT_REQUEST:
      return {
        ...state,
        dolApprovedReportLoading: true,
      };
    case dashboardConstants.GET_DOL_APPROVED_DRIVER_REPORT_SUCCESS:
      return {
        ...state,
        dolApprovedReportLoading: false,
        dolApprovedReport: action?.data,
      };
    case dashboardConstants.GET_DOL_APPROVED_DRIVER_REPORT_FAILURE:
      return {
        ...state,
        dolApprovedReportLoading: false,
        dolApprovedReportError: action?.error,
      };
    default:
      return state;
  }
}
