import React, { forwardRef, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import moment from "moment";
import DatePicker from "react-datepicker";
import CustomPagination from "_components/utils/Pagingation/CustomPagination";
import { ReactComponent as Calendar } from "assets/icons/calendar-icon.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete-icon-golden.svg";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions, chatActions } from "_actions";
import { toast } from "react-toastify";
import { config } from "_helpers";
import DocStatusTag from "_components/utils/DocStatusTag";
import { ReactComponent as OpenLink } from "assets/icons/open-link-green.svg";
import { withRouter } from "react-router-dom";

const ASC_CLASS = "sort-arrow asc";
const DESC_CLASS = "sort-arrow desc";

const APIReqDateFormat = "YYYY-MM-DD";
const USDateFormat = "MM/DD/YYYY";

const initialStyleState = {
  dateClass: ASC_CLASS,
  typeClass: ASC_CLASS,
};

const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => (
  <div className="management-datepicker" onClick={onClick}>
    <input type="text" value={value} readOnly ref={ref} />
    <Calendar className="svg-icon" />
  </div>
));

const Management = (props) => {
  const momentInstance = moment();
  const dispatch = useDispatch();
  const isMounted = useRef(true);

  const warningsList = useSelector(
    (state) => state.dashboard.warningManagementData,
  );

  const warningsListLoading = useSelector(
    (state) => state.dashboard.warningManagementLoading,
  );

  const loggedInUser = useSelector((state) => state?.user?.profile?.data);

  const permissions = useSelector(
    (state) => state?.permission?.permissions?.data,
  );

  const employeeId = useSelector((state) => state?.user?.items?.data.id);

  const signature = useSelector((state) => state?.chat.settings?.signature);

  const hasPermissionToCreate =
    loggedInUser?.is_admin > 0 || permissions.includes("View/Add Management");

  const [sortItem, setSortItem] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState("desc");
  const [filterType, setFilterType] = useState("");
  const [toggleAddEntryModal, setToggleAddEntryModal] = useState(false);
  const [toggleFilterModal, setToggleFilterModal] = useState(false);
  const [deleteEntryModal, setDeleteEntryModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState({});
  const [isAddEntryLoading, setIsAddEntryLoading] = useState(false);
  const [isResetEnabled, setIsResetEnabled] = useState(false);
  const [uploadedDOFile, setUploadedDOFile] = useState({
    DOFileName: "",
    fileName: "",
  });

  const [newEntryDate, setNewEntryDate] = useState(momentInstance);
  const [newEntryType, setNewEntryType] = useState("");
  const [newEntryNote, setNewEntryNote] = useState("");
  const [newEntryFile, setNewEntryFile] = useState(null);

  const [{ dateClass, typeClass }, setStyleState] = useState(initialStyleState);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? "asc" : "desc";
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const onCancelAddEntry = () => {
    setToggleAddEntryModal(false);
  };

  const onCancelFilter = () => {
    setToggleFilterModal(false);
  };

  const onCancelDeleteEntry = () => {
    setDeleteEntryModal(false);
  };

  const handleCreateNewVerbalWarning = async () => {
    setIsAddEntryLoading(true);
    const reqData = new FormData();
    reqData.append("date", moment(newEntryDate).format(APIReqDateFormat));
    reqData.append("type", newEntryType);
    reqData.append("notes", newEntryNote);
    reqData.append("file", uploadedDOFile?.DOFileName || "");
    reqData.append("user_id", employeeId);
    reqData.append("thumb_name", uploadedDOFile?.fileName || "");

    const response = await dispatch(
      dashboardActions.warningManagementAddEntry(reqData),
    );

    if (response.data?.success) {
      toast.success(response.data.message);
      setNewEntryFile(null);
      setNewEntryNote("");
      setNewEntryType("");
      setToggleAddEntryModal(false);
      setUploadedDOFile(null);
      fetchManagementData();
    } else {
      toast.error("Creating a new entry failed!");
    }
    setIsAddEntryLoading(false);
  };

  const onAddNewEntry = async () => {
    if (!newEntryType) {
      return toast.error("Please choose a type for creating a new entry!");
    } else if (!newEntryNote) {
      return toast.error("The notes field is required!");
    } else if (newEntryNote.length < 10) {
      return toast.error("The notes must be at least 10 characters long.");
    }

    if (newEntryFile) {
      const isFileTypeValid =
        newEntryFile.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        newEntryFile.type === "application/pdf";

      if (!isFileTypeValid) {
        return toast.error("The uploaded file must be of type .pdf or .docx");
      }

      const reqData = {
        newEntryFile,
        extension: newEntryFile.type === "application/pdf" ? "pdf" : "docx",
      };

      setIsAddEntryLoading(true);

      return await dispatch(dashboardActions.uploadFile(reqData))
        .then(async (res) => {
          setUploadedDOFile(res);
        })
        .catch((err) => {
          throw err;
        });
    }
    handleCreateNewVerbalWarning();
  };

  const onApplyFilter = () => {
    fetchManagementData();
    setToggleFilterModal(false);
    setIsResetEnabled(true);
  };

  const onResetFilter = () => {
    setOrder("desc");
    setSortItem("date");
    setCurrentPage(1);
    setFilterType("");
    setIsResetEnabled(false);
  };

  const onDeleteEntry = (entry) => {
    setDeleteEntryModal(true);
    setSelectedEntry(entry);
  };

  const onDeleteEntryConfirm = async () => {
    const data = new FormData();
    data.append("id", selectedEntry.id);
    const response = await dispatch(
      dashboardActions.warningManagementDeleteEntry(data),
    );
    if (response?.data?.success) {
      toast.success("The entry is deleted successfully.");
    } else {
      toast.error("Deleting entry failed!");
    }
    setDeleteEntryModal(false);
    fetchManagementData();
  };

  const onClickWarningTypeLink = (doc) => {
    /*
      since the url is already on baseUrl/settings, 
      the history.push from react-router-dom will not navigate to the given url, 
      because match is already detected with /settings route
      so force redirecting here
    */
    // window.location.href = `${config.baseUrl}settings?slug=warning-management&hash=${loggedInUser?.hash}&doc_id=${doc.doc_id}&doc_type_id=${doc.id}`;
  };

  const fetchManagementData = async () => {
    const data = new FormData();
    data.append("sort", sortItem);
    data.append("order", order);
    data.append("type", filterType);
    data.append("page", currentPage);
    data.append("user_id", employeeId);
    await dispatch(dashboardActions.fetchWarningManagement(data));
  };

  useEffect(() => {
    if (isMounted.current) return (isMounted.current = false);
    if (filterType === "") {
      fetchManagementData();
    }
  }, [filterType]);

  useEffect(() => {
    fetchManagementData();
  }, [order, sortItem, currentPage]);

  useEffect(() => {
    const fetchSettings = async () => {
      await dispatch(chatActions.getConversationSettings());
    };
    fetchSettings();
  }, []);

  useEffect(() => {
    if (uploadedDOFile?.fileName) {
      handleCreateNewVerbalWarning();
    }
  }, [uploadedDOFile?.fileName]);

  const RenderAddEntryModal = () => {
    return (
      <Modal
        isOpen={toggleAddEntryModal}
        className="manage-modal"
        overlayClassName="manage-modal-overlay"
      >
        <div className="manage-modal-header">
          <h3>ADD ENTRY</h3>
        </div>
        <div className="manage-modal-body">
          <div className="first-row">
            <DatePicker
              selected={newEntryDate}
              onChange={setNewEntryDate}
              showMonthDropdown
              showYearDropdown
              dateFormat="MM/DD/YYYY"
              dropdownMode="select"
              customInput={<CustomDatePickerInput />}
            />
            <select
              className="manage-modal-select-add-entry"
              onChange={(e) => setNewEntryType(e.target.value)}
            >
              <option value="">Select Type</option>
              <option value="1">Counseling</option>
              <option value="2">Verbal Warning</option>
              <option value="3">Written Warning</option>
              <option value="4">Final Warning</option>
              <option value="5">Suspension</option>
              <option value="6">Termination</option>
            </select>
          </div>
          <div className="second-row">
            <p>Notes</p>
            <textarea
              name=""
              id=""
              rows="5"
              onChange={(e) => setNewEntryNote(e.target.value)}
            />
          </div>
          <div className="third-row">
            <p>Attachment</p>
            <input
              type="file"
              onChange={(e) => setNewEntryFile(e.target.files[0])}
            />
          </div>
        </div>
        <div className="mange-modal-footer">
          <button className="button cancel-button" onClick={onCancelAddEntry}>
            Cancel
          </button>
          <button
            className="button"
            onClick={onAddNewEntry}
            disabled={isAddEntryLoading}
          >
            {isAddEntryLoading ? "Adding..." : "Add"}
          </button>
        </div>
      </Modal>
    );
  };

  const RenderFilterModal = () => {
    return (
      <Modal
        isOpen={toggleFilterModal}
        className="manage-modal"
        overlayClassName="manage-modal-overlay"
      >
        <div className="manage-modal-header">
          <h3>FILTER</h3>
        </div>
        <div className="manage-modal-body">
          <div className="first-row">
            <select
              className="manage-modal-select-filter"
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value="">Select Type</option>
              <option value={1}>Counseling</option>
              <option value={2}>Verbal Warning</option>
              <option value={3}>Written Warning</option>
              <option value={4}>Final Warning</option>
              <option value={5}>Suspension</option>
              <option value={6}>Termination</option>
            </select>
          </div>
        </div>
        <div className="mange-modal-footer">
          <button className="button cancel-button" onClick={onCancelFilter}>
            Cancel
          </button>
          <button className="button" onClick={onApplyFilter}>
            Apply Filter
          </button>
        </div>
      </Modal>
    );
  };

  const RenderDeleteModal = () => {
    return (
      <Modal
        isOpen={deleteEntryModal}
        className="manage-modal manage-modal-delete"
        overlayClassName="manage-modal-overlay"
      >
        <div className="manage-modal-header">
          <h3>
            Are you sure want to delete{" "}
            {`"${selectedEntry?.type?.type} - ${moment(
              selectedEntry.date,
            ).format(USDateFormat)}?"`}
          </h3>
        </div>
        <div className="manage-modal-body">
          <div className="first-row">
            <p>
              This item will be deleted immediately. You can't undo this action.
            </p>
          </div>
        </div>
        <div className="mange-modal-footer">
          <button
            className="button cancel-button"
            onClick={onCancelDeleteEntry}
          >
            Cancel
          </button>
          <button className="button" onClick={onDeleteEntryConfirm}>
            Delete
          </button>
        </div>
      </Modal>
    );
  };

  return (
    <div className="manage-wrapper">
      {RenderAddEntryModal()}
      {RenderFilterModal()}
      {RenderDeleteModal()}
      <div className="header-and-table">
        <div className="manage-header">
          <div className="title">
            <h3>Management</h3>
          </div>
          <div className="manage-header-buttons">
            {isResetEnabled && (
              <button
                className="button transparent-btn"
                onClick={onResetFilter}
              >
                Reset Filter
              </button>
            )}
            <button
              className="button transparent-btn"
              onClick={() => setToggleFilterModal(true)}
            >
              Filter
            </button>
            {hasPermissionToCreate && (
              <button
                className="button"
                onClick={() => setToggleAddEntryModal(!toggleAddEntryModal)}
              >
                Add Entry
              </button>
            )}
          </div>
        </div>
        <div className="manage-table">
          <table className={`mobile_hide report_list_width_full`}>
            {!warningsListLoading && (
              <thead>
                <tr className="report_list_table_row">
                  <th onClick={() => sortList("dateClass", dateClass, "date")}>
                    <span>Date</span>
                    <span className={dateClass} />
                  </th>
                  <th onClick={() => sortList("typeClass", typeClass, "type")}>
                    <span>Type</span>
                    <span className={typeClass} />
                  </th>
                  <th>
                    <span>Notes</span>
                  </th>
                  <th>
                    <span>Attachment</span>
                  </th>
                  <th></th>
                </tr>
              </thead>
            )}
            <tbody className="report_list_table_tbody">
              {!!warningsList?.data?.length &&
                !warningsListLoading &&
                warningsList.data.map((warning, index) => (
                  <tr key={index}>
                    <td className="date-td">
                      <span>{moment(warning?.date).format(USDateFormat)}</span>
                    </td>
                    <td>
                      <div className="warning-type">
                        <span
                          className="warning-type-name"
                          // onClick={() => onClickWarningTypeLink(warning)}
                        >
                          {warning?.type.type}{" "}
                          {/* <OpenLink className="open-link-icon" /> */}
                        </span>

                        <DocStatusTag
                          className="warning-type-tag"
                          bgColor={warning?.signature ? "#3f7e39" : "#c9c188"}
                          status={
                            warning?.signature
                              ? "Acknowledged"
                              : "Awaiting Ack."
                          }
                          fontColor={warning?.signature ? "#fff" : "#000"}
                        />
                      </div>
                    </td>
                    <td className="warning-notes">{warning?.notes}</td>
                    <td className="warning-fileName">
                      <a
                        href={
                          config.apiUrl +
                          `/management/get/file/${warning?.file_hash}?signature=${signature}&hash=${loggedInUser?.hash}`
                        }
                        rel="noreferrer"
                        target="_blank"
                      >
                        {warning?.file_name}
                      </a>
                    </td>
                    <td>
                      <DeleteIcon
                        className="manage-delete"
                        onClick={() => onDeleteEntry(warning)}
                      />
                    </td>
                  </tr>
                ))}

              {!warningsListLoading && !warningsList?.data?.length && (
                <tr>
                  <td colSpan={5} className="no-data-message">
                    There is no data available. Try adjusting the filter to get
                    some results.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {warningsListLoading && (
            <img
              className="warning-management-loader"
              alt="loader"
              src={require("../assets/images/loader.gif").default}
            />
          )}

          {!warningsListLoading && (
            <ManagementMobileView
              isLoading={warningsListLoading}
              sortList={sortList}
              dateClass={dateClass}
              typeClass={typeClass}
              data={warningsList}
              onDeleteEntry={onDeleteEntry}
              onClickWarningTypeLink={onClickWarningTypeLink}
              loggedInUserHash={loggedInUser?.hash}
              signature={signature}
            />
          )}
        </div>
      </div>
      {!warningsListLoading && (
        <CustomPagination
          forcePage={currentPage - 1}
          pageCount={warningsList?.last_page}
          onPageChange={onPageChange}
          pageRangeDisplayed={4}
        />
      )}
    </div>
  );
};

export default withRouter(Management);

const ManagementMobileView = (props) => {
  const {
    data,
    sortList,
    dateClass,
    typeClass,
    onDeleteEntry,
    onClickWarningTypeLink,
    signature,
    loggedInUserHash,
  } = props;

  return (
    <div className="mobile-view-wrapper warning-management-mobile-view">
      {data?.data?.map((warning, index) => (
        <div className="for_mobile_history_list" key={index}>
          <hr></hr>
          <div className="history_items">
            <div className="status_item">
              <div
                className="list_item"
                onClick={() => sortList("dateClass", dateClass, "date")}
              >
                <strong>Date</strong>
                <span className={dateClass} />
              </div>
              <div className="list_item">
                {moment(warning.date).format(USDateFormat)}
              </div>
            </div>

            <div className="status_item">
              <div
                className="list_item"
                onClick={() => sortList("typeClass", typeClass, "type")}
              >
                <strong>Type</strong>
                <span className={typeClass} />
              </div>
              <div className="list_item">
                <div className="warning-type-name-mobile">
                  <span onClick={() => onClickWarningTypeLink(warning)}>
                    {warning.type.type}
                    {/* <OpenLink className="open-link-icon" /> */}
                  </span>
                  <DocStatusTag
                    className="warning-type-tag-mobile"
                    bgColor={warning.status === 1 ? "#c9c188" : "green"}
                    status={
                      warning.status === 1 ? "Awaiting Ack." : "Acknowledged"
                    }
                    fontColor={warning.status === 1 ? "black" : "white"}
                  />
                </div>
              </div>
            </div>

            <div className="status_item">
              <div className="list_item">
                <strong>Notes</strong>
              </div>
              <div className="list_item warning-notes">{warning.notes}</div>
            </div>

            <div className="status_item">
              <div className="list_item">
                <strong>Attachment</strong>
              </div>
              <div className="list_item warning-fileName-mobile">
                <a
                  href={
                    config.apiUrl +
                    `/management/get/file/${warning.file_hash}?signature=${signature}&hash=${loggedInUserHash}`
                  }
                  rel="noreferrer"
                  target="_blank"
                >
                  {warning.file_name}
                </a>
              </div>
            </div>

            <div className="status_item">
              <div className="list_item"></div>
              <div className="list_item">
                <DeleteIcon
                  className="manage-delete"
                  onClick={() => onDeleteEntry(warning)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
