import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  certificates,
  getCertificatesTypes,
  getSelectedUserInfo,
  selectCurrentUserPermissions,
} from "../_helpers/reduxSelector";
import { config } from "_helpers/config";
import { createProfileActions, docsActions } from "_actions";
import ReactModal from "react-modal";
import Select from "react-select";
import { ReactComponent as DeleteIcon } from "assets/icons/delete-icon-golden.svg";
import moment from "moment";
import { docsService } from "_services";
import { toast } from "react-toastify";
import { handleErrorResponse } from "_helpers/helpers";
import { withRouter } from "react-router";
import { formatDate } from "_helpers/helpers";

const ASC_CLASS = "sort-arrow asc";
const DESC_CLASS = "sort-arrow desc";

const Sort = {
  Asc: "asc",
  Dsc: "desc",
};

const customStylesUpdateModal = {
  content: {
    width: "auto",
    height: "auto",
  },
};

const initialStyleState = {
  nameClass: ASC_CLASS,
  dateClass: ASC_CLASS,
};

function Certificates(props) {
  const dispatch = useDispatch();
  const currentUserInfo = useSelector(getSelectedUserInfo);
  const currentUserPermissions = useSelector(selectCurrentUserPermissions);
  const canModifyCertificates = currentUserPermissions.includes(
    "Manage Certificates",
  );

  const getCertificates = useSelector(certificates);
  const certificateTypes = useSelector(getCertificatesTypes);

  const [{ nameClass, dateClass }, setStyleState] = useState(initialStyleState);
  const [order, setOrder] = useState(true);
  const [sortItem, setSortItem] = useState("created_at");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [cert, setCert] = useState(null);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  const noDataAvailable = !getCertificates?.data?.length;

  const getUserCertificates = () => {
    let data = new FormData();
    data.append("user_id", currentUserInfo?.id);
    data.append("sort", sortItem);
    data.append("order", order ? Sort.Asc : Sort.Dsc);
    dispatch(docsActions.getCertificates(data));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append("user_id", currentUserInfo?.id || "");
    data.append("certificate_id", selectedOption?.value || "");
    data.append("file", selectedFile);

    let res;
    res = await dispatch(docsActions.addCertificate(data));
    if (res.success) {
      setSelectedOption("");
      setSelectedFile(null);
      closeModal();
      getUserCertificates();
      return toast.success(res?.message);
    } else {
      handleErrorResponse(res);
    }
  };

  const deleteEntry = async (id) => {
    let data = new FormData();
    data.append("id", id);
    dispatch(docsActions.deleteCertificate(data));
    let res;
    res = await dispatch(docsActions.deleteCertificate(data));
    if (res.success) {
      getUserCertificates();
      closeConfirmModal();
      return toast.success(res?.message);
    } else {
      handleErrorResponse(res);
    }
  };

  const downloadCertificate = (id) => {
    let data = new FormData();
    data.append("id", id);
    docsService
      .downloadCertificate(data)
      .then((response) => {
        window.open(response.data, "_blank");
      })
      .catch((error) => {
        return toast.error(error?.message);
      });
  };

  const options = certificateTypes.map((certificateType) => ({
    value: certificateType.id,
    label: certificateType.name,
  }));

  const openModal = () => {
    setIsAddModalOpen(true);
  };

  const closeModal = () => {
    setIsAddModalOpen(false);
  };

  const openConfirmModal = (item) => {
    setIsConfirmModalOpen(true);
    setCert(item);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setCert(null);
  };
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    dispatch(createProfileActions?.getAll());
    sortList("dateClass", dateClass, "created_at");
  }, []);

  useEffect(() => {
    getUserCertificates();
  }, [sortItem, order]);

  return (
    <>
      <div id="chart">
        <div className="report_tbl_head-cert certificates-list">
          <div className="cert_header-wrapper">
            <h4 className="graph_header certificates-title">Certificates</h4>
          </div>
          {canModifyCertificates && (
            <div className="report_head_btn_grp">
              <div className="cert__btn-wrapper">
                <button onClick={openModal} className="button">
                  Add Certificate
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="history_lists outer_list table__scroll certificates-table-wrapper">
        <table className="mobile_hide report_list_width_full">
          <thead>
            <tr className="report_list_table_row">
              <th onClick={() => sortList("nameClass", nameClass, "name")}>
                <span>Certificate Name</span>
                <span className={nameClass} />
              </th>
              <th onClick={() => sortList("dateClass", dateClass, "name")}>
                <span>Date Added</span>
                <span className={dateClass} />
              </th>
              <th>
                <span></span>
              </th>
              <th>
                <span></span>
              </th>
            </tr>
          </thead>
          <tbody className="report_list_table_tbody">
            {noDataAvailable ? (
              <tr>
                <td colSpan="3">
                  <div className="dashboard_expiration_date_no_data">
                    No Data Found
                  </div>
                </td>
              </tr>
            ) : (
              getCertificates?.data?.map((item, index) => {
                return (
                  <tr>
                    <td>{item?.certificate?.name}</td>
                    <td>
                      {moment(item?.certificate?.created_at).isValid()
                        ? formatDate(item?.certificate?.created_at)
                        : "-"}
                    </td>
                    <td>
                      <a
                        onClick={() => downloadCertificate(item?.id)}
                        className="cert-link"
                      >
                        {item?.file_name}
                      </a>
                    </td>
                    <td>
                      {canModifyCertificates && (
                        <DeleteIcon
                          className="cert-delete-icon"
                          onClick={() => openConfirmModal(item)}
                        />
                      )}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      {/* Mobile */}
      {noDataAvailable ? (
        <div className="dashboard_expiration_date_no_data for_mobile_history_list">
          No Data Found
        </div>
      ) : (
        <CertificatesListMobileView
          data={getCertificates?.data}
          getUserCertificates={getUserCertificates}
          downloadCertificate={downloadCertificate}
          nameClass={nameClass}
          dateClass={dateClass}
          sortList={sortList}
          openConfirmModal={openConfirmModal}
        />
      )}

      <ReactModal
        isOpen={isAddModalOpen}
        overlayClassName="alertPopup"
        style={customStylesUpdateModal}
      >
        <div id="statusPopup" className="popupModal">
          <div className="modal-head">
            <h4 className="modal-title">ADD CERTIFICATE</h4>
          </div>
          <div className="modal-body-qual">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <Select
                  id="dropdown"
                  selectedOption={selectedOption}
                  onChange={handleOptionChange}
                  options={options}
                  placeholder="Select Certificate"
                  required
                  isSearchable
                />
              </div>
              <div className="form-group">
                <input
                  type="file"
                  id="file"
                  accept=".pdf"
                  required
                  onChange={handleFileChange}
                />
              </div>
              <div className="qual_btn_list">
                <button
                  className="button grey mr-1"
                  onClick={closeModal}
                  type="button"
                >
                  Cancel
                </button>
                <button className="button" type="submit">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={isConfirmModalOpen}
        overlayClassName="alertPopup"
        style={customStylesUpdateModal}
      >
        <div id="statusPopup" className="popupModal">
          <div className="modal-head">
            <h4 className="modal-title-qual">Confirm File Deletion</h4>
          </div>
          <div className="modal-body-qual">
            <p>
              Are you sure wou want to delete "
              <strong>
                {cert?.file_name} ({cert?.certificate?.name})
              </strong>
              " .This will be deleted immediately. You cant undo this action.
            </p>
            <div className="updateQual_btn_list">
              <button
                className="button grey mr-1"
                onClick={closeConfirmModal}
                type="button"
              >
                Cancel
              </button>
              <button
                className="button"
                onClick={() => deleteEntry(cert?.id)}
                type="submit"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
}

const CertificatesListMobileView = ({
  dateClass,
  nameClass,
  data,
  sortList,
  downloadCertificate,
  openConfirmModal,
}) => {
  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("nameClass", nameClass, "name")}
            >
              <strong>Certificate Name</strong>
              <span className={nameClass} />
            </div>
            <div className="list_item cert-name">
              {item?.certificate?.name ?? "-"}
            </div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("dateClass", dateClass, "name")}
            >
              <strong>Date Added</strong>
              <span className={dateClass} />
            </div>
            <div className="list_item">{item?.created_at ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item">
              <strong>File</strong>
            </div>
            <div
              className="list_item cert-file-hash"
              onClick={() => downloadCertificate(item?.id)}
            >
              {item?.file_hash ?? "-"}
            </div>
          </div>
          <div className="status_item">
            <div className="list_item"></div>
            <div className="list_item" onClick={() => openConfirmModal(item)}>
              {<DeleteIcon />}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};

export default withRouter(Certificates);
