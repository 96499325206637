import React from 'react';

import { EditProfile } from './EditProfile';

import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';

import { checkPastDate, formatDate } from '_helpers/helpers';
import { dateTimeConstants } from '_constants';
import {
  preventNegativeValueInDocs,
  formatSSN,
  convertDateTimeToPST,
} from '_helpers/helpers';

import moment from 'moment';
import NumberFormat from 'react-number-format';

const AdministrationComponent = ({
  profileData,
  dropdownData,
  refetchUser,
  isOpen,
  toggleAccordion,
  permList,
  authUser,
}) => {
  const ownProfile = profileData?.id == authUser?.id;

  return (
    <div
      id='administration'
      className={`profile-data__drops profile-data_comp ${isOpen ? 'is-active' : ''}`}
    >
      <header onClick={() => toggleAccordion('#administration')}>
        <button type='button'>
          <h3>Administration</h3>
        </button>
      </header>
      <div className='profile-data__drops-cnt'>
        <ul role='list'>
          <li role='listitem'>
            <span>
              <b>Title</b>
            </span>
            <span>{profileData.user_type}</span>
            <EditProfile
              label='Title'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes('Edit Title')}
            >
              {(register, errors) => (
                <>
                  <label>Title</label>
                  <select
                    {...register('user_type_id', {
                      required: 'Title is required',
                    })}
                    defaultValue={profileData.user_type_id}
                    className={
                      errors.user_type_id && 'profile-form-field-error'
                    }
                  >
                    <option value=''>Select Title</option>
                    {dropdownData.Roles.map((role, index) => (
                      <option key={index} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>

                  {errors.user_type_id && (
                    <p className='error-message'>
                      {errors.user_type_id.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role='listitem'>
            <span>
              <b>Crew</b>
            </span>
            <span>{profileData.crew_name}</span>
            <EditProfile
              label='Crew'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes('Assign Staff')}
            >
              {(register, errors) => (
                <>
                  <label>Crew</label>
                  <select
                    {...register('crew_identifier_id', {
                      required: 'Crew is required',
                    })}
                    defaultValue={profileData.crew_identifier_id}
                    className={
                      errors.crew_identifier_id && 'profile-form-field-error'
                    }
                  >
                    <option value=''>Select Crew</option>
                    {dropdownData.FireFighter.map((crew, index) => (
                      <option key={index} value={crew.id}>
                        {crew.resource_name}
                      </option>
                    ))}
                  </select>
                  {errors.crew_identifier_id && (
                    <p className='error-message'>
                      {errors.crew_identifier_id.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role='listitem'>
            <span>
              <b>Firestorm ID #</b>
            </span>
            <span>{profileData.fire_id}</span>
            <EditProfile
              label='Firestorm ID #'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                'Manage Sensitive Employee Profile Fields',
              )}
            >
              {(register, errors) => (
                <>
                  <label>Firestorm ID #</label>
                  <input
                    type='number'
                    {...register('fire_id', {
                      required: 'Firestorm ID # is required',
                      min: {
                        value: 1,
                        message: 'Firestorm ID # must be a valid value',
                      },
                      validate: (value) =>
                        value.toString().length <= 7 ||
                        'Firestorm ID # must be at most 7 digits',
                      valueAsNumber: true,
                    })}
                    placeholder='Enter Firestorm ID #'
                    defaultValue={profileData.fire_id}
                    className={errors.fire_id && 'profile-form-field-error'}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={preventNegativeValueInDocs}
                  />
                  {errors.fire_id && (
                    <p className='error-message'>{errors.fire_id.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          {(permList.includes('Manage Expiry/Assigned Dates') ||
            ownProfile) && (
            <li role='listitem'>
              <span>
                <b>RT-130</b>
              </span>
              <span>{formatDate(profileData.rt_130_expiry)}</span>
              <EditProfile
                label='RT-130'
                profileData={profileData}
                refetchUser={refetchUser}
                authUser={authUser}
                hasPermissionRequiredToEditOwn={false}
                hasPermissionToEditOthers={permList.includes(
                  'Manage Expiry/Assigned Dates',
                )}
              >
                {(register, errors, setValue, control) => (
                  <>
                    <label>RT-130</label>
                    <Controller
                      name='rt_130_expiry'
                      control={control}
                      defaultValue={
                        profileData.rt_130_expiry
                          ? moment(profileData.rt_130_expiry).format(
                              dateTimeConstants.DATE_FORMAT,
                            )
                          : null
                      }
                      rules={{
                        required: 'RT-130 is required',
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <DatePicker
                          selected={value ? moment(value) : ''}
                          onChange={(date) =>
                            onChange(
                              date &&
                                !date
                                  .endOf('day')
                                  .isAfter(moment().endOf('day'))
                                ? date.format(dateTimeConstants.DATE_FORMAT)
                                : '',
                            )
                          }
                          customInput={<input inputMode='none' />}
                          maxDate={moment()}
                          showMonthDropdown
                          showYearDropdown
                          placeholderText='Select RT 130 Expiry Date'
                          className={error ? 'profile-form-field-error' : ''}
                          dateFormat={dateTimeConstants.DATE_FORMAT}
                          showPopperArrow={false}
                        />
                      )}
                    />
                    {errors.rt_130_expiry && (
                      <p className='error-message'>
                        {errors.rt_130_expiry.message}
                      </p>
                    )}
                  </>
                )}
              </EditProfile>
            </li>
          )}

          <li role='listitem'>
            <span>
              <b>Primary Crew</b>
            </span>
            <span>{profileData.primary_crew_name}</span>
            <EditProfile
              label='Primary Crew'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes('Assign Staff')}
            >
              {(register, errors) => (
                <>
                  <label>Primary Crew</label>
                  <select
                    {...register('primary_crew_identifier_id', {
                      required: 'Primary Crew is required',
                    })}
                    defaultValue={profileData.primary_crew_identifier_id}
                    className={
                      errors.primary_crew_identifier_id &&
                      'profile-form-field-error'
                    }
                  >
                    <option value=''>Select Primary Crew</option>
                    {dropdownData.FireFighter.map((crew, index) => (
                      <option key={index} value={crew.id}>
                        {crew.resource_name}
                      </option>
                    ))}
                  </select>
                  {errors.primary_crew_identifier_id && (
                    <p className='error-message'>
                      {errors.primary_crew_identifier_id.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          {(permList.includes('Manage Sensitive Employee Profile Fields') ||
            ownProfile) && (
            <li role='listitem'>
              <span>
                <b>SSN</b>
              </span>
              <span>{formatSSN(profileData.ssn)}</span>
              <EditProfile
                label='SSN'
                profileData={profileData}
                refetchUser={refetchUser}
                authUser={authUser}
                hasPermissionRequiredToEditOwn={false}
                hasPermissionToEditOthers={permList.includes(
                  'Manage Sensitive Employee Profile Fields',
                )}
              >
                {(register, errors, setValue) => (
                  <>
                    <label>SSN</label>
                    <NumberFormat
                      format='###-##-####'
                      mask='_'
                      placeholder='Enter SSN'
                      value={profileData.ssn}
                      onValueChange={({ value }) => {
                        setValue('ssn', value);
                      }}
                      className={errors.ssn && 'profile-form-field-error'}
                      isNumericString
                      getInputRef={register('ssn', {
                        required: 'SSN is required',
                        minLength: {
                          value: 9,
                          message: 'SSN must be 9 digits long',
                        },
                      })}
                      onKeyDown={preventNegativeValueInDocs}
                    />
                    {errors.ssn && (
                      <p className='error-message'>{errors.ssn.message}</p>
                    )}
                  </>
                )}
              </EditProfile>
            </li>
          )}

          {(permList.includes('Manage Sensitive Employee Profile Fields') ||
            ownProfile) && (
            <li role='listitem'>
              <span>
                <b>DOB</b>
              </span>
              <span>{profileData.dob}</span>
              <EditProfile
                label='DOB'
                profileData={profileData}
                refetchUser={refetchUser}
                authUser={authUser}
                hasPermissionRequiredToEditOwn={false}
                hasPermissionToEditOthers={permList.includes(
                  'Manage Sensitive Employee Profile Fields',
                )}
              >
                {(register, errors, setValue, control) => (
                  <>
                    <label>DOB</label>
                    <Controller
                      name='dob'
                      control={control}
                      defaultValue={
                        profileData.dob
                          ? moment(profileData.dob).format(
                              dateTimeConstants.DATE_FORMAT,
                            )
                          : null
                      }
                      rules={{
                        required: 'Date of Birth is required',
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <DatePicker
                          selected={value ? moment(value) : ''}
                          onChange={(date) => {
                            const formattedDate =
                              date && moment().diff(moment(date), 'years') >= 18
                                ? date.format(dateTimeConstants.DATE_FORMAT)
                                : '';
                            onChange(formattedDate);
                          }} // Handle date change
                          customInput={<input inputMode='none' />}
                          showMonthDropdown
                          showYearDropdown
                          maxDate={moment().subtract(18, 'years')}
                          placeholderText='Select Birth Date'
                          className={error ? 'profile-form-field-error' : ''}
                          dateFormat={dateTimeConstants.DATE_FORMAT}
                          showPopperArrow={false}
                        />
                      )}
                    />
                    {errors.dob && (
                      <p className='error-message'>{errors.dob.message}</p>
                    )}
                  </>
                )}
              </EditProfile>
            </li>
          )}

          <li role='listitem'>
            <span>
              <b>Sex</b>
            </span>
            <span>{profileData.gender}</span>
            <EditProfile
              label='Gender'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                'Manage Employee Profile Fields',
              )}
            >
              {(register, errors) => (
                <>
                  <label>Gender</label>
                  <select
                    {...register('gender', {
                      required: 'Gender is required',
                    })}
                    defaultValue={profileData.gender}
                    className={errors.gender && 'profile-form-field-error'}
                  >
                    <option value=''>Select Gender</option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                  </select>
                  {errors.gender && (
                    <p className='error-message'>{errors.gender.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          {(permList.includes('Manage Expiry/Assigned Dates') ||
            ownProfile) && (
            <>
              <li role='listitem'>
                <span>
                  <b>DL #</b>
                </span>
                <span>{profileData.drivers_license ?? '-'}</span>
                <EditProfile
                  label='DL #'
                  profileData={profileData}
                  refetchUser={refetchUser}
                  authUser={authUser}
                  hasPermissionRequiredToEditOwn={false}
                  hasPermissionToEditOthers={permList.includes(
                    'Manage Expiry/Assigned Dates',
                  )}
                >
                  {(register, errors) => (
                    <>
                      <label>DL #</label>
                      <input
                        {...register('drivers_license', {
                          maxLength: {
                            value: 20,
                            message:
                              'Length exceeds the maximum limit of 20 characters.',
                          },
                          pattern: {
                            value: /^[A-Za-z0-9]+$/, // Regex for alphabets and numbers
                            message: 'Only alphabets and numbers are allowed.',
                          },
                        })}
                        placeholder='Enter Drivers License / ID'
                        defaultValue={profileData.drivers_license}
                        className={
                          errors.drivers_license && 'profile-form-field-error'
                        }
                      />
                      {errors.drivers_license && (
                        <p className='error-message'>
                          {errors.drivers_license.message}
                        </p>
                      )}
                    </>
                  )}
                </EditProfile>
              </li>

              <li role='listitem'>
                <span>
                  <b>Driver's License State Issued</b>
                </span>
                <span>{profileData.DLState ? profileData.DLState : '-'}</span>
                <EditProfile
                  label='Driver License State'
                  profileData={profileData}
                  refetchUser={refetchUser}
                  authUser={authUser}
                  hasPermissionRequiredToEditOwn={false}
                  hasPermissionToEditOthers={permList.includes(
                    'Manage Expiry/Assigned Dates',
                  )}
                >
                  {(register, errors) => (
                    <>
                      <label>Driver License State</label>
                      <input
                        {...register('driver_license_state', {
                          maxLength: {
                            value: 20,
                            message:
                              'Length exceeds the maximum limit of 20 characters.',
                          },
                          pattern: {
                            value: /^[A-Za-z ]+$/,
                            message: 'Only alphabets are allowed.',
                          },
                        })}
                        placeholder='Enter Driver License State'
                        defaultValue={profileData.DLState}
                        className={
                          errors.driver_license_state &&
                          'profile-form-field-error'
                        }
                      />
                      {errors.driver_license_state && (
                        <p className='error-message'>
                          {errors.driver_license_state.message}
                        </p>
                      )}
                    </>
                  )}
                </EditProfile>
              </li>

              <li role='listitem'>
                <span>
                  <b>Drivers License Expiration Date</b>
                </span>
                <span
                  className={
                    checkPastDate(profileData.dl_expiry) ? 'expired' : ''
                  }
                >
                  {formatDate(profileData.dl_expiry)}
                </span>
                <EditProfile
                  label='Drivers License Expiration Date'
                  profileData={profileData}
                  refetchUser={refetchUser}
                  authUser={authUser}
                  hasPermissionRequiredToEditOwn={false}
                  hasPermissionToEditOthers={permList.includes(
                    'Manage Expiry/Assigned Dates',
                  )}
                >
                  {(register, errors, setValue, control) => (
                    <>
                      <label>Drivers License Expiration Date</label>
                      <Controller
                        name='dl_expiry'
                        control={control}
                        defaultValue={
                          profileData.dl_expiry
                            ? moment(profileData.dl_expiry).format(
                                dateTimeConstants.DATE_FORMAT,
                              )
                            : null
                        }
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <DatePicker
                            selected={value ? moment(value) : ''}
                            onChange={(date) =>
                              onChange(
                                date &&
                                  !date
                                    .startOf('day')
                                    .isBefore(moment().startOf('day'))
                                  ? date.format(dateTimeConstants.DATE_FORMAT)
                                  : '',
                              )
                            }
                            customInput={<input inputMode='none' />}
                            minDate={moment()}
                            showMonthDropdown
                            showYearDropdown
                            placeholderText='Select Drivers License Expiration Date'
                            className={error ? 'profile-form-field-error' : ''}
                            dateFormat={dateTimeConstants.DATE_FORMAT}
                            showPopperArrow={false}
                          />
                        )}
                      />
                      {errors.dl_expiry && (
                        <p className='error-message'>
                          {errors.dl_expiry.message}
                        </p>
                      )}
                    </>
                  )}
                </EditProfile>
              </li>
            </>
          )}

          {permList.includes('Manage Administration Information') && (
            <>
              <li role='listitem'>
                <span>
                  <b>E-Verify Date</b>
                </span>
                <span>{formatDate(profileData.everify_date)}</span>
                <EditProfile
                  label='E-Verify Date'
                  profileData={profileData}
                  refetchUser={refetchUser}
                  authUser={authUser}
                  hasPermissionRequiredToEditOwn={false}
                  hasPermissionToEditOthers={permList.includes(
                    'Manage Administration Information',
                  )}
                >
                  {(register, errors, setValue, control) => (
                    <>
                      <label>E-Verify Date</label>
                      <Controller
                        name='everify_date'
                        control={control}
                        defaultValue={
                          profileData.everify_date
                            ? moment(profileData.everify_date).format(
                                dateTimeConstants.DATE_FORMAT,
                              )
                            : null
                        }
                        rules={{
                          required: 'E-Verify Date is required',
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <DatePicker
                            selected={value ? moment(value) : ''}
                            onChange={(date) =>
                              onChange(
                                date &&
                                  !date
                                    .endOf('day')
                                    .isAfter(moment().endOf('day'))
                                  ? date.format(dateTimeConstants.DATE_FORMAT)
                                  : '',
                              )
                            }
                            customInput={<input inputMode='none' />}
                            maxDate={moment()}
                            showMonthDropdown
                            showYearDropdown
                            placeholderText='Select E-Verify Date'
                            className={error ? 'profile-form-field-error' : ''}
                            dateFormat={dateTimeConstants.DATE_FORMAT}
                            showPopperArrow={false}
                          />
                        )}
                      />
                      {errors.everify_date && (
                        <p className='error-message'>
                          {errors.everify_date.message}
                        </p>
                      )}
                    </>
                  )}
                </EditProfile>
              </li>
              <li role='listitem'>
                <span>
                  <b>401k Eligibility Status and Date</b>
                </span>
                <span>
                  {profileData.dob &&
                  moment().diff(moment(profileData.dob), 'years') >= 21 &&
                  profileData.everify_date &&
                  moment().diff(moment(profileData.everify_date), 'years') >= 1
                    ? 'Eligible as of ' +
                      (moment(profileData.dob).add(21, 'years') >
                      moment(profileData.everify_date).add(1, 'year')
                        ? formatDate(moment(profileData.dob).add(21, 'years'))
                        : formatDate(
                            moment(profileData.everify_date).add(1, 'year'),
                          ))
                    : 'Not Eligible'}
                </span>
              </li>
            </>
          )}

          {(permList.includes('Manage Employee Profile Fields') ||
            ownProfile) && (
            <>
              <li role='listitem'>
                <span>
                  <b>Language</b>
                </span>
                <span>{profileData.language ?? '-'}</span>
                <EditProfile
                  label='Language'
                  profileData={profileData}
                  refetchUser={refetchUser}
                  authUser={authUser}
                  hasPermissionRequiredToEditOwn={true}
                  hasPermissionToEditOthers={permList.includes(
                    'Manage Employee Profile Fields',
                  )}
                >
                  {(register, errors) => (
                    <>
                      <label>Language</label>
                      <input
                        {...register('language', {
                          maxLength: {
                            value: 100,
                            message:
                              'Length exceeds the maximum limit of 100 characters.',
                          },
                          pattern: {
                            value: /^[A-Za-z, ]+$/,
                            message: 'Only alphabets and comma are allowed.',
                          },
                        })}
                        placeholder='Enter Language'
                        defaultValue={profileData.language}
                        className={
                          errors.language && 'profile-form-field-error'
                        }
                      />
                      {errors.language && (
                        <p className='error-message'>
                          {errors.language.message}
                        </p>
                      )}
                    </>
                  )}
                </EditProfile>
              </li>

              <li role='listitem'>
                <span>
                  <b>Height</b>
                </span>
                <span>{profileData.height ?? '-'}</span>
                <EditProfile
                  label='Height'
                  profileData={profileData}
                  refetchUser={refetchUser}
                  authUser={authUser}
                  hasPermissionRequiredToEditOwn={true}
                  hasPermissionToEditOthers={permList.includes(
                    'Manage Employee Profile Fields',
                  )}
                >
                  {(register, errors) => (
                    <>
                      <label>Height</label>
                      <input
                        {...register('height', {
                          maxLength: {
                            value: 10,
                            message:
                              'Length exceeds the maximum limit of 10 characters.',
                          },
                          pattern: {
                            value:
                              /^(?:(?:[4-7]' ?\d{1,2}")|(?:[4-7]-\d{1,2})|(?:[4-7] ft \d{1,2} in)|(?:[4-7]\.\d+ ft)|(?:\d{2,3}")|(?:\d{2,3} in))$/,
                            message:
                              'Invalid height format. Example: 6\'2", 6-2, 6 ft 2 in, 74"',
                          },
                        })}
                        placeholder={
                          'Enter Height. Sample formats: 6\'2", 6-2, 6 ft 2 in, 74"'
                        }
                        defaultValue={profileData.height}
                        className={errors.height && 'profile-form-field-error'}
                      />
                      {errors.height && (
                        <p className='error-message'>{errors.height.message}</p>
                      )}
                    </>
                  )}
                </EditProfile>
              </li>

              <li role='listitem'>
                <span>
                  <b>Weight</b>
                </span>
                <span>{profileData.weight + ' lbs'}</span>
                <EditProfile
                  label='Weight'
                  profileData={profileData}
                  refetchUser={refetchUser}
                  authUser={authUser}
                  hasPermissionRequiredToEditOwn={true}
                  hasPermissionToEditOthers={permList.includes(
                    'Manage Employee Profile Fields',
                  )}
                >
                  {(register, errors) => (
                    <>
                      <label>Weight</label>
                      <input
                        type='number'
                        {...register('weight', {
                          required: 'Weight is required',
                          min: {
                            value: 0,
                            message: 'Weight must be a valid value',
                          },
                          validate: (value) =>
                            value.toString().length <= 4 ||
                            'Weight must be at most 4 digits',
                          valueAsNumber: true,
                        })}
                        placeholder='Enter Weight in lbs'
                        defaultValue={profileData.weight}
                        className={errors.weight && 'profile-form-field-error'}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={preventNegativeValueInDocs}
                      />
                      {errors.weight && (
                        <p className='error-message'>{errors.weight.message}</p>
                      )}
                    </>
                  )}
                </EditProfile>
              </li>

              <li role='listitem'>
                <span>
                  <b>ICA #</b>
                </span>
                <span>{profileData.ica_number}</span>
                <EditProfile
                  label='ICA #'
                  profileData={profileData}
                  refetchUser={refetchUser}
                  authUser={authUser}
                  hasPermissionRequiredToEditOwn={false}
                  hasPermissionToEditOthers={permList.includes(
                    'Manage Sensitive Employee Profile Fields',
                  )}
                >
                  {(register, errors) => (
                    <>
                      <label>ICA #</label>
                      <input
                        type='number'
                        {...register('ica_number', {
                          required: 'ICA # is required',
                          min: {
                            value: 1,
                            message: 'ICA # must be a valid value',
                          },
                          validate: (value) =>
                            value.toString().length <= 12 ||
                            'ICA # must be at most 12 digits',
                          setValueAs: (v) => (v === '' ? '' : parseFloat(v)),
                        })}
                        placeholder='Enter ICA #'
                        defaultValue={profileData.ica_number}
                        className={
                          errors.ica_number && 'profile-form-field-error'
                        }
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={preventNegativeValueInDocs}
                      />
                      {errors.ica_number && (
                        <p className='error-message'>
                          {errors.ica_number.message}
                        </p>
                      )}
                    </>
                  )}
                </EditProfile>
              </li>
            </>
          )}

          {permList.includes('Manage Employee Affirmative Action Section') && (
            <>
              <li class='accordion_sub' role='listitem'>
                <h4>Affirmative Action</h4>
              </li>

              <li role='listitem'>
                <span>
                  <b>Race</b>
                </span>
                <span>{profileData.race ? profileData.race : '-'}</span>
                <EditProfile
                  label='Race'
                  profileData={profileData}
                  refetchUser={refetchUser}
                  authUser={authUser}
                  hasPermissionRequiredToEditOwn={false}
                  hasPermissionToEditOthers={permList.includes(
                    'Manage Employee Affirmative Action Section',
                  )}
                >
                  {(register, errors) => (
                    <>
                      <label>Race</label>
                      <select
                        {...register('race_id', {
                          required: 'Race is required',
                        })}
                        defaultValue={profileData.userattribute?.race_id}
                        className={errors.race_id && 'profile-form-field-error'}
                      >
                        <option value=''>Select Race</option>
                        {dropdownData.Race.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.value}
                          </option>
                        ))}
                      </select>
                      {errors.race_id && (
                        <p className='error-message'>
                          {errors.race_id.message}
                        </p>
                      )}
                    </>
                  )}
                </EditProfile>
              </li>

              <li role='listitem'>
                <span>
                  <b>EEO Classification</b>
                </span>
                <span>
                  {profileData.eeo_classification
                    ? profileData.eeo_classification?.title
                    : '-'}
                </span>
                <EditProfile
                  label='EEO Classification'
                  profileData={profileData}
                  refetchUser={refetchUser}
                  authUser={authUser}
                  hasPermissionRequiredToEditOwn={false}
                  hasPermissionToEditOthers={permList.includes(
                    'Manage Employee Affirmative Action Section',
                  )}
                >
                  {(register, errors) => (
                    <>
                      <label>EEO Classification</label>
                      <select
                        {...register('eeo_classification_id')}
                        defaultValue={profileData.eeo_classification_id}
                        className={
                          errors.eeo_classification_id &&
                          'profile-form-field-error'
                        }
                      >
                        <option value=''>Select EEO Classification</option>
                        {dropdownData.ListEeoClassification.map(
                          (item, index) => (
                            <option key={index} value={item.id}>
                              {item.title}
                            </option>
                          ),
                        )}
                      </select>
                      {errors.eeo_classification_id && (
                        <p className='error-message'>
                          {errors.eeo_classification_id.message}
                        </p>
                      )}
                    </>
                  )}
                </EditProfile>
              </li>

              <li role='listitem'>
                <span>
                  <b>Veteran Status</b>
                </span>
                <span>
                  {profileData.veteran === 1
                    ? 'Veteran'
                    : profileData.veteran === 0
                      ? 'Not a Veteran'
                      : '-'}
                </span>
                <EditProfile
                  label='Veteran Status'
                  profileData={profileData}
                  refetchUser={refetchUser}
                  authUser={authUser}
                  hasPermissionRequiredToEditOwn={false}
                  hasPermissionToEditOthers={permList.includes(
                    'Manage Employee Affirmative Action Section',
                  )}
                >
                  {(register, errors) => (
                    <>
                      <label>Veteran Status</label>
                      <select
                        {...register('veteran', {
                          required: 'Veteran Status is required',
                        })}
                        defaultValue={profileData.veteran}
                        className={errors.veteran && 'profile-form-field-error'}
                      >
                        <option value=''>Select Veteran Status</option>
                        <option value='1'>Veteran</option>
                        <option value='0'>Not a Veteran</option>
                      </select>
                      {errors.veteran && (
                        <p className='error-message'>
                          {errors.veteran.message}
                        </p>
                      )}
                    </>
                  )}
                </EditProfile>
              </li>
            </>
          )}

          {(permList.includes('Manage Employee Profile Fields') ||
            ownProfile) && (
            <>
              <li class='accordion_sub' role='listitem'>
                <h4>Uniform Information</h4>
              </li>

              <li role='listitem'>
                <span>
                  <b>Shirt Size</b>
                </span>
                <span>{profileData.shirt ? profileData.shirt : '-'}</span>
                <EditProfile
                  label='Shirt Size'
                  profileData={profileData}
                  refetchUser={refetchUser}
                  authUser={authUser}
                  hasPermissionRequiredToEditOwn={true}
                  hasPermissionToEditOthers={permList.includes(
                    'Manage Employee Profile Fields',
                  )}
                >
                  {(register, errors) => (
                    <>
                      <label>Shirt Size</label>
                      <select
                        {...register('shirt_id')}
                        defaultValue={profileData.shirt_size}
                        className={
                          errors.shirt_id && 'profile-form-field-error'
                        }
                      >
                        <option value=''>Select Shirt Size</option>
                        {dropdownData.ShirtSize.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.value}
                          </option>
                        ))}
                      </select>
                      {errors.shirt_id && (
                        <p className='error-message'>
                          {errors.shirt_id.message}
                        </p>
                      )}
                    </>
                  )}
                </EditProfile>
              </li>

              <li role='listitem'>
                <span>
                  <b>Pant Size</b>
                </span>
                <span>{profileData.pant ? profileData.pant : '-'}</span>
                <EditProfile
                  label='Pant Size'
                  profileData={profileData}
                  refetchUser={refetchUser}
                  authUser={authUser}
                  hasPermissionRequiredToEditOwn={true}
                  hasPermissionToEditOthers={permList.includes(
                    'Manage Employee Profile Fields',
                  )}
                >
                  {(register, errors) => (
                    <>
                      <label>Pant Size</label>
                      <select
                        {...register('pant_id')}
                        defaultValue={profileData.pant_size}
                        className={errors.pant_id && 'profile-form-field-error'}
                      >
                        <option value=''>Select Pant Size</option>
                        {dropdownData.PantSize.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.value}
                          </option>
                        ))}
                      </select>
                      {errors.pant_id && (
                        <p className='error-message'>
                          {errors.pant_id.message}
                        </p>
                      )}
                    </>
                  )}
                </EditProfile>
              </li>

              <li role='listitem'>
                <span>
                  <b>Inseam Pant Size</b>
                </span>
                <span>
                  {profileData.inseam_pant ? profileData.inseam_pant : '-'}
                </span>
                <EditProfile
                  label='Inseam Pant Size'
                  profileData={profileData}
                  refetchUser={refetchUser}
                  authUser={authUser}
                  hasPermissionRequiredToEditOwn={true}
                  hasPermissionToEditOthers={permList.includes(
                    'Manage Employee Profile Fields',
                  )}
                >
                  {(register, errors) => (
                    <>
                      <label>Inseam Pant Size</label>
                      <select
                        {...register('inseam_pant_id')}
                        defaultValue={profileData.inseam_pant_size}
                        className={
                          errors.inseam_pant_id && 'profile-form-field-error'
                        }
                      >
                        <option value=''>Select Inseam Pant Size</option>
                        {dropdownData.ListInseamPantSize.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.value}
                          </option>
                        ))}
                      </select>
                      {errors.inseam_pant_id && (
                        <p className='error-message'>
                          {errors.inseam_pant_id.message}
                        </p>
                      )}
                    </>
                  )}
                </EditProfile>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AdministrationComponent;
