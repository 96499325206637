import React from 'react';

import { EditProfile } from './EditProfile';

import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';

import {
  dateTimeConstants,
  DENIED_STATUS,
  dolApplicationStatus,
  insuranceCheckStatus,
} from '_constants';

import { formatDate } from '_helpers/helpers';

import moment from 'moment';

const DriverQualificationsComponent = ({
  profileData,
  refetchUser,
  isOpen,
  toggleAccordion,
  permList,
  authUser,
}) => {
  return (
    <div
      id='driver-qualifications'
      className={`profile-data__drops profile-data_comp ${isOpen ? 'is-active' : ''}`}
    >
      <header onClick={() => toggleAccordion('#driver-qualifications')}>
        <button type='button'>
          <h3>Driver Qualifications</h3>
        </button>
      </header>
      <div className='profile-data__drops-cnt'>
        <ul role='list'>
          <li class='accordion_sub' role='listitem'>
            <h4>Insurance Status</h4>
          </li>

          <li role='listitem'>
            <span>
              <b>Insurance Date Check</b>
            </span>
            <span>{formatDate(profileData.insurance_check_status_date)}</span>
            <EditProfile
              label='Insurance Date Check'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                'Manage Expiry/Assigned Dates',
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Insurance Date Check</label>
                  <Controller
                    name='insurance_check_status_date'
                    control={control}
                    defaultValue={
                      profileData.insurance_check_status_date
                        ? moment(
                            profileData.insurance_check_status_date,
                          ).format(dateTimeConstants.DATE_FORMAT)
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ''}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf('day').isAfter(moment().endOf('day'))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : '',
                          )
                        }
                        customInput={<input inputMode='none' />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText='Select Insurance Check Status Date'
                        className={error ? 'profile-form-field-error' : ''}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.insurance_check_status_date && (
                    <p className='error-message'>
                      {errors.insurance_check_status_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role='listitem'>
            <span>
              <b>Insurance</b>
            </span>
            <span
              className={
                insuranceCheckStatus[profileData.insurance_check_status] ==
                DENIED_STATUS
                  ? 'expired'
                  : ''
              }
            >
              {profileData.insurance_check_status &&
              profileData.insurance_check_status_date
                ? insuranceCheckStatus[profileData.insurance_check_status] +
                  ' on ' +
                  formatDate(profileData.insurance_check_status_date)
                : '-'}
            </span>
            <EditProfile
              label='Insurance'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                'Manage Expiry/Assigned Dates',
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Insurance</label>
                  <select
                    {...register('insurance_check_status')}
                    defaultValue={profileData.insurance_check_status}
                    className={
                      errors.insurance_check_status &&
                      'profile-form-field-error'
                    }
                  >
                    <option value=''>Select Insurance Check Status</option>
                    <option value='1'>Approved</option>
                    <option value='2'>Denied</option>
                  </select>
                  {errors.insurance_check_status && (
                    <p className='error-message'>
                      {errors.insurance_check_status.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role='listitem'>
            <span>
              <b>Driver Pin</b>
            </span>
            <span>{profileData.driver_pin ?? '-'}</span>
            <EditProfile
              label='Driver Pin'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                'Manage Expiry/Assigned Dates',
              )}
            >
              {(register, errors) => (
                <>
                  <label>Driver Pin</label>
                  <input
                    {...register('driver_pin', {
                      maxLength: {
                        value: 10,
                        message:
                          'Length exceeds the maximum limit of 10 characters.',
                      },
                      pattern: {
                        value: /^[A-Za-z0-9]+$/, // Regex for alphabets and numbers
                        message: 'Only alphabets and numbers are allowed.',
                      },
                    })}
                    placeholder='Enter Driver Pin'
                    defaultValue={profileData.driver_pin}
                    className={errors.driver_pin && 'profile-form-field-error'}
                  />
                  {errors.driver_pin && (
                    <p className='error-message'>{errors.driver_pin.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li class='accordion_sub' role='listitem'>
            <h4>Driver License Information</h4>
          </li>

          <li role='listitem'>
            <span>
              <b>DL #</b>
            </span>
            <span>{profileData.drivers_license ?? '-'}</span>
            <EditProfile
              label='DL #'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                'Manage Expiry/Assigned Dates',
              )}
            >
              {(register, errors) => (
                <>
                  <label>DL #</label>
                  <input
                    {...register('drivers_license', {
                      maxLength: {
                        value: 20,
                        message:
                          'Length exceeds the maximum limit of 20 characters.',
                      },
                      pattern: {
                        value: /^[A-Za-z0-9]+$/, // Regex for alphabets and numbers
                        message: 'Only alphabets and numbers are allowed.',
                      },
                    })}
                    placeholder='Enter Drivers License #'
                    defaultValue={profileData.drivers_license}
                    className={
                      errors.drivers_license && 'profile-form-field-error'
                    }
                  />
                  {errors.drivers_license && (
                    <p className='error-message'>
                      {errors.drivers_license.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role='listitem'>
            <span>
              <b>Driver's License State Issued</b>
            </span>
            <span>{profileData.DLState ? profileData.DLState : '-'}</span>
            <EditProfile
              label='Driver License State'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                'Manage Expiry/Assigned Dates',
              )}
            >
              {(register, errors) => (
                <>
                  <label>Driver License State</label>
                  <input
                    {...register('driver_license_state', {
                      maxLength: {
                        value: 20,
                        message:
                          'Length exceeds the maximum limit of 20 characters.',
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: 'Only alphabets are allowed.',
                      },
                    })}
                    placeholder='Enter Driver License State'
                    defaultValue={profileData.DLState}
                    className={
                      errors.driver_license_state && 'profile-form-field-error'
                    }
                  />
                  {errors.driver_license_state && (
                    <p className='error-message'>
                      {errors.driver_license_state.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role='listitem'>
            <span>
              <b>Driver's License Expiration Date</b>
            </span>
            <span>{formatDate(profileData.dl_expiry)}</span>
            <EditProfile
              label="Driver's License Expiration Date"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                'Manage Expiry/Assigned Dates',
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Driver's License Expiration Date</label>
                  <Controller
                    name='dl_expiry'
                    control={control}
                    defaultValue={
                      profileData.dl_expiry
                        ? moment(profileData.dl_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ''}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf('day')
                                .isBefore(moment().startOf('day'))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : '',
                          )
                        }
                        customInput={<input inputMode='none' />}
                        showMonthDropdown
                        showYearDropdown
                        minDate={moment()}
                        placeholderText='Select Expiry Date'
                        className={error ? 'profile-form-field-error' : ''}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.dl_expiry && (
                    <p className='error-message'>{errors.dl_expiry.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role='listitem'>
            <span>
              <b>Personal License Plate Number</b>
            </span>
            <span>{profileData.personal_licence_plate_number ?? '-'}</span>
            <EditProfile
              label='Personal License Plate Number'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                'Manage Employee Profile Fields',
              )}
            >
              {(register, errors) => (
                <>
                  <label>Personal License Plate Number</label>
                  <input
                    {...register('personal_licence_plate_number', {
                      maxLength: {
                        value: 20,
                        message:
                          'Length exceeds the maximum limit of 20 characters.',
                      },
                    })}
                    placeholder='Enter Personal License Plate Number'
                    defaultValue={profileData.personal_licence_plate_number}
                    className={
                      errors.personal_licence_plate_number &&
                      'profile-form-field-error'
                    }
                  />
                  {errors.personal_licence_plate_number && (
                    <p className='error-message'>
                      {errors.personal_licence_plate_number.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role='listitem'>
            <span>
              <b>Make / Model of Personal Vehicle</b>
            </span>
            <span>{profileData.plpn_make_or_model ?? '-'}</span>
            <EditProfile
              label='Make / Model of Personal Vehicle'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                'Manage Employee Profile Fields',
              )}
            >
              {(register, errors) => (
                <>
                  <label>Make / Model of Personal Vehicle</label>
                  <input
                    {...register('plpn_make_or_model', {
                      maxLength: {
                        value: 30,
                        message:
                          'Length exceeds the maximum limit of 30 characters.',
                      },
                    })}
                    placeholder='Enter Make / Model'
                    defaultValue={profileData.plpn_make_or_model}
                    className={
                      errors.plpn_make_or_model && 'profile-form-field-error'
                    }
                  />
                  {errors.plpn_make_or_model && (
                    <p className='error-message'>
                      {errors.plpn_make_or_model.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li class='accordion_sub' role='listitem'>
            <h4>Department of Labor Information</h4>
          </li>

          <li role='listitem'>
            <span>
              <b>DOL Application Status</b>
            </span>
            <span className={
                dolApplicationStatus[profileData.dol_application_status] ==
                DENIED_STATUS
                  ? 'expired'
                  : ''
              }>
              {profileData.dol_application_status &&
              profileData.dol_application_date
                ? dolApplicationStatus[profileData.dol_application_status] +
                  ' on ' +
                  formatDate(profileData.dol_application_date)
                : '-'}
            </span>
            <EditProfile
              label='DOL Application Date'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                'Manage Expiry/Assigned Dates',
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>DOL Application Status</label>
                  <select
                    {...register('dol_application_status')}
                    defaultValue={profileData.dol_application_status}
                    className={
                      errors.dol_application_status &&
                      'profile-form-field-error'
                    }
                  >
                    <option value=''>Select Status</option>
                    <option value='1'>Application Sent</option>
                    <option value='2'>Approved</option>
                    <option value='3'>Denied</option>
                  </select>
                  {errors.dol_application_status && (
                    <p className='error-message'>
                      {errors.dol_application_status.message}
                    </p>
                  )}

                  <label>Date of Action</label>
                  <Controller
                    name='dol_application_date'
                    control={control}
                    defaultValue={
                      profileData.dol_application_date
                        ? moment(profileData.dol_application_date).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ''}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf('day').isAfter(moment().endOf('day'))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : '',
                          )
                        }
                        customInput={<input inputMode='none' />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText='Select DOL Application Date'
                        className={error ? 'profile-form-field-error' : ''}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.dol_application_date && (
                    <p className='error-message'>
                      {errors.dol_application_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role='listitem'>
            <span>
              <b>DOL Number</b>
            </span>
            <span>{profileData.dol_number ?? '-'}</span>
            <EditProfile
              label='DOL Number'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                'Manage Expiry/Assigned Dates',
              )}
            >
              {(register, errors) => (
                <>
                  <label>DOL Number</label>
                  <input
                    {...register('dol_number', {
                      maxLength: {
                        value: 20,
                        message:
                          'Length exceeds the maximum limit of 20 characters.',
                      },
                    })}
                    placeholder='Enter DOL Number'
                    defaultValue={profileData.dol_number}
                    className={errors.dol_number && 'profile-form-field-error'}
                  />
                  {errors.dol_number && (
                    <p className='error-message'>{errors.dol_number.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role='listitem'>
            <span>
              <b>DOL Blue Card / MSPA Expiration Date</b>
            </span>
            <span>{formatDate(profileData.dol_blue_card_expiry)}</span>
            <EditProfile
              label='DOL Blue Card / MSPA Expiration Date'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                'Manage Expiry/Assigned Dates',
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>DOL Blue Card / MSPA Expiration Date</label>
                  <Controller
                    name='dol_blue_card_expiry'
                    control={control}
                    defaultValue={
                      profileData.dol_blue_card_expiry
                        ? moment(profileData.dol_blue_card_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ''}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf('day')
                                .isBefore(moment().startOf('day'))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : '',
                          )
                        }
                        customInput={<input inputMode='none' />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText='Select DOL Blue Card / MSPA Expiration Date'
                        className={error ? 'profile-form-field-error' : ''}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.dol_blue_card_expiry && (
                    <p className='error-message'>
                      {errors.dol_blue_card_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role='listitem'>
            <span>
              <b>DOL - Fingerprint Expiration Date</b>
            </span>
            <span>{formatDate(profileData.finger_print_expiry)}</span>
            <EditProfile
              label='DOL - Fingerprint Expiration Date'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                'Manage Expiry/Assigned Dates',
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>DOL - Fingerprint Expiration Date</label>
                  <Controller
                    name='finger_print_expiry'
                    control={control}
                    defaultValue={
                      profileData.finger_print_expiry
                        ? moment(profileData.finger_print_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ''}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf('day')
                                .isBefore(moment().startOf('day'))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : '',
                          )
                        }
                        customInput={<input inputMode='none' />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText='Select DOL - Fingerprint Expiration Date'
                        className={error ? 'profile-form-field-error' : ''}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.finger_print_expiry && (
                    <p className='error-message'>
                      {errors.finger_print_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role='listitem'>
            <span>
              <b>DOL - Physical Expiration Date</b>
            </span>
            <span>{formatDate(profileData.physical_expiry)}</span>
            <EditProfile
              label='DOL - Physical Expiration Date'
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                'Manage Expiry/Assigned Dates',
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>DOL - Physical Expiration Date</label>
                  <Controller
                    name='physical_expiry'
                    control={control}
                    defaultValue={
                      profileData.physical_expiry
                        ? moment(profileData.physical_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ''}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf('day')
                                .isBefore(moment().startOf('day'))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : '',
                          )
                        }
                        customInput={<input inputMode='none' />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText='Select DOL - Physical Expiration Date'
                        className={error ? 'profile-form-field-error' : ''}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.physical_expiry && (
                    <p className='error-message'>
                      {errors.physical_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DriverQualificationsComponent;
