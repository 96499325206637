export const applicantsConstants = {
  GETAPPLN_REQUEST: "APPLICANT_GETAPPLN_REQUEST",
  GETAPPLN_SUCCESS: "APPLICANT_GETAPPLN_SUCCESS",
  GETAPPLN_FAILURE: "APPLICANT_GETAPPLN_FAILURE",

  VIEWAPPLN_REQUEST: "APPLICANT_VIEWAPPLN_REQUEST",
  VIEWAPPLN_SUCCESS: "APPLICANT_VIEWAPPLN_SUCCESS",
  VIEWAPPLN_FAILURE: "APPLICANT_VIEWAPPLN_FAILURE",

  GETFILTER_REQUEST: "APPLICANT_GETFILTER_REQUEST",
  GETFILTER_SUCCESS: "APPLICANT_GETFILTER_SUCCESS",
  GETFILTER_FAILURE: "APPLICANT_GETFILTER_FAILURE",

  FILTERS_REQUEST: "APPLICANT_FILTERS_REQUEST",
  FILTERS_SUCCESS: "APPLICANT_FILTERS_SUCCESS",
  FILTERS_FAILURE: "APPLICANT_FILTERS_FAILURE",

  GETFIELD_REQUEST: "APPLICANT_GETFIELD_REQUEST",
  GETFIELD_SUCCESS: "APPLICANT_GETFIELD_SUCCESS",
  GETFIELD_FAILURE: "APPLICANT_GETFIELD_FAILURE",

  EDITAPP_REQUEST: "APPLICANT_EDITAPP_REQUEST",
  EDITAPP_SUCCESS: "APPLICANT_EDITAPP_SUCCESS",
  EDITAPP_FAILURE: "APPLICANT_EDITAPP_FAILURE",

  UPDATE_NOTE_REQUEST: "UPDATE_NOTE_REQUEST",
  UPDATE_NOTE_SUCCESS: "UPDATE_NOTE_SUCCESS",
  UPDATE_NOTE_FAILURE: "UPDATE_NOTE_FAILURE",

  DELETE_NOTE_REQUEST: "DELETE_NOTE_REQUEST",
  DELETE_NOTE_SUCCESS: "DELETE_NOTE_SUCCESS",
  DELETE_NOTE_FAILURE: "DELETE_NOTE_FAILURE",

  GET_APPLICANT_DETAILS_REQUEST: "GET_APPLICANT_DETAILS_REQUEST",
  GET_APPLICANT_DETAILS_SUCCESS: "GET_APPLICANT_DETAILS_SUCCESS",
  GET_APPLICANT_DETAILS_ERROR: "GET_APPLICANT_DETAILS_ERROR",

  GET_FORMER_EMPLOYED_APPLICANTS_REQUEST:
    "GET_FORMER_EMPLOYED_APPLICANTS_REQUEST",
  GET_FORMER_EMPLOYED_APPLICANTS_SUCCESS:
    "GET_FORMER_EMPLOYED_APPLICANTS_SUCCESS",
  GET_FORMER_EMPLOYED_APPLICANTS_FAILURE:
    "GET_FORMER_EMPLOYED_APPLICANTS_FAILURE",

  SAVE_ADMIN_REASON_FOR_LEAVING_REQUEST:
    "SAVE_ADMIN_REASON_FOR_LEAVING_REQUEST",
  SAVE_ADMIN_REASON_FOR_LEAVING_SUCCESS:
    "SAVE_ADMIN_REASON_FOR_LEAVING_SUCCESS",
  SAVE_ADMIN_REASON_FOR_LEAVING_FAILURE:
    "SAVE_ADMIN_REASON_FOR_LEAVING_FAILURE",
};
