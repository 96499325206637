import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
// import { applicantsActions } from '../_actions';
import { resourceActions } from "../_actions";
import "../assets/styles/resource.css";
import { config } from "../_helpers";

// function formatPhoneNumber(s) {
//   var s2 = ("" + s).replace(/\D/g, "");
//   var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
//   return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
// }

class ArchivedResources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      statusOpen: false,
      errorMessage: "",
      keyword: "",
      unarchiveIndex: null,
      timeout: null,
      page: 1,
      scrolled: false,
      last_page: 1,
    };

    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.unarchive = this.unarchive.bind(this);
    this.setCrew = this.setCrew.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  statusOpenModal(index) {
    this.setState({
      statusOpen: true,
      unarchiveIndex: index,
      errorMessage: "",
    });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }

  componentDidMount() {
    this.props.dispatch(resourceActions.archivedResources({}));
  }

  componentWillReceiveProps(nextProps) {
    window.addEventListener("scroll", this.handleScroll);

    const employee = nextProps.employee;
    const resource = nextProps.resource;

    var employeesOption = [];
    var resource_list = [];

    if (resource.unarchive_index >= 0) {
      let resources = Object.assign([], this.state.resources);
      resources.splice(resource.unarchive_index, 1);
      this.setState({
        resources: resources,
      });
    }

    if (resource.error) {
      let error = JSON.parse(resource.error);
      this.setState({ errorMessage: error.message });

      setTimeout(() => {
        this.setState({
          errorMessage: "",
        });
      }, 3000);
    }

    if (resource.archived) {
      if (this.state.scrolled) {
        this.setState({
          resources: [...this.state.resources, ...resource.archived.data.data],
          last_page: resource.archived.data.last_page,
          scrolled: false,
        });
      } else {
        this.setState({
          resources: resource.archived.data.data,
          last_page: resource.archived.data.last_page,
        });
      }
    }
  }

  setCrew(event) {
    this.setState({ crew_identifier_id: event.target.value });
  }

  onSearch(event) {
    this.setState(
      {
        [event.target.name]: event.target.value,
        page: 1,
      },
      () => {
        clearTimeout(this.state.timeout);
        this.state.timeout = setTimeout(() => {
          this.props.dispatch(
            resourceActions.archivedResources({
              keyword: this.state.keyword,
              page: this.state.page,
            }),
          );
        }, 1000);
      },
    );
  }

  unarchive() {
    this.statusCloseModal();
    this.props.dispatch(
      resourceActions.unarchiveResource(
        this.state.resources[this.state.unarchiveIndex].id,
        this.state.unarchiveIndex,
      ),
    );
  }

  handleScroll(event) {
    var wrap = document.getElementById("right_sidebar"),
      contentHeight = wrap.offsetHeight,
      yOffset = window.pageYOffset,
      y = yOffset + window.innerHeight;

    if (y >= contentHeight) {
      if (this.state.page < this.state.last_page) {
        this.setState(
          {
            page: this.state.page + 1,
            scrolled: true,
          },
          () => {
            this.props.dispatch(
              resourceActions.archivedResources({
                keyword: this.state.keyword,
                page: this.state.page,
              }),
            );
          },
        );
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <div className="resource_container">
        <div className="breadcrumbs">
          <Link to={"/resources"} className="green">
            Resources
          </Link>{" "}
          / Archived Resources
        </div>
        {this.state.errorMessage && (
          <div className="alert alert-danger" role="alert">
            {this.state.errorMessage}{" "}
          </div>
        )}
        <div className="page_title float">
          <h1>Archived Resources</h1>
        </div>

        <div className="resource_list">
          <div className="inner_head">
            <input
              type="text"
              className="resource_search"
              name="keyword"
              placeholder="Search Resources..."
              onChange={this.onSearch.bind(this)}
              autoComplete="off"
            />
          </div>

          <div
            className="content_div archived_wrapper resource_archived_list"
            onScroll={this.handleScroll}
          >
            <div className="appln_list_header archived_list">
              <div className="resource list_item_head">Resource</div>
              <div className="name list_item_head">Name</div>
              <div className="type list_item_head phone">Type</div>
              <div className="email list_item_head email"></div>
              <div className="unarchive list_item_head unarchive" />
            </div>
            {this.state.resources.map((item, index) => {
              return (
                <div className="applicant_item" key={index}>
                  <div className="basic_info">
                    <div className="list_item">
                      <img
                        className="pic"
                        src={item.resource_photo}
                        alt=""
                        onError={(e) => {
                          e.target.setAttribute(
                            "src",
                            config.apiUrl + "/" + config.altImg,
                          );
                        }}
                      />
                    </div>
                    <div className="appln_name list_item">
                      {item.resource_name}
                    </div>
                    <div className="list_item type">{item.resource_type}</div>
                    <a
                      className="unarchive list_item"
                      href={`/archived-resources/${item?.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                    </a>
                    <div
                      className="unarchive list_item"
                      onClick={this.statusOpenModal.bind(this, index)}
                    >
                      Unarchive
                    </div>
                  </div>
                </div>
              );
            })}
            {this.state.resources.length <= 0 && (
              <div className="applicant_item" key="noEmployees">
                <p className="no_employee float">No Resources found</p>
              </div>
            )}
          </div>
        </div>

        <Modal
          style={"width: 550px"}
          id="status_popup"
          className="status_popup"
          open={this.state.statusOpen}
          onClose={this.statusCloseModal.bind(this)}
        >
          <div id="statusPopup" className="unarchivePopup">
            <div className="modal-head">
              <h4 className="modal-title">Unarchive Resource</h4>
            </div>

            <div className="modal-body">
              <div className="status_step step1">
                <label className="popup-text-label">
                  Are you sure you want to unarchive this resource ?
                </label>
                <br />
                <div className="btn_list">
                  <button
                    className="button grey"
                    onClick={this.statusCloseModal.bind(this)}
                  >
                    Cancel
                  </button>
                  <button
                    className="button"
                    onClick={this.unarchive.bind(this)}
                  >
                    Unarchive
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { employee, resource } = state;
  return {
    employee,
    resource,
  };
}

const connectedResourcePage = connect(mapStateToProps)(ArchivedResources);
export { connectedResourcePage as ArchivedResources };
