import React, { useEffect, useState } from "react";
import styles from "./SystemManage.module.scss";
import { ReactComponent as Info } from ".././assets/icons/info-green.svg";
import Modal from "react-modal";
import BulkImportPreview from "./BulkImportPreview";
import { v4 as uuidv4 } from "uuid";
import { config } from "_helpers";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "_actions";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { SwitchableTabs } from "_components/utils/Tabs/SwitchableTabs";
import { SystemManagementSettings } from "./SystemManagementSettings/SystemManagementSettings";
import { BypassEmployeeApplication } from "./BypassEmployeeApplication/BypassEmployeeApplication";

const TEMPLATE_FILE_URL = `${config?.assetUrl}samples/downloads/Import-Bypass-Employees.csv`;
const SystemManage = () => {
  const dispatch = useDispatch();
  const addEmployeeLoading = useSelector(
    (state) => state.user.isAddEmployeeLoading,
  );

  const [addEmployeeModal, setAddEmployeeModal] = useState(false);
  const [bulkImportModal, setBulkImportModal] = useState(false);
  const [error, setError] = useState(false);
  const [isBulkImport, setIsBulkImport] = useState(false);
  const [paginatedData, setPaginatedData] = useState();
  const [rawData, setRawData] = useState([]);
  const [fileError, setFileError] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [employee, setEmployee] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const handleAddEmployeeOnChange = (e) => {
    const { name, value } = e.target;
    setEmployee({ ...employee, [name]: value });
  };

  const onAdd = async () => {
    if (employee?.email === "" || /^\s*$/.test(employee?.email))
      return setError(true);
    const data = new FormData();
    data.append("firstname", employee.firstName);
    data.append("lastname", employee.lastName);
    data.append("email", employee.email);
    const res = await dispatch(userActions.addEmployeeForBypassing(data));

    if (res.success) {
      onCancelAddEmployee();
      return toast.success("Employee added successfully.");
    } else {
      return toast.error(res.message);
    }
  };

  const onImportBulk = () => {
    if (!rawData.length) return;
    setIsBulkImport(true); // to show uploaded data in new page
    setBulkImportModal(false);
  };

  const handleFileSelect = (e) => {
    setFileError("");
    const file = e.target.files[0];
    // const fileName = file.name;
    // const fileExtension = fileName.split(".").pop();
    // if (fileExtension !== "csv") {
    //   setFileError(
    //     "The file has the wrong formatting. Please check the template file for the correct formatting."
    //   );
    //   return;
    // }

    // Parse and check csv data
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // Check if the parsed CSV file has the expected column headers
        const expectedHeaders = ["First name", "Last name", "Email"];
        const headers = results.meta.fields;
        if (
          headers.length !== 3 ||
          !headers.every((header) => expectedHeaders.includes(header))
        ) {
          setFileError(
            "The file has the wrong formatting. Please check the template file for the correct formatting.",
          );
          return;
        }

        // Check if each row of the parsed CSV file has valid data
        const rows = results.data;
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          if (!row["First name"] || !row["Last name"] || !row["Email"]) {
            setFileError(
              `Row ${i + 2} of the uploaded CSV file does not have valid data.`,
            );
            return;
          }
        }

        // The uploaded CSV file is valid
        // Process the data in the CSV file
        const reader = new FileReader();

        reader.onload = (event) => {
          const batches = [];
          const batchSize = 30;
          const contents = event.target.result;
          const rows = contents.trim().split("\n").slice(1); // skip first row (headers)
          const data = rows.map((row, index) => {
            const [firstname, lastname, email] = row.split(",");
            return { firstname, lastname, email, id: uuidv4(), index };
          });

          for (let i = 0; i < data.length; i += batchSize) {
            batches.push(data.slice(i, i + batchSize));
          }
          setRawData(data);
          setPaginatedData(batches);
        };

        reader.readAsText(file);
      },
      error: function (error) {
        setFileError("An error occurred while parsing the uploaded CSV file.");
        console.error(error);
      },
    });
  };

  const onDownloadTemplate = () => {
    saveAs(TEMPLATE_FILE_URL, "");
  };

  const onCancelAddEmployee = () => {
    setAddEmployeeModal(false);
    setEmployee({
      firstName: "",
      lastName: "",
      email: "",
    });
    setError(false);
  };

  const onCancelBulkImport = () => {
    setBulkImportModal(false);
    setFileError("");
    setRawData([]);
    setPaginatedData([]);
  };

  useEffect(() => {
    if (!rawData.length) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [rawData.length]);

  return (
    <div className={styles.SystemManage}>
      {/* ------------------------ADD EMPLOYEE MODAL------------------------- */}

      <Modal
        isOpen={addEmployeeModal}
        overlayClassName="modal-overlay"
        className={styles.system_manage_modal}
      >
        <div className="system-manage-modal-header">
          <h4>ADD EMPLOYEE</h4>
        </div>
        <div className="system-manage-modal-body">
          <label htmlFor="first-name">First Name</label>
          <input
            name="firstName"
            type="text"
            onChange={handleAddEmployeeOnChange}
          />
          <label htmlFor="first-name">Last Name</label>
          <input
            name="lastName"
            type="text"
            onChange={handleAddEmployeeOnChange}
          />
          <label htmlFor="first-name">
            Email Address<span style={{ color: "red" }}>*</span>
          </label>
          <input
            name="email"
            type="email"
            onChange={handleAddEmployeeOnChange}
          />
          {error && <p className="error">Email address is required.</p>}
        </div>
        <div className="system-manage-modal-footer">
          <button className="button grey" onClick={onCancelAddEmployee}>
            Cancel
          </button>
          <button
            className="button"
            onClick={onAdd}
            disabled={addEmployeeLoading}
          >
            Add
          </button>
        </div>
      </Modal>

      {/* ------------------------BULK IMPORT MODAL------------------------- */}
      <Modal
        isOpen={bulkImportModal}
        overlayClassName="modal-overlay"
        className={styles.system_manage_modal}
      >
        <div className="system-manage-modal-header">
          <h4>BULK IMPORT</h4>
        </div>
        <div className="system-manage-modal-body">
          <input type="file" accept=".csv" onChange={handleFileSelect} />
          <p style={{ color: "red" }}>{fileError}</p>
          <p>
            <span className="download-file" onClick={onDownloadTemplate}>
              Click here
            </span>{" "}
            to download the template file.
          </p>
        </div>
        <div className="system-manage-modal-footer">
          <button className="button grey" onClick={onCancelBulkImport}>
            Cancel
          </button>
          <button
            className="button"
            onClick={onImportBulk}
            disabled={isDisabled || fileError.length}
          >
            Continue
          </button>
        </div>
      </Modal>

      {/* ------------------------------------------------- */}
      <div className="main-container">
        <div className="system-management-outer-tabs">
          <SwitchableTabs
            tab1Name="Settings"
            tab2Name="Bypass Employee Application"
            tab1Content={<SystemManagementSettings />}
            tab2Content={
              <BypassEmployeeApplication
                isBulkImport={isBulkImport}
                setIsBulkImport={setIsBulkImport}
                setPaginatedData={setPaginatedData}
                paginatedData={paginatedData}
                rawData={rawData}
                onCancelBulkImport={onCancelBulkImport}
                setAddEmployeeModal={setAddEmployeeModal}
                setBulkImportModal={setBulkImportModal}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SystemManage;
