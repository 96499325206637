import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import footerLogo from "../assets/images/firestorm.png";
// import { userActions } from '../_actions';

class Position extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      captain: false,
    };
    this.goBack = this.goBack.bind(this);
    this.information = this.information.bind(this);
  }

  componentDidMount() {}

  information(position) {
    if (position === "Superintendent / Engine Captain") {
      this.setState({ captain: true });
    } else {
      this.setState({ captain: false });
      this.props.information(this.props.type, position);
    }
  }

  goBack() {
    this.props.toResourceType();
  }

  render() {
    return (
      <div className="resource_type">
        <div className="login_header">
          <Link to={"/login"}>
            <img src={footerLogo} alt="Firestorm" />
          </Link>
          <p>Employee Management System</p>
        </div>

        <div className="top_head">
          <span className="back" onClick={this.goBack}>
            Back
          </span>
          <span className="title">Step 2 - Choose Position</span>
        </div>

        <div className="signup-contentarea">
          <div
            id="choosePositionWrapA"
            className={
              this.props.type === "FireFighter" && !this.state.captain
                ? "position-select"
                : "hide position-select"
            }
          >
            <button onClick={this.information.bind(this, "Firefighter")}>
              <span className="pos-sel">Firefighter</span>
            </button>
            <button onClick={this.information.bind(this, "Crew Captain")}>
              <span className="pos-sel">Crew Captain</span>
            </button>
            <button
              onClick={this.information.bind(
                this,
                "Superintendent / Engine Captain",
              )}
            >
              <span className="expand-opts">
                Superintendent / Engine Captain
              </span>
            </button>
          </div>
          <div
            id="choosePositionWrapB"
            className={
              this.props.type === "AdminStaff" && !this.state.captain
                ? "position-select"
                : "hide position-select"
            }
          >
            <button onClick={this.information.bind(this, "Staff Manager")}>
              <span className="pos-sel">Staff Manager</span>
            </button>
            <button onClick={this.information.bind(this, "Staff")}>
              <span className="pos-sel">Staff</span>
            </button>
          </div>
          <div
            id="choosePositionWrapC"
            className={
              this.props.type === "FireFighter" && this.state.captain
                ? "position-select"
                : "hide position-select"
            }
          >
            <button onClick={this.information.bind(this, "Superintendent")}>
              <span className="pos-sel">Superintendent</span>
            </button>
            <button onClick={this.information.bind(this, "Engine Captain")}>
              <span className="pos-sel">Engine Captain</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering } = state.registration;
  return {
    registering,
  };
}

const connectedRegisterPage = connect(mapStateToProps)(Position);
export { connectedRegisterPage as Position };
