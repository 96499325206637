import React from "react";

import { EditProfile } from "./EditProfile";

import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { checkPastDate, formatDate } from "_helpers/helpers";
import { dateTimeConstants } from "_constants";
import moment from "moment";

const ImportantDatesComponent = ({
  profileData,
  refetchUser,
  isOpen,
  toggleAccordion,
  permList,
  authUser,
}) => {
  return (
    <div
      id="important-dates"
      className={`profile-data__drops profile-data_comp ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#important-dates")}>
        <button type="button">
          <h3>Important Dates</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li class='accordion_sub' role='listitem'>
            <h4>Assigned Dates</h4>
          </li>

          <li role="listitem">
            <span>
              <b>Security Code</b>
            </span>
            <span>{formatDate(profileData.security_code_assigned)}</span>
            <EditProfile
              label="Security Code"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Security Code</label>
                  <Controller
                    name="security_code_assigned"
                    control={control}
                    defaultValue={
                      profileData.security_code_assigned
                        ? moment(profileData.security_code_assigned).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Security Code Assigned Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.security_code_assigned && (
                    <p className="error-message">
                      {errors.security_code_assigned.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Credit Card</b>
            </span>
            <span>{formatDate(profileData.credit_card_assigned)}</span>
            <EditProfile
              label="Credit Card"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Credit Card</label>
                  <Controller
                    name="credit_card_assigned"
                    control={control}
                    defaultValue={
                      profileData.credit_card_assigned
                        ? moment(profileData.credit_card_assigned).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Credit Card Assigned Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.credit_card_assigned && (
                    <p className="error-message">
                      {errors.credit_card_assigned.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Fuel Pin</b>
            </span>
            <span>{formatDate(profileData.fuel_pin_assigned)}</span>
            <EditProfile
              label="Fuel Pin"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Fuel Pin</label>
                  <Controller
                    name="fuel_pin_assigned"
                    control={control}
                    defaultValue={
                      profileData.fuel_pin_assigned
                        ? moment(profileData.fuel_pin_assigned).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Fuel Pin Assigned Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.fuel_pin_assigned && (
                    <p className="error-message">
                      {errors.fuel_pin_assigned.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Key Fob</b>
            </span>
            <span>
              {(profileData.key_fob ? profileData.key_fob + ": " : "") +
                (profileData.key_fob_assigned
                  ? formatDate(profileData.key_fob_assigned)
                  : "-")}
            </span>
            <EditProfile
              label="Key Fob"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Key Fob Assigned Date</label>
                  <Controller
                    name="key_fob_assigned"
                    control={control}
                    defaultValue={
                      profileData.key_fob_assigned
                        ? moment(profileData.key_fob_assigned).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Key Fob Assigned Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.key_fob_assigned && (
                    <p className="error-message">
                      {errors.key_fob_assigned.message}
                    </p>
                  )}

                  <label>Key Fob Name</label>
                  <input
                    {...register("key_fob", {
                      maxLength: {
                        value: 10,
                        message:
                          "Length exceeds the maximum limit of 10 characters.",
                      },
                    })}
                    placeholder="Enter Key Fob Name"
                    defaultValue={profileData.key_fob}
                    className={errors.key_fob && "profile-form-field-error"}
                  />
                  {errors.key_fob && (
                    <p className="error-message">{errors.key_fob.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li class='accordion_sub' role='listitem'>
            <h4>Expiration Dates</h4>
          </li>

          <li role="listitem">
            <span>
              <b>Drivers License</b>
            </span>
            <span
              className={checkPastDate(profileData.dl_expiry) ? "expired" : ""}
            >
              {formatDate(profileData.dl_expiry)}
            </span>
            <EditProfile
              label="Drivers License"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Drivers License</label>
                  <Controller
                    name="dl_expiry"
                    control={control}
                    defaultValue={
                      profileData.dl_expiry
                        ? moment(profileData.dl_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Drivers License Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.dl_expiry && (
                    <p className="error-message">{errors.dl_expiry.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>DOL Blue Card/MSPA</b>
            </span>
            <span
              className={
                checkPastDate(profileData.dol_blue_card_expiry) ? "expired" : ""
              }
            >
              {formatDate(profileData.dol_blue_card_expiry)}
            </span>
            <EditProfile
              label="DOL Blue Card/MSPA"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>DOL Blue Card/MSPA</label>
                  <Controller
                    name="dol_blue_card_expiry"
                    control={control}
                    defaultValue={
                      profileData.dol_blue_card_expiry
                        ? moment(profileData.dol_blue_card_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select DOL Blue Card/MSPA Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.dol_blue_card_expiry && (
                    <p className="error-message">
                      {errors.dol_blue_card_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>DOL - Finger Print</b>
            </span>
            <span
              className={
                checkPastDate(profileData.finger_print_expiry) ? "expired" : ""
              }
            >
              {formatDate(profileData.finger_print_expiry)}
            </span>
            <EditProfile
              label="DOL - Finger Print"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>DOL - Finger Print</label>
                  <Controller
                    name="finger_print_expiry"
                    control={control}
                    defaultValue={
                      profileData.finger_print_expiry
                        ? moment(profileData.finger_print_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Finger Print Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.finger_print_expiry && (
                    <p className="error-message">
                      {errors.finger_print_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>DOL - Physical</b>
            </span>
            <span
              className={
                checkPastDate(profileData.physical_expiry) ? "expired" : ""
              }
            >
              {formatDate(profileData.physical_expiry)}
            </span>
            <EditProfile
              label="DOL - Physical"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>DOL - Physical</label>
                  <Controller
                    name="physical_expiry"
                    control={control}
                    defaultValue={
                      profileData.physical_expiry
                        ? moment(profileData.physical_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Physical Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.physical_expiry && (
                    <p className="error-message">
                      {errors.physical_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>First Aid/CPR</b>
            </span>
            <span
              className={
                checkPastDate(profileData.first_aid_expiry) ? "expired" : ""
              }
            >
              {formatDate(profileData.first_aid_expiry)}
            </span>
            <EditProfile
              label="First Aid/CPR"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>First Aid/CPR</label>
                  <Controller
                    name="first_aid_expiry"
                    control={control}
                    defaultValue={
                      profileData.first_aid_expiry
                        ? moment(profileData.first_aid_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select First Aid/CPR Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.first_aid_expiry && (
                    <p className="error-message">
                      {errors.first_aid_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Sexual Harassment Certification</b>
            </span>
            <span>
              {!(
                profileData.sexual_harassment_certification ||
                profileData.sexual_harassment_expiry
              ) ? (
                "-"
              ) : (
                <>
                  {profileData.sexual_harassment_certification
                    ? "Certified on " +
                      formatDate(profileData.sexual_harassment_certification)
                    : ""}
                  <br />
                  {profileData.sexual_harassment_expiry
                    ? (moment(profileData.sexual_harassment_expiry) < moment()
                        ? "Expired "
                        : "Expires ") +
                      "on " +
                      formatDate(profileData.sexual_harassment_expiry)
                    : ""}
                </>
              )}
            </span>
            <EditProfile
              label="Sexual Harassment Certification Dates"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Certification Date</label>
                  <Controller
                    name="sexual_harassment_certification"
                    control={control}
                    defaultValue={
                      profileData.sexual_harassment_certification
                        ? moment(
                            profileData.sexual_harassment_certification,
                          ).format(dateTimeConstants.DATE_FORMAT)
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Certification Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.sexual_harassment_certification && (
                    <p className="error-message">
                      {errors.sexual_harassment_certification.message}
                    </p>
                  )}
                  <label>Expiration Date</label>
                  <Controller
                    name="sexual_harassment_expiry"
                    control={control}
                    defaultValue={
                      profileData.sexual_harassment_expiry
                        ? moment(profileData.sexual_harassment_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Expiration Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.sexual_harassment_expiry && (
                    <p className="error-message">
                      {errors.sexual_harassment_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Faller Qualification</b>
            </span>
            <span
              className={
                checkPastDate(profileData.faller_quals) ? "expired" : ""
              }
            >
              {formatDate(profileData.faller_quals)}
            </span>
            <EditProfile
              label="Faller Qualification"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Faller Qualification</label>
                  <Controller
                    name="faller_quals"
                    control={control}
                    defaultValue={
                      profileData.faller_quals
                        ? moment(profileData.faller_quals).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Faller Qualification Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.faller_quals && (
                    <p className="error-message">
                      {errors.faller_quals.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>


        </ul>
      </div>
    </div>
  );
};

export default ImportantDatesComponent;
