import { authHeader, config } from "_helpers";

export const interviewService = {
  getInterviewFormList,
  saveInterviewForm,
  getInterviewForm,
  generateInterviewPdf,
  deleteInterviewForm,
};

function getInterviewFormList(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + `/api/interviewform/list`, requestOptions).then(
    handleResponse,
    handleError,
  );
}

function getInterviewForm(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    config.apiUrl + `/api/interviewform/content/${id}`,
    requestOptions,
  ).then(handleResponse, handleError);
}

function saveInterviewForm(data) {
  const requestOptions = {
    headers: authHeader(),
    method: "POST",
    body: JSON.stringify(data),
  };
  return fetch(config.apiUrl + "/api/interviewform/save", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function deleteInterviewForm(data) {
  const requestOptions = {
    headers: authHeader(),
    method: "POST",
    body: data,
  };
  return fetch(
    config.apiUrl + "/api/interviewform/delete",
    requestOptions,
  ).then(handleResponse, handleError);
}

function generateInterviewPdf(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    config.apiUrl + `/api/interviewform/pdf/${id}`,
    requestOptions,
  ).then(handleResponse, handleError);
}

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then((json) => resolve(json));
      } else {
        resolve();
      }
    } else {
      // return error message from response body
      response.text().then((text) => reject(text));
    }
  });
}

function handleError(error) {
  return Promise.reject(error && error.message);
}
