export const unAvailableReason = (reason, other) => {
  let unavailableReason = "No Reason";
  switch (reason) {
    case 0:
      unavailableReason = "";
      break;
    case 1:
      unavailableReason = "Time off";
      break;
    case 2:
      unavailableReason = "Sick";
      break;
    case 3:
      unavailableReason = "Injury";
      break;
    case 4:
      unavailableReason = other;
      break;
    default:
      unavailableReason = "";
  }
  return unavailableReason;
};

export const getApplicantStatus = {
  0: "Not Selected & Archive",
  1: "Received",
  2: "Was Hired",
  3: "In Review",
  4: "Offered Position",
  10: "Do Not Hire & Archive",
};

export const reportList = [
  {
    title: "Qualification Report",
    key: "qualification",
    permission: "Qualification Report",
  },
  { title: "Vehicle Report", key: "vehicle", permission: "Vehicle Report" },
  {
    title: "Expiration Date Report",
    key: "expiration",
    permission: "Expiration Date Report",
  },
  {
    title: "All Inventory Report",
    key: "inventory",
    permission: "All Inventory Reports",
  },
  {
    title: "Equipment Sold Report",
    key: "equipment",
    permission: "Equipment Sold Report",
  },
  {
    title: "Employee Birthdays",
    key: "birthday",
    permission: "Employee Birthdays",
  },
  { title: "Refresher Report", key: "refresher", permission: "RT 130 Reports" },
  {
    title: "Docs Status List",
    key: "master",
    permission: "Master Admin List Report",
  },
  {
    title: "Mileage/Hobbs List",
    key: "mileage",
    permission: "Manage Resources",
  },
  {
    title: "Applicant's Report",
    key: "applicants",
    permission: "Manage Resources",
  },
  {
    title: "Fire History Report",
    key: "fire_history",
    permission: "Manage Resources",
  },
  {
    title: "Affirmative Action Report",
    key: "affirmative_action",
    permission: "View Affirmative Action Report",
  },
  {
    title: "DOL Application Status Report",
    key: "dol_approved",
    permission: "Manage Resources",
  },
];
