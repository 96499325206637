import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactModal from "react-modal";
import { userActions, docsActions } from "_actions";
import { SidebarUser } from "./SidebarUser";
import { SidebarUserMobile } from "./SidebarUserMobile";
import { config } from "_helpers/config";
import { Redirect } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { AlertPopup } from "../../_components/AlertPopup";

import {
  CandidateInfoPage,
  EmployeeAgreement,
  EmployeeAgreement_GISS,
  MPN,
  PaycheckDistribution,
  DisabilityStatus,
  CoveredVeteran,
  F8850,
  DisclosureStatement,
  WageRateAgreement,
  WageRateAgreement_GISS,
  RightOfWorkers,
  JobDescription,
  PayDates,
  MerchandiseOrder,
  I9,
  W4,
  EmployeeRehireForm,
  AuthorizationForDirectDeposit,
  DCRHandbook,
  FWFSEmployeeHandbook,
  MealPeriodInformationAndWaiver,
  OnDutyMealPeriodAgreement,
  ArbitrationAgreement
} from "Docs/Forms";

import { Pdf } from "Docs/Components";
import BeneficiaryForm from "Docs/Forms/BeneficiaryForm";
import I9Rehire from "Docs/Forms/I9-rehire";
import GeneralConsentAndReleaseDoc from "Docs/Forms/GeneralConsentAndReleaseDoc";
import { docStatusConstants } from "_constants";
import { toast } from "react-toastify";

class Docs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mobile: {
        screen: "sidebar",
        enabled: false,
        closeConfirmationModal: false,
      },
      alertPop: false,
      alertPopMessage: "",
      adminMode: false,
      lastPage: "merchandise-order-form",
      rehire: this.props.rehire || false,
      giss: false,
    };

    this.getActiveForm = this.getActiveForm.bind(this);
    this.setScreen = this.setScreen.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.closeConfirmation = this.closeConfirmation.bind(this);
    this.toggleCloseConfirmation = this.toggleCloseConfirmation.bind(this);
    this.toggleErrorPopup = this.toggleErrorPopup.bind(this);
    this.resizeTimeOut = null;
    this.handleResize();
  }
  
/**
 * 
 * @param doc 
 * @returns component
 */
  getPdf(doc,filteredDocs) {
    let sampleUid = this.props?.user?.profile ? this.props.user.profile.data.hash : "";
    let params = {
      id: doc.id,
      uid: sampleUid, // the user who logged in
      signature: this.props.chat.settings.signature || "",
    };
    // setting up url for pdf
    let url = new URL(`${config.apiUrl}/docs/pdf/${doc.slug}`);
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key]),
    );

    const lastIndex = filteredDocs.length - 1;
    const isLastPage = filteredDocs[lastIndex]?.slug === doc?.slug;

    const isGiss =
      this.props.user?.profile?.data?.user_type_id === 11 ||
      this.props.user?.profile?.data?.user_type_id === 12;

    return (
      <Pdf
        // className="other-pdf"
        url={url.href}
        lastPage={isLastPage}
        rehire={this.props.rehire}
        giss={isGiss}
      />
    );
  }

  togglePdf(flag) {
    this.setState({ pdf_view: !flag });
  }

  getActiveForm() {
    let { params } = this.props.match;
    let { doc } = params;

    //if user is not admin and doc status is approved then show only pdf
    const isNotAdmin = this.props.user?.profile?.data?.is_admin === 0;

    let filteredDocs;

    const isGiss =
      this.props.user?.profile?.data?.user_type_id === 11 ||
      this.props.user?.profile?.data?.user_type_id === 12;

    if (this.props.rehire) {
      filteredDocs = isGiss
        ? this.props?.docs?.userDocs.filter(
            (item) => item.category_slug === "giss-rehire-documents",
          )
        : this.props?.docs?.userDocs.filter(
            (doc) => doc?.category_slug === "rehire-documents",
          );
    } else {
      filteredDocs = isGiss
        ? this.props?.docs?.userDocs.filter(
            (item) => item.category_slug === "giss-onboarding-documents",
          )
        : this.props?.docs?.userDocs.filter(
            (doc) => doc?.category_slug === "onboarding-documents",
          );
    }
    const selectedDoc = filteredDocs?.find((item) => item.slug === doc);

    if (selectedDoc?.status >= 3 && isNotAdmin) {
      return this.getPdf(selectedDoc,filteredDocs);
    }

    if (! selectedDoc) {
      return <Redirect to={{ pathname: `/dashboard` }} />;
    }

    if (selectedDoc.version != selectedDoc.docs_type.latest_version) {
      if (selectedDoc.status == docStatusConstants.STARTED_FILLING) {
        toast.error("This form is outdated. Please contact the support team to receive the latest forms.");
      }
      return this.getPdf(selectedDoc,filteredDocs);
    }

    switch (doc) {
      case "employee-information-page":
        return (
          <CandidateInfoPage
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "employee-information-page"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "general-consent-and-release":
        return (
          <GeneralConsentAndReleaseDoc
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "general-consent-and-release"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
            doc={selectedDoc}
            docs={this.props.docs}
          />
        );
      case "beneficiary-form":
        return (
          <BeneficiaryForm
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "beneficiary-form"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
            doc={selectedDoc}
            docs={this.props.docs}
          />
        );
      case "employee-agreement":
        return (
          <EmployeeAgreement
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "employee-agreement"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "giss-employee-agreement":
        return (
          <EmployeeAgreement_GISS
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "employee-agreement"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "mpn":
        return (
          <MPN
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "mpn"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "meal-period-information-and-waiver":
        return (
            <MealPeriodInformationAndWaiver
              adminMode={this.state.adminMode}
              lastPage={this.state.lastPage === "meal-period-information-and-waiver"}
              rehire={this.state.rehire}
              setScreen={this.setScreen}
              mobileMode={this.state.mobile.enabled}
              toggleCloseConfirmation={this.toggleCloseConfirmation}
              toggleErrorPopup={this.toggleErrorPopup}
            />
          );
      case "arbitration-agreement":
        return (
            <ArbitrationAgreement
              adminMode={this.state.adminMode}
              lastPage={this.state.lastPage === "arbitration-agreement"}
              rehire={this.state.rehire}
              setScreen={this.setScreen}
              mobileMode={this.state.mobile.enabled}
              toggleCloseConfirmation={this.toggleCloseConfirmation}
              toggleErrorPopup={this.toggleErrorPopup}
            />
          );
      case "on-duty-meal-period-agreement":
        return (
          <OnDutyMealPeriodAgreement
            adminMode={this.state.adminMode}
            lastPage={this.state.lastPage === "on-duty-meal-period-agreement"}
            rehire={this.state.rehire}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "dcr-employee-handbook-acknowledge-of-receipt":
        return (
          <DCRHandbook
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={
              this.state.lastPage ===
              "dcr-employee-handbook-acknowledge-of-receipt"
            }
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "fwfs-employee-handbook-acknowledgement-of-receipt":
        return (
          <FWFSEmployeeHandbook
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={
              this.state.lastPage ===
              "fwfs-employee-handbook-acknowledgement-of-receipt"
            }
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "wage-rate-agreement":
        return (
          <WageRateAgreement
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "wage-rate-agreement"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "giss-wage-rate-agreement":
        return (
          <WageRateAgreement_GISS
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "wage-rate-agreement"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "paycheck-distribution-form":
        return (
          <PaycheckDistribution
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "paycheck-distribution-form"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "covered-veteran":
        return (
          <CoveredVeteran
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "covered-veteran"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "disability-status":
        return (
          <DisabilityStatus
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "disability-status"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "8850":
        return (
          <F8850
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "8850"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "disclosure-statement-work-agreement":
        return (
          <DisclosureStatement
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={
              this.state.lastPage === "disclosure-statement-work-agreement"
            }
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "rights-of-workers":
        return (
          <RightOfWorkers
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "rights-of-workers"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "job-description":
        return (
          <JobDescription
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "job-description"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "pay-dates":
        return (
          <PayDates
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "pay-dates"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "i-9":
        return (
          <I9
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "i-9"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "w-4":
        return (
          <W4
            adminMode={this.state.adminMode}
            lastPage={this.state.lastPage === "w-4"}
            rehire={this.state.rehire}
            giss={this.state.giss}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "merchandise-order-form":
        return (
          <MerchandiseOrder
            lastPage={this.state.lastPage === "merchandise-order-form"}
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "employee-re-hire-form":
        return (
          <EmployeeRehireForm
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "employee-re-hire-form"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      case "i-9-rehire":
        return (
          <I9Rehire
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={this.state.lastPage === "i-9-rehire"}
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
            doc={selectedDoc}
            docs={this.props.docs}
          />
        );
      case "authorization-for-direct-deposit":
        return (
          <AuthorizationForDirectDeposit
            adminMode={this.state.adminMode}
            rehire={this.state.rehire}
            giss={this.state.giss}
            lastPage={
              this.state.lastPage === "authorization-for-direct-deposit"
            }
            setScreen={this.setScreen}
            mobileMode={this.state.mobile.enabled}
            toggleCloseConfirmation={this.toggleCloseConfirmation}
            toggleErrorPopup={this.toggleErrorPopup}
          />
        );
      default:
        return <Redirect to={{ pathname: `/dashboard` }} />;
    }
  }

  toggleErrorPopup(errorMsg) {
    this.setState({
      alertPop: true,
      alertPopMessage: errorMsg,
    });
  }

  setScreen(screen) {
    this.setState({ mobile: { ...this.state.mobile, screen } });
  }

  handleResize() {
    clearTimeout(this.resizeTimeOut);
    this.resizeTimeOut = setTimeout(() => {
      if (isMobileOnly && window.screen.width < 768) {
        this.setState({ mobile: { ...this.state.mobile, enabled: true } });
      } else {
        this.setState({ mobile: { ...this.state.mobile, enabled: false } });
      }
    }, 100);
  }

  closeConfirmation(goBack) {
    if (goBack) {
      this.setState({
        mobile: {
          ...this.state.mobile,
          screen: "sidebar",
          closeConfirmationModal: false,
        },
      });
    } else {
      this.setState({
        mobile: { ...this.state.mobile, closeConfirmationModal: false },
      });
    }
  }

  toggleCloseConfirmation(flag) {
    this.setState({
      mobile: { ...this.state.mobile, closeConfirmationModal: flag },
    });
  }

  componentWillUnmount() {
    document.body.classList.remove("document-active");
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate() {
    let { mobile } = this.state;
    if (mobile.enabled && mobile.screen === "document") {
      document.body.classList.add("document-active");
    } else {
      document.body.classList.remove("document-active");
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.dispatch(docsActions.getFormOptions());
    this.props.dispatch(userActions.getAll());
    this.props.dispatch(docsActions.getDocs()).then((res) => {
      res.success && this.setState({ loading: false });
      this.props.match &&
        this.props.match.params.doc &&
        this.setState({ mobile: { ...this.state.mobile, screen: "document" } });
    });

    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillReceiveProps(nextProps) {
    let category_id,
      user = null,
      slug = "merchandise-order-form";

    nextProps.user &&
      nextProps.user.items &&
      nextProps.user.items.data &&
      (user = nextProps.user.items.data);

    user && (user.user_type_id === 11 || user.user_type_id === 12)
      ? nextProps.rehire
        ? (category_id = 5)
        : (category_id = 4)
      : nextProps.rehire
        ? (category_id = 2)
        : (category_id = 1);

    nextProps.docs &&
      nextProps.docs.userDocs.forEach((doc) => {
        doc.docs_categories_id == category_id && (slug = doc.slug);
      });

    user &&
      nextProps.docs.userDocs &&
      this.setState({
        lastPage: slug,
        rehire: nextProps.rehire,
        user,
        giss: user.user_type_id === 11 || user.user_type_id === 12,
      });
  }

  render() {
    let user;
    this.props.user &&
      this.props.user.items &&
      this.props.user.items.data &&
      (user = this.props.user.items.data);

    let { loading, mobile, alertPop, alertPopMessage } = this.state;

    return (
      <Fragment>
        {this.state.mobile.screen === "sidebar" || mobile.enabled == false ? (
          <div className="breadcrumbs">
            <span
              onClick={() => {
                this.props.history.push("/");
              }}
              className="green"
            >
              Dashboard
            </span>{" "}
            {this.props.rehire
              ? "/ Rehire Documents"
              : "/ Onboarding Documents"}
          </div>
        ) : (
          ""
        )}

        <section
          className={`user-docs user-onboarding ${mobile.enabled && "mobile-mode"}`}
        >
          {alertPop && (
            <AlertPopup
              head="Error"
              message={alertPopMessage}
              close={() => {
                this.setState({ alertPop: false });
              }}
            />
          )}
          {!this.props.rehire && !isMobileOnly && (
            <h1 className="page_title">Onboarding Documents</h1>
          )}
          {this.props.rehire && !isMobileOnly && (
            <h1 className="page_title">Rehire Documents</h1>
          )}

          <div className="onboarding-docs">
            {!loading && (
              <section className="d-flex w-100">
                {mobile.enabled ? (
                  /* Depreciated  */
                  // <NoMobileSupport feature='docs' />

                  // Mobile View
                  <div className="doc_info">
                    {/* SIDEBAR */}
                    {this.state.mobile.screen === "sidebar" &&
                      !this.state.loading && (
                        <SidebarUserMobile
                          rehire={this.state.rehire}
                          giss={this.state.giss}
                          setScreen={this.setScreen}
                        />
                      )}

                    {/* DOCUMENT */}
                    {!this.state.loading &&
                      this.state.mobile.screen === "document" &&
                      this.getActiveForm()}
                  </div>
                ) : (
                  <div className="doc_info">
                    {!this.state.loading && (
                      <SidebarUser
                        rehire={this.state.rehire}
                        giss={this.state.giss}
                      />
                    )}
                    {!this.state.loading && user && this.getActiveForm()}
                  </div>
                )}
              </section>
            )}
          </div>
        </section>
        {/* Modals */}
        <ReactModal
          isOpen={this.state.mobile.closeConfirmationModal}
          onClose={this.closeConfirmation}
          overlayClassName="RemoveApplntPopup"
          contentLabel="Confirmation"
          className="custom-modal"
        >
          <div className="modal-head">
            <h4 className="modal-title">Are you sure you want to leave?</h4>
            <figure>
              <img
                alt="close"
                className="closeModal"
                onClick={this.closeConfirmation}
                src={`${config.assetUrl}icons/close.svg`}
              />
            </figure>
          </div>
          <div className="modal-body">
            <div className="content">
              <p> If you leave before saving, your changes will be lost.</p>
            </div>
            <div className="buttons">
              <button
                className="button grey"
                onClick={() => this.closeConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="button"
                onClick={() => this.closeConfirmation(true)}
              >
                Yes, leave without saving
              </button>
            </div>
          </div>
        </ReactModal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { chat, docs, user } = state;
  return { chat, docs, user };
}

let connectedDocs = connect(mapStateToProps)(Docs);
export { connectedDocs as Docs };
