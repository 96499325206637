import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import footerLogo from "../assets/images/firestorm.png";
import { userActions } from "../_actions";
import { AlertPopup } from "../_components/AlertPopup";
import { alertActions } from "../_actions/alert.actions";
import queryString from "query-string";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "password",
      email: "",
      password: "",
      confirm_password: "",
      submitted: false,
      passwordError: {
        type: "",
        message: "",
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    //reset login status
    this.props.dispatch(userActions.logout());
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      passwordError: {
        message: "",
        type: "",
      },
    });
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });

    this.props.dispatch(alertActions.clear());

    let queryParams = queryString.parse(this.props.location.search);

    if (
      queryParams.type &&
      queryParams.type == "applicants" &&
      this.state.submitted
    ) {
      window.location = "/job-application";
    } else if (this.state.submitted) {
      this.props.history.push("/login");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.alert && Object.keys(nextProps.alert).length) {
      if (nextProps.alert.type !== "alert-success") {
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: nextProps.alert.message,
          alertFunction: "",
          submitted: false,
        });
      } else {
        this.setState({
          alertPop: true,
          alertHead: "Success",
          alertMsg: nextProps.alert.message,
          alertFunction: "",
        });
      }
    }
  }

  togglePassword() {
    this.setState({
      type: this.state.type === "password" ? "text" : "password",
    });
  }

  validatePassword() {
    let flag = true;
    let message = "";
    let type = "";
    if (this.state.password && this.state.password.length < 8) {
      flag = false;
      type = 1;
      message = "Password length must be at least 8 characters";
    } else if (
      String(this.state.password) !== String(this.state.confirm_password)
    ) {
      flag = false;
      type = 2;
      message = "Password and Confirm Password does not match";
    }
    if (!flag) {
      this.setState({
        passwordError: {
          message,
          type,
        },
      });
    } else {
      this.setState({
        passwordError: {
          message: "",
          type: "",
        },
      });
    }
    return flag;
  }

  handleSubmit(e) {
    e.preventDefault();

    let validate = this.validatePassword();
    if (!validate) {
      return;
    }

    this.setState({ submitted: true });
    const { email, password, confirm_password } = this.state;
    const { dispatch } = this.props;
    let data = new FormData();
    data.append("email", email);
    data.append("password", password);
    data.append("password_confirmation", confirm_password);
    data.append("token", this.props.match.params.token);
    let queryParams = queryString.parse(this.props.location.search);
    let type = queryParams.type || "users";
    data.append("type", type);
    if (email && password && confirm_password) {
      dispatch(userActions.newPassword(data));
    }
  }

  render() {
    const { loggingIn } = this.props;
    const { email, password, confirm_password, submitted, passwordError } =
      this.state;
    return (
      <div className="reset_pwd_outer">
        <div className="login_header">
          <Link to={"/login"}>
            <img src={footerLogo} alt="Firestorm" />
          </Link>
          <p>Employee Management System</p>
        </div>
        <div className="loginForm reset_pwd">
          <form name="form" onSubmit={this.handleSubmit}>
            <div
              className={
                "form-group" + (submitted && !email ? " has-error" : "")
              }
            >
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control"
                name="email"
                value={email}
                onChange={this.handleChange}
              />
              {submitted && !email && (
                <div className="help-block">Email address is required</div>
              )}
              <label htmlFor="email">Password</label>
              <div className="inline-login-txt-wrapper">
                <input
                  type={this.state.type}
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                />

                <span
                  className="toggle-icon-password"
                  onClick={(event) => {
                    this.togglePassword(event);
                  }}
                  aria-hidden={
                    this.state.type === "password" ? "true" : "false"
                  }
                >
                  <img src={require("../assets/images/eye.png").default} />
                  <img src={require("../assets/images/eye-hide.png").default} />
                </span>
              </div>
              {submitted && !password && (
                <div className="help-block">Password is required</div>
              )}
              {submitted && password && passwordError.type === 1 && (
                <div className="help-block">{passwordError.message}</div>
              )}
              <label htmlFor="email">Confirm password</label>
              <input
                type={this.state.type}
                className="form-control"
                name="confirm_password"
                value={confirm_password}
                onChange={this.handleChange}
              />
              {submitted && !confirm_password && (
                <div className="help-block">Confirm password is required</div>
              )}
              {submitted && confirm_password && passwordError.type === 2 && (
                <div className="help-block">{passwordError.message}</div>
              )}
            </div>
            <hr />
            <div className="form-group btnGroup">
              <button className="btn btn-primary">Submit</button>
              {loggingIn && (
                <img
                  alt="loader"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                />
              )}
            </div>
          </form>
        </div>
        {this.state.alertPop && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            viewOpen="true"
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return {
    loggingIn,
    alert,
  };
}

const connectedLoginPage = connect(mapStateToProps)(ResetPassword);
export { connectedLoginPage as ResetPassword };
