import React from "react";
import { connect } from "react-redux";
import "../assets/styles/inventory.css";
import { inventoryActions, permissionActions } from "../_actions";
import { config } from "../_helpers";
import { AlertPopup } from "../_components/AlertPopup";
import NumberFormat from "react-number-format";
import moment from "moment";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { formatDate } from "_helpers/helpers";

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const unDefined = "undefined";

class InventoryView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      certificationList: [],
      mainHtml: "",
      type_list: [],
      equip_type: "",
      subPackages: [],
      type: "",
      name: "",
      cost: "",
      license_no: "",
      mspa_date: "",
      condition: "",
      make_model: "",
      notes: "",
      pic: "",
      subtype: "",
      imgPreview: "",
      isOpen: false,
      equipment: {},
      crop: { x: 20, y: 10, width: 150, height: 150 },
      pixelCrop: {
        x: "",
        y: "",
        width: "",
        height: "",
      },
      alertPop: false,
      prev_file_list: [],
      photoIndex: 0,
      permList: [],
      state_of_licensure: "",
      equipment_owner: "",
      insurance: "",
      job_code: "",
      class_code: "",
      arb_program: "",
    };
    this.goBack = this.goBack.bind(this);
    this.onImageError = this.onImageError.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(inventoryActions.getListEquipments());
    this.props.dispatch(inventoryActions.equipmentDetails(this.props.id));
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altInvImg);
  }

  goBack() {
    this.props.goBack();
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }
  sliderImageChange(index) {
    this.setState({ photoIndex: index });
  }

  componentWillReceiveProps(nextProps) {
    const { equipments, equipList } = nextProps.inventory;
    const { permission } = nextProps;
    
    if (equipList?.data) {
      const resource = equipList.data.resource ? equipList.data.resource.resource_name : "";
      const driver = equipList.data.user ? equipList.data.user.first_name + " " + equipList.data.user.last_name : "";
      this.setState({
        type: equipList.data.type,
        is_archived: equipList.data.is_archived,
        type_id: equipList.data.type_id.toString(),
        name: equipList.data.unique_id,
        condition: equipList.data.current_condition,
        subtype: equipList.data.subpackage_id,
        cost: equipList.data.cost,
        license_no: equipList.data.serial_vin_number,
        notes: equipList.data.note,
        imgPreview: config.s3BucketUrl + equipList.data.pic,
        mspa_date: equipList.data.mspa_exp_date,
        make_model: equipList.data.vehicle_model_or_make,
        equip_id: equipList.data.id,
        year: equipList.data.year,
        resource: resource,
        driver: driver,
        equipment: equipList.data,
        prev_file_list: equipList.data.equipment_files,
        invPic: equipList.data.pic,
        state_of_licensure: equipList.data.state_of_licensure,
        equipment_owner: equipList.data.equipment_owner,
        insurance: equipList.data.insurance,
        job_code: equipList.data.job_code,
        class_code: equipList.data.class_code,
        arb_program: equipList.data.arb_program,
      });
 
      if (equipList.data.pic !== null) {
        this.setState({ imgPreview: config.s3BucketUrl + equipList.data.pic });
      } else {
        this.setState({ imgPreview: config.apiUrl + "/" + config.altInvImg });
      }
    }

    if (equipments) {
      this.setState({
        type_list: equipments.EquipmentType,
        subPackages: equipments.SubPackages,
        divisionList: equipments.Division,
        conditionList: equipments.CurrentCondition,
        certificationList: equipments.EngineCertification,
      });
    }

    if (permission.permissions) {
      const permList = permission.permissions.data;
      this.setState({ permList });
    }
  }

  render() {
    const condition = this.state.conditionList?.find((item) => item.id == this.state.condition)?.value ?? "";
    const subCateg = this.state.subPackages?.find((item) => item.id === this.state.equipment?.subpackage_id)?.value ?? "";
    const division_value = this.state.divisionList?.find((item) => item.id === this.state.equipment?.division_id)?.value ?? "";
    const certiType = this.state.certificationList?.find((item) => item.id === this.state.equipment?.engine_certification)?.value ?? "";

    return (
      <div className="equipment_container">
        <div className="page_title float">
          <h1>Inventory Details</h1>
          {this.state.permList &&
            this.state.permList.includes("Add/Edit/Archive Equipments") &&
            this.state.is_archived === 0 && (
              <div className="btn_list">
                <div
                  className="button add_equip"
                  onClick={() => {
                    this.props.editInventory(
                      this.state.equip_id,
                      this.props.id,
                      "view",
                    );
                  }}
                >
                  Edit Equipment
                </div>
              </div>
            )}
        </div>
        <div className="inner_head"></div>
        <div className="inner_container">
          <div className="inventory_view">
            <div className="inv_field">
              {this.state.prev_file_list.length > 0 && (
                <div
                  className="image-box"
                  style={{
                    backgroundImage:
                      "url(" +
                      config.s3BucketUrl +
                      this.state.prev_file_list[this.state.photoIndex]
                        .file_hash +
                      ")",
                  }}
                  onClick={() => {
                    this.setState({ isOpen: true });
                  }}
                ></div>
              )}

              {/* <img src={this.state.prev_file_list.length>0&&config.s3BucketUrl+this.state.prev_file_list[this.state.photoIndex].file_hash} onError={this.onImageError.bind(this)}/> */}
            </div>
            <div className="inv_field img_list">
              <div className="inventory-select-slider">
                <Slider
                  {...{
                    infinite: true,
                    speed: 500,
                    prevArrow: <Arrow />,
                    nextArrow: <Arrow />,
                    slidesToShow: this.state.prev_file_list.length > 3 ? 3 : this.state.prev_file_list.length,
                    slidesToScroll: 1,
                  }}
                >
                  {this.state.prev_file_list &&
                    this.state.prev_file_list.length > 0 &&
                    this.state.prev_file_list.map((item, index) => {
                      return (
                        <div className="inv-slick-item-box" key={item.file_hash}>
                          <div
                            style={{
                              backgroundImage:
                                "url(" +
                                config.s3BucketUrl +
                                item.file_hash +
                                ")",
                              border:
                                this.state.photoIndex === index
                                  ? "3px solid #c9c288"
                                  : "",
                            }}
                            className="item-bg"
                            onClick={() => {
                              this.sliderImageChange(index);
                            }}
                          >
                            <img
                              alt="mail"
                              src={
                                require("../assets/images/placeholder.png")
                                  .default
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </div>
            <div className="inv_field">
              <label>Category</label>
              <p className="field">{this.state.type}</p>
            </div>
            {subCateg && (
              <div className="inv_field">
                <label>Sub-category</label>
                <p className="field">{subCateg}</p>
              </div>
            )}
            {this.state.type_id === "18" &&
              this.state.type_list.length != 0 && (
                <p>
                  {
                    this.state.type_list.find(
                      (item) => item.id == this.state.type_id,
                    ).type_desc
                  }
                </p>
              )}
            <div className="inv_field">
              <label>Division</label>
              <p className="field">{division_value ? division_value : "-"}</p>
            </div>
            <div className="inv_field">
              <label>Unique ID</label>
              <p className="field">{this.state.name ? this.state.name : "-"}</p>
            </div>
            <div className="inv_field">
              <label>Resource</label>
              <p className="field">
                {this.state.resource ? this.state.resource : "-"}
              </p>
            </div>
            <div className="inv_field">
              <label>Driver / Employee</label>
              <p className="field">
                {this.state.driver ? this.state.driver : "-"}
              </p>
            </div>
            <div className="inv_field">
              <label>Description</label>
              <p className="field">
                {this.state.equipment && this.state.equipment.description
                  ? this.state.equipment.description
                  : "-"}
              </p>
            </div>
            <div className="inv_field">
              <label>Year</label>
              <p className="field">{this.state.year ? this.state.year : "-"}</p>
            </div>
            <div className="inv_field">
              <label>Make / Model</label>
              <p className="field">
                {this.state.make_model ? this.state.make_model : "-"}
              </p>
            </div>
            <div className="inv_field">
              <label>Serial Number / VIN</label>
              <p className="field">
                {this.state.equipment && this.state.equipment.serial_vin_number
                  ? this.state.equipment.serial_vin_number
                  : "-"}
              </p>
            </div>
            {(this.state.type_id === "1" ||
              this.state.type_id === "5" ||
              this.state.type_id === "6" ||
              this.state.type_id === "7" ||
              this.state.type_id === "8" ||
              this.state.type_id === "9" ||
              this.state.type_id === "10") && (
              <div className="vehicle_fields">
                <div className="inv_field">
                  <label>License Number</label>
                  <p className="field">
                    {this.state.equipment.vehicle_license &&
                    this.state.equipment.vehicle_license !== unDefined
                      ? this.state.equipment.vehicle_license
                      : "-"}
                  </p>
                </div>
                <div className="inv_field">
                  <label>GVW</label>
                  <p className="field">
                    {this.state.equipment.GVW ? this.state.equipment.GVW : "-"}
                  </p>
                </div>
                <div className="inv_field">
                  <label>Engine Family</label>
                  <p className="field">
                    {this.state.equipment.engine_family
                      ? this.state.equipment.engine_family
                      : "-"}
                  </p>
                </div>
                <div className="inv_field">
                  <label>Engine Model</label>
                  <p className="field">
                    {this.state.equipment.engine_model
                      ? this.state.equipment.engine_model
                      : "-"}
                  </p>
                </div>
                <div className="inv_field">
                  <label>Engine Serial</label>
                  <p className="field">
                    {this.state.equipment.engine_serial
                      ? this.state.equipment.engine_serial
                      : "-"}
                  </p>
                </div>
                <div className="inv_field">
                  <label>Engine Description</label>
                  <p className="field">
                    {this.state.equipment.engine_description
                      ? this.state.equipment.engine_description
                      : "-"}
                  </p>
                </div>
                <div className="inv_field">
                  <label>Engine Certification</label>
                  <p className="field">
                    {this.state.equipment.engine_certification
                      ? certiType
                      : "-"}
                  </p>
                </div>
                <div className="inv_field">
                  <label>ARB</label>
                  <p className="field">
                    {this.state.equipment.ARB ? this.state.equipment.ARB : "-"}
                  </p>
                </div>
                <div className="inv_field">
                  <label>Non-Op</label>
                  <p className="field">
                    {this.state.equipment.non_op
                      ? this.state.equipment.non_op
                      : "-"}
                  </p>
                </div>
                <div className="inv_field">
                  <label>MSPA Exp. Date</label>
                  <p className="field">
                    {this.state.equipment.mspa_exp_date
                      ? formatDate(this.state.equipment.mspa_exp_date)
                      : "-"}
                  </p>
                </div>
              </div>
            )}
            <div className="inv_field">
              <label>Current Condition</label>
              <p className="field">{condition !== "" ? condition : "-"}</p>
            </div>
            <div className="inv_field">
              <label>Year</label>
              <p className="field">
                {this.state.equipment.year ? this.state.equipment.year : "-"}
              </p>
            </div>
            <div className="inv_field">
              <label>Purchase Value</label>
              {this.state.equipment && this.state.equipment.cost ? (
                <NumberFormat
                  value={this.state.equipment.cost}
                  displayType={"text"}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              ) : (
                <p className="field">-</p>
              )}
            </div>
            <div className="inv_field">
              <label>State of Licensure</label>
              <p className="field">
                {this.state?.equipment?.state_of_licensure || "-"}
              </p>
            </div>
            <div className="inv_field">
              <label>Owner of Equipment</label>
              <p className="field">
                {this.state?.equipment?.equipment_owner || "-"}
              </p>
            </div>
            <div className="inv_field">
              <label>Insurance</label>
              <p className="field">{this.state?.equipment?.insurance || "-"}</p>
            </div>
            <div className="inv_field">
              <label>Job Code</label>
              <p className="field">{this.state?.equipment?.job_code || "-"}</p>
            </div>
            <div className="inv_field">
              <label>Class Code</label>
              <p className="field">
                {this.state?.equipment?.class_code || "-"}
              </p>
            </div>
            <div className="inv_field">
              <label>ARB Program</label>
              <p className="field">
                {this.state?.equipment?.arb_program || "-"}
              </p>
            </div>
            <div className="inv_field">
              <label>Notes</label>
              <p className="field">
                {this.state.equipment.note ? this.state.note : "-"}
              </p>
            </div>
            {this.state.permList &&
              this.state.permList.includes("Inventory Sold Info") && (
                <React.Fragment>
                  <div className="inv_field">
                    <label>Sold Date</label>
                    <p className="field">
                      {this.state.equipment && this.state.equipment.sold_date
                        ? formatDate(this.state.equipment.sold_date)
                        : "-"}
                    </p>
                  </div>
                  <div className="inv_field">
                    <label>Sold To</label>
                    <p className="field">
                      {this.state.equipment && this.state.equipment.sold_to
                        ? this.state.equipment.sold_to
                        : "-"}
                    </p>
                  </div>
                  <div className="inv_field">
                    <label>Sold Value</label>
                    <p className="field">
                      {this.state.equipment &&
                      this.state.equipment.sold_value ? (
                        <NumberFormat
                          value={this.state.equipment.sold_value}
                          displayType={"text"}
                          fixedDecimalScale={true}
                          decimalScale={2}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      ) : (
                        "-"
                      )}
                    </p>
                  </div>
                </React.Fragment>
              )}
            <React.Fragment>
              <div className="inv_field">
                <label>Mileage/Hobbs Meter</label>
                <p>{this.state?.equipment?.mileage || "-"}</p>
              </div>
              <div className="inv_field">
                <label>Mileage/Hobbs Date</label>
                <p>
                  {moment(this.state?.equipment?.mileage_date).isValid()
                    ? formatDate(this.state?.equipment?.mileage_date)
                    : "-"}
                </p>
              </div>
            </React.Fragment>
          </div>
        </div>
        {this.state.alertPop && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            viewOpen="true"
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
        {this.state.isOpen && this.state.prev_file_list.length > 0 && (
          <div className="gallery-pop-wrapper">
            <Lightbox
              mainSrc={
                config.s3BucketUrl +
                this.state.prev_file_list[this.state.photoIndex]
                  .file_original_hash
              }
              imageLoadErrorMessage={<span>Loading...</span>}
              nextSrc={
                config.s3BucketUrl +
                this.state.prev_file_list[
                  (this.state.photoIndex + 1) % this.state.prev_file_list.length
                ].file_original_hash
              }
              prevSrc={
                config.s3BucketUrl +
                this.state.prev_file_list[
                  (this.state.photoIndex +
                    this.state.prev_file_list.length -
                    1) %
                    this.state.prev_file_list.length
                ].file_original_hash
              }
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex +
                      this.state.prev_file_list.length -
                      1) %
                    this.state.prev_file_list.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex + 1) %
                    this.state.prev_file_list.length,
                })
              }
            />
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { permission, inventory } = state;
  return {
    permission,
    inventory,
  };
}
const connectedHomePage = connect(mapStateToProps)(InventoryView);
export { connectedHomePage as InventoryView };
