import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { manifestActions } from "../_actions";
import "../assets/styles/resource.css";
import { authHeader, config } from "../_helpers";
import { AlertPopup } from "../_components/AlertPopup";
import { withRouter } from "react-router-dom";

class CreateManifest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resource_id:
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : null,
      manifest_type_list: [],
      manifestError: "",
      manifest_type: "",
      contract_type: "",
      contract_numbers: [],
      outerVehicle: null,
    };
    this.closeAlert = this.closeAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.yourCrew = this.yourCrew.bind(this);
    this.goBack = this.goBack.bind(this);
    this.getContracts = this.getContracts.bind(this);
    this.generateContracts = this.generateContracts.bind(this);
  }

  goBack() {
    window.location.reload();
  }

  componentDidMount() {
    this.props.dispatch(manifestActions.manifestType());
    let data = new FormData();
    data.append("resource_id", this.state.resource_id);
    this.props.dispatch(manifestActions.getVehicles(data));
    this.componentWillReceiveProps(this.props);
    this.getContracts();
    this.setState({
      contract_type: this.props.contract_number_id,
    });
  }

  componentWillReceiveProps(nextProps) {
    const vehicles = nextProps.props.vehicles;
    const vehicleList = vehicles.vehicleList;
    var vehiclesOption = [];

    if (vehicleList) {
      this.setState({ outerVehicle: vehicleList });
      if (
        !vehicleList.data ||
        (vehicleList.data && vehicleList.data.length == 0)
      ) {
        vehiclesOption.push(
          <div className="applicant_item" key="noVehicles">
            <p>No Vehicles added!</p>
          </div>,
        );
      }
      this.setState({ vehiclesOption });
    }

    if (vehicles?.vehicleList) {
      this.setState({ vehicleList: vehicles.vehicleList });
    }

    if (nextProps.type) {
      this.setState({ manifest_type: nextProps.type }, function () {});
    }

    var manifest_type_list = [];
    if (nextProps.manifest.manifest_type) {
      nextProps.manifest.manifest_type.map((item, index) => {
        manifest_type_list.push(
          <option key={index} value={item.id}>
            {item.value}
          </option>,
        );
        return manifest_type_list;
      });
      this.setState({ manifest_type_list });
    }
  }

  getContracts() {
    // Get all docs (multiple docs) by admin
    // appending queryParams with URL
    let url = new URL(`${config.apiUrl}/api/getlist/contractnumbers`);
    // Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    fetch(url, requestOptions).then(
      (response) => {
        response.json().then((res) => {
          this.setState({ contract_numbers: res });
        });
      },
      (err) => console.log(err),
    );
  }

  generateContracts(type) {
    let list = this.state.contract_numbers.filter(
      (item) => item.base_manifests_id == type,
    );
    let contracts = [];
    list.map((item, index) => {
      contracts.push(
        <option key={index} value={item.id}>
          {item.value}
        </option>,
      );
    });
    return contracts;
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  handleChange(event, type) {
    if (event.target.value !== "" && type == 1) {
      this.setState({
        [event.target.name]: event.target.value,
      });
      event.target.setAttribute("errors", "");
      sessionStorage.setItem("MID", event.target.value);
    }

    if (event.target.value !== "" && type == 2) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
    if (event.target.name === "contract_type") {
      let locations = this.state.contract_numbers.filter(
        (contract) => Number(contract.id) === Number(event.target.value),
      );
      let dispatch_location = locations ? locations[0].dispatch_location : "";
      this.setState({
        dispatch_location,
      });
    }
  }

  validate(e) {
    let isError = false;
    if (this.state.manifest_type === "") {
      isError = true;
      this.setState({
        manifestError: "error",
      });
    }

    return isError;
  }
  yourCrew(event) {
    event.preventDefault();
    if (this.state.manifest_type && this.state.manifest_type != 5) {
      if (
        this.state.outerVehicle &&
        Array.isArray(this.state.outerVehicle.data) &&
        this.state.outerVehicle.data.length > 0
      ) {
        if (
          this.state.vehicleList &&
          this.state.vehicleList.data.length === 0
        ) {
          this.setState({
            alertPop: true,
            alertHead: "Error",
            alertMsg: "Assign drivers to vehicle for creating manifest",
            alertFunction: "",
          });
          return;
        }
      } else {
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: "Assign vehicles to this resource for creating manifest",
          alertFunction: "",
        });
        return;
      }
    }

    const err = this.validate();
    if (!err) {
      this.props.yourCrew(
        this.state.manifest_type,
        this.state.contract_type,
        this.state.dispatch_location,
      );
    }
  }

  render() {
    // const { resource } = this.props;
    // const form = this.state.form;
    return (
      <div className="create_manifest_container">
        <div className="breadcrumbs">
          <Link to={"/resources"} className="green">
            Resources
          </Link>{" "}
          / <span onClick={this.goBack.bind(this)}>Details</span> / Create
          Manifest
        </div>
        <div className="page_title float">
          <h1>Create Manifest</h1>
        </div>
        <div className="manifest_type">
          <div className="inner_head" />
          <div className="content_div inner_container ">
            {/*<div className="goback" onClick={this.goBack.bind(this)}><p>Back</p></div>*/}
            <div className="manifest_type_form">
              <label>Choose Manifest Type</label>
              <select
                name="manifest_type"
                value={this.state.manifest_type}
                className="manifest_type_list"
                errors={this.state.manifestError}
                onChange={(e) => this.handleChange(e, 1)}
              >
                <option value="">-Select-</option>
                {this.state.manifest_type_list}
              </select>

              {(this.state.manifest_type == 1 ||
                this.state.manifest_type == 3 ||
                this.state.manifest_type == 7 ||
                this.state.manifest_type == 8) && (
                <div>
                  {this.state.manifest_type == 7 ? (
                    <label>Choose Contract Number</label>
                  ) : (
                    <label>Choose Contract Type</label>
                  )}
                  <select
                    name="contract_type"
                    value={this.state.contract_type}
                    className="manifest_type_list"
                    onChange={(e) => this.handleChange(e, 2)}
                  >
                    <option value="">-Select-</option>
                    {this.generateContracts(this.state.manifest_type)}
                  </select>
                </div>
              )}

              <div className="btn_list">
                <button
                  className="button grey"
                  onClick={this.goBack.bind(this)}
                >
                  Cancel
                </button>
                <button className="button" onClick={this.yourCrew.bind(this)}>
                  Next
                </button>
              </div>

              {this.state.alertPop === true && (
                <AlertPopup
                  head={this.state.alertHead}
                  message={this.state.alertMsg}
                  alertFunction={this.state.alertFunction}
                  close={this.closeAlert.bind(this)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { manifest } = state;
  return {
    manifest,
  };
}

const connectedManifest = withRouter(connect(mapStateToProps)(CreateManifest));
export { connectedManifest as CreateManifest };
