/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import { connect } from "react-redux";
import { docsActions } from "_actions/docs.actions";
import { chatActions } from "_actions/chat.actions";
import { Filter } from "./Components";
import { List } from "./Components";

class DocsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        doc_types: "",
        status: "",
        from: "",
        to: "",
        user: "",
        sort: "updated_at",
        order: "desc",
      },
      outerTab: 1,
      innerTab: "list",
      adminMode: true,
      uid: null,
      signature: null,
      onboarding_docs: [],
      other_docs: [],
      rehire_docs: [],
    };
    // binding
    this.getDocs = this.getDocs.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.tabChangeOuter = this.tabChangeOuter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.tabChangeInner = this.tabChangeInner.bind(this);
  }

  // Debounce a function , call after a delay
  debounceTimer = null;
  debounce = (fn, args, delay) => {
    const context = this;
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => fn.call(context, args), delay);
  };

  // filter keys coming from Filter Component
  handleFilter(keysObject) {
    // setting filter based on value from filter component and existing values stored in state.filter
    let sortBy = {
      sort: this?.state?.filter?.sort,
      order: this?.state?.filter?.order,
    };

    let filter =
      Object.keys(keysObject).length === 0
        ? { doc_category: this.state.outerTab }
        : {
            ...this.state.filter,
            ...keysObject,
            doc_category: this.state.outerTab,
          };

    filter = { ...filter, ...sortBy };

    this.setState({ filter, loading: true }, () => {
      this.props
        .dispatch(docsActions.getAllDocs(this.state.filter))
        .then((res) => {
          if (res.success) {
            switch (this.state.outerTab) {
              case 1:
                this.setState({ loading: false, onboarding_docs: res.data });
                break;
              case 2:
                this.setState({ loading: false, rehire_docs: res.data });
                break;
              case 3:
                this.setState({ loading: false, other_docs: res.data });
                break;
              default:
                this.setState({ loading: false, onboarding_docs: res.data });
            }
            // this.state.outerTab === 1 ? this.setState({ loading: false, onboarding_docs: res.data }) : this.setState({ loading: false, onboarding_docs: res.data })
          }
        });
    });
  }

  clearFilter() {
    this.setState(
      { filter: { doc_category: this.state.outerTab }, loading: true },
      () => {
        this.props
          .dispatch(docsActions.getAllDocs(this.state.filter))
          .then((res) => {
            if (res.success) {
              this.state.outerTab === 1
                ? this.setState({ loading: false, onboarding_docs: res.data })
                : this.setState({ loading: false, other_docs: res.data });
            }
          });
      },
    );
  }

  tabChangeInner(tab) {
    tab !== this.state.innerTab && this.setState({ innerTab: tab });
  }

  tabChangeOuter(tab) {
    this.setState({ outerTab: tab }, () =>
      this.handleFilter(this.state.filter),
    );
  }
  // sort keys from list component
  handleSort(key, sortOrder, doc_category) {
    this.setState(
      {
        filter: { ...this.state.filter, ...sortOrder, doc_category },
        loading: true,
      },
      () => {
        this.props
          .dispatch(docsActions.getAllDocs(this.state.filter))
          .then((res) => {
            if (res.success) {
              this.setState({ loading: false, [key]: res.data });
            }
          });
      },
    );
  }

  getDocs(key, params = {}) {
    this.setState({ loading: true });
    this.props
      .dispatch(docsActions.getAllDocs({ ...params, ...this.state.filter }))
      .then((res) => {
        if (res.success) {
          this.setState({ loading: false, [key]: res.data });
        }
      });
  }

  componentDidMount() {
    this.props.dispatch(chatActions.getConversationSettings()).then((res) => {
      this.setState({ signature: this.props.chat.settings.signature });
    });
    if (this.props.location.search) {
      let query = new URLSearchParams(this.props.location.search);
      let filter = {};
      query.forEach((value, key) => {
        filter[key] = value;
      });
      this.setState(
        {
          filter: {
            ...this.state.filter,
            ...filter,
          },
        },
        () => {
          this.getDocs("onboarding_docs", filter);
        },
      );
    } else {
      this.getDocs("rehire_docs", { doc_category: 2 });
      this.getDocs("other_docs", { doc_category: 3 });
      this.getDocs("onboarding_docs", { doc_category: 1 });
    }
  }

  componentWillReceiveProps(props) {
    props.user.profile && this.setState({ uid: props.user.profile.data.hash });
  }

  // RENDER
  render() {
    return (
      <section className="d-flex w-100">
        <div className="doc_info col-vert pos-rel">
          {/* Filter */}
          {(this.state.outerTab === 1 || this.state.innerTab === "list") && (
            <Filter
              classes="filter-hover"
              filter={this.state.filter}
              clearFilter={this.clearFilter}
              onChange={(args, delay = 1000) => {
                this.debounce(this.handleFilter, args, delay);
              }}
            />
          )}

          {/* List */}
          <List
            {...this.props}
            onChange={(key, sortOrder, doc_category) =>
              this.handleSort(key, sortOrder, doc_category)
            }
            onPageChange={(key, params) => this.getDocs(key, params)}
            onboarding_docs={this.state.onboarding_docs}
            other_docs={this.state.other_docs}
            rehire_docs={this.state.rehire_docs}
            tabChangeOuter={this.tabChangeOuter}
            tabChangeInner={this.tabChangeInner}
            adminMode={this.state.adminMode}
            uid={this.state.uid}
            signature={this.state.signature}
          />
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { user, docs, chat, permission } = state;
  return { user, docs, chat, permission };
}

let connectedDocs = connect(mapStateToProps)(DocsList);
export { connectedDocs as DocsList };
