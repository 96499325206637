import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CircularProgressbar from "react-circular-progressbar";
import { docsActions } from "../_actions";

class DocsStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      stats: [
        {
          name: "Pending Approval",
          pending: 0,
          per: 0,
          status: 2,
          submitted: 0,
        },
        {
          name: "Pending Submission",
          pending: 0,
          per: 0,
          status: 1,
          submitted: 0,
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    let { stats } = this.props.docs;

    if (prevProps.docs.stats != stats) {
      this.setState({
        stats,
      });
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.dispatch(docsActions.getDocsStats()).then((res) => {
      this.setState({ loading: false });
    });
  }

  render() {
    let { loading, stats } = this.state;

    return (
      <div className="employee_stats_outer">
        <div className="employee_stats inner_head">
          <h2>
            Onboarding Documents
            <span className="drag-icon-controller"></span>
            {loading && (
              <img
                alt="loader"
                className="login_loader"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
            )}
          </h2>
          <div
            className="toggle-controller"
            aria-owns="docs_status_contents"
            onClick={(event) => {
              this.props.toggle(event, this.props.widgetid);
            }}
          ></div>
        </div>
        <div
          className="employee_stats_details docs_stats"
          aria-expanded={this.props.visibility == 1 ? "true" : "false"}
          id="docs_status_contents"
        >
          {stats.length ? (
            stats.map((stat, index) => {
              return (
                <div className="docs_stats_progress" key={index}>
                  <h3>{stat.name}</h3>
                  <div className="stats_progress_div">
                    <div className="progress_outer">
                      <CircularProgressbar
                        percentage={stat.per}
                        textForPercentage={(percentage) => `${percentage}%`}
                        strokeWidth="9"
                      />
                    </div>
                    <div className="progress-text">
                      <p className="title">
                        <a>{stat.pending} Pending</a>
                      </p>
                      <p className="text">
                        <a>{stat.submitted} Submitted</a>
                      </p>
                      <span className="view_employee_list_button">
                        <Link to={`/docs?status=${stat.status}`}>
                          View Pending Documents
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className="emp_progress"></div>
                </div>
              );
            })
          ) : (
            <div> No Documents Found </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { docs } = state;
  return {
    docs,
  };
}

const connectedDocsStats = connect(mapStateToProps)(DocsStats);
export { connectedDocsStats as DocsStats };

// export default EmployeeStats;
