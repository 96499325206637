import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import "../assets/styles/createProfile.css";
import { InformationForm } from "./InformationForm";
import { ProfilePic } from "./ProfilePic";
import { resourceService } from "_services";
import { toast } from "react-toastify";

class GISSRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainHtml: "",
      step: "information",
      loader: true,
    };
    this.choosePosition = this.choosePosition.bind(this);
    this.toResourceType = this.toResourceType.bind(this);
    this.information = this.information.bind(this);
    this.ProfilePic = this.ProfilePic.bind(this);
    this.toInformation = this.toInformation.bind(this);
  }

  // Step 1 - Resource Type
  toResourceType() {
    this.setState({ step: "ResourceType" }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }
  // Step 2 - Information
  information(type, position) {
    this.setState(
      { step: "information", type: type, position: position },
      function () {
        this.componentWillReceiveProps(this.props);
      },
    );
  }
  // Step 2 - Information
  toInformation() {
    this.setState({ step: "information" }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }
  // Step 3 - the was removed
  choosePosition(type) {
    this.setState({ step: "Position", type: type }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  // Step 3 - Profile picture
  ProfilePic(user) {
    this.setState({ step: "ProfilePic", user: user }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  componentDidMount() {
    this.componentWillReceiveProps(this.props);
    // Validate Token For GISS
    this.validateToken();
  }

  validateToken = async () => {
    try {
      let data = new FormData();
      const query = this.props?.location?.search;
      const sp = new URLSearchParams(query);
      data.append("email", sp.get("email"));
      data.append("token", sp.get("token"));
      const response = await resourceService?.validateResourceRegLink(data);
      if (response?.success) {
        this.setState({ loader: false });
      } else {
        toast.error(response?.message);
        this.props.history.push("/login");
      }
    } catch (error) {
      toast.error(error?.message);
      this.props.history.push("/login");
    }
  };

  componentWillReceiveProps(nextProps) {
    var options;
    if (this.state.step === "information") {
      options = (
        <div>
          <InformationForm
            ProfilePic={this.ProfilePic}
            toResourceType={this.toResourceType}
            choosePosition={this.choosePosition}
            type={this.state.type}
            position={this.state.position}
            prevData={this.state.user}
          />
        </div>
      );
    }
    if (this.state.step === "ProfilePic") {
      options = (
        <div>
          <ProfilePic
            toInformation={this.toInformation}
            type={this.state.type}
            position={this.state.position}
            information={this.information}
            user={this.state.user}
          />
        </div>
      );
    }
    this.setState({ mainHtml: options });
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <div className="giss__loader">
            <i class="fa fa-spinner fa-spin spin-big"></i>
          </div>
        ) : (
          <div>{this.state.mainHtml}</div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { applicants } = state;
  return {
    applicants,
  };
}

export default connect(mapStateToProps)(GISSRegister);
