import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {
  getCurrentUserInfo,
  selectFireHistoryReportLoading,
  selectQualifications,
} from "_helpers/reduxSelector";
import Modal from "_components/utils/Modal/Modal";
import { createProfileActions, dashboardActions } from "_actions";
import moment from "moment";
import { toast } from "react-toastify";
import { formatDate, handleErrorResponse } from "_helpers/helpers";
import ReactModal from "react-modal";

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";
const momentInstance = moment();
const API_req_date_format = "YYYY-MM-DD";
const datePickerPopperModifiers = [
  {
    name: "offset",
    options: {
      offset: [5, 10],
    },
  },
  {
    name: "customStyle",
    enabled: true,
    fn: (data) => {
      data.styles.width = "300px";
      return data;
    },
  },
];

const initialStyleState = {
  assignmentTypeClass: ASC_CLASS,
  incidentNameClass: ASC_CLASS,
  locationClass: ASC_CLASS,
  fireSizeClass: ASC_CLASS,
  complexityClass: ASC_CLASS,
  fuelTypeClass: ASC_CLASS,
  qualClass: ASC_CLASS,
  shiftsClass: ASC_CLASS,
  hotlineClass: ASC_CLASS,
  dateOnClass: ASC_CLASS,
  dateOffClass: ASC_CLASS,
};

const FireHistoryReportList = ({
  getFireHistoryByHash,
  fireHistoryReport,
  selectedResource,
  listComplexity,
  listFireSize,
  listFuelType,
  listAssignmentStatusTypesOptions,
  legacy,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectFireHistoryReportLoading);
  const userInfo = useSelector(getCurrentUserInfo);
  const isUserAdmin = [1, 2].includes(userInfo?.is_admin); //1: admin 2: super admin

  const qualifications = useSelector(selectQualifications)?.map((qual) => ({
    ...qual,
    label: qual?.key,
    value: qual.id,
  }));

  const data = fireHistoryReport?.data ?? [];
  const [order, setOrder] = useState(false); /** true === asc **/
  const [sortItem, setSortItem] = useState("type");
  const [page, setPage] = useState(0);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedFireHistory, setSelectedFireHistory] = useState("");
  const [selectedHistoryItem, setSelectedHistoryItem] = useState({
    id: "",
    fuelType: null,
    fireSize: null,
    complexity: null,
    assignmentType: null,
    qual: null,
    shifts: "",
    incident: "",
    location: "",
    hotline: "",
    startDate: momentInstance,
    endDate: momentInstance,
  });

  const [sortArrowStyle, setStyleState] = useState(initialStyleState);
  const {
    assignmentTypeClass,
    incidentNameClass,
    locationClass,
    fireSizeClass,
    fuelTypeClass,
    dateOffClass,
    dateOnClass,
    complexityClass,
    qualClass,
    shiftsClass,
    hotlineClass,
  } = sortArrowStyle;

  const handlePageChange = (newPage) => {
    const sortOrder = order ? "asc" : "desc";
    getFireHistoryByHash(newPage?.selected + 1, sortItem || "type", sortOrder);
    setPage(newPage?.selected + 1);
  };

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  const onClickSelectHistoryItem = (selected) => {
    if (!isUserAdmin) return;
    setSelectedHistoryItem({
      id: selected.assignment_history_user_id,
      assignmentType: selected.assignment_type
        ? listAssignmentStatusTypesOptions.find(
            (status) => status.label === selected.assignment_type,
          )
        : null,
      qual: selected.qualification
        ? qualifications.find((qual) => qual.label === selected.qualification)
        : null,
      fireSize: selected.fire_size
        ? listFireSize.find((fireSize) => fireSize.value === selected.fire_size)
        : null,
      fuelType: selected.fuel_type
        ? listFuelType.find((fuelType) => fuelType.value === selected.fuel_type)
        : null,
      complexity: selected.complexity
        ? listComplexity.find(
            (complexity) => complexity.label === selected.complexity,
          )
        : null,
      shifts: selected.shifts,
      hotline: selected.hotline_shifts,
      incident: selected.incident_name || null,
      location: selected.location || null,
      startDate: moment(selected.date_on).isValid()
        ? moment(selected.date_on)
        : momentInstance,
      endDate: moment(selected.date_off).isValid()
        ? moment(selected.date_off)
        : momentInstance,
    });
    setEditModalOpen(true);
  };

  const handleDelete = (fid) => {
    setDeleteModalOpen(true);
    setSelectedFireHistory(fid);
  };

  const handleCancel = () => {
    setDeleteModalOpen(false);
    setSelectedFireHistory("");
  };

  const verifyItem = async (id) => {
    const res = await dispatch(dashboardActions.verifyFireHistory(id));
    if (res?.success) {
      toast.success(
        "The fire history has been verified and moved to fire history.",
      );
      fetchFireHistory();
    } else {
      toast.error("Failed to verify fire history.");
    }
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedHistoryItem({
      id: "",
      fuelType: null,
      fireSize: null,
      complexity: null,
      qual: null,
      shifts: "",
      hotline: "",
      startDate: momentInstance,
      endDate: momentInstance,
      incident: "",
      location: "",
    });
  };

  const deleteIndividualFireHistory = async (id) => {
    const reqData = new FormData();
    reqData.append("id", id);
    const res = await dispatch(
      dashboardActions.deleteIndividualFireHistory(reqData),
    );
    if (res?.success) {
      toast.success(res?.message);
      fetchFireHistory();
      setDeleteModalOpen(false);
      setSelectedFireHistory("");
    } else {
      handleErrorResponse(res);
      setDeleteModalOpen(false);
      setSelectedFireHistory("");
    }
  };

  const updateHistoryItem = async (e) => {
    e.preventDefault();
    const reqData = new FormData();
    reqData.append("id", selectedHistoryItem?.id || "");
    reqData.append(
      "assignment_type",
      selectedHistoryItem?.assignmentType?.value?.value ||
        selectedHistoryItem?.assignmentType?.value ||
        "",
    );
    reqData.append("incident", selectedHistoryItem?.incident || "");
    reqData.append("location", selectedHistoryItem?.location || "");
    reqData.append(
      "complexity",
      selectedHistoryItem?.complexity?.value?.value ||
        selectedHistoryItem?.complexity?.value ||
        "",
    );
    reqData.append(
      "fuel_type",
      selectedHistoryItem?.fuelType?.value?.value ||
        selectedHistoryItem?.fuelType?.value ||
        "",
    );
    reqData.append(
      "fire_size",
      selectedHistoryItem?.fireSize?.value?.value ||
        selectedHistoryItem?.fireSize?.value ||
        "",
    );
    reqData.append(
      "qualification",
      selectedHistoryItem?.qual?.value?.value ||
        selectedHistoryItem?.qual?.value ||
        "",
    );
    reqData.append("shifts", selectedHistoryItem?.shifts || "");
    reqData.append("hotline_shifts", selectedHistoryItem?.hotline || "");
    reqData.append(
      "date_on",
      moment(selectedHistoryItem.startDate).format(API_req_date_format) || "",
    );
    reqData.append(
      "date_off",
      moment(selectedHistoryItem.endDate).format(API_req_date_format) || "",
    );

    const res = await dispatch(
      dashboardActions.updateIndividualFireHistoryEntry(reqData),
    );
    if (res.success) {
      toast.success("Fire history updated successfully");
      setEditModalOpen(false);
      fetchFireHistory();
    } else {
      handleErrorResponse(res);
    }
  };

  const onChangeEditHistoryItem = (name, value) => {
    setSelectedHistoryItem((prevState) => {
      if (name === "qualSelect") {
        return { ...prevState, qual: { value } };
      } else if (name === "complexity") {
        return { ...prevState, complexity: { value } };
      } else if (name === "assignmentType") {
        return { ...prevState, assignmentType: { value } };
      } else if (name === "fireSize") {
        return { ...prevState, fireSize: { value } };
      } else if (name === "fuelType") {
        return { ...prevState, fuelType: { value } };
      } else if (name === "startDate" || name === "endDate") {
        const newStartDate = name === "startDate" ? value : prevState.startDate;
        const newEndDate = name === "endDate" ? value : prevState.endDate;

        const diffInDays = newEndDate.diff(newStartDate, "days") + 1;

        return {
          ...prevState,
          startDate: newStartDate,
          endDate: newEndDate,
          shifts: diffInDays, // or any other key you want to set for the difference
        };
      } else {
        return { ...prevState, [name]: value };
      }
    });
  };

  const fetchFireHistory = () => {
    const sortOrder = order ? "asc" : "desc";
    getFireHistoryByHash(page, sortItem, sortOrder);
  };

  useEffect(() => {
    if (sortItem) {
      fetchFireHistory();
    }
    dispatch(createProfileActions.getAll());
  }, [sortItem, order]);

  return (
    <div className="history_lists outer_list" style={{ overflow: "auto" }}>
      <Modal
        isOpen={editModalOpen}
        className="update-applicant-status-modal incident-center"
      >
        <div className="modal-head">
          <h4>EDIT FIRE HISTORY</h4>
        </div>
        <form onSubmit={updateHistoryItem}>
          <div className="modal-body applicant-status-modal-body scroll-incident">
            <div className="modify-item">
              <p>Assignment Type</p>
              <Select
                options={listAssignmentStatusTypesOptions}
                name="assignmentType"
                defaultValue={selectedHistoryItem.assignmentType}
                onChange={(option) =>
                  onChangeEditHistoryItem("assignmentType", option)
                }
              />
            </div>
            <div className="modify-item">
              <p>Incident Name</p>
              <input
                name="incident"
                value={selectedHistoryItem.incident}
                onChange={(e) =>
                  onChangeEditHistoryItem(e.target.name, e.target.value)
                }
                s
              />
            </div>
            <div className="modify-item">
              <p>Location</p>
              <input
                name="location"
                value={selectedHistoryItem.location}
                onChange={(e) =>
                  onChangeEditHistoryItem(e.target.name, e.target.value)
                }
              />
            </div>
            <div className="fh-flex-container">
              <div className="modify-item">
                <p>Complexity</p>
                <Select
                  options={listComplexity}
                  name="complexity"
                  defaultValue={selectedHistoryItem.complexity}
                  onChange={(option) =>
                    onChangeEditHistoryItem("complexity", option)
                  }
                />
              </div>
              <div className="modify-item">
                <p>Fuel Type</p>
                <Select
                  options={listFuelType}
                  name="fuelType"
                  defaultValue={selectedHistoryItem.fuelType}
                  onChange={(option) =>
                    onChangeEditHistoryItem("fuelType", option)
                  }
                />
              </div>

              <div className="modify-item">
                <p>Fire Size</p>
                <Select
                  options={listFireSize}
                  name="fireSize"
                  defaultValue={selectedHistoryItem.fireSize}
                  onChange={(option) =>
                    onChangeEditHistoryItem("fireSize", option)
                  }
                />
              </div>
            </div>
            {/* <div className="modify-item">
              <p>Qualification</p>
              <Select
                options={qualifications}
                onChange={(option) =>
                  onChangeEditHistoryItem("qualSelect", option)
                }
                name="qual"
                defaultValue={selectedHistoryItem.qual}
                isSearchable={false}
              />
            </div> */}
            <div className="fh-flex-container">
              <div className="modify-item">
                <p>Shifts</p>
                <input
                  name="shifts"
                  value={selectedHistoryItem.shifts}
                  type="number"
                  disabled
                  required
                />
              </div>

              <div className="modify-item">
                <p>Hotline</p>
                <input
                  name="hotline"
                  value={selectedHistoryItem.hotline}
                  onChange={(e) =>
                    onChangeEditHistoryItem(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
            <div className="fh-flex-container">
              <div className="modify-item">
                <p>Start Date</p>
                <DatePicker
                  name="startDate"
                  selected={selectedHistoryItem.startDate}
                  onChange={(date) =>
                    onChangeEditHistoryItem("startDate", date)
                  }
                  onKeyDown={(e) => e.preventDefault()}
                  popperClassName="some-custom-class"
                  popperModifiers={datePickerPopperModifiers}
                  showMonthDropdown
                  showYearDropdown
                  required
                />
              </div>
              <div className="modify-item">
                <p>End Date</p>
                <DatePicker
                  name="endDate"
                  selected={selectedHistoryItem.endDate}
                  onChange={(date) => onChangeEditHistoryItem("endDate", date)}
                  onKeyDown={(e) => e.preventDefault()}
                  popperModifiers={datePickerPopperModifiers}
                  showMonthDropdown
                  showYearDropdown
                  required
                  minDate={selectedHistoryItem.startDate}
                />
              </div>
            </div>

            <>
              <div className="button-holder button-holder--right">
                <button
                  disabled={false}
                  className="button grey"
                  onClick={handleCloseEditModal}
                >
                  Cancel
                </button>
                <button className="button" type="submit" disabled={isLoading}>
                  Update
                </button>
              </div>
            </>
          </div>
        </form>
      </Modal>

      <ReactModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        overlayClassName="alertPopup"
        style={customStylesDeleteModal}
      >
        <div id="statusPopup" className="popupModal">
          <div className="modal-head">
            <h4 className="modal-title-qual">
              Are you sure you want to delete the entry?
            </h4>
          </div>
          <div className="modal-body-qual">
            <p>
              This action will permanently delete the data and it cannot be
              undone.
            </p>
            <div className="updateQual_btn_list">
              <button
                // disabled={isSubmitting}
                className="button grey mr-1"
                onClick={handleCancel}
                type="button"
              >
                Cancel
              </button>
              <button
                // disabled={isSubmitting}
                className="button"
                onClick={() => deleteIndividualFireHistory(selectedFireHistory)}
                type="submit"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <table
        className="mobile_hide report_list_width_full long__report tab_hide expiry_report"
        style={{ minWidth: "1200px" }}
      >
        <tr className="report_list_table_row">
          <th
            onClick={() =>
              sortList(
                "assignmentTypeClass",
                assignmentTypeClass,
                "assignment_type",
              )
            }
          >
            <div className="report_table_header">
              <span>Type</span>
              <span className={assignmentTypeClass} />
            </div>
          </th>
          <th
            onClick={() =>
              sortList("incidentNameClass", incidentNameClass, "incident_name")
            }
          >
            <div className="report_table_header">
              <span>Event Name</span>
              <span className={incidentNameClass} />
            </div>
          </th>
          <th
            onClick={() => sortList("locationClass", locationClass, "location")}
          >
            <div className="report_table_header">
              <span>Location</span>
              <span className={locationClass} />
            </div>
          </th>
          <th
            onClick={() =>
              sortList("complexityClass", complexityClass, "complexity")
            }
          >
            <div className="report_table_header">
              <span>Complexity (1,2,3,4,5)</span>
              <span className={complexityClass} />
            </div>
          </th>
          <th
            onClick={() =>
              sortList("fuelTypeClass", fuelTypeClass, "fuel_type")
            }
          >
            <div className="report_table_header">
              <span>Fuel Type</span>
              <span className={fuelTypeClass} />
            </div>
          </th>
          <th
            onClick={() =>
              sortList("fireSizeClass", fireSizeClass, "fire_size")
            }
          >
            <div className="report_table_header">
              <span>Fire Size</span>
              <span className={fireSizeClass} />
            </div>
          </th>

          <th onClick={() => sortList("qualClass", qualClass, "qualification")}>
            <div className="report_table_header">
              <span>Qualification</span>
              <span className={qualClass} />
            </div>
          </th>

          <th onClick={() => sortList("shiftsClass", shiftsClass, "shifts")}>
            <div className="report_table_header">
              <span>Shifts</span>
              <span className={shiftsClass} />
            </div>
          </th>

          <th onClick={() => sortList("hotlineClass", hotlineClass, "hotline")}>
            <div className="report_table_header">
              <span>Hotline</span>
              <span className={hotlineClass} />
            </div>
          </th>

          <th onClick={() => sortList("dateOnClass", dateOnClass, "date_on")}>
            <div className="report_table_header">
              <span>Start Date</span>
              <span className={dateOnClass} />
            </div>
          </th>
          <th
            onClick={() => sortList("dateOffClass", dateOffClass, "date_off")}
          >
            <div className="report_table_header">
              <span>End Date</span>
              <span className={dateOffClass} />
            </div>
          </th>
          {isUserAdmin && legacy && (
            <>
              <th colSpan={3}></th>
            </>
          )}
        </tr>
        <tbody className="report_list_table_tbody">
          {isLoading ? (
            <tr>
              <td colSpan={10}>
                <div className="dashboard_expiration_date_no_data">
                  Loading...
                </div>
              </td>
            </tr>
          ) : data?.length ? (
            data.map((item) => (
              <tr key={item?.assignment_history_user_id}>
                <td>{item?.assignment_type || "-"}</td>
                <td>{item?.incident_name || "-"}</td>
                <td>{item?.location || "-"}</td>
                <td>{item?.complexity || "-"}</td>
                <td>{item?.fuel_type || "-"}</td>
                <td>{item?.fire_size || "-"}</td>
                <td>{item?.qualification || "-"}</td>
                <td>{item?.shifts || "-"}</td>
                <td>{item?.hotline_shifts || "-"}</td>
                <td>{formatDate(item?.date_on) || "-"}</td>
                <td>{formatDate(item?.date_off) || "-"}</td>
                {isUserAdmin && legacy && (
                  <>
                    <td
                      onClick={() => onClickSelectHistoryItem(item)}
                      className="edit-button-fire-history"
                    >
                      Edit
                    </td>
                    <td
                      onClick={() =>
                        handleDelete(item?.assignment_history_user_id)
                      }
                      className="delete-button-fire-history"
                    >
                      Delete
                    </td>
                    <td
                      onClick={() =>
                        verifyItem(item?.assignment_history_user_id)
                      }
                      className="edit-button-fire-history"
                    >
                      Verify
                    </td>
                  </>
                )}
              </tr>
            ))
          ) : (
            !isLoading && (
              <tr>
                <td colSpan={10}>
                  <div className="dashboard_expiration_date_no_data">
                    No Data Found
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>

      {data?.length ? (
        <>
          <RefresherReportMobileView
            data={data}
            sortArrowStyle={sortArrowStyle}
            sortList={sortList}
            onClickSelectHistoryItem={onClickSelectHistoryItem}
            isUserAdmin={isUserAdmin}
            handleDelete={handleDelete}
            verifyItem={verifyItem}
          />
          {data?.length && !isLoading ? (
            <div className="fs-pagination-wrapper-outer">
              <div className="customised-pagination right-align">
                <ReactPaginate
                  previousLabel={""}
                  nextLabel={""}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={fireHistoryReport?.last_page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(data) => handlePageChange(data)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          ) : null}
        </>
      ) : (
        !isLoading && (
          <div className="dashboard_expiration_date_no_data for_mobile_history_list">
            No Data Found
          </div>
        )
      )}
    </div>
  );
};

export default FireHistoryReportList;

const RefresherReportMobileView = ({
  data,
  sortList,
  sortArrowStyle,
  onClickSelectHistoryItem,
  isUserAdmin,
  handleDelete,
  verifyItem,
}) => {
  const {
    assignmentTypeClass,
    incidentName,
    locationClass,
    fireSizeClass,
    fuelTypeClass,
    qualClass,
    shiftsClass,
    hotlineClass,
    dateOffClass,
    dateOnClass,
    complexityClass,
  } = sortArrowStyle;
  const legacy = window.location.pathname.includes("legacy");
  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list fs_custom_list tab_show">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList(
                  "assignmentTypeClass",
                  assignmentTypeClass,
                  "assignment_type",
                )
              }
            >
              <strong>Type</strong>
              <span className={assignmentTypeClass} />
            </div>
            <div className="list_item">{item?.assignment_type ?? "-"}</div>
          </div>
          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("incidentName", incidentName, "incident_name")
              }
            >
              <strong>Event Name</strong>
              <span className={incidentName} />
            </div>
            <div className="list_item">{item?.incident_name ?? "-"}</div>
          </div>
          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("locationClass", locationClass, "location")
              }
            >
              <strong>Location</strong>
              <span className={locationClass} />
            </div>
            <div className="list_item">{item?.location ?? "-"}</div>
          </div>
          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("complexityClass", complexityClass, "complexity")
              }
            >
              <strong>Complexity (1,2,3,4,5)</strong>
              <span className={complexityClass} />
            </div>
            <div className="list_item">{item?.complexity ?? "-"}</div>
          </div>
          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("fuelTypeClass", fuelTypeClass, "fuel_type")
              }
            >
              <strong>Fuel Type</strong>
              <span className={fuelTypeClass} />
            </div>
            <div className="list_item">{item?.fuel_type ?? "-"}</div>
          </div>
          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("fireSizeClass", fireSizeClass, "fire_size")
              }
            >
              <strong>Fire Size</strong>
              <span className={fireSizeClass} />
            </div>
            <div className="list_item">{item?.fire_size ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("qualClass", qualClass, "qual")}
            >
              <strong>Qualification</strong>
              <span className={qualClass} />
            </div>
            <div className="list_item">{item?.qualification ?? "-"}</div>
          </div>
          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("shiftsClass", shiftsClass, "shifts")}
            >
              <strong>Shifts</strong>
              <span className={shiftsClass} />
            </div>
            <div className="list_item">{item?.shifts ?? "-"}</div>
          </div>
          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("hotlineClass", hotlineClass, "hotline")}
            >
              <strong>Hotline</strong>
              <span className={hotlineClass} />
            </div>
            <div className="list_item">{item?.hotline_shifts ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("dateOnClass", dateOnClass, "date_on")}
            >
              <strong>Start Date</strong>
              <span className={dateOnClass} />
            </div>
            <div className="list_item">{formatDate(item?.date_on) || "-"}</div>
          </div>
          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("dateOffClass", dateOffClass, "date_off")}
            >
              <strong>End Date</strong>
              <span className={dateOffClass} />
            </div>
            <div className="list_item">{formatDate(item?.date_off) || "-"}</div>
          </div>
          <div className="status_item">
            {isUserAdmin && legacy && (
              <>
                <div className="list_item"></div>
                <div
                  className="list_item edit-button-fire-history"
                  onClick={() => onClickSelectHistoryItem(item)}
                >
                  Edit
                </div>
                <div
                  onClick={() => handleDelete(item?.assignment_history_user_id)}
                  className="list_item delete-button-fire-history"
                >
                  Delete
                </div>
                <div
                  onClick={() => verifyItem(item?.assignment_history_user_id)}
                  className="list_item delete-button-fire-history"
                >
                  Verify
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};

const customStylesDeleteModal = {
  content: {
    width: "auto",
    height: "auto",
  },
};
