import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { dashboardActions } from "_actions";
import { dashboardService } from "../_services/dashboard.service";
import "../assets/styles/report.css";
import FireHistoryReportList from "./FireHistoryReportWithEmployees";
import {
  selectEmployeesFireHistoryReport,
  selectEmployeesFireHistoryReportAdditionalData,
} from "_helpers/reduxSelector";

const FireHistoryReportWithEmployeesMain = (props) => {
  const dispatch = useDispatch();

  const [sortAndOrder, setSortAndOrder] = React.useState({
    order: "asc",
    sortBy: "id",
  });

  const fireHistoryReport = useSelector(selectEmployeesFireHistoryReport);
  const fireHistoryReportAdditionalData = useSelector(
    selectEmployeesFireHistoryReportAdditionalData,
  );

  const historyId = props?.match?.params?.historyId;
  const historyState = props?.history?.location?.state;

  const getFireHistoryByHash = (newPage, sort, order) => {
    handleSortAndOrderForPrint(sort, order);
    let data = new FormData();
    data.append("page", newPage || 0);
    data.append("assignment_history_id", historyId);
    data.append("sort", sort || "id");
    data.append("order", order || "asc");
    dispatch(dashboardActions.getEmployeesFireHistory(data));
  };

  const handleDeleteItem = async (itemToDelete) => {
    try {
      if (itemToDelete) {
        let data = new FormData();
        data.append("assignment_history_id", historyId);
        data.append("user_id", itemToDelete?.userid);
        const response = await dashboardService.deleteFireHistory(data);
        if (response?.success) {
          getFireHistoryByHash();
        } else throw response;
      }
    } catch (error) {
      toast?.error(error?.message ?? "Something went wrong");
    }
  };

  const handleSortAndOrderForPrint = (sortBy, order) => {
    let _sortAndOrder = { ...sortAndOrder };
    _sortAndOrder.sortBy = sortBy || _sortAndOrder.sortBy;
    _sortAndOrder.order = order || _sortAndOrder.order;
    setSortAndOrder(_sortAndOrder);
  };

  return (
    <div>
      <div className={"dashboard_container"}>
        <div className="white">
          <div className="back_to_board dashboard_expiration_date_nav">
            <div className="back_button">
              {/* <Link to={historyState?.nav === 'crew' ? '/crew' : (historyState?.nav === 'resource' && historyState?.resourceId ? `/resources/${historyState?.resourceId}` : '/resources')}> */}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // Prevent the default anchor behavior

                  // Determine the correct URL based on historyState
                  const navigateTo =
                    historyState?.nav === "crew"
                      ? "/crew"
                      : historyState?.nav === "resource" &&
                          historyState?.resourceId
                        ? `/resources/${historyState?.resourceId}`
                        : fireHistoryReportAdditionalData?.resource_id
                          ? `/resources/${fireHistoryReportAdditionalData?.resource_id}`
                          : "/resources";

                  // Navigate to the URL
                  window.location.href = navigateTo;
                }}
              >
                <img
                  src={require("../assets/images/back.svg").default}
                  alt="Back"
                />
                <span>Back</span>
              </a>

              {/* </Link> */}
            </div>
          </div>
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head sold_rpt_mbl_flx">
                <h5 className="graph_header">Employees List - Fire History</h5>
                <div
                  style={{ gap: `${props.isReportMenu ? "10px" : "0px"}` }}
                  className="report_head_btn_grp exp_multi_head_flx"
                ></div>
              </div>
            </div>
            <div>
              <FireHistoryReportList
                fireHistoryReport={fireHistoryReport}
                getFireHistoryByHash={getFireHistoryByHash}
                handleDeleteItem={handleDeleteItem}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FireHistoryReportWithEmployeesMain.defaultProps = {
  isReportMenu: true,
};

export default FireHistoryReportWithEmployeesMain;
