import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
// import { applicantsActions } from '../_actions';
import { resourceActions } from "../_actions";
import "../assets/styles/resource.css";

function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}

class ArchivedEmployees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: true,
      keyword: "",
      employeesOption: [],
      resource_list: [],
      statusOpen: false,
      crew_identifier_id: "",
      hash: "",
      archive_crew: "",
      current_page: "",
      last_page: "",
      index: "",
      aflag: false,
      archivedEmployeesHashIds: [],
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.keyUpFn = this.keyUpFn.bind(this);
    this.unarchive = this.unarchive.bind(this);
    this.setCrew = this.setCrew.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  statusOpenModal(hash, crew, index) {
    this.setState({
      statusOpen: true,
      hash: hash,
      archive_crew: crew,
      index: index,
      crew_identifier_id: crew,
      errorMessage: "",
    });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }

  componentDidMount() {
    let data = new FormData();
    // data.append('crew_identifier_id', 2)

    this.props.dispatch(resourceActions.getAll());
    this.props.dispatch(resourceActions.archivedEmployees(data));
  }

  componentWillReceiveProps(nextProps) {
    let archivedEmployeesHashIds = [...this?.state?.archivedEmployeesHashIds];
    window.addEventListener("scroll", this.handleScroll);

    let tmp = nextProps.employee.list;
    if (tmp && tmp.hasOwnProperty("data")) {
      this.setState({ current_page: tmp.data.current_page });
      this.setState({ last_page: tmp.data.last_page });
    }

    const employee = nextProps.employee;
    const resource = nextProps.resource;
    var employeesOption = [];
    var resource_list = [];

    if (resource.error) {
      let error = JSON.parse(resource.error);
      this.setState({ errorMessage: error.message });
      setTimeout(() => {
        this.setState({
          errorMessage: "",
        });
      }, 3000);
    }
    if (resource.items) {
      const { data } = resource.items;

      var resource_list = [];
      Object.keys(data).map((key) => {
        if (key && key != "" && Array.isArray(data[key])) {
          data[key].map((item, index) => {
            resource_list.push(
              <option key={index} className="item" value={item.id}>
                {item.resource_name}
              </option>,
            );
          });
        }
      });
      this.setState({ resource_list: resource_list });
    }
    if (employee?.list?.data?.data) {
      if (employee?.list?.data?.data?.length > 0) {
        employee.list.data.data.map((item, index) => {
          // if (!archivedEmployeesHashIds?.includes(item?.hash)){
          archivedEmployeesHashIds.push(item?.hash);
          employeesOption.push(
            <div className="applicant_item" key={index}>
              <div className="basic_info">
                <div className="appln_name list_item">
                  {item.first_name + " " + item.last_name}
                  <p>{item.user_type}</p>
                </div>
                <div className="list_item">{item.crew_name}</div>
                <div className="appln_phone list_item">
                  {item.phone ? (
                    <a
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      className="phone_email_link"
                      href={`tel:${item.phone}`}
                      target="_top"
                    >
                      {formatPhoneNumber(item.phone)}
                    </a>
                  ) : (
                    ""
                  )}
                  {/* {formatPhoneNumber(item.phone)} */}
                </div>
                <div className="list_item email">
                  {item.email ? (
                    <a
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      className="phone_email_link"
                      href={`mailto:${item.email}`}
                      target="_top"
                    >
                      {item.email}
                    </a>
                  ) : (
                    ""
                  )}
                  {/* {item.email} */}
                </div>
                <a
                  className="unarchive list_item"
                  href={`/archived/employee-profile/${item?.hash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View
                </a>
                <div
                  className="unarchive list_item"
                  onClick={this.statusOpenModal.bind(
                    this,
                    item.hash,
                    item.rid,
                    index,
                  )}
                >
                  Unarchive
                </div>
              </div>
            </div>,
          );
          // }
        });
      } else {
        if (!this?.state?.employeesOption?.length) {
          employeesOption.push(
            <div className="applicant_item" key="noEmployees">
              <p className="no_employee float">No Employees found</p>
            </div>,
          );
        }
      }

      if (this.state.aflag == false) {
        this.setState({
          employeesOption: employeesOption,
        });
      } else {
        this.setState({ employeesOption: employeesOption });
      }

      //var tmpemp = this.state.employeesOption;
      //this.setState({ employeesOption: tmpemp.concat(employeesOption) })
    }
    // debugger

    this.setState({ archivedEmployeesHashIds });
  }

  setCrew(event) {
    this.setState({ crew_identifier_id: event.target.value });
  }

  keyUpFn(event) {
    var value = event.target.value;
    clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(
      function () {
        this.handleSearch(value);
      }.bind(this),
      1000,
    );
  }

  handleSearch(text) {
    this.setState({ keyword: text, aflag: false });

    let data = new FormData();
    data.append("archived", "true");
    data.append("keyword", text);
    this.setState({ employeesOption: [] });
    this.props.dispatch(resourceActions.employeeList(data));
  }

  unarchive() {
    let archivedEmployeesHashIds = [...this?.state?.archivedEmployeesHashIds];
    if (!this.state.crew_identifier_id) {
      return;
    }
    let index = archivedEmployeesHashIds?.findIndex(
      (hashId) => hashId === this.state.hash,
    );
    if (index > -1) {
      archivedEmployeesHashIds?.splice(index, 1);
    }
    this.statusCloseModal();
    this.setState({ aflag: true });
    this.setState({ employeesOption: [], archivedEmployeesHashIds });
    debugger;
    let data = new FormData();
    data.append("crew_identifier_id", this.state.crew_identifier_id);
    data.append("hash", this.state.hash);
    let data2 = new FormData();
    data2.append("archived", "true");
    data2.append("keyword", this.state.keyword);
    this.props.dispatch(resourceActions.unarchiveEmployee(data, data2));
    this.setState({ crew_identifier_id: "", employeesOption: [] });
  }

  handleScroll(event) {
    this.setState({ aflag: false });
    var wrap = document.getElementById("right_sidebar"),
      contentHeight = wrap.offsetHeight,
      yOffset = window.pageYOffset,
      y = yOffset + window.innerHeight;

    if (y >= contentHeight) {
      if (
        this.state.current_page < this.state.last_page &&
        this.state.flag !== false
      ) {
        let data = new FormData();
        data.append("page", this.state.current_page + 1);
        data.append("archived", "true");
        data.append("keyword", this.state.keyword);
        this.props.dispatch(resourceActions.employeeList(data));
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <div className="resource_container">
        <div className="breadcrumbs">
          <Link to={"/resources"} className="green">
            Resources
          </Link>{" "}
          / Archived Employees
        </div>
        {this.state.errorMessage && (
          <div className="alert alert-danger" role="alert">
            {this.state.errorMessage}{" "}
          </div>
        )}
        <div className="page_title float">
          <h1>Archived Employees</h1>
          {/*<div className="btn_list">
                        <Link to={"/resources"} className="create_resource button">Back</Link>
                        
                    </div>  */}
        </div>

        <div className="resource_list">
          <div className="inner_head">
            <input
              type="text"
              className="resource_search"
              name="keyword"
              placeholder="Search Employees..."
              onKeyUp={this.keyUpFn.bind(this)}
              autoComplete="off"
            />
          </div>
          <div
            className="content_div archived_wrapper"
            onScroll={this.handleScroll}
          >
            {/*<Link to={"/resources"} className="goback">Back</Link>*/}
            <div className="appln_list_header archived_list">
              <div className="list_item_head">Employee</div>
              <div className="list_item_head">Resource</div>
              <div className="list_item_head phone">Phone #</div>
              <div className="list_item_head email">Email</div>
              <div className="list_item_head unarchive" />
              <div className="list_item_head unarchive" />
            </div>
            {this.state.employeesOption}
          </div>
        </div>
        <Modal
          style={"width: 550px"}
          id="status_popup"
          className="status_popup"
          open={this.state.statusOpen}
          onClose={this.statusCloseModal.bind(this)}
        >
          <div id="statusPopup" className="unarchivePopup">
            <div className="modal-head">
              <h4 className="modal-title">Unarchive Employee</h4>
            </div>

            <div className="modal-body">
              <div className="status_step step1">
                <label>Change Crew: </label>
                <br />
                <select
                  name="crew"
                  onChange={this.setCrew.bind(this)}
                  defaultValue={this.state.archive_crew}
                >
                  <option value="">Choose crew*</option>
                  {this.state.resource_list}
                </select>

                <span className="error-message">
                  {!this.state.crew_identifier_id && " Please select a crew"}
                </span>
                <label className="popup-text-label">
                  Are you sure you want to unarchive this employee ?
                </label>
                <br />
                <div className="btn_list">
                  <button
                    className="button grey"
                    onClick={this.statusCloseModal.bind(this)}
                  >
                    Cancel
                  </button>
                  <button
                    className="button"
                    onClick={this.unarchive.bind(this)}
                  >
                    Unarchive
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { employee, resource } = state;
  return {
    employee,
    resource,
  };
}

const connectedResourcePage = connect(mapStateToProps)(ArchivedEmployees);
export { connectedResourcePage as ArchivedEmployees };
