import React from "react";
import styles from "./submitButton.module.css";

const SubmitButton = ({ label, onClick, ...props }) => {
  return (
    <button
      className={styles.submitButton}
      onClick={() => (onClick ? onClick() : {})}
      {...props}
    >
      {label}
    </button>
  );
};

export default SubmitButton;
