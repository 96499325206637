import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";

class SidebarUser extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let documents = this.props.docs.userDocs;
    let docSlug = this.props.match.params.doc;

    let activeDoc = documents.find((doc) => doc.slug === docSlug);
    let docs_count = this.props.docs.userDocs.filter(
      (item) => item.docs_categories_id == 4 || item.docs_categories_id == 1,
    ).length;
    let rehire_docs_count = this.props.docs.userDocs.filter(
      (item) => item.docs_categories_id == 5 || item.docs_categories_id == 2,
    ).length;
    let user;
    this.props.user &&
      this.props.user.items &&
      this.props.user.items.data &&
      (user = this.props.user.items.data);

    const onboardingShouldLock = user?.docs_status === 3;
    const rehireShouldLock = user?.rehire_docs_status === 3;

    return (
      <div className="doc_info-lh">
        {activeDoc && (
          <Fragment>
            <div className="doc-title">
              <h3>
                Documents{" "}
                <span className="doc-count">
                  {this.props.rehire ? rehire_docs_count : docs_count}
                </span>
              </h3>
            </div>
            <div className="doc-statusbar">
              <ul className="doc-status-list">
                {documents.map((doc) => {
                  // if (this.props.giss) {
                  //   if (!this.props.rehire && doc.docs_categories_id == 4) {
                  //     return (
                  //       <Link to={`/dashboard/docs/${doc.slug}?rehire=false&docId=${doc.id}`} key={doc.id}>
                  //         <li
                  //           className={`${doc.slug == activeDoc.slug ? "doc-active " : ""} ${
                  //             doc.status >= 2 && !(doc.slug == activeDoc.slug)
                  //               ? "doc-complete "
                  //               : ""
                  //           }`}
                  //           key={doc.id}>
                  //           {doc.doc_type_name}
                  //         </li>
                  //       </Link>
                  //     );
                  //   } else if (this.props.rehire && doc.docs_categories_id == 5) {
                  //     return (
                  //       <Link to={`/dashboard/docs/${doc.slug}?rehire=true&docId=${doc.id}`} key={doc.id}>
                  //         <li
                  //           className={`${doc.slug == activeDoc.slug ? "doc-active " : ""} ${
                  //             doc.status >= 2 && !(doc.slug == activeDoc.slug)
                  //               ? "doc-complete "
                  //               : ""
                  //           }`}
                  //           key={doc.id}>
                  //           {doc.doc_type_name}
                  //         </li>
                  //       </Link>
                  //     );
                  //   }
                  // } else {
                  if (
                    !this.props.rehire &&
                    (doc.docs_categories_id == 1 || doc.docs_categories_id == 4)
                  ) {
                    return (
                      <Link
                        to={`/dashboard/docs/${doc.slug}?rehire=false&docId=${doc.id}`}
                        key={doc.id}
                      >
                        <li
                          className={`${doc?.docs_type?.is_lockable && onboardingShouldLock && !(doc.slug == activeDoc.slug) ? "doc-locked" : ""} 
                              ${doc.slug == activeDoc.slug ? "doc-active " : ""} ${
                                doc.status >= 2 && !(doc.slug == activeDoc.slug)
                                  ? "doc-complete"
                                  : ""
                              } `}
                          key={doc.id}
                        >
                          {doc.doc_type_name}
                        </li>
                      </Link>
                    );
                  } else if (
                    this.props.rehire &&
                    (doc.docs_categories_id == 2 || doc.docs_categories_id == 5)
                  ) {
                    return (
                      <Link
                        to={`/dashboard/docs/${doc.slug}?rehire=true&docId=${doc.id}`}
                        key={doc.id}
                      >
                        <li
                          className={`${doc?.docs_type?.is_lockable && rehireShouldLock && !(doc.slug == activeDoc.slug) ? "doc-locked" : ""} ${doc.slug == activeDoc.slug ? "doc-active " : ""} ${
                            doc.status >= 2 && !(doc.slug == activeDoc.slug)
                              ? "doc-complete "
                              : ""
                          }`}
                          key={doc.id}
                        >
                          {doc.doc_type_name}
                        </li>
                      </Link>
                    );
                  }
                  // }
                })}
              </ul>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { user, docs } = state;
  return { user, docs };
}

const connectedSidebarUser = compose(
  withRouter,
  connect(mapStateToProps),
)(SidebarUser);
export { connectedSidebarUser as SidebarUser };
