import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  getUsersExpirationDates,
  isUsersExpirationDatesLoading,
} from "_helpers/reduxSelector";
import { formatDate } from "_helpers/helpers";

const getStatusLabel = (status) => {
  // switch (status) {
  //   case 0:
  //     return "Inactive";
  //     case 1:
  //     return "Active";
  //     case 2:
  //     return "Locked";
  //     case 3:
  //       return "Guest"
  //   default:
  //     return "Guest"
  // }
  if (status === 1) {
    return "Available";
  } else if (status === 3) {
    return "Pending";
  } else if (status === 2) {
    return "Locked";
  } else return "Guest";
};

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  nameClass: ASC_CLASS,
  resourceClass: ASC_CLASS,
  statusClass: ASC_CLASS,
  dlClass: ASC_CLASS,
  mspaClass: ASC_CLASS,
  printClass: ASC_CLASS,
  physicalClass: ASC_CLASS,
  firstAidClass: ASC_CLASS,
  fallerClass: ASC_CLASS,
  harassClass: ASC_CLASS,
  rtClass: ASC_CLASS,
};

const ExpirationDateReportList = ({
  currentFilters = [],
  getExpirationDatesByFilters,
  usersExpirationDates,
}) => {
  const isLoading = useSelector((state) =>
    isUsersExpirationDatesLoading(state),
  );
  const data = usersExpirationDates?.data ?? [];

  const isSelectedAll = currentFilters.includes("all");
  const isDL = isSelectedAll || currentFilters.includes("dl");
  const isDOL = isSelectedAll || currentFilters.includes("dol");
  const isFirstAid = isSelectedAll || currentFilters.includes("firstaid");
  const isRt130 = isSelectedAll || currentFilters.includes("rt130");
  const isSexualHarassment =
    isSelectedAll || currentFilters.includes("sexualharrassment");
  const isFallerEquals =
    isSelectedAll || currentFilters.includes("fallerquals");
  const isStatus = isSelectedAll || currentFilters.includes("status");
  const isResource = isSelectedAll || currentFilters.includes("resource");
  const isRT130 = isSelectedAll || currentFilters.includes("rt130");

  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("last_name");
  const [page, setPage] = useState(0);

  const [
    {
      nameClass,
      resourceClass,
      statusClass,
      dlClass,
      mspaClass,
      printClass,
      physicalClass,
      firstAidClass,
      fallerClass,
      harassClass,
      rtClass,
    },
    setStyleState,
  ] = useState(initialStyleState);

  const handlePageChange = (newPage) => {
    const sortOrder = order ? "asc" : "desc";
    getExpirationDatesByFilters(
      newPage?.selected + 1,
      sortItem || "last_name",
      sortOrder,
    );
    setPage(newPage?.selected + 1);
  };

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getExpirationDatesByFilters(page, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  return (
    <>
      <div className="history_lists outer_list table__scroll">
        <table className="mobile_hide report_list_width_full long__report tab_hide expiry_report">
          <tr className="report_list_table_row">
            <th onClick={() => sortList("nameClass", nameClass, "first_name")}>
              <div className="report_table_header">
                <span>Employee Name</span>
                <span className={nameClass} />
              </div>
            </th>
            {
              <>
                <th
                  onClick={() =>
                    sortList("resourceClass", resourceClass, "resource_name")
                  }
                >
                  <div className="report_table_header">
                    <span>Resource</span>
                    <span className={resourceClass} />
                  </div>
                </th>
              </>
            }

            {isStatus && (
              <>
                <th
                  onClick={() => sortList("statusClass", statusClass, "status")}
                >
                  <div className="report_table_header">
                    <span>Status</span>
                    <span className={statusClass} />
                  </div>
                </th>
              </>
            )}
            {isDL && (
              <>
                <th onClick={() => sortList("dlClass", dlClass, "dl_expiry")}>
                  <div className="report_table_header">
                    <span>Drivers License Expiration Date</span>
                    <span className={dlClass} />
                  </div>
                </th>
              </>
            )}
            {isDOL && (
              <>
                <th
                  onClick={() =>
                    sortList("mspaClass", mspaClass, "mspa_exp_date")
                  }
                >
                  <div className="report_table_header">
                    <span>DOL Blue Card/ MSPA Expiration Date</span>
                    <span className={mspaClass} />
                  </div>
                </th>
                <th
                  onClick={() =>
                    sortList("printClass", printClass, "finger_print_expiry")
                  }
                >
                  <div className="report_table_header">
                    <span>DOL - Finger Print</span>
                    <span className={printClass} />
                  </div>
                </th>
                <th
                  onClick={() =>
                    sortList("physicalClass", physicalClass, "physical_expiry")
                  }
                >
                  <div className="report_table_header">
                    <span>DOL - Physical</span>
                    <span className={physicalClass} />
                  </div>
                </th>
              </>
            )}
            {isFirstAid && (
              <>
                <th
                  onClick={() =>
                    sortList("firstAidClass", firstAidClass, "first_aid_expiry")
                  }
                >
                  <div className="report_table_header">
                    <span>First Aid/CPR</span>
                    <span className={firstAidClass} />
                  </div>
                </th>
              </>
            )}
            {isFallerEquals && (
              <>
                <th
                  onClick={() =>
                    sortList("fallerClass", fallerClass, "faller_quals")
                  }
                >
                  <div className="report_table_header">
                    <span>Faller Qualification</span>
                    <span className={fallerClass} />
                  </div>
                </th>
              </>
            )}
            {isSexualHarassment && (
              <>
                <th
                  onClick={() =>
                    sortList(
                      "harassClass",
                      harassClass,
                      "sexual_harassment_expiry",
                    )
                  }
                >
                  <div className="report_table_header">
                    <span>Sexual Harassment</span>
                    <span className={harassClass} />
                  </div>
                </th>
              </>
            )}
          </tr>
          <tbody className="report_list_table_tbody">
            {data?.length && currentFilters?.length
              ? data.map((item, index) => (
                  <tr key={index}>
                    <td>{`${item?.last_name} ${item?.first_name}`}</td>
                    {
                      <>
                        <td>{item?.resource_name}</td>
                      </>
                    }

                    {isStatus && (
                      <>
                        <td>{getStatusLabel(item?.status)}</td>
                      </>
                    )}
                    {isDL && (
                      <>
                        <td>{formatDate(item?.dl_expiry)}</td>
                      </>
                    )}
                    {isDOL && (
                      <>
                        <td>{formatDate(item?.mspa_exp_date)}</td>
                        <td>{formatDate(item?.finger_print_expiry)}</td>
                        <td>{formatDate(item?.physical_expiry)}</td>
                      </>
                    )}
                    {isFirstAid && (
                      <>
                        <td>{formatDate(item?.first_aid_expiry)}</td>
                      </>
                    )}
                    {isFallerEquals && (
                      <>
                        <td>{formatDate(item?.faller_quals)}</td>
                      </>
                    )}
                    {isSexualHarassment && (
                      <>
                        <td>{formatDate(item?.sexual_harassment_expiry)}</td>
                      </>
                    )}
                  </tr>
                ))
              : !isLoading && (
                  <tr>
                    <td colSpan={10}>
                      <div className="dashboard_expiration_date_no_data">
                        No Data Found
                      </div>
                    </td>
                  </tr>
                )}
          </tbody>
        </table>

        {data?.length && currentFilters?.length ? (
          <>
            <ExpirationDateReportListMobileView
              data={data}
              showFields={{
                isDL,
                isDOL,
                isFirstAid,
                isRt130,
                isSexualHarassment,
                isFallerEquals,
                isStatus,
                isResource,
                isRT130,
              }}
              getExpirationDatesByFilters={getExpirationDatesByFilters}
            />
          </>
        ) : (
          !isLoading && (
            <div className="dashboard_expiration_date_no_data for_mobile_history_list">
              No Data Found
            </div>
          )
        )}
      </div>
      {data?.length ? (
        <div className="fs-pagination-wrapper-outer" key={7}>
          <div className="customised-pagination right-align">
            <ReactPaginate
              previousLabel={""}
              nextLabel={""}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={usersExpirationDates?.last_page}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => handlePageChange(data)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ExpirationDateReportList;

const ExpirationDateReportListMobileView = ({
  data,
  showFields,
  getExpirationDatesByFilters,
}) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("last_name");

  const [
    {
      nameClass,
      resourceClass,
      statusClass,
      dlClass,
      mspaClass,
      printClass,
      physicalClass,
      firstAidClass,
      fallerClass,
      harassClass,
      rtClass,
    },
    setStyleState,
  ] = useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getExpirationDatesByFilters(0, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list tab_show">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("nameClass", nameClass, "first_name")}
            >
              <strong>Employee Name</strong>
              <span className={nameClass} />
            </div>
            <div className="list_item">{`${item?.first_name} ${item?.last_name}`}</div>
          </div>
          {showFields && (
            <>
              <div className="status_item">
                <div
                  className="list_item"
                  onClick={() =>
                    sortList("resourceClass", resourceClass, "resource_name")
                  }
                >
                  <strong>Resource</strong>
                  <span className={resourceClass} />
                </div>
                <div className="list_item">{item?.resource_name}</div>
              </div>
            </>
          )}
          {showFields?.isSt && (
            <>
              <div className="status_item">
                <div
                  className="list_item"
                  onClick={() => sortList("statusClass", statusClass, "status")}
                >
                  <strong>Status</strong>
                  <span className={statusClass} />
                </div>
                <div className="list_item">{getStatusLabel(item?.status)}</div>
              </div>
            </>
          )}
          {showFields?.isDL && (
            <>
              <div className="status_item">
                <div
                  className="list_item"
                  onClick={() => sortList("dlClass", dlClass, "dl_expiry")}
                >
                  <strong>Drivers License Expiration Date</strong>
                  <span className={dlClass} />
                </div>
                <div className="list_item">{formatDate(item?.dl_expiry)}</div>
              </div>
            </>
          )}
          {showFields?.isDOL && (
            <>
              <div className="status_item">
                <div
                  className="list_item"
                  onClick={() =>
                    sortList("mspaClass", mspaClass, "mspa_exp_date")
                  }
                >
                  <strong>DOL Blue Card/ MSPA Expiration Date</strong>
                  <span className={mspaClass} />
                </div>
                <div className="list_item">
                  {formatDate(item?.mspa_exp_date)}
                </div>
              </div>
              <div className="status_item">
                <div
                  className="list_item"
                  onClick={() =>
                    sortList("printClass", printClass, "finger_print_expiry")
                  }
                >
                  <strong>DOL - Finger Print</strong>
                  <span className={printClass} />
                </div>
                <div className="list_item">
                  {formatDate(item?.finger_print_expiry)}
                </div>
              </div>
              <div className="status_item">
                <div
                  className="list_item"
                  onClick={() =>
                    sortList("physicalClass", physicalClass, "physical_expiry")
                  }
                >
                  <strong>DOL - Physical</strong>
                  <span className={physicalClass} />
                </div>
                <div className="list_item">
                  {formatDate(item?.physical_expiry)}
                </div>
              </div>
            </>
          )}
          {showFields?.isFirstAid && (
            <>
              <div className="status_item">
                <div
                  className="list_item"
                  onClick={() =>
                    sortList("firstAidClass", firstAidClass, "first_aid_expiry")
                  }
                >
                  <strong>First Aid/CPR</strong>
                  <span className={firstAidClass} />
                </div>
                <div className="list_item">
                  {formatDate(item?.first_aid_expiry)}
                </div>
              </div>
            </>
          )}
          {showFields?.isFallerEquals && (
            <>
              <div className="status_item">
                <div
                  className="list_item"
                  onClick={() =>
                    sortList("fallerClass", fallerClass, "faller_quals")
                  }
                >
                  <strong>Faller Qualification</strong>
                  <span className={fallerClass} />
                </div>
                <div className="list_item">
                  {formatDate(item?.faller_quals)}
                </div>
              </div>
            </>
          )}
          {showFields?.isSexualHarassment && (
            <>
              <div className="status_item">
                <div
                  className="list_item"
                  onClick={() =>
                    sortList(
                      "harassClass",
                      harassClass,
                      "sexual_harassment_expiry",
                    )
                  }
                >
                  <strong>Sexual Harassment</strong>
                  <span className={harassClass} />
                </div>
                <div className="list_item">
                  {formatDate(item?.sexual_harassment_expiry)}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  ));
};
